import React, { useState, useEffect } from "react";
import { API } from "../../config";
import resolveCurrency from "../../helpers/Helper";
import NumberFormat from "../../helpers/number-format-helper";

const MaintenanceBreakdown = ({ vehicle, units }) => {
  const [state, setState] = useState({
    loading: true,
    analytics: {},
    count: {},
    error: null,
    odometer: 0,
  });
  const { count, loading, analytics, error, odometer } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const token = localStorage.getItem("token");
      setState({ ...state, loading: true, error: null });
      fetch(`${API}/analytics/${vehicle.id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          fetch(
            `${API}/maintenance-schedule-count/${vehicle.id}?make=${vehicle.make}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((countData) => {
              console.log(data.analytics);
              // console.log(JSON.stringify(data.analytics));
              setState({
                ...state,
                loading: false,
                error: null,
                analytics: data.analytics,
                count: countData.maintenanceCount,
                odometer: countData.odometer,
              });
            })
            .catch((err) => {
              console.log(err);
              setState({
                ...state,
                loading: false,
                error:
                  "Unable to fetch your Maintenance Analytics at this time.",
              });
            });
        })
        .catch((err) => {
          console.log(err);
          setState({
            ...state,
            loading: false,
            error: "Unable to fetch your Maintenance Analytics at this time.",
          });
        });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [vehicle]);

  const computeCondition = (property) => {
    if (!count) {
      return "N/A";
    }
    let actualCount = analytics[`${property}_sum`];
    let recommendedCount =
      parseInt(odometer / 50000) * 8 + count[`${property}Count`] || 1;
    const change = ((actualCount - recommendedCount) / recommendedCount) * 100;
    if (analytics[`${property}_sum`] == 0 && count[`${property}Count`] == 0) {
      return <span class="badge badge-success">OK</span>;
    }

    if (change <= 5 && change >= -5) {
      return <span class="badge badge-success">OK</span>;
    } else {
      return <span class="badge badge-warning">ASK</span>;
    }
  };

  const computeExamples = (property) => {
    let actualCount = analytics[`${property}_sum`];
    let recommendedCount =
      parseInt(odometer / 50000) * 8 + count[`${property}Count`] || 1;
    const change = ((actualCount - recommendedCount) / recommendedCount) * 100;
    if (analytics[`${property}_sum`] == 0 && count[`${property}Count`] == 0) {
      return "The owner has reported most of the maintenance for this category";
    }

    if (change <= 5 && change >= -5) {
      return "The owner has reported most of the maintenance for this category";
    } else {
      return "The owner has reported some of the maintenance for this category";
    }
  };

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h4>Maintenance Totals</h4>

          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card">
        <div className="card-body">
          <h4>Maintenance Totals</h4>
          <div
            class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
            role="alert"
          >
            <strong>Error - </strong> {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card" id="keemut-report_maintenance_breakdown">
      <div className="card-body">
        <h4>Maintenance Totals</h4>

        <table className="table-striped">
          <thead>
            <tr>
              <th>Category</th>
              <th>Amount</th>
              <th>Count</th>
              <th>Recommended Count</th>
              <th>Condition</th>
              <th>Examples</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td data-label="Category">AC Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(analytics.ac_cost.toFixed(0))}
              </td>

              <td data-label="Count">{analytics.ac_sum}</td>
              <td data-label="Recommended Count">
                {count &&
                  (count.acCount
                    ? parseInt(odometer / 50000) + count.acCount
                    : parseInt(odometer / 50000))}
              </td>
              <td data-label="Condition">{computeCondition("ac")}</td>
              <td data-label="Examples">Ex. coolant and radiator flush</td>
            </tr>

            <tr>
              <td data-label="Category">Body Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(analytics.body_cost.toFixed(0))}
              </td>

              <td data-label="Count">{analytics.body_sum}</td>
              <td data-label="Recommended Count">
                {count &&
                  (count.bodyCount
                    ? parseInt(odometer / 50000) + count.bodyCount
                    : parseInt(odometer / 50000))}
              </td>
              <td data-label="Condition">{computeCondition("body")}</td>
              <td data-label="Examples">Ex. underbody flush.</td>
            </tr>
            <tr>
              <td data-label="Category">Engine Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(analytics.engine_cost.toFixed(0))}
              </td>
              <td data-label="Count">{analytics.engine_sum}</td>
              <td data-label="Recommended Count">
                {count &&
                  (count.engineCount
                    ? parseInt(odometer / 50000) + count.engineCount
                    : parseInt(odometer / 50000))}
              </td>
              <td data-label="Condition">{computeCondition("engine")}</td>
              <td data-label="Examples">
                Ex. spark plug and air filter replacement.
              </td>
            </tr>
            <tr>
              <td data-label="Category">Electrical Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(analytics.electrical_cost.toFixed(0))}
              </td>

              <td data-label="Count">{analytics.electrical_sum}</td>
              <td data-label="Recommended Count">
                {count &&
                  (count.electricalCount
                    ? parseInt(odometer / 50000) + count.electricalCount
                    : parseInt(odometer / 50000))}
              </td>
              <td data-label="Condition">{computeCondition("electrical")}</td>
              <td data-label="Examples">Ex. wiring / battery issues.</td>
            </tr>

            <tr>
              <td data-label="Category">Interior Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(analytics.interior_cost.toFixed(0))}
              </td>

              <td data-label="Count">{analytics.interior_sum}</td>
              <td data-label="Recommended Count">
                {count &&
                  (count.interiorCount
                    ? parseInt(odometer / 50000) + count.interiorCount
                    : parseInt(odometer / 50000))}
              </td>
              <td data-label="Condition">{computeCondition("interior")}</td>
              <td data-label="Examples">Ex. cabin air filter replacement.</td>
            </tr>

            <tr>
              <td data-label="Category">Other Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(analytics.other_cost.toFixed(0))}
              </td>

              <td data-label="Count">{analytics.other_sum}</td>
              <td data-label="Recommended Count">
                {count && (count.otherCount ? count.otherCount : 0)}
              </td>
              <td data-label="Condition">
                <span class="badge badge-success">OK</span>
              </td>
              <td data-label="Examples">-</td>
            </tr>

            <tr>
              <td data-label="Category">Steering Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(analytics.steering_cost.toFixed(0))}
              </td>

              <td data-label="Count">{analytics.steering_sum}</td>
              <td data-label="Recommended Count">
                {count &&
                  (count.steeringCount
                    ? parseInt(odometer / 50000) + count.steeringCount
                    : parseInt(odometer / 50000))}
              </td>
              <td data-label="Condition">{computeCondition("steering")}</td>
              <td data-label="Examples">
                Ex. power steering and steering belt replacement
              </td>
            </tr>
            <tr>
              <td data-label="Category">Suspension Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(analytics.suspension_cost.toFixed(0))}
              </td>

              <td data-label="Count">{analytics.suspension_sum}</td>
              <td data-label="Recommended Count">
                {count &&
                  (count.suspensionCount
                    ? parseInt(odometer / 50000) + count.suspensionCount
                    : parseInt(odometer / 50000))}
              </td>
              <td data-label="Condition">{computeCondition("suspension")}</td>
              <td data-label="Examples">Ex. vehicle pulling related issues</td>
            </tr>

            <tr>
              <td data-label="Category">Tire / Brake Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(analytics.tyre_brake_cost.toFixed(0))}
              </td>

              <td data-label="Count">{analytics.tyre_brake_sum}</td>
              <td data-label="Recommended Count">
                {count &&
                  (count.tyreBrakeCount
                    ? parseInt(odometer / 50000) + count.tyreBrakeCount
                    : parseInt(odometer / 50000))}
              </td>
              <td data-label="Condition">{computeCondition("tyre_brake")}</td>
              <td data-label="Examples">
                Ex. tire rotation and brake fluid flush
              </td>
            </tr>
            <tr>
              <td data-label="Category">Transmission Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(analytics.transmission_cost.toFixed(0))}
              </td>

              <td data-label="Count">{analytics.transmission_sum}</td>
              <td data-label="Recommended Count">
                {count &&
                  (count.transmissionCount
                    ? parseInt(odometer / 50000) + count.transmissionCount
                    : parseInt(odometer / 50000))}
              </td>
              <td data-label="Condition">{computeCondition("transmission")}</td>
              <td data-label="Examples">
                Ex. rear axle and transfer case fluid flush
              </td>
            </tr>

            <tr style={{ borderTop: "2px solid #000" }}>
              <td data-label="Category">
                <strong>Total</strong>
              </td>
              <td data-label="Amount">
                <strong>
                  {resolveCurrency(units.currencyunit)}{" "}
                  {count &&
                    NumberFormat(
                      parseInt(analytics.other_cost) +
                        parseInt(analytics.engine_cost) +
                        parseInt(analytics.electrical_cost) +
                        parseInt(analytics.ac_cost) +
                        parseInt(analytics.tyre_brake_cost) +
                        parseInt(analytics.transmission_cost) +
                        parseInt(analytics.interior_cost) +
                        parseInt(analytics.steering_cost) +
                        parseInt(analytics.suspension_cost)
                    )}
                </strong>
              </td>

              <td data-label="Count">
                {analytics.engine_sum +
                  analytics.ac_sum +
                  analytics.electrical_sum +
                  analytics.tyre_brake_sum +
                  analytics.transmission_sum +
                  analytics.interior_sum +
                  analytics.steering_sum +
                  analytics.suspension_sum +
                  analytics.other_sum}
              </td>
              <td data-label="Recommended Count">
                {count &&
                  parseInt(odometer / 50000) * 8 +
                    count.engineCount +
                    count.acCount +
                    count.electricalCount +
                    count.tyreBrakeCount +
                    count.transmissionCount +
                    count.interiorCount +
                    count.steeringCount +
                    count.suspensionCount +
                    count.otherCount}
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <br />
      </div>
    </div>
  );
};

export default MaintenanceBreakdown;
