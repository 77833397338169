import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import BatteryLifeChart from "./BatteryLifeChart";
import BatteryRangeChart from "./BatteryRangeChart";
import { Redirect } from "react-router-dom";
import AverageBatteryLife from "./AverageBatteryLife";
import UsableBatteryLevelChart from "./UsableBatteryLevelChart";
import CommunityBatteryLife from "./CommunityBatteryLife";
import ChargingHistory from "./ChargingHistory";
import SubscribeBanner from "../../common/SubscribeBanner";
import PhantomDrainChart from "./PhantomDrainChart";
import BatteryDegradationChart from "./BatteryDegradationChart";
const EnergyHistory = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: true,
    units: {},
    redirect: false,
  });
  const { units, redirect, loading } = state;

  useEffect(() => {
    const lsJSON = localStorage.getItem("units");

    if (lsJSON) {
      setState({ ...state, units: JSON.parse(lsJSON), loading: false });
    } else {
      const token = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("user"));
      fetch(`${API}/units/${user.uid}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          localStorage.setItem("units", JSON.stringify(data.units));
          setState({ ...state, units: data.units, loading: false });
        });
    }
  }, []);

  useEffect(() => {
    if (vehicle.make !== "Tesla") {
      setState({ ...state, redirect: true });
    }
  }, [vehicle]);

  if (redirect) {
    return <Redirect to="/dashboard/fuel-history" />;
  }

  if (loading) {
    return <div class="spinner-border text-primary" role="status"></div>;
  }

  return (
    <div className="text-dark">
      <SubscribeBanner show7dayMessage={true} />
      <h3> Energy History </h3>
      <div className="mx-2 my-3">
        <div className="row ">
          <div className="col-lg-7">
            <BatteryLifeChart vehicle={vehicle} units={units} />
          </div>{" "}
          <div className="col-lg-5">
            <AverageBatteryLife vehicle={vehicle} units={units} />
          </div>
        </div>
        <br />
        <div className="row ">
          <div className="col-lg-6">
            <BatteryRangeChart vehicle={vehicle} units={units} />
          </div>{" "}
          <div className="col-lg-6">
            <UsableBatteryLevelChart vehicle={vehicle} />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-lg-12">
            <ChargingHistory vehicle={vehicle} units={units} />
            {/* <CommunityBatteryLife vehicle={vehicle} units={units} /> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <PhantomDrainChart vehicle={vehicle} />
            {/* <CommunityBatteryLife vehicle={vehicle} units={units} /> */}
          </div>
          <div className="col-lg-6">
            {/* <BatteryDegradationChart vehicle={vehicle} units={units} /> */}
            <CommunityBatteryLife vehicle={vehicle} units={units} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyHistory;
