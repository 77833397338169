import React, { useState, useEffect } from "react";
import { Tab } from "semantic-ui-react";
import TCO from "../tco/TCO";
import FuelHistory from "../fuel-history/FuelHistory";
import InsuranceLogbook from "./insurance/InsuranceLogbook";
import ExpenseLogbook from "./expense/ExpenseLogbook";
import { Helmet } from "react-helmet";
import { API } from "../../../config";
import EnergyHistory from "../energy-history/EnergyHistory";
import FloatingButton from "../common/FloatingButton";
import queryString from "query-string";
import DrivingReport from "./driving/DrivingReport";
import TripTaxSavings from "../trip/TripTaxSavings";

const Logbook = ({ vehicle, ...props }) => {
  const [state, setState] = useState({
    loading: false,

    activeIndex:
      queryString.parse(props.location.search).defaultTab ||
      localStorage.getItem("activeLogbookTab") ||
      0,
    units: JSON.parse(localStorage.getItem("units")),
  });
  const { loading, units, activeIndex } = state;

  const panes = [
    {
      menuItem: "Maintenance",
      render: () => (
        <Tab.Pane>
          <TCO vehicle={vehicle} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: vehicle.make === "Tesla" ? "Energy" : "Fuel",
      render: () => {
        if (vehicle.make === "Tesla") {
          return (
            <Tab.Pane>
              <EnergyHistory vehicle={vehicle} />
            </Tab.Pane>
          );
        } else {
          return (
            <Tab.Pane>
              <FuelHistory vehicle={vehicle} />
            </Tab.Pane>
          );
        }
      },
    },
    {
      menuItem: "Insurance",
      render: () => (
        <Tab.Pane>
          <InsuranceLogbook units={units} vehicle={vehicle} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Expense",
      render: () => (
        <Tab.Pane>
          <ExpenseLogbook units={units} vehicle={vehicle} />
        </Tab.Pane>
      ),
    },

    {
      menuItem: "Driving Report",
      render: () => (
        <Tab.Pane>
          <DrivingReport vehicle={vehicle} units={units} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Tax Report",
      render: () => (
        <Tab.Pane>
          <TripTaxSavings vehicle={vehicle} units={units} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="text-dark">
      <Helmet>
        <title>Keemut | Logbook</title>
      </Helmet>
      <h1>{`${vehicle.name}'s`} Logbook </h1>{" "}
      <Tab
        activeIndex={activeIndex}
        panes={panes}
        onTabChange={(e, data) => {
          localStorage.setItem("activeLogbookTab", data.activeIndex);
          setState({ ...state, activeIndex: data.activeIndex });
        }}
      />{" "}
    </div>
  );
};

export default Logbook;
