import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { API, site } from "../../../config";
import { Helmet } from "react-helmet";
import OdometerCard from "./OdometerCard";
import TeslaCarImage from "./tesla/TeslaCarImage";
import CostPerMileCard from "./CostPerMileCard";
import CostDataChart from "./CostDataChart";
import CostPerMileDataChart from "./CostPerMileDataChart";
import Dialog from "@material-ui/core/Dialog";
import TemperatureVsEfficiencyChart from "./tesla/TemperatureVsEfficiencyChart";
import ValueCard from "./ValueCard";
import OdometerHistoryChart from "./OdometerHistoryChart";
import ReminderTable from "../tco/ReminderTable";
import Joyride from "react-joyride";
import FloatingButton from "../common/FloatingButton";
import SubscribeBanner from "../../common/SubscribeBanner";
import PhantomDrainCard from "./tesla/PhantomDrainCard";
import BatteryLifeChart from "../energy-history/BatteryLifeChart";
import AverageBatteryLife from "../energy-history/AverageBatteryLife";
import TeslaSoftwareUpdateHistory from "./tesla/TeslaSoftwareUpdateHistory";
import RecentCharges from "./tesla/RecentCharges";
import LocationHeatmap from "../trip/LocationHeatmap";
import CommunitySoftwareVersionBreakdown from "./tesla/CommunitySofwareVersionBreakdown";
import TeslaLiveData from "./tesla/TeslaLiveData";
import VinwiseChargeCurve from "./VinwiseChargeCurve";
import ModelwiseChargeCurve from "./ModelwiseChargeCurve";
import WhMiVsOdometerChart from "./WhMiVsOdometerChart";
import WhMiVsTemperatureChart from "./WhMiVsTemperatureChart";
import TataLiveData from "./tata/tata-live-data";
import ConnectionModeHelper from "../../../helpers/connection-mode-helper";
import TataBatteryLifeChart from "./tata/tata-battery-life-chart";
import TataWattPerMileChart from "./tata/tata-watt-per-mile-chart";
import TataEfficiencyChart from "./tata/tata-efficiency-chart";
import TataDailySummary from "./tata/tata-daily-summary";
import TataChargeHistory from "./tata/tata-charge-history";
const mixpanel = require("mixpanel-browser");

const Overview = ({
  vehicle,
  updateDropdownOptions,
  updateVehicleDropdownValues,
  vehiclePicker,
  updateOdometer
}) => {
  const [state, setState] = useState({
    units: {},
    vehicles: [],
    showOnBoardingDialog: false,
    seeding: false,
    loading: true,
    user: null,
    steps: [
      {
        target: ".value-card-step",
        placement: "right",

        content:
          "This is the Current Value of your car, It will change over time based on how you drive and various other factors.",
      },
      {
        target: ".odometer-card-step",
        placement: "right",
        content:
          "This is the driving information, Details like Odometer, Monthly Driving etc can be found here.",
      },

      {
        target: ".costpermile-card-step",
        placement: "left",

        content:
          "This is the Cost of ownership or in other words the cost you bear for every mile you travel. ",
      },

      {
        target: ".charts-step",
        content:
          "These are the various insights that we analyse based on the data for a vehicle.",
      },

      {
        target: ".reminders-step",
        placement: "right",
        content:
          "These are the upcoming reminders for the vehicle, We automatically add reminders for you based on your odometer and driving.",
      },
      {
        target: ".odometer-history-step",
        placement: "left",
        content:
          "This is the odometer history Our Platform has a inbuilt Rollback detection, any potention rollback that we detect will get flagged here.",
      },
      {
        target: ".dropdown-step",
        placement: "right",

        content:
          "Use the dropdown to change the active vehicle. Now let's get your vehicle added to keemut",
      },
    ],
  });

  const {
    steps,
    loading,
    units,
    user,
    vehicles,
    showOnBoardingDialog,
    seeding,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      intialRequest();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const intialRequest = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    setState({ ...state, loading: true });
    fetch(`${API}/vehicles/all/${user.uid}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        fetch(`${API}/units/${user.uid}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((jsonData) => {
            localStorage.setItem("units", JSON.stringify(jsonData.units));
            localStorage.setItem("vehicles", JSON.stringify(data.vehicles));

            if (data.vehicles.length < 1) {
              setState({
                ...state,
                loading: false,
                user: user,
                units: jsonData.units,
                vehicles: data.vehicles,
                showOnBoardingDialog: true,
              });
            } else
              setState({
                ...state,
                loading: false,
                user: user,

                units: jsonData.units,
                vehicles: data.vehicles,
                vehicle: data.vehicles[0],
              });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const seedSampleVehicle = () => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    setState({ ...state, seeding: true });
    fetch(`${API}/seed-vehicle/${user.uid}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        fetch(`${API}/vehicles/all/${user.uid}`, {
          method: "GET",
          headers: {
            Accept: "application/json",

            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            const vehicles = data.vehicles;

            const { options } = updateDropdownOptions(vehicles);
            updateVehicleDropdownValues(options, vehicles);
            // window.location.reload(true);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return (
      <div
        className="spinner-border spinner-center text-primary"
        role="status"
      ></div>
    );
  }

  return (
    <div className="text-dark">
      <FloatingButton />
      <Helmet>
        <meta
          name="description"
          content="Keemut Dashboard, Get Detailed Analytics for your car and key insights that help you increase the value of your car."
        />
        <title>Keemut | Dashboard</title>
      </Helmet>
      {vehicle && vehicle.name === "Sample Audi" && (
        <Joyride
          steps={steps}
          disableScrollParentFix={true}
          showSkipButton={true}
          showProgress={true}
          disableOverlay={true}
          spotlightClicks={true}
          continuous={true}
          callback={(e) => {
            if (e.action == "reset") {
              window.location = `${site}/dashboard/vehicles`;
            }
          }}
          styles={{
            options: {
              textColor: "#000",
            },
            tooltipContainer: {
              textAlign: "left",
            },
          }}
        />
      )}
      <Dialog
        disableEscapeKeyDown
        disableBackdropClick
        open={showOnBoardingDialog}
        onClose={() => setState({ ...state, showOnBoardingDialog: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h4 className="modal-title" id="standard-modalLabel">
              <strong>Welcome To Keemut 😄</strong>
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() =>
                setState({ ...state, showOnBoardingDialog: false })
              }
            ></button>
          </div>
          <div className="modal-body">
            Let's take a quick tour of Keemut to get you upto speed with the
            platform
            {"                                "}
            <br /> <br /> <br />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-primary"
              data-dismiss="modal"
              disabled={seeding}
              onClick={seedSampleVehicle}
            >
              {seeding ? "Generating Your Vehicle..." : "Continue"}
            </button>
            {/* <a href="/dashboard/vehicles">
              <button type="button" className="btn btn-primary" disabled={seeding}>
                Add Your Vehicle
              </button>
            </a> */}
          </div>
        </div>
      </Dialog>

      {vehicles.length >= 1 && (
        <>
          {" "}
          <div className="row">
            <div
              className="dropdown-step"
              style={{ textAlign: "end", width: "100%", marginTop: "-35px" }}
            ></div>
            <SubscribeBanner />
            <div className="col-12">
              <h1 className="page-title" style={{ letterSpacing: "1.2px" }}>
                {vehicle.name + "'s "}
                Dashboard{" "}
              </h1>
              <br />
            </div>
          </div>{" "}
          {vehicle && vehicle.make == "Tesla" && (
            <>
              <div className="row">
                <div className="col-lg-6">
                  <TeslaLiveData vehicle={vehicle} />
                </div>
                <div className="col-lg-6">
                  <TeslaCarImage vehicle={vehicle} />
                </div>
              </div>
              <br />
            </>
          )}
          {vehicle && vehicle.make == "Tata" && (
            <>
              <div className="row">
                <div className="col-lg-12">
                  <TataLiveData vehicle={vehicle} updateOdometer={updateOdometer} />
                </div>
                {/* <div className="col-lg-6">
                  <TeslaCarImage vehicle={vehicle} />
                </div> */}
              </div>
              <br />
            </>
          )}
          <div className="row">
            <div className="col-lg-4 value-card-step">
              <ValueCard vehicle={vehicle} units={units} />
            </div>
            <div className="col-lg-4 odometer-card-step">
              <OdometerCard vehicle={vehicle} units={units} />
            </div>

            <div className="col-lg-4 costpermile-card-step">
              {vehicle.make == "Tesla" ? (
                <PhantomDrainCard vehicle={vehicle} units={units} />
              ) : (
                <CostPerMileCard vehicle={vehicle} units={units} />
              )}
            </div>
          </div>
          {ConnectionModeHelper.isTataZConnect(vehicle) && (
            <>
              <div className="row">
                <div className="col-lg-6">
                  <TataBatteryLifeChart vehicle={vehicle} units={units} />
                </div>
                <div className="col-lg-6">
                  <TataDailySummary vehicle={vehicle} units={units} />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-6">
                  <TataEfficiencyChart vehicle={vehicle} units={units} />
                </div>
                <div className="col-lg-6">
                  <TataWattPerMileChart vehicle={vehicle} units={units} />
                </div>
              </div>
              <br />
            </>
          )}
          {ConnectionModeHelper.isTataZConnect(vehicle) && (
            <>
              <div className="row">
                <div className="col-lg-12">
                  <TataChargeHistory vehicle={vehicle} units={units}  />
                </div>
              </div>
              <br/>

            </>
          )}
          {(vehicle.make == "Tesla" || vehicle.is_smartcar) && (
            <div className="row ">
              <div className="col-lg-7">
                <BatteryLifeChart vehicle={vehicle} units={units} />
              </div>{" "}
              <div className="col-lg-5">
                <AverageBatteryLife vehicle={vehicle} units={units} />
              </div>
            </div>
          )}
          {vehicle.make == "Tesla" && (
            <>
              <br />
              <div className="row">
                <div className="col-lg-7">
                  <TemperatureVsEfficiencyChart
                    vehicle={vehicle}
                    units={units}
                  />
                  {/* <CommunitySoftwareVersionBreakdown /> */}
                </div>

                <div className="col-lg-5">
                  <TeslaSoftwareUpdateHistory vehicle={vehicle} />
                </div>
              </div>
              <br />
            </>
          )}
          {vehicle.is_smartcar && (
            <>
              <br />

              <div className="row">
                <div className="col-lg-12">
                  <LocationHeatmap vehicle={vehicle} />
                </div>
              </div>
            </>
          )}
          <div className="row charts-step">
            <div className="col-lg-6">
              <CostDataChart units={units} vehicle={vehicle} />
            </div>
            <div className="col-lg-6">
              {vehicle.make == "Tesla" ? (
                <RecentCharges vehicle={vehicle} units={units} />
              ) : (
                <CostPerMileDataChart vehicle={vehicle} units={units} />
              )}
            </div>
          </div>
          {vehicle && vehicle.make == "Tesla" && (
            <div className="row mt-3" id="simple-dragula" data-plugin="dragula">
              <div className="col-lg-6 ">
                <VinwiseChargeCurve vehicle={vehicle} />
              </div>
              <div className="col-lg-6 ">
                <ModelwiseChargeCurve vehicle={vehicle} />
              </div>
            </div>
          )}
          {vehicle && vehicle.make == "Tesla" && (
            <div className="row mt-3" id="simple-dragula" data-plugin="dragula">
              <div className="col-lg-6 ">
                <WhMiVsOdometerChart vehicle={vehicle} units={units} />
              </div>
              <div className="col-lg-6 ">
                <WhMiVsTemperatureChart vehicle={vehicle} units={units} />
              </div>
            </div>
          )}
          <div className="row mt-3" id="simple-dragula" data-plugin="dragula">
            <div className="col-lg-6 reminders-step">
              <ReminderTable vehicle={vehicle} units={units} />
              {/* <MaintenanceScheduleTable vehicle={vehicle} units={units} /> */}
              {/* <ComparableFuelTable vehicle={vehicle} units={units} /> */}
            </div>
            <div className="col-lg-6 odometer-history-step">
              {/* <FuelMpgChart vehicle={vehicle} /> */}
              <OdometerHistoryChart vehicle={vehicle} units={units} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Overview;
