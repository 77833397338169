import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../../config";
import moment from "moment";
import regression from "regression";
import KalmanFilter from "kalmanjs";
import { Popup, Dropdown } from "semantic-ui-react";

const BatteryLifeChart = ({ vehicle }) => {
  const [state, setState] = useState({
    timeRange: "7days",
    options: {
      chart: {
        foreColor: "#000000",

        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 5,
          color: "#00274c",
          opacity: 0.45,
        },
      },

      yaxis: {
        min: 0,
        title: { text: "Phantom Drain (kWh)" },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#00274c", "#ffbb002a"],

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2.75,
        dashArray: 0,
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
    valueLost: null,
    loading: true,
  });

  const { options, series, loading, valueLost, timeRange } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getPhantomDrainData();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getPhantomDrainData = async () => {
    const token = localStorage.getItem("token");

    fetch(
      `${API}/tesla/phantom-drain-data/${vehicle.vin}?timeRange=${timeRange}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        let xAxisData = [];
        let yAxisData = [];

        let min, max;
        data.phantomDrainData.forEach((d, i) => {});
        let sum = 0;
        for (let i = 0; i < data.phantomDrainData.length; i++) {
          let d = data.phantomDrainData[i];
          xAxisData.push(moment(d.date).format("DD-MMM-YY"));
          yAxisData.push(parseFloat(d.phantom_drain).toFixed(2));

          if (!min) {
            min = d.phantom_drain;
          } else if (min > d.phantom_drain) {
            min = d.phantom_drain;
          }

          if (!max) {
            max = d.phantom_drain;
          } else if (max < d.phantom_drain) {
            max = d.phantom_drain;
          }

          sum += parseFloat(d.phantom_drain);
        }
        let originalData = yAxisData;
        // var kalmanFilter = new KalmanFilter({ R: 0.0000000001111, Q: 2 });

        // yAxisData = yAxisData.map(function (v) {
        //   return kalmanFilter.filter(v).toFixed(2);
        // });

        // let valLost =
        //   0.21 *
        //   vehicle.purchaseprice *
        //   ((100 - sum / data.batteryLifeData.length) / 100);

        setState({
          ...state,
          loading: false,
          options: {
            ...options,
            xaxis: {
              type: "Date",
              categories: xAxisData,
              title: { text: "Date" },
            },
            yaxis: {
              max: parseFloat(max),
              min: parseFloat(min),
            },
          },
          series: [
            {
              name: "Daily Phantom Drain (kWh) ",
              type: "bar",
              data: yAxisData,
            },
          ],
          valueLost: 50,
        });
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return (
      <div class="card">
        <div class="card-body">
          <h4>Battery Life</h4>

          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card" style={{ height: "100%" }}>
      <div class="card-body">
        <h4>
          {" "}
          Daily Phantom Drain{" "}
          <Popup
            className="ml-4"
            content={
              "This chart depicts the amount of energy your vehicle loses when idle."
            }
            trigger={
              <i className="mdi mdi-information-outline hover-pointer"></i>
            }
          />
        </h4>
        <div className="form-group">
          <label className="mr-3">Show Charges From</label>
          <Dropdown
            search
            selection
            value={timeRange}
            onChange={(e, { value }) => {
              setState({ ...state, timeRange: value });
            }}
            options={[
              { key: "7days", value: "7days", text: "Last 7 days" },

              { key: "all", value: "all", text: "All" },
            ]}
          />
        </div>
        <Chart options={options} series={series} type="bar" />
      </div>
    </div>
  );
};

export default BatteryLifeChart;
