import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../config";
import ConfidenceChart from "../custom-charts/ConfidenceChart";

const ReportValuationChart = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: false,
    series: [],
    options: {
      chart: {
        type: "line",
        height: "100%",
        id: "basic-line",
      },

      xaxis: {
        categories: [],
      },

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
  });
  const { loading, series, options } = state;

  useEffect(() => {
    const data = {
      done: true,
      cumulativeWeekDepreciation: [
        "-2.92",
        "-1.57",
        "-1.17",
        "-1.57",
        "-2.64",
        "-4.24",
        "-6.22",
        "-8.44",
        "-10.76",
        "-13.04",
        "-15.14",
        "-16.91",
        "-18.22",
        "-17.57",
      ],
      netDepreciation: 17.569974479461507,
      weeklyDepreciation: 0.7518917284608,
    };
    let cumulativeData = [];
    let cumulativeHigh = [];
    let cumulativeLow = [];
    let deviation = 0;
    data.cumulativeWeekDepreciation.forEach((cd, i) => {
      if (i < data.cumulativeWeekDepreciation.length / 2) {
        deviation = 2;
      } else if (deviation < data.cumulativeWeekDepreciation.length - 2) {
        deviation = 4;
      } else {
        deviation = 5;
      }

      cumulativeHigh.push({
        x: i + 1,
        y:
          99000 -
          99000 * parseFloat((-1 * cd) / 100) +
          99000 * (deviation / 100),
      });
      cumulativeData.push({
        x: i + 1,
        y: 99000 - 99000 * parseFloat((-1 * cd) / 100),
      });
      cumulativeLow.push({
        x: i + 1,
        y:
          99000 -
          99000 * parseFloat((-1 * cd) / 100) -
          99000 * (deviation / 100),
      });
    });

    setState({
      ...state,
      options: {
        ...options,
        colors: ["#00274c", "#343a40", "#343a40"],
        yaxis: { title: { text: "Value" } },
        xaxis: {
          type: "Age, Years",
          categories: [4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56],
          labels: {
            formatter: function (value) {
              return parseInt(value);
            },
          },
          title: { text: "Car Age ( Week )" },
        },
      },
      series: [
        {
          name: "This Vehicle",
          data: cumulativeHigh,
        },
        {
          name: "Excellent Driving",
          data: cumulativeData,
        },
        {
          name: "Aggressive Driving",
          data: cumulativeLow,
        },
      ],
    });
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <h3>Valuation</h3>
        <div className="row">
          <div className="col-lg-12 mt-5 p-0  text-center">
            <ConfidenceChart
              id="tesla-xyz"
              data={[
                // {
                //   x: 7,
                //   date: "2020-07-01",
                //   y: 23338.375,
                //   lower: 21600.891,
                //   upper: 25075.859,
                // },
                // {
                //   x: 8,
                //   date: "2020-08-01",
                //   y: 24039.0078,
                //   lower: 22301.5238,
                //   upper: 25776.4918,
                // },
                // {
                //   x: 9,
                //   date: "2020-09-01",
                //   y: 24787.8789,
                //   lower: 23050.3949,
                //   upper: 26525.3629,
                // },
                // {
                //   x: 10,
                //   date: "2020-10-01",
                //   y: 24588.4473,
                //   lower: 22850.9633,
                //   upper: 26325.9313,
                // },
                // {
                //   x: 11,
                //   date: "2020-11-01",
                //   y: 24201.998,
                //   lower: 22464.514,
                //   upper: 25939.482,
                // },
                // {
                //   x: 12,
                //   date: "2020-12-01",
                //   y: 23831.8047,
                //   lower: 22094.3207,
                //   upper: 25569.2887,
                // },
                {
                  x: 13,
                  date: "2021-01-01",
                  y: 48500,
                  lower: 46000,
                  upper: 49000,
                },
                {
                  x: 14,
                  date: "2021-02-01",
                  y: 49000,
                  lower: 46000,
                  upper: 51000,
                },
                {
                  x: 15,
                  date: "2021-03-01",
                  y: 50000,
                  lower: 48000,
                  upper: 51000,
                },
                {
                  x: 16,
                  date: "2021-04-01",
                  y: 48000,
                  lower: 46000,
                  upper: 50000,
                },
                {
                  x: 17,
                  date: "2021-05-01",
                  y: 46000,
                  lower: 44000,
                  upper: 48000,
                },
                {
                  x: 18,
                  date: "2021-06-01",
                  y: 44500,
                  lower: 42500,
                  upper: 46500,
                },
                {
                  x: 19,
                  date: "2021-07-01",
                  y: 47000,
                  lower: 45000,
                  upper: 49000,
                },
                {
                  x: 20,
                  date: "2021-08-01",
                  y: 48000,
                  lower: 45000,
                  upper: 50000,
                },
                {
                  x: 21,
                  date: "2021-08-15",
                  y: 50000,
                  lower: 48000,
                  upper: 52000,
                },
              ]}
              areaData={[
                // {
                //   x: 7,
                //   date: "2020-07-01",
                //   y: 23338.375,
                //   lower: 21600.891,
                //   upper: 25075.859,
                // },
                // {
                //   x: 8,
                //   date: "2020-08-01",
                //   y: 24039.0078,
                //   lower: 22301.5238,
                //   upper: 25776.4918,
                // },
                // {
                //   x: 9,
                //   date: "2020-09-01",
                //   y: 24787.8789,
                //   lower: 23050.3949,
                //   upper: 26525.3629,
                // },
                // {
                //   x: 10,
                //   date: "2020-10-01",
                //   y: 24588.4473,
                //   lower: 22850.9633,
                //   upper: 26325.9313,
                // },
                // {
                //   x: 11,
                //   date: "2020-11-01",
                //   y: 24201.998,
                //   lower: 22464.514,
                //   upper: 25939.482,
                // },
                // {
                //   x: 12,
                //   date: "2020-12-01",
                //   y: 23831.8047,
                //   lower: 22094.3207,
                //   upper: 25569.2887,
                // },
                {
                  x: 13,
                  date: "2021-01-01",
                  y: 48500,
                  lower: 46000,
                  upper: 50000,
                },
                {
                  x: 14,
                  date: "2021-02-01",
                  y: 49000,
                  lower: 46000,
                  upper: 51000,
                },
                {
                  x: 15,
                  date: "2021-03-01",
                  y: 50000,
                  lower: 48000,
                  upper: 51000,
                },
                {
                  x: 16,
                  date: "2021-04-01",
                  y: 48000,
                  lower: 46000,
                  upper: 50000,
                },
                {
                  x: 17,
                  date: "2021-05-01",
                  y: 46000,
                  lower: 44000,
                  upper: 48000,
                },
                {
                  x: 18,
                  date: "2021-06-01",
                  y: 44500,
                  lower: 42500,
                  upper: 46500,
                },
                {
                  x: 19,
                  date: "2021-07-01",
                  y: 47000,
                  lower: 45000,
                  upper: 49000,
                },
                {
                  x: 20,
                  date: "2021-08-01",
                  y: 48000,
                  lower: 45000,
                  upper: 50000,
                },
                {
                  x: 21,
                  date: "2021-08-15",
                  y: 50000,
                  lower: 48000,
                  upper: 52000,
                },
              ]}
              domainX={[13, 21]}
              domainY={[38000, 56000]}
              years={[2021, 2021, 2021, 2021, 2021, 2021, 2021, 2021, 2021]}
              vin={"Your Vehicle"}
              currencyUnit={"AED"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportValuationChart;
