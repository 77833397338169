import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import resolveCurrency from "../../../helpers/Helper";
import EditMaintenanceDialog from "./EditMaintenanceDialog";
import moment from "moment";
import { Dropdown } from "semantic-ui-react";
import ViewMaintenanceDialog from "./ViewMaintenanceDialog";
import DeleteMaintenanceDialog from "./DeleteMaintenanceDialog";
import { getAllMaintenanceFormService } from "../../../services/forms/maintenance-form-service";

const MaintenanceHistoryTable = ({ vehicle, units }) => {
  const [state, setState] = useState({
    loading: true,
    maintRecords: [],
    paginations: 0,
    currentPage: 1,
    showEditDialog: false,
    showViewDialog: false,
    maintenanceReportId: null,
    showDeleteDialog: false,
    maintenanceReport: null,
    error: null,
    filter: "All",
  });
  const {
    maintenanceReport,
    loading,
    maintRecords,
    paginations,
    currentPage,
    maintenanceReportId,
    showViewDialog,
    showEditDialog,
    showDeleteDialog,
    error,
    filter,
  } = state;

  useEffect(() => {
    getAllMaintenanceHistory();
  }, [vehicle]);
  const getAllMaintenanceHistory = () => {
    const token = localStorage.getItem("token");
    setState({
      ...state,
      loading: true,
      showEditDialog: false,
      showDeleteDialog: false,
      error: null,
    });

    getAllMaintenanceFormService(vehicle.id)
      .then((data) => {
        setState({
          ...state,
          loading: false,
          maintRecords: data.maintRecords,
          paginations: Math.abs(data.maintRecords.length / 10) + 1,
          showEditDialog: false,
          showDeleteDialog: false,
          error: null,
        });
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          loading: false,
          error: "Unable to fetch your Maintenance History at this time.",
        });
      });
  };
  const renderPagination = () => {
    let content = [];
    for (let i = 1; i <= paginations; i++) {
      content.push(
        <a
          onClick={() => setState({ ...state, currentPage: i })}
          class="page-link"
          style={
            currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
      );
    }
    return <> {content} </>;
  };

  const getMaintenanceType = (typeObj) => {
    if (typeObj.engine_related) {
      return "Engine Related";
    } else if (typeObj.electrical_related) {
      return "Electrical Related";
    } else if (typeObj.ac_related) {
      return "AC Related";
    } else if (typeObj.tyre_brake_related) {
      return "Tyre / Brake Related";
    } else if (typeObj.transmission_related) {
      return "Transmission Related";
    } else if (typeObj.body_related) {
      return "Body Related";
    } else if (typeObj.interior_related) {
      return "Interior Related";
    } else if (typeObj.suspension_related) {
      return "Suspension Related";
    } else {
      return "Other";
    }
  };

  const sortByPropertyAsc = (propertyName) => {
    function compare(v1, v2) {
      if (propertyName === "total_cost") {
        const valueA = parseFloat(v1["total_cost"]);
        const valueB = parseFloat(v2["total_cost"]);
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      }

      const valueA =
        typeof v1[propertyName] === "string"
          ? v1[propertyName].toUpperCase()
          : v1[propertyName];
      const valueB =
        typeof v2[propertyName] === "string"
          ? v2[propertyName].toUpperCase()
          : v2[propertyName];

      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      return comparison;
    }
    const values = maintRecords;
    values.sort(compare);
    setState({ ...state, maintRecords: values });
  };

  const sortByPropertyDesc = (propertyName) => {
    function compare(v1, v2) {
      if (propertyName === "total_cost") {
        const valueA = parseFloat(v1["total_cost"]);
        const valueB = parseFloat(v2["total_cost"]);
        console.log(v1);
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      } else {
        const valueA =
          typeof v1[propertyName] === "string"
            ? v1[propertyName].toUpperCase()
            : v1[propertyName];
        const valueB =
          typeof v2[propertyName] === "string"
            ? v2[propertyName].toUpperCase()
            : v2[propertyName];

        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      }
    }
    const values = maintRecords;
    values.sort(compare);
    setState({ ...state, maintRecords: values.reverse() });
  };

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h2 class="header-title">Maintenance History</h2>
          <p>Breakdown of Total Cost of Ownership</p>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card">
        <div className="card-body">
          <h2 class="header-title">Maintenance History</h2>
          <p>Breakdown of Total Cost of Ownership</p>
          <div
            class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
            role="alert"
          >
            <strong>Error - </strong> {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="float-left">
          <h2>Maintenance History</h2>
          <p>Breakdown of Total Cost of Ownership</p>
        </div>

        <div className="float-right">
          <div className="form-group">
            <label>Filter by</label>
            <Dropdown
              className="form-control"
              placeholder="Filter By"
              options={[
                {
                  key: "Ac",
                  value: "All",
                  text: "All",
                },
                {
                  key: "Al",
                  value: "ac_related",
                  text: "AC Related",
                },
                {
                  key: "Body",
                  value: "body_related",
                  text: "Body Related",
                },
                {
                  key: "Electrical",
                  value: "electrical_related",
                  text: "Electrical Related",
                },
                {
                  key: "Engine",
                  value: "engine_related",
                  text: "Engine Related",
                },
                {
                  key: "Interior",
                  value: "interior_related",
                  text: "Interior Related",
                },

                {
                  key: "Steering",
                  value: "steering_related",
                  text: "Steering Related",
                },
                {
                  key: "Suspension",
                  value: "suspension_related",
                  text: "Suspension Related",
                },
                {
                  key: "tire",
                  value: "tire_related",
                  text: "Tire & Brakes Related",
                },
                {
                  key: "transmission",
                  value: "transmission_related",
                  text: "Transmission Related",
                },
                {
                  key: "other",
                  value: "other_related",
                  text: "Other Related",
                },
              ]}
              selection
              value={filter}
              onChange={(e, { value }) => {
                setState({ ...state, filter: value });
              }}
            />
          </div>
        </div>
        <br />
        <br />

        <table className="table-striped">
          <thead>
            <tr>
              <th>
                Date{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("date")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("date")}
                ></i>
              </th>
              <th>Service </th>
              <th>Labor </th>
              <th>Tax </th>
              <th>
                Cost{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("maintcost")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("maintcost")}
                ></i>
              </th>
              <th>
                Odometer{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("odometer")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("odometer")}
                ></i>
              </th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {maintRecords.map((record, i) => {
              if (filter == "ac_related" && !record.ac_related) return;

              if (filter == "body_related" && !record.body_related) return;

              if (filter == "engine_related" && !record.engine_related) return;

              if (filter == "electrical_related" && !record.electrical_related)
                return;

              if (filter == "tire_related" && !record.tyre_brake_related)
                return;

              if (filter == "interior_related" && !record.interior_related)
                return;

              if (
                filter == "transmission_related" &&
                !record.transmission_related
              )
                return;

              if (filter == "steering_related" && !record.steering_related)
                return;

              if (filter == "suspension_related" && !record.suspension_related)
                return;

              if (filter == "other_related" && !record.other_related) return;

              return i < currentPage * 10 && i >= (currentPage - 1) * 10 ? (
                <tr key={record.id}>
                  <td data-label="Date">
                    {moment(record.date.split("T")[0]).format("DD-MMM-YYYY")}
                  </td>
                  <td data-label="Service">
                    {getMaintenanceType({
                      engine_related: record.engine_related,
                      electrical_related: record.electrical_related,
                      ac_related: record.ac_related,
                      tyre_brake_related: record.tyre_brake_related,
                      transmission_related: record.transmission_related,
                      interior_related: record.interior_related,
                      body_related: record.body_related,
                      steering_related: record.steering_related,
                      suspension_related: record.suspension_related,
                    })}
                  </td>

                  <td data-label="Labor Cost">
                    {" "}
                    {resolveCurrency(units.currencyunit)}
                    {record.labour_cost}
                  </td>
                  <td data-label="Tax Cost">
                    {" "}
                    {resolveCurrency(units.currencyunit)}
                    {record.tax_cost}
                  </td>
                  <td data-label="Cost">
                    {resolveCurrency(units.currencyunit)}{" "}
                    {record.total_cost &&
                      parseInt(record.total_cost)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td data-label="Odometer">
                    {record.odometer &&
                      record.odometer
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td data-label="Action">
                    <i
                      title="Edit Maintenance"
                      className="mdi mdi-pencil"
                      onClick={() => {
                        console.log(record);
                        setState({
                          ...state,
                          maintenanceReport: record,

                          maintenanceReportId: record.id,
                          showEditDialog: true,
                        });
                      }}
                    ></i>
                    <i
                      title="View Maintenance"
                      className=" mdi mdi-eye ml-3"
                      onClick={() =>
                        setState({
                          ...state,
                          maintenanceReport: record,

                          maintenanceReportId: record.id,
                          showViewDialog: true,
                        })
                      }
                    ></i>

                    <i
                      title="Delete Maintenance"
                      className=" mdi mdi-delete ml-3"
                      onClick={() => {
                        setState({
                          ...state,
                          maintenanceReportId: record.id,
                          showDeleteDialog: true,
                        });
                      }}
                    ></i>
                  </td>
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
        <br />
        <nav aria-label="Page navigation example" style={{ float: "right" }}>
          <ul class="pagination">{renderPagination()}</ul>
        </nav>

        <EditMaintenanceDialog
          showEditDialog={showEditDialog}
          closeEditDialog={getAllMaintenanceHistory}
          maintenanceReportId={maintenanceReportId}
          units={units}
          vehicle={vehicle}
          maintenanceReport={maintenanceReport}
        />

        <EditMaintenanceDialog
          showEditDialog={showViewDialog}
          closeEditDialog={() =>
            setState({ ...state, showViewDialog: !showViewDialog })
          }
          maintenanceReportId={maintenanceReportId}
          units={units}
          vehicle={vehicle}
          maintenanceReport={maintenanceReport}
          viewOnly={true}
        />

        <DeleteMaintenanceDialog
          showDelete={showDeleteDialog}
          closeDeleteDialog={getAllMaintenanceHistory}
          maintenanceReportId={maintenanceReportId}
          vehicle={vehicle}
        />
      </div>
    </div>
  );
};

export default MaintenanceHistoryTable;
