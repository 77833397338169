import React, { useState, useEffect } from "react";
import { API } from "../../../../config";
import resolveCurrency from "../../../../helpers/Helper";
import moment from "moment";
import EditExpenseDialog from "./EditExpenseDialog";
import DeleteExpenseDialog from "./DeleteExpenseDialog";
import { getAllExpenseFormService } from "../../../../services/forms/expense-form-service";

const ExpenseLogbook = ({ vehicle, units }) => {
  const [state, setState] = useState({
    loading: true,
    expenseReports: [],
    paginations: 0,
    currentPage: 1,
    showEditDialog: false,
    showViewDialog: false,

    showDeleteDialog: false,
    error: null,
    selectedExpenseReport: null,
  });
  const {
    loading,
    expenseReports,
    paginations,
    currentPage,

    showViewDialog,
    showEditDialog,
    showDeleteDialog,
    error,
    selectedExpenseReport,
  } = state;

  useEffect(() => {
    getAllExpense();
  }, [vehicle]);
  const getAllExpense = () => {
    const token = localStorage.getItem("token");
    setState({
      ...state,
      loading: true,
      showEditDialog: false,
      showDeleteDialog: false,
      error: null,
    });
    getAllExpenseFormService(vehicle.id)
      .then((data) => {
        setState({
          ...state,
          loading: false,
          expenseReports: data.expenseReports,
          paginations: Math.abs(data.expenseReports.length / 10) + 1,
          showEditDialog: false,
          showDeleteDialog: false,
          error: null,
        });
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          loading: false,
          error: "Unable to fetch your Expense History at this time.",
        });
      });
  };
  const renderPagination = () => {
    let content = [];
    for (let i = 1; i <= paginations; i++) {
      content.push(
        <a
          onClick={() => setState({ ...state, currentPage: i })}
          class="page-link"
          style={
            currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
      );
    }
    return <> {content} </>;
  };

  const sortByPropertyAsc = (propertyName) => {
    function compare(v1, v2) {
      if (propertyName === "maintCost") {
        const valueA = parseFloat(v1["maintCost"]);
        const valueB = parseFloat(v2["maintCost"]);
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      }

      const valueA =
        typeof v1[propertyName] === "string"
          ? v1[propertyName].toUpperCase()
          : v1[propertyName];
      const valueB =
        typeof v2[propertyName] === "string"
          ? v2[propertyName].toUpperCase()
          : v2[propertyName];

      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      return comparison;
    }
    const values = expenseReports;
    values.sort(compare);
    setState({ ...state, expenseReports: values });
  };

  const sortByPropertyDesc = (propertyName) => {
    function compare(v1, v2) {
      if (propertyName === "cost") {
        const valueA = parseFloat(v1["cost"]);
        const valueB = parseFloat(v2["cost"]);
        console.log(v1);
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      } else {
        const valueA =
          typeof v1[propertyName] === "string"
            ? v1[propertyName].toUpperCase()
            : v1[propertyName];
        const valueB =
          typeof v2[propertyName] === "string"
            ? v2[propertyName].toUpperCase()
            : v2[propertyName];

        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      }
    }
    const values = expenseReports;
    values.sort(compare);
    setState({ ...state, expenseReports: values.reverse() });
  };

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h2 class="header-title">Expense History</h2>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card">
        <div className="card-body">
          <h2 class="header-title">Expense History</h2>
          <div
            class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
            role="alert"
          >
            <strong>Error - </strong> {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <a href="/dashboard/expense">
        <div
          class="btn btn-primary text-center "
          role="alert"
          style={{ width: "100%" }}
        >
          Add Expense
        </div>
      </a>
      <div className="card-body">
        <h2>Expense History</h2>
        <p>Your vehicle's expense history.</p>
        <table className="table-striped">
          <thead>
            <tr>
              <th>
                Date{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("date")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("date")}
                ></i>
              </th>

              <th>
                Expense{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("type")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("type")}
                ></i>
              </th>
              <th>
                Odometer{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("odometer")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("odometer")}
                ></i>
              </th>
              <th>
                Cost{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("expcost")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("expcost")}
                ></i>
              </th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {expenseReports.map((record, i) => {
              return i < currentPage * 10 && i >= (currentPage - 1) * 10 ? (
                <tr key={record.id}>
                  <td data-label="Date">
                    {moment(record.date.split("T")[0]).format("DD-MMM-YYYY")}
                  </td>

                  <td data-label="type">{record.type}</td>

                  <td data-label="Odometer">{record.odometer}</td>
                  <td data-label="Cost">
                    {resolveCurrency(units.currencyunit)}{" "}
                    {record.expcost &&
                      parseInt(record.expcost)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>

                  <td data-label="Action">
                    <i
                      title="Edit Insurance"
                      className="mdi mdi-pencil"
                      onClick={() =>
                        setState({
                          ...state,
                          selectedExpenseReport: record,
                          showEditDialog: true,
                        })
                      }
                    ></i>

                    <i
                      title="Delete Insurance"
                      className=" mdi mdi-delete ml-3"
                      onClick={() =>
                        setState({
                          ...state,
                          selectedExpenseReport: record,
                          showDeleteDialog: true,
                        })
                      }
                    ></i>
                    {record.attachment && record.attachment != "null" && (
                      <a href={record.attachment} target="_blank">
                        <i
                          title="View / Download Attachment"
                          className=" mdi mdi-file-multiple mx-3"
                        ></i>
                      </a>
                    )}
                  </td>
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
        <br />
        <nav aria-label="Page navigation example" style={{ float: "right" }}>
          <ul class="pagination">{renderPagination()}</ul>
        </nav>
        {selectedExpenseReport && (
          <>
            <EditExpenseDialog
              closeEditDialog={getAllExpense}
              units={units}
              showEditDialog={showEditDialog}
              expenseReport={selectedExpenseReport}
              vehicle={vehicle}
            />

            <DeleteExpenseDialog
              closeDeleteDialog={getAllExpense}
              showDelete={showDeleteDialog}
              vehicle={vehicle}
              revenueReport={selectedExpenseReport}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ExpenseLogbook;
