import { Slider } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import Chart from "react-apexcharts";

const BatteryPredictor = () => {
  const [state, setState] = useState({
    percent100Charge: 0,
    milesPerCycle: 0,
    odometer: 0,
    loading: false,
    options: {
      colors: ["#00274c"],
      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -10,
              show: false,
              color: "#000",
              fontSize: "0px",
            },
            value: {
              color: "#000000",
              fontSize: "44px",
              show: true,
            },
          },
        },
      },
      labels: [" "],
    },
    series: [0],
    isSubscribed: false,
    showSubscriptionMessage: false,
  });
  const {
    percent100Charge,
    milesPerCycle,
    odometer,
    options,
    series,
    isSubscribed,
    showSubscriptionMessage,
  } = state;

  useEffect(() => {
    let mounted = true;
    const user = JSON.parse(localStorage.getItem("user"));

    const getSubscriptionStatus = async () => {
      try {
        const res = await fetch(`${API}/subscription/${user.subscription_id}`, {
          method: "GET",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const data = await res.json();

        console.log(data);

        setState({ isSubscribed: data.isSubscribed });
      } catch (err) {}
    };

    if (mounted) {
      getSubscriptionStatus();
    }

    return function () {
      mounted = false;
    };
  }, []);

  const onSubmit = async () => {
    try {
      if (!isSubscribed) {
        return setState({ ...state, showSubscriptionMessage: true });
      }

      const res = await fetch(`${API}/ai/battery-predict`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ percent100Charge, milesPerCycle, odometer }),
      });
      const data = await res.json();
      console.log(data);
      setState({ ...state, series: [data.data.prediction] });
    } catch (err) {}
  };

  return (
    <div className="text-dark">
      {showSubscriptionMessage && (
        <div
          class="alert alert-info alert-dismissible fade show text-center"
          role="alert"
          style={{ width: "100%" }}
        >
          This is a premium feature, Please upgrade your subscription to access
          this.{" "}
          <a href="/pricing" className="text-danger">
            Click Here
          </a>{" "}
          to upgrade
        </div>
      )}
      <div className="card card-body">
        <h4>Battery Predictor</h4>

        <div className="my-3 row">
          <div className="col-lg-7 p-3">
            <label>% of Overcharging</label>
            <div className="row">
              <div className="col-lg-9">
                <Slider
                  value={percent100Charge}
                  onChange={(e, val) => {
                    setState({ ...state, percent100Charge: val });
                  }}
                  aria-labelledby="continuous-slider"
                />
              </div>
              <div className="col-lg-3">
                <div class="form-group">
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder="% of Overcharging"
                      value={percent100Charge}
                      onChange={(e) => {
                        setState({
                          ...state,
                          percent100Charge: e.target.value,
                        });
                      }}
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <label>Cycle Count</label>
            <div className="row">
              <div className="col-lg-9">
                <Slider
                  max={4000}
                  value={milesPerCycle}
                  onChange={(e, val) => {
                    setState({ ...state, milesPerCycle: val });
                  }}
                  aria-labelledby="continuous-slider"
                />
              </div>
              <div className="col-lg-3">
                <div class="form-group">
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder="Kilometers Per Cycle"
                      value={milesPerCycle}
                      onChange={(e) => {
                        setState({ ...state, milesPerCycle: e.target.value });
                      }}
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        cycle
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <label>Odometer</label>
            <div className="row">
              <div className="col-lg-9">
                <Slider
                  max={250000}
                  value={odometer}
                  onChange={(e, val) => {
                    setState({ ...state, odometer: val });
                  }}
                  aria-labelledby="continuous-slider"
                />
              </div>
              <div className="col-lg-3">
                <div class="form-group">
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder="Odometer"
                      value={odometer}
                      onChange={(e) => {
                        setState({ ...state, odometer: e.target.value });
                      }}
                    />
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        km
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className="btn btn-primary" onClick={onSubmit}>
              Predict
            </button>
          </div>
          <div className="col-lg-5 ">
            <Chart options={options} series={series} type="radialBar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatteryPredictor;
