import moment from "moment"
import React, { useEffect, useState } from "react"
import { Dropdown } from "semantic-ui-react"
import {CSVLink} from 'react-csv'
import TeslaFleetService from "../../../services/connected-cars/tesla-fleet-service"
export default function SuperchargerTracking() {

  const [state, setState] = useState({
    _loading: true,
    _data: [],
    _currentPage: 0,
    _totalPages: 1,
    _rowsPerPage: 10,
    _tripCsv: [
      [
        "VIN",
        "Entry Time",
        "Visit Duration (mins)",
        "Charge Duration (mins)",
        "Idle Duration (mins)",
        "Location"
      ],
    ],
  })

  const { _loading, _data, _totalPages, _currentPage, _rowsPerPage, _tripCsv } = state

  useEffect(() => {
    _init();
  }, [])



  const _init = async () => {
    try {
      setState({ ...state, _loading: true });

      const data = await TeslaFleetService.getSuperchargerTriggers();
      
      data.result.forEach(e => _tripCsv.push(
       [ e.vin,
        moment.unix(e.entry_timestamp).utc().add(4, "hours").format("DD-MMM-YYYY HH:mm"),
        parseInt(e.visit_duration / 60),
        parseInt(e.charging_time / 60),
        parseInt((e.visit_duration - e.charging_time) / 60),
        `https://maps.google.com/?q=${e.lat},${e.long}`
        ,]
      ))

      setState({ ...state, _loading: false, _data: data.result, _totalPages: Math.ceil(data.result.length / 10), _tripCsv });
    } catch (err) {

    }
  }

  const _renderTableData = () => {
    let content = [];
    let startIdx = _currentPage * _rowsPerPage;
    let endIdx = _currentPage * _rowsPerPage + 9;


    _data.forEach((e, idx) => {
      if (idx >= startIdx && idx <= endIdx)
        content.push(<tr>
          <td>{e.vin}</td>
          <td>{moment.unix(e.entry_timestamp).utc().add(4, "hours").format("DD-MMM-YYYY HH:mm")}</td>
          <td>{parseInt(e.visit_duration / 60)} mins</td>
          <td>{parseInt(e.charging_time / 60)} mins</td>
          <td>{parseInt((e.visit_duration - e.charging_time) / 60)} mins</td>
          <td>
            <a target="_blank" href={`https://maps.google.com/?q=${e.lat},${e.long}`} style={{ color: "blue" }}>View</a>
          </td>
        </tr>)
    })

    return content;

  }

  return <div className="card card-body">
    <h3>Supercharger Tracking</h3>
    <CSVLink
      data={_tripCsv}
      className="btn btn-primary my-2"
      style={{ width: "15%" }}
      filename={`fleet_report.csv`}
    >
      Download Data
    </CSVLink>
    <table className="table-striped mt-3">
      <thead>
        <tr>
          <th>VIN</th>
          <th>Entry Time</th>
          <th>Visit Duration</th>
          <th>Charge Duration</th>
          <th>Idle Duration</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        {_loading ? <div className="spinner spinner-border"></div> : _renderTableData()}
      </tbody>
    </table>

    <div className="row mt-3">
      <div className="col-lg-10"> </div>
      <div className="col-lg-2">
        <label className="mr-2">Page </label>
        <Dropdown

          search
          selction
          value={_currentPage + 1}

          options={function () {
            let ops = [];
            for (let i = 0; i <= _totalPages; i++) {
              ops.push({
                key: i,
                value: i + 1,
                text: i + 1
              })
            }
            return ops
          }()}
          onChange={(e, { value }) => setState({ ...state, _currentPage: value })}

        />
      </div>
    </div>

  </div>
}