import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { API, firebaseConfig } from "../../config";
import * as firebase from "firebase";
import queryString from "query-string";
import OtpInput from "react-otp-input";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import WebRoutes from "../../web-routes";
import PhoneInput from "react-phone-input-2";
const mixpanel = require("mixpanel-browser");

const Login = (props) => {
  const captchaRef = useRef(null);

  const [state, setState] = useState({
    username: "",
    _email: null,
    _password: "",
    _agreeToTnC: false,
    _loading: false,
    _success: false,
    _error: false,
    _showPhoneInput: false,

    _rememberMe: false,
    _verificationId: null,
    _phoneHint: null,
    _onSuccessRedirectTo: null,
    _showOtpForm: false,
    _resolver: null,
    _oAuthLoading: false,
    _phone: null,
    _otpConfirmer: null,
    verificationCode: null,
  });
  const {
    _email,
    _password,
    _loading,
    _oAuthLoading,
    _verificationId,
    _resolver,
    _phoneHint,
    _showOtpForm,
    _success,
    _error,
    _showPhoneInput,
    _rememberMe,
    _phone,
    _onSuccessRedirectTo,
    _otpConfirmer,
    verificationCode,
  } = state;

  // * Initial function
  // * Checks for remember me logic

  useEffect(() => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const rememberMe = localStorage.getItem("_rememberMe");
    const onSuccessRedirectTo = new URLSearchParams(props.location.search).get(
      "onSuccessRedirectTo"
    );

    // set the refferer based on the query param
    let params = queryString.parse(props.location.search);
    let refId = params.referrer;
    if (refId) localStorage.setItem("referrer", refId);

    setState({
      ...state,
      _onSuccessRedirectTo: onSuccessRedirectTo,
      _success: token && user && rememberMe === "true" ? true : false,
    });

    // if no token or user and remember me then clear local storage
    if (!(token && user && rememberMe === "true")) {
      localStorage.clear();
    }
  }, []);

  // * Change handler
  const _handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  // * Exchanges idToken for authToken
  const _informBackendAboutLogin = async () => {
    try {
      // get the id token of current user
      const idToken = await firebase.auth().currentUser.getIdToken();

      // this request will exchange id token for the authToken
      const res = await fetch(`${API}/login-email-password`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idToken,
          email:  _email,
          phoneNo: _phone
        }),
      });

      const data = await res.json();

      // if success
      if (data.done) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));
        mixpanel.track("user_login", { uid: data.user.uid });
        if (_rememberMe) {
          localStorage.setItem("_rememberMe", true);
        }

        setState({ ...state, _loading: false, _error: null, _success: true });
      } else {
        // failure
        setState({
          ...state,
          _error: true,

          loading: false,
        });
      }
    } catch (err) {
      setState({ ...state, _loading: false, _error: true });
    }
  };

  const _initiatePhoneLogin = async (e) => {
    try {
      e.preventDefault()
      setState({...state, _loading: true})
      if (!firebase.apps.length)
        var firebaseApp = new firebase.initializeApp(firebaseConfig);
      console.log("voisd")
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        captchaRef.current,
        {
          size: "invisible",
        }
      );

      firebase
        .auth()
        .signInWithPhoneNumber( `+${_phone}`, appVerifier)
        .then((confirmationResult) => {
          setState({...state, _loading: false, _showOtpForm: true, _otpConfirmer: confirmationResult})
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const _verifyOtp = async (e) => {
    try {
      e.preventDefault()
      setState({...state, _loading : true})
      _otpConfirmer.confirm(verificationCode).then((result) => {
        _informBackendAboutLogin();
      }).catch((error) => {
      console.log(error)
      });
      setState({...state, _loading : true})
    } catch(err) {
      console.log(err)
    }
  }



  // * logs in using firebase
  const _attemptLogin = async (e) => {
    try {
      e.preventDefault();

      setState({ ...state, _loading: true });

      // initialise firebase if required
      if (!firebase.apps.length)
        var firebaseApp = new firebase.initializeApp(firebaseConfig);

      // const appVerifier = new firebase.auth.RecaptchaVerifier(
      //   captchaRef.current,
      //   {
      //     'size': 'normal',
      //     'callback': function (response) {
      //       // reCAPTCHA solved, allow signInWithPhoneNumber.
      //       // ...
      //     },
      //     'expired-callback': function () {
      //       // Response expired. Ask user to solve reCAPTCHA again.
      //       // ...
      //     }
      //  }

      // );

      // appVerifier.render()

      // console.log(appVerifier)

      // * login with email and password
      await firebase.auth().signInWithEmailAndPassword(_email, _password);
      // no error means success, exhange id token for auth token
      _informBackendAboutLogin();
    } catch (err) {
      console.log(err);

      if (err.code == "auth/multi-factor-auth-required") {
        let resolver = err.resolver;
        const appVerifier = new firebase.auth.RecaptchaVerifier(
          captchaRef.current,
          {
            size: "invisible",
          }
        );
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        const phone = resolver.hints[0].phoneNumber;
        // Send SMS verification code.
        phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, appVerifier)
          .then(function (_verificationId) {
            setState({
              ...state,
              _resolver: resolver,
              _verificationId,
              _showOtpForm: true,
              _phoneHint: phone.substr(phone.length - 4),
              _loading: false,
            });
          });

        // console.log(resolver);
      } else if (err.code == "auth/wrong-password") {
        setState({ ...state, _error: true, _loading: false });
      } else {
        setState({ ...state, _error: true, _loading: false });
      }
    }
  };

  // const _verifyOtp = async (e) => {
  //   try {
  //     e.preventDefault();
  //     setState({ ...state, _loading: true });
  //     var cred = firebase.auth.PhoneAuthProvider.credential(
  //       _verificationId,
  //       verificationCode
  //     );
  //     var multiFactorAssertion =
  //       firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
  //     await _resolver.resolveSignIn(multiFactorAssertion);

  //     _informBackendAboutLogin();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const _signInUsingOauth = async (type, e) => {
    try {
      e.preventDefault();
      setState({ ...state, _oAuthLoading: true });
      if (!firebase.apps.length)
        var firebaseApp = new firebase.initializeApp(firebaseConfig);

      let provider;

      if (type == "google") provider = new firebase.auth.GoogleAuthProvider();
      else provider = new firebase.auth.FacebookAuthProvider();

      firebase.auth().useDeviceLanguage();

      const result = await firebase.auth().signInWithPopup(provider);
      console.log(result);
      // This gives you a Google Access Token. You can use it to access the Google API.

      // The signed-in user info.
      var user = result.user;

      const idToken = await user.getIdToken();
      console.log(idToken);

      const res = await fetch(`${API}/login-google-oauth`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idToken,
          email: user.email,
          uid: user.uid,
        }),
      });

      const data = await res.json();
      console.log(data);
      if (data.done) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));

        if (_rememberMe) {
          localStorage.setItem("_rememberMe", true);
        }

        setState({
          ...state,
          _oAuthLoading: false,
          _error: null,
          _success: true,
        });
      } else {
        setState({
          ...state,
          _error: true,

          _oAuthLoading: false,
        });
      }
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        _error: true,

        _oAuthLoading: false,
      });
    }
  };

  // on success redirect to the specified url or to dashboard by if nothing is specified
  if (_success) {
    if (_onSuccessRedirectTo) {
      return <Redirect to={`/${_onSuccessRedirectTo}`} />;
    } else {
      return <Redirect to="/dashboard/overview" />;
    }
  }

  return (
    <div className="card-body p-4">
      <div className="text-center w-75 m-auto">
        <h4
          id="login_title"
          className="text-dark-50 text-center mt-0 font-weight-bold"
        >
          {!_showOtpForm ? "Sign In" : "Please Verify the OTP"}
        </h4>
        <p className="text-muted mb-2">
          {!_showOtpForm
            ? "Enter your credentials to login"
            : "  A Verification Code has been sent to your number +" +
              _phone}
        </p>
      </div>
      {/* <p
        className="text-muted text-center mb-4"
        onClick={() =>
          setState({ ...state, _showPhoneInput: !_showPhoneInput })
        }
      >
        <span style={{ color: "blue", cursor: "pointer" }}>Click Here</span> to
        login via {_showPhoneInput ? "Email" : "Phone"}
      </p> */}
      <form onSubmit={_showOtpForm ? _verifyOtp : _showPhoneInput ? _initiatePhoneLogin : _attemptLogin}>
        {_error && (
          <div
            id="login_error_text"
            className="alert alert-danger"
            role="alert"
          >
            Incorrect Email or Password was entered.
          </div>
        )}

        {!_showOtpForm ? (
          !_showPhoneInput ? (
            <>
              <div className="form-group">
                <label for="emailaddress">Email address</label>
                <input
                  className="form-control"
                  type="email"
                  id="login_email_textfield"
                  required="true"
                  placeholder="Enter your email"
                  name="_email"
                  value={_email}
                  onChange={_handleChange}
                />
              </div>
              <div className="form-group">
                <label for="password">Password</label>
                <div className="input-group input-group-merge">
                  <input
                    type="password"
                    id="login_password_textfield"
                    className="form-control"
                    placeholder="Enter your password"
                    name="_password"
                    value={_password}
                    onChange={_handleChange}
                  />
                  <div className="input-group-append" data-password="false">
                    <div
                      className="input-group-text"
                      onClick={() => {
                        const passwordInp = document.getElementById(
                          "login_password_textfield"
                        );
                        if (passwordInp.type === "text") {
                          passwordInp.type = "password";
                        } else {
                          passwordInp.type = "text";
                        }
                      }}
                    >
                      <span className="password-eye"></span>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="custom-control custom-checkbox"
                    style={{ display: "inline-block" }}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="checkbox-signin"
                      onChange={(e) =>
                        setState({
                          ...state,
                          _rememberMe: !_rememberMe,
                        })
                      }
                    />
                    <label
                      className="custom-control-label"
                      for="checkbox-signin"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <a
                    href={WebRoutes.FORGOT_PASSWORD}
                    className="text-muted my-2 float-right "
                  >
                    Forgot Password ?
                  </a>
                </div>
              </div>
            </>
          ) : (
            <>
              <PhoneInput
                containerStyle={{ width: "100%" }}
                inputStyle={{ width: "100%" }}
                country={"us"}
                
                onChange={(phone) => {
                  console.log(phone)
                  setState({
                    ...state,
                    _phone: phone,
                  });
                }}
              />
            </>
          )
        ) : (
          <OtpInput
            numInputs={6}
            value={verificationCode}
            separator={<span>-</span>}
            onChange={(otp) => {
              setState({ ...state, verificationCode: otp });
            }}
            inputStyle={{
              display: "block",
              width: "100%",
              height: "calc(2.25rem + 2px)",
              padding: "0.45rem 0.9rem",
              fontSize: "0.9rem",
              fontWeight: "400",
              lineHeight: "1.5",
              color: "#6c757d",
              backgroundColor: "#fff",
              backgroundClip: "padding-box",
              border: "1px solid #dee2e6",
              borderRadius: "0.25rem",
            }}
          />
        )}
        <div id="recaptcha-container" ref={captchaRef}></div>

        <div className="mt-3 form-group mb-0 my-3 text-center ">
          <input
            id="login_email_submit"
            onSubmit={() => {
              console.log(_showPhoneInput)
            }}
            type="submit"
            className="btn btn-lg btn-outline-primary px-5"
            style={{ width: "100%" }}
            value={_loading ? "Loading..." : "Login"}
          ></input>

          <br />
          <br />

          <div className="separator">OR</div>

          <button
            className="mt-3 btn btn-outline-dark"
            role="button"
            onClick={(e) => _signInUsingOauth("google", e)}
          >
            <img
              width="20px"
              style={{ marginBottom: "3px", marginRight: "5px" }}
              alt="Google sign-in"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
            />
            {_oAuthLoading ? (
              <>
                {" "}
                <span
                  className="spinner-grow spinner-grow-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...{" "}
              </>
            ) : (
              "Login with Google"
            )}
          </button>
          <br />
          <button
            className="mt-3 btn btn-outline-light text-light"
            role="button"
            onClick={(e) => _signInUsingOauth("facebook", e)}
            style={{ backgroundColor: "#354c8c" }}
          >
            <img
              width="20px"
              style={{ marginBottom: "3px", marginRight: "5px" }}
              alt="Google sign-in"
              src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png"
            />
            {_oAuthLoading ? (
              <>
                {" "}
                <span
                  className="spinner-grow spinner-grow-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...{" "}
              </>
            ) : (
              "Login with Facebook"
            )}
          </button>
          <br />
        </div>
      </form>
    </div>
  );
};

export default Login;
