import React, { useEffect, Redirect, useState } from "react";
import { useHistory } from "react-router-dom";

const Landing = () => {
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // history.push("/login");
    redirectTo();
  }, []);

  const redirectTo = () => history.push("/login");

  if (redirect) return <Redirect to="/login" />;

  // there is no landing page for beta.keemut.com, it has been moved to keemut.com so redirect directly to /login
  return <div></div>;
};

export default Landing;
