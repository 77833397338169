import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import Chart from "react-apexcharts";
import { getMonthlyFuelDataService } from "../../../services/forms/fuel-form-service";

const FuelChart = ({ vehicle, units }) => {
  const [state, setState] = useState({
    options: {
      chart: {
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 5,
          color: "#00274c",
          opacity: 0.45,
        },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#00274c"],

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2.75,
        dashArray: 0,
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
    loading: false,
    fuelHistory: [],

    noData: false,
  });
  const { loading, fuelHistory, noData, options, series } = state;

  useEffect(() => {
    const _getFuelDataMonthly = async () => {
      try {
        setState({ ...state, loading: true, noData: true });

        const data = await getMonthlyFuelDataService(vehicle.id);

        if (!data.fuelReports || data.fuelReports.length < 1) {
          setState({ ...state, noData: true });
        } else {
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];

          const xaxisData = [];
          const yaxisData = [];
          data.fuelReports.sort(function (fd1, fd2) {
            let res = 0;
            if (fd1.year === fd2.year) {
              if (fd1.month < fd2.month) {
                return -1;
              } else {
                return 1;
              }
            } else {
              if (fd1.year < fd2.year) {
                return -1;
              } else {
                return 1;
              }
            }
          });
          data.fuelReports.forEach((dataPoint) => {
            xaxisData.push(
              `${monthNames[dataPoint.month - 1]} ${dataPoint.year}`
            );
            yaxisData.push((dataPoint.distance / dataPoint.sum).toFixed(2));
          });
          setState({
            ...state,
            loading: false,
            noData: false,
            options: {
              ...options,

              yaxis: {
                title: {
                  text: `Average Miles Per ${
                    units.fuelunit == "gal" ? "Gallon" : "Litre"
                  }`,
                },
              },
              xaxis: {
                type: "Month",
                categories: xaxisData,
                title: { text: "Month" },
              },
            },
            series: [
              {
                name:
                  "Average Miles Per " +
                  (units.fuelunit == "gal" ? "Gallons" : "Litres"),
                data: yaxisData,
              },
            ],
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    _getFuelDataMonthly();
  }, [vehicle]);

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h4 class="header-title">Fuel History</h4>
          <p>Breakdown of Fuel History</p>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }
  if (noData) {
    return (
      <div className="card" style={{ height: "100%" }}>
        <div className="card-body">
          <h4 class="header-title">Fuel History</h4>

          <p>Breakdown of Fuel History</p>
          <br />
          <p>
            Oops, Seems like we don't have any fuel history for this car. Head
            to Forms, Fuel Reports to Add a new record.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="card" style={{ height: "100%" }}>
      <div className="card-body">
        <h4>Fuel History</h4>
        <p>Breakdown of Fuel History</p>
        <Chart options={options} series={series} />
      </div>
    </div>
  );
};

export default FuelChart;
