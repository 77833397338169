import simpleFetch from "../helpers/fetch-wrapper";

/**
 * @author Hemang Bhogayata
 * @description Get the makes by countr, default country to usa
 * @param {String} country
 */
export async function getMakesService(country) {
  try {
    return await simpleFetch(`/vehicle-list/make?country=${country || "USA"}`);
  } catch (err) {
    console.log(err);
  }
}

/**
 * @author Hemang Bhogayata
 * @description Get the model list by make, year and country
 * @param {String} make
 * @param {Number} year
 * @param {String} country
 * @returns
 */
export async function getModelsService(make, year, country) {
  try {
    country = country || "USA";

    return await simpleFetch(
      `/vehicle-list/model?make=${make}&country=${country}&year=${year}`
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Get the trims by make model year country
 * @param {String} make
 * @param {String} model
 * @param {Number} year
 * @param {String} country
 */
export async function getTrimsService(make, model, year, country) {
  try {
    country = country || "USA";

    return await simpleFetch(
      `/vehicle-list/trim?model=${model}&country=${country}&make=${make}&year=${year}`
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 *
 * @param {String} make
 * @param {String} model
 * @param {String} trim
 * @param {Number} year
 * @param {String} country
 * @returns
 */
export async function getMsrpByMMTYService(make, model, trim, year, country) {
  try {
    return await simpleFetch(
      `/vehicle-list/price?make=${make}&model=${model}&year=${year}&trim=${trim}&country=${country}`,
      "GET"
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 *
 * @param {String} make
 * @param {String} model
 * @param {String} trim
 * @param {Number} year
 * @param {String} country
 * @returns
 */
export async function getDrivetrainByMMTYService(
  make,
  model,
  trim,
  year,
  country
) {
  try {
    return await simpleFetch(
      `/vehicle-list/drivetrain?`,
      "POST",
      JSON.stringify({
        make,
        model,
        trim,
        year,
        country,
      })
    );
  } catch (err) {
    console.log(err);
  }
}
