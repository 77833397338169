import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import moment from "moment";
import EditFuelDialog from "./EditFuelDialog";
import resolveCurrency from "../../../helpers/Helper";
import { getAllFuelReportsService } from "../../../services/forms/fuel-form-service";

const FuelHistoryTable = ({ vehicle, units }) => {
  const [state, setState] = useState({
    loading: false,
    fuelHistory: [],
    paginations: 0,
    currentPage: 1,
    noData: false,
    fuelReportId: null,
    showEditDialog: false,
  });
  const {
    loading,
    fuelHistory,
    paginations,
    currentPage,
    noData,
    showEditDialog,
    fuelReportId,
  } = state;

  useEffect(() => {
    getFuelReports();
  }, [vehicle]);

  const getFuelReports = async () => {
    try {
      setState({
        ...state,
        loading: true,
        noData: true,
        showEditDialog: false,
      });

      const data = await getAllFuelReportsService(vehicle.id);
      if (!data.fuelReports || data.fuelReports.length < 1) {
        setState({ ...state, noData: true, showEditDialog: false });
      } else
        setState({
          ...state,
          fuelHistory: data.fuelReports,
          loading: false,
          showEditDialog: false,
          paginations: Math.ceil(data.fuelReports.length / 5),
        });
    } catch (err) {
      console.log(err);
    }
  };

  const renderPagination = () => {
    let content = [];
    for (let i = 1; i <= paginations; i++) {
      content.push(
        <a
          onClick={() => setState({ ...state, currentPage: i })}
          class="page-link"
          style={
            currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
      );
    }
    return <> {content} </>;
  };

  const sortByPropertyAsc = (propertyName) => {
    function compare(v1, v2) {
      const valueA =
        typeof v1[propertyName] === "string"
          ? v1[propertyName].toUpperCase()
          : v1[propertyName];
      const valueB =
        typeof v2[propertyName] === "string"
          ? v2[propertyName].toUpperCase()
          : v2[propertyName];

      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      return comparison;
    }
    const values = fuelHistory;
    values.sort(compare);
    setState({ ...state, fuelHistory: values });
  };

  const sortByPropertyDesc = (propertyName) => {
    function compare(v1, v2) {
      const valueA =
        typeof v1[propertyName] === "string"
          ? v1[propertyName].toUpperCase()
          : v1[propertyName];
      const valueB =
        typeof v2[propertyName] === "string"
          ? v2[propertyName].toUpperCase()
          : v2[propertyName];

      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      return comparison;
    }
    const values = fuelHistory;
    values.sort(compare);
    setState({ ...state, fuelHistory: values.reverse() });
  };

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h4 class="header-title">Fuel History</h4>
          <p>Breakdown of Fuel History</p>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }
  if (noData) {
    return (
      <div className="card">
        <div className="card-body">
          <h4 class="header-title">Fuel History</h4>
          <p>Breakdown of Fuel History</p>
          <br />
          <p>
            Oops, Seems like we don't have any fuel history for this car. Head
            to Forms, Fuel Reports to Add a new record.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="card" style={{ height: "100%" }}>
      <div className="card-body">
        <h4>Fuel History</h4>
        <p>Breakdown of Fuel History</p>
        <table className="table-striped">
          <thead>
            <tr>
              <th>
                Date{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("date")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("date")}
                ></i>
              </th>
              <th>
                Fuel Amount{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("amount")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("amount")}
                ></i>
              </th>
              <th>
                Cost{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("cost")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("cost")}
                ></i>
              </th>
              <th>
                Odometer{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("odometer")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("odometer")}
                ></i>
              </th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {fuelHistory.map((record, i) => {
              return i < currentPage * 5 && i >= (currentPage - 1) * 5 ? (
                <tr key={record.id}>
                  <td data-label="Date">
                    {moment(record.date).format("DD-MMM-YYYY")}
                  </td>
                  <td data-label="Fuel Amount">
                    {record.amount &&
                      record.amount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td data-label="Cost">
                    {resolveCurrency(units.currencyunit)}{" "}
                    {record.cost &&
                      record.cost
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td data-label="Odometer">
                    {record.odometer &&
                      record.odometer
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td data-label="Action">
                    <i
                      title="Edit Maintenance"
                      className="mdi mdi-pencil"
                      onClick={() =>
                        setState({
                          ...state,
                          fuelReportId: record.id,
                          showEditDialog: true,
                        })
                      }
                    ></i>

                    {record.attachment && (
                      <a href={record.attachment} target="_blank">
                        <i
                          title="View / Download Attachment"
                          className=" mdi mdi-file-multiple mx-3"
                        ></i>
                      </a>
                    )}
                  </td>
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
        <br />
        <nav aria-label="Page navigation example" style={{ float: "right" }}>
          <ul class="pagination pagination-rounded">{renderPagination()}</ul>
        </nav>

        <EditFuelDialog
          vehicle={vehicle}
          fuelReportId={fuelReportId}
          showEditDialog={showEditDialog}
          units={units}
          closeEditDialog={() => getFuelReports()}
        />
      </div>
    </div>
  );
};

export default FuelHistoryTable;
