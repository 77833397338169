import simpleFetch from "../helpers/fetch-wrapper";

/**
 * @author Hemang Bhogayata
 * @description Get user object
 * @param {String} uid
 * @returns
 */
export async function getUserService(uid) {
  try {
    return await simpleFetch(`/users/${uid}`, "GET");
  } catch (err) {
    console.log(err);
  }
}
