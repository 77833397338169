import React, { useEffect, useState } from "react";
import { API } from "../../config";

/** Show a banner on top of a page if user is not subscribed asking them to upgrade */
const SubscribeBanner = ({ show7dayMessage }) => {
  const [state, setState] = useState({
    loading: true,
    subscriptionStatus: null,
    show: true,
  });
  const { loading, subscriptionStatus, show } = state;

  useEffect(() => {
    let mounted = true;

    const checkSubscriptionStatus = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = localStorage.getItem("token");

        if (user.subscription_id || user.uid) {
          const res = await fetch(
            `${API}/subscription/${user.subscription_id || user.uid}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data = await res.json();

          // console.log(amount);

          setState({
            ...state,
            loading: false,
            subscriptionStatus: data.isSubscribed,
          });
        } else {
          setState({
            ...state,
            loading: false,
            subscriptionStatus: false,
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (mounted) {
      checkSubscriptionStatus();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  if (loading || subscriptionStatus || !show) return <> </>;

  if (!loading && !subscriptionStatus && show)
    return (
      <div
        class="alert alert-primary text-center"
        role="alert"
        style={{ width: "100%" }}
      >
        {show7dayMessage
          ? "On a free subscription, you will only be able to see a 7 day history. To see your all time history, "
          : "You are on a Free subscription to get access to all features,"}
        <a href="/pricing" className="text-danger">
          {" "}
          Upgrade Your Subscription
        </a>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={() => setState({ ...state, show: false })}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
};

export default SubscribeBanner;
