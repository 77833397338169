import React, { useState, useEffect } from "react";
import { API, site } from "../../../config";
import Dialog from "@material-ui/core/Dialog";
import { Dropdown } from "semantic-ui-react";
import moment from "moment";
import { getAllReminderFormService } from "../../../services/forms/reminder-form-service";

const ReminderTable = ({ vehicle, units }) => {
  const [state, setState] = useState({
    loading: true,
    showAddReminderDialog: false,
    reminders: [],
    addLoading: false,
    type: "insurance",
    event: null,

    currDate: new Date(),
    showConfirmDialog: false,
    completeLoading: false,
    selectedReminder: {},
    reminderId: null,

    // complete reminder form vars
    engineRelated: false,
    batteryRelated: false,
    acRelated: false,
    tyreBrakeRelated: false,
    transmissionRelated: false,
    bodyRelated: false,
    interiorRelated: false,
    steeringRelated: false,
    suspensionRelated: false,
    otherRelated: false,
    odometer: 0,
    cost: 0,
    date: null,
    expenseType: null,
    reminderCompleteData: null,
    renewalDate: null,
    vendor: null,

    paginations: 0,
    currentPage: 1,
  });
  const {
    loading,
    reminders,
    showAddReminderDialog,
    type,
    event,
    date,
    addLoading,
    currDate,
    showConfirmDialog,
    completeLoading,
    reminderId,
    selectedReminder,
    acRelated,
    batteryRelated,
    bodyRelated,
    cost,
    engineRelated,
    expenseType,
    interiorRelated,
    odometer,
    otherRelated,
    reminderCompleteData,
    renewalDate,
    steeringRelated,
    suspensionRelated,
    transmissionRelated,
    tyreBrakeRelated,
    vendor,
    paginations,
    currentPage,
  } = state;

  useEffect(() => {
    const getReminders = async () => {
      try {
        const token = localStorage.getItem("token");

        const data = await getAllReminderFormService(vehicle.id);

        setState({
          ...state,
          loading: false,
          reminders: data.reminders,
          paginations: Math.ceil(data.reminders.length / 5),
          odometer: data.odometer,
        });
      } catch (err) {}
    };

    let mounted = true;

    if (mounted) {
      getReminders();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [vehicle]);

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });
  const addReminder = (e) => {
    setState({ ...state, addLoading: true });
    const token = localStorage.getItem("token");
    e.preventDefault();
    fetch(`${API}/reminders/${vehicle.id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        date,
        type,
        event,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setState({ ...state, addLoading: false, showAddReminderDialog: false });
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const completeReminder = (e) => {
    setState({ ...state, completeLoading: true });
    const token = localStorage.getItem("token");
    e.preventDefault();
    fetch(`${API}/reminders/${vehicle.id}/${reminderId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        reminderType: selectedReminder.type,
        odometer,
        cost,
        date,
        engineRelated,
        batteryRelated,
        acRelated,
        tyreBrakeRelated,
        transmissionRelated,
        bodyRelated,
        interiorRelated,
        steeringRelated,
        suspensionRelated,
        otherRelated,
        renewalDate,
        vendor,
        expenseType: type,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setState({
          ...state,
          completeLoading: false,
          showConfirmDialog: false,
        });
        localStorage.setItem("odoChange", true);
        window.location.reload(true);
      })
      .catch((err) => console.log(err));
  };

  const computeStatus = (date, expected_odometer) => {
    if (expected_odometer && expected_odometer < odometer) {
      return "Over Due";
    }

    if (!date) {
      return "Upcoming";
    }
    if (moment(date).isBefore(moment())) {
      return "Over Due";
    } else {
      return "Upcoming";
    }
  };

  const renderPagination = () => {
    let content = [];
    for (let i = 1; i <= paginations; i++) {
      content.push(
        <a
          onClick={() => setState({ ...state, currentPage: i })}
          class="page-link"
          style={
            currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
      );
    }
    return <> {content} </>;
  };

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-9">
              <h4 class="header-title">Upcoming Reminders</h4>
            </div>
            <div className="col-lg-3">
              <button className="btn btn-primary">Add </button>
            </div>
          </div>

          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="card" style={{ height: "100%" }}>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-10">
            <h3>Upcoming Reminders</h3>
          </div>
          <div className="col-lg-2">
            <button
              className="btn btn-primary"
              onClick={() =>
                setState({ ...state, showAddReminderDialog: true })
              }
              style={{ width: "100%" }}
            >
              Add
            </button>
          </div>
        </div>
        <br />
        {reminders.length === 0 && (
          <>
            {" "}
            <br /> Your maintenance reminders will be updated in within 24
            hours. Meanwhile you can add any custom reminders you want using the
            Add Button above.
          </>
        )}
        {reminders.length > 0 && (
          <table className="table-striped">
            <thead>
              <tr>
                {/* <th>Category</th> */}
                <th>Event</th>
                <th>Date</th>
                <th>Expected Odometer</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {reminders.map((reminder, i) => {
                return i < currentPage * 5 && i >= (currentPage - 1) * 5 ? (
                  <tr>
                    {/* <td data-label="Category">
          {reminder.type.charAt(0).toUpperCase() +
            reminder.type.slice(1)}
        </td> */}
                    <td data-label="Event">{reminder.event} </td>
                    <td data-label="Date">
                      {reminder.date
                        ? moment(reminder.date.split("T")[0]).format(
                            "DD-MMM-YYYY"
                          )
                        : "N/A"}
                    </td>
                    <td data-label="Expected Odometer">
                      {reminder.expected_odometer
                        ? reminder.expected_odometer
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "N/A"}
                    </td>

                    <td data-label="Status">
                      {" "}
                      <Dropdown
                        trigger={
                          <span
                            className={
                              computeStatus(
                                reminder.date,
                                reminder.expected_odometer
                              ) == "Upcoming"
                                ? "text-warning"
                                : "text-danger"
                            }
                          >
                            {computeStatus(
                              reminder.date,
                              reminder.expected_odometer
                            )}
                          </span>
                        }
                        floating
                        options={[
                          // {
                          //   key: "edit",
                          //   text: "Edit",
                          //   icon: "mdi mdi-pencil-box-multiple-outline",
                          //   value: "edit",
                          // },
                          // {
                          //   key: "delete",
                          //   text: "Delete",
                          //   icon: " mdi mdi-delete-alert",
                          //   value: "delete",
                          // },
                          {
                            key: "complete",
                            text: "Mark As Complete",
                            icon: "mdi mdi-check-bold",
                            value: "complete",
                          },
                        ]}
                        onClick={() =>
                          setState({
                            ...state,
                            reminderId: reminder.id,
                            selectedReminder: reminder,
                          })
                        }
                        onChange={(e, { value }) => {
                          if (value === "complete") {
                            if (reminder.event_category) {
                              window.location = `${site}/dashboard/maintenance-v2/?description=${reminder.event}&reminderCategory=${reminder.event_category}&reminderId=${reminder.id}`;
                            }
                            setState({ ...state, showConfirmDialog: true });
                          }
                        }}
                      />
                    </td>
                  </tr>
                ) : null;
              })}
            </tbody>
          </table>
        )}
        <br />
        <div style={{ width: "100%" }}>
          <nav aria-label="Page navigation example" style={{ float: "right" }}>
            <ul class="pagination pagination">{renderPagination()}</ul>
          </nav>
        </div>
        <Dialog
          open={showAddReminderDialog}
          onClose={() => setState({ ...state, showAddReminderDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div class="modal-content dialog">
            <div class="modal-header modal-colored-header bg-primary">
              <h4
                class="modal-title"
                id="primary-header-modalLabel"
                style={{ color: "#ffffff", fontFamily: "Roboto" }}
              >
                Add Reminder
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() =>
                  setState({ ...state, showAddReminderDialog: false })
                }
              >
                ×
              </button>
            </div>
            <div class="modal-body">
              <div className="p-3">
                <div className="form-group">
                  <label style={{ color: "#6c757d", fontFamily: "Roboto" }}>
                    Reminder Type
                  </label>

                  <select
                    class="form-control select2"
                    data-toggle="select2"
                    name="type"
                    value={type}
                    onChange={handleChange}
                  >
                    <option value="insurance">Insurance & Regulations</option>
                    <option value="maintenance">Maintenance</option>
                    <option value="expense">Other Expense</option>
                  </select>
                </div>

                <div class="form-group">
                  <label
                    className="overview-dialog-label"
                    style={{ color: "#6c757d", fontFamily: "Roboto" }}
                  >
                    Expected Date
                  </label>

                  <div className="input-group">
                    <input
                      type="date"
                      class="form-control"
                      name="date"
                      value={date}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label
                    className="overview-dialog-label"
                    style={{ color: "#6c757d", fontFamily: "Roboto" }}
                  >
                    Event Name
                  </label>

                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="basic-addon1"
                    placeholder="Change Oil"
                    name="event"
                    value={event}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-light"
                data-dismiss="modal"
                onClick={() =>
                  setState({ ...state, showAddReminderDialog: false })
                }
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={addReminder}
              >
                {addLoading ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={showConfirmDialog}
          onClose={() => setState({ ...state, showConfirmDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div class="modal-content dialog">
            <div class="modal-header modal-colored-header bg-info">
              <h4
                class="modal-title"
                id="primary-header-modalLabel"
                style={{ color: "#ffffff", fontFamily: "Roboto" }}
              >
                Complete{" "}
                {selectedReminder.type === "maintenance"
                  ? "Maintenance"
                  : selectedReminder.type === "insurance"
                  ? "Insurance"
                  : "Expense"}
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => setState({ ...state, showConfirmDialog: false })}
              >
                ×
              </button>
            </div>
            <div class="modal-body">
              <div className="p-3">
                <div class="form-group">
                  <label style={{ color: "#6c757d", fontFamily: "Roboto" }}>
                    Enter date
                  </label>

                  <input
                    type="date"
                    class="form-control date"
                    id="birthdatepicker"
                    data-toggle="date-picker"
                    data-single-date-picker="true"
                    name="date"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group ">
                  <label style={{ color: "#6c757d", fontFamily: "Roboto" }}>
                    Enter Odometer
                  </label>
                  <div className="input-group">
                    {" "}
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter odometer"
                      name="odometer"
                      onChange={handleChange}
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">{units.distanceunit}</span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label style={{ color: "#6c757d", fontFamily: "Roboto" }}>
                    Amount Paid
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="inputGroupSelect01">
                        {units.currencyunit.toUpperCase()}
                      </label>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter how much you paid"
                      name="cost"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {selectedReminder.type === "maintenance" ? (
                  <>
                    {" "}
                    <label style={{ color: "#6c757d", fontFamily: "Roboto" }}>
                      Maintenance List
                    </label>
                    <div className="row">
                      <div className="col-lg-6">
                        <div
                          class="btn-group text-left "
                          data-toggle="buttons"
                          style={{ width: "100%" }}
                        >
                          <label class="btn btn-light">
                            <input
                              type="checkbox"
                              name="engineRelated"
                              onChange={() =>
                                setState({
                                  ...state,
                                  engineRelated: !engineRelated,
                                })
                              }
                            />{" "}
                            Engine / EV Related
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        {" "}
                        <div
                          class="btn-group "
                          data-toggle="buttons"
                          style={{ width: "100%" }}
                        >
                          <label class="btn btn-light">
                            <input
                              type="checkbox"
                              name="batteryRelated"
                              onChange={() =>
                                setState({
                                  ...state,
                                  batteryRelated: !batteryRelated,
                                })
                              }
                            />{" "}
                            Battery Related
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div
                          class="btn-group "
                          data-toggle="buttons"
                          style={{ width: "100%" }}
                        >
                          <label class="btn btn-light">
                            <input
                              type="checkbox"
                              name="acRelated"
                              onChange={() =>
                                setState({ ...state, acRelated: !acRelated })
                              }
                            />{" "}
                            HVAC / Airconditioning
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        {" "}
                        <div
                          class="btn-group "
                          data-toggle="buttons"
                          style={{ width: "100%" }}
                        >
                          <label class="btn btn-light">
                            <input
                              type="checkbox"
                              name="tyreBrakeRelated"
                              onChange={() =>
                                setState({
                                  ...state,
                                  tyreBrakeRelated: !tyreBrakeRelated,
                                })
                              }
                            />{" "}
                            Tires & Brakes
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div
                          class="btn-group "
                          data-toggle="buttons"
                          style={{ width: "100%" }}
                        >
                          <label class="btn btn-light">
                            <input
                              type="checkbox"
                              name="transmissionRelated"
                              onChange={() =>
                                setState({
                                  ...state,
                                  transmissionRelated: !transmissionRelated,
                                })
                              }
                            />{" "}
                            Drivetrain / Transmission
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 ">
                        {" "}
                        <div
                          class="btn-group "
                          data-toggle="buttons"
                          style={{ width: "100%" }}
                        >
                          <label class="btn btn-light">
                            <input
                              type="checkbox"
                              name="bodyRelated"
                              onChange={() =>
                                setState({
                                  ...state,
                                  bodyRelated: !bodyRelated,
                                })
                              }
                            />{" "}
                            Exterior Body / Door / Bumpers
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 ">
                        <div
                          class="btn-group "
                          data-toggle="buttons"
                          style={{ width: "100%" }}
                        >
                          <label class="btn btn-light">
                            <input
                              type="checkbox"
                              name="interiorRelated"
                              onChange={() =>
                                setState({
                                  ...state,
                                  interiorRelated: !interiorRelated,
                                })
                              }
                            />{" "}
                            Interior Components
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div
                          class="btn-group "
                          data-toggle="buttons"
                          style={{ width: "100%" }}
                        >
                          <label class="btn btn-light">
                            <input
                              type="checkbox"
                              name="steeringRelated"
                              onChange={() =>
                                setState({
                                  ...state,
                                  steeringRelated: !steeringRelated,
                                })
                              }
                            />{" "}
                            Steering
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div
                          class="btn-group "
                          data-toggle="buttons"
                          style={{ width: "100%", textAlign: "start" }}
                        >
                          <label class="btn btn-light">
                            <input
                              type="checkbox"
                              name="suspensionRelated"
                              onChange={() =>
                                setState({
                                  ...state,
                                  suspensionRelated: !suspensionRelated,
                                })
                              }
                            />{" "}
                            Suspension
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        {" "}
                        <div
                          class="btn-group "
                          data-toggle="buttons"
                          style={{ width: "100%" }}
                        >
                          <label class="btn btn-light">
                            <input
                              type="checkbox"
                              name="otherRelated"
                              onChange={() =>
                                setState({
                                  ...state,
                                  otherRelated: !otherRelated,
                                })
                              }
                            />{" "}
                            All Other Maintenance
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                ) : selectedReminder.type === "insurance" ? (
                  <>
                    {" "}
                    <div class="form-group">
                      <label
                        style={{ color: "#6c757d", fontFamily: "Roboto" }}
                        className="overview-dialog-label"
                      >
                        When to renew ?
                      </label>

                      <div className="input-group">
                        <input
                          type="date"
                          class="form-control"
                          name="renewalDate"
                          onChange={handleChange}
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>{" "}
                    <div className="form-group">
                      <label style={{ color: "#6c757d", fontFamily: "Roboto" }}>
                        Expense type
                      </label>

                      <select
                        class="form-control select2"
                        data-toggle="select2"
                        name="expenseType"
                        onChange={handleChange}
                      >
                        <option value="insurance">Insurance</option>
                        <option value="regulation">
                          Government Regulations
                        </option>
                        <option value="other">
                          Other reguRobotory expenses{" "}
                        </option>
                      </select>
                    </div>{" "}
                    <div className="form-group">
                      <label style={{ color: "#6c757d", fontFamily: "Roboto" }}>
                        Enter Vendor
                      </label>

                      <input
                        name="vendor"
                        onChange={handleChange}
                        type="text"
                        class="form-control"
                        placeholder="optional"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="form-group">
                      <label style={{ color: "#6c757d", fontFamily: "Roboto" }}>
                        Enter Expense
                      </label>

                      <br />
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter expense type"
                        name="type"
                        value={type}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}
                <button
                  className="btn btn-primary"
                  onClick={completeReminder}
                  style={{ width: "100%" }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ReminderTable;
