import Chart from "react-apexcharts";
import React, { useState } from "react";

const AreaChart = ({ yAxisTitle, xAxisTitle, yaxis, yaxes, xaxis }) => {
  const [state, setState] = useState({
    _options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      yaxis: {
        title: { text: yAxisTitle },
      },
      xaxis: {
        categories: xaxis,
        title: { text: xAxisTitle },
      },
      colors: ["#00274c"],
    },

    _series: yaxes || [
      {
        name: "",
        type: "area",
        data: yaxis,
      },
    ],
  });

  const { _options, _series } = state;

  return <Chart options={_options} series={_series} type="area" />;
};

export default AreaChart;
