export default function NumberFormat(value, countryCode) {
  if (!countryCode) countryCode = "USA";

  countryCode = countryCode.toUpperCase();

  if (value == null || value == "N/A") {
    return "N/A";
  }
  if (typeof value === null || typeof value === undefined) {
    return value;
  } else {
    if (countryCode != "IND")
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else
      return value.toString().split(".")[0].length > 3
        ? value
            .toString()
            .substring(0, value.toString().split(".")[0].length - 3)
            .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
            "," +
            value
              .toString()
              .substring(value.toString().split(".")[0].length - 3)
        : value.toString();
  }
}
