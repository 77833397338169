import { Dialog } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import TataService from "../../../services/connected-cars/tata-service";
import { Dropdown } from "semantic-ui-react";
const TataAccountSettings = () => {
  const [state, setState] = useState({
    _status: "CONNECTED",
    _accessToken: null,
    _refresh_token: null,
    _loading: true,
    _expiresOn: null,
    _showDeleteSuccessDialog: false,
    _deleteLoading: false,
    _showExportRequestDialog: false,
    _vin: null,
  });

  const {
    _accessToken,
    _expiresOn,
    _loading,
    _refresh_token,
    _status,
    _deleteLoading,
    _showDeleteSuccessDialog,
    _showExportRequestDialog,
    _vin,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) _getTataAccountInfo();

    return function cleanup() {
      mounted = false;
    };
  }, []);

  function _copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  const _getTataAccountInfo = async () => {
    try {
      setState({ ...state, _loading: true, _showDeleteSuccessDialog: false });
      const data = await TataService.getTataMetaByUid();

      if (data.result) {
        setState({
          ...state,
          _status: "CONNECTED",
          _accessToken: data.result.access_token,
          _refresh_token: data.result.refresh_token,
          _expiresOn: moment(data.result.updated_at).add(1, "hour").format(),
          _loading: false,
          _deleteLoading: false,
          _showExportRequestDialog: false,
        });
      } else {
        setState({
          ...state,
          _status: "NOT CONNECTED",
          _accessToken: "N/A",
          _refresh_token: "N/A",
          _expiresOn: "N/A",
          _loading: false,
          _showDeleteSuccessDialog: false,
          _deleteLoading: false,
          _showExportRequestDialog: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const _deleteTataAccountInfo = async () => {
    try {
      setState({ ...state, _deleteLoading: true });
      const data = await TataService.deleteTataMetaByUid();
      setState({
        ...state,
        _deleteLoading: false,
        _showDeleteSuccessDialog: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const _requestDataExport = async () => {
    try {
      setState({ ...state, _deleteLoading: true });
      const data = await TataService.dataExportRequest(_vin);

      _getTataAccountInfo();
    } catch (err) {
      console.log(err);
    }
  };

  if (_loading)
    return (
      <div className="card card-body">
        <h1>Tata Account Settings</h1>
        <div className="mt-3 spinner spinner-border center-spinner"></div>
      </div>
    );

  const _onTokenDeleteDialog = () => {
    return (
      <Dialog
        open={_showDeleteSuccessDialog}
        onClose={() => setState({ ...state, _showDeleteSuccessDialog: false })}
      >
        <div class="modal-content">
          <div class="modal-header modal-colored-header bg-primary">
            <h4 class="modal-title">Tata Token Deleted</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() =>
                setState({ ...state, _showDeleteSuccessDialog: false })
              }
            >
              ×
            </button>
          </div>
          <div class="modal-body">
            Your Tata is no longer connected with Keemut. We can no longer
            communicate with your car.
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light"
              data-dismiss="modal"
              onClick={() => _getTataAccountInfo()}
            >
              CONTINUE
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  const _dataExportDialog = () => {
    return (
      <Dialog
        open={_showExportRequestDialog}
        onClose={() => setState({ ...state, _showExportRequestDialog: false })}
      >
        <div class="modal-content">
          <div class="modal-header modal-colored-header bg-primary">
            <h4 class="modal-title">Tata Data Export Request</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() =>
                setState({ ...state, _showExportRequestDialog: false })
              }
            >
              ×
            </button>
          </div>
          <div class="modal-body">
            <p>
              The data will be sent via email to the registered email account
              within 10-15 minutes.
            </p>
            <p>
              The link will be valid only for 1 day. Upon expiry, you will have
              to issue another request.
            </p>

            <div className="mt-3 form-group">
              <label>Choose Vehicle </label>
              <div className="input-group">
                <Dropdown
                  search
                  selection
                  options={(() => {
                    let vehiclesJson = localStorage.getItem("vehicles");

                    let ops = [];

                    JSON.parse(vehiclesJson).forEach((v, i) => {
                      if (v.vin != null && v.make == "Tata")
                        ops.push({
                          text: `${v.name}`,
                          key: i,
                          value: v.vin,
                        });
                    });

                    return ops;
                  })()}
                  fluid
                  value={_vin}
                  onChange={(e, { value }) => {
                    setState({ ...state, _vin: value });
                  }}
                />
              </div>
            </div>

            <br />
            <br />
            <br />
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              onClick={_requestDataExport}
              style={{ width: "100%" }}
            >
              {_deleteLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <div className=" p-3 card card-boy">
      <h1>Tata Account Settings</h1>

      <p className="mt-3">
        <strong>Tata Account Status:</strong>{" "}
        <span
          className={`text-${_status == "CONNECTED" ? "success" : "danger"}`}
        >
          {_status}
        </span>
      </p>
      <p>
        <strong>Tata Access Token:</strong>{" "}
        {_accessToken == "N/A" ? (
          "N/A"
        ) : (
          <button
            onClick={() => _copyToClipboard(_accessToken)}
            className="btn btn-light ml-2"
          >
            Click To Copy
          </button>
        )}
      </p>
      <p>
        <strong>Tata Refresh Token:</strong>{" "}
        {_refresh_token == "N/A" ? (
          "N/A"
        ) : (
          <button
            onClick={() => _copyToClipboard(_refresh_token)}
            className="btn btn-light ml-2"
          >
            Click To Copy
          </button>
        )}
      </p>

      <p className="mb-3  ">
        <strong className="mr-2">Export Tata Data:</strong>
        COMING SOON
        {/* <button
          onClick={() => setState({ ...state, _showExportRequestDialog: true })}
          className="btn btn-light ml-2"
        >
          Request Data Export
        </button> */}
      </p>
      {_status == "CONNECTED" ? (
        <>
          {" "}
          <button
            style={{ width: "200px" }}
            onClick={_deleteTataAccountInfo}
            className="btn  btn-outline-danger"
          >
            {_deleteLoading ? "Saving..." : "Delete My Tata Token"}
          </button>
          <p className="text-muted mt-2">
            This action is not reversible and your Tata access token will be
            removed permanently.
          </p>
        </>
      ) : (
        <>
          <a href="/dashboard/Tata/onboard">
            <button
              style={{ width: "200px" }}
              className="btn  btn-outline-success"
            >
              Connect Tata Account
            </button>
          </a>
        </>
      )}

      {_onTokenDeleteDialog()}
      {_dataExportDialog()}
    </div>
  );
};

export default TataAccountSettings;
