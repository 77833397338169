import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const DonutChart = ({ percentValue, formatter, label }) => {
  const [state, setState] = useState({
    _options: {
      colors: ["#00274c"],
      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -10,
              show: false,
              color: "#000",
              fontSize: "0px",
            },
            value: {
              color: "#000000",
              fontSize: "32px",
              show: true,
              formatter: formatter,
            },
          },
        },
      },
      labels: [label],
    },
    _series: [percentValue],
  });
  const { _series, _options } = state;

  useEffect(() => {
    setState({
      ...state,
      _options: {
        colors: ["#00274c"],
        chart: {
          height: 0,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "70%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -10,
                show: false,
                color: "#000",
                fontSize: "0px",
              },
              value: {
                color: "#000000",
                fontSize: "32px",
                show: true,
                formatter: formatter,
              },
            },
          },
        },
        labels: [label],
      },
      _series: [percentValue],
    });
  }, [percentValue, formatter, label]);

  return <Chart options={_options} series={_series} type="radialBar" />;
};

export default DonutChart;
