import React, { useState, useEffect } from "react";
import Topbar from "../common/Topbar";
import Footer from "../common/Footer";
import { API, site } from "../../config";
import { Dropdown } from "semantic-ui-react";
import NumberFormat from "../../helpers/number-format-helper";

const VinDecoder = () => {
  const [state, setState] = useState({
    vin: null,
    searched: false,
    make: null,
    model: null,
    trim: null,
    trimOptions: [],
    bodyClass: null,
    year: null,
    engineModel: null,
    driveType: null,
    gvwr: null,
    transmission: null,
    brakeType: null,
    plantCity: null,
    plantState: null,
    plantCountry: null,
    batteryInfo: null,
    abs: null,
    showVinError: null,
    loading: false,
    recentVinSearches: [],
  });

  const {
    vin,
    searched,
    make,
    model,
    year,
    trim,
    trimOptions,
    bodyClass,
    engineModel,
    gvwr,
    driveType,
    transmission,
    brakeType,
    plantCity,
    plantState,
    abs,
    plantCountry,
    batteryInfo,
    showVinError,
    loading,
    recentVinSearches,
  } = state;

  useEffect(() => {
    const getVinSearches = async () => {
      try {
        const res = await fetch(`${API}/vin-search-results`, {
          method: "GET",
        });
        const data = await res.json();

        if (data.done) {
          setState({ ...state, recentVinSearches: data.recentSearches });
        }
      } catch (err) {
        console.log(err);
      }
    };

    let mounted = true;

    if (mounted) {
      getVinSearches();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getVinData = async () => {
    try {
      setState({ ...state, loading: true });
      const res = await fetch(
        `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin}?format=json`,
        { method: "GET" }
      );
      const data = await res.json();
      let vehicleData = data.Results[0];
      console.log(vehicleData);
      const tres = await fetch(
        `${API}/vehicle-list/trim/?make=${vehicleData.Make}&model=${
          vehicleData.Model
        }&year=${vehicleData.ModelYear}${
          make == "Tesla" ? "&deprecated=true" : ""
        }`,
        {
          method: "GET",
          headers: {
            Accpet: "application/json",
          },
        }
      );
      const tdata = await tres.json();

      let trimOptions = [];

      tdata.trimList.forEach((t) => {
        trimOptions.push({
          key: t.trim,
          value: t.trim,
          text: t.trim,
        });
      });
      setState({
        ...state,
        loading: false,
        make: vehicleData.Make,
        model: vehicleData.Model,
        year: vehicleData.ModelYear,
        bodyClass: vehicleData.BodyClass,
        engineModel: vehicleData.EngineModel,
        driveType: vehicleData.DriveType,
        gvwr: vehicleData.GVWR,
        transmission: vehicleData.TransmissionStyle,
        brakeType: vehicleData.BrakeSystemType,
        plantCity: vehicleData.PlantCity,
        plantState: vehicleData.PlantState,
        plantCountry: vehicleData.PlantCountry,
        batteryInfo: vehicleData.BatteryInfo,

        abs: vehicleData.ABS,

        trimOptions: trimOptions,
        searched: true,
        loading: false,
      });
    } catch (err) {}
  };

  return (
    <div>
      
      <Topbar />
      <div className="container text-dark mt-3 mb-5 card card-body ">
        <h1>Vin Decoder - Free Keemut Vin Report</h1>

        <div className="mt-4">
          <p>
            Get your free VIN report and valuation based on the Patented Keemut
            Valuation Engine.{" "}
          </p>
        </div>
        {vin && vin.length != 17 && (
          <p className="text-danger mt-2">
            Invalid Vin, please enter a valid Vin
          </p>
        )}
        <div className="row text-center mt-2">
          <div className="col-lg-1 pt-1">
            <label>Enter Vin : </label>
          </div>
          <div className="col-lg-4">
            <input
              className="form-control"
              placeholder="xxxxxxxxxxxxxxxxx"
              name="vin"
              value={vin}
              onChange={(e) => {
                let val = e.target.value;
                setState({ ...state, vin: val });
              }}
            />
          </div>

          <div className="col-lg-2">
            <button
              onClick={() => {
                getVinData();
              }}
              className="btn btn-success"
            >
              {loading ? (
                <> Loading... </>
              ) : (
                <>
                  {" "}
                  Search <i className="mdi mdi-magnify search-icon"></i>{" "}
                </>
              )}
            </button>
          </div>
        </div>
        <br />
        <br />
        <br />

        {searched && (
          <>
            <div>
              <div className="row">
                <div className="col-lg-3">
                  <strong>Make : </strong>
                  {make}
                </div>
                <div className="col-lg-3">
                  <strong>Body Class : </strong> {bodyClass || "N/A"}
                </div>
                <div className="col-lg-3">
                  {batteryInfo ? (
                    <>
                      {" "}
                      <strong>Battery Info : </strong> {batteryInfo || "N/A"}{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <strong>Engine Model : </strong> {engineModel || "N/A"}{" "}
                    </>
                  )}
                </div>
                <div className="col-lg-3">
                  <strong>Drive Type : </strong> {driveType || "N/A"}
                </div>
              </div>

              <br />
              <br />
              <div className="row">
                <div className="col-lg-3">
                  <strong>Model : </strong>
                  {model}
                </div>
                <div className="col-lg-3">
                  <strong>Transmission : </strong> {transmission || "N/A"}
                </div>

                <div className="col-lg-3">
                  <strong>Weight Rating : </strong> {gvwr || "N/A"}
                </div>
                <div className="col-lg-3">
                  <strong>Brake Type : </strong> {brakeType || "N/A"}
                </div>
              </div>

              <br />
              <br />

              <div className="row">
                <div className="col-lg-3">
                  <strong>Year : </strong>
                  {year}
                </div>
                <div className="col-lg-3">
                  <strong>Plant City : </strong> {plantCity || "N/A"}
                </div>
                <div className="col-lg-3">
                  <strong>Plant State : </strong> {plantState || "N/A"}
                </div>
                <div className="col-lg-3">
                  <strong>Plant Country : </strong> {plantCountry || "N/A"}
                </div>
              </div>

              <br />
              <br />

              <p>
                <strong>Trim : </strong>
                <Dropdown
                  className="ml-3"
                  options={trimOptions}
                  value={trim}
                  search
                  selection
                  onChange={(e, { value }) => {
                    setState({ ...state, trim: value });
                  }}
                />
              </p>
              <br />
              <br />
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (trim == null) {
                    setState({ ...state, showVinError: true });
                    return;
                  }
                  window.open(
                    `${site}/free-vin-report/${vin}?trim=${trim}`,
                    "_blank"
                  );
                }}
              >
                Show Free Vin Report
              </button>
              <br />
              {showVinError && (
                <div
                  class="alert alert-danger text-center"
                  role="alert"
                  style={{ width: "100%" }}
                >
                  The make and model selected are not eligible for the Keemut
                  Vin Report
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setState({ ...state, showVinError: false })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
            </div>
          </>
        )}

        <br />
        <br />
        <br />

        <div>
          <h4>Recent Searches</h4>
          <table className="table-striped">
            <thead>
              <th>Vin</th>
              <th>Make</th>

              <th>Model</th>
              <th>Year</th>

              <th>Valuation</th>
              <th>Keemut Connected</th>
            </thead>

            <tbody>
              {recentVinSearches.map((item) => (
                <tr>
                  <td>
                    <a
                      style={{ color: "blue", textDecoration: "underline" }}
                      href={`${site}/free-vin-report/${item.vin}?trim=${item.trim}`}
                    >
                      {item.vin}
                    </a>
                  </td>
                  <td>{item.make}</td>
                  <td>{item.model}</td>
                  <td>{item.year}</td>
                  <td>
                    $
                    {item.price_low &&
                      NumberFormat(parseFloat(item.price_low).toFixed(0))}{" "}
                    - $
                    {item.price_high &&
                      NumberFormat(parseFloat(item.price_high).toFixed(0))}
                  </td>
                  <td>No</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default VinDecoder;
