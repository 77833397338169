import React, { useState } from "react";
import {
  getDrivetrainByMMTYService,
  getModelsService,
  getMsrpByMMTYService,
  getTrimsService,
} from "../../services/vehicle-list-service";
import FormGenerator from "../../components/common/FormGenerator";
import BmwService from "../../services/bmw-service";
import { createVehicleService } from "../../services/vehicles-service";
import { site } from "../../config";

// PS: This code needs serious refactoring
const BmwPage = () => {
  const [state, setState] = useState({
    _username: null,
    _password: null,
    _vin: null,
    _timezone: null,
    _useRemoteControl: true,
    _error: null,
    _loading: false,
    _success: false,
    _region: "NorthAmerica",
    _vehicles: [],
    _step: 0,

    _make: null,
    _model: null,
    _year: null,
    _drivetrain: null,
    _trim: null,

    _modelOps: [],
    _trimOps: [],
    _drivetrainOps: [],

    _currentCarIndex: -1,
  });

  const {
    _region,
    _password,
    _username,
    _vin,
    _step,
    _vehicles,
    _loading,
    _error,
    _make,
    _model,
    _modelOps,
    _trim,
    _trimOps,
    _currentCarIndex,
    _year,
  } = state;

  const _onSubmit = async (e) => {
    try {
      e.preventDefault();

      setState({ ...state, _loading: true, _error: null });

      const data = await BmwService.loginWithBmw(
        _username,
        _password,
        _vin,
        _region
      );

      if (data.error) {
        return setState({
          ...state,
          _loading: false,
          _error: "Incorrect Username or Password!",
          _success: false,
          _step: 0,
        });
      }

      setState({
        ...state,
        _loading: false,
        _error: null,
        _success: true,
        _step: 1,
      });
    } catch (err) {
      setState({
        ...state,
        _loading: false,
        _error: "Unable to process your request at this time!",
      });
    }
  };

  const _onboardVehicles = async (e) => {
    try {
      e.preventDefault();

      setState({ ...state, _loading: true, _error: null });

      const data = await BmwService.onboardBmwVehicles();
      console.log(data);
      _onboardNextVehicle(data.vehicles);
    } catch (err) {
      setState({
        ...state,
        _loading: false,
        _error: "Unable to process your request at this time!",
      });
    }
  };

  const _onboardNextVehicle = async (vehicles) => {
    try {
      let idx = _currentCarIndex + 1;

      setState({
        ...state,
        _step: 2,
        _loading: false,
        _vehicles: vehicles,
        _currentCarIndex: idx,
      });

      if (idx >= vehicles.length) {
        setState({ ...state, _loading: true, _step: 3 });
        localStorage.removeItem("vehicles");
        return setTimeout(function () {
          window.location = `${site}/dashboard/overview`;
        }, 2000);
      }

      let vehicle = vehicles[idx];
      console.log(vehicle);
      let make = vehicle.brand;
      let model = vehicle.model.split(" ")[0];
      let trim = vehicle.model.split(" ")[1];
      let vin = vehicle.vin;
      let year = vehicle.yearOfConstruction;
      let modelOps = [],
        trimOps = [];

      const models = await getModelsService(make, year, "USA");

      const trims = await getTrimsService(make, model, year, "USA");

      models.modelList.forEach((item) => {
        modelOps.push({
          key: item.model,
          text: item.model,
          value: item.model,
        });
      });

      trims.trimList.forEach((item) => {
        trimOps.push({ key: item.trim, text: item.trim, value: item.trim });
      });

      setState({
        ...state,
        _vin: vin,
        _make: make,
        _model: model,
        _trim: trim,
        _year: year,
        _modelOps: modelOps,
        _trimOps: trimOps,
        _step: 2,
        _vehicles: vehicles,
        _loading: false,
        _currentCarIndex: idx,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const _updateYear = async (year) => {
    try {
      let modelOps = [],
        trimOps = [];

      const models = await getModelsService(_make, year, "USA");

      const trims = await getTrimsService(_make, _model, year, "USA");

      models.modelList.forEach((item) => {
        modelOps.push({
          key: item.model,
          text: item.model,
          value: item.model,
        });
      });

      trims.trimList.forEach((item) => {
        trimOps.push({ key: item.trim, text: item.trim, value: item.trim });
      });

      setState({
        ...state,
        _trimOps: trimOps,
        _modelOps: modelOps,
        _year: year,
        _trim: null,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const _updateModel = async (model) => {
    try {
      let trimOps = [];

      const trims = await getTrimsService(_make, model, _year, "USA");

      trims.trimList.forEach((item) => {
        trimOps.push({ key: item.trim, text: item.trim, value: item.trim });
      });

      setState({ ...state, _trimOps: trimOps, _model: model, _trim: null });
    } catch (err) {
      console.log(err);
    }
  };

  const _addVehicle = async (e) => {
    try {
      e.preventDefault();
      setState({ ...state, _loading: true });
      const drivetrains = await getDrivetrainByMMTYService(
        _make,
        _model,
        _trim,
        _year,
        "USA"
      );
      const msrp = await getMsrpByMMTYService(
        _make,
        _model,
        _trim,
        _year,
        "USA"
      );

      await createVehicleService(
        `${_vin}`,
        _model,
        _make,
        _trim,
        drivetrains.drivetrains
          ? drivetrains.drivetrains.length > 0
            ? drivetrains.drivetrains[0].drivetrain
            : "AWD"
          : "AWD",
        0,
        "SUV",
        "car",
        parseInt(msrp.price || 0),
        _year,
        _vin,
        "",
        null,
        null,
        null,
        "purchase",
        null,
        false
      );

      _onboardNextVehicle(_vehicles);
    } catch (err) {
      console.log(err);
    }
  };

  const _handleChange = (e) => {
    try {
      setState({ ...state, [e.target.name]: e.target.value });
    } catch (err) {}
  };

  if (_step == 0)
    return (
      <div className="p-3 card card-body text-dark">
        <h3>Connect BMW Account</h3>
        <p>
          <strong>Note:</strong> Keemut is in beta for BMW, we are still
          evaluating different features that we get from BMW vehicles.
        </p>
        <p>
          We don't store your username or password. We obtain a Access Token
          from BMW to poll data on the user's behalf.
        </p>
        <br />
        <FormGenerator
          onSubmit={_onSubmit}
          inputs={[
            {
              label: "Choose Region",
              inputType: "dropdown",
              dropdownOptions: [
                {
                  key: "usa",
                  value: "NorthAmerica",
                  text: "North America",
                },
                {
                  key: "china",
                  value: "China",
                  text: "China",
                },

                {
                  key: "Other",
                  value: "RestOfTheWorld",
                  text: "Rest Of The World",
                },
              ],
              id: "BMW_onboard_region_dropdown",
              required: true,
              placeholder: "Choose Region",
              name: "_region",
              value: _region,
              handleChange: (e, { value }) =>
                setState({ ...state, _region: value }),
            },
            {
              label: "BMW Username",
              inputType: "text",
              id: "BMW_onboard_username_textfield",
              required: true,
              placeholder: "Enter BMW Username",
              name: "_username",
              value: _username,
              handleChange: _handleChange,
            },
            {
              label: "BMW Password",
              inputType: "password",
              id: "BMW_onboard_password_textfield",
              required: true,
              placeholder: "Enter BMW Password",
              name: "_password",
              value: _password,
              handleChange: _handleChange,
            },
          ]}
          error={_error}
          loading={_loading}
        />
      </div>
    );

  if (_step == 1) {
    return (
      <div className="p-3 card card-body text-dark">
        <h3>Onboard your vehicles</h3>
        <p>
          Your BMW Account has been connected. To onboard your Vehicles, please
          click continue.
        </p>
        <FormGenerator
          onSubmit={_onboardVehicles}
          loading={_loading}
          inputs={[]}
        />
      </div>
    );
  }

  if (_step == 2) {
    return (
      <div className="text-dark card card-body p-3">
        <h3>Onboard Vehicle : {_vin}</h3>
        <p>Please confirm the vehicle configuration below.</p>
        {_vehicles && _vehicles.length > 0 && (
          <FormGenerator
            loading={_loading}
            inputs={[
              {
                label: "Choose Year",
                inputType: "dropdown",
                dropdownOptions: (function () {
                  let ops = [];

                  for (let i = 2015; i <= new Date().getFullYear(); i++) {
                    ops.push({
                      key: i,
                      text: i,
                      value: i,
                    });
                  }

                  return ops;
                })(),
                id: "BMW_year_dropdown",
                required: true,
                placeholder: "Choose Year",
                name: "_year",
                value: _year,
                handleChange: (e, { value }) => _updateYear(value),
              },

              {
                label: "Choose Model",
                inputType: "dropdown",
                dropdownOptions: _modelOps,
                id: "BMW_Model_dropdown",
                required: true,
                placeholder: "Choose Model",
                name: "_model",
                value: _model,
                handleChange: (e, { value }) => _updateModel(value),
              },

              {
                label: "Choose Trim",
                inputType: "dropdown",
                dropdownOptions: _trimOps,
                id: "BMW_trim_dropdown",
                required: true,
                placeholder: "Choose Trim",
                name: "_trim",
                value: _trim,
                handleChange: (e, { value }) =>
                  setState({ ...state, _trim: value }),
              },
            ]}
            onSubmit={_addVehicle}
            error={_error}
          />
        )}
      </div>
    );
  }

  if (_step == 3) {
    return (
      <div className="text-dark card card-body p-3">
        <h3>Onboarding Completed!</h3>
        <p>
          Your Vehicles are now onboarded, you will start seeing data for your
          vehicles soon! You will be redirect back in a few seconds.{" "}
        </p>
      </div>
    );
  }
};

export default BmwPage;
