import React, { useState, useEffect } from "react";
import { API } from "../../config";

const BatteryInfo = ({ vehicle, units, isFreeReport }) => {
  const [state, setState] = useState({
    batteryLife: "N/A",
    averageSOC: "N/A",
    initialRange: "N/A",
    distanceUnit: "mi",
    loading: false,
    error: null,
  });
  const {
    batteryLife,
    averageSOC,
    initialRange,
    distanceUnit,
    loading,
    error,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted && !isFreeReport) {
      getBatteryInfo();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getBatteryInfo = async () => {
    try {
      setState({ ...state, loading: true });
      const res = await fetch(
        `${API}/keemut-report/battery-info/${vehicle.vin}/?model=${vehicle.model}&trim=${vehicle.trim}&is_smartcar=${vehicle.is_smartcar}&is_highmobility=${vehicle.is_highmobility}`,
        {
          method: "GET",
          headers: {
            Accept: `application/json`,
          },
        }
      );

      const data = await res.json();
      console.log(data);
      if (data.done) {
        setState({
          ...state,
          batteryLife: data.batteryLife,
          averageSOC: data.averageSOC,
          distanceUnit: data.distanceUnit,
          initialRange:
            units.distanceunit == "mi"
              ? data.initialRange
              : parseInt(data.initialRange * 1.607),
          loading: false,
        });
      } else {
        setState({ ...state, loading: false });
      }
    } catch (err) {
      setState({ ...state, loading: false });
    }
  };

  if (loading) {
    return (
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class=" mdi mdi-weather-lightning widget-icon bg-warning-lighten text-warning"></i>
          </div>
          <h5
            class="text-dark font-weight-normal mt-0"
            title="Distance Driven till date"
          >
            Battery Information
          </h5>
          <h3 class="mt-3 mb-3">{vehicle.trim}</h3>
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card widget-flat" id="keemut-report_battery_info_card">
      <div class="card-body">
        <div class="float-right">
          <i class=" mdi mdi-weather-lightning widget-icon bg-warning-lighten text-warning"></i>
        </div>
        <h5
          class="text-dark font-weight-normal mt-0"
          title="Distance Driven till date"
        >
          Battery Information
        </h5>
        <h3 class="mt-3 mb-3">
          {vehicle.vin == "1HM888055H7DDA033" ||
          vehicle.vin == "1HM888055H7DDA034"
            ? "Cross Turismo"
            : vehicle.trim || "Trim N/A"}
        </h3>
        <p>
          Initial Range : {initialRange} {units.distanceunit}
        </p>
        <p>
          Current Range : {parseInt((batteryLife * initialRange) / 100)}{" "}
          {units.distanceunit} ({batteryLife}%)
        </p>
        <p>
          Average SOC : {vehicle.vin == "1HM888055H7DDA034" ? 99 : averageSOC} %
        </p>
      </div>
    </div>
  );
};

export default BatteryInfo;
