import React, { useEffect, useState } from "react";
import { API } from "../../config";
import CurrentOdometerRadial from "./CurrentOdometerRadial";
import CurrentCostPerMile from "./CurrentCostPerMile";
import AnalyticsTable from "../dashboard/tco/AnalyticsTable";
import ReportAnalyticsTable from "./ReportAnalyticsTable";
import ReportMaintenanceCostChart from "./ReportMaintenanceCostChart";
import ReportMaintenanceBreakdown from "./ReportMaintenanceBreakdown";
import DistanceDrivenPerMonth from "./DistanceDrivenPerMonth";
import ReportOdometerChart from "./ReportOdometerChart";
import ReportCostDataChart from "./ReportCostDataChart";
import DummyRadials from "./DummyRadials";
import PredictionVariables from "./PredictionVariables";
import ReportValuationChart from "./ReportValuationChart";
import ReportCumulativeChart from "./ReportCumulativeChart";
import ReportValueCard from "./ReportValueCard";
import queryString from "query-string";

const SampleKeemutReport = (props) => {
  const [state, setState] = useState({
    loading: false,
    vehicle: {},
    vin: "Vehicle VIN",
  });
  const { vehicle, loading, vin } = state;

  if (loading) {
    return <div>Loading....</div>;
  }

  return (
    <>
      <div id="watermark">
        {/* <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p id="watermark-text">Sample Report</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> <br /> <br />
        <p id="watermark-text">Keemut.com</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> <br /> <br />
        <p id="watermark-text">Sample Report</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> <br /> <br />
        <p id="watermark-text">Keemut.com</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> <br /> <br />
        <p id="watermark-text">Sample Report</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> <br /> <br />
        <p id="watermark-text">Keemut.com</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> <br /> <br />
        <p id="watermark-text">Sample Report</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> <br /> <br />
        <p id="watermark-text">Keemut.com</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br /> <br /> <br /> */}
      </div>
      <div
        className="px-5 py-3"
        id="watermark-content"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div className="card">
          <div className="card-body">
            <span>
              <div className="text-center">
                <span>
                  <img
                    src={require("../../assets/keemut-dark.png")}
                    width="200"
                  ></img>
                  <span className="float-right p-3">
                    <img
                      src={require("../../assets/keemut-report.png")}
                      width="150px"
                    />
                  </span>
                </span>
              </div>
              <div className="text-primary p-2 text-center">
                <span style={{ fontSize: "3rem" }}>
                  <strong>Connected Car Vehicle History Report </strong>
                </span>
                <h5>As Of Sep 05, 2021</h5>
              </div>
            </span>
          </div>
        </div>
        {/* <div
          class="alert alert-primary text-center"
          role="alert"
          style={{ width: "100%" }}
        >
          We were not able to locate the Keemut Report for the vin {vin}. If you
          own this vehicle, please{" "}
          <a href="/register" className="text-danger">
            Connect it with Keemut
          </a>{" "}
          and get the most accurate valuation for your car online.
        </div> */}
        <br />
        <div className="card">
          <div className="card-body">
            <h3>Vehicle Information</h3>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-lg-12">
            {" "}
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3 my-5 text-center">
                    <img
                      src={require("../../assets/logos/honda.svg")}
                      width="100"
                      height="75"
                    ></img>
                  </div>

                  <div className="col-lg-3">
                    {/* <div className="card">
                    <div className="card-body bg-light">
                      <h3>Vehicle Information</h3> <br />
                      <h4>
                        Make:{" "}
                        <span className="mx-2" style={{ fontWeight: "normal" }}>
                          {" "}
                          {vehicle.make}
                        </span>
                      </h4>{" "}
                      <h4>
                        Model:{" "}
                        <span className="mx-2" style={{ fontWeight: "normal" }}>
                          {" "}
                          {vehicle.model} ({vehicle.year})
                        </span>
                      </h4>{" "}
                      <h4>
                        Trim:{" "}
                        <span className="mx-2" style={{ fontWeight: "normal" }}>
                          {" "}
                          {vehicle.trim}
                        </span>
                      </h4>{" "}
                      <h4>
                        VIN:{" "}
                        <span className="mx-2" style={{ fontWeight: "normal" }}>
                          {" "}
                          1FTFX1E54KFA26018
                        </span>
                      </h4>{" "}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="card">
                    <div className="card-body bg-light">
                      <h3>Vehicle Information</h3> <br />
                      <h4>
                        Make:{" "}
                        <span className="mx-2" style={{ fontWeight: "normal" }}>
                          {" "}
                          {vehicle.make}
                        </span>
                      </h4>{" "}
                      <h4>
                        Model:{" "}
                        <span className="mx-2" style={{ fontWeight: "normal" }}>
                          {" "}
                          {vehicle.model} ({vehicle.year})
                        </span>
                      </h4>{" "}
                      <h4>
                        Trim:{" "}
                        <span className="mx-2" style={{ fontWeight: "normal" }}>
                          {" "}
                          {vehicle.trim}
                        </span>
                      </h4>{" "}
                      <h4>
                        VIN:{" "}
                        <span className="mx-2" style={{ fontWeight: "normal" }}>
                          {" "}
                          1FTFX1E54KFA26018
                        </span>
                      </h4>{" "}
                    </div>
                  </div> */}

                    <div class="card widget-flat">
                      <div class="card-body">
                        <div class="float-right">
                          <i class="  mdi mdi-robot-mower widget-icon bg-success-lighten text-success"></i>
                        </div>
                        <h5
                          class="text-dark font-weight-normal mt-0"
                          title="Distance Driven till date"
                        >
                          5KBRL5891DB702688
                        </h5>
                        <h3 class="mt-3 mb-3">Honda Odyssey</h3>
                        <p>Year : 2013</p>
                        <p>Interior Color: Beige</p>
                        <p>Exterior Color: Gold</p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-lg-3">
                    <div class="card widget-flat">
                      <div class="card-body">
                        <div class="float-right">
                          <i class=" mdi mdi-weather-lightning widget-icon bg-warning-lighten text-warning"></i>
                        </div>
                        <h5
                          class="text-dark font-weight-normal mt-0"
                          title="Distance Driven till date"
                        >
                          Battery Information
                        </h5>
                        <h3 class="mt-3 mb-3">100D</h3>
                        <p>Battery Life : 99.2%</p>
                        <p>Average State of Charge : 82%</p>
                        <p>Battery Range : 255 mi</p>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-lg-3">
                    <div class="card widget-flat">
                      <div class="card-body">
                        <div class="float-right">
                          <i class=" mdi mdi-weather-lightning widget-icon bg-warning-lighten text-warning"></i>
                        </div>
                        <h5
                          class="text-dark font-weight-normal mt-0"
                          title="Distance Driven till date"
                        >
                          Engine Information
                        </h5>
                        <h3 class="mt-3 mb-3">V6</h3>
                        <p>Fuel Economy : 9.5 L/100km</p>
                        <p>Tank Size : 75L</p>
                        <p>Maintenance : Agency</p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-lg-3">
                    <div class="card widget-flat">
                      <div class="card-body">
                        <div class="float-right">
                          <i class="mdi mdi-clock-digital widget-icon bg-danger-lighten text-danger"></i>
                        </div>
                        <h5
                          class="text-dark font-weight-normal mt-0"
                          title="Distance Driven till date"
                        >
                          Tracking History
                        </h5>
                        <h3 class="mt-3 mb-3"> 7,850 mi </h3>
                        <p>Total Odometer : 8,000 mi</p>
                        <p>Lifespan Tracked : 98.12%</p>
                        <p>Driving Utilization : 12.20% </p>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-lg-3">
                    <div class="card widget-flat">
                      <div class="card-body">
                        <div class="float-right">
                          <i class="mdi mdi-clock-digital widget-icon bg-danger-lighten text-danger"></i>
                        </div>
                        <h5
                          class="text-dark font-weight-normal mt-0"
                          title="Distance Driven till date"
                        >
                          Tracking History
                        </h5>
                        <h3 class="mt-3 mb-3"> 34,758 km </h3>
                        <p>Total Odometer : 115,861 km</p>
                        <p>Lifespan Tracked : 30%</p>
                        <p>Driving Utilization : 2.4% </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="card">
          <div className="card-body">
            <h3>Valuation</h3>
            <div className="row">
              <div className="col-lg-12  text-center">
                <div className="row bg-light">
                  <div className="col-lg-4">
                    <p class="text-muted mb-0 mt-3">Keemut AI™ Valuation</p>
                    <h2 class="font-weight-normal mb-3">
                      <small class="mdi mdi-checkbox-blank-circle text-primary align-middle mr-1"></small>
                      <span className="text-success">$ 84,800</span>
                    </h2>
                  </div>
                  <div className="col-lg-4">
                    <p class="text-muted mb-0 mt-3">Depreciation</p>
                    <h2 class="font-weight-normal mb-3">
                      <small class="mdi mdi-checkbox-blank-circle text-primary align-middle mr-1"></small>
                      <span className="text-danger">14.34%</span>
                    </h2>
                  </div>
                  <div className="col-lg-4">
                    <p class="text-muted mb-0 mt-3">Purchase Price</p>
                    <h2 class="font-weight-normal mb-3">
                      <small class="mdi mdi-checkbox-blank-circle text-primary align-middle mr-1"></small>
                      <span className="text-warning">$ 99,000</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="card">
          <div className="card-body">
            <h3>Valuation</h3>
            <div className="row">
              <div className="col-lg-12  text-center">
                <div className="row bg-light">
                  <div className="col-lg-4">
                    <p class="text-muted mb-0 mt-3">Keemut AI™ Valuation</p>
                    <h2 class="font-weight-normal mb-3">
                      <small class="mdi mdi-checkbox-blank-circle text-primary align-middle mr-1"></small>
                      <span className="text-success">AED 55,000</span>
                    </h2>
                  </div>
                  <div className="col-lg-4">
                    <p class="text-muted mb-0 mt-3">Depreciation</p>
                    <h2 class="font-weight-normal mb-3">
                      <small class="mdi mdi-checkbox-blank-circle text-primary align-middle mr-1"></small>
                      <span className="text-danger">64%</span>
                    </h2>
                  </div>
                  <div className="col-lg-4">
                    <p class="text-muted mb-0 mt-3">Purchase Price</p>
                    <h2 class="font-weight-normal mb-3">
                      <small class="mdi mdi-checkbox-blank-circle text-primary align-middle mr-1"></small>
                      <span className="text-warning">AED 149,900</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            {" "}
            <ReportValuationChart />{" "}
          </div>
          <div className="col-lg-5">
            <PredictionVariables />
          </div>
        </div>
        <br />
        <div className="card">
          <div className="card-body">
            <h3>Driving Information</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <CurrentOdometerRadial />
          </div>
          <div className="col-lg-4">
            <CurrentCostPerMile />
          </div>
          <div className="col-lg-4">
            <DistanceDrivenPerMonth />
          </div>
        </div>
        <br /> <br />
        <div className="row">
          <div className="col-lg-7">
            <ReportOdometerChart />
          </div>
          <div className="col-lg-5">
            <DummyRadials />
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h3>Maintenance History</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <ReportAnalyticsTable />
          </div>
          <div className="col-lg-6">
            <ReportMaintenanceBreakdown />
          </div>
        </div>{" "}
        <footer className="text-center">
          <div>
            This is a sample report to illustrate the type of data that Keemut
            can capture. Some vehicles may have different data, depending on the
            automotive manufacturer's connection. We do not capture any data
            without the car owners opt-in. Keemut is not responsible for any
            data that may not be available.
          </div>
          <div>
            © 2020 Keemut, LLC. All rights reserved.{" "}
            <strong>Patent US9799058B2</strong>, other patents pending
          </div>
        </footer>
      </div>
    </>
  );
};

export default SampleKeemutReport;
