import React, { useState } from "react";
import { Tab } from "semantic-ui-react";
import UnitSettings from "./UnitSettings";
import TataAccountSettings from "./TataAccountSettings";
import PaymentSettings from "./PaymentSettings";
import AccountSettings from "./account-settings/AccountSettings";
import NotificationSettings from "./NotificationSettings";
import FloatingButton from "../common/FloatingButton";
import ConnectedCar from "./ConnectedCar";
const Settings = ({ vehicle }) => {
  const [state, setState] = useState({
    activeIndex: localStorage.getItem("activeSettingsTab") || 0,
    panes: [
      {
        menuItem: "Profile",
        render: () => (
          <Tab.Pane>
            {" "}
            <UnitSettings />{" "}
          </Tab.Pane>
        ),
      },

      {
        menuItem: "Payment",
        render: () => (
          <Tab.Pane>
            {" "}
            <PaymentSettings />{" "}
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Account",
        render: () => (
          <Tab.Pane>
            <AccountSettings />
          </Tab.Pane>
        ),
      },

      {
        menuItem: "Notifications",
        render: () => (
          <Tab.Pane>
            <NotificationSettings vehicle={vehicle} />
          </Tab.Pane>
        ),
      },

      {
        menuItem: "Manage Connected Car",
        render: () => (
          <Tab.Pane>
            <ConnectedCar />
          </Tab.Pane>
        ),
      },

    ],
  });
  const { panes, activeIndex } = state;

  return (
    <div className="text-dark">
      <h3>Settings</h3>
      <FloatingButton />
      <Tab
        activeIndex={activeIndex}
        panes={panes}
        onTabChange={(e, data) => {
          localStorage.setItem("activeSettingsTab", data.activeIndex);
          setState({ ...state, activeIndex: data.activeIndex });
        }}
      />
    </div>
  );
};

export default Settings;
