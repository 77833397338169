import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 380,
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const actions = [
  {
    icon: (
      <a href="/dashboard/vehicles/add">
        {" "}
        <i className="mdi mdi-car-back"></i>{" "}
      </a>
    ),
    name: "Vehicles",
  },
  {
    icon: (
      <a href="/dashboard/fuel">
        {" "}
        <i className="mdi mdi-gas-station-outline"></i>{" "}
      </a>
    ),
    name: "Fuel",
  },
  {
    icon: (
      <a href="/dashboard/maintenance">
        {" "}
        <i className="mdi mdi-gas-station-mdi mdi-progress-wrench"></i>{" "}
      </a>
    ),
    name: "Maintenance",
  },
  {
    icon: (
      <a href="/dashboard/insurance">
        {" "}
        <i className="mdi mdi-airbag"></i>{" "}
      </a>
    ),
    name: "Insurance",
  },
  {
    icon: (
      <a href="/dashboard/expense">
        {" "}
        <i className="mdi mdi-currency-usd"></i>{" "}
      </a>
    ),
    name: "Expense",
  },
  {
    icon: (
      <a href="/dashboard/revenue">
        {" "}
        <i className="mdi mdi-receipt"></i>{" "}
      </a>
    ),
    name: "Revenue",
  },
];

const FloatingButton = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
   <></>
  );
};

export default FloatingButton;

/** 
<SpeedDial
ariaLabel="SpeedDial tooltip example"
className={classes.speedDial}
hidden={hidden}
icon={<SpeedDialIcon />}
onClose={handleClose}
onOpen={handleOpen}
open={open}
>
{actions.map((action) => (
  <SpeedDialAction
    key={action.name}
    icon={action.icon}
    tooltipTitle={action.name}
    tooltipOpen
    onClick={handleClose}
  />
))}
</SpeedDial>

*/