import React, { useState, useEffect } from "react";
import VinDialog from "../VinDialog";
import Dialog from "@material-ui/core/Dialog";
import { API } from "../../../../config";
import VehicleInformation from "./VehicleInformation";
import KeemutReportConfig from "./KeemutReportConfig";

const KeemutReportSettings = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: true,
    published: false,
    showVinDialog: false,
    showPublishDialog: false,
    showPublishedDialog: false,
    publishing: false,
    isSubscribed: false,
    error: null,
  });
  const {
    loading,
    published,
    showVinDialog,
    isSubscribed,
    showPublishedDialog,
    showPublishDialog,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      isReportPublished();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const isReportPublished = async () => {
    try {
      setState({ ...state, loading: true });
      const user = JSON.parse(localStorage.getItem("user"));

      const subRes = await fetch(
        `${API}/subscription/${user.subscription_id}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const subData = await subRes.json();

      if (user.uid == "WWghtqwN9yZ0sqbjUxRO6QKUG502")
        subData.isSubscribed = true;

      if (!subData.isSubscribed) {
        setState({ ...state, isSubscribed: false, loading: false });
      } else {
        const res = await fetch(
          `${API}/keemut-report/exists?vehicleId=${vehicle.id}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const data = await res.json();

        if (data.done) {
          if (data.keemutReport) {
            setState({
              ...state,
              loading: false,
              isSubscribed: true,
              published: data.exists && data.keemutReport.published,
            });
          } else {
            setState({
              ...state,
              loading: false,
              isSubscribed: true,
              published: data.exists && data.keemutReport.published,
            });
          }
        } else {
          setState({
            ...state,
            loading: false,
            isSubscribed: true,
            published: false,
          });
        }
      }
    } catch (err) {
      setState({ ...state, loading: false, published: false });
    }
  };

  const publishReport = async () => {
    try {
      const res = await fetch(`${API}/keemut-report/publish/${vehicle.id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ vin: vehicle.vin, cost: 9.99 }),
      });

      const data = await res.json();

      if (data.done) {
        setState({ ...state, showVinDialog: false, showPublishedDialog: true });
      } else {
        setState({
          ...state,
          error: "Unable to publish your report at the moment",
          publishing: false,
          showVinDialog: false,
        });
      }
    } catch (err) {
      setState({
        ...state,
        error: "Unable to publish your report at the moment",
        publishing: false,
        showVinDialog: false,
      });
    }
  };

  if (loading) {
    return <div className="spinner-border text-primary" role="status"></div>;
  }

  if (!isSubscribed) {
    return (
      <div
        class="alert alert-primary text-center"
        role="alert"
        style={{ width: "100%" }}
      >
        Keemut Report is a premium feature, please
        <a href="/pricing" target="_blank" className="text-danger">
          {" "}
          Upgrade Your Subscription
        </a>{" "}
        to generate your Keemut Report.
        <a href="/sample-report" target="_blank" className="text-danger">
          {" "}
          Click Here
        </a>{" "}
        to view the sample report.
      </div>
    );
  }

  return (
    <div>
      <div class="alert alert-info alert-dismissible fade show" role="alert">
        <p>
          You can manage your Keemut Reports here. Once published, your report
          and its data will be publicly accessible.{" "}
        </p>
      </div>

      <div className="row">
        <div className="col-lg-3">
          <VehicleInformation vehicle={vehicle} />
        </div>

        <div className="col-lg-9">
          <KeemutReportConfig vehicle={vehicle} />
        </div>
      </div>

      <VinDialog
        showVinDialog={showVinDialog}
        closeVinDialog={() => setState({ ...state, showVinDialog: false })}
      />

      <Dialog
        open={showPublishDialog}
        onClose={() => {
          setState({ ...state, showPublishDialog: false });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div class="modal-content">
          <div class="modal-header modal-colored-header bg-primary">
            <h4 class="modal-title" id="primary-header-modalLabel">
              Ready To Publish ?
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => {
                setState({ ...state, showPublishDialog: false });
              }}
            >
              ×
            </button>
          </div>
          <div class="modal-body" style={{ fontSize: "14px" }}>
            Your Keemut Report will publish the following things :
            <br />
            <br />
            <p style={{ fontSize: "14px" }}>🏅 Basic information</p>
            <p style={{ fontSize: "14px" }}>🚗 Driving information</p>
            <p style={{ fontSize: "14px" }}>
              ⛽ Battery/fuel economy information
            </p>
            <p style={{ fontSize: "14px" }}>🛠️ Maintenance Information </p>
            <p style={{ fontSize: "14px" }}>📡 Telematics Information </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light"
              data-dismiss="modal"
              onClick={() => {
                setState({ ...state, showPublishDialog: false });
              }}
            >
              Close
            </button>

            <button
              type="button"
              class="btn btn-primary"
              onClick={publishReport}
            >
              Agree
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={showPublishedDialog}
        onClose={() => {
          setState({ ...state, showPublishedDialog: false });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="text-center">
              <i class=" mdi mdi-book-open-variant h1 text-warning"></i>
              <h4 class="mt-2">Report Published</h4>
              <p class="mt-3">
                Your Report Is Live at :{" "}
                <a
                  href={`https://beta.keemut.com/keemut-report?vin=${vehicle.vin}`}
                  style={{ color: "blue" }}
                >
                  https://beta.keemut.com/keemut-report?vin={vehicle.vin}
                </a>
              </p>
              <button
                type="button"
                class="btn btn-warning my-2"
                data-dismiss="modal"
                onClick={() => {
                  setState({ ...state, showPublishedDialog: false });
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default KeemutReportSettings;
