import simpleFetch from "../../helpers/fetch-wrapper";

const TataService = {
  getTataMetaByUid: async () => {
    return await simpleFetch(`/tata/meta`, "GET");
  },

  deleteTataMetaByUid: async () => {
    return await simpleFetch(`/tata/meta`, "DELETE");
  },

  /**
   *
   * @param {String} vin
   * @returns
   */
  getLiveData: async (vin) => {
    return await simpleFetch(
      `/tata/live-data`,
      "POST",
      JSON.stringify({ vin })
    );
  },

  /**
   *
   * @param {String} vin
   * @returns
   */
  getDailyAvgBatteryLife: async (vin) => {
    return await simpleFetch(`/tata/daily-battery-life/${vin}`, "GET");
  },

  getDailyAvgWattPerMile: async (vin) => {
    return await simpleFetch(`/tata/daily-watt-per-mile/${vin}`, "GET");
  },

  getDailyAvgEfficiency: async (vin) => {
    return await simpleFetch(`/tata/daily-efficiency/${vin}`, "GET");
  },

  getDailySummary: async (vin) => {
    return await simpleFetch(`/tata/daily-summary/${vin}`, "GET");
  },

  /**
   *
   * @param {String} vin
   * @param {String} filter
   * @returns
   */
  getTrips: async (vin, filter) => {
    return await simpleFetch(
      `/tata/trips`,
      "POST",
      JSON.stringify({
        vin,
        filter,
      })
    );
  },
};

export default TataService;
