import React, { useEffect, useState } from "react";
import TataService from "../../../../services/connected-cars/tata-service";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";
import NumberFormat from "../../../../helpers/number-format-helper";
import { convertTimeToTimezone } from "../../../../helpers/date-time-helper";
const TataLiveData = ({ vehicle, updateOdometer }) => {
  const [state, setState] = useState({
    loading: true,
    vehicleState: "",
    batteryLevel: null,
    batteryRange: null,
    doors: "Unlocked",
    sentryMode: "Off",
    timestamp: "",
    odometer: null,
    address: "",
    loadingStatus: null,
    nextServiceDueInDays: null,
    nextServiceDueInKm: null,
    chargerStatus: "DISCONNECTED",

    commandLoading: false,
    ac: false,
  });

  const {
    address,
    ac,
    timestamp,
    batteryLevel,
    batteryRange,
    chargerStatus,
    nextServiceDueInDays,
    nextServiceDueInKm,
    odometer,
    doors,
    loading,
    sentryMode,
    vehicleState,
    loadingStatus,
    commandLoading,
  } = state;

  useEffect(() => {
    async function getTataLiveData() {
      try {
        setState({
          ...state,
          loading: true,
          loadingStatus: "Communicating With Tata Servers...",
        });

        const data = await TataService.getLiveData(vehicle.vin);
        console.log(data);
        setState({ ...state, loadingStatus: "Doing some calculations..." });

        const startRes = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${data.result.state.gpsLatitude}&lon=${data.result.state.gpsLongitude}`,
          {
            method: "GET",
          }
        );

        const i = await startRes.json();

        updateOdometer(data.result.state.odometer)
        setState({
          ...state,
          odometer: data.result.state.odometer,
          vehicleState: data.result.state.speed > 0 ? "DRIVING" : data.result.chargingTelemetry.hvChargerConnectFlag ? "CHARGING": "IDLE",
          batteryLevel: data.result.health.hvBatterySocPercentage,
          batteryRange: data.result.health.distanceToEmpty,
          nextServiceDueInDays: data.result.health.nextServiceDueDays,
          nextServiceDueInKm: data.result.health.nextServiceDueKm,
          ac: data.result.state.airConditionOn ? "ON" : "OFF",
          doors:
            data.result.health.leftFrontDoorOpenFlag ||
            data.result.health.leftRearDoorOpenFlag ||
            data.result.health.RightRearDoorOpenFlag ||
            data.result.health.RightFrontDoorOpenFlag
              ? "Open"
              : "Closed",
          timestamp: moment
            .utc(data.result.state.eventDateTime)
            .add(5.5, "hour")
            .format("DD-MMM-YY HH:MM a"),
          address: i.display_name,
          loading: false,
          loadingStatus: null,
          chargerStatus: data.result.state.speed <= 0 && data.result.chargingTelemetry.hvChargerConnectFlag 
            ? "CONNECTED"
            : "DISCONNECTED",
        });

        // console.log();
      } catch (err) {
        console.log(err);
        setState({ ...state, loading: true });
        alert("We were unable to get the live stats for your Tata");
      }
    }

    getTataLiveData();
  }, []);

  return (
    <div className="card card-body" style={{ height: "100%" }}>
      <div>
        <div style={{ float: "left" }}>
          <h3 style={{ display: "inline-block" }}>
            Live Stats | As of {timestamp}
          </h3>
        </div>
      </div>

      {loading && (
        <div>
          <span>{loadingStatus}</span>
          <span className="ml-3 spinner spinner-border center-spinner"></span>
        </div>
      )}

      {!loading && (
        <div className="row">
          <div className="col-lg-6">
            <table className="mt-3 table-striped">
              <tbody>
                <tr>
                  <td>
                    <i className=" mdi mdi-car-info text-warning mr-1"></i>
                    <strong>Vehicle State: </strong>
                  </td>
                  <td>
                    {loading ? (
                      <div className="spinner spinner-border center-spinner"></div>
                    ) : (
                      <span className="text-warning">{vehicleState}</span>
                    )}
                  </td>
                </tr>

                <tr>
                  <td>
                    <i className=" mdi mdi-battery-medium text-primary mr-1"></i>{" "}
                    <strong>Battery Level:</strong>
                  </td>
                  <td>
                    <span>
                      {" "}
                      {batteryLevel}% ({batteryRange} km){" "}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <i className=" mdi mdi-shield-lock-outline text-secondary mr-1"></i>
                    <strong>Doors:</strong>{" "}
                  </td>
                  <td>
                    <span className="text-success">{doors}</span>{" "}
                  </td>
                </tr>

                <tr>
                  <td>
                    <i className=" mdi mdi-shield-lock-outline text-secondary mr-1"></i>
                    <strong>Odometer</strong>{" "}
                  </td>
                  <td>
                    <span>{NumberFormat(odometer)} km</span>{" "}
                  </td>
                </tr>

                <tr>
                  <td>
                    <i className=" mdi mdi-crosshairs-gps text-success mr-1"></i>{" "}
                    <strong>Current Location:</strong>
                  </td>
                  <td style={{ textOverflow: "ellipsis" }}>
                    <a
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${address}`}
                      style={{ color: "blue" }}
                      title={address}
                    >
                      {" "}
                      <LinesEllipsis
                        text={address}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-lg-6">
            <table className="mt-3 table-striped">
              <tbody>
                <tr>
                  <td>
                    <i className=" mdi mdi-car-info text-warning mr-1"></i>
                    <strong>Service Due In </strong>
                  </td>
                  <td>
                    {loading ? (
                      <div className="spinner spinner-border center-spinner"></div>
                    ) : (
                      <span>
                        {nextServiceDueInDays} Days | {nextServiceDueInKm}km
                      </span>
                    )}
                  </td>
                </tr>

                <tr>
                  <td>
                    <i className=" mdi mdi-battery-medium text-primary mr-1"></i>{" "}
                    <strong>Charger Status:</strong>
                  </td>
                  <td>
                    <span
                      className={
                        chargerStatus == "CONNECTED"
                          ? "text-success"
                          : "text-danger"
                      }
                    >
                      {chargerStatus}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <i className=" mdi mdi-shield-lock-outline text-secondary mr-1"></i>
                    <strong>A/C Status:</strong>{" "}
                  </td>
                  <td>
                    <span className="text-success">{ac}</span>{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default TataLiveData;
