import React, { useState, useEffect } from "react";
import {
  API,
  STRIPE_RTA_SUBSCRIPTION_MONTHLY,
  STRIPE_RTA_SUBSCRIPTION_YEARLY,
} from "../../../config";

const Rta = () => {
  const [state, setState] = useState({
    isSubscribed: false,
  });
  const { isSubscribed } = state;

  useEffect(() => {
    let mounted = true;
    const user = JSON.parse(localStorage.getItem("user"));

    const getSubscriptionStatus = async () => {
      try {
        const res = await fetch(`${API}/subscription/${user.subscription_id}`, {
          method: "GET",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const data = await res.json();

        // Check if base subscription is active
        if (data.subscription && data.subscription.status == "active") {
          data.subscription.items.data.forEach((obj) => {
            // check if one of the items is rta and its quantity > 0
            if (
              obj.price.id == STRIPE_RTA_SUBSCRIPTION_MONTHLY ||
              obj.price.id == STRIPE_RTA_SUBSCRIPTION_YEARLY
            ) {
              if (obj.quantity > 0) {
                setState({ ...state, isSubscribed: true, loading: false });
              }
            }
          });
        } else {
          setState({ ...state, isSubscribed: false, loading: false });
        }
      } catch (err) {
        setState({ ...state, isSubscribed: false, loading: false });
      }
    };

    if (mounted) {
      getSubscriptionStatus();
    }

    return function () {
      mounted = false;
    };
  }, []);

  return (
    <>
      <div
        class="alert alert-primary text-center"
        role="alert"
        style={{ width: "100%" }}
      >
        {!isSubscribed
          ? "You are not subscribed to RTA-RMS Add-on,"
          : "You are subscribed to RTA-RMS"}
        {!isSubscribed && (
          <a href="/pricing" className="text-danger">
            {" "}
            Upgrade Your Subscription
          </a>
        )}
      </div>
    </>
  );
};

export default Rta;
