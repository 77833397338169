import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../../config";
import moment from "moment";
import regression from "regression";
import KalmanFilter from "kalmanjs";
import { Popup } from "semantic-ui-react";
import resolveCurrency from "../../../helpers/Helper";

const BatteryLifeChart = ({ vehicle, units }) => {
  const [state, setState] = useState({
    options: {
      chart: {
        foreColor: "#000000",

        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 5,
          color: "#00274c",
          opacity: 0.45,
        },
      },

      yaxis: { title: { text: "Battery Life (%)" } },
      xaxis: {
        categories: [],
      },
      colors: ["#00274c", "#ffbb002a"],

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2.75,
        dashArray: 0,
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
    valueLost: null,
    loading: true,
  });

  const { options, series, loading, valueLost } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getBatteryLife();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getBatteryLife = async () => {
    const token = localStorage.getItem("token");
    let url;

    // different calls with diff url that return same format data
    if (vehicle.make == "Tesla") {
      url = `/tesla/battery-life/${vehicle.vin}/?model=${vehicle.model}&trim=${vehicle.trim}`;
    } else {
      url = `/smartcar/battery-life-data/${vehicle.vin}`;
    }

    fetch(`${API}${url}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let xAxisData = [];
        let yAxisData = [];

        let min, max;
        data.batteryLifeData.forEach((d, i) => {});
        let sum = 0;
        for (let i = 0; i < data.batteryLifeData.length; i++) {
          let d = data.batteryLifeData[i];
          d.average_battery_life = parseFloat(d.average_battery_life);
          xAxisData.push(moment(d.date).format("DD-MMM-YY"));
          yAxisData.push(parseFloat(d.average_battery_life).toFixed(2));

          if (!min) {
            min = d.average_battery_life;
          } else if (min > d.average_battery_life) {
            min = d.average_battery_life;
          }

          if (!max) {
            max = d.average_battery_life;
          } else if (max < d.average_battery_life) {
            max = d.average_battery_life;
          }

          sum += parseFloat(d.average_battery_life);
        }
        console.log(min, max);
        let originalData = yAxisData;
        var kalmanFilter = new KalmanFilter({ R: 0.00000000001111, Q: 2 });

        yAxisData = yAxisData.map(function (v) {
          return kalmanFilter.filter(v).toFixed(2);
        });

        let valLost =
          0.21 *
          vehicle.purchaseprice *
          ((100 - sum / data.batteryLifeData.length) / 100);

        // ((100 - sum / data.batteryLifeData.length) / 100) means degradation %

        let scatterData = [];
        let lineData = [];

        // console.log(min, max, max + 1, min - 1);

        setState({
          ...state,
          loading: false,
          options: {
            ...options,
            xaxis: {
              type: "Date",
              categories: xAxisData,
              title: { text: "Date" },
            },
            yaxis: {
              max: parseFloat(max) + 0.5,
              min: parseFloat(min) - 0.5,
            },
          },
          series: [
            {
              name: "Battery Life Normalised (%) ",
              type: "line",
              data: yAxisData,
            },
            {
              name: "Battery Life Observed (%) ",
              type: "line",
              data: originalData,
            },
          ],
          valueLost: valLost,
        });
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return (
      <div class="card">
        <div class="card-body">
          <h4>Battery Life</h4>

          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card" style={{ height: "100%" }}>
      <div class="card-body">
        <h3>
          {" "}
          Battery Life{" "}
          <Popup
            className="ml-4"
            content={
              "This chart show the degradation of your battery. Maintaining a SoC below 90 is advised for increasing the life span of your vehicle"
            }
            trigger={
              <i className="mdi mdi-information-outline hover-pointer"></i>
            }
          />
        </h3>
        {vehicle.make == "Tesla" && (
          <p>
            From degradation, your vehicle has lost{" "}
            <span className="text-danger">
              <strong>
                {resolveCurrency(units.currencyunit)}
                {valueLost && valueLost.toFixed(2)}
              </strong>
            </span>{" "}
            in valuation{" "}
          </p>
        )}
        <Chart options={options} series={series} />
      </div>
    </div>
  );
};

export default BatteryLifeChart;
