import React, { useState, useEffect } from "react";
import { API } from "../../../../config";
import resolveCurrency from "../../../../helpers/Helper";
import moment from "moment";
import EditInuranceDialog from "./EditInsuranceDialog";
import DeleteInsuranceDialog from "./DeleteInsuranceDialog";
import { getAllInsuranceFormService } from "../../../../services/forms/insurance-form-service";

const InsuranceLogbook = ({ vehicle, units }) => {
  const [state, setState] = useState({
    loading: true,
    insuranceReports: [],
    paginations: 0,
    currentPage: 1,
    showEditDialog: false,
    showViewDialog: false,
    maintenanceReportId: null,
    showDeleteDialog: false,
    error: null,
    selectedInsuranceReport: null,
  });
  const {
    loading,
    insuranceReports,
    paginations,
    currentPage,
    maintenanceReportId,
    showViewDialog,
    showEditDialog,
    showDeleteDialog,
    error,
    selectedInsuranceReport,
  } = state;

  useEffect(() => {
    getAllInsurance();
  }, [vehicle]);
  const getAllInsurance = () => {
    const token = localStorage.getItem("token");
    setState({
      ...state,
      loading: true,
      showEditDialog: false,
      showDeleteDialog: false,
      error: null,
    });
    getAllInsuranceFormService(vehicle.id)
      .then((data) => {
        setState({
          ...state,
          loading: false,
          insuranceReports: data.insuranceReports,
          paginations: Math.abs(data.insuranceReports.length / 10) + 1,
          showEditDialog: false,
          showDeleteDialog: false,
          error: null,
        });
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          loading: false,
          error: "Unable to fetch your Insurance History at this time.",
        });
      });
  };
  const renderPagination = () => {
    let content = [];
    for (let i = 1; i <= paginations; i++) {
      content.push(
        <a
          onClick={() => setState({ ...state, currentPage: i })}
          class="page-link"
          style={
            currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
      );
    }
    return <> {content} </>;
  };

  const sortByPropertyAsc = (propertyName) => {
    function compare(v1, v2) {
      if (propertyName === "maintCost") {
        const valueA = parseFloat(v1["maintCost"]);
        const valueB = parseFloat(v2["maintCost"]);
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      }

      const valueA =
        typeof v1[propertyName] === "string"
          ? v1[propertyName].toUpperCase()
          : v1[propertyName];
      const valueB =
        typeof v2[propertyName] === "string"
          ? v2[propertyName].toUpperCase()
          : v2[propertyName];

      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      return comparison;
    }
    const values = insuranceReports;
    values.sort(compare);
    setState({ ...state, insuranceReports: values });
  };

  const sortByPropertyDesc = (propertyName) => {
    function compare(v1, v2) {
      if (propertyName === "cost") {
        const valueA = parseFloat(v1["cost"]);
        const valueB = parseFloat(v2["cost"]);
        console.log(v1);
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      } else {
        const valueA =
          typeof v1[propertyName] === "string"
            ? v1[propertyName].toUpperCase()
            : v1[propertyName];
        const valueB =
          typeof v2[propertyName] === "string"
            ? v2[propertyName].toUpperCase()
            : v2[propertyName];

        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      }
    }
    const values = insuranceReports;
    values.sort(compare);
    setState({ ...state, insuranceReports: values.reverse() });
  };

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h2 class="header-title">Insurance History</h2>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card">
        <div className="card-body">
          <h2 class="header-title">Insurance History</h2>
          <div
            class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
            role="alert"
          >
            <strong>Error - </strong> {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <a href="/dashboard/insurance">
        <div
          class="btn btn-primary text-center "
          role="alert"
          style={{ width: "100%" }}
        >
          Add Insurance
        </div>
      </a>
      <div className="card-body">
        <h2>Insurance History</h2>
        <p>A history of your Insurance reports.</p>
        <table className="table-striped">
          <thead>
            <tr>
              <th>
                Date{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("date")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("date")}
                ></i>
              </th>

              <th>
                Renewal Date{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("renewaldate")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("renewaldate")}
                ></i>
              </th>
              <th>
                Type{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("expensetype")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("expensetype")}
                ></i>
              </th>
              <th>
                Cost{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("cost")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("cost")}
                ></i>
              </th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {insuranceReports.map((record, i) => {
              return i < currentPage * 10 && i >= (currentPage - 1) * 10 ? (
                <tr key={record.id}>
                  <td data-label="Date">
                    {moment(record.date.split("T")[0]).format("DD-MMM-YYYY")}
                  </td>
                  <td data-label="Renewal Date">
                    {moment(record.renewaldate.split("T")[0]).format(
                      "DD-MMM-YYYY"
                    )}
                  </td>
                  <td data-label="type">
                    {record.expensetype &&
                      record.expensetype
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td data-label="Cost">
                    {resolveCurrency(units.currencyunit)}{" "}
                    {record.cost &&
                      parseInt(record.cost)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>

                  <td data-label="Action">
                    <i
                      title="Edit Insurance"
                      className="mdi mdi-pencil"
                      onClick={() =>
                        setState({
                          ...state,
                          selectedInsuranceReport: record,
                          showEditDialog: true,
                        })
                      }
                    ></i>

                    <i
                      title="Delete Insurance"
                      className=" mdi mdi-delete ml-3"
                      onClick={() =>
                        setState({
                          ...state,
                          selectedInsuranceReport: record,
                          showDeleteDialog: true,
                        })
                      }
                    ></i>
                    {record.attachment && (
                      <a href={record.attachment} target="_blank">
                        <i
                          title="View / Download Attachment"
                          className=" mdi mdi-file-multiple mx-3"
                        ></i>
                      </a>
                    )}
                  </td>
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
        <br />
        <nav aria-label="Page navigation example" style={{ float: "right" }}>
          <ul class="pagination">{renderPagination()}</ul>
        </nav>
        {selectedInsuranceReport && (
          <>
            <EditInuranceDialog
              closeEditDialog={getAllInsurance}
              units={units}
              showEditDialog={showEditDialog}
              insuranceReport={selectedInsuranceReport}
              vehicle={vehicle}
            />

            <DeleteInsuranceDialog
              showDelete={showDeleteDialog}
              vehicle={vehicle}
              revenueReport={selectedInsuranceReport}
              closeDeleteDialog={getAllInsurance}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InsuranceLogbook;
