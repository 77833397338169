import React, { useState, useEffect } from "react";
import { API } from "../../../../config";
import VinDialog from "../VinDialog";
import simpleFetch from "../../../../helpers/fetch-wrapper";

const KeemutReportConfig = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: true,
    published: false,
    showVinDialog: false,
    showPublishDialog: false,
    showPublishedDialog: false,
    publishing: false,
    error: null,
    published: false,
    basicInformation: false,
    drivingInformation: false,
    fuelInformation: false,
    maintenanceInformation: false,
    telematicsInformation: false,

    views: null,
    amountSold: null,
    revenue: null,
    free: false,
  });
  const {
    loading,
    amountSold,
    revenue,
    error,
    views,
    published,
    showVinDialog,
    showPublishDialog,
    showPublishedDialog,
    basicInformation,
    drivingInformation,
    fuelInformation,
    maintenanceInformation,
    telematicsInformation,
    publishing,
    free,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      isReportPublished();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const isReportPublished = async () => {
    try {
      setState({ ...state, loading: true });
      const res = await fetch(
        `${API}/keemut-report/exists?vehicleId=${vehicle.id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await res.json();

      const salesData = await simpleFetch(
        `/keemut-report/sales-stats/${
          data.keemutReport && data.keemutReport.id
        }`,
        "GET"
      );

      if (data.done) {
        setState({
          ...state,
          loading: false,
          published: data.exists,
          basicInformation: data.keemutReport.basic_information,
          drivingInformation: data.keemutReport.driving_information,
          telematicsInformation: data.keemutReport.telematics_information,
          fuelInformation: data.keemutReport.fuel_information,
          maintenanceInformation: data.keemutReport.maintenance_information,
          amountSold: salesData.stats ? salesData.stats.count : 0,
          revenue: salesData.stats ? salesData.stats.revenue : 0,
          views: data.keemutReport ? data.keemutReport.views : 0,

          published: data.keemutReport.published,
        });
      } else {
        setState({
          ...state,
          loading: false,
          published: false,
          publishing: false,
        });
      }
    } catch (err) {
      setState({
        ...state,
        loading: false,
        published: false,
        publishing: false,
      });
    }
  };

  const publishReport = async () => {
    try {
      if (!vehicle.vin) {
        return setState({ ...state, showVinDialog: true });
      }

      setState({ ...state, publishing: true });
      const res = await fetch(`${API}/keemut-report/publish/${vehicle.id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          vin: vehicle.vin,
          cost: computeTotal(),
          basicInformation,
          drivingInformation,
          fuelInformation,
          maintenanceInformation,
          telematicsInformation,
          published: published,
          free,
        }),
      });

      const data = await res.json();

      if (data.done) {
        isReportPublished();
      } else {
        setState({
          ...state,
          error: "Unable to publish your report at the moment",
          publishing: false,
          showVinDialog: false,
        });
      }
    } catch (err) {
      setState({
        ...state,
        error: "Unable to publish your report at the moment",
        publishing: false,
        showVinDialog: false,
      });
    }
  };

  const computeTotal = () => {
    let total = 0;

    if (drivingInformation) {
      total += 10;
    }

    if (fuelInformation) {
      total += 10;
    }

    if (telematicsInformation) {
      total += 0;
    }

    if (maintenanceInformation) {
      total += 30;
    }

    return total.toFixed(2);
  };

  if (loading) {
    return <div className="spinner-border text-primary" role="status" />;
  }

  return (
    <div className="card card-body" style={{ height: "100%" }}>
      <div className="row mt-3">
        <div class="col-lg-4">
          <div class="card widget-flat">
            <div class="card-body">
              <div class="float-right">
                <i class=" mdi mdi-eye widget-icon"></i>
              </div>
              <h5
                class="text-muted font-weight-normal mt-0"
                title="Number of Orders"
              >
                Views
              </h5>
              <h3 class="mt-3 mb-3">{views}</h3>
              <p class="mb-0 text-muted">
                <span class="text-nowrap">Number of Views</span>
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card widget-flat">
            <div class="card-body">
              <div class="float-right">
                <i class="mdi mdi-cart-plus widget-icon"></i>
              </div>
              <h5
                class="text-muted font-weight-normal mt-0"
                title="Number of Orders"
              >
                Sold
              </h5>
              <h3 class="mt-3 mb-3">{amountSold}</h3>
              <p class="mb-0 text-muted">
                <span class="text-nowrap">Amount of reports sold</span>
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card widget-flat">
            <div class="card-body">
              <div class="float-right">
                <i class=" mdi mdi-currency-usd widget-icon"></i>
              </div>
              <h5
                class="text-muted font-weight-normal mt-0"
                title="Number of Orders"
              >
                Revenue
              </h5>
              <h3 class="mt-3 mb-3">{revenue}</h3>
              <p class="mb-0 text-muted">
                <span class="text-nowrap">Total Revenue</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-2">
          <h4 className="overview-dialog-label">Report Status</h4>
        </div>
        <div className="col-lg-1">
          <input
            type="checkbox"
            id="switch0"
            data-switch="none"
            checked={published}
            onChange={() => setState({ ...state, published: !published })}
          />
          <label for="switch0" data-on-label="" data-off-label=""></label>
        </div>
        <div className="col-lg-3">
          <p>{published ? "Published" : "Not Published"}</p>
        </div>

        <div className="col-lg-3">
          <h4 className="overview-dialog-label">Make the report Free</h4>
        </div>
        <div className="col-lg-1">
          <input
            type="checkbox"
            id="switch1"
            data-switch="none"
            checked={free}
            onChange={() => setState({ ...state, free: !free })}
          />
          <label for="switch1" data-on-label="" data-off-label=""></label>
        </div>
      </div>
      <table className="text-center table-striped">
        <thead>
          <tr>
            <th>Active</th>
            <th>Item</th>
            <th>Price</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <input
                type="checkbox"
                checked={basicInformation}
                onChange={(e) => {
                  setState({ ...state, basicInformation: !basicInformation });
                }}
              />
            </td>
            <td>Basic Information</td>
            <td>Free</td>
          </tr>

          <tr>
            <td>
              <input
                type="checkbox"
                checked={drivingInformation}
                onChange={(e) => {
                  setState({
                    ...state,
                    drivingInformation: !drivingInformation,
                  });
                }}
              />
            </td>
            <td>Driving Information</td>
            <td>$10.00</td>
          </tr>

          <tr>
            <td>
              <input
                type="checkbox"
                checked={fuelInformation}
                onChange={(e) => {
                  setState({ ...state, fuelInformation: !fuelInformation });
                }}
              />
            </td>
            <td>Battery / Fuel History</td>
            <td>$10.00</td>
          </tr>

          <tr>
            <td>
              <input
                type="checkbox"
                checked={maintenanceInformation}
                onChange={(e) => {
                  setState({
                    ...state,
                    maintenanceInformation: !maintenanceInformation,
                  });
                }}
              />
            </td>
            <td>Maintenance Information</td>
            <td>$30.00</td>
          </tr>

          {/* <tr>
            <td>
              <input
                type="checkbox"
                checked={telematicsInformation}
                onChange={(e) => {
                  setState({
                    ...state,
                    telematicsInformation: !telematicsInformation,
                  });
                }}
                disabled={true}
              />
            </td>
            <td>Telematics Information</td>
            <td>$20.00</td>
          </tr> */}

          <tr style={{ borderTop: "2px solid #000" }}>
            <td title="Excluding Keemut Deductions">Total Cost</td>
            <td> - </td>
            <td>${computeTotal()}</td>
          </tr>
        </tbody>
      </table>

      {/* <div className="row">
        
      </div> */}
      {published && (
        <p className="mt-3">
          <a
            className=" btn btn-success btn-sm"
            target="_blank"
            href={`https://beta.keemut.com/keemut-report/${vehicle.vin}`}
          >
            View Report
          </a>
        </p>
      )}

      <button className="mt-4 btn  btn-primary" onClick={publishReport}>
        {publishing ? "Saving..." : "Save"}
      </button>

      <VinDialog
        showVinDialog={showVinDialog}
        closeVinDialog={() => setState({ ...state, showVinDialog: false })}
      />
    </div>
  );
};

export default KeemutReportConfig;
