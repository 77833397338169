import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import KeemutReportBanner from "./KeemutReportBanner";
import { API, site } from "../../config";
import VehicleInfo from "./VehicleInfo";
import LogoResolver from "../../helpers/LogoResolver";
import BatteryInfo from "./BatteryInfo";
import TrackingHistory from "./TrackingHistory";
import ValuationBanner from "./ValuationBanner";
import ValuationChart from "./ValuationChart";
import DummyRadials from "./DummyRadials";
import OdometerCard from "./OdometerCard";
import TcoCard from "./TcoCard";
import DistancePerMonth from "./DistancePerMonth";
import OdometerHistory from "./OdometerHistory";
import MaintenanceBreakdown from "./MaintenanceBreakdown";
import DrivingBreakdown from "./DrivingBreakdown";
import html2canvas from "html2canvas";
import MaintenanceHistory from "./MaintenanceHistory";
import LocationHeatmap from "../dashboard/trip/LocationHeatmap";
import queryString from "query-string";
import simpleFetch from "../../helpers/fetch-wrapper";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/searching.json";
import SampleReport from "../sample-report-ev/SampleKeemutReport";

const mixpanel = require("mixpanel-browser");
const ref = React.createRef();

const KeemutReport = (props) => {
  const [state, setState] = useState({
    _loading: true,
    _vin: useParams().vin,
    _notFound: false,
    _units: null,
    _vehicle: null,
    _free: false,
    _isAccessible: false,
    _status: null,
    _showFaheemsReport: false,
  });
  const {
    _loading,
    _vin,
    _notFound,
    _vehicle,
    _units,
    _isAccessible,
    _free,
    _status,
    _showFaheemsReport,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      isReportPublished();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const isReportPublished = async () => {
    try {
      // i hate hardcodes like these
      if (_vin == "5KBRL5891DB702688")
        return setState({ ...state, _showFaheemsReport: true });

      setState({
        ...state,
        _status: "Searching for the Keemut Report...",
        _loading: true,
      });

      let params = queryString.parse(props.location.search);
      let refId = params.referrer; // referrer to track
      if (refId) localStorage.setItem("referrer", refId);

      // track mix panel
      mixpanel.track("keemut-report-visit", {
        vin: _vin,
        referrer: refId,
      });

      // Does the keemut report exists
      const data = await simpleFetch(
        `/keemut-report/exists?vin=${_vin}`,
        "GET"
      );

      // update status
      setState({
        ...state,
        _status: "Fetching the vehicle info...",
      });

      // get vehicle info
      const vdata = await simpleFetch(`/keemut-report/vehicles/${_vin}`, "GET");

      // update status
      setState({
        ...state,
        _status: "Crunching some numbers...",
      });

      // get units of vehicle
      const udata = await simpleFetch(
        `/units/${vdata.vehicle.fkusersuid}`,
        "GET"
      );

      let uid;

      const userJson = localStorage.getItem("user");

      if (userJson) {
        uid = JSON.parse(userJson).uid;
      }

      if (userJson) {
        const u = JSON.parse(userJson);
        if (u.email == "test2@dashroad.com" || u.email == "anuj@dashroad.com")
          return setState({
            ...state,
            _loading: false,
            _notFound: false,
            _vehicle: vdata.vehicle,
            _units: udata.units,
            _isAccessible: true,
            _free: data.keemutReport.is_free,
          });
      }

      // is keemut report accessible to this user
      const accessibleData = await simpleFetch(
        `/keemut-report/is-accessible/${
          data.keemutReport ? data.keemutReport.id : null
        }/${uid}`,
        "GET"
      );

      // a user should be able to view their own car's report without paying
      if (uid == data.keemutReport.fkusersuid) {
        return setState({
          ...state,
          _loading: false,
          _notFound: false,
          _vehicle: vdata.vehicle,
          _units: udata.units,
          _isAccessible: true,
        });
      }

      if (data.done) {
        // no report found
        if (!data.exists) {
          setState({ ...state, _loading: false, _notFound: true });
        } else if (data.keemutReport && !data.keemutReport.published) {
          // not public
          setState({ ...state, _loading: false, _notFound: true });
        } else {
          // public and found
          setState({
            ...state,
            _loading: false,
            _notFound: false,
            _vehicle: vdata.vehicle,
            _units: udata.units,
            _isAccessible: data.keemutReport.is_free
              ? true
              : accessibleData.purchasedReport
              ? true
              : false,
            _free: refId ? true : data.keemutReport.is_free,
          });
        }
      }
    } catch (err) {
      setState({ ...state, _loading: false, _notFound: true });
    }
  };

  // * Download the report as an image
  // ! Known bug, the generated image has a glitched out image of the valuation graph, idk why ?!?!
  function _downloadBase64File(contentType, base64Data, fileName) {
    const linkSource = `${base64Data}`;
    console.log(linkSource);
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const reportBody = (
    <div id="keemut-report_report_body">
      <div className="row">
        <div className="col-lg-12">
          <ValuationBanner vehicle={_vehicle} units={_units} />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-7">
          <ValuationChart vehicle={_vehicle} units={_units} />
        </div>
        <div className="col-lg-5">
          <DummyRadials vehicle={_vehicle} />
        </div>
        {/*  */}
      </div>

      <div className="card">
        <div className="card-body">
          <h3>Driving Information</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4">
          <OdometerCard vehicle={_vehicle} units={_units} />
        </div>

        <div className="col-lg-4">
          <TcoCard vehicle={_vehicle} units={_units} />
        </div>

        <div className="col-lg-4">
          <DistancePerMonth vehicle={_vehicle} units={_units} />
        </div>
      </div>
      {_vehicle && _vehicle.is_smartcar && (
        <div className="row">
          <div className="col-lg-12">
            <LocationHeatmap vehicle={_vehicle} />
          </div>
        </div>
      )}
      {_vehicle &&
        (_vehicle.vin == "1HM888055H7DDA033" ||
          _vehicle.vin == "1HM888055H7DDA034") && (
          <div className="row">
            <div className="col-lg-12">
              <LocationHeatmap vehicle={_vehicle} />
            </div>
          </div>
        )}
      <div className="row">
        <div className="col-lg-7">
          <OdometerHistory vehicle={_vehicle} units={_units} />
        </div>

        <div className="col-lg-5">
          <DrivingBreakdown vehicle={_vehicle} />
        </div>
      </div>
      <br />
      <div className="card">
        <div className="card-body">
          <h3>Maintenance Information</h3>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-lg-12">
          <MaintenanceBreakdown vehicle={_vehicle} units={_units} />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <MaintenanceHistory vehicle={_vehicle} units={_units} />
        </div>
      </div>
    </div>
  );

  if (_showFaheemsReport) return <SampleReport />;

  if (_loading) {
    return (
      <div>
        <div className="text-center mt-4">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={400}
            width={400}
            isStopped={false}
            isPaused={false}
          />
          <h3>{_status}</h3>
        </div>
      </div>
    );
  }

  if (_notFound) {
    window.location = `${site}/sample-report/?vin=${_vin}`;
    return <> </>;
  }

  return (
    <div
      id="keemut-report"
      className="keemut-report-padding"
      style={{ backgroundColor: "#ffffff", font: "Roboto" }}
    >
      <KeemutReportBanner />

      {/* <button
        id="save"
        className="btn btn-success my-3"
        onClick={() => {
          const input = document.getElementById("keemut-report");
          html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");

            _downloadBase64File(
              "image/jpeg",
              imgData,
              `Keemut_Report__${vin}.jpeg`
            );
          });
        }}
      >
        <i class="mdi mdi-download mr-1"></i> Download Report
      </button> */}

      <div className="card">
        <div className="card-body">
          <h3>Vehicle Information</h3>
        </div>
      </div>

      <div className="row my-4" ref={ref}>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 my-5 text-center">
                  <img
                    src={LogoResolver(_vehicle.make)}
                    width="100"
                    height="75"
                  ></img>
                </div>

                <div className="col-lg-3">
                  <VehicleInfo vehicle={_vehicle} />
                </div>

                <div className="col-lg-3">
                  <BatteryInfo vehicle={_vehicle} units={_units} />
                </div>

                <div className="col-lg-3">
                  <TrackingHistory vehicle={_vehicle} units={_units} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!_isAccessible && !_free ? (
        <div className="card card-body rounded shadow-lg">
          <h2 className="text-center">
            Want To See More ? Purchase The Premium Report to get full access.
          </h2>
          <p className=" my-4 " style={{ fontSize: "16px" }}>
            Purchase the premium report to get access to more details about this
            vehicle. This is a <b>Live Report</b>. You will have access to this
            report for 7 days after which the access will expire.
          </p>
          <p style={{ fontSize: "16px" }}>
            To see a sample report, <a href="/sample-report">Click Here</a>
          </p>

          <div className="text-center">
            <a
              target="_blank"
              href={`/checkout/?vin=${_vehicle.vin}&priceId=keemut-report&make=${_vehicle.make}&model=${_vehicle.model}`}
            >
              <button
                className="btn btn-lg  btn-primary "
                style={{ width: "300px" }}
              >
                Purchase Report
              </button>
            </a>
          </div>
        </div>
      ) : (
        <div> </div>
        // reportBody
      )}

      {_isAccessible && reportBody}

      <footer className="text-center">
        <div>
          Keemut depends on data from the connected vehicle itself. If the
          connection is not active, data can be missed, therefore, no
          responsibility is assumed by Keemut or its affiliates for any
          omissions in this report.
        </div>
        <div>
          © {new Date().getFullYear()} Keemut, LLC. All rights reserved.{" "}
          <strong>Patent US9799058B2</strong>, other patents pending
        </div>
      </footer>
    </div>
  );
};

export default KeemutReport;
