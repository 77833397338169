import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
const Dials = () => {
  const [state, setState] = useState({
    options: {
      chart: {
        height: 500,
        type: "radialBar",
        background: "#ffffff",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 5,
            size: "45%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: true,
            },
            value: {
              show: false,
            },
            total: {
              show: true,
              label: "Fuel Economy",
              color: "#373d3f",
              fontSize: "16px",
              fontFamily: undefined,
              fontWeight: 600,
            },
          },
        },
      },
      colors: ["#2F363F", "#333945", "#535C68", "#2C3335"],
      labels: ["Toyota", "Audi", "Tesla", "Ferrari"],
      legend: {
        show: true,
        floating: true,
        fontSize: "16px",
        position: "bottom",
        offsetX: 0,
        offsetY: 0,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        formatter: function (seriesName, opts) {
          return (
            seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
          );
        },
        itemMargin: {
          vertical: 3,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
      stroke: {
        dashArray: 2,
      },
    },
    series: [76, 67, 61, 90],
  });

  const { series, options } = state;

  return (
    <div className="row">
      <div className="col-lg-6">
        <Chart series={series} options={options} type="radialBar" />
      </div>
    </div>
  );
};

export default Dials;
