import React from "react";

const DrivingStats = ({ vehicle }) => {
  return (
    <div className="text-dark">
      <h3 className="page-title" style={{ letterSpacing: "1.2px" }}>
        {vehicle.name + "'s "}
        Driving Stats{" "}
      </h3>

      <div className="row mt-4">
        <div className="col-lg-4">
          <div class="card widget-flat">
            <div class="card-body">
              <div class="float-right">
                <i class=" mdi mdi-gauge widget-icon bg-success-lighten text-success"></i>
              </div>
              <h5 class="text-dark  mt-0" title="Distance Driven till date">
                Average Drive Efficiency{" "}
                <i className="ml-3 mdi mdi-information-outline hover-pointer"></i>
              </h5>
              <h3 class="mt-3 mb-3"> 56%</h3>
              <p className="text-danger">15% Below Average</p>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div class="card widget-flat">
            <div class="card-body">
              <div class="float-right">
                <i class=" mdi mdi-gauge widget-icon bg-success-lighten text-success"></i>
              </div>
              <h5 class="text-dark  mt-0" title="Distance Driven till date">
                Average Watt Per Mile{" "}
                <i className="ml-3 mdi mdi-information-outline hover-pointer"></i>
              </h5>
              <h3 class="mt-3 mb-3"> 423 W/mi</h3>
              <p className="text-danger">15% Below Average</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div class="card widget-flat">
            <div class="card-body">
              <div class="float-right">
                <i class=" mdi mdi-gauge widget-icon bg-success-lighten text-success"></i>
              </div>
              <h5 class="text-dark  mt-0" title="Distance Driven till date">
                Average Daily Phantom Drain{" "}
                <i className="ml-3 mdi mdi-information-outline hover-pointer"></i>
              </h5>
              <h3 class="mt-3 mb-3"> 12 kWh</h3>
              <p className="text-danger">30% Above Average</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <h4>Battery Degradation</h4>
        </div>
        <div className="col-lg-6">
          <h4>Recent Charges</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <h4>Recent Trips</h4>
        </div>
        <div className="col-lg-6">
          <h4>Odometer History</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <h4>Reminders</h4>
        </div>
        <div className="col-lg-6">
          <h4>Recent Charges</h4>
        </div>
      </div>
    </div>
  );
};

export default DrivingStats;
