import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { API } from "../../../config";

const DeleteRevenueDialog = ({
  revenueReport,
  showDelete,
  closeDeleteDialog,
}) => {
  const [state, setState] = useState({
    loading: false,
    error: false,
  });
  const { loading, error } = state;

  const deleteRevenue = () => {
    setState({ ...state, loading: true });

    fetch(`${API}/revenue-reports/${revenueReport.id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        closeDeleteDialog();
      })
      .catch((err) => closeDeleteDialog());
  };

  return (
    <Dialog
      open={showDelete}
      onClose={closeDeleteDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div class="modal-content">
        <div class="modal-header modal-colored-header bg-primary">
          <h4 class="modal-title" id="primary-header-modalLabel">
            Are You Sure
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={closeDeleteDialog}
          >
            ×
          </button>
        </div>
        <div class="modal-body">
          Once deleted, You will not be able to recover this record.
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-dismiss="modal"
            onClick={closeDeleteDialog}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            onClick={deleteRevenue}
          >
            Confirm
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteRevenueDialog;
