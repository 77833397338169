/**
 * @author Hemang Bhogayata
 * @description returns the referrerId of user, if any
 */
export default function getReferrer() {
  const referrerId = localStorage.getItem("referrer");

  if (referrerId) {
    return referrerId;
  } else {
    const user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user).referrer_id;
    } else {
      return null;
    }
  }
}
