import React, { useState, useEffect } from "react";
import { API } from "../../config";
import resolveCurrency from "../../helpers/Helper";

const ReportAnalyticsTable = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: true,
    analytics: {},
    count: {},
  });
  const { count, loading, analytics } = state;

  useEffect(() => {
    const countData = {
      done: true,
      maintenanceCount: {
        engineCount: 0,
        electricalCount: 0,
        acCount: 0,
        tyreBrakeCount: 0,
        transmissionCount: 0,
        bodyCount: 0,
        interiorCount: 0,
        steeringCount: 0,
        suspensionCount: 0,
        otherCount: 0,
      },
      odometer: "8000",
    };

    const data = {
      done: true,
      analytics: {
        id: "1vdlspckuokd5r9fzq",
        fk_users_uid: "aqzUTVBRcNhAMkuKsUAqnAhFLL93",
        fk_vehicles_id: "1vdlspckuokd5r9eme",
        total_odo: "6850",
        recorded_odo: "0",
        total_maint_cost: 275,
        engine_sum: 0,
        engine_cost: "0",
        engine_dist: 0,
        electrical_sum: 0,
        electrical_dist: 0,
        electrical_cost: "0",
        ac_sum: 1,
        ac_dist: 2800,
        ac_cost: "120",
        tyre_brake_sum: 2,
        tyre_brake_dist: 2800,
        tyre_brake_cost: "155",
        transmission_sum: 0,
        transmission_dist: 0,
        transmission_cost: "0",
        body_sum: 0,
        body_dist: 0,
        body_cost: "0",
        interior_sum: 0,
        interior_dist: 0,
        interior_cost: "0",
        steering_sum: 0,
        steering_dist: 0,
        steering_cost: "0",
        suspension_sum: 0,
        suspension_dist: 0,
        suspension_cost: "0",
        other_sum: 0,
        other_dist: 0,
        other_cost: "0",
        maintCostDist: null,
      },
    };
    setState({
      ...state,
      loading: false,
      analytics: data.analytics,
      count: countData.maintenanceCount,
    });
  }, [vehicle]);

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h4>Maintenance Totals</h4>

          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4>Maintenance Totals</h4>

        <table className="table-striped">
          <thead>
            <tr>
              <th>Category</th>
              <th>Amount</th>
              <th>Count</th>
              <th>Recommended Count</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td data-label="Category">Engine Related</td>
              <td data-label="Amount">
                {resolveCurrency("usd")} {analytics.engine_cost}
              </td>
              <td data-label="Count">{analytics.engine_sum}</td>
              <td data-label="Recommended Count">{count.engineCount}</td>
            </tr>
            <tr>
              <td data-label="Category">Electrical Related</td>
              <td data-label="Amount">
                {resolveCurrency("usd")} {analytics.electrical_cost}
              </td>

              <td data-label="Count">{analytics.electrical_sum}</td>
              <td data-label="Recommended Count">{count.electricalCount}</td>
            </tr>
            <tr>
              <td data-label="Category">AC Related</td>
              <td data-label="Amount">
                {resolveCurrency("usd")} {analytics.ac_cost}
              </td>

              <td data-label="Count">{analytics.ac_sum}</td>
              <td data-label="Recommended Count">{count.acCount}</td>
            </tr>
            <tr>
              <td data-label="Category">Tyre / Brake Related</td>
              <td data-label="Amount">
                {resolveCurrency("usd")} {analytics.tyre_brake_cost}
              </td>

              <td data-label="Count">{analytics.tyre_brake_sum}</td>
              <td data-label="Recommended Count">{count.tyreBrakeCount}</td>
            </tr>
            <tr>
              <td data-label="Category">Transmission Related</td>
              <td data-label="Amount">
                {resolveCurrency("usd")} {analytics.transmission_cost}
              </td>

              <td data-label="Count">{analytics.transmission_sum}</td>
              <td data-label="Recommended Count">{count.transmissionCount}</td>
            </tr>
            <tr>
              <td data-label="Category">Interior Related</td>
              <td data-label="Amount">
                {resolveCurrency("usd")} {analytics.interior_cost}
              </td>

              <td data-label="Count">{analytics.interior_sum}</td>
              <td data-label="Recommended Count">{count.interiorCount}</td>
            </tr>
            <tr>
              <td data-label="Category">Steering Related</td>
              <td data-label="Amount">
                {resolveCurrency("usd")} {analytics.steering_cost}
              </td>

              <td data-label="Count">{analytics.steering_sum}</td>
              <td data-label="Recommended Count">{count.steeringCount}</td>
            </tr>
            <tr>
              <td data-label="Category">Suspension Related</td>
              <td data-label="Amount">
                {resolveCurrency("usd")} {analytics.suspension_cost}
              </td>

              <td data-label="Count">{analytics.suspension_sum}</td>
              <td data-label="Recommended Count">{count.suspensionCount}</td>
            </tr>
            <tr>
              <td data-label="Category">Other Related</td>
              <td data-label="Amount">
                {resolveCurrency("usd")} {analytics.other_cost}
              </td>

              <td data-label="Count">{analytics.other_sum}</td>
              <td data-label="Recommended Count">{count.otherCount}</td>
            </tr>
            <tr style={{ borderTop: "2px solid #000" }}>
              <td data-label="Category">
                <strong>Total</strong>
              </td>
              <td data-label="Amount">
                <strong>
                  {resolveCurrency("usd")}{" "}
                  {parseInt(analytics.other_cost) +
                    parseInt(analytics.engine_cost) +
                    parseInt(analytics.electrical_cost) +
                    parseInt(analytics.ac_cost) +
                    parseInt(analytics.tyre_brake_cost) +
                    parseInt(analytics.transmission_cost) +
                    parseInt(analytics.interior_cost) +
                    parseInt(analytics.steering_cost) +
                    parseInt(analytics.suspension_cost)}
                </strong>
              </td>

              <td data-label="Count">-</td>
              <td data-label="Recommended Count">-</td>
            </tr>
          </tbody>
        </table>
        <br />
      </div>
    </div>
  );
};

export default ReportAnalyticsTable;
