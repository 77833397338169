import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PaymentSuccessModal from "./PaymentSuccessModal";
import { API } from "../../config";
import getReferrer from "../../helpers/Referrer";
import simpleFetch from "../../helpers/fetch-wrapper";
const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {},
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const PaymentForm = ({ priceId, vin, subscriptionData }) => {
  const [state, setState] = useState({
    error: null,
    showSuccessDialog: false,
    processing: false,
    email: null,
    firstName: null,
    lastName: null,
    addressLine1: null,
    addressLine2: null,
    region: null,
    city: null,
    country: null,
    zip: null,
    clientSecret: null,
  });

  const {
    error,
    showSuccessDialog,
    processing,
    email,
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    region,
    country,
    zip,
    clientSecret,
  } = state;
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    let mounted = true;

    /**
     * @author Hemang Bhogayata
     * @description Initiate Payment by fetching the client secret for stripe
     * @param {String} vin
     */
    const initiateReportPayment = async (vin) => {
      try {
        const token = localStorage.getItem("token");
        const { email } = JSON.parse(localStorage.getItem("user"));
        const res = await fetch(`${API}/initiate-payment`, {
          method: "POST",
          headers: {
            Accept: `application/json`,
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email, vin }),
        });

        const data = await res.json();

        if (data.done) {
          setState({ ...state, clientSecret: data.clientSecret, email });
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (mounted) {
      // this step only for Keemut Report
      if (priceId == "keemut-report") {
        initiateReportPayment(vin);
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  /**
   * @author Hemang Bhogayata
   * @description Handles submit for Subscription payment
   * @param {Object} e
   * @todo Add case for error
   */
  const handleSubmitForSubscription = async (e) => {
    e.preventDefault();
    setState({ ...state, processing: true });
    const userJSON = localStorage.getItem("user");
    const { stripe_customer_id, uid } = JSON.parse(userJSON);
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    // of no error
    if (!error) {
      // create the subscription on success
      createSubscription({
        customerId: stripe_customer_id,
        paymentMethodId: paymentMethod.id,
        priceId,
        uid,
        subscriptionData,
      });
    }
  };

  /**
   * @author Hemang Bhogayata
   * @description Creates a subscription entry
   * @param {String} customerId
   * @param {String} paymentMethodId
   * @param {String} priceId
   * @param {String} uid
   * @param {String} subscriptionData
   */
  async function createSubscription({
    customerId,
    paymentMethodId,
    priceId,
    uid,
    subscriptionData,
  }) {
    try {
      const data = await simpleFetch(
        `/create-subscription/${uid}`,
        "POST",
        JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          companyName: email,
          firstName,
          lastName,
          addressLine1,
          addressLine2,
          city,
          region,
          subscriptionData,
          country,
          zip,
          referrerId: getReferrer(),
        })
      );

      // if error occurs
      if (data.error) {
        console.log(error);
      }
      // if subscription is active
      if (data.subscription.status === "active") {
        setState({ ...state, showSuccessDialog: true, processing: false });
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * @author Hemang Bhogayata
   * @description Handles submit for stripe form for keemut report checkout
   * @param {Object} e
   */
  const handleSubmitForReport = async (e) => {
    try {
      e.preventDefault();
      setState({ ...state, processing: true, error: null });

      // validation
      if (!email) {
        return setState({
          ...state,
          processing: false,
          error: "Please fillout the email",
        });
      }

      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
        receipt_email: email,
      });

      if (payload.error) {
        setState({ ...state, processing: false, error: payload.error.message });
      } else {
        registerKeemutReportPurchase(payload);
      }
    } catch (err) {
      console.log(error);
      setState({
        ...state,
        processing: false,
        error: "Unable to process your payment request at this time.",
      });
    }
  };

  /**
   * @author Hemang Bhogayata
   * @description Confirms the purchase for keemut report
   * @param {Object} stripePayload
   */
  const registerKeemutReportPurchase = async (stripePayload) => {
    try {
      const data = await simpleFetch(
        `/keemut-report/purchase`,
        "POST",
        JSON.stringify({
          paymentIntentId: stripePayload.paymentIntent.id,
          vin: vin,
          referrerId: getReferrer(),
        })
      );

      if (data.done) {
        setState({ ...state, processing: true, showSuccessDialog: true });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {" "}
      <div className="card card-body" style={{ height: "100%" }}>
        <h3>Customer Information</h3>
        <br />
        <br />
        <br />

        <div className="form-group mt-2">
          <label>Email</label>
          <input
            type="text"
            class="form-control"
            aria-describedby="basic-addon1"
            placeholder="Enter Email *"
            name="email"
            value={email}
            onChange={handleChange}
          />
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group mt-2">
              <label>First Name</label>

              <input
                type="text"
                class="form-control"
                aria-describedby="basic-addon1"
                placeholder="Enter First Name (optional) "
                name="firstName"
                value={firstName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group mt-2">
              <label>Last Name</label>

              <input
                type="text"
                class="form-control"
                aria-describedby="basic-addon1"
                placeholder="Enter Last Name (optional)"
                name="lastName"
                value={lastName}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        {/* <div className="form-group mt-2 ">
          <input
            type="text"
            class="form-control"
            aria-describedby="basic-addon1"
            placeholder="Enter Address Line 1 (optional)"
            name="addressLine1"
            value={addressLine1}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mt-2">
          <input
            type="text"
            class="form-control"
            aria-describedby="basic-addon1"
            placeholder="Enter Address Line 2 (optional)"
            name="addressLine2"
            value={addressLine2}
            onChange={handleChange}
          />
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group mt-2">
              <input
                type="text"
                class="form-control"
                aria-describedby="basic-addon1"
                placeholder="Enter City (optional)"
                name="city"
                value={city}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group mt-2">
              <input
                type="text"
                class="form-control"
                aria-describedby="basic-addon1"
                placeholder="Enter Region / State / County  (optional) "
                name="region"
                value={region}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-6">
            <div className="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="basic-addon1"
                placeholder="Enter Country  (optional)"
                name="country"
                value={country}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div lassName="form-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="basic-addon1"
                placeholder="Enter Zip Code (optional)"
                name="zip"
                value={zip}
                onChange={handleChange}
              />
            </div>
          </div>
        </div> */}
        <h3 className="my-4">Payment</h3>
        <br />
        <br />
        <br />

        {/* STRIPE FORM */}
        <form
          id="payment-form"
          onSubmit={
            priceId != "keemut-report"
              ? handleSubmitForSubscription
              : handleSubmitForReport
          }
        >
          {error && (
            <div
              class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show my-4"
              role="alert"
            >
              <strong>Error - </strong> {error}
            </div>
          )}
          <CardElement id="card-element" options={cardStyle} />
          <button
            className="btn btn-lg btn-outline-primary my-3"
            style={{ width: "100%" }}
            disabled={processing}
          >
            <span id="button-text">
              {" "}
              {processing ? (
                <>
                  {" "}
                  <span
                    class="spinner-grow spinner-grow-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Processing...{" "}
                </>
              ) : (
                "Pay"
              )}
            </span>
          </button>

          {/* Show any error that happens when processing the payment */}

          {/* Show a success message upon completion */}
        </form>

        {/* STRIPE FORM END */}

        <PaymentSuccessModal
          vin={vin}
          showSuccessDialog={showSuccessDialog}
          closeSuccessDialog={() =>
            setState({ ...state, showSuccessDialog: false })
          }
        />
      </div>
    </>
  );
};

export default PaymentForm;
