import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import moment from "moment";
import { API } from "../../../config";
import { Input } from "semantic-ui-react";
import resolveCurrency from "../../../helpers/Helper";
import EditRevenueDialog from "./EditRevenueDialog";
import FloatingButton from "../common/FloatingButton";
import DeleteRevenueDialog from "./DeleteRevenueDialog";

const RevenueHistory = ({ units }) => {
  const [state, setState] = useState({
    result: [],
    loading: true,
    month: "January",
    year: 2020,
    error: null,
    paginations: 0,
    currentPage: 1,
    searchQuery: null,
    showEditDialog: false,
    selectedRevenueReport: null,
    showDeleteDialog: false,
  });
  const {
    searchQuery,
    result,
    loading,
    month,
    year,
    error,
    paginations,
    currentPage,
    showEditDialog,
    selectedRevenueReport,
    showDeleteDialog,
  } = state;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAnalytics();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [month, year]);

  const getAnalytics = async () => {
    try {
      setState({
        ...state,
        loading: true,
        error: null,
        result: [],
        showEditDialog: false,
        showDeleteDialog: false,
      });
      const token = localStorage.getItem("token");
      const res = await fetch(`${API}/revenue-reports`, {
        method: "GET",
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.done)
        setState({
          ...state,
          showEditDialog: false,
          result: data.revenueReports,
          loading: false,
          showDeleteDialog: false,
          error: null,
          paginations: Math.ceil(data.revenueReports.length / 20),
        });
      else
        setState({
          ...state,
          showEditDialog: false,
          showDeleteDialog: false,
          loading: false,
          result: [],
          error: "No Records Found / Unable to process at this time.",
        });
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        showEditDialog: false,
        showDeleteDialog: false,
        loading: false,
        error: "No Records Found / Unable to process at this time.",
      });
    }
  };

  const renderPagination = () => {
    let content = [];
    for (let i = 1; i <= paginations; i++) {
      content.push(
        <a
          onClick={() => setState({ ...state, currentPage: i })}
          class="page-link"
          style={
            currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
      );
    }
    return <> {content} </>;
  };

  const renderReports = () => {
    let content = [];
    let filtered = [];

    if (searchQuery) {
      result.forEach((res) => {
        if (
          (res.plate_no &&
            res.plate_no.toLowerCase().includes(searchQuery.toLowerCase())) ||
          res.date.toLowerCase().includes(searchQuery.toLowerCase()) ||
          res.earnings.toLowerCase().includes(searchQuery.toLowerCase()) ||
          res.trip_expense.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          filtered.push(res);
        }
      });
    } else filtered = result;

    filtered.map((record, i) => {
      return i < currentPage * 20 && i >= (currentPage - 1) * 20
        ? content.push(
            <tr key={i}>
              <td>{record.plate_no}</td>
              {/* <td>{record.name}</td>
        <td>{record.make + " " + record.model}</td> */}
              <td>{moment(record.date).format("DD-MMM-YYYY")}</td>
              <td>{moment(record.end_date).format("DD-MMM-YYYY")}</td>
              <td>
                {" "}
                {resolveCurrency(units.currencyunit)} {record.earnings}
              </td>
              <td>
                {resolveCurrency(units.currencyunit)}
                {record.trip_expense ? record.trip_expense : 0}
              </td>
              <td>
                <Dropdown
                  trigger={<span className="text-primary">Action</span>}
                  floating
                  options={[
                    {
                      key: "edit",
                      text: "Edit",
                      icon: "mdi mdi-pencil-box-multiple-outline",
                      value: "edit",
                    },
                    {
                      key: "delete",
                      text: "Delete",
                      icon: " mdi mdi-delete-alert",
                      value: "delete",
                    },
                  ]}
                  onClick={() =>
                    setState({
                      ...state,
                      revenueReportId: record.id,
                      selectedRevenueReport: record,
                    })
                  }
                  onChange={(e, { value }) => {
                    if (value === "edit") {
                      setState({ ...state, showEditDialog: true });
                    } else if (value === "delete") {
                      setState({ ...state, showDeleteDialog: true });
                    }
                  }}
                />
              </td>
            </tr>
          )
        : null;
    });

    return <>{content} </>;
  };

  const sortByPropertyAsc = (propertyName) => {
    function compare(v1, v2) {
      let valueA =
        typeof v1[propertyName] === "string"
          ? v1[propertyName].toUpperCase()
          : v1[propertyName];
      let valueB =
        typeof v2[propertyName] === "string"
          ? v2[propertyName].toUpperCase()
          : v2[propertyName];

      if (propertyName === "earnings" || propertyName === "trip_expense") {
        valueA = parseInt(valueA);
        valueB = parseInt(valueB);
      }
      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      return comparison;
    }
    const values = result;
    values.sort(compare);
    setState({ ...state, result: values });
  };

  const sortByPropertyDesc = (propertyName) => {
    function compare(v1, v2) {
      let valueA =
        typeof v1[propertyName] === "string"
          ? v1[propertyName].toUpperCase()
          : v1[propertyName];
      let valueB =
        typeof v2[propertyName] === "string"
          ? v2[propertyName].toUpperCase()
          : v2[propertyName];

      if (propertyName === "earnings" || propertyName === "trip_expense") {
        valueA = parseInt(valueA);
        valueB = parseInt(valueB);
      }
      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      return comparison;
    }
    const values = result;
    values.sort(compare);
    setState({ ...state, result: values.reverse() });
  };

  return (
    <div className="card card-body">
      <h4>Revenue Reports</h4>
      <FloatingButton />

      <div className="col-lg-4">
        <Input
          className="mr-2"
          placeholder="Search..."
          onChange={(e, { value }) => {
            setState({ ...state, searchQuery: value });
          }}
        />
      </div>
      <div className="row"></div>
      {error && <p className="text-danger my-3">{error} </p>}

      <table className="table-striped my-3">
        <thead>
          <tr>
            {/* <th>Name </th>
            <th>Vehicle </th> */}
            <th>
              Plate No.{" "}
              <i
                className=" caret up icon p-0 ml-3"
                onClick={() => sortByPropertyAsc("plate_no")}
              ></i>
              <i
                className="caret down icon p-0"
                onClick={() => sortByPropertyDesc("plate_no")}
              ></i>{" "}
            </th>

            <th>
              Start Date{" "}
              <i
                className=" caret up icon p-0 ml-3"
                onClick={() => sortByPropertyAsc("date")}
              ></i>
              <i
                className="caret down icon p-0"
                onClick={() => sortByPropertyDesc("date")}
              ></i>{" "}
            </th>
            <th>
              End Date{" "}
              <i
                className=" caret up icon p-0 ml-3"
                onClick={() => sortByPropertyAsc("end_date")}
              ></i>
              <i
                className="caret down icon p-0"
                onClick={() => sortByPropertyDesc("end_date")}
              ></i>
            </th>
            <th>
              Revenue{" "}
              <i
                className=" caret up icon p-0 ml-3"
                onClick={() => sortByPropertyAsc("earnings")}
              ></i>
              <i
                className="caret down icon p-0"
                onClick={() => sortByPropertyDesc("earnings")}
              ></i>
            </th>
            <th>
              Expense{" "}
              <i
                className=" caret up icon p-0 ml-3"
                onClick={() => sortByPropertyAsc("trip_expense")}
              ></i>
              <i
                className="caret down icon p-0"
                onClick={() => sortByPropertyDesc("trip_expense")}
              ></i>
            </th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {/* {loading ? (
            <div className="spinner-border" role="status" />
          ) : (
            renderReports()
          )} */}
          {renderReports()}
        </tbody>
      </table>
      <nav aria-label="Page navigation example" style={{ float: "right" }}>
        <ul class="pagination pagination">{renderPagination()}</ul>
      </nav>

      {selectedRevenueReport && (
        <>
          <EditRevenueDialog
            showEditDialog={showEditDialog}
            units={units}
            closeEditDialog={getAnalytics}
            revenueReport={selectedRevenueReport}
          />
          <DeleteRevenueDialog
            showDelete={showDeleteDialog}
            revenueReport={selectedRevenueReport}
            closeDeleteDialog={getAnalytics}
          />
        </>
      )}
    </div>
  );
};

export default RevenueHistory;
