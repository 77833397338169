import React, { useState, useEffect } from "react";
import { API, site } from "../../../config";
import { Redirect } from "react-router-dom";
import resolveCurrency from "../../../helpers/Helper";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import RecentOdometer from "../common/RecentOdometer";
import FloatingButton from "../common/FloatingButton";
import RollbackDialog from "../common/RollbackDialog";
import { Helmet } from "react-helmet";

/** DEPRECATED, THIS PAGE NOW REDIRECTS TO /dashboard/maintenance-v2 */

const MaintenanceForm = ({ vehicle, updateOdometer }) => {
  const [state, setState] = useState({
    success: false,
    loading: true,
    units: {},
    vehicles: [],
    date: null,
    odometer: null,
    cost: null,
    engineRelated: false,
    batteryRelated: false,
    acRelated: false,
    tyreBrakeRelated: false,
    transmissionRelated: false,
    bodyRelated: false,
    interiorRelated: false,
    steeringRelated: false,
    suspensionRelated: false,
    otherRelated: false,
    attachment: null,
    formData: new FormData(),
    futureError: false,
    error: false,
    showOdoDialog: false,
    lastOdo: false,
    ignoreRollback: false,
    showRollbackDialog: false,
    submitting: false,
  });
  const {
    success,
    submitting,
    loading,
    units,
    vehicles,
    odometer,
    date,
    cost,
    engineRelated,
    batteryRelated,
    acRelated,
    tyreBrakeRelated,
    transmissionRelated,
    bodyRelated,
    interiorRelated,
    steeringRelated,
    suspensionRelated,
    otherRelated,
    attachment,
    formData,
    futureError,
    error,
    showOdoDialog,
    showRollbackDialog,
    ignoreRollback,
    lastOdo,
  } = state;

  useEffect(() => {
    window.location = `${site}/dashboard/maintenance-v2`;
  }, []);

  const initialRequest = () => {
    if (localStorage.getItem("units") && localStorage.getItem("vehicles")) {
      const vehicles = JSON.parse(localStorage.getItem("vehicles"));
      const units = JSON.parse(localStorage.getItem("units"));
      setState({
        ...state,
        vehicles,
        units,
        loading: false,
      });
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");
      setState({ ...state, loading: true });
      fetch(`${API}/vehicles/all/${user.uid}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          fetch(`${API}/units/${user.uid}`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => response.json())
            .then((jsonData) => {
              localStorage.setItem("units", JSON.stringify(jsonData.units));
              localStorage.setItem("vehicles", JSON.stringify(data.vehicles));
              setState({
                ...state,
                units: jsonData.units,
                loading: false,
                vehicles: data.vehicles,
              });
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
  };

  const handleChange = (e) => {
    if (e.target.value == "true") {
      setState({ ...state, [e.target.name]: true });
    } else if (e.target.value === "false") {
      setState({ ...state, [e.target.name]: false });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  const isFormValid = () => {
    if (!date || !odometer || !cost) {
      setState({ ...state, error: true });
      return false;
    } else if (moment().isBefore(date)) {
      setState({ ...state, futureError: true });
      return false;
    } else if (odometer < lastOdo && !ignoreRollback) {
      setState({ ...state, showRollbackDialog: true });
      return false;
    } else if (cost < 0) {
      setState({ ...state, error: true });
      return false;
    } else {
      return true;
    }
  };

  const addMaintenance = (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    if (isFormValid()) {
      setState({ ...state, submitting: true });
      formData.set("date", date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2"));
      formData.set("odometer", odometer);
      formData.set("cost", cost);
      formData.set("engineRelated", engineRelated);
      formData.set("batteryRelated", batteryRelated);
      formData.set("acRelated", acRelated);
      formData.set("tyreBrakeRelated", tyreBrakeRelated);
      formData.set("transmissionRelated", transmissionRelated);
      formData.set("bodyRelated", bodyRelated);
      formData.set("interiorRelated", interiorRelated);
      formData.set("steeringRelated", steeringRelated);
      formData.set("suspensionRelated", suspensionRelated);
      formData.set("otherRelated", otherRelated);

      fetch(`${API}/maintenance/${vehicle.id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.done) {
            localStorage.setItem("odoChange", true);
            setState({ ...state, success: true, submitting: false });
            updateOdometer(parseInt(odometer));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getOdo = (date) => {
    const token = localStorage.getItem("token");
    fetch(
      `${API}/odometer/${vehicle.id}/?date=${moment(date).format(
        "YYYY-MM-DD"
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const odoInput = document.getElementById("maintenanceOdoInput");
        odoInput.placeholder = "Most Recent Odometer was " + data.odometer;

        setState({
          ...state,
          lastOdo: data.odometer ? data.odometer : 0,
          date,
        });
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, date });
      });
  };

  if (loading) {
    return <div class="spinner-border text-primary" role="status"></div>;
  }

  if (success) {
    return <Redirect to="/dashboard/overview" />;
  }

  return (
    <div className="text-dark">
      <Helmet>
        <title>Keemut | Maintenance</title>
      </Helmet>
      <h3>Add Maintenance</h3>
      <FloatingButton />
      <div style={{ backgroundColor: "#ffffff" }} className="mx-2 my-3">
        <div className=" container p-3  card-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label>Enter date</label>

                <input
                  type="date"
                  class="form-control date"
                  id="birthdatepicker"
                  data-toggle="date-picker"
                  data-single-date-picker="true"
                  name="date"
                  onChange={(e) => {
                    getOdo(e.target.value);
                  }}
                  value={date}
                />
              </div>

              <div className="form-group">
                <label>
                  Enter odometer{" "}
                  <i
                    className={
                      date
                        ? "mdi mdi-information-outline text-danger"
                        : "mdi mdi-information-outline"
                    }
                    title="Click to see, last odometer value"
                    onClick={
                      date
                        ? () => setState({ ...state, showOdoDialog: true })
                        : () => {}
                    }
                  ></i>
                </label>
                <div class="input-group">
                  <br />
                  <input
                    id="maintenanceOdoInput"
                    type="text"
                    class="form-control"
                    placeholder="Enter odometer"
                    name="odometer"
                    value={odometer}
                    onChange={handleChange}
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">{units.distanceunit}</span>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Amount Paid</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">
                      {resolveCurrency(units.currencyunit.toUpperCase())}
                    </label>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter how much you paid"
                    name="cost"
                    value={cost}
                    onChange={handleChange}
                  />
                </div>

                <label>Maintenance List</label>

                <div className="row">
                  <div className="col-lg-6">
                    <div
                      class="btn-group text-left "
                      data-toggle="buttons"
                      style={{ width: "100%" }}
                    >
                      <label class="btn btn-light">
                        <input
                          type="checkbox"
                          name="engineRelated"
                          onChange={handleChange}
                          value={true}
                        />{" "}
                        Engine / EV Related
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    {" "}
                    <div
                      class="btn-group "
                      data-toggle="buttons"
                      style={{ width: "100%" }}
                    >
                      <label class="btn btn-light">
                        <input
                          type="checkbox"
                          name="batteryRelated"
                          onChange={handleChange}
                          value={true}
                        />{" "}
                        Battery Related
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      class="btn-group "
                      data-toggle="buttons"
                      style={{ width: "100%" }}
                    >
                      <label class="btn btn-light">
                        <input
                          type="checkbox"
                          name="acRelated"
                          onChange={handleChange}
                          value={true}
                        />{" "}
                        HVAC / Airconditioning
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    {" "}
                    <div
                      class="btn-group "
                      data-toggle="buttons"
                      style={{ width: "100%" }}
                    >
                      <label class="btn btn-light">
                        <input
                          type="checkbox"
                          name="tyreBrakeRelated"
                          onChange={handleChange}
                          value={true}
                        />{" "}
                        Tires & Brakes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      class="btn-group "
                      data-toggle="buttons"
                      style={{ width: "100%" }}
                    >
                      <label class="btn btn-light">
                        <input
                          type="checkbox"
                          name="transmissionRelated"
                          onChange={handleChange}
                          value={true}
                        />{" "}
                        Drivetrain / Transmission
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    {" "}
                    <div
                      class="btn-group "
                      data-toggle="buttons"
                      style={{ width: "100%" }}
                    >
                      <label class="btn btn-light">
                        <input
                          type="checkbox"
                          name="bodyRelated"
                          onChange={handleChange}
                          value={true}
                        />{" "}
                        Exterior Body / Door / Bumpers
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 ">
                    <div
                      class="btn-group "
                      data-toggle="buttons"
                      style={{ width: "100%" }}
                    >
                      <label class="btn btn-light">
                        <input
                          type="checkbox"
                          name="interiorRelated"
                          onChange={handleChange}
                          value={true}
                        />{" "}
                        Interior Components
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      class="btn-group "
                      data-toggle="buttons"
                      style={{ width: "100%" }}
                    >
                      <label class="btn btn-light">
                        <input
                          type="checkbox"
                          name="steeringRelated"
                          onChange={handleChange}
                          value={true}
                        />{" "}
                        Steering
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      class="btn-group "
                      data-toggle="buttons"
                      style={{ width: "100%", textAlign: "start" }}
                    >
                      <label class="btn btn-light">
                        <input
                          type="checkbox"
                          name="suspensionRelated"
                          onChange={handleChange}
                          value={true}
                        />{" "}
                        Suspension
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <div
                      class="btn-group "
                      data-toggle="buttons"
                      style={{ width: "100%" }}
                    >
                      <label class="btn btn-light">
                        <input
                          type="checkbox"
                          name="otherRelated"
                          onChange={handleChange}
                          value={true}
                        />{" "}
                        All Other Maintenance
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Upload Maintenance Attachment</label>
                <div class="input-group">
                  <div class="custom-file ">
                    <input
                      type="file"
                      class="custom-file-input "
                      id="inputGroupFile04"
                      name="attachment"
                      onChange={(e) => {
                        document.getElementById("file-name").innerHTML =
                          e.target.files[0].name;
                        formData.set("attachment", e.target.files[0]);
                      }}
                    />
                    <label
                      class="custom-file-label"
                      id="file-name"
                      for="inputGroupFile04"
                    >
                      Optional
                    </label>
                  </div>
                </div>
              </div>
              <br />
              <button
                class="btn btn-primary btn-lg"
                onClick={addMaintenance}
                style={{ width: "100%" }}
              >
                {submitting ? "Saving..." : "Save"}
              </button>
            </div>
            <Dialog
              open={futureError}
              onClose={() => setState({ ...state, futureError: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div class="modal-content">
                <div class="modal-header modal-colored-header bg-warning">
                  <h4 class="modal-title" id="primary-header-modalLabel">
                    Seems like You are trying to enter a Future event
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={() => setState({ ...state, futureError: null })}
                  >
                    ×
                  </button>
                </div>
                <div class="modal-body">
                  The date field must be today or a past date, To Add a
                  reminder, head over to reminders.
                </div>
                <div class="modal-footer">
                  <a href={`${site}/dashboard/tco`}>
                    <button
                      type="button"
                      class="btn btn-warning"
                      data-dismiss="modal"
                      onClick={() => setState({ ...state, futureError: null })}
                    >
                      Go To Reminders
                    </button>
                  </a>
                </div>
              </div>
            </Dialog>

            <RecentOdometer
              vehicle={vehicle}
              date={date}
              showOdoDialog={showOdoDialog}
              closeOdoDialog={() =>
                setState({ ...state, showOdoDialog: false })
              }
            />

            <RollbackDialog
              units={units}
              showRollbackDialog={showRollbackDialog}
              cancelRollback={() =>
                setState({
                  ...state,
                  showRollbackDialog: false,
                  ignoreRollback: false,
                })
              }
              proceedRollback={() =>
                setState({
                  ...state,
                  showRollbackDialog: false,
                  ignoreRollback: true,
                })
              }
              lastOdo={lastOdo}
            />

            <Dialog
              open={error}
              onClose={() => setState({ ...state, error: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div class="modal-content">
                <div class="modal-header modal-colored-header bg-warning">
                  <h4 class="modal-title" id="primary-header-modalLabel">
                    Please Fillout all the fields
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={() => setState({ ...state, error: null })}
                  >
                    ×
                  </button>
                </div>
                <div class="modal-body">
                  It seems like you missed a few of the fields while entering...
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-light"
                    data-dismiss="modal"
                    onClick={() => setState({ ...state, error: null })}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceForm;
