import React, { useState } from "react";
import { Tab } from "semantic-ui-react";
import BreakEven from "./BreakEven";
import FleetAnalytics from "./FleetAnalytics";
import TuroTool from "./TuroTool";

const FleetPage = ({ vehicle }) => {
  const [state, setState] = useState({
    panes: [
      {
        menuItem: "Fleet Analytics",
        render: () => (
          <Tab.Pane>
            {" "}
            <FleetAnalytics />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Break Even Analysis",
        render: () => (
          <Tab.Pane>
            <BreakEven vehicle={vehicle} />
          </Tab.Pane>
        ),
      },

      {
        menuItem: "Turo Pricing",
        render: () => (
          <Tab.Pane>
            <TuroTool />
          </Tab.Pane>
        ),
      },
    ],
  });
  const { panes } = state;

  return (
    <div className="text-dark">
      <Tab panes={panes} />
    </div>
  );
};

export default FleetPage;
