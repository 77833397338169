import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import NumberFormat from "../../../helpers/number-format-helper";
import resolveCurrency from "../../../helpers/Helper";
import { getFuelTcoService } from "../../../services/forms/fuel-form-service";

const ExpectedFuel = ({ vehicle, units }) => {
  const [state, setState] = useState({
    fuelTco: null,
    totalCost: null,
    loading: false,
  });
  const { fuelTco, totalCost, loading } = state;

  useEffect(() => {
    const _getFuelTco = async () => {
      try {
        const data = await getFuelTcoService(vehicle.id);

        if (data.done) {
          setState({
            ...state,
            fuelTco: data.fuelTco,
            totalCost: data.totalCost,
            loading: false,
          });
        }
      } catch (err) {
        setState({ ...state, loading: false, fuelTco: 0, totalCost: 0 });
      }
    };

    let mount = true;

    if (mount) _getFuelTco();

    return function cleanup() {
      mount = false;
    };
  }, [vehicle]);

  if (loading) {
    return (
      <div class="card  widget-flat ">
        <div class="card-body">
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card  widget-flat ">
      <div class="card-body">
        <div class="float-right">
          <i
            class=" mdi mdi-gas-station
 widget-icon bg-warning-lighten text-warning"
          ></i>
        </div>
        <span>
          <strong>Fuel Cost</strong>{" "}
        </span>
        <h3 class="mt-3 mb-3">
          {resolveCurrency(units.currencyunit)}{" "}
          {fuelTco ? fuelTco.toFixed(2) : "N/A"} per {units.fuelunit}
        </h3>
        <label class="mb-0 text-muted">
          <span class="text-danger mr-2">
            <i class="mdi mdi-arrow-down-bold"></i>{" "}
            {resolveCurrency(units.currencyunit)}
            {totalCost ? NumberFormat(totalCost, units.country_code) : "N/A"}
          </span>
          <span class="text-nowrap">Since Purchase</span>
        </label>
        <p>
          If you want to update this, <a href="/dashboard/fuel">Click here</a>
        </p>
      </div>
    </div>
  );
};

export default ExpectedFuel;
