import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import simpleFetch from "../../../helpers/fetch-wrapper";
import LineChart from "../../custom-charts/LineChart";
import ScatterChart from "../../custom-charts/ScatterChart";

const VinwiseChargeCurve = ({ vehicle }) => {
  const [state, setState] = useState({
    _loading: true,
    _xaxis: [],
    _yaxes: [],
    _level: "L1",
    _scatterSeries: [],
    _min: null,
    _max: null,
    _noData: false,
  });

  const {
    _level,
    _loading,
    _xaxis,
    _yaxes,
    _scatterSeries,
    _min,
    _max,
    _noData,
  } = state;

  useEffect(() => {
    _getData("L1");
  }, []);

  const _getData = async (level) => {
    try {
      setState({ ...state, _loading: true, _noData: false });
      let x = [],
        y = [],
        scatterSeries = [],
        missingY = [];

      const data = await simpleFetch(
        `/charge-curve/vin`,
        "POST",
        JSON.stringify({
          vin: vehicle.vin,
          chargerLevel: level,
        })
      );

      if (data.result && data.result.length == 0) {
        return setState({ ...state, _noData: true });
      }

      if (vehicle.make != "Tesla") return;
      x = [
        5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
        95, 100,
      ];

      let min = data.result[0].charger_power;
      let max = data.result[0].charger_power;

      for (let i = 0; i < x.length; i++) {
        let found = false;
        for (let j = 0; j < data.result.length; j++) {
          let item = data.result[j];
          if (item.battery_level != x[i]) continue;

          found = true;
          if (item.is_available) {
            if (item.charger_power > 250) item.charger_power = 250;

            if (min > item.charger_power) min = parseFloat(item.charger_power);

            if (max < item.charger_power) max = parseFloat(item.charger_power);

            y.push(item.charger_power);

            scatterSeries.push([
              parseFloat(x[i]),
              parseFloat(item.charger_power),
            ]);
            // missingY.push(null);
          } else {
            // missingY.push(item.charger_power);
            y.push(null);
            scatterSeries.push([x[i], null]);
          }
        }

        if (!found) {
          y.push(null);
          scatterSeries.push([x[i], null]);
        }
      }

      setState({
        ...state,
        _xaxis: x,
        _min: min,
        _max: max,
        // _yaxes: [
        //   { data: missingY, type: "line", name: `` },
        //   { data: y, type: "line", name: `${level} Charge Curve` },
        // ],
        _scatterSeries: [{ name: "Sample", data: scatterSeries }],
        _yaxes: y,
        _loading: false,
        _level: level,
        _noData: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="text-dark card card-body" style={{ height: "100%" }}>
      <h3>Charge Curve</h3>

      <Dropdown
        className="my-2"
        placeholder="Charger Level"
        value={_level}
        onChange={(e, { value }) => _getData(value)}
        options={[
          { key: 1, text: "L1", value: "L1" },
          { key: 2, text: "L2", value: "L2" },
          { key: 3, text: "L3V1", value: "L3V1" },
          { key: 4, text: "L3V2", value: "L3V2" },
          { key: 5, text: "L3V3", value: "L3V3" },
        ]}
        search
        selection
      />
      {_noData ? (
        <p>No data found for {_level} Chargers.</p>
      ) : _loading ? (
        <div className="spinner spinner-border center-spinner"> </div>
      ) : (
        <>
          <ScatterChart
            xAxisTitle="Battery Level (%)"
            yAxisTitle="Charge Power (kW)"
            series={_scatterSeries}
            min={parseFloat(parseFloat(_min) - parseFloat(_min) * 0.1)}
            max={parseFloat(parseFloat(_max) + parseFloat(_max) * 0.1)}
          />
          <p className="mt-3">
            <strong>Note:</strong> The chart above may not reflect your
            vehicle's charge curve in detail because we let the car sleep during
            charging and do not get all the data points. With more charge events
            the data will reflect a more accurate curve.
          </p>
        </>
      )}
    </div>
  );
};

export default VinwiseChargeCurve;
