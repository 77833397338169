import React, { useState } from "react";
import TataHelper from "../../../helpers/tata-helper";
import FormGenerator from "../../common/FormGenerator";
import { Dialog } from "@material-ui/core";
import { Step, Accordion } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import simpleFetch from "../../../helpers/fetch-wrapper";
const Onboard = () => {
  const [state, setState] = useState({
    _username: null,
    _password: null,
    _error: null,
    _loading: false,
    _success: false,
    _phoneNo: null,
    _isChild: false,
    _vehicles: [],
    _currentVehicleIdx: -1,
    _step: 0,
    _step2Loading: true,
    _trimOptions: [],
    _trim: null,
    _processingUpdate: false,
  });

  const {
    _password,
    _username,
    _loading,
    _error,
    _success,
    _vehicles,
    _phoneNo,
    _step,
    _isChild,
    _currentVehicleIdx,
    _step2Loading,
    _trim,
    _trimOptions,
    _processingUpdate,
  } = state;

  const _onSubmit = async (e) => {
    try {
      e.preventDefault();

      setState({ ...state, _loading: true, _error: null });

      const data = await TataHelper.loginWithTata(
        _username,
        _password,
        _phoneNo,
        _isChild
      );

      console.log(data);

      if (data.vehicles) {
        _getTrims(true, data.vehicles);
      } else
        setState({
          ...state,
          _loading: false,
          _error:
            (data.data && data.data.error && data.data.error_description) ||
            "Incorrect Username / Password!",
        });
    } catch (err) {
      setState({
        ...state,
        _loading: false,
        _error: "Unable to process your request at this time!",
      });
    }
  };

  const _getTrims = async (newStep, vehicles) => {
    try {
      console.log(newStep, vehicles);
      if (newStep) {
        setState({
          ...state,
          _loading: false,
          _error: null,
          _step2Loading: true,
          _step: vehicles.length == 0 ? 2 : 1,
          _vehicles: vehicles,
        });
      } else setState({ ...state, _step2Loading: true });

      console.log("here");
      let newIdx = _currentVehicleIdx + 1;

      let vehicle = vehicles ? vehicles[newIdx] : _vehicles[newIdx];
      const data = await simpleFetch(
        `/vehicle-list/trim?make=${vehicle.make}&model=${vehicle.model}&year=${vehicle.year}&country=IND`,
        "GET"
      );

      let trimOps = [];

      data.trimList.forEach((t) =>
        trimOps.push({ key: t.trim, value: t.trim, text: t.trim })
      );

      console.log(_vehicles, vehicles);

      setState({
        ...state,
        _step2Loading: false,
        _step: 1,
        _trimOptions: trimOps,
        _trim: trimOps[0].value,
        _vehicles: vehicles,
        _currentVehicleIdx: newIdx,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const _onboardVehicle = async () => {
    try {
      let vehicle = _vehicles[_currentVehicleIdx];

      await simpleFetch(
        `/vehicles/${vehicle.id}`,
        "PUT",
        JSON.stringify({
          nickname: vehicle.name,
          purchasePrice: vehicle.purchaseprice,
          odometer: vehicle.odometer,
          year: vehicle.year,
          vin: vehicle.vin,
          plateNo: vehicle.plate_no,
          trim: _trim,
          workRelated: vehicle.work_related,
        })
      );

      localStorage.removeItem("vehicles");

      if (_currentVehicleIdx + 1 == _vehicles.length) {
        setState({ ...state, _step: 2 });
      } else {
        _getTrims(false, _vehicles);
      }
    } catch (err) {}
  };

  const _handleChange = (e) => {
    try {
      setState({ ...state, [e.target.name]: e.target.value });
    } catch (err) {}
  };

  return (
    <div className="p-3 card card-body text-dark">
      <h1>
        Connect Tata Account{" "}
        <sup className="text-danger" style={{ fontSize: "18px" }}>
          BETA
        </sup>{" "}
      </h1>

      <p className="mb-4">
        <strong>Note:</strong> Keemut is in beta for Tata, we are still
        evaluating different features that we get from Tata vehicles.
      </p>

      <Step.Group ordered>
        <Step active={_step == 0} completed={_step > 0}>
          <Step.Content>
            <Step.Title className="text-primary">Login With Tata</Step.Title>
            <Step.Description>Login With Tata zConnect</Step.Description>
          </Step.Content>
        </Step>

        <Step active={_step == 1} completed={_step > 1}>
          <Step.Content>
            <Step.Title>Verify Your Vehicles</Step.Title>
            <Step.Description>
              Confirm your vehicle's information to ensure accuracy.{" "}
            </Step.Description>
          </Step.Content>
        </Step>

        <Step active={_step == 1} completed={_step > 1}>
          <Step.Content>
            <Step.Title>Connection Completed</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
      <br />
      {_step == 0 && (
        <FormGenerator
          onSubmit={_onSubmit}
          inputs={[
            {
              label: "Tata Username",
              inputType: "text",
              id: "Tata_onboard_username_textfield",
              required: true,
              placeholder: "Enter Tata Username",
              name: "_username",
              value: _username,
              handleChange: _handleChange,
              belowLabelComponent: (
                <p className="">
                  This may be your email or the phone number, depending on how
                  your vehicle was registered with TATA.
                </p>
              ),
            },

            {
              label: "Phone Number",
              inputType: "text",
              id: "Tata_onboard_phone_textfield",
              required: true,
              placeholder: "+911010101010",
              name: "_phoneNo",
              value: _phoneNo,
              handleChange: _handleChange,
              belowLabelComponent: (
                <p className="">Please add +91 at the beginning.</p>
              ),
            },
            {
              label: "Tata Password / PIN",
              inputType: "password",
              id: "Tata_onboard_password_textfield",
              required: true,
              placeholder: "Enter Tata Password",
              name: "_password",
              value: _password,
              handleChange: _handleChange,
              belowLabelComponent: (
                <p className="">
                  <strong> We don't store your username or password.</strong> We
                  obtain a Access Token from Tata to poll data on the user's
                  behalf.
                </p>
              ),
            },

            {
              label: "Is this a Child / Tennant account?",
              inputType: "checkbox",
              id: "Tata_onboard_is_child_textfield",
              required: true,
              name: "_isChild",
              value: _isChild,

              handleChange: () => setState({ ...state, _isChild: !_isChild }),
            },
          ]}
          error={_error}
          loading={_loading}
        />
      )}

      {_step == 1 ? (
        _step2Loading ? (
          <div className="spinner spinner-border"> </div>
        ) : (
          <div>
            <div
              class="alert alert-info bg-info text-white border-0"
              role="alert"
            >
              Tata Account connected, We found{" "}
              <strong>{_vehicles.length}</strong> vehicle(s) associated with
              your Tata account.
            </div>

            {_currentVehicleIdx < _vehicles.length && (
              <>
                <h2 className="mt-2">
                  Vehicle {_currentVehicleIdx + 1} of {_vehicles.length}:{" "}
                  {_vehicles[_currentVehicleIdx].name}
                </h2>
                <p>
                  Please confirm the below information to ensure data accuracy.
                </p>
                <p className="mt-4">
                  <strong> Vehicle Model </strong>: Tata Nexon EV
                </p>

                <p className="mt-4">
                  <strong> Vehicle Chassis Number </strong>:{" "}
                  {_vehicles[_currentVehicleIdx].vin}
                </p>
                <p>
                  <strong> Vehicle Name </strong>:{" "}
                  {_vehicles[_currentVehicleIdx].name}
                </p>
                <p>
                  <span className="mr-2">
                    <strong> Vehicle Trim </strong>
                  </span>
                  <span>
                    <Dropdown
                      placeholder="Please confirm your vehicle's trim from the list."
                      options={_trimOptions}
                      value={_trim}
                      onChange={(e, { value }) =>
                        setState({ ...state, _trim: value })
                      }
                      search
                      selection
                    />
                  </span>
                </p>
                <button
                  onClick={_onboardVehicle}
                  className="btn btn-primary"
                  style={{ width: "200px" }}
                >
                  Save & Continue
                </button>
              </>
            )}
          </div>
        )
      ) : null}

      {_step == 2 && (
        <div>
          <p>
            Your account is now connected with Keemut. We will now proceed to
            collect data from the TATA api for your vehicles. You will see your
            live stats and odometer for now, We hope to add more stats based on
            the data in the coming days.
          </p>
          <a href="/dashboard/vehicles">
            <button className="btn btn-primary">Continue</button>
          </a>
        </div>
      )}

      {/* <Accordion
        defaultActiveIndex={0}
        panels={[
          {
            key: "panel-1a",
            title: "Does Keemut Store my TATA credentials?",
            content:
              "No, We only obtain an access token from TATA, which authorises us to poll vehicle data on behalf of the user.",
          },

          {
            key: "panel-1a",
            title: "Where can I find my Tata ZConnect Username and Password?",
            content:
              "For most users, the username is either their email or their phone number (along with +91) and password is a 4 digit PIN.",
          },

          {
            key: "panel-1a",
            title: "Does this require any external hardware?",
            content: "No external hardware is required for this.",
          },

          {
            key: "panel-1a",
            title: "Does Keemut get data directly from my vehicle?",
            content:
              "We don't have any connection with the vehicle, we only get the latest data that is available on the Tata Servers for your vehicle.",
          },

          {
            key: "panel-1a",
            title: "Does Keemut Fix the infamous 'Sync' Issue?",
            content:
              "The Sync issue is totally relied on the communication between the vehicle and the Tata servers, as such Keemut has no part to play in this.",
          },

          {
            key: "panel-1a",
            title: "Is my data publicly accessible?",
            content:
              "No, the data is stored in a publicly inaccessible database. Only the owner of the vehicle has access to his data and nobody else.",
          },

          {
            key: "panel-1a",
            title: "Some of my data is inaccurate, What can I do?",
            content:
              "In case you find any bugs, Feel free to report them at tech@keemut.com",
          },
        ]}
        styled
      /> */}
    </div>
  );
};

export default Onboard;
