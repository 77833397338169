import React, { useState } from "react";
import { API } from "../../../../config";

const ChangePassword = () => {
  const [state, setState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    loading: false,
    error: null,
    success: false,
  });
  const {
    oldPassword,
    newPassword,
    confirmNewPassword,
    loading,
    error,
    success,
  } = state;

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const isFormValid = () => {
    if (confirmNewPassword != newPassword) {
      setState({
        ...state,
        error: "Confirm Password and New Password do not match",
      });
      return false;
    } else if (
      !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(newPassword)
    ) {
      setState({
        ...state,
        error: "The New password does not meet the rules specified above.",
      });
      return false;
    } else {
      return true;
    }
  };

  const updatePassword = async () => {
    try {
      setState({ ...state, loading: true, error: null });

      if (!isFormValid()) {
        return;
      }
      const { email } = JSON.parse(localStorage.getItem("user"));
      const res = await fetch(`${API}/update-password`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ email, oldPassword, newPassword }),
      });

      const data = await res.json();

      if (data.done) {
        setState({ ...state, success: true, loading: false, error: null });
      } else {
        setState({
          ...state,
          success: false,
          loading: false,
          error: "Unable to change your password at this time",
          confirmNewPassword: "",
          oldPassword: "",
          newPassword: "",
        });
      }
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        error: "Unable to change your password at this time",
      });
    }
  };

  return (
    <div className="card card-body">
      <h4>Change Password</h4>
      <div class="alert alert-info my-2" role="alert">
        Password Must Contain a Capital Letter, A Number and Symbol
      </div>
      <div className="form-group my-3">
        <label for="password">Enter Old Password</label>
        <div className="input-group input-group-merge">
          <input
            type="password"
            id="oldPassword"
            className="form-control"
            placeholder="Enter your old password"
            name="oldPassword"
            value={oldPassword}
            onChange={handleChange}
          />
          <div className="input-group-append" data-password="false">
            <div
              className="input-group-text"
              onClick={() => {
                const passwordInp = document.getElementById("oldPassword");
                if (passwordInp.type === "text") {
                  passwordInp.type = "password";
                } else {
                  passwordInp.type = "text";
                }
              }}
            >
              <span className="password-eye"></span>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label for="password">Enter New Password</label>
        <div className="input-group input-group-merge">
          <input
            type="password"
            id="newPassword"
            className="form-control"
            placeholder="Enter your new password"
            name="newPassword"
            value={newPassword}
            onChange={handleChange}
          />
          <div className="input-group-append" data-password="false">
            <div
              className="input-group-text"
              onClick={() => {
                const passwordInp = document.getElementById("newPassword");
                if (passwordInp.type === "text") {
                  passwordInp.type = "password";
                } else {
                  passwordInp.type = "text";
                }
              }}
            >
              <span className="password-eye"></span>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label for="password">Confirm New Password</label>
        <div className="input-group input-group-merge">
          <input
            type="password"
            id="confirmNewPassword"
            className="form-control"
            placeholder="Enter your old password"
            name="confirmNewPassword"
            value={confirmNewPassword}
            onChange={handleChange}
          />
          <div className="input-group-append" data-password="false">
            <div
              className="input-group-text"
              onClick={() => {
                const passwordInp = document.getElementById(
                  "confirmNewPassword"
                );
                if (passwordInp.type === "text") {
                  passwordInp.type = "password";
                } else {
                  passwordInp.type = "text";
                }
              }}
            >
              <span className="password-eye"></span>
            </div>
          </div>
        </div>
      </div>
      <small className="text-muted">
        Forgot your password ?{" "}
        <a href="/forgot-password" style={{ color: "blue" }}>
          {" "}
          Click Here{" "}
        </a>{" "}
        to reset
      </small>
      {error && (
        <div class="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      {success && (
        <div class="alert alert-success my-2" role="alert">
          Your password has been updated!
        </div>
      )}
      <button className="btn mt-3 btn-primary" onClick={updatePassword}>
        {" "}
        {loading ? (
          <>
            {" "}
            <span
              class="spinner-grow spinner-grow-sm mr-1"
              role="status"
              aria-hidden="true"
            ></span>
            Saving...{" "}
          </>
        ) : (
          "Save"
        )}{" "}
      </button>
    </div>
  );
};

export default ChangePassword;
