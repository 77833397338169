import React, { useEffect, useState } from "react";
import {getAbrpMeta} from "../../../services/abrp-service"
import moment from "moment"

const Abrp = () => {
  const [state, setState] = useState({
    _loading: true,
    _connected: false,
    _connectedAt: null,
    _abrpEmail: null
  })

  const {_loading, _connected, _connectedAt, _abrpEmail} = state;

  useEffect(() => {
    let mount = true;

    if(mount) 
      _init();

    return function cleanup() {
      mount = false;
    }
  }, [])

  const _init = async () => {
    try {
      const data = await getAbrpMeta();
      
      if(data.abrpMeta.length > 0) {
        const meta = data.abrpMeta[0];
        console.log(meta);
      } else {
        setState({...state, _loading: false, _connected: false, _abrpEmail: null, _connectedAt: null})
      }
    } catch(err){ 

    }
  }
  


  return (
    <div className="text-dark">
      <h1>Connect With ABRP </h1>
      <div className="card card-body">
        <p>Status : {_connected ? "Connected" : "Not Connected"}</p>
        <p>ABRP Account : {_abrpEmail || "N/A"}</p>
        <p>Connected At : {_connectedAt || "N/A"}</p>
        <button
          className="btn btn-primary"
          onClick={() => {
            window.location = `https://abetterrouteplanner.com/oauth/auth?client_id=247&scope=set_telemetry,vehicle_history&response_type=code&redirect_uri=${"http://localhost:3000/dashboard/abrp/oauth"}`;
          }}
        >
          {_connected ? "Disconnect" : "Connect"}
        </button>
      </div>
    </div>
  );
};

export default Abrp;
