import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import RegisterPage from "./pages/register-page";
import LoginPage from "./pages/login-page";
import Dashboard from "./components/dashboard/Dashboard";
import VerifyEmail from "./components/verify-email/VerifyEmail";
import NotFound from "./components/common/NotFound";
import Landing from "./components/landing/Landing";
import SampleKeemutReport from "./components/sample-keemut-report/SampleKeemutReport";
import AuthAction from "./components/auth-action/AuthAction";
import Dials from "./components/landing/Dials";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import Pricing from "./components/landing/Pricing";
import CheckoutPage from "./components/payment/CheckoutPage";
import KeemutReport from "./components/keemut-report/KeemutReport";
import VinDecoder from "./components/keemut-report/VinDecoder";
import FreeReport from "./components/keemut-report/free-report/FreeReport";
import PorscheDataCup from "./components/keemut-report/PorscheDataCup";

const Routes = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/register" component={RegisterPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/vin-decoder" component={VinDecoder} />

        <Route exact path="/verify-account" component={VerifyEmail} />
        <Route exact path="/" component={Landing} />
        <Route exact path="/dials" component={Dials} />

        <Route path="/auth-action" component={AuthAction} />

        <Route exact path="/sample-report" component={SampleKeemutReport} />
        <Route exact path="/keemut-report/:vin" component={KeemutReport} />
        <Route exact path="/porsche-data-cup" component={PorscheDataCup} />

        <Route exact path="/free-vin-report/:vin" component={FreeReport} />

        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/checkout" component={CheckoutPage} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
