import React, { useEffect, useState } from "react"
import SuperchargerTracking from "./supercharger-tracking"
import TeslaFleetPerformanceStats from "./tesla-fleet-performance-stats"
import TeslaFleetTimeStats from "./tesla-fleet-time-stats"

export default function TeslaFleet() {

  const [state, setState] = useState({
    _loading: true,
    _user: null
  })

  const { _loading, _user } = state

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))

    setState({...state, _loading: false, _user: user})
  }, [])

  return _loading ? <div className="spinner spinner-border"></div> : <div className="p-3 card card-body text-dark">
    <h1>
      Tesla Fleet Analytics
    </h1>

    { _user.fkusersuid != "D3PJatN5xsYb9x7Bdci2EcovpaS2" ? <p>This service is limited to our fleet consumers. To avail this service for your fleet, please <a href="https://keemut.com/contact" target="_blank" style={{color: "blue"}}>Contact Us</a></p> : <>
    <TeslaFleetTimeStats />
    <SuperchargerTracking />
    </> }
  

  </div>
}