import React, { useState } from "react";
import Chart from "react-apexcharts";
const DummyRadials = () => {
  const [state, setState] = useState({
    loading: false,
    price: 0,
    options1: {
      colors: ["#7523E8"],
      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["High Way"],
    },
    series1: [30],
    options2: {
      colors: ["#7523E8"],

      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["Off Road"],
    },
    series2: [5],

    options3: {
      colors: ["#7523E8"],
      fill: {
        type: "gradient",
      },
      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["City Driving"],
    },
    series3: [40],
    options4: {
      colors: ["#7523E8"],

      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["Extreme Highway"],
    },
    series4: [25],
  });
  const {
    options1,
    options2,
    series1,
    series2,
    price,
    loading,
    options3,
    options4,
    series3,
    series4,
  } = state;

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class=" mdi mdi-road-variant widget-icon bg-primary-lighten text-primary"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">
                  Highway Driving
                </h5>
                <Chart options={options1} series={series1} type="radialBar" />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class=" mdi mdi-highway  widget-icon bg-danger-lighten text-danger"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">Off Road</h5>
                <Chart options={options2} series={series2} type="radialBar" />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class=" mdi mdi-road widget-icon bg-success-lighten text-success"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">City Driving</h5>
                <Chart options={options3} series={series3} type="radialBar" />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class="mdi mdi-truck-fast-outline widget-icon bg-warning-lighten text-warning"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">
                  Extreme Highway Driving
                </h5>
                <Chart options={options4} series={series4} type="radialBar" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DummyRadials;
