import simpleFetch from "../../helpers/fetch-wrapper";

/**
 * @author Hemang Bhogayata
 * @description create the fuel form
 * @param {Object} formData
 * @param {String} vehicleId
 * @returns
 */
export async function createFuelFormService(formData, vehicleId) {
  try {
    return await simpleFetch(
      `/fuel-reports/${vehicleId}`,
      "POST",
      formData,
      {}
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 * @author Hemang Bhogayata
 * @description get mpg of the vehicle
 * @param {String} vehicleId
 * @param {String} make
 * @param {String} model
 * @returns
 */
export async function getFuelEconomyService(vehicleId, make, model) {
  try {
    return await simpleFetch(
      `/fuel-reports/economy/${vehicleId}/?make=${make}&model=${model}`
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 * @author Hemang Bhogayata
 * @description Get fuel report by id
 * @param {String} fuelReportId
 * @returns
 */
export async function getFuelReportByIdService(fuelReportId) {
  try {
    return await simpleFetch(`/fuel-reports/single/${fuelReportId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Edit fuel report
 * @param {String} vehicleId
 * @param {String} fuelReportId
 * @param {Object} formData
 * @returns
 */
export async function editFuelReportService(vehicleId, fuelReportId, formData) {
  try {
    return await simpleFetch(
      `/fuel-reports/${vehicleId}/${fuelReportId}`,
      "PUT",
      formData,
      {}
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Edit fuel report
 * @param {String} vehicleId
 * @returns
 */
export async function getFuelTcoService(vehicleId) {
  try {
    return await simpleFetch(`/fuel-reports/tco/${vehicleId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Get fuel data monthly
 * @param {String} vehicleId
 * @returns
 */
export async function getMonthlyFuelDataService(vehicleId) {
  try {
    return await simpleFetch(`/fuel-reports/monthly/${vehicleId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

/**
 * @author hemang Bhogayata
 * @description Get all the fuel reports of the vehicle
 * @param {String} vehicleId
 * @returns
 */
export async function getAllFuelReportsService(vehicleId) {
  try {
    return await simpleFetch(`/fuel-reports/${vehicleId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

/**
 * @author Hemang Bhogayata
 * @description Get the count of fuel reports
 * @param {String} vehicleId
 * @returns
 */
export async function getFuelReportCountService(vehicleId) {
  try {
    return await simpleFetch(`/fuel-reports/count/${vehicleId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}
