import React from "react";
import LoginForm from "../components/login/login-form";
import WebRoutes from "../web-routes";

const LoginPage = (props) => {
  return (
    <React.Fragment>
      <div
        id="Login-Container"
        className=" authentication-bg"
        style={{ minHeight: "100vh" }}
      >
        <div className="account-pages  mb-5 container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              {/* Login Card Start*/}
              <div className="card mt-5">
                {/* Login Card Header Start */}
                <div className="card-header pt-4 pb-4 text-center bg-dark">
                  <a id="login_logo_link" href={WebRoutes.KEEMUT_LANDING}>
                    <span>
                      <img
                        src={require("../assets/keemut-beta-white.svg")}
                        alt=""
                        class="logo-dark"
                        height="40"
                      />
                    </span>
                  </a>
                </div>
                {/* Login Card Header End */}

                {/* Login Form Start */}
                <LoginForm {...props} />
                {/* Login Form End */}
              </div>
              {/* Login Card Start*/}

              {/* Login Footnote start */}
              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted">
                    Don't have an account?{" "}
                    <a
                      href={WebRoutes.REGISTER_ROUTE}
                      className="text-muted ml-1"
                    >
                      <b>Sign Up</b>
                    </a>
                  </p>
                </div>
              </div>
              {/* Login Footnote end */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginPage;
