import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
const BugReport = () => {
  const [state, setState] = useState({
    showDialog: false,
  });
  const { showDialog } = state;
  return (
    <div className="fuel-main" style={{ maxWidth: "100%" }}>
      <h3 className="fuel-header-heading">Add Bug Report</h3>
      <div style={{ backgroundColor: "#ffffff" }} className="mx-2 my-3">
        <div className=" container p-3  card-body">
          <div className="row">
            <div className="col-lg-12">
              <div class="form-group">
                <label for="example-textarea">Bug Description</label>
                <textarea
                  class="form-control"
                  id="example-textarea"
                  rows="5"
                ></textarea>
              </div>
              <br />
              <button
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => setState({ ...state, showDialog: true })}
              >
                Submit Bug
              </button>
            </div>
          </div>
          <Dialog
            open={showDialog}
            onClose={() => setState({ ...state, showDialog: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div class="modal-content">
              <div class="modal-body p-4">
                <div class="text-center">
                  <i class="dripicons-information h1 text-info"></i>
                  <h4 class="mt-2">Heads up!</h4>
                  <p class="mt-3">
                    Thanks For the Submitting the bug, Our Tech team will
                    resolve the bug in 2-3 Working Days. If it still remains
                    unresolved, feel free to mail us at tech@keemut.com
                  </p>
                  <button
                    type="button"
                    class="btn btn-info my-2"
                    data-dismiss="modal"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
      <br /> <br /> <br /> <br />
      <br /> <br /> <br /> <br />
      <br /> <br /> <br /> <br />
      <br />
    </div>
  );
};

export default BugReport;
