import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  Polyline,
} from "react-leaflet";
import { Tab } from "semantic-ui-react";
import FormGenerator from "../../common/FormGenerator";
import GeofenceService from "../../../services/geofence-service";

function AddGeofence(props) {
  const [state, setState] = useState({
    _position: [51.505, -0.09],
    _markers: [],
    _latLngs: [],
    _mousePosition: [],
    _continueAdding: true,
    _draggable: false,
    _activeIndex: 0,
    _panes: [
      {
        menuItem: "Add Markers",
        render: () => <Tab.Pane></Tab.Pane>,
      },
      {
        menuItem: "Edit Markers",
        render: () => <Tab.Pane></Tab.Pane>,
      },

      {
        menuItem: "Clear All Markers",
        render: () => <Tab.Pane></Tab.Pane>,
      },
    ],
    _fetching: true,
    _loading: false,
    _error: null,
    _trigger: null,
    _name: null,
    _editRequest: false,
    _geofenceId: null,
    _geofenceRadius: null,
    _alertMode: "notification",
    _geofenceRadiusCenter: null,
    _geofenceType: "polygon",
    _success: false,
  });
  const {
    _markers,
    _error,
    _latLngs,
    _mousePosition,
    _continueAdding,
    _activeIndex,
    _panes,
    _draggable,
    _trigger,
    _loading,
    _name,
    _fetching,
    _geofenceId,
    _geofenceRadius,
    _geofenceRadiusCenter,
    _editRequest,
    _geofenceType,
    _position,
    _success,
    _alertMode,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) _initialRequest();

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const _initialRequest = async () => {
    try {
      setState({ ...state, _fetching: true });
      const query = new URLSearchParams(window.location.search);
      const geofenceId = query.get("id");

      if (!geofenceId) {
        return setState({ ...state, _fetching: false });
      }

      const data = await GeofenceService.getGeofenceById(geofenceId);

      if (!data.geofence) return setState({ ...state, _fetching: false });

      let geofence = data.geofence;

      let coordinatesStringArray = geofence.polygon_coords.split(";");

      let coordinates = [];
      let markers = [];

      coordinatesStringArray.forEach((item) => {
        let temp = item.split(",");

        if (temp.length == 2) {
          coordinates.push([parseFloat(temp[0]), parseFloat(temp[1])]);
          markers.push({ lat: parseFloat(temp[0]), lng: parseFloat(temp[1]) });
        }
      });

      setState({
        ...state,
        _markers: markers,
        _continueAdding: false,
        _activeIndex: 1,
        _draggable: true,
        _latLngs: coordinates,
        _fetching: false,
        _name: geofence.name,
        _editRequest: true,
        _alertMode: geofence.alert_mode,
        _trigger: geofence.trigger_on,
        _geofenceId: geofenceId,
        _geofenceType: geofence.geofence_type,
        _position: coordinates[0],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    try {
      e.preventDefault();
      setState({ ...state, _loading: true, _error: null });
      let vehicle = JSON.parse(localStorage.getItem("vehicle"));
      let polygonCoords = "";

      _latLngs.forEach((ltlng) => {
        polygonCoords += `${ltlng[0]},${ltlng[1]};`;
      });

      let data;

      if (!_editRequest) {
        data = await GeofenceService.createGeofence(
          vehicle.vin,
          vehicle.id,
          polygonCoords,
          _geofenceType,
          _geofenceRadius,
          _name,
          _geofenceRadiusCenter,
          _trigger,
          _alertMode
        );
      } else {
        data = await GeofenceService.updateGeofenceById(
          _geofenceId,
          polygonCoords,
          _geofenceType,
          _geofenceRadius,
          _name,
          _geofenceRadiusCenter,
          _trigger,
          _alertMode
        );
      }

      if (data.done)
        setState({ ...state, _loading: false, _error: null, _success: true });
      else
        setState({
          ...state,
          _loading: false,
          _error: "Unable to add geofence at the moment ",
        });
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        _loading: false,
        _error: "Unable to add geofence at the moment",
      });
    }
  };

  const _handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const Map = () => {
    const _map = useMapEvents({
      click(e) {
        console.log(e.latlng);
        if (_continueAdding) {
          _markers.push(e.latlng);
          _latLngs.push([e.latlng.lat, e.latlng.lng]);
          setState({ ...state, _markers, _latLngs });
        }
      },
      mousemove(e) {
        setState({ ...state, _mousePosition: [e.latlng.lat, e.latlng.lng] });
      },
    });

    return null;
  };

  if (_success) return <Redirect to="/dashboard/geofence" />;

  if (_fetching)
    return (
      <div
        className="spinner spinner-border center-spinner"
        role="status"
      ></div>
    );

  return (
    <div className="text-dark">
      <h1>{_editRequest ? "Edit" : "Add"} Geofence</h1>

      <div className="card card-body">
        <Tab
          activeIndex={_activeIndex}
          panes={_panes}
          onTabChange={(e, data) => {
            if (data.activeIndex == 2) {
              setState({
                ...state,
                _markers: [],
                _latLngs: [],
                _continueAdding: true,
                _activeIndex: 0,
                _draggable: false,
              });
            } else if (data.activeIndex == 1) {
              setState({
                ...state,
                _activeIndex: data.activeIndex,
                _continueAdding: false,
                _draggable: true,
              });
            } else if (data.activeIndex == 0) {
              setState({
                ...state,
                _activeIndex: data.activeIndex,
                _continueAdding: true,
                _draggable: false,
              });
            }
          }}
        />

        <MapContainer
          style={{ height: "400px", width: "100%" }}
          center={_position}
          zoom={5}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Map />
          {_markers.map((pos, id) => (
            <Marker
              draggable={_draggable}
              title="Click on the first marker to close the geofence"
              eventHandlers={{
                click: (e) => {
                  _latLngs.push([e.latlng.lat, e.latlng.lng]);
                  setState({ ...state, _latLngs, _continueAdding: false });
                },
                dragend: (e) => {
                  let m = _markers;
                  let l = _latLngs;

                  l[id] = [e.target._latlng.lat, e.target._latlng.lng];
                  m[id] = e.target._latlng;

                  if (id == 0) {
                    l[_latLngs.length - 1] = [
                      e.target._latlng.lat,
                      e.target._latlng.lng,
                    ];
                    m[_markers.length - 1] = e.target._latlng;
                  }
                  setState({ ...state, _markers: m, _latLngs: l });
                },
              }}
              key={id}
              position={pos}
            ></Marker>
          ))}
          {_latLngs.map((item, i) => {
            // if only one lat long exists show no line
            // or if the it is the last element, accessing i+1 would result in index out of bound issue
            // so show no line in that case as well
            if (_latLngs.length == 1 || i + 1 >= _latLngs.length) return null;

            // show the polyline for all other cases from i to i+1 lat longs
            return <Polyline positions={[item, _latLngs[i + 1]]} />;
          })}
          {_latLngs.length > 0 && _continueAdding && (
            <Polyline
              positions={[_latLngs[_latLngs.length - 1], _mousePosition]}
            />
          )}
        </MapContainer>
        <br />
        <br />

        <FormGenerator
          onSubmit={_onSubmit}
          inputs={[
            {
              label: "Geofence Name",
              inputType: "text",
              id: "geofence_add_name_textfield",
              required: true,
              placeholder: "Enter Geofence name",
              name: "_name",
              value: _name,
              handleChange: _handleChange,
            },
            {
              label: "Geofence Trigger On",
              inputType: "dropdown",
              id: "geofence_add_trigger_on_dropdown",
              required: true,
              placeholder: "Choose When the geofence should trigger",
              name: "_trigger",
              value: _trigger,
              dropdownOptions: [
                {
                  key: 1,
                  text: "On Enter",
                  value: "enter",
                },
                {
                  key: 2,
                  text: "On Exit",
                  value: "exit",
                },
                {
                  key: 3,
                  text: "Both",
                  value: "both",
                },
              ],
              handleChange: (e, { value }) =>
                setState({ ...state, _trigger: value }),
            },
            {
              label: "Alert via",
              inputType: "dropdown",
              id: "geofence_add_alert_via_dropdown",
              required: true,
              placeholder: "Alert on geofence status change via",
              name: "_alertMode",
              value: _alertMode,
              dropdownOptions: [
                {
                  key: 1,
                  text: "Email",
                  value: "email",
                },
                {
                  key: 2,
                  text: "Push Notification to Keemut App",
                  value: "notification",
                },
                {
                  key: 3,
                  text: "Both",
                  value: "both",
                },
              ],
              handleChange: (e, { value }) => {
                setState({ ...state, _alertMode: value });
              },
            },
          ]}
          error={_error}
          loading={_loading}
        />
      </div>
    </div>
  );
}

export default AddGeofence;
