import simpleFetch from "../../helpers/fetch-wrapper";

const TeslaFleetService = {
  getSuperchargerTriggers: async () => {
    return await simpleFetch(`/tesla-fleet/supercharger-triggers`, "GET");
  },

  getFleetStats: async () => {
    return await simpleFetch(`/tesla-fleet/stats`, "GET");
  },
}

export default TeslaFleetService;
