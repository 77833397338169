import React from "react";
import { Helmet } from "react-helmet";

const PorscheDataCup = () => {
  return (
    <div className="container my-3">
      <Helmet>
        <meta
          name="description"
          content="See how Keemut uses the Porsche API for Porsche Taycan on the Hockenheim Ring for the Porsche Data Cup"
        />
        <title>Keemut | Porsche</title>
      </Helmet>
      <div className="card card-body">
        <h1 className="display-2">
          <strong>Porsche Data Cup</strong>
        </h1>

        <p className="mt-3 text-muted" style={{ fontSize: "18px" }}>
          <span style={{ fontSize: "22px", color: "#848484" }}>
            Find your car's exact value based on how its treated with the Keemut
            report.
          </span>
          <br /> <br />
          We use our Keemut Machine Learning models to predict the value of cars
          based on how they are maintained, driven, charged, among many other
          factors. The data below represents Porsche data from the Porsche API
          from High-Mobility's emulator which we simulated over a period of 12
          months. We used Porsche Taycan valuations since the Cross Turismo is a
          newer vehicle, and we see strong correlation in pricing amongst
          different trims.
        </p>
      </div>
      <div className="card card-body mt-3">
        <div className="row ">
          <div className="col-lg-12">
            <h1 className="display-3 text-center">Sam's Work Car</h1>
            <p
              className="mt-3 text-muted text-center"
              style={{ fontSize: "18px" }}
            >
              Sam works at the Fordwerke Assembly plant as a Logistics Manager
              in Saarlouis and lives in Saabrucken. He uses his Porsche Taycan
              Cross Turismo for driving back and forth to work mostly. He
              sometimes will go fast on the Autobahn, but generally loves to
              take care of his Porsche. His dream is to work for Porsche one
              day.
            </p>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-12 text-center">
            <img src={require("../../assets/Taycan.png")} width="60%" />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-4 text-center">
            <img height="150px" src={require("../../assets/work-1.svg")} />
          </div>

          <div className="col-lg-4 text-center">
            <img height="150px" src={require("../../assets/work-2.svg")} />
          </div>

          <div className="col-lg-4 text-center">
            <img height="150px" src={require("../../assets/work-3.svg")} />
          </div>
        </div>
        <a target="_blank" href="/keemut-report/1HM888055H7DDA033">
          <button
            className="btn btn-lg btn-outline-primary mt-3"
            style={{ width: "100%" }}
          >
            View Report
          </button>
        </a>
      </div>

      <div className="card card-body mt-3">
        <div className="row ">
          <div className="col-lg-12">
            <h1 className="display-3 text-center">Timo's Rental Race Car</h1>
            <p
              className="mt-3 text-muted text-center"
              style={{ fontSize: "18px" }}
            >
              Timo owns a race car rental company near the Hockenheim Ring. His
              cars are used and abused on the racetrack by both professionals
              and amateurs. He keeps the cars for two years and then sells them
              online. He wants to get the most rentals out for the least hassle.
              He has recently gotten a Porsche Taycan Cross Turismo, which is
              his hottest rental at the moment. He hopes to sell it before the
              battery is used up.
            </p>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-12 text-center">
            <img src={require("../../assets/Taycan2.png")} width="60%" />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-4 text-center">
            <img height="150px" src={require("../../assets/race-1.svg")} />
          </div>

          <div className="col-lg-4 text-center">
            <img height="150px" src={require("../../assets/race-2.svg")} />
          </div>

          <div className="col-lg-4 text-center">
            <img height="150px" src={require("../../assets/race-3.svg")} />
          </div>
        </div>
        <a target="_blank" href="/keemut-report/1HM888055H7DDA034">
          <button
            className="btn btn-lg btn-outline-primary mt-3"
            style={{ width: "100%" }}
          >
            View Report
          </button>
        </a>
      </div>
    </div>
  );
};

export default PorscheDataCup;
