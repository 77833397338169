import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { API } from "../../../config";
import Dialog from "@material-ui/core/Dialog";
import resolveCurrency from "../../../helpers/Helper";
import moment from "moment";

const RevenueForm = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: true,
    vehicles: [],
    units: {},
    success: false,
    date: null,
    description: null,
    revenue: null,
    expense: null,
    formData: new FormData(),
    cost: null,
    error: null,
    submitting: false,
    startDate: null,
    endDate: null,
    showUploadingDialog: false,
  });

  const {
    loading,
    units,
    success,
    date,
    description,
    revenue,
    expense,
    formData,
    cost,
    error,
    submitting,
    startDate,
    endDate,
    showUploadingDialog,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) intialRequest();

    return function cleanUp() {
      mounted = false;
    };
  }, []);

  const intialRequest = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    fetch(`${API}/units/${user.uid}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((jsonData) => {
        localStorage.setItem("units", JSON.stringify(jsonData.units));
        setState({
          ...state,
          units: jsonData.units,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const isFormValid = (e) => {
    console.log(formData.get("dataFile"));
    if ((!date || !revenue || !expense) && !formData.get("dataFile")) {
      setState({ ...state, error: "Please Fillout All The Fields" });
      return false;
    } else {
      return true;
    }
  };

  const addRevenue = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      setState({ ...state, submitting: true });
      console.log(formData.get("dataFile"));
      if (!date && !description && !expense && !revenue) {
        const token = localStorage.getItem("token");

        fetch(`${API}/revenue-reports/excel/${vehicle.id}`, {
          method: "POST",
          headers: {
            Accept: "application/json",

            Authorization: `Bearer ${token}`,
          },
          body: formData,
        })
          .then((res) => res.json())
          .then((data) => {
            if (!data.done) {
              return setState({
                ...state,
                loading: false,
                error:
                  "We were unable to process your Excel Data. Please make sure the format is exactly same as the provided template.",
              });
            }
            setState({
              ...state,
              submitting: false,
              loading: false,
              success: true,
            });
          })
          .catch((err) => {
            console.log(err);
            setState({
              ...state,
              loading: false,
              error:
                "We were unable to process your Excel Data. Please make sure the format is exactly same as the provided template.",
            });
          });
      } else {
        formData.set("date", moment(date).format("YYYY-MM-DD"));
        formData.set("end_date", moment(endDate).format("YYYY-MM-DD"));
        formData.set("description", description);
        formData.set("revenue", revenue);
        formData.set("expense", expense);

        const token = localStorage.getItem("token");
        fetch(`${API}/revenue-reports/${vehicle.id}`, {
          method: "POST",
          headers: {
            Accept: "application/json",

            Authorization: `Bearer ${token}`,
          },
          body: formData,
        })
          .then((res) => res.json())
          .then((data) => {
            if (!data.done) {
              return setState({
                ...state,
                loading: false,
                error:
                  "We were unable to process your Excel Data. Please make sure the format is exactly same as the provided template.",
              });
            }

            setState({
              ...state,
              submitting: false,
              loading: false,
              success: true,
            });
          })
          .catch((err) => {
            return setState({
              ...state,
              loading: false,
              error:
                "We were unable to process your Excel Data. Please make sure the format is exactly same as the provided template.",
            });
          });
      }
    }
  };

  if (loading) {
    return <div class="spinner-border text-primary" role="status"></div>;
  }

  return (
    <div className="text-dark">
      <h3>Add Revenue</h3>
      <div style={{ backgroundColor: "#ffffff" }} className="mx-2 my-3">
        <div className=" container p-3  card-body">
          <div className="row">
            <div className="col-lg-12">
              <div class="form-group">
                <label>
                  Bulk Upload Excel / CSV{" "}
                  <small>
                    The Excel File Must obey a certain format,{" "}
                    <a
                      target="_blank"
                      href="https://docs.google.com/spreadsheets/d/1XujzB_s9a7pFVzRK3PAqKvpgM_-UpqyihKPFvgdLN5Y/edit?usp=sharing"
                      style={{ color: "blue" }}
                    >
                      Click Here
                    </a>{" "}
                    to get a template
                  </small>{" "}
                </label>
                <div class="input-group">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="inputGroupFile04"
                      name="attachment"
                      onChange={(e) => {
                        document.getElementById("file-name").innerHTML =
                          e.target.files[0].name;
                        formData.set("dataFile", e.target.files[0]);
                      }}
                    />
                    <label
                      class="custom-file-label"
                      id="file-name"
                      for="inputGroupFile04"
                    >
                      Optional
                    </label>
                  </div>
                </div>
                <small>
                  Depending on amount of data, The saving the data may take upto
                  a minute.
                </small>
              </div>
              <div class="separator my-3">OR</div>
              <h4>
                Adding For {vehicle.make} {vehicle.model}
              </h4>{" "}
              <br />
              <div class="form-group">
                <label>Enter start date</label>
                <div>
                  <input
                    type="date"
                    class="form-control date"
                    id="birthdatepicker"
                    data-toggle="date-picker"
                    data-single-date-picker="true"
                    name="date"
                    value={date}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="form-group">
                <label>Enter end date</label>
                <div className="input-group">
                  <input
                    type="date"
                    class="form-control date"
                    id="birthdatepicker"
                    data-toggle="date-picker"
                    data-single-date-picker="true"
                    name="endDate"
                    value={endDate}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="form-group">
                <label>Description</label>
                <div className="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Trip Description"
                    name="description"
                    value={description}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Enter Revenue</label>

                <br />
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">
                      {resolveCurrency(units.currencyunit.toUpperCase())}
                    </label>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter any trip expenses"
                    name="revenue"
                    value={revenue}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Trip Expenses (if any)</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">
                      {resolveCurrency(units.currencyunit.toUpperCase())}
                    </label>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter any trip expenses"
                    name="expense"
                    value={expense}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="form-group">
                <label>Trip Receipt</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="inputGroupFile04"
                      name="attachment"
                      onChange={(e) => {
                        document.getElementById("attachment-name").innerHTML =
                          e.target.files[0].name;
                        formData.set("attachment", e.target.files[0]);
                      }}
                    />
                    <label
                      class="custom-file-label"
                      id="attachment-name"
                      for="inputGroupFile04"
                    >
                      Optional
                    </label>
                  </div>
                </div>
              </div>
              <br />
              {error && (
                <div
                  class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
                  role="alert"
                >
                  <strong>Error - </strong> {error}
                </div>
              )}
              <button
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={addRevenue}
              >
                {submitting ? "Saving..." : "Submit"}
              </button>
            </div>

            <Dialog
              disableEscapeKeyDown
              disableBackdropClick
              open={success}
              onClose={() => {
                setState({ ...state, success: false });
                window.location.reload(true);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div class="modal-content modal-filled bg-success">
                <div class="modal-body p-4">
                  <div class="text-center">
                    <i class="dripicons-checkmark h1"></i>
                    <h4 class="mt-2">Revenue Added!</h4>
                    <p class="mt-3">
                      If you added a Excel / CSV file, It may take us upto a
                      minute to get all your data into our system. Your
                      dashboard will update soon with the new data, meanwhile,
                      you can proceed to use other features.
                    </p>
                    <button
                      type="button"
                      class="btn btn-light my-2"
                      data-dismiss="modal"
                      onClick={() => {
                        setState({ ...state, success: false });
                        window.location.reload(true);
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueForm;
