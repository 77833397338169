import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { set } from "d3v4";
import { API, site } from "../../config";
import { Tab } from "semantic-ui-react";

const LoginDialog = ({ showLoginDialog, closeLoginDialog }) => {
  const [state, setState] = useState({
    email: "",
    password: "",
    error: null,
    loading: false,
    agreeTnc: false,
  });
  const { email, password, error, loading, agreeTnc } = state;

  const panes = [
    {
      menuItem: "Login",
      render: () => (
        <Tab.Pane>
          <>
            <div class="form-group">
              <label for="emailaddress1">Email address</label>
              <input
                class="form-control"
                type="email"
                id="emailaddress1"
                required=""
                placeholder="john@deo.com"
                name="email"
                value={email}
                onChange={handleChange}
              />
            </div>

            <div class="form-group">
              <label for="password1">Password</label>
              <input
                class="form-control"
                type="password"
                required=""
                id="password1"
                placeholder="Enter your password"
                name="password"
                value={password}
                onChange={handleChange}
              />
            </div>

            <div class="form-group text-center mt-3">
              <button
                class="btn  btn-primary"
                style={{ width: "100px" }}
                onClick={handleSubmitForLogin}
              >
                {loading ? "Loading..." : "Sign In"}
              </button>
            </div>
          </>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Register",
      render: () => (
        <Tab.Pane>
          {" "}
          <>
            <div class="form-group">
              <label for="emailaddress1">Email address</label>
              <input
                class="form-control"
                type="email"
                id="emailaddress1"
                required=""
                placeholder="john@deo.com"
                name="email"
                value={email}
                onChange={handleChange}
              />
            </div>

            <div class="form-group">
              <label for="password1">Password</label>
              <input
                class="form-control"
                type="password"
                required=""
                id="password1"
                placeholder="Enter your password"
                name="password"
                value={password}
                onChange={handleChange}
              />
            </div>

            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="checkbox-signup"
                  checked={agreeTnc}
                  onChange={() => setState({ ...state, agreeTnc: !agreeTnc })}
                />
                <label class="custom-control-label" for="checkbox-signup">
                  I accept{" "}
                  <a
                    target="_blank"
                    href="/terms-and-conditions"
                    class="text-muted"
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div>
            </div>

            <div class="form-group text-center mt-3">
              <button
                class="btn  btn-primary"
                style={{ width: "100px" }}
                onClick={handleSubmitForRegister}
              >
                {loading ? "Loading..." : "Sign In"}
              </button>
            </div>
          </>{" "}
        </Tab.Pane>
      ),
    },
  ];

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const handleSubmitForRegister = () => {
    setState({ ...state, loading: true });
    fetch(`${API}/register-email-password?loginAsWell=true`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.taken) {
          return setState({
            ...state,
            error: "Email has already been taken by some user!",
            loading: false,
          });
        }
        if (data.done) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("user", JSON.stringify(data.user));

          setState({ ...state, loading: false, error: null });
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          error: "Unable to Register, Please Try again later",
          loading: false,
        });
      });
  };

  const handleSubmitForLogin = () => {
    setState({ ...state, loading: true });
    fetch(`${API}/login-email-password`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error)
          if (data.error.type === "not-verified") {
            window.location = `${site}/verify-account`;
          }

        if (data.done) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("user", JSON.stringify(data.user));

          setState({ ...state, loading: false, error: null });
          window.location.reload();
        } else {
          setState({
            ...state,
            error: true,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          error: true,
          loading: false,
        });
      });
  };

  return (
    <Dialog
      open={showLoginDialog}
      onClose={closeLoginDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div class="modal-content dialog p-3 mt-2">
        <div class="modal-body">
          <div class="text-center mt-2 mb-4">
            <a href="/" class="text-success">
              <span>
                <img
                  src={require("../../assets/keemut-dark.png")}
                  alt="keemut"
                  height="36"
                />
              </span>
            </a>
          </div>
          <Tab panes={panes} />
          {error && (
            <div class="alert alert-danger my-2" role="alert">
              Incorrect Email or Password was entered.
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default LoginDialog;
