import React, { useState, useEffect } from "react";
import NumberFormat from "../../helpers/number-format-helper";
import { API } from "../../config";
import moment from "moment";
import simpleFetch from "../../helpers/fetch-wrapper";
import resolveCurrency from "../../helpers/Helper";
import { getMsrpByMMTYService } from "../../services/vehicle-list-service";
import {
  getGenericMlValuationService,
  getMl1AndMl2ValuationService,
  createVinSearchHistoryService,
} from "../../services/keemut-report-service";

const ValuationBanner = ({ vehicle, units, isFreeReport }) => {
  const [state, setState] = useState({
    _loading: true,
    _predictedValuation: 84000,
    _depreciation: 14.34,
    _futureValue: 0,
    _msrp: null,
  });
  const { _loading, _futureValue, _predictedValuation, _msrp, _depreciation } =
    state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      _getKeemutValuation();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  // Gets the keemut valuation / generic valuation
  const _getKeemutValuation = async () => {
    try {
      // Get the msrp
      const pdata = await getMsrpByMMTYService(
        vehicle.make,
        vehicle.model,
        vehicle.trim,
        vehicle.year,
        vehicle.country_code
      );

      let data;

      // free reports use the generic valuation
      if (isFreeReport) {
        data = await getGenericMlValuationService(
          vehicle.make,
          vehicle.model,
          vehicle.year,
          vehicle.trim,
          15000 * (moment().format("YYYY") - vehicle.year + 1), // assume 15k miles driven every year
          pdata.price,
          pdata.price,
          vehicle.drivetrain,
          "Automatic",
          "White",
          "White"
        );
        vehicle.purchaseprice = pdata.price;
      } else {
        data = await getMl1AndMl2ValuationService(vehicle.id, vehicle.vin);
      }

      if (data.done) {
        if (
          vehicle.vin == "1HM888055H7DDA034" ||
          vehicle.vin == "1HM888055H7DDA033"
        ) {
          return setState({
            ...state,
            _loading: false,
            _futureValue: vehicle.vin == "1HM888055H7DDA034" ? 77490 : 93677,
            _predictedValuation:
              vehicle.vin == "1HM888055H7DDA034" ? 77728 : 94173,
            _msrp: parseInt(pdata.price * units.currencyRateWrtUsd),
          });
        }
        // console.log(pdata.price, units);
        let msrp = parseInt(pdata.price);

        // if source country is USA then do fx conversion as the price is in USD and needs to be converted to local curency
        if (pdata.source == "USA")
          msrp = parseInt(msrp * units.currencyRateWrtUsd);

        // copy by value, by default js does it by reference which will screw things up as it is a prop
        let myUnits = JSON.parse(JSON.stringify(units));

        // pdata.price = pdata.price * units.currencyRateWrtUsd;
        if (data.result.currency_code != "USD") myUnits.currencyRateWrtUsd = 1;

        setState({
          ...state,
          _loading: false,
          _futureValue: parseInt(
            parseInt(
              data.result.price_data[data.result.price_data.length - 1].price
            ) * myUnits.currencyRateWrtUsd
          ),
          _predictedValuation: parseInt(
            data.result.current_price * myUnits.currencyRateWrtUsd
          ),
          _msrp: msrp,
        });

        // add to vin search results table if a free report was used
        if (isFreeReport) {
          await createVinSearchHistoryService(
            vehicle.vin,
            vehicle.make,
            vehicle.model,
            vehicle.year,
            vehicle.trim,
            data.result.current_price + data.result.std_deviation.upper,
            data.result.current_price + data.result.std_deviation.lower
          );
        }
      } else {
        setState({ ...state, _loading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (_loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h3>Valuation</h3>
          <div className="row">
            <div className="col-lg-12  text-center">
              <div className="spinner-border text-primary" role="status" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="keemut-report_valuation_banner" className="card">
      <div className="card-body">
        <h3>Valuation</h3>
        <div className="row">
          <div className="col-lg-12  text-center">
            <div className="row bg-light">
              <div className="col-lg-3">
                <p class="text-muted mb-0 mt-3">Keemut AI™ Valuation</p>
                <h2 class="font-weight-normal mb-3">
                  <small class="mdi mdi-checkbox-blank-circle text-primary align-middle mr-1"></small>
                  <span className="text-success">
                    {resolveCurrency(units.currencyunit)}{" "}
                    {_predictedValuation && NumberFormat(_predictedValuation)}
                  </span>
                </h2>
              </div>

              <div className="col-lg-3">
                <p class="text-muted mb-0 mt-3">Next Month Prediction</p>
                <h2 class="font-weight-normal mb-3">
                  <small class="mdi mdi-checkbox-blank-circle text-primary align-middle mr-1"></small>
                  <span className="text-primary">
                    {resolveCurrency(units.currencyunit)}{" "}
                    {_futureValue && NumberFormat(_futureValue)}
                  </span>
                </h2>
              </div>
              <div className="col-lg-3">
                <p class="text-muted mb-0 mt-3">Depreciation</p>
                <h2 class="font-weight-normal mb-3">
                  <small class="mdi mdi-checkbox-blank-circle text-primary align-middle mr-1"></small>
                  <span className="text-danger">
                    {_predictedValuation &&
                      _msrp &&
                      (((_predictedValuation - _msrp) / _msrp) * 100).toFixed(
                        2
                      )}
                    %
                  </span>
                </h2>
              </div>
              <div className="col-lg-3">
                <p class="text-muted mb-0 mt-3">MSRP</p>
                <h2 class="font-weight-normal mb-3">
                  <small class="mdi mdi-checkbox-blank-circle text-primary align-middle mr-1"></small>
                  <span className="text-warning">
                    {resolveCurrency(units.currencyunit)}{" "}
                    {NumberFormat(_msrp && parseFloat(_msrp).toFixed(0))}
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuationBanner;
