import React, { useEffect, useState } from "react";
import YearOverYearChart from "./YearOverYearChart";
import CumulativeDepreciation from "./CumulativeDepreciation";
import FloatingButton from "../common/FloatingButton";
import { API } from "../../../config";
const Valuation = () => {
  const [state, setState] = useState({
    isSubscribed: false,
  });
  const { isSubscribed } = state;

  useEffect(() => {
    let mounted = true;
    const user = JSON.parse(localStorage.getItem("user"));

    const getSubscriptionStatus = async () => {
      try {
        const res = await fetch(`${API}/subscription/${user.subscription_id}`, {
          method: "GET",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const data = await res.json();
        console.log(data);

        setState({ isSubscribed: data.isSubscribed });
      } catch (err) {}
    };

    if (mounted) {
      getSubscriptionStatus();
    }

    return function () {
      mounted = false;
    };
  }, []);

  if (!isSubscribed) {
    return (
      <div
        class="alert alert-info alert-dismissible fade show text-center"
        role="alert"
        style={{ width: "100%" }}
      >
        This is a premium feature, Please upgrade your subscription to access
        this.{" "}
        <a href="/pricing" className="text-danger">
          Click Here
        </a>{" "}
        to upgrade
      </div>
    );
  }

  return (
    <div className="text-dark">
      <FloatingButton />
      <div className="row">
        <div className="col-lg-6">
          <YearOverYearChart />
        </div>
        <div className="col-lg-6">
          <CumulativeDepreciation />
        </div>
      </div>

      {/* <div className="row">
        <div className="col-lg-6">
          <AreaChart />
        </div>
      </div> */}
    </div>
  );
};

export default Valuation;
