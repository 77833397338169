import simpleFetch from "../helpers/fetch-wrapper";

/**
 * @author Hemang Bhogayata
 * @description Get user's units by uid
 * @param {String} uid
 */
export async function getUserUnitsService(uid) {
  try {
    return await simpleFetch(`/units/${uid}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

/**
 * @author Hemang Bhogayata
 * @description Get the currency fx rates
 * @param {String} currency
 */
export async function getFxRateService(currency) {
  try {
    return await simpleFetch(
      "/currency-conversion",
      "POST",
      JSON.stringify({
        currency,
      })
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 * @author Hemang Bhogayata
 * @description Get the fuel price by country
 * @param {String} country
 * @param {String} state
 * @returns
 */
export async function getFuelPriceService(country, state) {
  try {
    return await simpleFetch(
      "/units/fuel-price",
      "POST",
      JSON.stringify({
        country,
        state,
      })
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 * @author Hemang Bhogayata
 * @description Get the electricity price by country
 * @param {String} country
 * @param {String} state
 * @returns
 */
export async function getElectricityPriceService(country, state) {
  try {
    return await simpleFetch(
      "/units/electricity-price",
      "POST",
      JSON.stringify({
        country,
        state,
      })
    );
  } catch (err) {
    console.log(err);
  }
}
