import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Input } from "semantic-ui-react";
import { API, site } from "../../../config";
import AddConnectedTesla from "./AddConnectedTesla";
import { Helmet } from "react-helmet";
import resolveCurrency from "../../../helpers/Helper";
import { Dropdown } from "semantic-ui-react";
import FloatingButton from "../common/FloatingButton";
import { useHistory } from "react-router-dom";
import simpleFetch from "../../../helpers/fetch-wrapper";
import NumberFormat from "../../../helpers/number-format-helper";

const Vehicles = ({ updateDropdownOptions, updateVehicleDropdownValues }) => {
  const [state, setState] = useState({
    vehicles: [],
    vehicle: {},
    loading: true,
    showDeleteDialog: false,
    paginations: 0,
    currentPage: 1,
    units: {},
    vehicleId: null,
    nickname: null,
    purchasePrice: null,
    year: null,
    odometer: null,
    showEditDialog: false,
    vin: null,
    editLoading: false,
    showTeslaDialog: false,
    plateNo: null,
    searchQuery: null,
    make: null,
    vehicleListContent: null,
    trimOptions: [],
    model: null,
    selectedTrim: null,
    workRelated: null,
  });
  const {
    selectedTrim,
    vehicles,
    vehicle,
    loading,
    showDeleteDialog,
    paginations,
    currentPage,
    units,
    vehicleId,
    showEditDialog,
    nickname,
    purchasePrice,
    vin,
    year,
    odometer,
    editLoading,
    showTeslaDialog,
    make,
    plateNo,
    searchQuery,
    vehicleListContent,
    workRelated,
    trimOptions,
    model,
  } = state;

  const history = useHistory();

  const renderVehicleList = () => {
    let content = [];
    let filtered = [];
    if (searchQuery) {
      vehicles.map((vehicle) => {
        if (
          vehicle.make.toLowerCase().includes(searchQuery.toLowerCase()) ||
          vehicle.model.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (vehicle.vin &&
            vehicle.vin.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (vehicle.plate_no &&
            vehicle.plate_no
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          vehicle.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
          filtered.push(vehicle);
      });
    } else filtered = vehicles;
    filtered.map((vehicle, i) => {
      return i < currentPage * 10 && i >= (currentPage - 1) * 10
        ? content.push(
            <tr>
              <td
                data-label="Nickname"
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => {
                  localStorage.setItem("vehicle", JSON.stringify(vehicle));
                  window.location.reload();
                }}
              >
                {vehicle.name}
              </td>
              <td data-label="Make">{vehicle.make}</td>
              <td data-label="Model">{vehicle.model}</td>
              <td data-label="VIN">{vehicle.plate_no}</td>

              <td data-label="VIN" style={{ fontSize: "12px" }}>
                {vehicle.vin}
              </td>
              <td data-label="Price">
                {resolveCurrency(units.currencyunit.toUpperCase())}{" "}
                {vehicle.purchaseprice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td data-label="Action">
                <a class="action-icon">
                  {" "}
                  <i
                    class="mdi mdi-pencil"
                    onClick={() => getVehicleById(vehicle.id)}
                  ></i>
                </a>
                <a class="action-icon mx-4">
                  {" "}
                  <i
                    onClick={() => {
                      setState({
                        ...state,
                        showDeleteDialog: true,
                        vehicleId: vehicle.id,
                      });
                    }}
                    class="mdi mdi-delete"
                  ></i>
                </a>
              </td>
            </tr>
          )
        : null;
    });
    return <> {content} </>;
  };

  useEffect(() => {
    let mounted = true;

    const getTrimList = () => {
      fetch(`${API}/vehicle-list/trim?model=${model}&year=${year}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          let trimOptions = [];
          data.trimList.forEach((m) => {
            trimOptions.push({ key: m.trim, value: m.trim, text: m.trim });
          });
          setState({
            ...state,

            trimOptions,
            selectedTrim: vehicle.trim,
            model,
          });
        })
        .catch((err) => console.log(err));
    };

    if (mounted) {
      getTrimList();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [model]);

  useEffect(() => {
    getAllVehicles();
  }, []);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const getAllVehicles = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    setState({ ...state, loading: true });
    fetch(`${API}/vehicles/all/${user.uid}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem("vehicles", JSON.stringify(data.vehicles));

        fetch(`${API}/units/${user.uid}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((respose) => respose.json())
          .then((resValue) => {
            setState({
              ...state,
              loading: false,
              vehicles: data.vehicles,
              paginations: Math.ceil(data.vehicles.length / 10),
              units: resValue.units,
            });
            renderVehicleList();
            // const { options } = updateDropdownOptions(data.vehicles);
            // updateVehicleDropdownValues(options, data.vehicles);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  const deleteVehicle = () => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    simpleFetch(`/vehicles/${vehicleId}`, "DELETE")
      .then((data) => {
        setState({ ...state, showDeleteDialog: false });
        // window.location = `${site}/dashboard/vehicles`;
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, showDeleteDialog: false });
      });
  };

  const updateVehicle = (e) => {
    setState({ ...state, editLoading: true });
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    simpleFetch(
      `/vehicles/${vehicle.id}`,
      "PUT",
      JSON.stringify({
        nickname,
        purchasePrice,
        odometer: parseInt(odometer),
        vin,
        year,
        plateNo,
        trim: selectedTrim,
        workRelated,
      })
    )
      .then((data) => {
        localStorage.setItem("odoChange", true);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, editLoading: false, showEditDialog: false });
      });
  };

  const getVehicleById = (vehicleId) => {
    const token = localStorage.getItem("token");

    simpleFetch(`/vehicles/${vehicleId}`)
      .then((data) => {
        setState({
          ...state,
          vehicle: data.vehicle,
          showEditDialog: true,
          make: data.vehicle.make,
          purchasePrice: data.vehicle.purchaseprice,
          nickname: data.vehicle.name,
          odometer: data.vehicle.odometer,
          vin: data.vehicle.vin,
          year: data.vehicle.year,
          plateNo: data.vehicle.plate_no,
          model: data.vehicle.model,
          workRelated: data.vehicle.work_related,
        });
      })
      .catch((err) => console.log(err));
  };

  const initiateSmartcarAuth = async () => {
    try {
      const res = await fetch(`${API}/smartcar/initiate-oauth`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const data = await res.json();

      if (data.done) window.location = data.authUrl;
    } catch (err) {}
  };

  if (loading) {
    return (
      <div>
        <div class="spinner-border text-primary" role="status"></div>
      </div>
    );
  }

  const renderPagination = () => {
    let content = [];
    for (let i = 1; i <= paginations; i++) {
      content.push(
        <a
          onClick={() => setState({ ...state, currentPage: i })}
          class="page-link"
          style={
            currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
      );
    }
    return <> {content} </>;
  };

  const sortByPropertyAsc = (propertyName) => {
    function compare(v1, v2) {
      const valueA =
        typeof v1[propertyName] === "string"
          ? v1[propertyName].toUpperCase()
          : v1[propertyName];
      const valueB =
        typeof v2[propertyName] === "string"
          ? v2[propertyName].toUpperCase()
          : v2[propertyName];

      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      return comparison;
    }
    const values = vehicles;
    values.sort(compare);
    setState({ ...state, vehicles: values });
  };

  const sortByPropertyDesc = (propertyName) => {
    function compare(v1, v2) {
      const valueA =
        typeof v1[propertyName] === "string"
          ? v1[propertyName].toUpperCase()
          : v1[propertyName];
      const valueB =
        typeof v2[propertyName] === "string"
          ? v2[propertyName].toUpperCase()
          : v2[propertyName];

      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      return comparison;
    }
    const values = vehicles;
    values.sort(compare);
    setState({ ...state, vehicles: values.reverse() });
  };

  return (
    <div className="text-dark">
      <Helmet>
        <title>Keemut | Vehicles</title>
      </Helmet>
      <FloatingButton />
      <h1>Vehicle List</h1>
      <br />
      <div class="card">
        <div class="card-body">
          <div className="row">
            <div className="col-lg-4">
              <h3>Vehicle List</h3>
              <p class="text-muted font-5">
                List your cars here. Add/delete cars too.
              </p>{" "}
            </div>
            <div className="col-lg-8" style={{ textAlign: "end" }}>
              {" "}
              <Input
                className="mr-2"
                placeholder="Search..."
                onChange={(e, { value }) => {
                  setState({ ...state, searchQuery: value });
                }}
              />
              <a href="/dashboard/vehicles/add">
                <button className="btn btn-primary">Add Vehicle</button>
              </a>
              {/* <Dropdown
                selection
                placeholder="Add Vehicle"
                options={[
                  {
                    key: "Tesla",
                    text: "Tesla",
                    value: "Tesla",
                  },

                  {
                    key: "Smartcar",
                    text: "Smartcar",
                    value: "Smartcar",
                  },

                  {
                    key: "Other",
                    text: "Other",
                    value: "Other",
                  },
                ]}
                onChange={(e, { value }) => {
                  if (value == "Tesla") {
                    history.push("/dashboard/tesla/onboard");
                  } else if (value == "Smartcar") {
                    initiateSmartcarAuth();
                  } else {
                    history.push("/dashboard/vehicles/add");
                  }
                }}
              /> */}
              {/* <Link to="/dashboard/vehicles/add">
                <button className="btn btn-primary">Add Vehicle</button>
              </Link>
              <Link to="/dashboard/tesla/onboard">
                <button className="btn btn-primary ml-2">
                  Connect Tesla Account
                </button>
              </Link>
              <button
                onClick={initiateSmartcarAuth}
                className="btn btn-primary ml-2"
              >
                Connect Using Smart Car
              </button> */}
              <br />
            </div>
          </div>

          <br />
          <br />
          <div>
            <table className="table-striped">
              <thead>
                <tr>
                  <th>
                    Nickname{" "}
                    <i
                      className=" caret up icon p-0 ml-3"
                      onClick={() => sortByPropertyAsc("name")}
                    ></i>
                    <i
                      className="caret down icon p-0"
                      onClick={() => sortByPropertyDesc("name")}
                    ></i>{" "}
                  </th>
                  <th>
                    Make{" "}
                    <i
                      className=" caret up icon ml-3"
                      onClick={() => sortByPropertyAsc("make")}
                    ></i>
                    <i
                      className="caret down icon"
                      onClick={() => sortByPropertyDesc("make")}
                    ></i>
                  </th>
                  <th>
                    Model{" "}
                    <i
                      className=" caret up icon ml-3"
                      onClick={() => sortByPropertyAsc("model")}
                    ></i>
                    <i
                      className="caret down icon"
                      onClick={() => sortByPropertyDesc("model")}
                    ></i>
                  </th>
                  <th>
                    License Plate{" "}
                    <i
                      className=" caret up icon ml-3"
                      onClick={() => sortByPropertyAsc("plate_no")}
                    ></i>
                    <i
                      className="caret down icon"
                      onClick={() => sortByPropertyDesc("plate_no")}
                    ></i>
                  </th>
                  <th>
                    VIN{" "}
                    <i
                      className=" caret up icon ml-3"
                      onClick={() => sortByPropertyAsc("vin")}
                    ></i>
                    <i
                      className="caret down icon"
                      onClick={() => sortByPropertyDesc("vin")}
                    ></i>
                  </th>
                  <th>
                    Price{" "}
                    <i
                      className=" caret up icon ml-3"
                      onClick={() => sortByPropertyAsc("purchasePrice")}
                    ></i>
                    <i
                      className="caret down icon"
                      onClick={() => sortByPropertyDesc("purchaseprice")}
                    ></i>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>{renderVehicleList()}</tbody>
            </table>
          </div>
          <br />
          <nav aria-label="Page navigation example" style={{ float: "right" }}>
            <ul class="pagination pagination">{renderPagination()}</ul>
          </nav>
        </div>
      </div>
      <Dialog
        open={showDeleteDialog}
        onClose={() => setState({ ...state, showDeleteDialog: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div class="modal-content">
          <div class="modal-body p-4">
            <div class="text-center">
              <i class="dripicons-warning h1 text-warning"></i>
              <h4 class="mt-2" style={{ color: "#6c757d" }}>
                Are You Sure ?
              </h4>
              <p
                class="mt-3"
                style={{ color: "#6c757d", fontFamily: "Roboto" }}
              >
                Once deleted, You will not be able to recover <br /> this car or
                any other information associated with it.
              </p>
              <button
                type="button"
                class="btn btn-light mt-3 mx-2"
                data-dismiss="modal"
                onClick={() => setState({ ...state, showDeleteDialog: false })}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-warning mt-3 mx-2"
                data-dismiss="modal"
                onClick={deleteVehicle}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={showEditDialog}
        onClose={() => setState({ ...state, showEditDialog: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div class="modal-content dialog">
          <div class="modal-header modal-colored-header bg-primary">
            <h4
              class="modal-title"
              id="primary-header-modalLabel"
              style={{ color: "#ffffff", fontFamily: "Roboto" }}
            >
              Update Vehicle
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => setState({ ...state, showEditDialog: false })}
            >
              ×
            </button>
          </div>
          <div class="modal-body">
            <div className="p-3 ">
              <div class="form-group ">
                <label
                  className="overview-dialog-label"
                  style={{ color: "#6c757d", fontFamily: "Roboto" }}
                >
                  Vehicle Nickname
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter New Nickname"
                  name="nickname"
                  value={nickname}
                  disabled={vehicle.make === "Tesla" ? true : false}
                  onChange={handleChange}
                />
              </div>

              <div class="form-group ">
                <label
                  className="overview-dialog-label"
                  style={{ color: "#6c757d", fontFamily: "Roboto" }}
                >
                  License Plate
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Liscence Plate"
                  name="plateNo"
                  value={plateNo}
                  onChange={handleChange}
                />
              </div>

              <div class="form-group">
                <label
                  className="overview-dialog-label"
                  style={{ color: "#6c757d", fontFamily: "Roboto" }}
                >
                  Vehicle Info
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter New Nickname"
                  value={`${vehicle.make} ${vehicle.model}`}
                  disabled={true}
                />
              </div>

              <div class="form-group">
                <label
                  className="overview-dialog-label"
                  style={{ color: "#6c757d", fontFamily: "Roboto" }}
                >
                  Trim
                </label>
                <br />
                <Dropdown
                  search
                  selection
                  fluid
                  options={trimOptions}
                  onChange={(e, { value }) => {
                    setState({ ...state, selectedTrim: value });
                  }}
                  value={selectedTrim}
                />
              </div>
              <div class="form-group">
                <label
                  className="overview-dialog-label"
                  style={{ color: "#6c757d", fontFamily: "Roboto" }}
                >
                  Vehicle Year
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter New Nickname"
                  value={year}
                  name="year"
                  onChange={handleChange}
                  disabled={vehicle.make === "Tesla" ? true : false}
                />
              </div>

              <div class="form-group">
                <label
                  className="overview-dialog-label"
                  style={{ color: "#6c757d", fontFamily: "Roboto" }}
                >
                  Purchase Price
                </label>

                <div className="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      {resolveCurrency(units.currencyunit.toUpperCase())}
                    </span>
                  </div>
                  <input
                    class="form-control"
                    aria-describedby="basic-addon1"
                    placeholder="Update Price "
                    name="purchasePrice"
                    value={NumberFormat(
                      (purchasePrice || "").toString().replaceAll(",", ""),
                      units.country_code
                    )}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div class="form-group">
                <label
                  className="overview-dialog-label"
                  style={{ color: "#6c757d", fontFamily: "Roboto" }}
                >
                  Odometer
                </label>

                <div className="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      {units.distanceunit}
                    </span>
                  </div>
                  <input
                    class="form-control"
                    aria-describedby="basic-addon1"
                    placeholder="Update Ododmeter"
                    name="odometer"
                    value={NumberFormat(
                      (odometer || "").toString().replaceAll(",", ""),
                      units.country_code
                    )}
                    onChange={handleChange}
                    disabled={vehicle.make === "Tesla" ? true : false}
                  />
                </div>
              </div>

              <div class="form-group">
                <label
                  className="overview-dialog-label"
                  style={{ color: "#6c757d", fontFamily: "Roboto" }}
                >
                  VIN
                </label>

                <input
                  id="vin"
                  class="form-control"
                  aria-describedby="basic-addon1"
                  placeholder="Update VIN"
                  name="vin"
                  value={vin}
                  onChange={handleChange}
                  disabled={vehicle.make === "Tesla" ? true : false}
                />
              </div>
              <div className="form-group">
                <label className="overview-dialog-label">
                  Is the vehicle used for Business / Work?
                </label>
                <br />
                <input
                  type="checkbox"
                  id="switch0"
                  data-switch="none"
                  name="workRelated"
                  checked={workRelated}
                  onChange={() => {
                    setState({ ...state, workRelated: !workRelated });
                  }}
                />
                <label for="switch0" data-on-label="" data-off-label=""></label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light"
              data-dismiss="modal"
              onClick={() => setState({ ...state, showEditDialog: false })}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={updateVehicle}
            >
              {editLoading ? "Updating..." : "Save Changes"}
            </button>
          </div>
        </div>

        {/* 
          <button
            onClick={() => setState({ ...state, showEditDialog: false })}
            className="btn btn-success"
            style={{ width: "100%" }}
          >
            Submit
          </button>
        </div> */}
      </Dialog>

      {/* <AddConnectedTesla
        getAllVehicles={getAllVehicles}
        showTeslaDialog={showTeslaDialog}
        closeTeslaDialog={() => {
          setState({ ...state, showTeslaDialog: false });
          window.location.reload(true);
        }}
      /> */}
    </div>
  );
};

export default Vehicles;
