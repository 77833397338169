/**
 * @author Hemang Bhogayata [hemang@dashroad.com]
 * @copyright Keemut LLC, See License.txt for more info
 */

/**
 * @author Hemang Bhogayata
 * @description Get User from localstorage
 * @returns {Object}
 */

export default function getUser() {
  let user = localStorage.getItem("user");

  if (user) {
    return JSON.parse(user);
  } else {
    return null;
  }
}
