export const API = "https://api.keemut.com/api/v1";
// export const API = "https://api.keemut.com/api/v1";
export const site = "https://beta.keemut.com";
export const nextSite = "https://keemut.com";
export const firebaseConfig = {
  apiKey: "AIzaSyD57t23opvP6lbVzr-ebG9wLDYsHi3WT8M",
  authDomain: "keemut-auth.firebaseapp.com",
  databaseURL: "https://keemut-auth.firebaseio.com",
  projectId: "keemut-auth",
  storageBucket: "keemut-auth.appspot.com",
  messagingSenderId: "522893673378",
  appId: "1:522893673378:web:b78f2d4c725b47878d28fb",
  measurementId: "G-55QKJG5HC7",
};

export const STRIPE_PUBLISHABLE_KEY = "pk_live_km2JfkhXHzvnbmFyvAvBx8NS";
// export const STRIPE_PUBLISHABLE_KEY =
//   "pk_test_517QOguCijcjUBhneDWmJDIlcx8fvtcVwfn9nryypv57BzugDhCSVYEYOkR94HKZVGHYpyAHHvNbiCMniYJoUracl00EZ5WwKay";

// export const STRIPE_PUBLISHABLE_KEY =
//   "pk_test_517QOguCijcjUBhneDWmJDIlcx8fvtcVwfn9nryypv57BzugDhCSVYEYOkR94HKZVGHYpyAHHvNbiCMniYJoUracl00EZ5WwKay";

export const STRIPE_TESLA_SUBSCRIPTION_MONTHLY =
  "price_1Hr3mTCijcjUBhnezKYec7z2";
export const STRIPE_RTA_SUBSCRIPTION_MONTHLY = "price_1I1aeFCijcjUBhneQPQrCg1k";
export const STRIPE_SMARTCAR_SUBSCRIPTION_MONTHLY =
  "price_1Hr3mTCijcjUBhnepBy44iSl";

export const STRIPE_TESLA_SUBSCRIPTION_YEARLY =
  "price_1HrKYMCijcjUBhneNUdMmd4v";
export const STRIPE_RTA_SUBSCRIPTION_YEARLY = "price_1I1ag2CijcjUBhneSG99jLtn";
export const STRIPE_SMARTCAR_SUBSCRIPTION_YEARLY =
  "price_1HrKYMCijcjUBhnexYabVhYY";
export const INDEX_DB_NAME = "keemut";
