/**
 * @author Hemang Bhogayata [hemang@dashroad.com]
 * @copyright Keemut LLC, See License.txt for more info
 */

import { site, API } from "../config";

/**
 * @author Hemang Bhogayata
 * @description A wrapper around fetch to simplify api calls
 * @param {String} url - API ENDPOINT
 * @param {String} method - HTTP METHOD
 * @param {JSON} body - JSON BODY
 * @param {Object} customHeaders - HEADERS OBJECT
 */
export default async function simpleFetch(url, method, body, customHeaders) {
  try {
    const vehicleAuthToken = localStorage.getItem("vehicleAuthToken");
    const vehicleAuthTokenId = localStorage.getItem("vehicleAuthTokenId");
    const token = localStorage.getItem("token");

    const response = await fetch(`${API}${url}`, {
      method,
      headers: customHeaders
        ? {
            ...customHeaders,
            "User-Agent": "keemut-web",
            Authorization: `Bearer ${token}`,
            "x-vehicle-auth-token": vehicleAuthToken,
            "x-vehicle-auth-token-id": vehicleAuthTokenId,
          }
        : {
            "User-Agent": "keemut-web",

            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-vehicle-auth-token": vehicleAuthToken,
            "x-vehicle-auth-token-id": vehicleAuthTokenId,
          },
      body,
    });

    if (response.status == 401) {
      window.location = `${site}/login`;
    } else {
      const data = await response.json();
      return data;
    }
  } catch (err) {
    return err;
  }
}
