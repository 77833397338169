import React, { useState, useEffect } from "react";
import { API } from "../../config";
import NumberFormat from "../../helpers/number-format-helper";

const TrackingHistory = ({ vehicle, units }) => {
  const [state, setState] = useState({
    trackedAmount: 0,
    totalOdometer: 0,
    lifespanTracked: 0,
    drivingUtilisation: 0,
    loading: true,
  });
  const {
    trackedAmount,
    totalOdometer,
    lifespanTracked,
    drivingUtilisation,
    loading,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getUtilization();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getUtilization = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${API}/keemut-report/utilisation/${vehicle.id}`,
        {
          method: "GET",
          headers: {
            Accept: `application/json`,
          },
        }
      );
      const data = await res.json();

      const driveRes = await fetch(
        `${API}/keemut-report/driving-utilisation/${vehicle.vin}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        }
      );
      const driveData = await driveRes.json();
      if (data.done) {
        console.log("here");
        setState({
          ...state,
          loading: false,
          lifespanTracked: data.lifeSpanTracked,
          totalOdometer: data.totalOdometer,
          trackedAmount: data.amountTracked,
          drivingUtilisation:
            vehicle.vin == "1HM888055H7DDA033"
              ? 4.1
              : driveData.drivingUtilisation
              ? driveData.drivingUtilisation.toFixed(2)
              : 0,
        });
      } else {
        setState({ ...state, loading: false });
      }
    } catch (err) {
      console.log(err);
      setState({ ...state, loading: false });
    }
  };

  if (loading) {
    return (
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-clock-digital widget-icon bg-danger-lighten text-danger"></i>
          </div>
          <h5
            class="text-dark font-weight-normal mt-0"
            title="Distance Driven till date"
          >
            Tracking History
          </h5>
          <div className="spinner-border" role="status" />
        </div>
      </div>
    );
  }

  return (
    <div class="card widget-flat" id="keemut-report_tracking_history_card">
      <div class="card-body">
        <div class="float-right">
          <i class="mdi mdi-clock-digital widget-icon bg-danger-lighten text-danger"></i>
        </div>
        <h5
          class="text-dark font-weight-normal mt-0"
          title="Distance Driven till date"
        >
          Tracking History
        </h5>
        <h3 class="mt-3 mb-3">
          {" "}
          {NumberFormat(trackedAmount || 0)} {units.distanceunit}{" "}
        </h3>
        <p>
          Total Odometer : {NumberFormat(totalOdometer || 0)}{" "}
          {units.distanceunit}
        </p>
        <p>
          Lifespan Tracked :{" "}
          {NumberFormat(lifespanTracked != null ? lifespanTracked : 0)}%
        </p>
        <p>
          Driving Utilization :{" "}
          {vehicle.vin == "1HM888055H7DDA034"
            ? 15
            : drivingUtilisation != null
            ? drivingUtilisation
            : "N/A"}
          %{" "}
        </p>
      </div>
    </div>
  );
};

export default TrackingHistory;
