import simpleFetch from "../../helpers/fetch-wrapper";
import moment from "moment";

export async function updateReminderFormService(
  reminderType,
  vehicleId,
  reminderId
) {
  try {
    return await simpleFetch(
      `/reminders/${vehicleId}/${reminderId}`,
      "PUT",
      reminderType,
      {}
    );
  } catch (err) {
    console.log(err);
  }
}

export async function getAllReminderFormService(vehicleId) {
  try {
    return await simpleFetch(`/reminders/${vehicleId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}
