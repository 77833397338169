import React, { useState, useEffect } from "react";
import resolveCurrency from "../../../../helpers/Helper";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import { API } from "../../../../config";
import { editInsuranceFormService } from "../../../../services/forms/insurance-form-service";

const EditInuranceDialog = ({
  showEditDialog,
  closeEditDialog,
  insuranceReport,
  units,
  vehicle,
}) => {
  const [state, setState] = useState({
    date: moment(insuranceReport.date).format("YYYY-MM-DD"),
    renewalDate: moment(insuranceReport.renewalDate).format("YYYY-MM-DD"),
    cost: insuranceReport.cost,
    expense: insuranceReport.trip_expense,
    expenseType: insuranceReport.expenseType,
    formData: new FormData(),
    saving: false,
    error: null,
    previousAttachment: null,
    vendor: null,
    billNumber: null,
  });
  const {
    date,
    renewalDate,
    cost,
    expense,
    expenseType,
    saving,
    error,
    formData,
    previousAttachment,
    vendor,
    billNumber,
  } = state;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setState({
        ...state,
        date: moment(insuranceReport.date).format("YYYY-MM-DD"),
        renewalDate: moment(insuranceReport.end_date).format("YYYY-MM-DD"),
        cost: insuranceReport.cost,
        expense: insuranceReport.trip_expense,
        expenseType: insuranceReport.expenseType,
        previousAttachment: insuranceReport.attachment,
        vendor: insuranceReport.vendor,
        billNumber: insuranceReport.billNumber,
        saving: false,
      });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [insuranceReport]);

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const updateinsuranceReports = async () => {
    try {
      setState({ ...state, saving: true });
      formData.set("date", moment(date).format("YYYY-MM-DD"));
      formData.set("renewalDate", moment(renewalDate).format("YYYY-MM-DD"));
      formData.set("previousAttachment", previousAttachment);
      formData.set("cost", cost);
      formData.set("expenseType", expenseType);
      formData.set("billNumber", billNumber);
      formData.set("vendor", vendor);
      const data = await editInsuranceFormService(
        insuranceReport.id,
        formData,
        vehicle.id
      );

      if (data.done) {
        setState({ ...state, saving: false });
        closeEditDialog();
      } else {
        setState({
          ...state,
          error: "Unable to update at the moment.",
          saving: false,
        });
      }
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        error: "Unable to update at the moment.",
        saving: false,
      });
    }
  };

  return (
    <Dialog
      open={showEditDialog}
      onClose={closeEditDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-expenseType"
    >
      <div class="modal-content dialog">
        <div class="modal-header modal-colored-header bg-info">
          <h4
            class="modal-title"
            id="primary-header-modalLabel"
            style={{ color: "#ffffff", fontFamily: "Roboto" }}
          >
            Edit Insurance Report
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={closeEditDialog}
          >
            ×
          </button>
        </div>
        <div class="modal-body dialog">
          <div className="p-3">
            {error && (
              <div
                class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
                role="alert"
              >
                <strong>Error - </strong> {error}
              </div>
            )}
            <div className="form-group">
              <label>Expense type</label>

              <div className="input-group">
                <select
                  class="form-control select2"
                  data-toggle="select2"
                  name="expenseType"
                  value={expenseType}
                  onChange={handleChange}
                >
                  <option value="insurance">Insurance</option>
                  <option value="regulation">Government Regulations</option>
                  <option value="other">Other regulatory expenses </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label className="overview-dialog-label">Enter date</label>

              <div className="input-group">
                <input
                  type="date"
                  class="form-control date"
                  id="birthdatepicker"
                  data-toggle="date-picker"
                  data-single-date-picker="true"
                  name="date"
                  value={date}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="form-group">
              <label className="overview-dialog-label">Enter End Date</label>

              <div className="input-group">
                <input
                  type="date"
                  class="form-control date"
                  id="birthdatepicker"
                  data-toggle="date-picker"
                  data-single-date-picker="true"
                  name="renewalDate"
                  value={renewalDate}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="overview-dialog-label">Enter cost</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01">
                    {resolveCurrency(units.currencyunit.toUpperCase())}
                  </label>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter how much you paid"
                  name="cost"
                  value={cost}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div class="form-group">
              <label>Upload Insurance Receipt</label>
              <div class="input-group">
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="inputGroupFile04"
                    name="attachment"
                    onChange={(e) => {
                      document.getElementById("file-name").innerHTML =
                        e.target.files[0].name;
                      formData.set("attachment", e.target.files[0]);
                    }}
                  />
                  <label class="custom-file-label" id="file-name">
                    Optional
                  </label>
                </div>
              </div>
            </div>

            <button
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={updateinsuranceReports}
            >
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditInuranceDialog;
