import React from "react";
import InitialPrice from "./InitialPrice";
import FinalPrice from "./FinalPrice";
import KeemutSaving from "./KeemutSaving";
import KeemutValuationChart from "./KeemutValuationChart";

const KeemutValuation = ({ vehicle }) => {
  return (
    <>
      <div>
        <h3>Keemut Valuation®</h3>

        <div className="row">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                {" "}
                <KeemutValuationChart vehicle={vehicle} />{" "}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                {" "}
                {/* <KeemutValuationChart vehicle={vehicle} />{" "} */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <InitialPrice vehicle={vehicle} />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <KeemutSaving />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <FinalPrice />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeemutValuation;
