import Chart from "react-apexcharts";
import React, { useState } from "react";

const LineChart = ({ yAxisTitle, xAxisTitle, yaxis, yaxes, xaxis }) => {
  const [state, setState] = useState({
    _options: {
      chart: {
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
      },
      yaxis: {
        title: { text: yAxisTitle },
      },
      xaxis: {
        categories: xaxis,
        title: { text: xAxisTitle },
      },
      colors: ["#00274c", "#ffffff"],

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2.75,
        dashArray: 0,
      },
    },

    _series: yaxes || [
      {
        name: "",
        data: yaxis,
      },
    ],
  });

  const { _options, _series } = state;

  return <Chart options={_options} series={_series} />;
};

export default LineChart;
