import React from "react";
import { Dropdown } from "semantic-ui-react";

const FormGenerator = ({ inputs, onSubmit, loading, error }) => {
  return (
    <form onSubmit={onSubmit}>
      {error && (
        <div
          class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show mb-3"
          role="alert"
        >
          <strong>Error - </strong> {error}
        </div>
      )}
      {inputs.map((input) => {
        return (
          <div>
            {input.inputType == "text" || input.inputType == "password" ? (
              <div className="form-group">
                <label>{input.label}</label>
                {input.belowLabelComponent}
                <input
                  className="form-control"
                  type={input.inputType}
                  id={input.id}
                  required={input.required || false}
                  placeholder={input.placeholder}
                  name={input.name}
                  value={input.value}
                  onChange={input.handleChange}
                />
              </div>
            ) : input.inputType == "checkbox" ? (
              <div className="form-group">
                <label className="overview-dialog-label">{input.label}</label>
                {input.belowLabelComponent} <br />
                <input
                  type="checkbox"
                  id={input.id}
                  data-switch="none"
                  name={input.name}
                  value={input.value}
                  onChange={input.handleChange}
                />
                <label
                  for={input.id}
                  data-on-label=""
                  data-off-label=""
                ></label>
              </div>
            ) : (
              <>
                <div className="form-group">
                  <label>{input.label}</label>
                  {input.belowLabelComponent}
                  <div className="input-group">
                    <Dropdown
                      search
                      selection
                      options={input.dropdownOptions}
                      fluid
                      value={input.value}
                      onChange={input.handleChange}
                      placeholder={input.placeholder}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}

      <input
        type="submit"
        onSubmit={onSubmit}
        className="btn btn-primary mt-3"
        style={{ width: "100%" }}
        value={loading ? "Loading..." : "Save"}
      ></input>
    </form>
  );
};

export default FormGenerator;
