import React, { useState } from "react";
import { CSVLink } from "react-csv";
import DonutChart from "../../custom-charts/DonutChart";
import moment from "moment";
import { Dropdown } from "semantic-ui-react";
import TeslaService from "../../../helpers/tesla-helper";
import resolveCurrency from "../../../helpers/Helper";
import NumberFormat from "../../../helpers/number-format-helper";
import simpleFetch from "../../../helpers/fetch-wrapper";
import { convertTimeToTimezone } from "../../../helpers/date-time-helper";
import LinesEllipsis from "react-lines-ellipsis";
const TripTaxSavings = ({ vehicle, units }) => {
  const [state, setState] = useState({
    _trips: [],
    _startDate: moment().startOf("month"),
    _endDate: moment().endOf("month"),

    _month: new Date().getMonth(),
    _year: new Date().getFullYear(),

    _workMiles: null,
    _totalMiles: null,
    _taxSavings: null,
    _currentPage: null,
    _paginations: null,
    _loading: false,
    _error: null,
    _tripCsv: [
      [
        "id",
        "count",
        "vin",
        "Start Time",
        "End Time",
        "Start Location",
        "End Location",
        "Duration (min)",
        "Start Odometer",
        "End Odometer",
        "Distance",
       
      
        "Tax Savings (if any)",
        "Work Related",
      ],
    ],
    _firstSearch: false,
  });

  const {
    _endDate,
    _error,
    _loading,
    _tripCsv,
    _month,
    _startDate,
    _trips,
    _year,
    _taxSavings,
    _totalMiles,
    _workMiles,
    _currentPage,
    _firstSearch,
    _paginations,
  } = state;

  const _generateTaxReport = async () => {
    try {
      setState({ ...state, _loading: true, _error: null });
      const data = await TeslaService.getTaxReport(
        vehicle.vin,
        _startDate,
        _endDate
      );

      let totalMiles = 0;
      let workMiles = 0;
      let taxSavings = 0;

      data.trips.forEach((trip, i) => {
        let distance = trip.endOdometer - trip.startOdometer;
        console.log(trip.start_address);
        totalMiles += distance;

        _tripCsv.push([
          trip._id,
          data.trips.length - i,
          trip.vin,
          moment(
            convertTimeToTimezone(
              new Date(trip.start_time).toISOString(),
              units.timezone_name
            )
          ).format("DD-MMM-YY hh:mm A"),
          moment(
            convertTimeToTimezone(
              new Date(trip.end_time).toISOString(),
              units.timezone_name
            )
          ).format("DD-MMM-YY hh:mm A"),
          trip.startLocation || "N/A",
          trip.endLocation || "N/A",

          moment(trip.end_time).diff(moment(trip.start_time), "minutes"),
          trip.startOdometer,
          trip.endOdometer,
          (trip.endOdometer - trip.startOdometer).toFixed(2),
          trip.tax_savings ? ( (trip.endOdometer - trip.startOdometer) * (units.tax_rate || 0.56)  ).toFixed(2)   : "N/A",
          trip.tax_savings != null ? "YES" : "NO"
        ]);

        if (trip.tax_savings != null) {
          workMiles += parseFloat(distance);
          taxSavings += parseFloat(trip.tax_savings);
        }
      });

      setState({
        ...state,
        _loading: false,
        _error: null,
        _tripCsv: _tripCsv,
        _workMiles: parseInt(workMiles),
        _totalMiles: parseInt(totalMiles),
        _taxSavings: parseInt(taxSavings),
        _trips: data.trips ?  data.trips.reverse() :  [],
        _paginations: Math.ceil(data.trips.length / 10),
        _currentPage: 1,
        _firstSearch: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const _updateWorkRelated = async (trip) => {
    try {
      await simpleFetch(
        `/tesla/trips/${trip.vin}`,
        "PUT",
        JSON.stringify({
          _id: trip._id,
          workRelated: trip.tax_savings != null,
          taxDeductionRate: trip.tax_rate,
          distance: trip.endOdometer - trip.startOdometer,
        })
      );
    } catch (err) {}
  };

  const _renderPagination = () => {
    let content = [];
    for (let i = 1; i <= _paginations; i++) {
      
      content.push(
       <>
        <a
          onClick={() => setState({ ...state, _currentPage: i })}
          class="page-link"
          style={
            _currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
       </>
      );
    }
    return <> {content} </>;
  };

  if (vehicle.make != "Tesla" || !vehicle.vin)
    return (
      <div className="card card-body">
        <h4 className="text-danger">
          Tax Report is only available for Connected Tesla Vehicles.
        </h4>
      </div>
    );

  return (
    <div className="text-dark">
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-body">
            <h2>Potential Tax Savings Report</h2>
            <p>
              Your Current Tax Rate is {resolveCurrency(units.currencyunit)}{" "}
              {parseFloat(units.tax_rate == null || 0.56).toFixed(2)}. You can
              update it in{" "}
              <a href="/dashboard/settings" style={{ color: "blue " }}>
                Settings
              </a>
            </p>
            <div className="form-group">
              <label>Select Year</label>
              <Dropdown
                fluid
                search
                value={_year}
                selection
                placeholder="Select Year"
                options={(function () {
                  let ops = [];
                  for (let i = 2020; i <= new Date().getFullYear(); i++) {
                    ops.push({
                      text: i,
                      value: i,
                      key: i,
                    });
                  }
                  return ops;
                })()}
                onChange={(e, { value }) => {
                  if (_month) {
                    let m = _month < 10 ? `0${_month}` : _month;
                    let date = moment(`${value}-${m}-01`);
                    setState({
                      ...state,
                      _year: value,
                      _startDate: date.startOf("month").format(),
                      _endDate: date.endOf("month").format(),
                    });
                  } else {
                    setState({ ...state, _year: value });
                  }
                }}
              />
            </div>

            <div className="form-group">
              <label>Select Month</label>
              <Dropdown
                fluid
                search
                selection
                value={_month}
                placeholder="Select Month"
                options={(function () {
                  let ops = [{
                    text: "All",
                    value: "All",
                    key: "All",

                  }];

                  // for some weird reason, months are 0 based in moment... why???
                  for (let i = 0; i < 12; i++) {
                    ops.push({
                      text: moment.months(i),
                      value: i,
                      key: i,
                    });
                  }
                  return ops;
                })()}
                onChange={(e, { value }) => {
                  if(value == "All"){
                    setState({
                      ...state,
                      _month: value,
                      _endDate: `${_year}-12-31`,
                      _startDate: `${_year}-01-02`,
                    });
                  } else {
                    value++;
                  let m = value < 10 ? `0${value}` : value;
                  let date = moment(`${_year}-${m}-01`);
                  setState({
                    ...state,
                    _month: value,
                    _endDate: date.endOf("month").format(),
                    _startDate: date.startOf("month").format(),
                  });
                  }
                }}
              />
            </div>

            <button
              className="btn btn-primary mt-2"
              onClick={_generateTaxReport}
            >
              {_loading ? "Loading..." : "Generate Tax Report"}
            </button>
            {/* <CSVLink
              data={[]}
              className="btn btn-primary my-2"
              style={{ width: "15%" }}
              filename={`trips_${vehicle.vin}.csv`}
            >
              Download Data
            </CSVLink> */}
          </div>
        </div>
      </div>

      {_trips.length == 0 && _firstSearch && (
        <p>No trips found for the selected time interval.</p>
      )}

      {!_loading && _totalMiles && (
        <div className="row">
          <div className="col-lg-4">
            <div className="card card-body">
              <h3>Distance Driven related to Work</h3>

              <DonutChart
                formatter={(v) =>
                  `${NumberFormat(_workMiles)} ${units.distanceunit}`
                }
                percentValue={parseInt((_workMiles / _totalMiles) * 100)}
                label={"Distance Driven related to Work"}
              />
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card card-body">
              <h3>% Work Related Trips</h3>

              <DonutChart
                formatter={(v) => `${v}%`}
                percentValue={parseInt((_workMiles / _totalMiles) * 100)}
                label={"% Work Related"}
              />
            </div>
          </div>

          <div className="col-lg-4">
            <div className="card card-body">
              <h3>Potential Tax Savings</h3>

              <DonutChart
                formatter={(v) =>
                  `${resolveCurrency(units.currencyunit)} ${NumberFormat(
                    _taxSavings
                  )}`
                }
                percentValue={parseInt((_workMiles / _totalMiles) * 100)}
                label={"Potential Tax Savings"}
              />
            </div>
          </div>
        </div>
      )}

      {!_loading && _totalMiles && (
        <div className="card card-body">
          <CSVLink
            data={_tripCsv}
            className="btn btn-primary my-2"
            style={{ width: "15%" }}
            filename={`tax_report_${vehicle.vin}.csv`}
          >
            Download Trip Data
          </CSVLink>

          <table className="table-striped">
            <thead>
              <th>Count</th>
              <th>Start Time </th>
              <th>End Time</th>
              <th>Start Location</th>
              <th>End Location</th>

              <th>Duration (mins)</th>
              <th>Distance ({units.distanceunit})</th>

              <th>Potential Tax Savings</th>
              <th>Work Related</th>
            </thead>

            <tbody>
              {_trips.map((trip, i) => {
                return i < _currentPage * 10 && i >= (_currentPage - 1) * 10 ? (
                  <tr>
                    <td> {_trips.length - i} </td>
                    <td style={{ fontSize: "13px" }}>
                      {moment(
                        convertTimeToTimezone(
                          new Date(trip.start_time).toISOString(),
                          units.timezone_name
                        )
                      ).format("DD-MMM-YY hh:mm A")}
                    </td>
                    <td style={{ fontSize: "13px" }}>
                      {moment(
                        convertTimeToTimezone(
                          new Date(trip.end_time).toISOString(),
                          units.timezone_name
                        )
                      ).format("DD-MMM-YY hh:mm A")}
                    </td>

                    <td title={trip.endLocation}>
                      <LinesEllipsis
                        text={trip.startLocation || "N/A"}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </td>

                    <td title={trip.endLocation}>
                      <LinesEllipsis
                        text={trip.endLocation || "N/A"}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </td>
                    <td>
                      {moment(trip.end_time).diff(
                        moment(trip.start_time),
                        "minutes"
                      )}{" "}
                    </td>
                    <td>
                      {(trip.endOdometer - trip.startOdometer).toFixed(2)}
                    </td>

                    <td>
                      {resolveCurrency(units.currencyunit)}{" "}
                      {(trip.tax_savings != null ? ((trip.endOdometer - trip.startOdometer) * (units.tax_rate || 0.56)).toFixed(2) : "N/A" ) }

                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={trip.tax_savings != null}
                        onChange={(e) => {
                          if (trip.tax_savings != null) {
                            _trips[i].tax_savings = null;
                            _updateWorkRelated(_trips[i]);
                            setState({ ...state, _trips: _trips });
                          } else {
                            _trips[i].tax_savings =
                              (trip.endOdometer - trip.startOdometer) * trip.tax_rate;
                            _updateWorkRelated(_trips[i]);
                            setState({ ...state, _trips: _trips });

                        }}}
                      />
                    </td>
                  </tr>
                ) : null;
              })}
            </tbody>
          </table>

          <div className="row">
            <div className="col-lg-10"> </div>
            <div className="col-lg-2">
              <label className="mr-2">Page </label>
              <Dropdown 

              search
              selction
              value={_currentPage}

                options={function(){

                  let ops = [];

                  for(let i =1; i<= _paginations; i++) {
                    ops.push({
                      key: i,
                      value: i,
                      text: i
                    })
                  }

                  return ops
                }()}
                onChange={(e, {value}) => setState({...state, _currentPage: value})}

              />
          </div>
        </div>
          </div>
      )}
    </div>
  );
};

export default TripTaxSavings;
