import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../../config";
import resolveCurrency from "../../../helpers/Helper";
import { getCostPerMileDataService } from "../../../services/odometer-history-service";

const CostPerMileDataChart = ({ vehicle, units }) => {
  const [state, setState] = useState({
    options: {
      chart: {
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 5,
          color: "#00274c",
          opacity: 0.45,
        },
      },

      yaxis: {
        title: {
          text: `Cost Per Unit Distance (${resolveCurrency(
            units.currencyunit.toUpperCase()
          )} / ${units.distanceunit})`,
        },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#00274c"],

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2.75,
        dashArray: 0,
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
    loading: true,
  });

  const { options, series, loading } = state;

  useEffect(() => {
    getCostPerMileData();
  }, [vehicle]);

  const getCostPerMileData = async () => {
    try {
      setState({ ...state, loading: true });
      const odoChange = localStorage.getItem("odoChange");
      const costPerMileDataJson = localStorage.getItem("costPerMileData");
      if (odoChange === "false" && costPerMileDataJson) {
        const data = JSON.parse(costPerMileDataJson);

        if (vehicle.id === data.vehicleId) {
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ];

          const xaxisData = [];
          const yaxisData = [];
          data.costData.sort(function (costData1, costData2) {
            let res = 0;
            if (costData1.yyyy === costData2.yyyy) {
              if (costData1.month < costData2.month) {
                return -1;
              } else {
                return 1;
              }
            } else {
              if (costData1.yyyy < costData2.yyyy) {
                return -1;
              } else {
                return 1;
              }
            }
          });

          if (data.costData.length > 6)
            data.costData = data.costData.slice(
              Math.max(data.costData.length - 5, 1)
            );

          data.costData.forEach((dataPoint) => {
            xaxisData.push(
              `${monthNames[dataPoint.month - 1]} ${dataPoint.yyyy}`
            );
            yaxisData.push(
              dataPoint.costPerMile ? dataPoint.costPerMile.toFixed(3) : 0
            );
          });
          return setState({
            ...state,
            loading: false,
            options: {
              ...options,
              xaxis: {
                type: "Month",
                categories: xaxisData,
                title: { text: "Month" },
              },
            },
            series: [
              {
                name: "Cost Per Mile",
                data: yaxisData,
              },
            ],
          });
        }
      }
      const token = localStorage.getItem("token");

      const data = await getCostPerMileDataService(vehicle.id);

      if (!data.done) {
        return setState({ ...state, loading: false });
      }
      localStorage.setItem(
        "costPerMileData",
        JSON.stringify({
          vehicleId: vehicle.id,
          costData: data.costData,
        })
      );
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      const xaxisData = [];
      const yaxisData = [];

      data.costData.sort(function (costData1, costData2) {
        let res = 0;
        if (costData1.yyyy === costData2.yyyy) {
          if (costData1.month < costData2.month) {
            return -1;
          } else {
            return 1;
          }
        } else {
          if (costData1.yyyy < costData2.yyyy) {
            return -1;
          } else {
            return 1;
          }
        }
      });
      if (data.costData.length > 6)
        data.costData = data.costData.slice(
          Math.max(data.costData.length - 5, 1)
        );
      data.costData.forEach((dataPoint) => {
        xaxisData.push(`${monthNames[dataPoint.month - 1]} ${dataPoint.yyyy}`);
        yaxisData.push(dataPoint.costPerMile.toFixed(3));
      });
      setState({
        ...state,
        loading: false,
        options: {
          ...options,
          xaxis: {
            type: "Month",
            categories: xaxisData,
            title: { text: "Month" },
          },
        },
        series: [
          {
            name: `Cost Per Mile `,
            data: yaxisData,
          },
        ],
      });
    } catch (err) {
      console.log(err);
      setState({ ...state, loading: false });
    }
  };

  if (loading) {
    return (
      <div class="card">
        <div class="card-body">
          <h3>Cost / Distance</h3>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card">
      <div class="card-body">
        <h3
          title={`This is how much you pay for every ${
            units.distanceunit === "mi" ? "mile" : "kilometer"
          } you drive.`}
        >
          {" "}
          Cost / Distance
        </h3>
        <Chart options={options} series={series} type="line" />
      </div>
    </div>
  );
};

export default CostPerMileDataChart;
