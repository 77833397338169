import React, { useEffect, useRef, useState } from "react";
import { API } from "../../../config";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia2VlbXV0IiwiYSI6ImNqb29kMjRhMjAwZm0zcG10d2Jmb2x2bTUifQ.YUXQ359H96QrmRteACT0ZA";

const styles = {
  width: "100%",
  height: "500px",
};

const LocationHeatmap = ({ vehicle }) => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getLocationHeatmap();
    }

    return function cleanup() {
      mounted = true;
    };
  }, []);

  const getLocationHeatmap = async () => {
    try {
      if (
        vehicle.vin == "1HM888055H7DDA033" ||
        vehicle.vin == "1HM888055H7DDA034"
      ) {
        const res = await fetch(`${API}/high-mobility/location`, {
          method: "POST",
          body: JSON.stringify({ vin: vehicle.vin }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await res.json();

        let coords = [];

        data.latLon.forEach((item) => coords.push([item[1], item[0]]));
        let geoJson = {
          type: "FeatureCollection",
          features: [],
        };

        coords.forEach((coord, i) => {
          geoJson.features.push({
            type: "Feature",
            properties: { mag: Math.random() * 1 + 0 },
            geometry: {
              type: "Point",
              coordinates: coord,
            },
          });
        });

        return displayMap(coords, geoJson);
      }

      const res = await fetch(`${API}/smartcar/location-data/${vehicle.vin}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const data = await res.json();
      let coords = [];

      data.locationData.forEach((item, i) => {
        // lats are a comma separated string eg. 12.34,43.535.35.343
        const lats = item.lat.split(",");
        const longs = item.long.split(",");

        // assemble into single array
        lats.forEach((lat, j) => {
          // ignore any invalid values that may be there
          if (lat != "nan" || lat != "NaN" || lat != null) {
            coords.push([longs[j], lat]);
          }
        });
      });

      let geoJson = {
        type: "FeatureCollection",
        features: [],
      };

      coords.forEach((coord, i) => {
        geoJson.features.push({
          type: "Feature",
          properties: { mag: Math.random() * 1 + 0 },
          geometry: {
            type: "Point",
            coordinates: coord,
          },
        });
      });

      console.log(geoJson, coords.length);

      displayMap(coords, geoJson);
    } catch (err) {
      console.log(err);
    }
  };

  const displayMap = async (coords, geoJson) => {
    try {
      mapboxgl.accessToken =
        "pk.eyJ1Ijoia2VlbXV0IiwiYSI6ImNqb29kMjRhMjAwZm0zcG10d2Jmb2x2bTUifQ.YUXQ359H96QrmRteACT0ZA";

      // Create a map
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/light-v9", // stylesheet location
        center: coords[0],
        zoom: 8,
      });

      map.on("load", function () {
        // Add a geojson point source.
        // Heatmap layers also work with a vector tile source.
        map.addSource("earthquakes", {
          type: "geojson",
          data: geoJson,
        });

        map.addLayer(
          {
            id: "earthquakes-heat",
            type: "heatmap",
            source: "earthquakes",
            maxzoom: 9,
            paint: {
              // Increase the heatmap weight based on frequency and property magnitude
              "heatmap-weight": [
                "interpolate",
                ["linear"],
                ["get", "mag"],
                0,
                0,
                6,
                1,
              ],
              // Increase the heatmap color weight weight by zoom level
              // heatmap-intensity is a multiplier on top of heatmap-weight
              "heatmap-intensity": [
                "interpolate",
                ["linear"],
                ["zoom"],
                5,
                1,
                9,
                3,
              ],
              // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
              // Begin color ramp at 0-stop with a 0-transparancy color
              // to create a blur-like effect.
              "heatmap-color": [
                "interpolate",
                ["linear"],
                ["heatmap-density"],
                0,
                "rgba(33,102,172,0)",
                0.2,
                "rgb(103,169,207)",
                0.4,
                "rgb(209,229,240)",
                0.6,
                "rgb(253,219,199)",
                0.8,
                "rgb(239,138,98)",
                1,
                "rgb(178,24,43)",
              ],
              // Adjust the heatmap radius by zoom level
              "heatmap-radius": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                2,
                9,
                20,
              ],
              // Transition from heatmap to circle layer by zoom level
              "heatmap-opacity": [
                "interpolate",
                ["linear"],
                ["zoom"],
                7,
                1,
                9,
                0,
              ],
            },
          },
          "waterway-label"
        );

        map.addLayer(
          {
            id: "earthquakes-point",
            type: "circle",
            source: "earthquakes",
            minzoom: 7,
            paint: {
              // Size circle radius by earthquake magnitude and zoom level
              "circle-radius": [
                "interpolate",
                ["linear"],
                ["zoom"],
                7,
                ["interpolate", ["linear"], ["get", "mag"], 1, 1, 6, 4],
                16,
                ["interpolate", ["linear"], ["get", "mag"], 1, 5, 6, 50],
              ],
              // Color circle by earthquake magnitude
              "circle-color": [
                "interpolate",
                ["linear"],
                ["get", "mag"],
                1,
                "rgba(33,102,172,0)",
                2,
                "rgb(103,169,207)",
                3,
                "rgb(209,229,240)",
                4,
                "rgb(253,219,199)",
                5,
                "rgb(239,138,98)",
                6,
                "rgb(178,24,43)",
              ],
              "circle-stroke-color": "white",
              "circle-stroke-width": 1,
              // Transition from heatmap to circle layer by zoom level
              "circle-opacity": [
                "interpolate",
                ["linear"],
                ["zoom"],
                7,
                0,
                8,
                1,
              ],
            },
          },
          "waterway-label"
        );
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="card card-body text-dark">
      <h4>Location Heatmap</h4>

      <div className="row">
        <div className="col-lg-12 ">
          <div ref={(el) => (mapContainer.current = el)} style={styles} />
        </div>
      </div>
    </div>
  );
};

export default LocationHeatmap;
