import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import FleetProfitLoss from "./FleetProfitLoss";
import FleetNetRevenue from "./FleetNetRevenue";
import FleetRevenuePerUnit from "./FleetRevenuePerUnit";
import FleetRevenuePerDay from "./FleetRevenuePerDay";
import FleetRevenuePerContract from "./FleetRevenuePerContract";
import FleetNetExpenseCard from "./FleetNetExpenseCard";
import FleetAnalyticsTable from "./FleetAnalyticsTable";
import RevenueHistory from "./RevenueHistory";
import moment from "moment";
import { Helmet } from "react-helmet";
import FloatingButton from "../common/FloatingButton";
const FleetAnalytics = () => {
  const [state, setState] = useState({
    units: null,
    loading: true,
    lastUpdated: null,
  });

  const { units, loading, lastUpdated } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      initialRequest();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const initialRequest = async () => {
    try {
      const token = localStorage.getItem("token");
      const unitsJson = localStorage.getItem("units");
      const user = JSON.parse(localStorage.getItem("user"));
      let units;
      if (unitsJson) {
        units = JSON.parse(unitsJson);
      } else {
        const ures = await fetch(`${API}/units/${user.uid}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const jsonData = await ures.json();
        localStorage.setItem("units", JSON.stringify(jsonData.units));
        units = jsonData.units;
      }

      const res = await fetch(`${API}/revenue-reports/last-updated`, {
        method: "GET",
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const data = await res.json();

      if (data.done) {
        setState({
          ...state,
          lastUpdated: data.lastUpdated,
          units,
          loading: false,
        });
      } else {
        setState({ ...state, lastUpdated: null, units, loading: false });
      }
    } catch (err) {
      setState({
        ...state,
        lastUpdated: null,
        units: { currencyunit: "usd", fuelunit: "gal", distanceunit: "mi" },
        loading: false,
      });
    }
  };

  if (loading) {
    return (
      <>
        <div class="spinner-border text-primary" role="status"></div>
      </>
    );
  }

  return (
    <div className="text-dark">
      <Helmet>
        <title>Keemut | Turo</title>
      </Helmet>
      <h3>Turo Analytics </h3>
      <FloatingButton />
      <small className="text-muted">
        {lastUpdated
          ? `Last Updated : ${moment(lastUpdated).format("DD-MMM-YYYY")}`
          : ""}
      </small>
      <div className="mx-2 my-3">
        <div className="row">
          <div className="col-lg-4">
            <FleetNetRevenue units={units} />
          </div>
          <div className="col-lg-4">
            <FleetNetExpenseCard units={units} />
          </div>
          <div className="col-lg-4">
            <FleetProfitLoss />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <FleetRevenuePerUnit units={units} />
          </div>
          <div className="col-lg-4">
            <FleetRevenuePerDay units={units} />
          </div>
          <div className="col-lg-4">
            <FleetRevenuePerContract units={units} />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <FleetAnalyticsTable units={units} />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <RevenueHistory units={units} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FleetAnalytics;
