import React, { useEffect, useState } from "react";
import TeslaService from "../../../../helpers/tesla-helper";
// image name format
// {model}{trim}{exteriorColor}{wheels}
// replace all space with _

// const m3Tyres = ["20_Überturbine_Wheels", "18_Aero_Wheels", "19_Sport_Wheels"];

// const mxTyres = ["22_Turbine_Wheels", "20_Cyberstream_Wheels"];

// const myTyres = [
//   "21_Überturbine_Wheels",
//   "20_Induction_Wheels",
//   "19_Gemini_Wheels",
// ];

// const msTyres = ["21_Arachnid_Wheels", "19_Tempest_Wheels"];

const TeslaCarImage = ({ vehicle }) => {
  const [state, setState] = useState({
    _loading: true,
    _error: null,
    _imagePath: null,
  });
  const { _error, _imagePath, _loading } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) _getConfiguration();

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const _getConfiguration = async () => {
    try {
      setState({ ...state, _loading: true, _error: null });
      const data = await TeslaService.getConfiguration(vehicle.vin);

      let model = vehicle.model;
      let trim;
      let color = data.teslaConfig.exterior_color.toLowerCase();
      let wheels = data.teslaConfig.wheel_type.toLowerCase();

      switch (model) {
        case "Model S":
          trim = "Long_Range";
          model = "Model_S";
          if (wheels.includes("arachnid")) wheels = "21_Arachnid_Wheels";
          else wheels = "19_Tempest_Wheels";

          break;
        case "Model 3":
          trim = "Performance";
          model = "Model_3";
          if (wheels.includes("überturbine")) wheels = "20_Überturbine_Wheels";
          else if (wheels.includes("aero")) wheels = "18_Aero_Wheels";
          else wheels = "20_Überturbine_Wheels";

          break;
        case "Model X":
          trim = "Long_Range";
          model = "Model_X";
          if (wheels.includes("überturbine")) wheels = "22_Überturbine_Wheels";
          else wheels = "20_Cyberstream_Wheels";

          break;
        case "Model Y":
          trim = "Long_Range";
          model = "Model_Y";
          if (wheels.includes("überturbine")) wheels = "21_Überturbine_Wheels";
          else if (wheels.includes("induction")) wheels = "20_Induction_Wheels";
          else wheels = "19_Gemini_Wheels";

          break;

        default:
          trim = "Model 3";
          wheels = "19_Sport_Wheels";
      }

      if (color.includes("red")) color = "Red";
      else if (color.includes("white")) color = "White";
      else if (color.includes("silver")) color = "Silver";
      else if (color.includes("grey")) color = "White";
      else if (color.includes("blue")) color = "Blue";
      else if (color.includes("pearl")) color = "White";
      else if (color.includes("black")) color = "Black";
      else color = "White";
      console.log(`${model}${trim}${color}${wheels}`);
      setState({
        ...state,
        _error: null,
        _imagePath: `${model}${trim}${color}${wheels}`,
        _loading: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  try {
    return (
      <div className="card card-body" style={{ height: "100%" }}>
        {_loading && (
          <div className="spinner spinner-border center-spinner"> </div>
        )}

        {!_loading && (
          <img
            height="300px"
            src={require(`../../../../assets/tesla-images/${_imagePath}.png`)}
          />
        )}
      </div>
    );
  } catch (err) {
    console.log(err);
    return (
      <div className="card card-body" style={{ height: "100%" }}>
        <img
          height="300px"
          src={require("../../../../assets/tesla-images/Model_XLong_RangeWhite22_Turbine_Wheels.png")}
        />
      </div>
    );
  }
};

export default TeslaCarImage;
