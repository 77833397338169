import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import { getFuelEconomyService } from "../../../services/forms/fuel-form-service";

const AverageFuelEconomy = ({ vehicle, units }) => {
  const [state, setState] = useState({
    loading: false,
    economy: null,
    comparedToAvg: null,
  });

  const { loading, economy, comparedToAvg } = state;

  useEffect(() => {
    const getAvgEco = async () => {
      try {
        setState({ ...state, loading: true });

        const data = await getFuelEconomyService(
          vehicle.id,
          vehicle.make,
          vehicle.model
        );

        if (data.done && units) {
          setState({
            ...state,
            loading: false,
            economy: data.fuelEconomy.toFixed(2),
            comparedToAvg: data.comparedToAvg && data.comparedToAvg.toFixed(2),
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    let mounted = true;

    if (mounted) getAvgEco();

    return function cleanup() {
      mounted = false;
    };
  }, []);

  if (loading) {
    return (
      <div class="card  widget-flat ">
        <div class="card-body">
          <div class="float-right">
            <i class=" mdi mdi-fuel widget-icon bg-danger-lighten text-danger"></i>
          </div>
          <span>
            <strong>Average Fuel Economy</strong>{" "}
          </span>
          <br />
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card  widget-flat ">
      <div class="card-body">
        <div class="float-right">
          <i class=" mdi mdi-fuel widget-icon bg-danger-lighten text-danger"></i>
        </div>
        <span>
          <strong>Average Fuel Economy</strong>{" "}
        </span>
        <h3 class="mt-3 mb-3">
          {" "}
          {economy} {units.distanceunit + " per " + units.fuelunit}
        </h3>
        <label class="mb-0 text-muted">
          <span class={`text-${comparedToAvg > 0 ? "success" : "danger"} mr-2`}>
            <i
              class={`mdi mdi-arrow-${comparedToAvg > 0 ? "up" : "down"}-bold`}
            ></i>{" "}
            {comparedToAvg}%
          </span>
          <span class="text-nowrap">Below Average</span>
        </label>
        <p>
          <strong>
            {vehicle.make} {vehicle.model}
          </strong>{" "}
          Rated Fuel Economy
        </p>
      </div>
    </div>
  );
};

export default AverageFuelEconomy;
