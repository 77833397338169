import React, { useEffect, useState } from "react";
import TeslaService from "../../../../helpers/tesla-helper";
import moment from "moment";

const TeslaSoftwareUpdateHistory = ({ vehicle }) => {
  const [state, setState] = useState({
    _softwareUpdates: [],
    _loading: false,
    _error: null,
  });

  const { _error, _loading, _softwareUpdates } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) _getSoftwareUpdates();

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const _getSoftwareUpdates = async () => {
    try {
      setState({ ...state, _loading: true, _error: null });

      const data = await TeslaService.getSoftwareUpdateHistory(vehicle.vin);

      if (data.done) {
        setState({
          ...state,
          _loading: false,
          _error: null,
          _softwareUpdates: data.softwareUpdates,
        });
      } else throw "Error: Unable to get data";
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        _loading: false,
        _error: "Unable to fetch your software updates at this moment",
      });
    }
  };

  return (
    <div className="text-dark card card-body" style={{ height: "100%" }}>
      <h3>Tesla Software Update History</h3>

      {_error && (
        <div
          class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show mb-3"
          role="alert"
        >
          <strong>Error - </strong> {_error}
        </div>
      )}

      {_loading && (
        <div className="spinner spinner-border center-spinner"> </div>
      )}

      {!_loading && _softwareUpdates.length == 0 && (
        <p className="mt-3">
          No software update history found for your vehicle.
        </p>
      )}

      {!_loading && _softwareUpdates && _softwareUpdates.length > 0 && (
        <table className="mt-4 table-striped">
          <thead>
            <tr>
              <th>Date</th>
              <th>Version Name</th>
            </tr>
          </thead>

          <tbody>
            {_softwareUpdates.map((update, idx) => (
              <tr>
                <td>{moment(update.date).format("DD-MMM-YYYY")}</td>
                <td>{update.software_version}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TeslaSoftwareUpdateHistory;
