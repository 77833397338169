import simpleFetch from "../helpers/fetch-wrapper";

const bmwService = {
  loginWithBmw: async (username, password, vin, region) => {
    try {
      return await simpleFetch(
        "/bmw/auth",
        "POST",
        JSON.stringify({
          username,
          password,
          vin,
          region,
        })
      );
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  onboardBmwVehicles: async () => {
    try {
      return await simpleFetch("/bmw/vehicles", "POST", JSON.stringify({}));
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export default bmwService;
