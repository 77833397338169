import React, { useState } from 'react'
import { Dropdown } from 'semantic-ui-react'

export default function ConnectYourCar() {


  const [state, setState] = useState({
    make: "Tesla",
    url: "/dashboard/tesla/onboard"
  })

  return <div className="text-dark card card-body">
    <h1>Connect Your Car</h1>
    <p>Select Your Vehicle Make to connect with Keemut via the OEM's API.</p>
    <div className="mt-3">
   <div className="form-group">
   <label className="overview-dialog-label">Select Make</label>

<Dropdown 
  value={state.make}
  placeholder="Select Make"
  search selection
  fluid
  onChange={(e,{value}) => setState({...state, make: value, url: value == "Tesla" ? "/dashboard/tesla/onboard" : value == "Skoda" ? "/dashboard/skoda/onboard" : value == "Toyota" ? "/dashboard/toyota/onboard" : value == "Toyota" ? "/dashboard/toyota/onboard"  : value == "Honda" ? "/dashboard/honda/onboard" :  value == "Rivian" ? "/dashboard/rivian/onboard" : "/dashboard/tata/onboard"})}
  options={
    [
      {key: 0,
        value: "Honda",
        text:"Honda"
      },
      {key: 5,
        value: "Rivian",
        text: "Rivian"},
      {key: 2,
        value: "Skoda",
        text:"Škoda"
      },
      {key: 1,
        value: "Tesla",
        text:"Tesla"
      },
     {key: 3,
        value: "Tata",
        text:"Tata"
      },
      {key: 4,
        value: "Toyota",
        text:"Toyota"
      }
    ]
  }
/> </div>

  <a href={state.url} >
    
    <button className="btn btn-primary" style={{width: "100%"}}>Next</button>
  </a>
    </div>
  </div>
}