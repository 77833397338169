import simpleFetch from "./fetch-wrapper";

export const TeslaHelper = {
  /**
   * @description Gets the tesla status from Tesla API or mongo if the car is sleeping
   * @param {String} vin
   * @returns {Object}
   */
  getTeslaStatus: async (vin) => {
    try {
      return await simpleFetch(`/tesla/status/${vin}`, "GET");
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * @description Gets all the tesla driving reports for the vin
   * @param {String} vin
   * @returns
   */
  getAllTeslaDrivingReports: async (vin) => {
    try {
      return await simpleFetch(`/tesla/weekly-driving-report/${vin}`, "GET");
    } catch (err) {
      console.log(err);
    }
  },

  getTeslaAccountInfo: async () => {
    try {
      return await simpleFetch(`/tesla/account`, "GET");
    } catch (err) {
      console.log(err);
    }
  },

  deleteTeslaAccountInfo: async () => {
    try {
      return await simpleFetch(`/tesla/account`, "DELETE");
    } catch (err) {
      console.log(err);
    }
  },

  dataExportRequest: async (vin) => {
    try {
      return await simpleFetch(`/tesla/gdpr`, "POST", JSON.stringify({ vin }));
    } catch (err) {
      console.log(err);
    }
  },

  getSoftwareUpdateHistory: async (vin) => {
    try {
      return await simpleFetch(`/tesla/software-updates/${vin}`, "GET");
      // *  response
      // {
      //   done: true,
      //   softwareUpdates: [
      //     {
      //       id: "1vdlspc9l4kpqmsw3a",
      //       vin: "5YJXCAE23JF140570",
      //       software_version: "2021.4.18 b9447274f1b4",
      //       date: "2021-05-18T09:01:00.000Z",
      //       epoch_time: "1623313260406",
      //     },
      //   ],
      // };
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * @description get grouped count for the current software versions across the entier keemut fleet
   * @returns
   */
  getCommunitySoftwareVersionCount: async () => {
    try {
      return await simpleFetch(
        "/tesla/community/software-updates/count",
        "GET"
      );

      // * Response
      // {
      //   done: true,
      //   softwareUpdates: [
      //     { count: "20", software_version: "2021.4.16 6f392f68bc99" },
      //     { count: "33", software_version: "2021.4.11 8af6a4f84c84" },
      //     { count: "2", software_version: "2021.4.12.2 810872f8809b" },
      //   ],
      // }
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * @description Get trips for the vin
   * @param {String} vin
   * @param {String} startTime
   * @param {String} endTime
   * @returns
   */
  getTripsV2: async (vin, startTime, endTime) => {
    try {
      return await simpleFetch(
        "/tesla/trips-v2",
        "POST",
        JSON.stringify({
          vin,
          startTime,
          endTime,
        })
      );

      // * Response

      // {
      //   done: true,
      //   trips: [
      //     {
      //       _id: "608d0a8c7cac37052700e1ed",
      //       vin: "5YJXCAE23JF140570",
      //       speed: [33, 61],
      //       shiftState: ["D", "D"],
      //       timestamp: null,
      //       latLon: [
      //         [-122.106166, 47.676072],
      //         [-122.14398, 47.634039],
      //       ],
      //       odometer: null,
      //       rawTimestamp: null,
      //       battery_level: [63, 60],
      //       battery_range: null,
      //       avgIntTemp: 22.153846153846153,
      //       avgExtTemp: 12.46153846153846,
      //       avgSpeed: 31.692307692307693,
      //       counter: 13,
      //       startOdometer: 24855.727516,
      //       endOdometer: 24872.133935,
      //       end_time: "2021-05-01T06:34:00.000Z",
      //       start_time: "2021-05-01T05:49:00.000Z",
      //       start_blevel: 63,
      //       end_blevel: 56,
      //       start_brange: 174.52,
      //       end_brange: 154.78,
      //       start_raw_epoch: 1619848140,
      //       end_raw_epoch: 1619850840,
      //       work_related: null,
      //       tax_savings: 9.18759464000148,
      //       tax_rate: 0.56,
      //     },
      //   ],
      // }
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * @description Get configuration of tesla
   * @param {String} vin
   * @returns
   */
  getConfiguration: async (vin) => {
    try {
      return await simpleFetch(`/tesla/config/${vin}`, "GET");

      // {
      //   done: true,
      //   teslaConfig: {
      //     vin: "5YJXCAE23JF140570",
      //     can_accept_navigation_requests: null,
      //     can_actuate_trunks: true,
      //     charge_port_type: "US",
      //     exterior_color: "SteelGrey",
      //     has_air_suspension: true,
      //     has_ludicrous_mode: false,
      //     motorized_charge_port: true,
      //     perf_config: null,
      //     plg: true,
      //     rear_seat_heaters: 3,
      //     rhd: false,
      //     roof_color: "None",
      //     seat_type: 0,
      //     spoiler_type: null,
      //     sun_roof_installed: false,
      //     third_row_seats: "FuturisFoldFlat",
      //     timestamp: "+052581-04-21T22:26:07.000Z",
      //     wheel_type: "Slipstream20Carbon",
      //     trims:
      //       "AD15,MDL3,PBSB,RENA,BT37,ID3W,RF3G,S3PB,DRLH,DV2W,W39B,APF0,COUS,BC3B,CH07,PC30,FC3P,FG31,GLFR,HL31,HM31,IL31,LTPB,MR31,FM3B,RS3H,SA3P,STCP,SC04,SU3C,T3CA,TW00,TM00,UT3P,WR00,AU3P,APH3,AF00,ZCST,MI00,CDM0",
      //     id_s: "31775710220058824",
      //     is_eu: false,
      //     latitude: "47.514343",
      //     longitude: "-122.177949",
      //   },
      // }
    } catch (err) {
      console.log(err);
    }
  },

  /**
   *
   * @param {String} vin
   * @param {String} startTime
   * @param {String} endTime
   * @returns
   */
  getTaxReport: async (vin, startTime, endTime) => {
    try {
      return await simpleFetch(
        `/tesla/tax-savings`,
        "POST",
        JSON.stringify({ vin, startTime, endTime })
      );
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * @description Get the data for temp vs efficiency
   * @param {String} vin
   * @returns
   */
  getTemperatureVsEfficiency: async (vin) => {
    try {
      return await simpleFetch(
        `/tesla/temperature-vs-efficiency/${vin}`,
        "GET"
      );

      // * Response
      // {
      //   done: true,
      //   result: [
      //     {
      //       temperature_bin: "-10,-5",
      //       efficiency: "89.3211111111111111",
      //       id: -10,
      //       lower_limit: -10,
      //       upper_limit: -5,
      //     },
      //     {
      //       temperature_bin: "5,10",
      //       efficiency: "86.3231060606060606",
      //       id: 5,
      //       lower_limit: 5,
      //       upper_limit: 10,
      //     },
      //   ],
      // }
    } catch (err) {
      console.log(err);
    }
  },

  doesExist: async () => {
    try {
      return await simpleFetch(`/tesla/exists`, "GET");

      // * Response
      //  {done:true,exists:"true"}
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * @description Get wh mi vs odometer
   * @param {String} vin
   * @returns
   */
  getWhMiVsOdometer: async (vin) => {
    try {
      return await simpleFetch(
        "/tesla/watt-per-mile-vs-odometer",
        "POST",
        JSON.stringify({ vin })
      );
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * @description Get wh mi vs odometer
   * @param {String} vin
   * @returns
   */
  getWhMiVsTemperature: async (vin) => {
    try {
      return await simpleFetch(
        "/tesla/watt-per-mile-vs-temperature",
        "POST",
        JSON.stringify({ vin })
      );
    } catch (err) {
      console.log(err);
    }
  },
};

export default TeslaHelper;
