import React, { useEffect, useState } from "react";
import { API, site } from "../../../config";

const SmartcarOauth = (props) => {
  const [state, setState] = useState({
    done: false,
    loading: true,
  });
  const { loading, done } = state;

  useEffect(() => {
    try {
      const generateToken = async () => {
        const query = new URLSearchParams(window.location.search);
        const smartcarCode = query.get("code");

        const res = await fetch(`${API}/smartcar/token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ smartcarCode }),
        });
        const data = await res.json();
        const vres = await fetch(`${API}/smartcar/vehicles`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({}),
        });

        setState({
          loading: false,
          done: data.done,
        });

        setTimeout(() => {
          window.location = `${site}/dashboard/vehicles`;
        }, 1500);
      };

      generateToken();
    } catch (err) {}
  }, []);

  if (loading)
    return (
      <div className="contiainer text-center">
        <div className="spinner-border center-spinner" role="status"></div>
      </div>
    );

  return (
    <div className="contiainer text-center">
      <h3>
        {done
          ? "Your Car has been connected with Keemut"
          : "Connection Failed, please try again later!"}
      </h3>
    </div>
  );
};

export default SmartcarOauth;
