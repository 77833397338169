import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import simpleFetch from "../../../../helpers/fetch-wrapper";
import TeslaHelper from "../../../../helpers/tesla-helper";
import VehicleMMTYForm from "../../../common/VehicleMMTYForm";

const DrivingReport = ({ vehicle, units }) => {
  const [state, setState] = useState({
    _weeklyDrivingReports: [],
    _selectedReport: [],
    _loading: null,
    _comparisonLoading: false,
    _comparisionData: null,

    _comparisonMake: "BMW",
    _comparisonModel: "M5",
    _comparisonYear: 2020,

    _showEnv: false,
  });
  const {
    _weeklyDrivingReports,
    _loading,
    _selectedReport,
    _comparisonLoading,
    _comparisionData,
    _showEnv,

    _comparisonMake,
    _comparisonModel,
    _comparisonYear,
  } = state;

  useEffect(() => {
    console.log(".");
    _getDrivingReports();
  }, [vehicle]);

  const _getDrivingReports = async () => {
    try {
      setState({ ...state, loading: true });
      const data = await TeslaHelper.getAllTeslaDrivingReports(vehicle.vin);

      if (!data.done) {
        // handle error
        return;
      }

      let weeklyReports = [];
      let dates = {}
     

      for(let i = 0; i < data.teslaDrivingReports.length; i++) {
        let report = data.teslaDrivingReports[i]
        const startTime = moment(report.start_date).format("DD-MMM-YYYY");
        const endTime = moment(report.end_date).format("DD-MMM-YYYY");
        
        if(!dates[`${startTime} to ${endTime}`]){
          weeklyReports.push({
            key: i,
            value: report,
            text: `${startTime} to ${endTime}`,
          });
          dates[`${startTime} to ${endTime}`] = 1;
        } 
        
      }

      setState({
        ...state,
        _loading: false,
        _weeklyDrivingReports: weeklyReports,
        _selectedReport: weeklyReports[0].value,
      });
    } catch (error) {
      console.log(error);
      setState({
        ...state,
        _loading: false,
      });
    }
  };

  const _computeEmissionForComparisonCar = async () => {
    try {
      setState({ ...state, _comparisonLoading: true });
      const data = await simpleFetch(
        "/emission",
        "POST",
        JSON.stringify({
          make: _comparisonMake,
          year: _comparisonYear,
          model: _comparisonModel,
          milesDriven:
            units.distanceunit == "mi"
              ? _selectedReport.distance_driven
              : _selectedReport.distance_driven / 1.6,
        })
      );
      setState({
        ...state,
        _showEnv: true,
        _comparisonLoading: false,
        _comparisionData: data.emissionData,
      });
    } catch (err) {
      console.log(err);
    }
  };

  if (vehicle.make != "Tesla")
    return (
      <div className="card card-body">
        <h4 className="text-danger">
          Driving Report is only available for Tesla Vehicles.
        </h4>
      </div>
    );

  if (_loading)
    return <div className="spinner-border text-primary" role="status"></div>;

  if (_weeklyDrivingReports.length == 0)
    return (
      <div className="card card-body">
        <h4 className="text-danger">
          No driving reports present. Please check back later.
        </h4>
      </div>
    );

  return (
    <div className="card card-body" style={{ fontWeight: "Roboto" }}>
      <h1>Weekly Driving Report</h1>
      {_loading && (
        <div
          className="spinner spinner-border center-spinner mt-2"
          role="state"
        >
          {" "}
        </div>
      )}
      {!_loading && (
        <>
          <p className="mt-1">Select a week to view the summary</p>
          <div className="row mt-1">
            <div className="col-lg-5">
              <Dropdown
                options={_weeklyDrivingReports}
                search
                selection
                value={_selectedReport}
                onChange={(e, { value }) => {
                  setState({ ...state, _selectedReport: value });
                }}
              />
            </div>
          </div>

          <hr />
          <h2>Driving Stats</h2>

          <div className="row mt-4">
            <div className="col-lg-4">
              <div class="card widget-flat">
                <div class="card-body">
                  <div class="float-right">
                    <i class=" mdi mdi-speedometer-medium widget-icon bg-warning-lighten text-warning"></i>
                  </div>
                  <h3>Distance Driven</h3>
                  <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
                    <span className="h3">
                      {_selectedReport.distance_driven &&
                        parseInt(_selectedReport.distance_driven)}
                    </span>{" "}
                    {units.distanceunit}
                  </p>

                  <p className="text-muted">
                    This is the amount of distance driven in miles.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div class="card widget-flat">
                <div class="card-body">
                  <div class="float-right">
                    <i class="mdi mdi-battery-charging-medium widget-icon bg-primary-lighten text-primary"></i>
                  </div>
                  <h3>Battery Energy Used</h3>
                  <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
                    <span className="h3">
                      {_selectedReport &&
                        parseInt(_selectedReport.battery_used)}
                    </span>{" "}
                    kWh
                  </p>

                  <p className="text-muted">
                    This is the amount of energy consumed.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div class="card widget-flat">
                <div class="card-body">
                  <div class="float-right">
                    <i class="  mdi mdi-timer-sand widget-icon bg-success-lighten text-success"></i>
                  </div>
                  <h3>Driving Time</h3>
                  <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
                    <span className="h3">
                      {_selectedReport &&
                        parseFloat(
                          _selectedReport.total_drive_time_hrs
                        ).toFixed(2)}
                    </span>{" "}
                    hours
                  </p>
                  <p className="text-muted">
                    This the duration the vehicle was in a drive state.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <h2>Performance Stats</h2>

          <div className="row mt-4">
            <div className="col-lg-4">
              <div class="card widget-flat">
                <div class="card-body">
                  <div class="float-right">
                    <i class=" mdi mdi-graph-outline widget-icon bg-warning-lighten text-warning"></i>
                  </div>
                  <h3>Vehicle Efficiency</h3>
                  <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
                    <span className="h3">
                      {_selectedReport.efficiency &&
                        parseInt(_selectedReport.efficiency)}
                    </span>{" "}
                    %
                  </p>

                  <p className="text-muted">
                    This is a measure of your vehicle's efficiency based on the
                    Tesla API data.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div class="card widget-flat">
                <div class="card-body">
                  <div class="float-right">
                    <i class=" mdi mdi-taxi widget-icon bg-primary-lighten text-primary"></i>
                  </div>
                  <h3>
                    Watt-Hours Per {units.distanceunit == "mi" ? "Mi" : "Km"}{" "}
                  </h3>
                  <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
                    <span className="h3">
                      {_selectedReport.watt_per_mi &&
                        parseInt(_selectedReport.watt_per_mi)}
                    </span>{" "}
                    Wh/{units.distanceunit}
                  </p>

                  <p className="text-muted">
                    This is an estimated Wh/{units.distanceunit} for your
                    vehicle based on your driving over the selected week.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div class="card widget-flat">
                <div class="card-body">
                  <div class="float-right">
                    <i class=" mdi mdi-account-settings-outline widget-icon bg-success-lighten text-success"></i>
                  </div>
                  <h3>Driver Score (beta) </h3>
                  <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
                    <span className="h3">
                      {_selectedReport.driving_score &&
                        parseInt(_selectedReport.driving_score)}
                    </span>{" "}
                    / 100
                  </p>
                  <p className="text-muted">
                    This an estimated Driver score based on your driving
                    behavior.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <h2>Emission Stats</h2>

          <p className="mt-1">
            You can use the dropdown below to select a vehicle to compare your
            vehicle with.
          </p>

          <p className="mt-1">
            NOTE: This has no effect on the weekly newsletter.
          </p>
          <VehicleMMTYForm
            onMakeChange={(value) => {
              setState({ ...state, _comparisonMake: value });
            }}
            onModelChange={(value) => {
              setState({ ...state, _comparisonModel: value });
            }}
            onYearChange={(value) => {
              setState({ ...state, _comparisonYear: value });
            }}
            year={_comparisonYear}
            make={_comparisonMake}
            model={_comparisonModel}
          />

          <button
            onClick={_computeEmissionForComparisonCar}
            className="btn btn-primary"
          >
            {_comparisonLoading ? "Computing..." : "Re-Compute"}
          </button>

          {_showEnv && (
            <>
              {" "}
              <h4 className="mt-4">
                <span className="text-ev">
                  {vehicle.make} {vehicle.model} {vehicle.year}
                </span>{" "}
                vs{" "}
                <span className="text-ice">
                  {_comparisonMake} {_comparisonModel} {_comparisonYear}
                </span>
              </h4>
              <div className="row mt-1">
                <div className="col-lg-4">
                  <div class="card widget-flat">
                    <div class="card-body">
                      <div class="float-right">
                        <i class=" mdi mdi-gas-station-outline widget-icon bg-warning-lighten text-warning"></i>
                      </div>
                      <h3>{units.distanceunit == "mi" ? "MPG" : "KMPL"}e</h3>
                      <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
                        <span className="text-ev h3">
                          {_selectedReport.watt_per_mi &&
                            parseInt(
                              (units.distanceunit == "mi"
                                ? _selectedReport.watt_per_mi
                                : _selectedReport.watt_per_mi * 1.6) *
                                0.337 *
                                (units.distanceunit == "mi" ? 1 : 0.425)
                            )}
                        </span>{" "}
                        <span className="text-ev">
                          {units.distanceunit == "mi" ? "MPG" : "KMPL"}e
                        </span>{" "}
                        vs{" "}
                        <span className="text-ice h3">
                          {_comparisionData &&
                            parseInt(
                              _comparisionData.mpg *
                                (units.distanceunit == "mi" ? 1 : 0.425)
                            )}
                        </span>{" "}
                        <span className="text-ice">
                          {units.distanceunit == "mi" ? "MPG" : "KMPL"}
                        </span>
                      </p>

                      <p className="text-muted">
                        This is a measure of how efficient was your vehicle's
                        performance.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div class="card widget-flat">
                    <div class="card-body">
                      <div class="float-right">
                        <i class=" mdi mdi-factory widget-icon bg-primary-lighten text-primary"></i>
                      </div>
                      <h3>
                        Est. CO<sub>2</sub> Produced
                      </h3>
                      <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
                        <span className="text-ev h3">
                          {_selectedReport.total_kgco2_produced &&
                            parseInt(_selectedReport.total_kgco2_produced)}
                        </span>{" "}
                        <span className="text-ev">kg</span> vs{" "}
                        <span className="text-ice h3">
                          {_comparisionData &&
                            parseInt(_comparisionData.co2Produced)}
                        </span>{" "}
                        <span className="text-ice">kg</span>
                      </p>

                      <p className="text-muted">
                        This is the estimated amount of CO<sub>2</sub> produced
                        based on your driving.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div class="card widget-flat">
                    <div class="card-body">
                      <div class="float-right">
                        <i class=" mdi mdi-pine-tree widget-icon bg-success-lighten text-success"></i>
                      </div>
                      <h3>Trees Required Every Year </h3>
                      <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
                        <span className="text-ev h3">
                          {_selectedReport.number_of_trees_consumed &&
                            parseInt(
                              _selectedReport.number_of_trees_consumed * 52
                            )}
                        </span>{" "}
                        <span className="text-ev">Trees</span> vs{" "}
                        <span className="text-ice h3">
                          {_comparisionData &&
                            parseInt(_comparisionData.treesConsumed * 52)}
                        </span>{" "}
                        <span className="text-ice">Trees</span>
                      </p>
                      <p className="text-muted">
                        This is the number of trees you should plant every year
                        to remain carbon neutral.
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DrivingReport;
