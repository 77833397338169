import React, { useState, useEffect } from "react";
import { API } from "../../../../config";
import moment from "moment";
import resolveCurrency from "../../../../helpers/Helper";
import SubscriptionDialog from "../../../common/SubscriptionDialog";
import {
  convertTimeToTimezone,
  convertEpochToUtcDate,
} from "../../../../helpers/date-time-helper";
import { Dropdown } from 'semantic-ui-react'

const TataChargeHistory = ({ vehicle, units }) => {
  const [state, setState] = useState({
    charges: [],

    showUpdate: false,
    charge: null,
    loading: false,
    pricePerUnit: null,
    cost: null,
    vendor: null,
    chargers: [],
    tableLoading: true,
    paginations: 0,
    currentPage: 1,
    timeRange: "7days",
    showSubscriptionMessage: false,
    isSubscribed: true,
  });

  const {
    charges,
    tableLoading,
    loading,
    charge,
    showUpdate,
    pricePerUnit,
    cost,
    vendor,
    chargers,
    currentPage,
    paginations,
    timeRange,
    isSubscribed,
    showSubscriptionMessage,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getChargingInfo();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [timeRange]);

  const getChargingInfo = async () => {
    try {
      setState({
        ...state,
        tableLoading: true,
      });
      const res = await fetch(
        `${API}/tata/charging-history/${vehicle.vin}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await res.json();

        // console.log(data)
      setState({
        ...state,
        loading: false,
        tableLoading: false,
        charges: data.result,
        paginations: Math.ceil(data.result.length / 10),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateCharge = async (chargeId, isHome, vin) => {
    try {
      const res = await fetch(`${API}/tata/charging-history`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          chargeId: chargeId,
          isHome,
          vin,
        }),
      });
    
    } catch (err) {}
  };

  const renderPagination = () => {
    let content = [];
    for (let i = 1; i <= paginations; i++) {
      content.push(
        <a
          onClick={() => setState({ ...state, currentPage: i })}
          class="page-link"
          style={
            currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
      );
    }
    return <> {content} </>;
  };

  // const getOcmLocation = async (lat, lng) => {
  //   try {
  //     let chargers = [];
  //     console.log("HERFE");
  //     const res = await fetch(
  //       `https://api.openchargemap.io/v3/poi/key=1397042b-3f69-4cbc-8acc-c64390cb1313/?latitude=${lat}&longitude=${lng}&distance=1`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "User-Agent": "keemut",
  //         },
  //       }
  //     );
  //     const data = await res.json();
  //     console.log(data);
  //     data.forEach((e) =>
  //       chargers.push({
  //         key: e.OperatorInfo ? e.OperatorInfo.Title : "",
  //         text: e.OperatorInfo ? e.OperatorInfo.Title : "",
  //         value: e.OperatorInfo ? e.OperatorInfo.Title : "",
  //       })
  //     );

  //     setState({ ...state, chargers  });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <div className="card card-body" style={{ height: "100%" }}>
      <div className="row mb-4">
        <div className="col-lg-6">
          <h3> Charging History</h3>
        </div>
       
      </div>

      {tableLoading ? (
        <div className="spinner-border spinner-center" role="status">
          {" "}
        </div>
      ) : (
        <table className="table-striped">
          <thead>
            <tr>
              <th>Start Time </th>
              <th>End Time </th>

              {/* <th>Vendor </th> */}
              <th>Cost </th>
              <th>Energy Added (kW)</th>

              <th>
                Range Added (km)
              </th>

              <th>
                Charge Rate (kW/h)
              </th>

              <th>
                Location
              </th>

              <th>
                Use Residential Rate
              </th>
            </tr>
          </thead>

          <tbody>
            {charges.map((e, i) =>
              i < currentPage * 10 && i >= (currentPage - 1) * 10 ? (
                <tr key={e.id}>
                  <td>
                    {e.start_time}
                  </td>

                  <td>
                    {e.end_time}
                  </td>

                  {/* <td>{e.vendor == null ? "N/A" : e.vendor}</td> */}
                  <td>
                  ₹ {e.is_home ? parseInt(e.energy_added * units.residential_energy_rate) : parseInt(e.energy_added * units.commercial_energy_rate)}
                  </td>
                  <td>
                 {parseInt(e.energy_added || 0)} ({parseInt(e.start_battery_level)}% to {parseInt(e.end_battery_level)}%)
                  </td>
                  <td>{parseInt(e.miles_added * 1.609 )}</td>
                  <td>{parseFloat(e.charge_rate_kw).toFixed(2)}</td>
                  <td>
                    <a style={{color: "blue"}} target="_blank"  href={`https://www.google.com/maps/search/?api=1&query=${e.latitude},${e.longitude}`}> View In Maps</a>
                  </td>
                  <td>
                  <input
                        type="checkbox"
                        checked={e.is_home}
                        onChange={(event) => {
                          let newCharges = charges;
                          let charge = e;
                          charge.is_home = !charge.is_home;
                          newCharges[i] = charge;
                          setState({...state, charges: newCharges});

                          updateCharge(e.id, charge.is_home, vehicle.vin)
                        }}
                      />
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      )}
        <div className="row">
            <div className="col-lg-10"> </div>
            <div className="col-lg-2">
              <label className="mr-2">Page </label>
              <Dropdown 

              search
              selction
              value={currentPage}

                options={function(){

                  let ops = [];

                  for(let i =1; i<= paginations; i++) {
                    ops.push({
                      key: i,
                      value: i,
                      text: i
                    })
                  }

                  return ops
                }()}
                onChange={(e, {value}) => setState({...state, currentPage: value})}

              />
          </div>
          </div>

      <br />
      <SubscriptionDialog
        onClose={() => setState({ ...state, showSubscriptionMessage: false })}
        showSubscriptionMessage={showSubscriptionMessage}
      />
    </div>
  );
};

export default TataChargeHistory;
