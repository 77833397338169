import simpleFetch from "../../helpers/fetch-wrapper";
import moment from "moment";

export async function createInsuranceFormService(formData, vehicleId) {
  try {
    return await simpleFetch(`/insurance/${vehicleId}`, "POST", formData, {});
  } catch (err) {
    console.log(err);
  }
}

export async function getAllInsuranceFormService(vehicleId) {
  try {
    return await simpleFetch(`/insurance-reports/${vehicleId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

export async function editInsuranceFormService(reportId, formData, vehicleId) {
  try {
    return await simpleFetch(
      `/expense-reports/${vehicleId}/${reportId}`,
      "PUT",
      formData,
      {}
    );
  } catch (err) {
    console.log(err);
  }
}

export async function deleteInsuranceFormService(reportId, vehicleId) {
  try {
    return await simpleFetch(
      `/expense-reports/${vehicleId}/${reportId}`,
      "DELETE"
    );
  } catch (err) {
    console.log(err);
  }
}
