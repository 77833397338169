import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import Dialog from "@material-ui/core/Dialog";
import { Redirect } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import resolveCurrency from "../../../helpers/Helper";
import NumberFormat from "../../../helpers/number-format-helper";
import FloatingButton from "../common/FloatingButton";
import { getCountriesService } from "../../../services/gegraphic-service";
import {
  getMakesService,
  getModelsService,
  getTrimsService,
  getDrivetrainByMMTYService,
} from "../../../services/vehicle-list-service";
import {
  createVehicleService,
  getVehiclesService,
} from "../../../services/vehicles-service";
import { createCrowdsourceVehicleService } from "../../../services/crowdsource-service";
import CompatibleVehicles from "../../../helpers/smartcar-supported-vehicles";

const AddVehicle = ({ updateDropdownOptions, updateVehicleDropdownValues }) => {
  const [state, setState] = useState({
    _vehicleName: "", // vehicle nickname
    _year: new Date().getFullYear(), // model year
    _make: null, // make of the vehicle
    _trim: null, // trim of the vehicle
    _model: null, // model of the vehicle
    _filteredCars: [],
    _vehicleType: "car", // type of vehicle bike or car NOTE ! bike not yet supported
    _purchasePrice: null, // purchase price
    _odometer: 0,
    _vin: null,
    _token: "", // auth token
    _user: null,
    _type: "Sedan", // body type
    _success: false, // did the add vehicle operation succeed
    _saving: false, // show spuinner if saving
    _loading: true, // loading --> show spinner ?
    _successDialogLoading: false,
    _filteredModels: [],
    _redirect: false, // redirect on success
    _drivetrain: "AWD", // drivetrain of vehciel
    _makes: [], // list of makes
    _error: null,
    _showError: false, // show error
    _plateNo: null, // license plate of vehicle
    _makeOptions: [], // make options
    _modelOptions: [], // model options
    _trimOptions: [], // trim options
    _purchaseOption: "purchase", // how was the vehicle purchased, lease or loan or purchase
    _duration: "48", // duration of the loan / lease
    _downpayment: null, // downpayment amount
    _monthlyPayment: null, // monthly payment
    _drivingLimit: null, // is there any driving limit on the vehicle
    _units: {}, // user's units
    _workRelated: false, // if hte vehicle used for work
    _countryOptions: [], // country options
    _country: null, // vehicle country
    _customMake: null, // crowdsourced value
    _customModel: null, // crowd sourced value
    _customTrim: null, // crowd sourced value
    _customFuelTankSize: null, // crowd sourced tank size
    _customMsrp: null, // crowd sourced msrp value
    _countryInputValue: null, // value of the country dropdown -> it is a comma delimited string
    _currencyUnit: "USD",
    _fuelUnit: "gal",
    _distanceUnit: "mi",
    _customMpg: null, // crowd sourced mpg value
    _showTeslaConnectionDialog: false,
    _drivetrainOptions: [],
  });

  const {
    _drivetrainOptions,
    _showTeslaConnectionDialog,
    _customMpg,
    _distanceUnit,
    _currencyUnit,
    _fuelUnit,
    _countryInputValue,
    _duration,
    _downpayment,
    _monthlyPayment,
    _drivingLimit,
    _vehicleName,
    _vehicleType,
    _year,
    _make,
    _model,
    _filteredCars,
    _trim,
    _purchasePrice,
    _drivetrain,
    _odometer,
    _vin,
    _saving,
    _showError,
    _success,
    _loading,
    _successDialogLoading,
    _redirect,
    _error,
    _plateNo,
    _modelOptions,
    _makeOptions,
    _trimOptions,
    _purchaseOption,
    _units,
    _workRelated,
    _country,
    _countryOptions,
    _customMake,
    _customModel,
    _customMsrp,
    _customFuelTankSize,
    _customTrim,
  } = state;

  useEffect(() => {
    _getCountries();
  }, []);

  // * INITIAL STEP : FETCH LIST OF COUNTRIES
  const _getCountries = async () => {
    try {
      const data = await getCountriesService();

      let countryOptions = [];

      // assemble the country options
      data.countries.forEach((m) => {
        countryOptions.push({
          key: m.country_code,
          value: `${m.country_code},${m.unit_system},${m.currency_code}`,
          text: m.country,
        });
      });
      setState({
        ...state,
        _loading: false,
        _countryOptions: countryOptions,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // * Get Make Options based on country
  const _getMakeList = async (countryValue) => {
    try {
      // countryValue is a comma delimited string of format -> countryCode,unitsystem,currency eg. IND,metric,INR
      const splitArr = countryValue.split(",");
      const country = splitArr[0];
      const data = await getMakesService(country);

      let makeOptions = [];
      data.makeList.forEach((m) => {
        if (m.make !== "All")
          makeOptions.push({ key: m.make, value: m.make, text: m.make });
      });

      // add an other option
      makeOptions.push({
        key: "other",
        text: "Other - Add your own Make",
        value: "other",
      });

      setState({
        ...state,
        _loading: false,
        _makeOptions: makeOptions,
        _country: country,
        _makes: data.makeList,
        _token: localStorage.getItem("token"),
        _user: localStorage.getItem("user"),
        _countryInputValue: countryValue,
        _currencyUnit: splitArr[2],
        _fuelUnit: splitArr[1] == "metric" ? "ltr" : "gal",
        _distanceUnit: splitArr[1] == "metric" ? "km" : "mi",
        _units: JSON.parse(localStorage.getItem("units")),
      });
    } catch (err) {
      console.log(err);
    }
  };

  // * Get the model list by make, country and year
  const _getModelList = async (make) => {
    try {
      const data = await getModelsService(make, _year, _country);

      let modelOptions = [];
      data.modelList.forEach((m) => {
        modelOptions.push({ key: m.model, value: m.model, text: m.model });
      });
      modelOptions.push({
        key: "other",
        text: "Other - Add your own Model",
        value: "other",
      });
      setState({
        ...state,
        _loading: false,
        _make: make,
        _modelOptions: modelOptions,
        _model: make == "other" ? "other" : _model,
        _trim: make == "other" ? "other" : _trim,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // * Get Trim options by make model year coutnry
  const _getTrimList = async (model) => {
    try {
      const data = await getTrimsService(_make, model, _year, _country);

      let _trimOptions = [];
      data.trimList.forEach((m) => {
        _trimOptions.push({ key: m.trim, value: m.trim, text: m.trim });
      });
      _trimOptions.push({
        key: "other",
        text: "Other - Add your own Trim",
        value: "other",
      });

      let scSupported = false;
      CompatibleVehicles.forEach((car) => {
        if (car.Model.toLowerCase() == model.toLowerCase()) {
          if (car.minYear && parseInt(car.minYear) <= _year) {
            if (car.maxYear) {
              if (parseInt(car.maxYear) >= _year) scSupported = true;
            } else {
              scSupported = true;
            }
          }
        }
      });

      setState({
        ...state,
        _loading: false,
        _showTeslaConnectionDialog: scSupported,
        _trimOptions,
        _model: model,
        _trim: model == "other" ? "other" : _trim,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const _getDrivetrainOptions = async (trim) => {
    try {
      const data = await getDrivetrainByMMTYService(
        _make,
        _model,
        trim,
        _year,
        _country
      );

      let drivetrainOps = [];
      data.drivetrains.forEach((m) => {
        drivetrainOps.push({
          key: m.drivetrain,
          value: m.drivetrain,
          text: m.drivetrain,
        });
      });
      drivetrainOps.push({
        key: "other",
        text: "Other - Add your own Drivetrain",
        value: "other",
      });

      setState({
        ...state,
        _loading: false,
        _trim: trim,
        _drivetrainOptions: drivetrainOps,
        _drivetrain: drivetrainOps[0].value,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // * Inititiates smartcar oauth
  const _initiateSmartcarAuth = async () => {
    try {
      const res = await fetch(`${API}/smartcar/initiate-oauth`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const data = await res.json();

      if (data.done) window.location = data.authUrl;
    } catch (err) {}
  };

  // * Handle change, and set the sate of the target's name
  const _handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  // * evaluates form constraints
  const _isFormValid = (e) => {
    if (
      !_vehicleName ||
      !_year ||
      !_make ||
      !_model ||
      !_trim ||
      !_drivetrain
    ) {
      setState({
        ...state,
        _error: "Please Fillout All the fields",
        _showError: true,
      });
      return false;
    } else if (_purchasePrice > 99999999) {
      setState({
        ...state,
        _error:
          "Invalid Purchase Price. Purchase Price must be less than 9 digits",
        _showError: true,
      });
      return false;
    } else if (_vin && _vin.length !== 17) {
      setState({
        ...state,
        _error: "Invalid VIN. VIN must be 17 characters long",
        _showError: true,
      });
      return false;
    } else {
      return true;
    }
  };

  // * Adds a vehicle
  const _addVehicle = async (e) => {
    try {
      e.preventDefault();
      setState({ ...state, _saving: true });

      // is the form valid
      if (_isFormValid()) {
        setState({ ...state, _loading: true, _error: null });
        let category = "Sedan";
        _filteredCars.forEach((car) => {
          if (
            car.make === _make &&
            car.model === _model &&
            car.trim === _trim
          ) {
            category = car.category;
          }
        });

        if (_model == "other" || _make == "other" || _trim == "other") {
          const crowdsourceData = await createCrowdsourceVehicleService(
            _make == "other" ? _customMake : _make,
            _model == "other" ? _customModel : _model,
            _trim == "other" ? _customTrim : _trim,
            _year,
            _customMsrp.replaceAll(",", ""),
            _customFuelTankSize || 20,
            _drivetrain || "AWD",
            _units.country_code,
            _customMpg
          );
        }

        const data = await createVehicleService(
          _vehicleName,
          _model == "other" ? _customModel : _model,

          _make == "other" ? _customMake : _make,
          _trim == "other" ? _customTrim : _trim,

          _drivetrain,
          _odometer,
          category,
          _vehicleType,
          (_purchasePrice && _purchasePrice.replaceAll(",", "")) ||
            parseFloat(_monthlyPayment && _monthlyPayment.replaceAll(",", "")) *
              _duration +
              parseFloat(_downpayment && _downpayment.replaceAll(",", "")),
          _year,
          _vin,
          _plateNo,
          _duration,
          _monthlyPayment && _monthlyPayment.replaceAll(",", ""),
          _downpayment && _downpayment.replaceAll(",", ""),
          _purchaseOption,
          _drivingLimit && _drivingLimit.replaceAll(",", ""),
          _make == "other" || _model == "other" || _trim == "other"
        );
        setState({ ...state, _saving: false, _success: true });
      }
    } catch (err) {
      console.log(err);
      setState({ ...state, _saving: false });
    }
  };

  // * closes the dialog and refreshes the vehicles dropdown
  const _handleClose = async () => {
    try {
      setState({ ...state, _successDialogLoading: true });

      const data = await getVehiclesService();
      const vehicles = data.vehicles;

      const { options } = updateDropdownOptions(vehicles);
      updateVehicleDropdownValues(options, vehicles);
      setState({ ...state, _successDialogLoading: false, _redirect: true });
      window.Location.reload(true);
    } catch (err) {
      setState({ ...state, _redirect: true });
    }
  };

  // on success redirect to vehicles page
  if (_redirect) {
    return <Redirect to="/dashboard/vehicles" />;
  }

  // if loading show a spinner
  if (_loading) {
    return <div class="spinner-border text-primary" role="status"></div>;
  }

  return (
    <div className="text-dark">
      <h1>Add Vehicle</h1>
      <FloatingButton />
      {/* <div className="card p-3">
        <h2>Connect Your Car</h2>
        <p>
          If your have a <strong> Tata, BMW or Tesla </strong> Vehicle, You can
          directly connect with Keemut via your Manufacturer's API.
        </p>
        <Dropdown
          className="mt-2"
          fluid
          search
          selection
          options={[
            {
              text: "BMW",
              key: "BMW",
              value: "BMW",
            },
            {
              text: "Tata",
              key: "Tata",
              value: "Tata",
            },
            {
              text: "Tesla",
              key: "Tesla",
              value: "Tesla",
            },
          ]}
          placeholder="Select Your Make"
        />
        <button className="btn btn-primary mt-3">Connect</button>
      </div> */}
      <div className="card p-3">
        <p>
          If you have a <strong>TATA</strong> Vehicle,{" "}
          <a href="/dashboard/tata/onboard" style={{ color: "blue" }}>
            Click Here
          </a>{" "}
          to connect with Keemut.
        </p>
        <form onSubmit={_addVehicle} className="card-content" method="post">
          <div class="form-group">
            <label className="overview-dialog-label">Country</label>

            <Dropdown
              id="add_vehicle_country_dropdown"
              placeholder="Select Country"
              fluid
              search
              error={
                !_countryInputValue
                  ? {
                      content: "Country is required",
                      pointing: "below",
                    }
                  : false
              }
              selection
              options={_countryOptions}
              onChange={(e, { value }) => {
                _getMakeList(value);
              }}
            />
          </div>
          <div class="form-group">
            <label className="overview-dialog-label">Vehicle Nickname</label>
            <input
              type="text"
              required
              class="form-control"
              placeholder="My Awesome Car"
              value={_vehicleName}
              name="_vehicleName"
              onChange={_handleChange}
            />
          </div>

          <div class="form-group">
            <label className="overview-dialog-label">Model Year</label>
            <Dropdown
              fluid
              search
              selection
              options={(function () {
                let currentYear = new Date().getFullYear();
                let s = [];
                for (let i = parseInt(currentYear); i >= 1950; i--)
                  s.push({ key: i, value: i, text: i });

                return s;
              })()}
              onChange={(e, { value }) => {
                setState({ ...state, _year: value });
              }}
            />
          </div>
          <div class="form-group">
            <label className="overview-dialog-label">Make</label>

            <Dropdown
              fluid
              search
              error={
                !_make
                  ? {
                      content: "Make is required",
                      pointing: "below",
                    }
                  : false
              }
              selection
              options={_makeOptions}
              onChange={(e, { value }) => {
                if (value == "Tesla" || value == "BMW") {
                  return setState({
                    ...state,
                    _make: value,
                    _showTeslaConnectionDialog: true,
                  });
                }

                _getModelList(value);
              }}
            />

            {_make == "other" && (
              <input
                type="text"
                className="mt-2 form-control"
                placeholder="Enter Make Name"
                required
                value={_customMake}
                name="_customMake"
                onChange={_handleChange}
              />
            )}
          </div>
          <div class="form-group">
            <label className="overview-dialog-label">Model</label>

            <div className="input-group">
              <Dropdown
                fluid
                search
                error={
                  !_model
                    ? {
                        content: "Model is required",
                        pointing: "below",
                      }
                    : false
                }
                selection
                options={_modelOptions}
                onChange={(e, { value }) => {
                  _getTrimList(value);
                }}
              />
              {(_make == "other" || _model == "other") && (
                <input
                  type="text"
                  className="mt-2 form-control"
                  placeholder="Enter Model Name"
                  required
                  value={_customModel}
                  name="_customModel"
                  onChange={_handleChange}
                />
              )}
            </div>
          </div>

          <div class="form-group">
            <label className="overview-dialog-label">Trim</label>

            <div className="input-group">
              <Dropdown
                fluid
                search
                error={
                  !_trim
                    ? {
                        content: "Trim is required",
                        pointing: "below",
                      }
                    : false
                }
                selection
                options={_trimOptions}
                onChange={(e, { value }) => {
                  _getDrivetrainOptions(value);
                }}
              />
              {(_make == "other" || _model == "other" || _trim == "other") && (
                <input
                  type="text"
                  className="mt-2 form-control"
                  placeholder="Enter Trim Name"
                  required
                  value={_customTrim}
                  name="_customTrim"
                  onChange={_handleChange}
                />
              )}
            </div>
          </div>

          <div class="form-group">
            <label className="overview-dialog-label">Drivetrain</label>

            <div className="input-group">
              <Dropdown
                search
                selection
                fluid
                error={
                  !_drivetrain
                    ? {
                        content: "drivetrain is required",
                        pointing: "below",
                      }
                    : false
                }
                value={_drivetrain}
                onChange={(e, { value }) => {
                  setState({ ...state, _drivetrain: value });
                }}
                options={_drivetrainOptions}
              />
            </div>
          </div>

          <div class="form-group">
            <label className="overview-dialog-label">License Plate</label>
            <input
              type="text"
              class="form-control"
              placeholder="ABC 123"
              value={_plateNo}
              name="_plateNo"
              required
              onChange={_handleChange}
            />
          </div>
          <div class="form-group">
            <label className="overview-dialog-label">VIN</label>

            <div className="input-group">
              <input
                type="text"
                class="form-control"
                aria-describedby="basic-addon1"
                placeholder="optional"
                value={_vin}
                onChange={_handleChange}
                name="_vin"
              />
            </div>
          </div>

          {(_make == "other" || _model == "other" || _trim == "other") && (
            <>
              <div className="form-group">
                <label>Fuel Tank Size</label>
                <div className="input-group">
                  <input
                    type="number"
                    class="form-control"
                    aria-describedby="basic-addon1"
                    placeholder="How much is the fuel tank size ? "
                    value={_customFuelTankSize}
                    onChange={_handleChange}
                    name="_customFuelTankSize"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      {_fuelUnit}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Estimated Fuel Economy</label>
                <div className="input-group">
                  <input
                    type="number"
                    class="form-control"
                    aria-describedby="basic-addon1"
                    placeholder="How much is the estimated fuel economy ? "
                    value={_customMpg}
                    onChange={_handleChange}
                    name="_customMpg"
                  />
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      {_distanceUnit} per [{_fuelUnit}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Vehicle MSRP</label>
                <div className="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      {resolveCurrency(_currencyUnit)}
                    </span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    aria-describedby="basic-addon1"
                    placeholder="How much is the MSRP ? "
                    value={_customMsrp}
                    onChange={(e) => {
                      setState({
                        ...state,
                        _customMsrp: NumberFormat(
                          e.target.value.replaceAll(",", ""),
                          _country
                        ),
                      });
                    }}
                    name="_customMsrp"
                  />
                </div>
              </div>
            </>
          )}

          <div className="form-group">
            <label>Purchase Option</label>
            <div className="input-group">
              <Dropdown
                placeholder="How did you purchase the vehicle ?"
                options={[
                  { key: "purchase", value: "purchase", text: "Purchase" },
                  { key: "lease", value: "lease", text: "Lease " },
                  { key: "finance", value: "finance", text: "Finance " },
                ]}
                search
                selection
                fluid
                value={_purchaseOption}
                onChange={(e, { value }) =>
                  setState({ ...state, _purchaseOption: value })
                }
              />
            </div>
          </div>

          {_purchaseOption == "purchase" ? (
            <div class="form-group">
              <label className="overview-dialog-label">Purchase Price</label>

              <div className="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    {resolveCurrency(_currencyUnit)}
                  </span>
                </div>
                <input
                  class="form-control"
                  aria-describedby="basic-addon1"
                  placeholder="How much did you purchase for ? "
                  value={_purchasePrice}
                  onChange={(e) => {
                    setState({
                      ...state,
                      _purchasePrice: NumberFormat(
                        e.target.value.replaceAll(",", ""),
                        _country
                      ),
                    });
                  }}
                  name="_purchasePrice"
                />
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>Lease Duration (Months)</label>
                    <div className="input-group">
                      <Dropdown
                        search
                        selection
                        options={[
                          { key: "12", value: "12", text: "12" },
                          { key: "24", value: "24", text: "24" },
                          { key: "36", value: "36", text: "36" },
                          { key: "48", value: "48", text: "48" },
                          { key: "60", value: "60", text: "60" },
                          { key: "72", value: "72", text: "72" },
                          { key: "84", value: "84", text: "12" },
                          { key: "96", value: "96", text: "96" },
                        ]}
                        fluid
                        value={_duration}
                        onChange={(e, { value }) => {
                          setState({ ...state, _duration: value });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group">
                    <label>Downpayment (if any)</label>
                    <div className="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          {resolveCurrency(_currencyUnit)}
                        </span>
                      </div>
                      <input
                        type="number"
                        class="form-control"
                        aria-describedby="basic-addon1"
                        placeholder="How much was the downpayment ? "
                        name="_downpayment"
                        value={_downpayment}
                        onChange={(e) => {
                          setState({
                            ...state,
                            _downpayment: NumberFormat(
                              e.target.value.replaceAll(",", ""),
                              _country
                            ),
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group">
                    <label>Monthly Payment</label>
                    <div className="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          {resolveCurrency(_currencyUnit)}
                        </span>
                      </div>
                      <input
                        type="number"
                        class="form-control"
                        aria-describedby="basic-addon1"
                        placeholder="How much is monthly payment ? "
                        name="_monthlyPayment"
                        value={_monthlyPayment}
                        onChange={(e) => {
                          setState({
                            ...state,
                            _monthlyPayment: NumberFormat(
                              e.target.value.replaceAll(",", ""),
                              _country
                            ),
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                {_purchaseOption == "lease" && (
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>Driving Limit (if any)</label>
                      <div className="input-group">
                        <input
                          type="number"
                          class="form-control"
                          aria-describedby="basic-addon1"
                          placeholder="Is there any driving limit ? "
                          name="_drivingLimit"
                          value={_drivingLimit}
                          onChange={(e) => {
                            setState({
                              ...state,
                              _drivingLimit: NumberFormat(
                                e.target.value.replaceAll(",", ""),
                                _country
                              ),
                            });
                          }}
                        />

                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            {_units.distanceunit} / yr
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          <div className="form-group">
            <label className="overview-dialog-label">
              Is the vehicle used for Business / Work?
            </label>
            <br />
            <input
              type="checkbox"
              id="switch0"
              data-switch="none"
              name="_workRelated"
              checked={_workRelated}
              onChange={() => {
                setState({ ...state, _workRelated: !_workRelated });
              }}
            />
            <label for="switch0" data-on-label="" data-off-label=""></label>
          </div>

          <input
            type="submit"
            onSubmit={_addVehicle}
            className="btn btn-primary mt-3"
            style={{ width: "100%" }}
            value={_saving ? "Saving..." : "Submit"}
          ></input>
        </form>

        <Dialog
          open={_success}
          onClose={() => setState({ ...state, _success: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div class="modal-content modal-filled bg-success">
            <div class="modal-body p-4">
              <div class="text-center">
                <i class="dripicons-checkmark h1"></i>
                <h4 class="mt-2">Vehicle Added!</h4>
                <p class="mt-3">
                  You can now proceed to track the details of your car.
                </p>
                <button
                  type="button"
                  class="btn btn-light my-2"
                  data-dismiss="modal"
                  onClick={_handleClose}
                >
                  {_successDialogLoading ? "Loading..." : "Continue"}
                </button>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={_success}
          onClose={() => setState({ ...state, _success: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div class="modal-content modal-filled bg-success">
            <div class="modal-body p-4">
              <div class="text-center">
                <i class="dripicons-checkmark h1"></i>
                <h4 class="mt-2">Vehicle Added!</h4>
                <p class="mt-3">
                  You can now proceed to track the details of your car.
                </p>
                <button
                  type="button"
                  class="btn btn-light my-2"
                  data-dismiss="modal"
                  onClick={_handleClose}
                >
                  {_successDialogLoading ? "Loading..." : "Continue"}
                </button>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={_showError}
          onClose={() => setState({ ...state, _showError: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div class="modal-content modal-filled bg-danger">
            <div class="modal-body p-4">
              <div class="text-center">
                <i class="dripicons-wrong h1"></i>
                <h4 class="mt-2">Oh snap!</h4>
                <p class="mt-3">{_error}</p>
                <button
                  type="button"
                  class="btn btn-light my-2"
                  data-dismiss="modal"
                  onClick={() =>
                    setState({ ...state, _showError: false, _error: null })
                  }
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={_showTeslaConnectionDialog}
          onClose={() =>
            setState({ ...state, _showTeslaConnectionDialog: false })
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div class="modal-content modal-filled bg-success">
            <div class="modal-body p-4">
              <div class="text-center">
                <button
                  type="button"
                  className="float-right close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={(e) =>
                    setState({ ...state, _showTeslaConnectionDialog: false })
                  }
                >
                  ×
                </button>
                <h4 class="mt-2">
                  Connect Your {_make == "Tesla" ? "Tesla" : "Vehicle"}!
                </h4>
                <p class="mt-3">
                  {_make == "Tesla"
                    ? `You must connect your Tesla account with Keemut to proceed. We
                  do not store your credentials, rather we create a connection
                  with Tesla to capture data on your behalf for things like trip
                  history, battery degradation, and more.`
                    : _make == "BMW"
                    ? `You can connect your BMW account with Keemut to automatically track your vehicle stats.`
                    : `You can connect your ${_make} ${_model} with Keemut to track your vehicle automatically`}
                </p>

                <span className="ml-3">
                  {_make == "Tesla" ? (
                    <a href="/dashboard/tesla/onboard">
                      <button
                        type="button"
                        class="btn btn-light my-2"
                        data-dismiss="modal"
                      >
                        Connect {_make} Account
                      </button>
                    </a>
                  ) : _make == "BMW" ? (
                    <a href="/dashboard/bmw">
                      <button
                        type="button"
                        class="btn btn-light my-2"
                        data-dismiss="modal"
                      >
                        Connect {_make} Account
                      </button>
                    </a>
                  ) : (
                    <button
                      onClick={_initiateSmartcarAuth}
                      type="button"
                      class="btn btn-light my-2"
                      data-dismiss="modal"
                    >
                      Connect {_make} Account
                    </button>
                  )}
                </span>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default AddVehicle;
