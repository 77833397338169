import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import simpleFetch from "../../../helpers/fetch-wrapper";
import TeslaHelper from "../../../helpers/tesla-helper";
import LineChart from "../../custom-charts/LineChart";
import ScatterChart from "../../custom-charts/ScatterChart";

const WhMiVsTemperatureChart = ({ vehicle, units }) => {
  const [state, setState] = useState({
    _loading: true,
    _xaxis: [],
    _yaxes: [],
    _scatterSeries: [],
    _min: null,
    _max: null,
  });

  const { _loading, _scatterSeries, _min, _max } = state;

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    try {
      setState({ ...state, _loading: true });
      let scatterSeries = [];

      const data = await TeslaHelper.getWhMiVsTemperature(vehicle.vin);
      let min = 99999;
      let max = 0;

      data.result.forEach((item, i) => {
        if (units.distanceunit == "km") {
          item.watt_per_mile /= 1.607;
        } else {
          // console.log("here");
          item.outside_temp = (item.outside_temp * 9) / 5 + 32;
        }

        if (item.watt_per_mile != 0 && item.watt_per_mile <= 450) {
          if (min > item.watt_per_mile) min = parseFloat(item.watt_per_mile);

          if (max < item.watt_per_mile) max = parseFloat(item.watt_per_mile);

          scatterSeries.push([
            parseInt(item.outside_temp),
            parseInt(item.watt_per_mile),
          ]);
        }
      });

      setState({
        ...state,
        _min: min,
        _max: max,

        _scatterSeries: [{ name: "Sample", data: scatterSeries }],
        _loading: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="text-dark card card-body" style={{ height: "100%" }}>
      <h3>
        Watt Per {units.distanceunit == "km" ? "Kilometer" : "Mile"} vs
        Temperature
      </h3>

      {_loading ? (
        <div className="spinner spinner-border center-spinner"> </div>
      ) : (
        <>
          <ScatterChart
            yAxisTitle={`Watt Per ${
              units.distanceunit == "km" ? "Kilometer" : "Mile"
            } (Wh/${units.distanceunit})`}
            xAxisTitle={`Temperature (°${units.temperatureunit})`}
            series={_scatterSeries}
            min={parseFloat(parseFloat(_min) - parseFloat(_min) * 0.1)}
            max={parseFloat(parseFloat(_max) + parseFloat(_max) * 0.1)}
          />
        </>
      )}
    </div>
  );
};

export default WhMiVsTemperatureChart;
