import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { API } from "../../../config";

const HyundaiConnection = () => {
  const [state, setState] = useState({
    username: null,
    password: null,
    region: null,
    pin: null,
    loading: false,
  });
  const { username, password, region, pin, loading } = state;

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const onSubmit = async () => {
    try {
      setState({ ...state, loading: true });

      const res = await fetch(`${API}/hyundai/connect`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
          pin,
          region,
        }),
      });
      const data = await res.json();

      setState({ ...state, loading: false });
    } catch (err) {
      setState({ ...state, loading: false });
    }
  };

  return (
    <div className="text-dark">
      <div className="card card-body">
        <h4>Connect Your Hyundai</h4>
        <p>
          We don't save your Hyundai Credentials, we only use those credentials
          to obtain an access token.
        </p>

        <div className="form-group">
          <label for="password">Username</label>
          <div className="input-group input-group-merge">
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder="Enter your username"
              name="username"
              value={username}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-group">
          <label for="password">Password</label>
          <div className="input-group input-group-merge">
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder="Enter your password"
              name="password"
              value={password}
              onChange={handleChange}
            />
            <div className="input-group-append" data-password="false">
              <div
                className="input-group-text"
                onClick={() => {
                  const passwordInp = document.getElementById("password");
                  if (passwordInp.type === "text") {
                    passwordInp.type = "password";
                  } else {
                    passwordInp.type = "text";
                  }
                }}
              >
                <span className="password-eye"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label for="password">Pin</label>
          <div className="input-group input-group-merge">
            <input
              type="password"
              id="pin"
              className="form-control"
              placeholder="Enter your pin"
              name="pin"
              value={pin}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-group">
          <label for="password">Region</label>
          <Dropdown
            placeholder="Select your region"
            options={[
              {
                key: "US",
                text: "US",
                value: "US",
              },
              {
                key: "EU",
                text: "EU",
                value: "EU",
              },
              {
                key: "CA",
                text: "CA",
                value: "CA",
              },
            ]}
            search
            selection
            fluid
            value={region}
            onChange={(e, { value }) => setState({ ...state, region: value })}
          />
        </div>

        <button className="btn btn-block btn-primary" onClick={onSubmit}>
          {loading ? "Loading..." : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default HyundaiConnection;
