import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API } from "../../../config";
import NumberFormat from "../../../helpers/number-format-helper";
import { getOdometerDrivingDataService } from "../../../services/odometer-history-service";

const OdometerCard = ({ vehicle, units }) => {
  const [state, setState] = useState({
    perChange: null,
    totalOdo: null,
    rawChange: null,
    loading: true,
    noData: false,
    showStarman: false,
  });
  const { loading, perChange, totalOdo, rawChange, noData, showStarman } =
    state;

  useEffect(() => {
    const _getOdoData = async () => {
      try {
        if (vehicle.name === "Starman" && vehicle.id === "1iwzoinke0fgs9u") {
          return setState({ ...state, showStarman: true, loading: false });
        }

        setState({ ...state, loading: true });
        const token = localStorage.getItem("token");
        const odoCardJson = localStorage.getItem("odoCard");
        const odoChange = localStorage.getItem("odoChange");
        // console.log("Here" + vehicle.id);

        if (odoChange === "false" && odoCardJson) {
          const odoCard = JSON.parse(odoCardJson);
          if (odoCard.vehicleId === vehicle.id) {
            return setState({
              ...state,
              loading: false,
              perChange: odoCard.perChange < 100 ? -100 : odoCard.perChange,
              rawChange: odoCard.rawChange,
              totalOdo: odoCard.totalOdo,
            });
          }
        }

        const data = await getOdometerDrivingDataService(vehicle.id);

        if (!data.done) {
          setState({ ...state, noData: true, perChange: 0 });
        } else {
          localStorage.setItem(
            "odoCard",
            JSON.stringify({
              vehicleId: vehicle.id,
              perChange:
                data.odoChange.perChange < 100
                  ? -100
                  : data.odoChange.perChange,
              totalOdo: data.totalOdo,
              rawChange: data.odoChange.rawChange,
            })
          );
          localStorage.setItem("odoChange", false);
          setState({
            ...state,
            loading: false,
            perChange: data.odoChange.perChange,
            rawChange: data.odoChange.rawChange,
            totalOdo: data.totalOdo,
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    _getOdoData();
  }, [vehicle]);

  if (noData) {
    return (
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-speedometer-medium widget-icon bg-warning-lighten text-warning"></i>
          </div>
          <h4>Odometer</h4>
          <p>
            No Data Available,{" "}
            <Link to="/dashboard/fuel" style={{ color: "blue" }}>
              Add fuel data
            </Link>{" "}
            to see results.
          </p>
          <br />
          <br />
        </div>
      </div>
    );
  }
  if (loading) {
    return (
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-speedometer-medium widget-icon bg-warning-lighten text-warning"></i>
          </div>
          <h4>Odometer</h4>
          <br />
          <br />

          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  if (showStarman) {
    return (
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-speedometer-medium widget-icon bg-warning-lighten text-warning"></i>
          </div>
          <h3>Odometer</h3>
          <h3 class="mt-3 mb-3">
            {"73,108,300"} {units.distanceunit}{" "}
          </h3>
          <p class="mb-0 text-muted">
            <span class={`text-${15 >= 0 ? "success" : "danger"} mr-2`}>
              <i class={`mdi mdi-arrow-${15 >= 0 ? "up" : "down"}-bold`}></i>{" "}
              {15}%
            </span>
            <span class="text-nowrap">Monthly Driving</span>
          </p>
          <br />
          <p class="mb-0 text-muted">
            <span class="text-success mr-2">
              <i class="mdi mdi-arrow-up-bold"></i> {"45,000"}
            </span>
            <span class="text-nowrap">
              {units.distanceunit === "mi" ? "Miles" : "Kilometers"} increase
              since last month
            </span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div class="card widget-flat">
      <div class="card-body">
        <div class="float-right">
          <i class="mdi mdi-speedometer-medium widget-icon bg-warning-lighten text-warning"></i>
        </div>
        <h3>Odometer</h3>
        <p class="mt-3 mb-3">
          <span className="h3">{NumberFormat(parseInt(totalOdo))}</span>{" "}
          <span>{units.distanceunit}</span>
        </p>
        <br />
        <p class="mb-0 text-muted">
          <span class="text-success mr-2">
            <i class="mdi mdi-arrow-up-bold"></i>{" "}
            <strong> {NumberFormat(parseInt(rawChange))}</strong>
          </span>
          <span class="text-nowrap">
            {units.distanceunit === "mi" ? "Miles" : "Kilometers"} increase
            since last month
          </span>
        </p>
      </div>
    </div>
  );
};

export default OdometerCard;
