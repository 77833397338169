import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../config";
import ConfidenceChart from "../custom-charts/ConfidenceChart";

const ReportValuationChart = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: false,
    series: [],
    options: {
      chart: {
        type: "line",
        height: "100%",
        id: "basic-line",
      },

      xaxis: {
        categories: [],
      },

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
  });
  const { loading, series, options } = state;

  useEffect(() => {
    function initFunction() {
      console.log("FALALA");
      const data = {
        done: true,
        cumulativeWeekDepreciation: [
          "-2.92",
          "-1.57",
          "-1.17",
          "-1.57",
          "-2.64",
          "-4.24",
          "-6.22",
          "-8.44",
          "-10.76",
          "-13.04",
          "-15.14",
          "-16.91",
          "-18.22",
          "-17.57",
        ],
        netDepreciation: 17.569974479461507,
        weeklyDepreciation: 0.7518917284608,
      };
      let cumulativeData = [];
      let cumulativeHigh = [];
      let cumulativeLow = [];
      let deviation = 0;
      data.cumulativeWeekDepreciation.forEach((cd, i) => {
        if (i < data.cumulativeWeekDepreciation.length / 2) {
          deviation = 2;
        } else if (deviation < data.cumulativeWeekDepreciation.length - 2) {
          deviation = 4;
        } else {
          deviation = 5;
        }

        cumulativeHigh.push({
          x: i + 1,
          y:
            99000 -
            99000 * parseFloat((-1 * cd) / 100) +
            99000 * (deviation / 100),
        });
        cumulativeData.push({
          x: i + 1,
          y: 99000 - 99000 * parseFloat((-1 * cd) / 100),
        });
        cumulativeLow.push({
          x: i + 1,
          y:
            99000 -
            99000 * parseFloat((-1 * cd) / 100) -
            99000 * (deviation / 100),
        });
      });

      setState({
        ...state,
        options: {
          ...options,
          colors: ["#00274c", "#343a40", "#343a40"],
          yaxis: { title: { text: "Value" } },
          xaxis: {
            type: "Age, Years",
            categories: [4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56],
            labels: {
              formatter: function (value) {
                return parseInt(value);
              },
            },
            title: { text: "Car Age ( Week )" },
          },
        },
        series: [
          {
            name: "This Vehicle",
            data: cumulativeHigh,
          },
          {
            name: "Excellent Driving",
            data: cumulativeData,
          },
          {
            name: "Aggressive Driving",
            data: cumulativeLow,
          },
        ],
      });
    }

    let mount = true;

    if (mount) initFunction();

    return function cleanup() {
      mount = false;
    };
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <h3>Valuation</h3>
        <div className="row">
          <div className="col-lg-12 mt-5 p-0  text-center">
            <ConfidenceChart
              id="tesla-xyz"
              data={[
                { x: 13, y: 72600, lower: 68859, upper: 76341, ml2: 73489, date: "2021-01-01", },
                { x: 14, y: 72034, lower: 68415, upper: 75653, ml2: 72903, date: "2021-02-01" },
                { x: 15, y: 73135, lower: 69272, upper: 76998, ml2: 74019, date: "2021-03-01" },
                { x: 16, y: 74536, lower: 70873, upper: 78199, ml2: 75353, date: "2021-04-01" },
                { x: 17, y: 75879, lower: 72048, upper: 79710, ml2: 76670, date: "2021-05-01" },
                { x: 18, y: 77305, lower: 73395, upper: 81215, ml2: 78082, date: "2021-06-01" },
                { x: 19, y: 78115, lower: 74522, upper: 81708, ml2: 78858, date: "2021-07-01" },
                { x: 20, y: 79109, lower: 75243, upper: 82975, ml2: 79833 , date: "2021-08-01"},
                { x: 21, y: 79676, lower: 75810, upper: 83542, ml2: 80392, date: "2021-09-01" },
                { x: 22, y: 80365, lower: 76586, upper: 84144, ml2: 81068, date: "2021-10-01" },
                { x: 23, y: 80950, lower: 77171, upper: 84729, ml2: 81643, date: "2021-11-01" },
                { x: 24, y: 81656, lower: 77877, upper: 85435, ml2: 82339, date: "2021-12-01" },
              ]}
              areaData={[
                { x: 13, y: 72600, lower: 68859, upper: 76341, ml2: 73489, date: "2021-01-01" },
                { x: 14, y: 72034, lower: 68415, upper: 75653, ml2: 72903, date: "2021-02-01"},
                { x: 15, y: 73135, lower: 69272, upper: 76998, ml2: 74019, date: "2021-03-01"},
                { x: 16, y: 74536, lower: 70873, upper: 78199, ml2: 75353, date: "2021-04-01"},
                { x: 17, y: 75879, lower: 72048, upper: 79710, ml2: 76670, date: "2021-05-01"},
                { x: 18, y: 77305, lower: 73395, upper: 81215, ml2: 78082, date: "2021-06-01"},
                { x: 19, y: 78115, lower: 74522, upper: 81708, ml2: 78858, date: "2021-07-01"},
                { x: 20, y: 79109, lower: 75243, upper: 82975, ml2: 79833, date: "2021-08-01"},
                { x: 21, y: 79676, lower: 75810, upper: 83542, ml2: 80392, date: "2021-09-01"},
                { x: 22, y: 80365, lower: 76586, upper: 84144, ml2: 81068, date: "2021-10-01"},
                { x: 23, y: 80950, lower: 77171, upper: 84729, ml2: 81643, date: "2021-11-01"},
                { x: 24, y: 81656, lower: 77877, upper: 85435, ml2: 82339, date: "2021-12-01"},
              ]}
              dates={[
                "2021-01-01",
                "2021-02-01",
                "2021-03-01",
                "2021-04-01",
                "2021-05-01",
                "2021-06-01",
                "2021-07-01",
                "2021-09-01",
                "2021-10-01",
                "2021-11-01",
                "2021-12-01",


              ]}
              domainX={[13, 25]}
              domainY={[64995, 91189]}
              years={[
                2020, 2020, 2020, 2020, 2020, 2020, 2020, 2020, 2020, 2020,
                2021, 2021, 2021,
              ]}
              vin={"xyz"}
              currencyUnit={"USD"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportValuationChart;
