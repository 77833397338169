import React, { useState, useEffect } from "react";
import { API } from "../../../config";

const FleetProfitLoss = () => {
  const [state, setState] = useState({
    profitLossPercent: null,
    loading: true,
    error: null,
  });
  const { error, loading, profitLossPercent } = state;
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getProfitLoss();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getProfitLoss = async () => {
    const token = localStorage.getItem("token");
    setState({ ...state, loading: true, error: null });
    try {
      const res = await fetch(`${API}/fleet/margins`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await res.json();

      if (data.done) {
        setState({
          ...state,
          profitLossPercent:
            data.percentProfit && data.percentProfit.toFixed(2),
          loading: false,
        });
      }
    } catch (err) {
      setState({
        ...state,
        error: "Unable to process your fleet at the moment",
        loading: false,
      });
    }
  };

  if (loading) {
    return (
      <div class="card  widget-flat text-dark">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-cash-usd-outline widget-icon bg-primary-lighten text-primary  "></i>
          </div>
          <span>
            <strong>Net Profit</strong>{" "}
          </span>
          <br />
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card  widget-flat text-dark">
      <div class="card-body">
        <div class="float-right">
          <i class="mdi mdi-cash-usd-outline widget-icon bg-primary-lighten text-primary  "></i>
        </div>
        <span>
          <strong>Gross Margin</strong>{" "}
        </span>
        {error ? (
          <>
            {" "}
            <p className="text-danger">{error}</p>{" "}
          </>
        ) : (
          <>
            <h3 class="mt-3 mb-3">{profitLossPercent}%</h3>
            <p>This is your gross Profit or Loss for your fleet.</p>
          </>
        )}
      </div>
    </div>
  );
};
export default FleetProfitLoss;
