import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../../config";

const CityHighwayMpg = ({ vehicle, units }) => {
  const [state, setState] = useState({
    options1: {
      chart: {
        type: "bar",
        height: 380,
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      colors: ["#0acf97", "#00274c", "#fa5c7c"],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: ["City Economy", "Combined Economy", "Highway Economy"],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: "Fuel Economy",
        align: "center",
        floating: true,
      },

      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
    series1: [
      {
        data: [],
      },
    ],

    loading: true,
    error: null,
  });

  const { options1, series1, loading, error } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getFuelMpg();
    }

    return function () {
      mounted = false;
    };
  }, [vehicle]);

  const getFuelMpg = () => {
    const token = localStorage.getItem("token");
    setState({ ...state, loading: false, error: null });
    fetch(
      `${API}/vehicle-list/mpg/?make=${vehicle.make}&model=${vehicle.model}&crowdsource=${vehicle.is_crowdsourced}&country=${vehicle.country_code}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (units.distanceunit == "km" && units.fuelunit == "ltr") {
          data.cityMpg *= 0.43;
          data.fuelMpg *= 0.43;
          data.highwayMpg *= 0.43;
        }

        setState({
          ...state,
          loading: false,
          error: null,
          series1: [
            {
              data: [
                parseInt(data.cityMpg),
                parseInt(data.fuelMpg),
                parseInt(data.highwayMpg),
              ],
            },
          ],
        });
      })
      .catch((err) => {
        setState({
          ...state,
          error: "Unable to fetch the Fuel Economy Data at this moment",
          loading: false,
        });
      });
  };

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h3>Fuel Economy</h3>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card" style={{ height: "100%" }}>
        <div className="card-body">
          <h3>Fuel Economy</h3>
          <div className="row">
            <div
              class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
              role="alert"
            >
              <strong>Error - </strong> {error}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card" style={{ height: "100%" }}>
      <div className="card-body">
        <h3>Fuel Economy</h3>
        <div className="row">
          <div className="col-lg-12">
            <Chart options={options1} series={series1} type="bar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityHighwayMpg;
