import simpleFetch from "../helpers/fetch-wrapper";

/**
 * @author Hemang Bhogayata
 * @param {String} abrpAuthorizationCode
 * @description Exchange abrp auth code
 */
export async function exchangeAbrpAuthCode(abrpAuthorizationCode) {
  try {
    return await simpleFetch(
      "/abrp/oauth/exchange-code",
      "POST",
      JSON.stringify({ abrpAuthorizationCode })
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 * @author Hemang Bhogayata
 * @description get abrp meta if it exists
 */
export async function getAbrpMeta() {
  try {
    return await simpleFetch(
      "/abrp/meta",
      "GET",
    );
  } catch (err) {
    console.log(err);
  }
}
