import React, { useState } from "react";
import { API } from "../../config";
import Topbar from "../common/Topbar";

const ForgotPassword = () => {
  const [state, setState] = useState({
    email: null,
    success: false,
    error: false,
    loading: false,
  });
  const { email, success, error, loading } = state;

  const sendForgotPasswordEmail = () => {
    setState({ ...state, loading: true });
    fetch(`${API}/forgot-password?email=${email}`, {
      method: "GET",
      headers: {
        Accept: "appliction/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.done) {
          setState({ ...state, success: true, error: false, loading: false });
        } else {
          setState({ ...state, error: true, success: false, loading: false });
        }
      })
      .catch((err) => {
        setState({ ...state, error: true, success: false, loading: false });
      });
  };

  return (
    <div class="authentication-bg" style={{ height: "100vh" }}>
      <div class="account-pages mb-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="card mt-5">
                <div class="card-header pt-4 pb-4 text-center bg-primary">
                  <a href="/">
                    <span>
                      <img
                        src={require("../../assets/keemut-2.png")}
                        alt=""
                        width="100"
                        height="30"
                      />
                    </span>
                  </a>
                </div>

                <div class="card-body p-4">
                  <div class="text-center m-auto">
                    <h4 class="text-dark-50 text-center mt-4 font-weight-bold">
                      Reset Password
                    </h4>
                    <p class="text-muted mb-4">
                      An Email will be sent to your email with the instructions
                      to reset your password.
                    </p>
                  </div>
                  {error && (
                    <div
                      class="my-2 alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
                      role="alert"
                    >
                      Unable to send a Password Reset email at this time.
                    </div>
                  )}

                  {success && (
                    <div
                      class="my-2 alert alert-success alert-dismissible bg-success text-white border-0 fade show"
                      role="alert"
                    >
                      A email has been sent, You can close this page now.
                    </div>
                  )}
                  <div className="form-group">
                    <label for="emailaddress">Email address</label>
                    <input
                      className="form-control"
                      type="email"
                      id="emailaddress"
                      required="true"
                      placeholder="Enter your email"
                      name="email"
                      value={email}
                      onChange={(e) =>
                        setState({ ...state, email: e.target.value })
                      }
                    />
                  </div>

                  <div class="form-group mb-0 text-center">
                    <button
                      onClick={sendForgotPasswordEmail}
                      class="btn btn-primary"
                    >
                      {loading ? (
                        <>
                          {" "}
                          <span
                            class="spinner-grow spinner-grow-sm mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...{" "}
                        </>
                      ) : (
                        "Confirm"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="footer footer-alt">2020 © Keemut</footer>
    </div>
  );
};

export default ForgotPassword;
