import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../config";

const ReportMaintenanceBreakdown = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: false,
    analytics: {},
    series: [0, 0, 43, 57, 0, 0, 0, 0, 0, 0],
    options: {
      colors: [
        "#E44236",
        "#AE1438",
        "#7523E8",
        "#0A3D62",
        "#4BCFFA",
        "#2ecc72",
        "#F5C469",
        "#99AAAB",
        "#FFF222",
        "#67E6DC",
      ],
      plotOptions: {
        pie: {
          customScale: 0.8,
        },
      },
      chart: {
        width: 200,

        type: "donut",
      },
      labels: [
        "Engine Related",
        "Electrical Related",
        "AC Related",
        "Tyre Brake Related",
        "Transmission Related",
        "Body Related",
        "Interior Related",
        "Steering Related",
        "Suspension Related",
        "Other",
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const { loading, series, options } = state;

  return (
    <div>
      <div className="card" style={{ height: "550px" }}>
        <div className="card-body  p-3">
          <h4 class="modal-title" id="primary-header-modalLabel">
            Maintenance Cost Breakdown
          </h4>

          <div>
            <Chart series={series} options={options} type="donut" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportMaintenanceBreakdown;
