import React from "react";
import WebRoutes from "../web-routes";
import RegisterForm from "../components/register/register-form";

const RegisterPage = (props) => {
  return (
    <React.Fragment>
      <div className="loading authentication-bg" style={{ minHeight: "100vh" }}>
        <div className="account-pages mb-5 container">
          <div className="row justify-content-center">
            <div className="col-lg-5 ml-5">
              {/* Register Card Start */}
              <div className="card mt-5">
                {/* Register Card Header Start */}

                <div className="card-header pt-4 pb-4 text-center bg-dark">
                  <a href="/">
                    <span>
                      <img
                        src={require("../assets/keemut-beta-white.svg")}
                        alt=""
                        className="logo-dark"
                        height="40"
                      />
                    </span>
                  </a>
                </div>

                {/* Register Card Header End */}
                <div class="card-body p-4">
                  <div class="text-center w-75 m-auto">
                    <h4 class="text-dark-50 text-center mt-0 font-weight-bold">
                      Free Sign Up
                    </h4>
                    <p class="text-muted mb-2">
                      Don't have an account? Create your account, it takes less
                      than a minute{" "}
                    </p>
                  </div>
                  <p className="text-muted text-center mb-4">
                    <a
                      style={{ color: "blue" }}
                      href={WebRoutes.DEALER_LOGIN_ROUTE}
                    >
                      Click Here
                    </a>{" "}
                    for Dealer Registration
                  </p>
                  <RegisterForm {...props} />
                </div>
              </div>
              {/* Register Card End*/}

              {/* Register Footnote Start*/}
              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted">
                    Already have account?{" "}
                    <a href={WebRoutes.LOGIN_ROUTE} className="text-muted ml-1">
                      <b>Log In</b>
                    </a>
                  </p>
                </div>
              </div>
              {/* Register Footnote End*/}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegisterPage;
