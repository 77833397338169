import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import {
  getMakesService,
  getModelsService,
} from "../../services/vehicle-list-service";

const VehicleMMTYForm = ({
  onYearChange,
  onMakeChange,
  onModelChange,
  make,
  model,
  year,
}) => {
  const [state, setState] = useState({
    _year: year,
    _make: make,
    _model: model,

    _makeOpts: [],
    _modelOpts: [],
  });

  const { _make, _makeOpts, _model, _modelOpts, _year } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) _getMakes();

    return function cleanup() {
      mounted = false;
    };
  }, [_year]);

  useEffect(() => {
    let mounted = true;

    if (mounted && _make && _makeOpts.length > 0 && _year) _getModels();

    return function cleanup() {
      mounted = false;
    };
  }, [_make, _year, _makeOpts]);

  const _getMakes = async () => {
    try {
      console.log("GETTING MAKES!!");
      const data = await getMakesService("USA");
      let makes = [];
      data.makeList.forEach((m, i) => {
        makes.push({
          key: i,
          value: m.make,
          text: m.make,
        });
      });

      setState({ ...state, _makeOpts: makes });
    } catch (error) {
      console.log(error);
    }
  };

  const _getModels = async () => {
    try {
      const data = await getModelsService(_make, _year, "USA");
      let models = [];
      data.modelList.forEach((m, i) => {
        models.push({
          key: i,
          value: m.model,
          text: m.model,
        });
      });

      setState({ ...state, _modelOpts: models });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="row">
      <div className="col-lg-4">
        <div className="form-group">
          <label className="overview-dialog-label">Year</label>

          <div className="input-group">
            <Dropdown
              fluid
              search
              selection
              value={_year}
              options={(function () {
                let currentYear = new Date().getFullYear();
                let s = [];
                for (let i = parseInt(currentYear); i >= 1950; i--)
                  s.push({ key: i, value: i, text: i });

                return s;
              })()}
              onChange={(e, { value }) => {
                setState({ ...state, _year: value });
                onYearChange(value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="form-group">
          <label className="overview-dialog-label">Make</label>

          <div className="input-group">
            <Dropdown
              fluid
              search
              selection
              options={_makeOpts}
              value={_make}
              onChange={(e, { value }) => {
                setState({ ...state, _make: value });
                onMakeChange(value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="form-group">
          <label className="overview-dialog-label">Model</label>

          <div className="input-group">
            <Dropdown
              fluid
              search
              selection
              options={_modelOpts}
              value={_model}
              onChange={(e, { value }) => {
                setState({ ...state, _model: value });
                onModelChange(value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleMMTYForm;
