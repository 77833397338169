import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { API } from "../../../config";
import resolveCurrency from "../../../helpers/Helper";
import moment from "moment";
import { Dropdown } from "semantic-ui-react";
import { editMaintenanceFormService } from "../../../services/forms/maintenance-form-service";

const EditMaintenanceDialog = ({
  maintenanceReportId,
  showEditDialog,
  closeEditDialog,
  myUnits,
  vehicle,
  viewOnly,
  maintenanceReport,
}) => {
  const [state, setState] = useState({
    date: null,
    odometer: null,
    units: JSON.parse(localStorage.getItem("units")),

    acRelated: false,
    acCost: 0,
    bodyRelated: false,
    bodyCost: 0,
    engineRelated: false,
    engineCost: 0,
    electricalRelated: false,
    electricalCost: 0,
    interiorRelated: false,
    interiorCost: 0,
    suspensionRelated: false,
    suspensionCost: 0,
    steeringRelated: false,
    steeringCost: 0,
    transmissionRelated: false,
    transmissionCost: 0,
    tireRelated: false,
    tireCost: 0,
    anyOtherCost: 0,
    otherRelated: false,
    otherCost: 0,
    tax: 0,
    loading: false,
    formData: new FormData(),
    lastOdometer: null,
    success: false,
    error: false,
    prevDate: null,
    categories: [],
    attachment1: null,
    attachment2: null,
    attachment3: null,
  });

  const {
    categories,
    date,
    odometer,
    formData,
    units,
    acCost,
    acRelated,
    anyOtherCost,
    bodyCost,
    bodyRelated,
    electricalCost,
    electricalRelated,
    engineCost,
    engineRelated,
    interiorCost,
    interiorRelated,
    suspensionCost,
    suspensionRelated,
    steeringCost,
    steeringRelated,
    tireCost,
    tireRelated,
    transmissionCost,
    transmissionRelated,
    otherCost,
    otherRelated,
    tax,
    error,
    loading,
    lastOdometer,
    success,
    prevDate,
    attachment1,
    attachment2,
    attachment3,
  } = state;

  useEffect(() => {
    const initalizeMaintenanceReport = () => {
      if (maintenanceReport != null) {
        let cat = [];
        if (maintenanceReport.ac_related) {
          cat.push("ac_related");
        }

        if (maintenanceReport.body_related) {
          cat.push("body_related");
        }

        if (maintenanceReport.electrical_related) {
          cat.push("electrical_related");
        }

        if (maintenanceReport.engine_cost) {
          cat.push("engine_cost");
        }

        if (maintenanceReport.interior_related) {
          cat.push("interior_related");
        }

        if (maintenanceReport.suspension_related) {
          cat.push("suspension_related");
        }

        if (maintenanceReport.steering_related) {
          cat.push("steering_related");
        }

        if (maintenanceReport.tyre_brake_related) {
          cat.push("tire_related");
        }
        if (maintenanceReport.transmission_related) {
          cat.push("transmission_related");
        }

        if (maintenanceReport.other_related) {
          cat.push("other_related");
        }

        setState({
          ...state,
          categories: cat,
          date: moment(maintenanceReport.date).format("YYYY-MM-DD"),
          odometer: maintenanceReport.odometer,
          acCost: maintenanceReport.ac_cost,
          acRelated: maintenanceReport.ac_related,
          anyOtherCost: maintenanceReport.labour_cost,
          bodyCost: maintenanceReport.body_cost,
          bodyRelated: maintenanceReport.body_related,
          electricalCost: maintenanceReport.electrical_cost,
          electricalRelated: maintenanceReport.electrical_related,
          engineCost: maintenanceReport.engine_cost,
          engineRelated: maintenanceReport.engine_related,
          interiorCost: maintenanceReport.interior_cost,
          interiorRelated: maintenanceReport.interior_related,
          suspensionCost: maintenanceReport.suspension_cost,
          suspensionRelated: maintenanceReport.suspension_related,
          steeringCost: maintenanceReport.steering_cost,
          steeringRelated: maintenanceReport.steering_related,
          tireCost: maintenanceReport.tyre_brake_cost,
          tireRelated: maintenanceReport.tyre_brake_related,
          transmissionCost: maintenanceReport.transmission_cost,
          transmissionRelated: maintenanceReport.transmission_related,
          otherCost: maintenanceReport.other_cost,
          otherRelated: maintenanceReport.other_related,
          tax: maintenanceReport.tax_cost,
          attachment1: maintenanceReport.attachment_1,
          attachment2: maintenanceReport.attachment_2,
          attachment3: maintenanceReport.attachment_3,

          prevDate: maintenanceReport.date,
        });
      }
    };
    let mounted = true;
    if (mounted) initalizeMaintenanceReport();

    return function cleanup() {
      mounted = false;
    };
  }, [maintenanceReport]);

  const updateMaintenanceReport = async (e) => {
    try {
      setState({ ...state, loading: true });

      formData.set(
        "date",
        date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, moment(date).format())
      );
      formData.set("odometer", odometer);
      formData.set("prevDate", prevDate);

      formData.set(
        "total_cost",
        parseFloat(acCost || 0) +
          parseFloat(engineCost || 0) +
          parseFloat(electricalCost || 0) +
          parseFloat(bodyCost || 0) +
          parseFloat(interiorCost || 0) +
          parseFloat(suspensionCost || 0) +
          parseFloat(steeringCost || 0) +
          parseFloat(tireCost || 0) +
          parseFloat(transmissionCost || 0) +
          parseFloat(otherCost || 0) +
          parseFloat(anyOtherCost || 0) +
          parseFloat(tax || 0)
      );
      formData.set(
        "sub_total",
        parseFloat(acCost || 0) +
          parseFloat(engineCost || 0) +
          parseFloat(electricalCost || 0) +
          parseFloat(bodyCost || 0) +
          parseFloat(interiorCost || 0) +
          parseFloat(suspensionCost || 0) +
          parseFloat(steeringCost || 0) +
          parseFloat(tireCost || 0) +
          parseFloat(transmissionCost || 0) +
          parseFloat(otherCost || 0) +
          parseFloat(anyOtherCost || 0) +
          parseFloat(tax || 0)
      );

      formData.set("engine_related", engineRelated);
      formData.set("electrical_related", electricalRelated);
      formData.set("ac_related", acRelated);
      formData.set("tyre_brake_related", tireRelated);
      formData.set("transmission_related", transmissionRelated);
      formData.set("body_related", bodyRelated);
      formData.set("interior_related", interiorRelated);
      formData.set("steering_related", steeringRelated);
      formData.set("suspension_related", suspensionRelated);
      formData.set("other_related", otherRelated);

      formData.set("ac_cost", acCost);
      formData.set("engine_cost", engineCost);
      formData.set("electrical_cost", electricalCost);
      formData.set("body_cost", bodyCost);
      formData.set("interior_cost", interiorCost);
      formData.set("steering_cost", steeringCost);
      formData.set("suspension_cost", suspensionCost);
      formData.set("other_cost", otherCost);
      formData.set("transmission_cost", transmissionCost);
      formData.set("tyre_brake_cost", tireCost);

      formData.set("labour_cost", anyOtherCost);
      formData.set("tax_cost", tax);
      formData.set("labour_cost", anyOtherCost);

      formData.set("id", maintenanceReportId);
      const token = localStorage.getItem("token");

      const data = await editMaintenanceFormService(formData, vehicle.id);

      // console.log(data);

      setState({ ...state, loading: false, success: true });
      closeEditDialog();
    } catch (err) {
      setState({ ...state, loading: false, error: true });
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const computeCategories = (value) => {
    setState({
      ...state,

      engineRelated: value.includes("engine_related"),
      acRelated: value.includes("ac_related"),
      bodyRelated: value.includes("body_related"),

      electricalRelated: value.includes("electrical_related"),
      interiorRelated: value.includes("interior_related"),
      suspensionRelated: value.includes("suspension_related"),
      steeringRelated: value.includes("steering_related"),
      otherRelated: value.includes("other_related"),
      transmissionRelated: value.includes("transmission_related"),
      tireRelated: value.includes("tire_related"),
      otherRelated: value.includes("other_related"),
      categories: value,
    });
  };

  return (
    <Dialog
      open={showEditDialog}
      onClose={closeEditDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div class="modal-content dialog">
        <div class="modal-header modal-colored-header bg-info">
          <h4
            class="modal-title"
            id="primary-header-modalLabel"
            style={{ color: "#ffffff", fontFamily: "Roboto" }}
          >
            Edit Maintenance Report
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={closeEditDialog}
          >
            ×
          </button>
        </div>
        <div class="modal-body dialog">
          <div className="p-3">
            <div className="form-group">
              <label className="overview-dialog-label">Event Date</label>
              <input
                type="date"
                class="form-control date"
                data-toggle="input-mask"
                placeholder="09/09/2020"
                min="2000-01-01"
                name="date"
                value={date}
                onChange={handleChange}
                data-mask-format="00/00/0000"
                data-single-date-picker="true"
                disabled={viewOnly}
              />
            </div>

            <div className="form-group">
              <label>
                <strong>Enter Odometer</strong>{" "}
                <span style={{ fontWeight: "normal" }}>
                  {/* ( Last Odometer as of{" "}
                  {moment(date || new Date()).format("DD-MMM-YY")} :{" "}
                  {parseFloat(lastOdometer).toFixed(2)} {units.distanceunit} ) */}
                </span>
              </label>

              <div className="input-group">
                <input
                  id="odoInput"
                  type="number"
                  class="form-control"
                  name="odometer"
                  disabled={viewOnly}
                  value={odometer}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                  placeholder="What was the odometer reading  ?"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    {units.distanceunit}
                  </span>
                </div>
              </div>
            </div>
            <label>Select Cost Categories</label>
            <div className="form-group">
              <Dropdown
                className="form-control select2"
                placeholder="Select Cost"
                fluid
                disabled={viewOnly}
                multiple
                options={[
                  {
                    key: "Ac",
                    value: "All",
                    text: "All",
                  },
                  {
                    key: "Ac",
                    value: "ac_related",
                    text: "AC Related",
                  },
                  {
                    key: "Body",
                    value: "body_related",
                    text: "Body Related",
                  },
                  {
                    key: "Electrical",
                    value: "electrical_related",
                    text: "Electrical Related",
                  },
                  {
                    key: "Engine",
                    value: "engine_related",
                    text: "Engine Related",
                  },
                  {
                    key: "Interior",
                    value: "interior_related",
                    text: "Interior Related",
                  },

                  {
                    key: "Steering",
                    value: "steering_related",
                    text: "Steering Related",
                  },
                  {
                    key: "Suspension",
                    value: "suspension_related",
                    text: "Suspension Related",
                  },
                  {
                    key: "tire",
                    value: "tire_related",
                    text: "Tire & Brakes Related",
                  },
                  {
                    key: "transmission",
                    value: "transmission_related",
                    text: "Transmission Related",
                  },
                  {
                    key: "other",
                    value: "other_related",
                    text: "Other Related",
                  },
                ]}
                selection
                value={categories}
                onChange={(e, { value }) => {
                  computeCategories(value);
                }}
              />
            </div>

            {acRelated && (
              <>
                {" "}
                <label className="">AC Related Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      disabled={viewOnly}
                      id="odoInput"
                      type="number"
                      class="form-control"
                      name="acCost"
                      value={acCost}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {bodyRelated && (
              <>
                {" "}
                <label className="">Body Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      disabled={viewOnly}
                      id="odoInput"
                      type="number"
                      class="form-control"
                      name="bodyCost"
                      value={bodyCost}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {engineRelated && (
              <>
                {" "}
                <label className="">Engine Related Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      disabled={viewOnly}
                      id="odoInput"
                      type="number"
                      class="form-control"
                      name="engineCost"
                      value={engineCost}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {electricalRelated && (
              <>
                {" "}
                <label className="">Electrical Related</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      disabled={viewOnly}
                      id="odoInput"
                      type="number"
                      class="form-control"
                      name="electricalCost"
                      value={electricalCost}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {interiorRelated && (
              <>
                {" "}
                <label className="">Interior Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      disabled={viewOnly}
                      id="odoInput"
                      type="number"
                      class="form-control"
                      name="interiorCost"
                      value={interiorCost}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {steeringRelated && (
              <>
                {" "}
                <label className="">Steering Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      disabled={viewOnly}
                      id="odoInput"
                      type="number"
                      class="form-control"
                      name="steeringCost"
                      value={steeringCost}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {suspensionRelated && (
              <>
                {" "}
                <label className="">Suspension Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      disabled={viewOnly}
                      id="odoInput"
                      type="number"
                      class="form-control"
                      name="suspensionCost"
                      value={suspensionCost}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {transmissionRelated && (
              <>
                {" "}
                <label className="">Transmission Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      disabled={viewOnly}
                      id="odoInput"
                      type="number"
                      class="form-control"
                      name="transmissionCost"
                      value={transmissionCost}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {tireRelated && (
              <>
                {" "}
                <label className="">Tire Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      disabled={viewOnly}
                      id="odoInput"
                      type="number"
                      class="form-control"
                      name="tireCost"
                      value={tireCost}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {otherRelated && (
              <>
                {" "}
                <label className="">Other Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      disabled={viewOnly}
                      id="odoInput"
                      type="number"
                      class="form-control"
                      name="otherCost"
                      value={otherCost}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            <label className="">Tax Cost</label>
            <div className="form-group">
              <div className="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    {resolveCurrency(units.currencyunit)}
                  </span>
                </div>
                <input
                  disabled={viewOnly}
                  id="odoInput"
                  type="number"
                  class="form-control"
                  name="tax"
                  value={tax}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                  placeholder="0.00"
                />
              </div>
            </div>

            <label className="">Labor Cost</label>
            <div className="form-group">
              <div className="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    {resolveCurrency(units.currencyunit)}
                  </span>
                </div>
                <input
                  disabled={viewOnly}
                  id="odoInput"
                  type="number"
                  class="form-control"
                  name="anyOtherCost"
                  value={anyOtherCost}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                  placeholder="0.00"
                />
              </div>
            </div>
            <label>Total Cost</label>
            <div className="form-group ">
              <div className="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    {resolveCurrency(units.currencyunit)}
                  </span>
                </div>
                <input
                  disabled={viewOnly}
                  id="odoInput"
                  type="number"
                  class="form-control"
                  value={
                    parseFloat(acCost || 0) +
                    parseFloat(engineCost || 0) +
                    parseFloat(electricalCost || 0) +
                    parseFloat(bodyCost || 0) +
                    parseFloat(interiorCost || 0) +
                    parseFloat(suspensionCost || 0) +
                    parseFloat(steeringCost || 0) +
                    parseFloat(tireCost || 0) +
                    parseFloat(transmissionCost || 0) +
                    parseFloat(otherCost || 0) +
                    parseFloat(anyOtherCost || 0) +
                    parseFloat(tax || 0)
                  }
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                  placeholder="0.00"
                />
              </div>
            </div>
            {attachment1 && (
              <>
                {" "}
                <a
                  href={attachment1}
                  style={{ color: "blue", fontSize: "15px" }}
                  target="_blank"
                >
                  Attachment 1
                </a>{" "}
              </>
            )}

            {attachment2 && (
              <>
                {" "}
                <a
                  className="ml-2"
                  href={attachment2}
                  style={{ color: "blue", fontSize: "15px" }}
                  target="_blank"
                >
                  Attachment 2
                </a>{" "}
              </>
            )}

            {attachment3 && (
              <>
                {" "}
                <a
                  className="ml-2"
                  href={attachment3}
                  style={{ color: "blue", fontSize: "15px" }}
                  target="_blank"
                >
                  Attachment 3
                </a>{" "}
              </>
            )}
            <br />
            <br />

            <button
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={updateMaintenanceReport}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditMaintenanceDialog;
