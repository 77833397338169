import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { API, firebaseConfig, nextSite } from "../../config";
import { Dropdown } from "semantic-ui-react";
import * as firebase from "firebase";
import queryString from "query-string";

const mixpanel = require("mixpanel-browser");

const Register = (props) => {
  const [state, setState] = useState({
    email: "",
    password: "",
    country: "US",
    loading: false,
    success: false,
    error: null,
    showSnackbar: false,
    agreeTnc: false,
    timezone: null,
    oAuthLoading: false,
  });
  const {
    email,
    password,
    country,
    loading,
    oAuthLoading,
    success,
    error,
    showSnackbar,
    agreeTnc,
    timezone,
  } = state;

  useEffect(() => {
    let params = queryString.parse(props.location.search);
    let refId = params.referrer;
    if (refId) localStorage.setItem("referrer", refId);
  }, []);

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const isFormValid = () => {
    if (!email || !password) {
      setState({
        ...state,
        error: "Please fillout all the fields",
        loading: false,
        showSnackbar: true,
      });
      return false;
    } else if (
      !/^(?=.*\d)(?=.*[\-!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(password)
    ) {
      setState({
        ...state,
        error:
          "Password must be at least 8 character long with a Number, an uppercase Letter, a lowercase letter and a symbol",
        loading: false,
        showSnackbar: true,
      });
      return false;
    } else if (!agreeTnc) {
      setState({
        ...state,
        error: "Please agree to the Terms and Conditions before signing up!",
      });
    } else {
      return true;
    }
  };

  const registerUser = (e) => {
    e.preventDefault();
    setState({
      ...state,
      loading: true,
      error: null,
      success: false,
      showSnackbar: false,
    });

    if (isFormValid()) {
      fetch(`${API}/register-email-password`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          timezone,
          referrerId: localStorage.getItem("referrer"),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.taken) {
            return setState({
              ...state,
              error: "Email has already been taken by some user!",
              loading: false,
            });
          }

          // track registration
          mixpanel.track("user_register", {
            referrer: localStorage.getItem("referrer"),
          });
          setState({ ...state, loading: false, error: null, success: true });
        })
        .catch((err) => {
          console.log(err);
          setState({
            ...state,
            error: "Unable to Register, Please Try again later",
            loading: false,
          });
        });
    }
  };

  const _signInUsingOauth = async (type, e) => {
    try {
      e.preventDefault();
      setState({ ...state, oAuthLoading: true });
      if (!firebase.apps.length)
        var firebaseApp = new firebase.initializeApp(firebaseConfig);

      let provider = new firebase.auth.GoogleAuthProvider();

      if (type == "google") {
        provider = new firebase.auth.GoogleAuthProvider();
      } else {
        provider = new firebase.auth.FacebookAuthProvider();
      }

      firebase.auth().useDeviceLanguage();

      const result = await firebase.auth().signInWithPopup(provider);
      // This gives you a Google Access Token. You can use it to access the Google API.

      // The signed-in user info.
      var user = result.user;
      const idToken = await user.getIdToken();

      const res = await fetch(`${API}/login-google-oauth`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idToken,
          email: user.email,
          uid: user.uid,
        }),
      });

      const data = await res.json();
      if (data.done) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));

        setState({ ...state, oAuthLoading: false, error: null, success: true });
      } else {
        setState({
          ...state,
          error: true,
          showSnackbar: true,
          oAuthLoading: false,
        });
      }
    } catch (err) {
      setState({
        ...state,
        error: true,
        showSnackbar: true,
        oAuthLoading: false,
      });
    }
  };

  if (success) {
    return <Redirect to="/verify-account" />;
  }

  return (
    <form>
      {error && (
        <div class="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      <div class="form-group">
        <label for="emailaddress">Email address</label>
        <input
          name="email"
          value={email}
          onChange={handleChange}
          class="form-control"
          type="email"
          id="emailaddress"
          required
          placeholder="Enter your email"
        />
      </div>

      <div class="form-group">
        <label for="password">Password</label>
        <div class="input-group input-group-merge">
          <input
            name="password"
            value={password}
            onChange={handleChange}
            type="password"
            id="password"
            class="form-control"
            placeholder="Enter your password"
          />
          <div
            class="input-group-append"
            data-password="false"
            onClick={() => {
              const passwordInp = document.getElementById("password");
              if (passwordInp.type == "text") {
                passwordInp.type = "password";
              } else {
                passwordInp.type = "text";
              }
            }}
          >
            <div class="input-group-text">
              <span class="password-eye"></span>
            </div>
          </div>
        </div>
        {!/^(?=.*\d)(?=.*[\-!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
          password
        ) ? (
          <small className="text-danger">
            {password.length < 8
              ? " The password must be at-least 8 characters long"
              : !/[A-Z]/.test(password)
              ? "Password Must Contain a Capital Letter"
              : !/[1-9]/.test(password)
              ? "The password must contain at-least one number"
              : !/[!@#$%^&*]/.test(password)
              ? "The password must contain at-least one special character (!@#$%^&*-)"
              : ""}
          </small>
        ) : (
          <small className="text-success">The password is valid</small>
        )}
      </div>

      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="checkbox-signup"
            checked={agreeTnc}
            onChange={() => setState({ ...state, agreeTnc: !agreeTnc })}
          />
          <label class="custom-control-label" for="checkbox-signup">
            I accept{" "}
            <a target="_blank" href={`${nextSite}/terms`} class="text-muted">
              Terms and Conditions
            </a>
          </label>
        </div>
      </div>

      <div class="form-group mb-0 text-center">
        <button
          class="btn btn-lg btn-outline-primary px-5"
          onClick={registerUser}
        >
          {loading ? (
            <>
              {" "}
              <span
                class="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...{" "}
            </>
          ) : (
            "Register"
          )}
        </button>
        <br />
        <br />

        <div class="separator">OR</div>

        <button
          className="mt-3 btn btn-outline-dark"
          role="button"
          onClick={(e) => _signInUsingOauth("google", e)}
        >
          <img
            width="20px"
            style={{ marginBottom: "3px", marginRight: "5px" }}
            alt="Google sign-in"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
          />
          {oAuthLoading ? (
            <>
              {" "}
              <span
                className="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...{" "}
            </>
          ) : (
            "Sign up with Google"
          )}
        </button>
        <br />
        <button
          className="mt-3 btn btn-outline-light text-light"
          role="button"
          onClick={(e) => _signInUsingOauth("facebook", e)}
          style={{ backgroundColor: "#354c8c" }}
        >
          <img
            width="20px"
            style={{ marginBottom: "3px", marginRight: "5px" }}
            alt="Google sign-in"
            src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png"
          />
          {oAuthLoading ? (
            <>
              {" "}
              <span
                className="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...{" "}
            </>
          ) : (
            "Sign up with Facebook"
          )}
        </button>
      </div>
    </form>
  );
};

export default Register;
