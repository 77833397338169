import React from "react";
import Dialog from "@material-ui/core/Dialog";

const PaymentSuccessModal = ({
  showSuccessDialog,
  closeSuccessDialog,
  vin,
}) => {
  return (
    <Dialog
      open={showSuccessDialog}
      onClose={closeSuccessDialog}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <div class="modal-content modal-filled bg-success">
        <div class="modal-body p-4">
          <div class="text-center">
            <i class="dripicons-checkmark h1"></i>
            <h4 class="mt-2">Payment Successful!</h4>
            <p class="mt-3">
              {vin
                ? " Your purchase is complete, you can now proceed to view the report."
                : "Your subscription has been confirmed, You can now proceed to take full benefit of Keemut."}
            </p>
            {vin ? (
              <a href={`/keemut-report/${vin}`}>
                <button
                  type="button"
                  class="btn btn-light my-2"
                  data-dismiss="modal"
                >
                  Continue To Keemut Report
                </button>
              </a>
            ) : (
              <a href={`/dashboard/overview`}>
                <button
                  type="button"
                  class="btn btn-light my-2"
                  data-dismiss="modal"
                >
                  Continue To Dashboard
                </button>
              </a>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PaymentSuccessModal;
