import React, { useState } from "react";
import FormGenerator from "../../common/FormGenerator";
import RivianService from "../../../services/rivian-service";

const Oauth = () => {
  const [state, setState] = useState({
    _username: null,
    _password: null,
    _vin: null,
    _timezone: null,
    _useRemoteControl: true,
    _error: null,
    _loading: false,
  });

  const {
    _password,
    _timezone,
    _useRemoteControl,
    _username,
    _vin,
    _loading,
    _error,
  } = state;

  const _onSubmit = async (e) => {
    try {
      e.preventDefault();

      setState({ ...state, _loading: true, _error: null });

      await RivianService.loginRivian(_username, _password, _vin, true);

      setState({ ...state, _loading: false, _error: "Unable to generate Rivian access token. Status Code: 400" });
    } catch (err) {
      setState({
        ...state,
        _loading: false,
        _error: "Unable to process your request at this time!",
      });
    }
  };

  const _handleChange = (e) => {
    try {
      setState({ ...state, [e.target.name]: e.target.value });
    } catch (err) {}
  };

  return (
    <div className="p-3 card card-body text-dark">
      <h1>Connect Rivian Account</h1>

      <p>
        <strong>Note:</strong> Keemut is in beta for Rivian, we are still
        evaluating different features that we get from Rivian vehicles.
      </p>
      <p>
        We don't store your username or password. We obtain a Access Token from
        Rivian to poll data on the user's behalf.
      </p>
      <br />
      <FormGenerator
        onSubmit={_onSubmit}
        inputs={[
          {
            label: "Rivian Username",
            inputType: "text",
            id: "Rivian_onboard_username_textfield",
            required: true,
            placeholder: "Enter Rivian Username",
            name: "_username",
            value: _username,
            handleChange: _handleChange,
          },
          {
            label: "Rivian Password",
            inputType: "password",
            id: "Rivian_onboard_password_textfield",
            required: true,
            placeholder: "Enter Rivian Password",
            name: "_password",
            value: _password,
            handleChange: _handleChange,
          },

          {
            label: "Rivian VIN (if available)",
            inputType: "text",
            id: "Rivian_onboard_vin_textfield",
            required: true,
            placeholder: "Enter Rivian VIN",
            name: "_vin",
            value: _vin,
            handleChange: _handleChange,
          },
        ]}
        error={_error}
        loading={_loading}
      />

      <div className="mt-3"></div>
    </div>
  );
};

export default Oauth;
