import React, { useState } from "react";
import Chart from "react-apexcharts";
const PredictionVariables = () => {
  const [state, setState] = useState({
    loading: false,
    price: 0,
    options1: {
      colors: ["#7523E8"],
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["Driving Score"],
    },
    series1: [80],
    options2: {
      colors: ["#7523E8"],

      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["EV / Engine Score"],
    },
    series2: [95],
    options3: {
      colors: ["#7523E8"],

      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["Demand Score"],
    },
    series3: [60],
    options4: {
      colors: ["#7523E8"],

      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["Service Score"],
    },
    series4: [88],
  });
  const {
    options1,
    options2,
    options3,
    options4,
    series1,
    series2,
    series3,
    series4,
  } = state;

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class="  mdi mdi-bus-multiple widget-icon bg-primary-lighten text-primary"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">Driving Score</h5>
                <Chart options={options1} series={series1} type="radialBar" />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class=" mdi mdi-battery-charging-50  widget-icon bg-danger-lighten text-danger"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">Battery Score</h5>
                <Chart options={options2} series={series2} type="radialBar" />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class="  mdi mdi-google-analytics widget-icon bg-success-lighten text-success"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">Demand Score</h5>
                <Chart options={options3} series={series3} type="radialBar" />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class=" mdi mdi-progress-wrench widget-icon bg-warning-lighten text-warning"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">
                  Maintenance Score
                </h5>
                <Chart options={options4} series={series4} type="radialBar" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PredictionVariables;
