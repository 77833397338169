import simpleFetch from "../helpers/fetch-wrapper";

const RivianService = {
  loginRivian: async (username, password, vin) => {
    try {
      return await simpleFetch(
        "/rivian/auth",
        "POST",
        JSON.stringify({
          username,
          password,
          vin,
        })
      );
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  
};

export default RivianService;
