import React, { useEffect, useState } from 'react';
import TeslaFleetService from '../../../services/connected-cars/tesla-fleet-service';

export default function TeslaFleetTimeStats() {

  const [state, setState] = useState({
    _loading: true,
    _drivingTime: 0,
    _chargeTime: 0,
    _driverScore: 0,
    _units: null,
    _batteryLife: null,
    _wattPerMile: null,
    _driveEfficiency: null
  });
  const { _loading, _chargeTime, _driverScore, _drivingTime, _batteryLife, _units, _wattPerMile, _driveEfficiency } = state

  useEffect(() => {
    let mount = true;

    if (mount)
      _init()

    return function cleanup() {
      mount = false;
    }
  }, [])

  const _init = async () => {
    try {
      setState({ ...state, _loading: true })
      const data = await TeslaFleetService.getFleetStats()
    
      const result = data.result;
      const units = JSON.parse(localStorage.getItem("units"));
    
      if(units.distanceunit == "km") {
        result.watt_per_mile /= 1.60934;
      }
      setState({ ...state, _loading: false, _chargeTime: parseFloat(result.charging_time).toFixed(1), _drivingTime: parseFloat(result.drive_time).toFixed(1), _driverScore: parseInt(result.driving_score), _units: units, _wattPerMile: parseInt(result.watt_per_mile), _batteryLife: parseInt(result.battery_life), _driveEfficiency: parseInt(result.efficiency) })
    } catch (error) {
      console.log(error)
    }
  }


  return _loading ? <div className='spinner spinner-border'></div> : <> <div className="row mt-4">
    <div className="col-lg-4">
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class=" mdi mdi-speedometer-medium widget-icon bg-warning-lighten text-warning"></i>
          </div>
          <h3>Avg Driving Time Per Day</h3>
          <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
            <span className="h3">
              {_drivingTime}
            </span>{" "}
            hours
          </p>

          <p className="text-muted">
            This is the amount of time the vehicle is driving on average per day.
          </p>
        </div>
      </div>
    </div>

    <div className="col-lg-4">
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-battery-charging-medium widget-icon bg-primary-lighten text-primary"></i>
          </div>
          <h3>Avg Charging Time Per Day</h3>
          <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
            <span className="h3">
              {_chargeTime}
            </span>{" "}
            hours
          </p>

          <p className="text-muted">
            This is the amount of time the vehicle is charging on average per day.
          </p>
        </div>
      </div>
    </div>

    <div className="col-lg-4">
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class="  mdi mdi-timer-sand widget-icon bg-success-lighten text-success"></i>
          </div>
          <h3>Avg Driver Score</h3>
          <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
            <span className="h3">
              {_driverScore}
            </span>{" "}
            / 100
          </p>
          <p className="text-muted">
            This the duration the vehicle was in a drive state.
          </p>
        </div>
      </div>
    </div>
  </div>
    <div className="row ">
      <div className="col-lg-4">
        <div class="card widget-flat">
          <div class="card-body">
            <div class="float-right">
              <i class=" mdi mdi-battery-heart widget-icon bg-warning-lighten text-warning"></i>
            </div>
            <h3>Fleet Battery Health</h3>
            <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
              <span className="h3">
                {_batteryLife}
              </span>{" "}
              %
            </p>

            <p className="text-muted">
              This is the average battery health for your fleet.
            </p>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div class="card widget-flat">
          <div class="card-body">
            <div class="float-right">
              <i class="mdi mdi-engine widget-icon bg-primary-lighten text-primary"></i>
            </div>
            <h3>Fleet Wh/km</h3>
            <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
              <span className="h3">
                {_wattPerMile}
              </span>{" "}
              Wh/{_units.distanceunit}
            </p>

            <p className="text-muted">
              This is the average watt hours consumed per {_units.distanceunit} driven for your fleet.
            </p>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div class="card widget-flat">
          <div class="card-body">
            <div class="float-right">
              <i class="  mdi mdi-graph widget-icon bg-success-lighten text-success"></i>
            </div>
            <h3>Avg Drive Efficiency</h3>
            <p style={{ fontSize: "16px" }} class="mt-3 mb-3">
              <span className="h3">
                {_driveEfficiency}
              </span>{" "}
              %
            </p>
            <p className="text-muted">
              This the average drive efficiency of your fleet.
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
}