import simpleFetch from "../../helpers/fetch-wrapper";

const HondaService = {
  /**
   *
   * @param {String} username
   * @param {String} password
   * @returns
   */
  loginWithHonda: async (username, password) => {
    try {
      return await simpleFetch(
        "/honda/login",
        "POST",
        JSON.stringify({
          username: username,
          password,
        })
      );
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export default HondaService;
