import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import NumberFormat from "../../../helpers/number-format-helper";
import resolveCurrency from "../../../helpers/Helper";

const FleetNetRevenue = ({ units }) => {
  const [state, setState] = useState({
    netRevenue: null,
    loading: true,
    error: null,
  });
  const { error, loading, netRevenue } = state;
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getNetRevenue();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getNetRevenue = async () => {
    const token = localStorage.getItem("token");
    setState({ ...state, loading: true, error: null });
    try {
      const res = await fetch(`${API}/fleet/net-revenue`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await res.json();
      console.log(data);
      if (data.done) {
        setState({
          ...state,
          netRevenue:
            data.netRevenue &&
            NumberFormat(parseFloat(data.netRevenue).toFixed(2)),
          loading: false,
        });
      }
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        error: "Unable to process your fleet at the moment",
        loading: false,
      });
    }
  };

  if (loading) {
    return (
      <div class="card  widget-flat text-dark">
        <div class="card-body">
          <div class="float-right">
            <i class=" mdi mdi-briefcase widget-icon bg-success-lighten text-success  "></i>
          </div>
          <span>
            <strong> Revenue</strong>{" "}
          </span>
          <br />
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card  widget-flat text-dark">
      <div class="card-body">
        <div class="float-right">
          <i class=" mdi mdi-briefcase widget-icon bg-success-lighten text-success  "></i>
        </div>
        <span>
          <strong> Revenue</strong>{" "}
        </span>
        {error ? (
          <>
            {" "}
            <p className="text-danger">{error}</p>{" "}
          </>
        ) : (
          <>
            <h3 class="mt-3 mb-3">
              {" "}
              {resolveCurrency(units.currencyunit)}{" "}
              {netRevenue ? netRevenue : 0}
            </h3>
            <p>This is your net amount earned through the fleet.</p>
          </>
        )}
      </div>
    </div>
  );
};
export default FleetNetRevenue;
