/**
 * @author Hemang Bhogayata [hemang@dashroad.com]
 * @copyright Keemut LLC, See License.txt for more info
 */
import React, { useState, useEffect } from "react";
import Topbar from "../common/Topbar";
import Footer from "../common/Footer";
import { API } from "../../config";
import { Redirect } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Dropdown } from "semantic-ui-react";
import { STRIPE_PUBLISHABLE_KEY } from "../../config";
import PaymentForm from "./PaymentForm";
import SnackbarMessage from "../dashboard/common/SnackbarMessage";
import LoginDialog from "../login/LoginDialog";
import simpleFetch from "../../helpers/fetch-wrapper";

let stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const CheckoutPage = (props) => {
  const [state, setState] = useState({
    price: null,
    priceId: null,
    planName: null,
    loading: true,
    error: null,
    redirectToLogin: false,
    vin: null,
    clientSecret: null,
    showLoginDialog: false,
    make: null,
    model: null,
    numberOfTesla: 0,
    subscriptionData: {
      teslaSubscriptions: {
        priceId: "price_1Hr3mTCijcjUBhnezKYec7z2",
        quantity: 0,
        price: 4.99,
      },
      smartcarSubscriptions: {
        priceId: "price_1Hr3mTCijcjUBhnepBy44iSl",
        quantity: 0,
        price: 9.99,
      },
      rtaSubscription: {
        priceId: "price_1I1aeFCijcjUBhneQPQrCg1k",
        quantity: 0,
        price: 14.0,
      },
    },

    rtaEnabled: false,
    promoCode: null,
    isPromoValid: null,
    totalCost: null,
    showCouponSnackbar: false,
    discountAmount: 0,
  });

  const {
    make,
    model,
    price,
    priceId,
    planName,
    loading,
    error,
    redirectToLogin,
    vin,
    showLoginDialog,
    subscriptionData,
    clientSecret,
    numberOfTesla,
    rtaEnabled,
    promoCode,
    isPromoValid,
    totalCost,
    showCouponSnackbar,
    discountAmount,
  } = state;

  useEffect(async () => {
    let mounted = true;

    if (mounted) {
      const priceId = new URLSearchParams(props.location.search).get("priceId");
      const make = new URLSearchParams(props.location.search).get("make");
      const model = new URLSearchParams(props.location.search).get("model");

      const planName = new URLSearchParams(props.location.search).get(
        "planName"
      );
      const vin = new URLSearchParams(props.location.search).get("vin");
      const user = localStorage.getItem("user");
      const token = localStorage.getItem("token");

      // If user is not logged in make them login first
      if (!user || !token) {
        return setState({
          ...state,
          loading: false,
          showLoginDialog: true,
        });
      }

      // if subscription checkout
      if (priceId != "keemut-report") {
        getSubscriptionPlan(priceId, planName);
      } else {
        // if keemut report checkout
        getKeemutReport(priceId, vin, make, model);
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  /**
   * @author Hemang Bhogayata
   * @description Get the connected vehicles of user based on plan
   * @param {String} priceId
   * @param {String} planName
   */
  const getSubscriptionPlan = async (priceId, planName) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));

      // get user's connected vehicles
      const vdata = await simpleFetch(`/connected-vehicles/${user.uid}`, "GET");

      // get connected smartcars
      const smdata = await simpleFetch(`/smartcar/vehicles`, "GET");
      // update the quanitities
      subscriptionData.teslaSubscriptions.quantity = vdata.vehicles.length;
      subscriptionData.smartcarSubscriptions.quantity = smdata.vehicles.length;
      subscriptionData.rtaSubscription.quantity = vdata.vehicles.length;

      setState({
        ...state,
        loading: false,
        priceId,
        planName,
        totalCost:
          subscriptionData.teslaSubscriptions.quantity *
            subscriptionData.teslaSubscriptions.price +
          subscriptionData.smartcarSubscriptions.quantity *
            subscriptionData.smartcarSubscriptions.price +
          (rtaEnabled
            ? subscriptionData.rtaSubscription.quantity *
              subscriptionData.rtaSubscription.price
            : 0),
        subscriptionData,
      });
    } catch (err) {
      setState({
        ...state,
        error: "Unable to fetch the Subscription Plans at the moment",
        loading: false,
      });
    }
  };

  /**
   * @author Hemang Bhogayata
   * @description Get keemut report of vin
   * @param {String} priceId
   * @param {String} vin
   * @param {String} make
   * @param {String} model
   */
  const getKeemutReport = async (priceId, vin, make, model) => {
    try {
      const data = await simpleFetch(`/keemut-report/exists?vin=${vin}`, "GET");

      if (data.done) {
        // if keemut report is published then show
        if (data.keemutReport && data.keemutReport.published) {
          setState({
            ...state,
            loading: false,
            priceId,
            price: data.keemutReport.cost,

            totalCost: parseFloat(data.keemutReport.cost || 0),

            make,
            model,
            vin,
          });
        } else {
          // No such keemut report exists
          setState({
            ...state,
            error: "Unable to find any keemut reports associated with the vin",
            loading: false,
          });
        }
      } else {
        setState({
          ...state,
          error: "Unable to find any keemut reports associated with the vin",
          loading: false,
        });
      }
    } catch (err) {
      setState({
        ...state,
        error: "Unable to find any keemut reports associated with the vin",
        loading: false,
      });
    }
  };

  const verifyPromoCode = async (e) => {
    try {
      const res = await fetch(`${API}/is-promo-valid/${promoCode}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      const data = await res.json();
      console.log(data);

      if (data.valid) {
        setState({
          ...state,
          isPromoValid: true,
          totalCost: totalCost * (1 - data.promo.percent_off / 100),
          showCouponSnackbar: true,
          discountAmount: (data.promo.percent_off / 100) * totalCost,
        });
      } else {
        setState({
          ...state,
          isPromoValid: false,
          totalCost:
            subscriptionData.teslaSubscriptions.quantity *
              subscriptionData.teslaSubscriptions.price +
            subscriptionData.smartcarSubscriptions.quantity *
              subscriptionData.smartcarSubscriptions.price +
            (rtaEnabled
              ? subscriptionData.rtaSubscription.quantity *
                subscriptionData.rtaSubscription.price
              : 0),
          showCouponSnackbar: true,
          discountAmount: 0,
        });
      }
    } catch (err) {}
  };

  if (loading) {
    return (
      <div>
        <Topbar />
        <div className="spinner-border text-primary" role="status"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Topbar />
        <div
          class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
          role="alert"
        >
          <strong>Error - </strong> {error}
        </div>
      </div>
    );
  }

  if (redirectToLogin) {
    return <Redirect to="/login?onSuccessRedirectTo=pricing" />;
  }

  return (
    <div>
      {/* <Topbar /> */}
      <div className="p-4">
        <div className="row">
          <div className="col-lg-5">
            <div className="card card-body " style={{ height: "100%" }}>
              <div>
                <div className="float-left">
                  {priceId != "keemut-report" ? (
                    <h3>Subscription Plan</h3>
                  ) : (
                    <h3>Keemut Report</h3>
                  )}
                </div>
              </div>
              <br />
              {priceId != "keemut-report" && (
                <div className="form-group mt-2">
                  <label className="mr-3">Billing Duration</label>
                  <Dropdown
                    options={[
                      {
                        key: "monthly",
                        value: "monthly",
                        text: "Monthly",
                      },
                      {
                        key: "yearly",
                        value: "yearly",
                        text: "Yearly",
                      },
                    ]}
                    search
                    selection
                    placeholder="Choose billing duration"
                    onChange={(e, { value }) => {
                      if (value == "monthly") {
                        subscriptionData.teslaSubscriptions.priceId =
                          "price_1Hr3mTCijcjUBhnezKYec7z2";
                        subscriptionData.smartcarSubscriptions.priceId =
                          "price_1Hr3mTCijcjUBhnepBy44iSl";
                        subscriptionData.rtaSubscription.priceId =
                          "price_1I1aeFCijcjUBhneQPQrCg1k";

                        subscriptionData.teslaSubscriptions.price = 4.99;
                        subscriptionData.smartcarSubscriptions.price = 9.99;
                        subscriptionData.rtaSubscription.price = 14.0;
                      } else {
                        subscriptionData.teslaSubscriptions.priceId =
                          "price_1HrKYMCijcjUBhneNUdMmd4v";
                        subscriptionData.smartcarSubscriptions.priceId =
                          "price_1HrKYMCijcjUBhnexYabVhYY";
                        subscriptionData.rtaSubscription.priceId =
                          "price_1I1ag2CijcjUBhneSG99jLtn";

                        subscriptionData.teslaSubscriptions.price = 44.99;
                        subscriptionData.smartcarSubscriptions.price = 89.99;
                        subscriptionData.rtaSubscription.price = 168.0;
                      }

                      setState({
                        ...state,
                        totalCost:
                          subscriptionData.teslaSubscriptions.quantity *
                            subscriptionData.teslaSubscriptions.price +
                          subscriptionData.smartcarSubscriptions.quantity *
                            subscriptionData.smartcarSubscriptions.price +
                          (rtaEnabled
                            ? subscriptionData.rtaSubscription.quantity *
                              subscriptionData.rtaSubscription.price
                            : 0),
                        discountAmount: 0,
                      });
                    }}
                  />
                </div>
              )}
              <p className="text-muted text-bold">
                {priceId != "keemut-report" ? "" : `${make} ${model}`}
              </p>
              <p className="text-muted text-bold">
                {priceId != "keemut-report" ? "" : `VIN : ${vin}`}
              </p>

              {priceId != "keemut-report" && (
                <>
                  {" "}
                  <p>
                    <span>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={rtaEnabled}
                        onChange={(e) => {
                          let val = !rtaEnabled;
                          setState({
                            ...state,
                            rtaEnabled: !rtaEnabled,
                            totalCost:
                              subscriptionData.teslaSubscriptions.quantity *
                                subscriptionData.teslaSubscriptions.price +
                              subscriptionData.smartcarSubscriptions.quantity *
                                subscriptionData.smartcarSubscriptions.price +
                              (val
                                ? subscriptionData.rtaSubscription.quantity *
                                  subscriptionData.rtaSubscription.price
                                : 0),
                          });
                        }}
                      />
                    </span>
                    <span className="ml-2">
                      {" "}
                      <label>
                        RTA Add On (${subscriptionData.rtaSubscription.price} /
                        car / mo)
                      </label>
                    </span>
                  </p>
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="email"
                        id="emailaddress"
                        required="true"
                        placeholder="Coupon Code"
                        // name="email"
                        value={promoCode}
                        onChange={(e) =>
                          setState({ ...state, promoCode: e.target.value })
                        }
                      />
                      <button
                        className="btn btn-light"
                        onClick={verifyPromoCode}
                      >
                        Apply
                      </button>
                    </div>
                  </div>{" "}
                </>
              )}
              <br />

              {priceId != "keemut-report" && (
                <>
                  <div>
                    <div className="float-left">
                      <h4>
                        Tesla Vehicles x{" "}
                        {subscriptionData.teslaSubscriptions.quantity}
                      </h4>
                    </div>

                    <div className="float-right">
                      <h4>
                        $
                        {subscriptionData.teslaSubscriptions.quantity *
                          subscriptionData.teslaSubscriptions.price}
                      </h4>
                    </div>
                  </div>

                  <div className="mt-2">
                    <div className="float-left">
                      <h4>
                        Other Vehicles x{" "}
                        {subscriptionData.smartcarSubscriptions.quantity}
                      </h4>
                    </div>

                    <div className="float-right">
                      <h4>
                        ${" "}
                        {(
                          subscriptionData.smartcarSubscriptions.quantity *
                          subscriptionData.smartcarSubscriptions.price
                        ).toFixed(2)}
                      </h4>
                    </div>
                  </div>
                  {rtaEnabled && (
                    <div className="mt-2">
                      <div className="float-left">
                        <h4>RTA Add On</h4>
                      </div>

                      <div className="float-right">
                        <h4>
                          ${" "}
                          {rtaEnabled
                            ? (
                                subscriptionData.rtaSubscription.quantity *
                                subscriptionData.rtaSubscription.price
                              ).toFixed(2)
                            : 0.0}
                        </h4>
                      </div>
                    </div>
                  )}
                  <div className="mt-2">
                    <div className="float-left">
                      <h4>Subtotal</h4>
                    </div>

                    <div className="float-right">
                      <h4>$ {(totalCost + discountAmount).toFixed(2)}</h4>
                    </div>
                  </div>

                  <div className="mt-2">
                    <div className="float-left">
                      <h4>Discount</h4>
                    </div>

                    <div className="float-right">
                      <h4 className="text-danger">
                        $ - {discountAmount.toFixed(2)}
                      </h4>
                    </div>
                  </div>
                  <br />
                </>
              )}
              <hr />
              <div>
                <div className="float-left">
                  <h4>Total</h4>
                </div>

                <div className="float-right">
                  <h4> $ {totalCost && totalCost.toFixed(2)}</h4>
                </div>
              </div>

              <p className="text-muted mt-4">
                {priceId != "keemut-report"
                  ? ` You have a 14 day trial period where you can cancel your
                subscription at no cost. After the free trial period, you will
                be charged a monthly rate going forward automatically.`
                  : ` You have a 7 day access to the report once purchase, This is a live report and stats will keep on updating daily. After 7 days you will no longer be able to access this Keemut Report `}
              </p>
              <br />
              <p className="text-muted ">
                If you have any questions, please contact us at{" "}
                <a
                  href=""
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  hi@keemut.com
                </a>
              </p>
              <br />
            </div>
          </div>
          <div className="col-lg-7">
            {/* Here goes paymebt */}
            <Elements stripe={stripePromise}>
              <PaymentForm
                priceId={priceId}
                vin={vin}
                subscriptionData={subscriptionData}
              />
            </Elements>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="card card-body ">
              <div className="row px-3">
                <div className="col-lg-3 text-center">
                  <img
                    src={require("../../assets/animated/logbook-bw.svg")}
                    style={{ width: "100px", height: "100px" }}
                  />

                  <h4>You Control Your Data</h4>
                  <p>Decide what to keep and what to remove.</p>
                </div>
                <div className="col-lg-3 text-center">
                  <a
                    title="Realtime application protection"
                    href="https://www.sqreen.com/?utm_source=badge"
                  >
                    <img
                      style={{ height: "100px", width: "200px" }}
                      src="https://s3-eu-west-1.amazonaws.com/sqreen-assets/badges/20171107/sqreen-light-badge.svg"
                      alt="Sqreen | Runtime Application Protection"
                    />
                  </a>
                  <h4>We Invest In Security</h4>
                  <p>
                    We invest in security tools to ensure your data remains
                    secure
                  </p>
                </div>
                <div className="col-lg-3 text-center">
                  <img
                    src={require("../../assets/animated/loaf-heart-1.svg")}
                    style={{ width: "100px", height: "100px" }}
                  />
                  <h4>Customer Satisfaction</h4>
                  <p>Developing a product our customers love.</p>
                </div>
                <div className="col-lg-3 text-center">
                  <a
                    title="Payments Powered by Stripe"
                    href="https://www.stripe.com/"
                  >
                    <img
                      style={{ height: "100px", width: "200px" }}
                      src={require("../../assets/brands/stripe.svg")}
                      alt="Sqreen | Runtime Application Protection"
                    />
                  </a>
                  <h4>Payments Powered By Stripe</h4>
                  <p>
                    All payments on Keemut, are powered by Stripe. We do not
                    store your credit / debit card information with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SnackbarMessage
        open={showCouponSnackbar}
        onClose={() => setState({ ...state, showCouponSnackbar: false })}
        message={
          isPromoValid
            ? "The Coupon has been applied!"
            : "Invalid Coupon code, please try again!"
        }
      />

      <LoginDialog
        showLoginDialog={showLoginDialog}
        closeLoginDialog={() => {
          setState({ ...state, showLoginDialog: false });
        }}
      />
      {/* <Footer /> */}
    </div>
  );
};

export default CheckoutPage;
