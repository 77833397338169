import React, { useState, useEffect } from "react";
import resolveCurrency from "../../../helpers/Helper";
import { API } from "../../../config";
import {
  getCostPerMileService,
  getCostPerMonthService,
} from "../../../services/odometer-history-service";
import NumberFormat from "../../../helpers/number-format-helper";

const CostPerMileCard = ({ vehicle, units }) => {
  const [state, setState] = useState({
    loading: true,
    costPerMile: null,
    perChange: null,
    rawChange: null,
    totalCost: null,
    noData: false,
  });
  const { loading, totalCost, costPerMile, rawChange, perChange, noData } =
    state;

  useEffect(() => {
    const _getCostPerMileData = async () => {
      try {
        setState({ ...state, loading: true });
        const token = localStorage.getItem("token");
        const costPerMileCardJson = localStorage.getItem("costPerMileCard");
        const odoChange = localStorage.getItem("odoChange");

        if (odoChange === "false" && costPerMileCardJson) {
          const costPerMileCard = JSON.parse(costPerMileCardJson);

          if (vehicle.id === costPerMileCard.vehicleId)
            return setState({
              ...state,
              loading: false,
              totalCost: parseInt(costPerMileCard.totalCost),
              costPerMile: costPerMileCard.costPerMile,
              perChange: costPerMileCard.perChange,
              rawChange: costPerMileCard.rawChange,
            });
        }

        const data = await getCostPerMileService(vehicle.id);

        const resData = await getCostPerMonthService(vehicle.id);

        if (!resData.done || !data.done) {
          setState({ ...state, loading: false, noData: true });
        } else {
          localStorage.setItem(
            "costPerMileCard",
            JSON.stringify({
              vehicleId: vehicle.id,
              totalCost: parseInt(data.totalCost),
              costPerMile: data.costPerMile,
              perChange: resData.costChange.perChange,
              rawChange: resData.costChange.rawChange,
            })
          );
          setState({
            ...state,
            loading: false,
            costPerMile: data.costPerMile,
            totalCost: parseInt(data.totalCost),
            perChange: resData.costChange.perChange,
            rawChange: resData.costChange.rawChange,
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    _getCostPerMileData();
  }, [vehicle]);

  if (noData) {
    return (
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-speedometer-medium widget-icon bg-danger-lighten text-danger"></i>
          </div>
          <h4>Cost Summary</h4>
          <p>
            No Data Available.{" "}
            <a href="/dashboard/expense" style={{ color: "blue" }}>
              Add expenses
            </a>
            ,{" "}
            <a href="/dashboard/maintenance" style={{ color: "blue" }}>
              maintenance
            </a>{" "}
            and{" "}
            <a href="/dashboard/insurance" style={{ color: "blue" }}>
              insurance
            </a>{" "}
            reports to see more data.
          </p>
          <br />
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-speedometer-medium widget-icon bg-danger-lighten text-danger"></i>
          </div>
          <h3>Cost Summary</h3>
          <br />
          <br />
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card widget-flat">
      <div class="card-body">
        <div class="float-right">
          <i class="mdi mdi-cash-marker widget-icon bg-danger-lighten text-danger"></i>
        </div>
        <h3>Cost Summary</h3>
        <h3 class="mt-3 mb-3">
          {resolveCurrency(units.currencyunit.toUpperCase())}{" "}
          {costPerMile ? costPerMile.toFixed(3) : 0} Per {units.distanceunit}
        </h3>
        <p class="mb-0 text-muted">
          <span class={`text-success mr-2`}>
            <i class={`mdi mdi-arrow-up-bold`}></i>{" "}
            {resolveCurrency(units.currencyunit.toUpperCase())}{" "}
            <strong>{NumberFormat(totalCost || "0")}</strong>
          </span>
          <span class="text-nowrap">Spent Since Purchase</span>
        </p>
        <p class="mb-0 text-muted">
          <span class="text-success mr-2">
            <i class="mdi mdi-arrow-up-bold"></i>{" "}
            {resolveCurrency(units.currencyunit.toUpperCase())}{" "}
            {NumberFormat(Math.abs(rawChange || 0))}
          </span>
          <span class="text-nowrap">Spent This Month</span>
        </p>
      </div>
    </div>
  );
};

export default CostPerMileCard;
