import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { API } from "../../../config";
import Chart from "react-apexcharts";

const YearOverYearChart = () => {
  const [state, setState] = useState({
    makeOptions: [],
    makes: [],
    cumulativeDepreciation: [],
    selectedMakes: [],
    category: "All",
    options: {
      chart: {
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 3,
          color: "#000",
          opacity: 0.35,
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value + "%";
          },
        },
      },
      xaxis: {
        categories: [],
      },
      colors: [
        "#313a47",
        "#E44236",
        "#25CCF7",
        "#45CE30",
        "#FFF222",
        "#2B2B52",
        "#BB2CD9",
        "#E74292",
      ],

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
    loading: true,
    noData: false,
  });
  const {
    options,
    series,
    loading,
    make,
    category,
    noData,
    cumulativeDepreciation,
    selectedMakes,
    makeOptions,
  } = state;

  useEffect(() => {
    getDepreciation();
  }, []);

  useEffect(() => {
    let dataSeries = [];

    selectedMakes.forEach((make) => {
      cumulativeDepreciation.forEach((depreciation) => {
        if (depreciation.make === make && depreciation.category === category) {
          dataSeries.push(depreciation);
          return;
        }
      });
    });

    if (!dataSeries) {
      return;
    }
    let newSeries = [];
    dataSeries.forEach((data, i) => {
      const {
        avg1,
        avg2,
        avg3,
        avg4,
        avg5,
        std1,
        std2,
        std3,
        std4,
        std5,
      } = data;

      newSeries.push({
        type: "line",
        name: `${selectedMakes[i]} Dep.`,
        data: [
          0,
          avg1 ? parseFloat(avg1).toFixed(2) : 0,
          avg2 ? parseFloat(avg2).toFixed(2) : 0,
          avg3 ? parseFloat(avg3).toFixed(2) : 0,
          avg4 ? parseFloat(avg4).toFixed(2) : 0,
          avg5 ? parseFloat(avg5).toFixed(2) : 0,
        ],
      });
    });

    setState({
      ...state,

      loading: false,

      series: newSeries,
    });
  }, [selectedMakes, category]);

  const getMakeOptions = (makes) => {
    let options = [];

    makes.forEach((make) => {
      if (make !== "All Cars")
        options.push({ key: make, value: make, text: make });
    });
    return options;
  };

  const getDepreciation = () => {
    setState({ ...state, loading: true, noData: false });

    const token = localStorage.getItem("token");
    fetch(`${API}/cumulative-depreciation/all`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.cumulativeDepreciation) {
          setState({ ...state, loading: false, noData: true });
        } else {
          function compare(v1, v2) {
            const valueA =
              typeof v1["make"] === "string"
                ? v1["make"].toUpperCase()
                : v1["make"];
            const valueB =
              typeof v2["make"] === "string"
                ? v2["make"].toUpperCase()
                : v2["make"];

            let comparison = 0;
            if (valueA > valueB) {
              comparison = 1;
            } else if (valueA < valueB) {
              comparison = -1;
            }
            return comparison;
          }
          data.cumulativeDepreciation.sort(compare);
          const makes = new Set(
            data.cumulativeDepreciation.map((item) => {
              return item.make;
            })
          );
          const {
            avg1,
            avg2,
            avg3,
            avg4,
            avg5,
            std1,
            std2,
            std3,
            std4,
            std5,
          } = data.cumulativeDepreciation[0];
          setState({
            ...state,
            cumulativeDepreciation: data.cumulativeDepreciation,
            makes,
            selectedMakes: ["Honda"],
            makeOptions: getMakeOptions(makes),
            loading: false,
            options: {
              ...options,
              yaxis: { title: { text: "Cumulative % Depreciation" } },
              xaxis: {
                type: "Age, Years",
                categories: [0, 1, 2, 3, 4, 5],
                labels: {
                  formatter: function (value) {
                    return value + " Year";
                  },
                },
                title: { text: "Car Age ( Years )" },
              },
            },
            series: [
              {
                type: "line",
                name: "Depreciation (%)",
                data: [
                  0,
                  avg1 ? parseFloat(avg1).toFixed(2) : 0,
                  avg2 ? parseFloat(avg2).toFixed(2) : 0,
                  avg3 ? parseFloat(avg3).toFixed(2) : 0,
                  avg4 ? parseFloat(avg4).toFixed(2) : 0,
                  avg5 ? parseFloat(avg5).toFixed(2) : 0,
                ],
              },
            ],
          });
        }
      });
  };

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  if (loading) {
    return (
      <div class="card">
        <div class="card-body">
          <h4>Total Depreciation </h4>

          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }
  return (
    <div class="card">
      <div class="card-body">
        <h4>Total Depreciation</h4>
        <br />
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Make</label>
              <Dropdown
                className="form-control select2"
                placeholder="Vehicle Make"
                fluid
                multiple
                selection
                options={makeOptions}
                onChange={(e, { value }) => {
                  setState({ ...state, selectedMakes: value });
                }}
              />
            </div>
          </div>{" "}
          <div className="col-lg-6">
            <div className="form-group">
              <label>Category</label>

              <select
                class="form-control select2"
                data-toggle="select2"
                name="category"
                onChange={handleChange}
                value={category}
              >
                {" "}
                <option value="All">All</option>
                <option value="Coupe">Coupe</option>
                <option value="Minivan">Minivan</option>
                <option value="Pickup">Pickup</option>
                <option value="Sedan">Sedan</option>
                <option value="Subcompact">Subcompact</option>
                <option value="Small SUV">Small SUV</option>
                <option value="SUV">SUV</option>
              </select>
            </div>
          </div>
        </div>
        <Chart options={options} series={series} />
      </div>
    </div>
  );
};

export default YearOverYearChart;
