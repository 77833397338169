import React, { useState } from "react";
import Topbar from "../common/Topbar";
import { Dropdown } from "semantic-ui-react";

const Pricing = () => {
  const [state, setState] = useState({
    showStripeCheckout: false,
    plan: "monthly",
    priceTesla: 4.99,
    priceSmartcar: 9.99,
  });
  const { showStripeCheckout, plan, priceTesla, priceSmartcar } = state;

  return (
    <>
      <Topbar />

      <section class="pricing py-5">
        <div class="container">
          <div className="text-center">
            <h1 className="h1 display-4">Pricing</h1>
            <p>Sign Up today for a 15 day free trial</p>
          </div>
          <div className="form-group">
            <label className="mr-2">Pricing Plan : </label>
            <Dropdown
              selection
              options={[
                {
                  key: "monthly",
                  value: "monthly",
                  text: "Monthly",
                },
                {
                  key: "yearly",
                  value: "yearly",
                  text: "Annual",
                },
              ]}
              value={plan}
              onChange={(e, { value }) => {
                console.log(value);
                setState({
                  ...state,
                  plan: value,
                  priceTesla: value == "monthly" ? 4.99 : 44.99,
                  priceSmartcar: value == "monthly" ? 9.99 : 89.99,
                });
              }}
            />
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">
                    FREE
                  </h5>
                  <h6 class="h4   text-center">
                    $0.00 <span class="period">/ mo / car</span>
                  </h6>
                  <hr />
                  <ul class="fa-ul">
                    <li>
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Unlimited Vehicles
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Connected Car Data <strong> ( 7 days history) </strong>
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Maintenance Log + Analytics
                    </li>
                    <li className="text-muted">
                      <span class="fa-li">
                        <i class=" mdi mdi-close-octagon text-danger mr-2"></i>
                      </span>
                      Depreciation Analytics
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="mdi mdi-close-octagon text-danger mr-2"></i>
                      </span>
                      Battery Life Predictor
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="mdi mdi-close-octagon text-danger mr-2"></i>
                      </span>
                      Keemut Report
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="  mdi mdi-close-octagon text-danger mr-2"></i>
                      </span>
                      High Mileage Battery AI
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="  mdi mdi-close-octagon text-danger mr-2"></i>
                      </span>
                      Heatmap
                    </li>{" "}
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="  mdi mdi-close-octagon text-danger mr-2"></i>
                      </span>
                      Operations Analytics (Fleet)
                    </li>{" "}
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="  mdi mdi-close-octagon text-danger mr-2"></i>
                      </span>
                      Custom Features
                    </li>
                  </ul>
                  <a
                    class="btn btn-block btn-primary text-uppercase"
                    href="/dashboard/overview"
                    style={{
                      color: "#fff",
                      backgroundColor: "#5738a0",
                      borderColor: "#5738a0",
                    }}
                  >
                    Proceed
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">
                    PREMIUM
                  </h5>
                  <h6 class="h4  text-center">
                    ${priceTesla} - ${priceSmartcar}{" "}
                    <span class="period">
                      / {plan == "monthly" ? "mo" : "yr"} / car
                    </span>
                  </h6>
                  <hr />
                  <ul class="fa-ul">
                    <li>
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Unlimited Vehicles
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Connected Car Data <strong> ( All history) </strong>
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Maintenance Log + Analytics
                    </li>
                    <li className="">
                      <span class="fa-li">
                        <i class="  mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Depreciation Analytics
                    </li>
                    <li class="">
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Battery Life Predictor
                    </li>
                    <li class="">
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Keemut Report
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="  mdi mdi-close-octagon text-danger mr-2"></i>
                      </span>
                      High Mileage Battery AI
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="  mdi mdi-close-octagon text-danger mr-2"></i>
                      </span>
                      Heatmap
                    </li>{" "}
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="  mdi mdi-close-octagon text-danger mr-2"></i>
                      </span>
                      Operations Analytics (Fleet)
                    </li>{" "}
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="  mdi mdi-close-octagon text-danger mr-2"></i>
                      </span>
                      Custom Features
                    </li>
                  </ul>
                  <a
                    class="btn btn-block btn-primary text-uppercase"
                    href="/checkout/?priceId=price_1HMtksCijcjUBhneO0sotov5&planName=Premium"
                  >
                    Proceed
                  </a>
                  <br />
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">
                    Fleet
                  </h5>
                  <h6 class="h4   text-center">Custom Pricing</h6>
                  <hr />
                  <ul class="fa-ul">
                    <li>
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Unlimited Vehicles
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Connected Car Data <strong> ( All history) </strong>
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Maintenance Log + Analytics
                    </li>
                    <li className="">
                      <span class="fa-li">
                        <i class="  mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Depreciation Analytics
                    </li>
                    <li class="">
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Battery Life Predictor
                    </li>
                    <li class="">
                      <span class="fa-li">
                        <i class=" mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Keemut Report
                    </li>
                    <li class="">
                      <span class="fa-li">
                        <i class="  mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      High Mileage Battery AI
                    </li>
                    <li class="">
                      <span class="fa-li">
                        <i class="  mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Heatmap
                    </li>{" "}
                    <li class="">
                      <span class="fa-li">
                        <i class="  mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Operations Analytics (Fleet)
                    </li>{" "}
                    <li class="">
                      <span class="fa-li">
                        <i class="  mdi mdi-check-underline-circle text-success mr-2"></i>
                      </span>
                      Custom Features
                    </li>
                  </ul>
                  <a
                    href="https://keemut.com/contact"
                    class="btn btn-block btn-primary text-uppercase"
                  >
                    Contact Us
                  </a>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
