import React from "react";

const VehicleInformation = ({ vehicle }) => {
  return (
    <div className="card card-body" style={{ height: "100%" }}>
      <h4>Vehicle Information</h4>
      <div className="my-3">
        <p>
          {" "}
          <strong>Name : </strong> {vehicle.name}{" "}
        </p>

        <p>
          {" "}
          <strong>Make : </strong> {vehicle.make}{" "}
        </p>
        <p>
          {" "}
          <strong>Model : </strong> {vehicle.model}{" "}
        </p>
        <p>
          {" "}
          <strong>Trim : </strong> {vehicle.trim}{" "}
        </p>
        <p>
          {" "}
          <strong>Year : </strong> {vehicle.year}{" "}
        </p>
      </div>
    </div>
  );
};

export default VehicleInformation;
