import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { API } from "../../../config";
import moment from "moment";
import NumberFormat from "../../../helpers/number-format-helper";

const RollbackDialog = ({
  showRollbackDialog,
  cancelRollback,
  proceedRollback,
  lastOdo,
  units,
}) => {
  return (
    <Dialog
      open={showRollbackDialog}
      onClose={cancelRollback}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div class="modal-content">
        <div class="modal-header modal-colored-header bg-primary">
          <h4 class="modal-title" id="danger-header-modalLabel">
            You are about to Rollback
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={cancelRollback}
          >
            ×
          </button>
        </div>
        <div class="modal-body">
          <p style={{ fontSize: "14px" }}>
            Based on your earlier report, Your odometer was{" "}
            <strong>
              {lastOdo && NumberFormat(lastOdo, units.country_code)}{" "}
              {units ? units.distanceunit : "mi"}{" "}
            </strong>
            . You are about to perform a rollback on your odometer, Are you sure
            ? <br /> <br />
            <small className="text-muted">
              {" "}
              Note : This may affect your Keemut Valuation. It is highly advised
              to never perform a rollback.
            </small>{" "}
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-dismiss="modal"
            onClick={proceedRollback}
          >
            Proceed With Rollback
          </button>
          <button
            type="button"
            class="btn btn-primary"
            onClick={cancelRollback}
          >
            Go Back
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default RollbackDialog;
