import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
const PaymentSettings = () => {
  const [state, setState] = useState({
    currentPlan: null,
    subscriptionId: null,
    currentStatus: null,
    nextBillingDate: null,
    billingAmount: null,
    subscription_id: null,
    error: null,
    loading: true,
    showCancelDialog: false,
    canceling: false,
    canceled: false,
    showCancelMessage: false,
  });
  const {
    currentPlan,
    currentStatus,
    subscriptionId,
    nextBillingDate,
    billingAmount,
    subscription_id,
    error,
    loading,
    showCancelDialog,
    canceling,
    canceled,
    showCancelMessage,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getSubscription();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getSubscription = async () => {
    try {
      setState({ ...state, loading: true });
      const user = JSON.parse(localStorage.getItem("user"));

      if (!user.subscription_id) {
        setState({
          ...state,
          currentStatus: "Active",
          currentPlan: "Keemut Basic",
          subscriptionId: "N/A",
          nextBillingDate: "N/A",
          billingAmount: "$0.00",
          subscription_id: "N/A",
          loading: false,
        });
      } else {
        const res = await fetch(`${API}/subscription/${user.subscription_id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
        });

        const data = await res.json();
        if (data.done) {
          setState({
            ...state,
            showCancelDialog: false,
            nextBillingDate: moment
              .unix(data.subscription.current_period_end)
              .format("DD-MMM-YYYY"),
            billingAmount: "$" + data.subscription.amount_due,
            subscription_id: user.subscription_id,
            currentPlan: "Keemut Premium",
            currentStatus: data.subscription.status.toUpperCase(),
            loading: false,
          });
        } else {
          setState({
            ...state,
            showCancelDialog: false,
            loading: false,
            error: "Unable to fetch your subscription at this time",
          });
        }
      }
    } catch (err) {
      setState({
        ...state,
        loading: false,
        showCancelDialog: false,
        error: "Unable to fetch your subscription at this time",
      });
    }
  };

  const cancelSubscription = async () => {
    try {
      const res = await fetch(`${API}/subscription/${subscription_id}`, {
        method: "DELETE",
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const data = await res.json();

      if (data.done) {
        getSubscription();
      } else {
        setState({ ...state, canceled: false, showCancelMessage: true });
      }
    } catch (err) {}
  };

  if (loading) {
    return (
      <>
        {" "}
        <div className="row">
          <div classNa me="col-lg-12">
            <div className="card card-body">
              <h4>Subscription Information</h4>
              <div className="spinner-border" role="status"></div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-body">
            <h4>Subscription Information</h4>
            {error && (
              <div
                class="alert my-2 alert-danger alert-dismissible bg-danger text-white border-0 fade show"
                role="alert"
              >
                <strong>Error - </strong> {error}
              </div>
            )}
            <div className="row mt-3">
              <div className="col-lg-3">
                <h5>Current Plan: </h5>
              </div>
              <div className="col-lg-4">
                <h5 className=" text-success">{currentPlan} </h5>{" "}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3">
                <h5>Current Status: </h5>
              </div>
              <div className="col-lg-4">
                <h5>{currentStatus} </h5>{" "}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3">
                <h5> Subscription Id:</h5>
              </div>
              <div className="col-lg-4">
                <h5>{subscription_id}</h5>{" "}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3">
                <h5> Next Billing:</h5>
              </div>
              <div className="col-lg-4">
                <h5>{nextBillingDate}</h5>{" "}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-3">
                <h5>Billing Amount</h5>
              </div>
              <div className="col-lg-4">
                <h5>{billingAmount}</h5>{" "}
              </div>
            </div>

            <div className="row my-4">
              <div className="col-lg-4">
                <button
                  className="btn btn-light"
                  disabled={canceled}
                  onClick={() => setState({ ...state, showCancelDialog: true })}
                >
                  Cancel Subscription
                </button>
              </div>
              <div className="col-lg-4">
                <a
                  href="/pricing"
                  className="btn btn-primary"
                  disabled={canceled}
                >
                  Upgrade Subscription
                </a>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={showCancelDialog}
          onClose={() => setState({ ...state, showCancelDialog: false })}
        >
          <div class="modal-content">
            <div class="modal-header modal-colored-header bg-primary">
              <h4 class="modal-title" id="primary-header-modalLabel">
                Are you sure you want to cancel ?
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => setState({ ...state, showCancelDialog: false })}
              >
                ×
              </button>
            </div>
            <div class="modal-body dialog">
              <p>
                Your subscription will be canceled and your will be downgraded
                to Basic Version at the end of current subscription.
              </p>
            </div>
            <div class="modal-footer mt-4">
              <button
                type="button"
                class="btn btn-light"
                data-dismiss="modal"
                onClick={() => setState({ ...state, showCancelDialog: false })}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={cancelSubscription}
              >
                Confirm
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default PaymentSettings;
