import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { API } from "../../../config";
import Chart from "react-apexcharts";
import moment from "moment";
const TuroTool = () => {
  const [state, setState] = useState({
    _city: "Atlanta",
    _loading: false,
    _makeOptions: [],
    _make: null,
    _model: null,
    _submitting: false,
    _modelOptions: [],
    _options: {
      chart: {
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 5,
          color: "#00274c",
          opacity: 0.45,
        },
      },
      xaxis: {},
      colors: ["#00274c"],

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 0.5,
        dashArray: 0,
      },
    },
    _series: [
      {
        name: "series-1",
        data: [],
      },
    ],

    _options1: {
      chart: {
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 0.5,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 5,
          color: "#00274c",
          opacity: 0.45,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
      },
      yaxis: { title: { text: "Amount" } },
      xaxis: {
        categories: [],
      },
      colors: ["#00274c"],
    },
    _series1: [
      {
        name: "series-1",
        data: [],
      },
    ],

    _options2: {
      chart: {
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 5,
          color: "#00274c",
          opacity: 0.45,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
      },
      yaxis: { title: { text: "Amount" } },
      xaxis: {
        categories: [],
      },
      colors: ["#00274c"],
    },
    _series2: [
      {
        name: "series-2",
        data: [],
      },
    ],
    _error: null,
  });
  const {
    _city,
    _error,
    _loading,
    _make,
    _makeOptions,
    _submitting,
    _modelOptions,
    _model,
    _options1,
    _series1,
    _options,
    _options2,
    _series2,
    _series,
  } = state;

  useEffect(() => {
    let mounted = true;

    const getMakeList = () => {
      fetch(`${API}/vehicle-list/make`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          let makeOptions = [];
          data.makeList.forEach((m) => {
            if (m.make !== "All")
              makeOptions.push({ key: m.make, value: m.make, text: m.make });
          });
          setState({
            ...state,
            _loading: false,
            _makeOptions: makeOptions,
          });
        })
        .catch((err) => console.log(err));
    };
    if (mounted) {
      getMakeList();
    }

    return function cleanup() {
      mounted = true;
    };
  }, []);

  const getModelList = async (make) => {
    fetch(`${API}/vehicle-list/model?make=${make}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let modelOptions = [];
        data.modelList.forEach((m) => {
          modelOptions.push({ key: m.model, value: m.model, text: m.model });
        });
        setState({
          ...state,
          _loading: false,
          _make: make,
          _modelOptions: modelOptions,
        });
      })
      .catch((err) => console.log(err));
  };

  const _onSubmit = async () => {
    try {
      if (!_make || !_model) {
        return setState({
          ...state,
          _submitting: false,
          _error: "Please Select a make and model",
        });
      }

      setState({ ...state, _submitting: true, _error: null });
      const res = await fetch(`${API}/turo/city`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ city: _city, make: _make }),
      });

      const mres = await fetch(`${API}/turo/model`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ city: _city, model: _model }),
      });

      const cres = await fetch(`${API}/turo/model`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ model: _model }),
      });

      const mdata = await mres.json();
      const data = await res.json();
      const cdata = await cres.json();

      let xaxis = [];
      let yaxis = [];
      let models = [];
      const seriesData = [{ data: [] }];
      let i = 0;
      console.log(data);
      if (!data.done || data.turoData.length == 0) {
        return setState({
          ...state,
          _error: "Unable to find any price for the selected city and vehicle",
          _submitting: false,
        });
      }

      data.turoData.forEach((row) => {
        models.push(row.model);
        seriesData[0].data.push({
          x: row.model,
          y: [row.min, row.max],
        });
        i++;
      });

      let dates = [];

      let candleData = [];

      mdata.turoData.forEach((row) => {
        let index = dates.indexOf(row.date);
        if (index < 0) {
          xaxis.push(moment(row.date).format("DD-MMM-YY"));
          yaxis.push(row.price_per_day);
          dates.push(row.date);
        }
      });

      let cxaxis = [];
      let cyaxis = [];
      let cities = [];
      cdata.turoData.forEach((row) => {
        let index = dates.indexOf(row.location);
        if (index < 0) {
          cxaxis.push(row.location);
          cyaxis.push(row.price_per_day && parseInt(row.price_per_day));
          cities.push(row.location);
        }
      });

      setState({
        ...state,
        _options: {
          ..._options,
          yaxis: {
            type: "Amount",
            title: { text: "Price Per Day ($/day)" },
          },

          xaxis: {
            type: "Model",

            title: { text: "Model" },
          },
        },

        _options1: {
          ..._options1,
          xaxis: {
            type: "date-time",
            categories: xaxis,
          },
        },
        _series: seriesData,
        _series1: [
          {
            name: "Amount",
            data: yaxis,
          },
        ],

        _options2: {
          ..._options2,
          xaxis: {
            type: "date-time",
            categories: cxaxis,
          },
        },
        _series2: [
          {
            name: "Price Per Day",
            data: cyaxis,
          },
        ],
        _submitting: false,
        _error: null,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="card card-body">
        <h3>Turo Pricing Tool</h3>

        <div className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <label className="mr-3">City</label>
              <br />
              <Dropdown
                search
                selection
                value={_city}
                onChange={(e, { value }) =>
                  setState({ ...state, _city: value })
                }
                options={[
                  { value: "Atlanta", text: "Atlanta", key: "Atlanta" },
                  { value: "Austin", text: "Austin", key: "Austin" },
                  { value: "Boston", text: "Boston", key: "Boston" },
                  { value: "Chicago", text: "Chicago", key: "Chicago" },
                  { value: "Denver", text: "Denver", key: "Denver" },
                  { value: "Florida", text: "Florida", key: "Florida" },
                  { value: "Honolulu", text: "Honolulu", key: "Honolulu" },
                  { value: "Las Vegas", text: "Las Vegas", key: "Las Vegas" },
                  {
                    value: "Los Angeles",
                    text: "Los Angeles",
                    key: "Los Angeles",
                  },
                  {
                    value: "Mary Esther",
                    text: "Mary Esther",
                    key: "Mary Esther",
                  },

                  { value: "Miami", text: "Miami", key: "Miami" },
                  {
                    value: "New Jersey",
                    text: "New Jersey",
                    key: "New Jersey",
                  },

                  { value: "New York", text: "New York", key: "New York" },

                  { value: "Orlando", text: "Orlando", key: "Orlando" },
                  { value: "Phoenix", text: "Phoenix", key: "Phoenix" },

                  { value: "Portland", text: "Portland", key: "Portland" },
                  { value: "San Diego", text: "San Diego", key: "San Diego" },
                  {
                    value: "San Francisco",
                    text: "San Francisco",
                    key: "San Francisco",
                  },
                  { value: "Seattle", text: "Seattle", key: "Seattle" },
                  {
                    value: "Washington DC",
                    text: "Washington DC",
                    key: "Washington DC",
                  },
                ]}
                fluid
              />
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group">
              <label>Select Make</label>
              <br />
              <Dropdown
                fluid
                search
                selection
                options={_makeOptions}
                value={_make}
                onChange={(e, { value }) => {
                  getModelList(value);
                }}
              />
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group">
              <label>Select Model</label>
              <br />
              <Dropdown
                search
                selection
                options={_modelOptions}
                value={_model}
                onChange={(e, { value }) => {
                  setState({ ...state, _model: value });
                }}
                fluid
              />
            </div>
          </div>
        </div>

        <button className="btn btn-primary mt-3" onClick={_onSubmit}>
          {_submitting ? (
            <>
              {" "}
              <span
                class="spinner-grow spinner-grow-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...{" "}
            </>
          ) : (
            "Submit"
          )}
        </button>
      </div>

      {_error && (
        <div
          className=" my-3 alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
          role="alert"
        >
          <strong>Error - </strong> {_error}
        </div>
      )}

      <div className="row">
        <div className="col-lg-6">
          <div className="card card-body">
            <h4>
              City Comparison For {_make} {_model}
            </h4>
            {_submitting ? (
              <div
                className="spinner spinner-border spinner-center"
                role="status"
              />
            ) : (
              <Chart options={_options2} type="bar" series={_series2} />
            )}
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card card-body">
            <h4>
              Price Trend For {_make} {_model}
            </h4>
            {_submitting ? (
              <div
                className="spinner spinner-border spinner-center text-primary"
                role="status"
              />
            ) : (
              <Chart options={_options1} type="line" series={_series1} />
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card card-body">
            <h4> {_make} Price Ranges</h4>
            {_submitting ? (
              <div
                className="spinner spinner-border spinner-center text-primary"
                role="status"
              />
            ) : (
              <Chart options={_options} type="rangeBar" series={_series} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TuroTool;
