import React, { useEffect } from "react";
import * as d3 from "d3v4";
import NumberFormat from "../../helpers/number-format-helper";
import moment from "moment";
import resolveCurrency from "../../helpers/Helper";
const ConfidenceChart = ({
  data,
  id,
  domainX,
  domainY,
  years,
  ml2Data,
  dashedMl2Data,
  vin,
  currencyUnit,
  dashedData,
  areaData,
  dates
}) => {
  useEffect(() => {
    // console.log(data);
    document.getElementById(id).innerHTML = "";
    var margin = { top: 10, right: 30, bottom: 30, left: 100 },
      width = 950 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3
      .select(`#${id}`)
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("viewBox", "0 0 960 500")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // create the x axis with domain as domainX and range of 0 -> width
    var x = d3
      .scaleLinear()
      .domain(domainX ? domainX : [1, 10])
      .range([0, width]);
    // append the axis bottom as x
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(
        d3
          .axisBottom(x)
          .ticks(years.length)
          .tickFormat(function (d, i) {
            // month is not reset back to 1 after 12 rather continued as 13, 14, 15... so just subtract 12 * n where n is the quotient of n / 12
            let n = Math.floor(d / 12);
            if (d > 12) {
              d -= n * 12;
            }
            return moment(dates[i]).format("DD-MMM-YY")
          })
      )
      .selectAll("text")
      .attr("y", 0)
      .attr("x", 9)
      .attr("dy", ".35em")
      .attr("transform", "rotate(90)")
      .attr("transform", "rotate(90)")
      .style("text-anchor", "start")
      .style("margin-bottom", "100px");

    // create the y axis with domain as domainY and range of height -> 0
    var y = d3
      .scaleLinear()
      .domain(domainY ? domainY : [0, 100000])
      .range([height, 0]);

    // Append the y axis as axisLeft
    svg
      .append("g")
      .call(
        d3
          .axisLeft(y)
          .tickFormat(
            (d, i) => resolveCurrency(currencyUnit) + " " + NumberFormat(d)
          )
      );


    if (ml2Data) {
      // console.log(ml2Data);
      svg
        .append("path")
        .datum(ml2Data)
        .attr("fill", "none")
        .attr("stroke", "#6236FF")
        .attr("stroke-width", 1.5)
        .attr(
          "d",
          d3
            .line()
            .curve(d3.curveNatural)
            .x(function (d, i) {
              if (!ml2Data[i]) return;

              return x(ml2Data[i].x);
            })
            .y(function (d, i) {
              if (!ml2Data[i]) return;
              return y(ml2Data[i].y);
            })
        );

      svg
        .append("path")
        .datum(dashedMl2Data)
        .style("stroke-dasharray", "3, 3")
        .attr("fill", "none")
        .attr("stroke", "#6236FF")
        .attr("stroke-width", 1.5)
        .attr(
          "d",
          d3
            .line()
            .curve(d3.curveNatural)
            .x(function (d) {
              return x(d.x);
            })
            .y(function (d) {
              return y(d.y);
            })
        );
    }


    // Add a area bounding the upper and lower range
    if (areaData)
      svg
        .append("path")
        .datum(areaData)
        .attr("fill", "#fad2663b")
        .attr("stroke", "none")
        .attr(
          "d",
          d3
            .area()
            .curve(d3.curveNatural)
            .x(function (d) {
              return x(d.x);
            })
            .y0(function (d) {
              return y(d.upper);
            })
            .y1(function (d) {
              return y(d.lower);
            })
        );

    // Create the tooltip as a div and append to container
    var tooltip = d3
      .select(`#${id}`)
      .append("div")
      .style("position", "absolute")
      .style("z-index", "10")
      .style("visibility", "hidden")
      .style("background", "#eef2f7")
      .text("Hello there");

    // Add the line that plots the exact values y
    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#F7B500")
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3

          .line()
          .curve(d3.curveNatural)
          .x(function (d) {
            return x(d.x);
          })
          .y(function (d) {
            return y(d.y);
          })
      );

    if (dashedData) {
      svg
        .append("path")
        .datum(dashedData)
        .style("stroke-dasharray", "3, 3")
        .attr("fill", "none")
        .attr("stroke", "#F7B500")
        .attr("stroke-width", 1.5)
        .attr(
          "d",
          d3
            .line()
            .curve(d3.curveNatural)
            .x(function (d) {
              return x(d.x);
            })
            .y(function (d) {
              return y(d.y);
            })
        );
    }

    // Add the scatter dots on the line to show the data points individually
    svg
      .append("g")
      .selectAll("dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", function (d) {
        return x(d.x);
      })
      .attr("cy", function (d) {
        return y(d.y);
      })
      .attr("r", 4.5)
      .style("fill", "#F7B500")
      .style("cursor", "pointer")
      .on("mouseover", function (d, i) {
        if (d.x > 12) {
          // console.log(d.x, Math.floor(parseInt(d.x) / 12));
          d.x -= Math.floor(parseInt(d.x) / 12) * 12;
        }

        // Display the tooltip at the mouse position
        let mousePos = d3.mouse(this);

        tooltip
          .style("top", mousePos[1] + "px")
          .style("left", mousePos[0] + "px")
          .style("width", "350px")
          .style("height", "100")
          .style("padding", "15px").html(`
          ${
            d.ml2
              ? `<p><strong>${vin} Connected Car Price </strong> : ${resolveCurrency(
                  currencyUnit
                )} ${NumberFormat(parseInt(d.ml2))}  </p>`
              : ``
          }
            <p><strong>General Valuation </strong> : ${resolveCurrency(
              currencyUnit
            )} ${NumberFormat(parseInt(d.y))}  </p>
            <p><strong>Month</strong>: ${
              moment(d.x, "M").format("MMM") + " " + (years[i] || 2020)
            }</p>
          
          `);

        // chanage visibility style to visible
        return tooltip.style("visibility", "visible");
      })
      .on("mouseout", function (d, i) {
        // on mouseout make the tooltip hide
        return tooltip.style("visibility", "hidden");
      });

    svg
      .append("circle")
      .attr("cx", 200)
      .attr("cy", 460)
      .attr("r", 6)
      .style("fill", "#F7B500");

    svg
      .append("text")
      .attr("x", 220)
      .attr("y", 460)
      .text("General Valuation")
      .style("font-size", "15px")
      .attr("alignment-baseline", "middle");

    svg
      .append("circle")
      .attr("cx", 375)
      .attr("cy", 460)
      .attr("r", 6)
      .style("fill", "#6236FF");

    svg
      .append("text")
      .attr("x", 395)
      .attr("y", 460)
      .text(`${vin} Connected Car Valuation`)
      .style("font-size", "15px")
      .attr("alignment-baseline", "middle");

    svg
      .append("line")
      .attr("x1", 200)
      .attr("x2", 210)
      .attr("y1", 485)
      .attr("y2", 485)
      .style("stroke-dasharray", "5,5") //dashed array for line
      .style("stroke", "#000000");

    svg
      .append("line")
      .attr("x1", 215)
      .attr("x2", 225)
      .attr("y1", 485)
      .attr("y2", 485)
      .style("stroke-dasharray", "5,5") //dashed array for line
      .style("stroke", "#000000");

    svg
      .append("text")
      .attr("x", 245)
      .attr("y", 485)
      .text(`Predicted Data`)
      .style("font-size", "15px")
      .attr("alignment-baseline", "middle");

    // Add the x axis labels
    svg
      .append("text")
      .attr("class", "x label")
      .attr("text-anchor", "end")
      .attr("x", width)
      .attr("y", height - 6)
      .text("");

    // Add the y axis labels
    svg
      .append("text")
      .attr("class", "y label")
      .attr("text-anchor", "end")
      .attr("y", 20)
      .attr("dy", ".75em")
      .attr("transform", "rotate(-90)")
      .text("");
  }, [data]);

  return <div id={id}></div>;
};

export default ConfidenceChart;
