import { Dialog } from "@material-ui/core";
import React from "react";

const SubscriptionDialog = ({ showSubscriptionMessage, onClose }) => {
  return (
    <Dialog open={showSubscriptionMessage}>
      <div class="modal-content" style={{ width: "500px" }}>
        <div class="modal-header modal-colored-header bg-primary">
          <h4 class="modal-title" id="primary-header-modalLabel">
            Currently on Free Plan
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={() => onClose()}
          >
            ×
          </button>
        </div>
        <div class="modal-body">
          <p>
            You are on the <strong>Free Subscription</strong> plan. To access
            your all-time history, you need to upgrade to the Premium
            Subscription plan.
          </p>
          <br />
          <br />
        </div>
        <div class="modal-footer">
          <button
            onClick={() => onClose()}
            type="button"
            class="btn btn-light mr-3"
            data-dismiss="modal"
          >
            Close
          </button>
          <a href="/pricing">
            <button type="button" class="btn btn-primary">
              Upgrade
            </button>
          </a>
        </div>
      </div>
    </Dialog>
  );
};

export default SubscriptionDialog;
