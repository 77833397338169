import React, { useEffect, useState } from "react";
import resolveCurrency from "../../../helpers/Helper";
import { Dropdown } from "semantic-ui-react";
import moment from "moment";
import { API } from "../../../config";
import SnackbarMessage from "../common/SnackbarMessage";
import { getRecentOdometerService } from "../../../services/odometer-history-service";
import { createMaintenanceFormService } from "../../../services/forms/maintenance-form-service";
import { updateReminderFormService } from "../../../services/forms/reminder-form-service";
import NumberFormat from "../../../helpers/number-format-helper";

const mixpanel = require("mixpanel-browser");

const MaintenanceFormV2 = ({ vehicle, ...props }) => {
  const [state, setState] = useState({
    date: null,
    odometer: null,
    units: JSON.parse(localStorage.getItem("units")),
    categories: [],
    description: null,
    acRelated: false,
    acCost: 0,
    bodyRelated: false,
    bodyCost: 0,
    engineRelated: false,
    engineCost: 0,
    electricalRelated: false,
    electricalCost: 0,
    interiorRelated: false,
    interiorCost: 0,
    suspensionRelated: false,
    suspensionCost: 0,
    steeringRelated: false,
    steeringCost: 0,
    transmissionRelated: false,
    transmissionCost: 0,
    tireRelated: false,
    tireCost: 0,
    anyOtherCost: 0,
    otherRelated: false,
    otherCost: 0,
    tax: 0,
    loading: false,
    formData: new FormData(),
    lastOdometer: null,
    success: false,
    reminderId: null,
    error: false,
    showingAttachmentIndex: 0,
  });
  const {
    date,
    odometer,
    formData,
    units,
    description,
    acCost,
    acRelated,
    anyOtherCost,
    bodyCost,
    bodyRelated,
    electricalCost,
    electricalRelated,
    reminderId,
    engineCost,
    engineRelated,
    interiorCost,
    interiorRelated,
    suspensionCost,
    suspensionRelated,
    steeringCost,
    steeringRelated,
    tireCost,
    tireRelated,
    transmissionCost,
    transmissionRelated,
    otherCost,
    otherRelated,
    tax,
    error,
    loading,
    lastOdometer,
    success,
    showingAttachmentIndex,
    categories,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      mixpanel.track("maintenance_form", {
        vehicleId: vehicle.id,
        uid: vehicle.fkusersuid,
      });
      let cat = [];
      const category = new URLSearchParams(props.location.search).get(
        "reminderCategory"
      );
      const desc = new URLSearchParams(props.location.search).get(
        "description"
      );
      const id = new URLSearchParams(props.location.search).get("reminderId");

      if (category == "acRelated") {
        cat.push("ac_related");
      }

      if (category == "bodyRelated") {
        cat.push("body_related");
      }

      if (category == "electricalRelated") {
        cat.push("electrical_related");
      }

      if (category == "engineCost") {
        cat.push("engine_cost");
      }

      if (category == "interiorRelated") {
        cat.push("interior_related");
      }

      if (category == "suspensionRelated") {
        cat.push("suspension_related");
      }

      if (category == "steeringRelated") {
        cat.push("steering_related");
      }

      if (category == "tyreBrakeRelated") {
        cat.push("tire_related");
      }
      if (category == "transmissionRelated") {
        cat.push("transmission_related");
      }

      if (category == "otherRelated") {
        cat.push("other_related");
      }
      console.log(category, cat);
      state.categories = cat;
      state.description = desc;
      state.reminderId = id;
      setState({ ...state });
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const getLastOdometer = async () => {
      try {
        const token = localStorage.getItem("token");

        const data = await getRecentOdometerService(
          vehicle.id,
          moment(date).format()
        );

        setState({ ...state, lastOdometer: data.odometer || 0 });
      } catch (err) {
        console.log(err);
      }
    };

    let mounted = true;

    if (mounted) {
      getLastOdometer();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [date]);

  const onSubmit = async (e) => {
    try {
      setState({ ...state, loading: true });

      formData.set(
        "date",
        date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, moment(date).format())
      );
      formData.set("odometer", odometer.replaceAll(",", ""));
      formData.set(
        "total_cost",
        parseFloat(acCost || 0) +
          parseFloat(engineCost || 0) +
          parseFloat(electricalCost || 0) +
          parseFloat(bodyCost || 0) +
          parseFloat(interiorCost || 0) +
          parseFloat(suspensionCost || 0) +
          parseFloat(steeringCost || 0) +
          parseFloat(tireCost || 0) +
          parseFloat(transmissionCost || 0) +
          parseFloat(otherCost || 0) +
          parseFloat(anyOtherCost || 0) +
          parseFloat(tax || 0)
      );
      formData.set(
        "sub_total",
        parseFloat(acCost || 0) +
          parseFloat(engineCost || 0) +
          parseFloat(electricalCost || 0) +
          parseFloat(bodyCost || 0) +
          parseFloat(interiorCost || 0) +
          parseFloat(suspensionCost || 0) +
          parseFloat(steeringCost || 0) +
          parseFloat(tireCost || 0) +
          parseFloat(transmissionCost || 0) +
          parseFloat(otherCost || 0) +
          parseFloat(anyOtherCost || 0) +
          parseFloat(tax || 0)
      );

      formData.set("engine_related", engineRelated);
      formData.set("electrical_related", electricalRelated);
      formData.set("ac_related", acRelated);
      formData.set("tyre_brake_related", tireRelated);
      formData.set("transmission_related", transmissionRelated);
      formData.set("body_related", bodyRelated);
      formData.set("interior_related", interiorRelated);
      formData.set("steering_related", steeringRelated);
      formData.set("suspension_related", suspensionRelated);
      formData.set("other_related", otherRelated);
      formData.set("description", description);
      formData.set("ac_cost", acCost);
      formData.set("engine_cost", engineCost);
      formData.set("electrical_cost", electricalCost);
      formData.set("body_cost", bodyCost);
      formData.set("interior_cost", interiorCost);
      formData.set("steering_cost", steeringCost);
      formData.set("suspension_cost", suspensionCost);
      formData.set("other_cost", otherCost);
      formData.set("transmission_cost", transmissionCost);
      formData.set("tyre_brake_cost", tireCost);

      formData.set("labour_cost", anyOtherCost);
      formData.set("tax_cost", tax);
      formData.set("labour_cost", anyOtherCost);

      const token = localStorage.getItem("token");

      const data = await createMaintenanceFormService(formData, vehicle.id);

      if (reminderId) {
        const fd = new FormData();
        fd.set("reminderType", "maintenance");

        const remData = await updateReminderFormService(
          "maintenance",
          vehicle.id,
          reminderId
        );
      }

      // console.log(data);

      setState({ ...state, loading: false, success: true });
    } catch (err) {
      setState({ ...state, loading: false, error: true });
    }
  };

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const computeCategories = (value) => {
    setState({
      ...state,
      categories: value,
      engineRelated: value.includes("engine_related"),
      acRelated: value.includes("ac_related"),
      bodyRelated: value.includes("body_related"),

      electricalRelated: value.includes("electrical_related"),
      interiorRelated: value.includes("interior_related"),
      suspensionRelated: value.includes("suspension_related"),
      steeringRelated: value.includes("steering_related"),
      otherRelated: value.includes("other_related"),
      transmissionRelated: value.includes("transmission_related"),
      tireRelated: value.includes("tire_related"),
      otherRelated: value.includes("other_related"),
    });
  };

  return (
    <>
      <div className="text-dark">
        <h3>Add Maintenance</h3>
        <div style={{ backgroundColor: "#ffffff" }} className="my-3">
          <div className=" py-3  card-body">
            <div className="form-group">
              <label className="overview-dialog-label">Event Date</label>
              <input
                type="date"
                class="form-control date"
                data-toggle="input-mask"
                placeholder="09/09/2020"
                min="2000-01-01"
                name="date"
                value={date}
                onChange={handleChange}
                data-mask-format="00/00/0000"
                data-single-date-picker="true"
              />
            </div>

            <div className="form-group">
              <label>
                <strong>Enter Odometer</strong>{" "}
                <span style={{ fontWeight: "normal" }}>
                  {/* ( Last Odometer as of{" "}
                  {moment(date || new Date()).format("DD-MMM-YY")} :{" "}
                  {parseFloat(lastOdometer).toFixed(2)} {units.distanceunit} ) */}
                </span>
              </label>

              <div className="input-group">
                <input
                  id="odoInput"
                  class="form-control"
                  name="odometer"
                  value={NumberFormat(
                    (odometer || "").toString().replaceAll(",", ""),
                    vehicle.country_code
                  )}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                  placeholder="What was the odometer reading  ?"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    {units.distanceunit}
                  </span>
                </div>
              </div>
            </div>
            <label>Select Cost Categories</label>
            <div className="form-group">
              <Dropdown
                className="form-control select2"
                placeholder="Select Cost"
                fluid
                multiple
                options={[
                  {
                    key: "Ac",
                    value: "ac_related",
                    text: "AC Related",
                  },
                  {
                    key: "Body",
                    value: "body_related",
                    text: "Body Related",
                  },
                  {
                    key: "Electrical",
                    value: "electrical_related",
                    text: "Electrical Related",
                  },
                  {
                    key: "Engine",
                    value: "engine_related",
                    text: "Engine Related",
                  },
                  {
                    key: "Interior",
                    value: "interior_related",
                    text: "Interior Related",
                  },

                  {
                    key: "Steering",
                    value: "steering_related",
                    text: "Steering Related",
                  },
                  {
                    key: "Suspension",
                    value: "suspension_related",
                    text: "Suspension Related",
                  },
                  {
                    key: "tire",
                    value: "tire_related",
                    text: "Tire & Brakes Related",
                  },
                  {
                    key: "transmission",
                    value: "transmission_related",
                    text: "Transmission Related",
                  },
                  {
                    key: "other",
                    value: "other_related",
                    text: "Other Related",
                  },
                ]}
                selection
                value={categories}
                onChange={(e, { value }) => {
                  computeCategories(value);
                }}
              />
            </div>

            {acRelated && (
              <>
                {" "}
                <label className="">AC Related Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      id="odoInput"
                      class="form-control"
                      name="acCost"
                      value={NumberFormat(
                        (acCost || "").toString().replaceAll(",", ""),
                        vehicle.country_code
                      )}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {bodyRelated && (
              <>
                {" "}
                <label className="">Body Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      id="odoInput"
                      class="form-control"
                      name="bodyCost"
                      value={NumberFormat(
                        (bodyCost || "").toString().replaceAll(",", ""),
                        vehicle.country_code
                      )}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {engineRelated && (
              <>
                {" "}
                <label className="">Engine Related Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      id="odoInput"
                      class="form-control"
                      name="engineCost"
                      value={NumberFormat(
                        (engineCost || "").toString().replaceAll(",", ""),
                        vehicle.country_code
                      )}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {electricalRelated && (
              <>
                {" "}
                <label className="">Electrical Related</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      id="odoInput"
                      class="form-control"
                      name="electricalCost"
                      value={NumberFormat(
                        (electricalCost || "").toString().replaceAll(",", ""),
                        vehicle.country_code
                      )}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {interiorRelated && (
              <>
                {" "}
                <label className="">Interior Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      id="odoInput"
                      class="form-control"
                      name="interiorCost"
                      value={NumberFormat(
                        (interiorCost || "").toString().replaceAll(",", ""),
                        vehicle.country_code
                      )}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {steeringRelated && (
              <>
                {" "}
                <label className="">Steering Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      id="odoInput"
                      class="form-control"
                      name="steeringCost"
                      value={NumberFormat(
                        (steeringCost || "").toString().replaceAll(",", ""),
                        vehicle.country_code
                      )}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {suspensionRelated && (
              <>
                {" "}
                <label className="">Suspension Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      id="odoInput"
                      class="form-control"
                      name="suspensionCost"
                      value={NumberFormat(
                        (suspensionCost || "").toString().replaceAll(",", ""),
                        vehicle.country_code
                      )}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {transmissionRelated && (
              <>
                {" "}
                <label className="">Transmission Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      id="odoInput"
                      class="form-control"
                      name="transmissionCost"
                      value={NumberFormat(
                        (transmissionCost || "").toString().replaceAll(",", ""),
                        vehicle.country_code
                      )}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {tireRelated && (
              <>
                {" "}
                <label className="">Tire Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      id="odoInput"
                      class="form-control"
                      name="tireCost"
                      value={NumberFormat(
                        (tireCost || "").toString().replaceAll(",", ""),
                        vehicle.country_code
                      )}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            {otherRelated && (
              <>
                {" "}
                <label className="">Other Cost</label>
                <div className="form-group">
                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(units.currencyunit)}
                      </span>
                    </div>
                    <input
                      id="odoInput"
                      class="form-control"
                      name="otherCost"
                      value={NumberFormat(
                        (otherCost || "").toString().replaceAll(",", ""),
                        vehicle.country_code
                      )}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                      placeholder="0.00"
                    />
                  </div>
                </div>{" "}
              </>
            )}

            <label className="">Tax Cost</label>
            <div className="form-group">
              <div className="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    {resolveCurrency(units.currencyunit)}
                  </span>
                </div>
                <input
                  id="odoInput"
                  class="form-control"
                  name="tax"
                  value={NumberFormat(
                    (tax || "").toString().replaceAll(",", ""),
                    vehicle.country_code
                  )}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  aria-describedby="basic-addon1"
                  placeholder="0.00"
                />
              </div>
            </div>

            <label className="">Labor Cost</label>
            <div className="form-group">
              <div className="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    {resolveCurrency(units.currencyunit)}
                  </span>
                </div>
                <input
                  id="odoInput"
                  class="form-control"
                  name="anyOtherCost"
                  value={NumberFormat(
                    (anyOtherCost || "").toString().replaceAll(",", ""),
                    vehicle.country_code
                  )}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                  placeholder="0.00"
                />
              </div>
            </div>
            <label>Total Cost</label>
            <div className="form-group ">
              <div className="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    {resolveCurrency(units.currencyunit)}
                  </span>
                </div>
                <input
                  id="odoInput"
                  class="form-control"
                  value={NumberFormat(
                    (
                      parseFloat(acCost || 0) +
                      parseFloat(engineCost || 0) +
                      parseFloat(electricalCost || 0) +
                      parseFloat(bodyCost || 0) +
                      parseFloat(interiorCost || 0) +
                      parseFloat(suspensionCost || 0) +
                      parseFloat(steeringCost || 0) +
                      parseFloat(tireCost || 0) +
                      parseFloat(transmissionCost || 0) +
                      parseFloat(otherCost || 0) +
                      parseFloat(anyOtherCost || 0) +
                      parseFloat(tax || 0)
                    )
                      .toString()
                      .replaceAll(",", ""),
                    vehicle.country_code
                  )}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                  placeholder="0.00"
                />
              </div>
            </div>

            <div className="form-group">
              <label>Description / Comments</label>
              <input
                class="form-control"
                name="description"
                value={description}
                onChange={handleChange}
                aria-describedby="basic-addon1"
                placeholder="Optional"
              />
            </div>

            <div class="form-group">
              <label>Upload Maintenance Receipt 1</label>
              <div class="input-group">
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="inputGroupFile04"
                    name="attachment"
                    onChange={(e) => {
                      document.getElementById("file-name-1").innerHTML =
                        e.target.files[0].name;
                      formData.set("attachment_1", e.target.files[0]);
                    }}
                  />
                  <label
                    class="custom-file-label"
                    id="file-name-1"
                    for="inputGroupFile04"
                  >
                    Optional
                  </label>
                </div>
              </div>
            </div>

            {showingAttachmentIndex >= 1 && (
              <div class="form-group">
                <label>Upload Maintenance Receipt 2</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="inputGroupFile04"
                      name="attachment"
                      onChange={(e) => {
                        document.getElementById("file-name-2").innerHTML =
                          e.target.files[0].name;
                        formData.set("attachment_2", e.target.files[0]);
                      }}
                    />
                    <label
                      class="custom-file-label"
                      id="file-name-2"
                      for="inputGroupFile04"
                    >
                      Optional
                    </label>
                  </div>
                </div>
              </div>
            )}

            {showingAttachmentIndex == 2 && (
              <div class="form-group">
                <label>Upload Maintenance Receipt 3</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="inputGroupFile04"
                      name="attachment"
                      onChange={(e) => {
                        document.getElementById("file-name-3").innerHTML =
                          e.target.files[0].name;
                        formData.set("attachment_3", e.target.files[0]);
                      }}
                    />
                    <label
                      class="custom-file-label"
                      id="file-name-3"
                      for="inputGroupFile04"
                    >
                      Optional
                    </label>
                  </div>
                </div>
              </div>
            )}

            {showingAttachmentIndex < 2 && (
              <button
                className="btn btn-light"
                onClick={() => {
                  setState({
                    ...state,
                    showingAttachmentIndex: showingAttachmentIndex + 1,
                  });
                }}
              >
                Add More Attachments
              </button>
            )}

            <button
              onClick={onSubmit}
              className="btn btn-primary btn-block mt-3 "
            >
              {loading ? "Loading..." : "Submit"}
            </button>

            <SnackbarMessage
              open={success}
              onClose={() => setState({ ...state, success: false })}
              message="Maintenance Has Been added"
            />
            <SnackbarMessage
              open={error}
              onClose={() => setState({ ...state, error: false })}
              message="Unable to add the maintenance at this time"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MaintenanceFormV2;
