import simpleFetch from "../../helpers/fetch-wrapper";
import moment from "moment";

export async function createExpenseFormService(formData, vehicleId) {
  try {
    return await simpleFetch(
      `/expense-reports/${vehicleId}`,
      "POST",
      formData,
      {}
    );
  } catch (err) {
    console.log(err);
  }
}

export async function getAllExpenseFormService(vehicleId) {
  try {
    return await simpleFetch(`/expense-reports/${vehicleId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}
export async function editExpenseFormService(reportId, formData, vehicleId) {
  try {
    return await simpleFetch(
      `/expense-reports/${vehicleId}/${reportId}`,
      "PUT",
      formData,
      {}
    );
  } catch (err) {
    console.log(err);
  }
}

export async function deleteExpenseFormService(reportId, vehicleId) {
  try {
    return await simpleFetch(
      `/expense-reports/${vehicleId}/${reportId}`,
      "DELETE"
    );
  } catch (err) {
    console.log(err);
  }
}
