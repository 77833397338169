import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import moment from "moment";
import { API } from "../../../config";
import resolveCurrency from "../../../helpers/Helper";

const FleetAnalyticsTable = ({ units }) => {
  const [state, setState] = useState({
    result: [],
    loading: true,
    month: "January",
    year: 2020,
    error: null,
  });
  const { result, loading, month, year, error } = state;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAnalytics();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [month, year]);

  const getAnalytics = async () => {
    try {
      setState({ ...state, loading: true, error: null, result: [] });
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${API}/fleet/analytics/?month=${month}&year=${year}&refresh=true`,
        {
          method: "GET",
          headers: {
            Accept: `application/json`,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.done)
        setState({
          ...state,
          result: data.result,
          loading: false,
          error: null,
        });
      else
        setState({
          ...state,
          loading: false,
          result: [],
          error: "No Records Found / Unable to process at this time.",
        });
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        loading: false,
        error: "No Records Found / Unable to process at this time.",
      });
    }
  };

  return (
    <div className="card card-body">
      <h4>Fleet Breakdown</h4>

      <div className="row">
        <div className="col-lg-3">
          <Dropdown
            search
            selection
            value={month}
            placeholder="Select Month"
            onChange={(e, { value }) => setState({ ...state, month: value })}
            options={(() => {
              let options = [];
              for (let i = 0; i <= 11; i++) {
                options.push({
                  key: i,
                  value: moment().month(i).format("MMMM"),
                  text: moment().month(i).format("MMMM"),
                });
              }
              options.unshift({ key: "All", value: "All", text: "All" });

              return options;
            })()}
          />
        </div>
        <div className="col-lg-3">
          <Dropdown
            search
            selection
            value={year}
            placeholder="Select Year"
            onChange={(e, { value }) => setState({ ...state, year: value })}
            options={(() => {
              let options = [];
              for (let i = new Date().getFullYear(); i > 2010; i--) {
                options.push({ key: i, value: i, text: i });
              }
              return options;
            })()}
          />
        </div>
      </div>
      {error && <p className="text-danger my-3">{error} </p>}

      <table className="table-striped my-3">
        <thead>
          <tr>
            <th title="This is the License Plate Number / Vehicle Id entered by you">
              Plate No.{" "}
            </th>
            {/* <th>Name </th>
            <th>Vehicle </th> */}
            <th title="Number of Days in the month">Available Days </th>
            <th title="Number of Days the car was rented for">Rented Days</th>
            <th title="Number of Agreements">Agreements</th>
            <th title="Vehicle Utilization">Utilization</th>
            <th title="Revenue for the selected month">Revenue</th>
            <th title="This is the net expense for the trip, including all the maintenance and fuel costs.">
              Expense
            </th>
            <th title="This is your margins before any tax deductions">
              Gross Margin
            </th>
            <th title="Average Revenue Per Day">ARPD</th>
            <th title="Average Revenue Per Unit">ARPU</th>
            <th title="Average Revenue Per Agreement">ARPA</th>
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <div className="spinner-border" role="status" />
          ) : (
            result.map((record, i) => {
              return (
                <tr
                  style={{
                    borderTop: `${record.make === "" ? "#000 2px solid" : ""}`,
                  }}
                >
                  <td title={`${record.make} ${record.model}`}>
                    {record.plate_no}
                  </td>
                  {/* <td>{record.name}</td>
                  <td>{record.make + " " + record.model}</td> */}
                  <td>{`${
                    record.plate_no == "Total"
                      ? moment(month, "MMM").daysInMonth() * (result.length - 1)
                      : moment(month, "MMM").daysInMonth()
                  }`}</td>
                  <td>{record.rental_days}</td>
                  <td>{record.n_agreements}</td>
                  <td>
                    {(
                      (record.rental_days /
                        (record.plate_no == "Total"
                          ? moment(month, "MMM").daysInMonth() *
                            (result.length - 1)
                          : moment(month, "MMM").daysInMonth())) *
                      100
                    ).toFixed(2)}
                    %
                  </td>
                  <td>
                    {" "}
                    {resolveCurrency(units.currencyunit)} {record.revenue}
                  </td>
                  <td>
                    {" "}
                    {resolveCurrency(units.currencyunit)}{" "}
                    {(
                      parseFloat(record.expense || 0) +
                      parseFloat(record.monthly_payment || 0)
                    ).toFixed(2)}
                  </td>
                  <td>
                    {(
                      ((record.revenue -
                        (parseFloat(record.expense || 0) +
                          parseFloat(record.monthly_payment || 0))) /
                        record.revenue) *
                      100
                    ).toFixed(2)}
                    %
                  </td>
                  <td>
                    {" "}
                    {resolveCurrency(units.currencyunit)}{" "}
                    {(
                      record.revenue / moment(month, "MMM").daysInMonth()
                    ).toFixed(2)}{" "}
                  </td>
                  <td>
                    {" "}
                    {resolveCurrency(units.currencyunit)} {record.revenue}
                  </td>
                  <td>
                    {" "}
                    {resolveCurrency(units.currencyunit)}{" "}
                    {(record.revenue / record.n_agreements).toFixed(2)}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FleetAnalyticsTable;
