import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import NumberFormat from "../../../../helpers/number-format-helper";
import TataService from "../../../../services/connected-cars/tata-service";

export default function TataDailySummary({ vehicle, units }) {
  const [state, setState] = useState({
    _loading: false,
    _date: null,
    _options: [],
    _summaries: [],
    _value: null,
  });
  const { _date, _loading, _options, _summaries, _value } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) _getData();

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const _getData = async () => {
    try {
      setState({ ...state, _loading: true });
      let ops = [];
      const data = await TataService.getDailySummary(vehicle.vin);
      data.result.forEach((element, i) => {
        ops.push({
          key: element.date,
          text: moment(element.date).format("DD-MMM-YY"),
          value: i,
        });
      });

      setState({
        ...state,
        _loading: false,
        _summaries: data.result,
        _options: ops,
        _value: ops.length - 1,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card" style={{ height: "100%" }}>
      <div className="card-body">
        <h3>Daily Summary</h3>

        <Dropdown
          search
          selection
          value={_value}
          options={_options}
          onChange={(e, { value }) => setState({ ...state, _value: value })}
          placeholder={"Select Date"}
          fluid
        />

        {!_loading && _options.length > 0 && (
          <table className="table-striped mt-2">
            <tbody>
              <tr>
                <td>
                  <i className="mdi mdi-timer text-primary"> </i>{" "}
                  <strong>Driving Duration</strong>
                </td>
                <td> {(_summaries[_value].drive_time / 60).toFixed(1)} hrs </td>
              </tr>
              <tr>
                <td>
                  <i className=" mdi mdi-map-marker-distance text-warning"> </i>{" "}
                  <strong>Odometer</strong>
                </td>
                <td>{NumberFormat(parseInt(_summaries[_value].end_odo))} km</td>
              </tr>
              <tr>
                <td>
                  <i className=" mdi mdi-matrix text-success"> </i>{" "}
                  <strong>Distance Driven</strong>
                </td>
                <td>
                  {_summaries[_value].end_odo - _summaries[_value].start_odo} km
                </td>
              </tr>
              <tr>
                <td>
                  <i className=" mdi mdi-battery-charging-60"></i>{" "}
                  <strong>Charge Duration</strong>
                </td>
                <td>{(_summaries[_value].charge_time / 60).toFixed(1)} hrs</td>
              </tr>
              <tr>
                <td>
                  <strong>Watt Hours Per Km</strong>
                </td>
                <td>
                  {_summaries[_value].watt_per_mile
                    ? (_summaries[_value].watt_per_mile / 1.60934).toFixed(0)
                    : "N/A"}
                  Wh/km
                </td>
              </tr>
              {/* <tr>
                <td>
                  <i className=" mdi mdi-alarm-multiple text-primary"> </i>{" "}
                  <strong>Time Tracked</strong>
                </td>
                <td>
                  {_summaries[_value].tracked_time
                    ? (_summaries[_value].tracked_time / 60).toFixed(1)
                    : "N/A"}{" "}
                  hrs out of 24 hrs
                </td>
              </tr> */}
              <tr>
                <td>
                  <i className=" mdi mdi-graph text-warning"> </i>{" "}
                  <strong>Efficiency</strong>
                </td>
                <td>
                  {_summaries[_value].efficiency
                    ? parseInt(_summaries[_value].efficiency)
                    : "N/A"}
                  %
                </td>
              </tr>
              <tr>
                <td>
                  <i className=" mdi mdi-speedometer-medium text-danger"> </i>{" "}
                  <strong>Max Speed</strong>
                </td>
                <td>
                  {_summaries[_value].max_speed
                    ? (_summaries[_value].max_speed * 1.60934).toFixed(1)
                    : "N/A"}
                  km/h
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
