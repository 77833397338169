import simpleFetch from "../helpers/fetch-wrapper";
import getUser from "../helpers/user";

/**
 * @description create a crowd source entry
 * @param {String} make
 * @param {String} model
 * @param {String} trim
 * @param {Number} year
 * @param {Number} msrp
 * @param {Number} fuelTankSize
 * @param {String} drivetrain
 * @param {String} country
 * @param {Number} mpg
 *
 */
export async function createCrowdsourceVehicleService(
  make,
  model,
  trim,
  year,
  msrp,
  fuelTankSize,
  drivetrain,
  country,
  mpg
) {
  try {
    return await simpleFetch(
      "/crowdsource/vehicle-list",
      "POST",
      JSON.stringify({
        make,
        model,
        trim,
        year,
        msrp,
        fuelTankSize,
        drivetrain,
        country,
        mpg,
      })
    );
  } catch (err) {
    console.log(err);
  }
}
