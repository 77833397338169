import simpleFetch from "../helpers/fetch-wrapper";

/**
 * @description gets the generic valuation i.e w/o ML2 valuation
 * @param {String} make
 * @param {String} model
 * @param {Number} year
 * @param {String} powertrain
 * @param {Number} odometer
 * @param {Number} msrp
 * @param {Number} optionPrice
 * @param {String} drivetrain
 * @param {String} transmission
 * @param {String} interiorColor
 * @param {String} exteriorColor
 * @returns
 */
export async function getGenericMlValuationService(
  make,
  model,
  year,
  powertrain,
  odometer,
  msrp,
  optionPrice,
  drivetrain,
  transmission,
  interiorColor,
  exteriorColor
) {
  try {
    return await simpleFetch(
      "/ml-depreciation",
      "POST",
      JSON.stringify({
        make,
        model,
        year,
        powertrain,
        odometer,
        msrp,
        optionPrice,
        drivetrain,
        transmission,
        interiorColor,
        exteriorColor,
      })
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Get the ml1 and ml2 valuation
 * @param {String} vehicleId
 * @param {String} vin
 * @returns
 */
export async function getMl1AndMl2ValuationService(vehicleId, vin) {
  try {
    return await simpleFetch(
      `/keemut-report/valuation/${vehicleId}?vin=${vin}`,
      "GET"
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Create a vin search history entry
 * @param {String} vin
 * @param {String} make
 * @param {String} model
 * @param {Number} year
 * @param {String} trim
 * @param {Number} priceHigh
 * @param {Number} priceLow
 * @returns
 */
export async function createVinSearchHistoryService(
  vin,
  make,
  model,
  year,
  trim,
  priceHigh,
  priceLow
) {
  try {
    return await simpleFetch(
      "/vin-search-results",
      "POST",
      JSON.stringify({
        vin,
        make,
        model,
        year,
        trim,
        priceHigh,
        priceLow,
      })
    );
  } catch (err) {
    console.log(err);
  }
}
