import React, { useState, useEffect } from "react";
import resolveCurrency from "../../../helpers/Helper";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import { API } from "../../../config";

const EditRevenueDialog = ({
  showEditDialog,
  closeEditDialog,
  revenueReport,
  units,
}) => {
  const [state, setState] = useState({
    date: moment(revenueReport.date).format("YYYY-MM-DD"),
    endDate: moment(revenueReport.end_date).format("YYYY-MM-DD"),
    revenue: revenueReport.earnings,
    expense: revenueReport.trip_expense,
    description: revenueReport.description,
    saving: false,
    error: null,
  });
  const { date, endDate, revenue, expense, description, saving, error } = state;

  useEffect(() => {
    let mounted = true;
    if (mounted)
      setState({
        ...state,
        date: moment(revenueReport.date).format("YYYY-MM-DD"),
        endDate: moment(revenueReport.end_date).format("YYYY-MM-DD"),
        revenue: revenueReport.earnings,
        expense: revenueReport.trip_expense,
        description: revenueReport.description,
        saving: false,
      });

    return function cleanup() {
      mounted = false;
    };
  }, [revenueReport]);

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const updateRevenueReports = async () => {
    try {
      setState({ ...state, saving: true });

      const res = await fetch(`${API}/revenue-reports/${revenueReport.id}`, {
        method: "PUT",
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ description, date, endDate, revenue, expense }),
      });
      const data = await res.json();

      if (data.done) {
        setState({ ...state, saving: false });
        closeEditDialog();
      } else {
        setState({
          ...state,
          error: "Unable to update at the moment.",
          saving: false,
        });
      }
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        error: "Unable to update at the moment.",
        saving: false,
      });
    }
  };

  return (
    <Dialog
      open={showEditDialog}
      onClose={closeEditDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div class="modal-content dialog">
        <div class="modal-header modal-colored-header bg-info">
          <h4
            class="modal-title"
            id="primary-header-modalLabel"
            style={{ color: "#ffffff", fontFamily: "Roboto" }}
          >
            Edit Revenue Report
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={closeEditDialog}
          >
            ×
          </button>
        </div>
        <div class="modal-body dialog">
          <div className="p-3">
            {error && (
              <div
                class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
                role="alert"
              >
                <strong>Error - </strong> {error}
              </div>
            )}
            <div class="form-group">
              <label className="overview-dialog-label">Enter Description</label>

              <div className="input-group">
                <input
                  type="text"
                  class="form-control "
                  name="description"
                  value={description}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="form-group">
              <label className="overview-dialog-label">Enter date</label>

              <div className="input-group">
                <input
                  type="date"
                  class="form-control date"
                  id="birthdatepicker"
                  data-toggle="date-picker"
                  data-single-date-picker="true"
                  name="date"
                  value={date}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div class="form-group">
              <label className="overview-dialog-label">Enter End Date</label>

              <div className="input-group">
                <input
                  type="date"
                  class="form-control date"
                  id="birthdatepicker"
                  data-toggle="date-picker"
                  data-single-date-picker="true"
                  name="endDate"
                  value={endDate}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="overview-dialog-label">Enter Revenue</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01">
                    {resolveCurrency(units.currencyunit.toUpperCase())}
                  </label>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter how much you earned"
                  name="revenue"
                  value={revenue}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="overview-dialog-label">
                Enter Trip Expense
              </label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01">
                    {resolveCurrency(units.currencyunit.toUpperCase())}
                  </label>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter how much you spent during the trip"
                  name="expense"
                  value={expense}
                  onChange={handleChange}
                />
              </div>
            </div>

            <button
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={updateRevenueReports}
            >
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditRevenueDialog;
