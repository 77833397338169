import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import * as firebase from "firebase";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import parsePhoneNumber from "libphonenumber-js";
import resolveCurrency from "../../../helpers/Helper";
import { API, firebaseConfig } from "../../../config";
import { getTimezoneSemanticUiDropdownOptions } from "../../../helpers/date-time-helper";
import SnackbarMessage from "../common/SnackbarMessage";
import {
  getElectricityPriceService,
  getFuelPriceService,
  getFxRateService,
  getUserUnitsService,
} from "../../../services/units-service";
import {
  getCountriesService,
  getStatesService,
} from "../../../services/gegraphic-service";
import { getUserService } from "../../../services/auth-service";

const UnitSettings = () => {
  const captchaRef = useRef(null);

  const [state, setState] = useState({
    distanceUnit: null,
    currencyUnit: null,
    fuelUnit: null,
    firstname: null,
    email: null,
    lastname: null,
    loading: true,
    success: false,
    saving: false,
    timezone: null,
    temperatureUnit: null,
    enableMfa: false,
    showMfaDialog: false,
    appVerifier: null,
    otpSent: false,
    verificationId: null,
    password: null,
    phoneNumber: null,
    commercialRate: null,
    mfaLoading: false,
    verificationCode: null,
    firebaseUser: null,
    error: null,
    countryOptions: [],
    country: null,
    failure: false,
    stateOptions: [],
    energyRate: null,
    fuelRate: null,
    myState: null,
    mfaEnabled: false,
    otpError: false,
    taxRate: null,
    showFetchError: false,
    countries: [],
    unitsErrorMessage: null,
    unitSystem: null,
    timezoneName: null,
  });
  const {
    unitSystem,
    countries,
    loading,
    myState,
    timezoneName,
    stateOptions,
    distanceUnit,
    password,
    otpError,
    countryOptions,
    country,
    currencyUnit,
    appVerifier,
    success,
    mfaEnabled,
    firebaseUser,
    fuelUnit,
    phoneNumber,
    firstname,
    lastname,
    showMfaDialog,
    mfaLoading,
    temperatureUnit,
    email,
    saving,
    enableMfa,
    timezone,
    commercialRate,
    error,
    otpSent,
    verificationId,
    verificationCode,
    energyRate,
    fuelRate,
    showFetchError,
    unitsErrorMessage,
    failure,
    taxRate,
  } = state;

  useEffect(() => {
    initialRequest();
  }, []);

  const initialRequest = () => {
    const getUnits = async () => {
      try {
        const { uid } = JSON.parse(localStorage.getItem("user"));
        const data = await getUserUnitsService(uid);
        const udata = await getUserService(uid);

        const sdata = await getStatesService();

        const countryData = await getCountriesService();

        sdata.states.forEach((s) => {
          stateOptions.push({
            key: s.state,
            value: s.state,
            text: s.state,
          });
        });

        countryData.countries.forEach((s) => {
          countryOptions.push({
            key: s.country,
            value: s.country,
            text: s.country,
          });
        });

        const user = JSON.parse(localStorage.getItem("user"));
        localStorage.setItem("units", JSON.stringify(data.units));
        setState({
          ...state,
          stateOptions,
          loading: false,
          countryOptions,
          country: data.units.country,
          myState: data.units.state,
          distanceUnit: data.units.distanceunit,
          fuelUnit: data.units.fuelunit,
          commercialRate: data.units.commercial_energy_rate,
          timezone: data.units.timezone,
          currencyUnit: data.units.currencyunit.toUpperCase(),
          temperatureUnit: data.units.temperatureunit,
          email: user.email,
          countries: countryData.countries,
          firstname: udata.user.firstname,
          lastname: udata.user.lastname,
          mfaEnabled: JSON.parse(localStorage.getItem("user")).mfa_enabled,
          fuelRate: data.units.fuel_rate,
          energyRate: data.units.energy_rate,
          taxRate: data.units.tax_rate,
          timezoneName: data.units.timezone_name,
          unitSystem: data.units.fuelunit == "gal" ? "imperial" : "metric",
        });
      } catch (err) {
        console.log(err);
        setState({
          ...state,
          loading: false,
          showFetchError: true,
          unitsErrorMessage: "Unable to fetch your unit settings at this time",
        });
      }
    };

    let mounted = true;

    if (mounted) {
      getUnits();
    }

    return function cleanup() {
      mounted = false;
    };
  };

  const saveSettings = () => {
    setState({ ...state, saving: true });
    const token = localStorage.getItem("token");
    fetch(`${API}/units`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        distanceUnit,
        fuelUnit,
        currencyUnit,
        firstName: firstname,
        lastName: lastname,
        country: country,
        state: myState,
        temperatureUnit,
        timezone,
        energyRate,
        fuelRate,
        taxRate,
        commercialEnergyRate: commercialRate,
        timezoneName,
      }),
    })
      .then((res) => res.json())
      .then((data) => setState({ ...state, success: true, saving: false }))
      .catch((err) => setState({ ...state, failure: true, saving: false }));
  };

  if (loading) {
    return <div class="spinner-border text-primary" role="status"></div>;
  }

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const renderCaptcha = async () => {
    try {
      setState({ ...state, mfaLoading: true, error: false });
      const myUser = JSON.parse(localStorage.getItem("user"));

      if (!firebase.apps.length)
        var firebaseApp = new firebase.initializeApp(firebaseConfig);
      console.log(captchaRef.current);
      var verifier = new firebase.auth.RecaptchaVerifier(captchaRef.current, {
        size: "invisible",
      });

      // const res = await fetch(`${API}/mfa/enable`, {
      //   method: "POST",
      //   headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      //   body: JSON.stringify({
      //     email: "hemang@dashroad.com",
      //     password: "LvMxKq2428*/",
      //     captchaVerificationToken: verifier,
      //   }),
      // });

      await firebase.auth().signInWithEmailAndPassword(myUser.email, password);
      const user = firebase.auth().currentUser;
      const multiFactorSession = await user.multiFactor.getSession();
      var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      let pNum = parsePhoneNumber("+" + phoneNumber);
      const intPhoneFormat = pNum.formatInternational();

      phoneAuthProvider
        .verifyPhoneNumber(
          {
            phoneNumber: intPhoneFormat,
            session: multiFactorSession,
          },
          verifier
        )
        .then(function (verificationId) {
          console.log(verificationId);
          setState({
            ...state,
            verificationId,
            otpSent: true,
            firebaseUser: user,
            mfaLoading: false,
          });
        });

      // const captchaVerificationToken = await verifier.verify();
    } catch (err) {
      console.log(err);
      setState({ ...state, error: true, mfaLoading: false });
    }
  };

  const enrollMfa = async () => {
    try {
      setState({ ...state, mfaLoading: true });
      var cred = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      var multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      firebaseUser.multiFactor.enroll(multiFactorAssertion, "My phone number");
      setState({ ...state, mfaLoading: false, mfaEnabled: true });
    } catch (err) {
      console.log(err);
      setState({ ...state, mfaLoading: false, otpError: true });
    }
  };

  const getFuelPrices = async () => {
    try {
      const data = await getFuelPriceService(country, myState);

      const edata = await getElectricityPriceService(country, myState);

      const currData = await getFxRateService(currencyUnit);

      // convert to user's units
      // default units are usd per gallon    and    usd per kWh
      data.price *=
        currData.currencyConversion[0].rate / (fuelUnit == "gal" ? 1 : 3.78541);

      edata.price *= currData.currencyConversion[0].rate;

      setState({
        ...state,
        fuelRate: data.price.toFixed(2),
        energyRate: edata.price.toFixed(2),
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div style={{ backgroundColor: "#ffffff" }}>
        <div className=" container p-3  card-body">
          <div className="row">
            <div className="col-lg-12">
              <div className="card card-body">
                <h4>User Settings</h4>
                <div className="form-group mt-3">
                  <label className="overview-dialog-label">First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter First Name"
                    name="firstname"
                    value={firstname}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label className="overview-dialog-label">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Lastname"
                    name="lastname"
                    value={lastname}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label className="overview-dialog-label">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Updated Email"
                    name="email"
                    value={email}
                    disabled={true}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mx-2 my-3">
        <div className="container p-3 card-body">
          <div className="row">
            <div className="col-lg-12">
              <h4>Security Settings</h4>

              <div className="form-group">
                <label className="overview-dialog-label">
                  Enable Multifactor Authentication
                </label>

                <button
                  disabled={mfaEnabled}
                  className="btn btn-primary ml-3"
                  onClick={() => setState({ ...state, showMfaDialog: true })}
                >
                  {mfaEnabled ? "MFA Enabled" : "Enable"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div style={{ backgroundColor: "#ffffff" }}>
        <div className=" container px-3  card-body">
          <div className="row">
            <div className="col-lg-12">
              <div className="card card-body ">
                <h4>Geographical & Data Settings</h4>
                <div className="form-group mt-3">
                  <label>Country </label>
                  <Dropdown
                    placeholder="Select Your Country"
                    fluid
                    selection
                    search
                    options={countryOptions}
                    value={country}
                    onChange={(e, { value }) => {
                      // update the tax rate if us is selected
                      if (value == "United States Of America") {
                        state.taxRate = 0.56;
                      } else {
                        state.taxRate = 0;
                      }
                      let us = "metric";
                      let cu;
                      countries.forEach((c) => {
                        if (c.country == value) {
                          cu = c.currency_code;
                          if (c.unit_system != "metric") {
                            us = "imperial";
                          } else {
                            us = "metric";
                          }
                        }
                      });

                      setState({
                        ...state,
                        country: value,
                        unitSystem: us,
                        currencyUnit: cu,
                        fuelUnit: us == "metric" ? "ltr" : "gal",
                        distanceUnit: us == "metric" ? "km" : "mi",
                        temperatureUnit: us == "metric" ? "c" : "f",
                      });
                    }}
                  />
                </div>
                {country == "United States Of America" ? (
                  <div className="form-group">
                    <label>State (US only)</label>
                    <Dropdown
                      placeholder="Select Your State"
                      fluid
                      selection
                      search
                      value={myState}
                      options={stateOptions}
                      onChange={(e, { value }) => {
                        setState({
                          ...state,
                          myState: value,
                        });
                      }}
                    />
                  </div>
                ) : null}
                <div class="form-group">
                  <label>Unit System </label>
                  <Dropdown
                    search
                    selection
                    value={unitSystem}
                    fluid
                    placeholder="Select your preferred unit system"
                    options={[
                      {
                        key: "metric",
                        text: "Metric [Kilometers, Litres, Celcius]",
                        value: "metric",
                      },
                      {
                        key: "imperial",
                        text: "Imperial [Miles, Gallons, Fahrenheit]",
                        value: "imperial",
                      },
                    ]}
                    onChange={(e, { value }) => {
                      setState({
                        ...state,
                        unitSystem: value,
                        fuelUnit: value == "metric" ? "ltr" : "gal",
                        distanceUnit: value == "metric" ? "km" : "mi",
                        temperatureUnit: value == "metric" ? "c" : "f",
                      });
                    }}
                  />
                </div>
                <div class="form-group">
                  <label>Choose Your Timezone </label>

                  <Dropdown
                    placeholder="Select Your Timezone"
                    fluid
                    selection
                    search
                    onChange={(e, { value }) => {
                      setState({
                        ...state,
                        timezoneName: value,
                      });
                    }}
                    value={timezoneName}
                    options={getTimezoneSemanticUiDropdownOptions()}
                  />
                </div>
                <div class="form-group">
                  <label className="overview-dialog-label">
                    Tax Deduction Per{" "}
                    {distanceUnit == "mi" ? "Mile" : "Kilometer"}
                  </label>

                  <div className="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">
                        {resolveCurrency(currencyUnit)}/{distanceUnit}
                      </span>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      name="taxRate"
                      value={taxRate}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    <div class="form-group">
                      <label className="overview-dialog-label">Fuel Rate</label>

                      <div className="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            {resolveCurrency(currencyUnit)}/{fuelUnit}
                          </span>
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          name="fuelRate"
                          value={fuelRate}
                          onChange={handleChange}
                          aria-describedby="basic-addon1"
                          placeholder="How much is your fuel rate ?"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <br />
                      <button
                      style={{width: "100%"}}
                        className="btn mt-1 btn-light "
                        onClick={getFuelPrices}
                      >
                        {" "}
                        Update rates based on location data
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div class="form-group">
                      <label className="overview-dialog-label">
                        Residential Energy Rate
                      </label>

                      <div className="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            {resolveCurrency(currencyUnit)}/kWh
                          </span>
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          name="energyRate"
                          value={energyRate}
                          onChange={handleChange}
                          aria-describedby="basic-addon1"
                          placeholder="How much is your energy rate for residential charging ?"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div class="form-group">
                      <label className="overview-dialog-label">
                        Commercial Energy Rate
                      </label>

                      <div className="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">
                            {resolveCurrency(currencyUnit)}/kWh
                          </span>
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          name="commercialRate"
                          value={commercialRate}
                          onChange={handleChange}
                          aria-describedby="basic-addon1"
                          placeholder="How much is your energy rate for commercial chargers ?"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={saveSettings}
              >
                {saving ? "Saving..." : "Save"}
              </button>

              <Dialog open={showMfaDialog}>
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title" id="standard-modalLabel">
                      Enable Multifactor Authentication
                    </h4>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                      onClick={(e) => {
                        setState({ ...state, showMfaDialog: false });
                      }}
                    >
                      ×
                    </button>
                  </div>
                  <div class="modal-body">
                    {error && (
                      <div
                        class="mb-3 alert alert-info alert-dismissible fade show text-center"
                        role="alert"
                        style={{ width: "100%" }}
                      >
                        Unable to enable Mfa at this time, please try again
                        later or contact Keemut Support
                      </div>
                    )}
                    <p>
                      To enable Mfa, please provide a valid phone number on
                      which you can receive the verification code.
                    </p>

                    <PhoneInput
                      containerStyle={{ width: "100%" }}
                      inputStyle={{ width: "100%" }}
                      country={"us"}
                      onChange={(phone) => {
                        setState({
                          ...state,
                          phoneNumber: phone,
                        });
                      }}
                    />

                    <div className="form-group mt-3">
                      <div className="input-group input-group-merge">
                        <input
                          type="password"
                          id="password"
                          className="form-control"
                          placeholder="Enter your password"
                          name="password"
                          value={password}
                          onChange={(e) =>
                            setState({ ...state, password: e.target.value })
                          }
                        />
                        <div
                          className="input-group-append"
                          data-password="false"
                        >
                          <div
                            className="input-group-text"
                            onClick={() => {
                              const passwordInp =
                                document.getElementById("password");
                              if (passwordInp.type === "text") {
                                passwordInp.type = "password";
                              } else {
                                passwordInp.type = "text";
                              }
                            }}
                          >
                            <span className="password-eye"></span>
                          </div>
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div
                      id="recaptcha-container"
                      style={{ width: "100%" }}
                      ref={captchaRef}
                    ></div>
                    {otpSent && (
                      <OtpInput
                        numInputs={6}
                        value={verificationCode}
                        separator={<span>-</span>}
                        onChange={(otp) => {
                          setState({ ...state, verificationCode: otp });
                        }}
                        inputStyle={{
                          display: "block",
                          width: "100%",
                          height: "calc(2.25rem + 2px)",
                          padding: "0.45rem 0.9rem",
                          fontSize: "0.9rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                          color: "#6c757d",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #dee2e6",
                          borderRadius: "0.25rem",
                        }}
                      />
                    )}
                    <br />
                    <br />
                    <button
                      className="btn btn-outline btn-primary"
                      style={{ width: "100%" }}
                      onClick={!otpSent ? renderCaptcha : enrollMfa}
                    >
                      {mfaLoading ? (
                        <>
                          {" "}
                          <span
                            class="spinner-grow spinner-grow-sm mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...{" "}
                        </>
                      ) : !otpSent ? (
                        "Send Verification Code"
                      ) : (
                        "Verify & Enable Mfa"
                      )}
                    </button>
                  </div>
                </div>
              </Dialog>

              <SnackbarMessage
                open={showFetchError}
                onClose={() => setState({ ...state, showFetchError: false })}
                message={unitsErrorMessage}
              />

              <SnackbarMessage
                open={success}
                onClose={() => setState({ ...state, success: false })}
                message={"Your settings have been updated!"}
              />

              <SnackbarMessage
                open={failure}
                onClose={() => setState({ ...state, failure: false })}
                message={"Unable to update your settings at this time!"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnitSettings;
