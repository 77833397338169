import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../../config";
import moment from "moment";

const BatteryLifeChart = ({ vehicle, units }) => {
  const [state, setState] = useState({
    options: {
      chart: {
        foreColor: "#000000",

        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 5,
          color: "#00274c",
          opacity: 0.45,
        },
      },

      xaxis: {
        categories: [],
      },
      colors: ["#00274c"],

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2.75,
        dashArray: 0,
      },
    },
    series: [
      {
        name: "Miles",
        data: [],
      },
    ],
    loading: true,
  });

  const { options, series, loading } = state;

  useEffect(() => {
    getBatteryLife();
  }, []);

  const getBatteryLife = () => {
    const token = localStorage.getItem("token");

    fetch(`${API}/tesla/battery-range/${vehicle.vin}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let xAxisData = [];
        let yAxisData = [];
        data.batteryRangeData.forEach((d) => {
          xAxisData.push(moment(d.date).format("DD-MMM-YY"));
          yAxisData.push(parseFloat(d.battery_range).toFixed(0));
        });
        setState({
          ...state,
          loading: false,
          options: {
            ...options,
            yaxis: {
              title: { text: `Battery Range (${units && units.distanceunit})` },
            },
            xaxis: {
              type: "Date",
              categories: xAxisData,
              title: { text: "Date" },
            },
          },
          series: [
            {
              name: "Battery Life",
              data: yAxisData,
            },
          ],
        });
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return (
      <div class="card">
        <div class="card-body">
          <h4>Battery Range</h4>

          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card" style={{ height: "100%" }}>
      <div class="card-body">
        <h4 title={`This how your car's battery range has changed over time.`}>
          {" "}
          Battery Range
        </h4>
        <p>This is how the car's battery range has changed over time.</p>
        <Chart options={options} series={series} type="line" />
      </div>
    </div>
  );
};

export default BatteryLifeChart;
