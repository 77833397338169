import simpleFetch from "./fetch-wrapper";

const toyotaHelper = {
  loginWithToyota: async (_username, _password, _vin, _useRemoteControl) => {
    try {
      return await simpleFetch(
        "/toyota/login",
        "POST",
        JSON.stringify({
          _username,
          _password,
          _vin,
          _useRemoteControl,
        })
      );
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export default toyotaHelper;
