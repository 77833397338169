import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../../config";
import { getMonthlyOdometerDataService } from "../../../services/odometer-history-service";

const OdometerHistoryChart = ({ vehicle, units }) => {
  const [state, setState] = useState({
    options: {
      chart: {
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 5,
          color: "#00274c",
          opacity: 0.45,
        },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#00274c"],

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2.75,
        dashArray: 0,
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
    loading: true,
  });

  const { options, series, loading } = state;

  useEffect(() => {
    getOdometerData();
  }, [vehicle]);

  const getOdometerData = async () => {
    try {
      setState({ ...state, loading: true });

      const data = await getMonthlyOdometerDataService(vehicle.id);

      localStorage.setItem(
        "monthlyOdometerData",
        JSON.stringify({
          vehicleId: vehicle.id,
          odometerData: data.odometerData,
        })
      );
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const xaxisData = [];
      const yaxisData = [];

      if (!data.odometerData) {
        return setState({
          ...state,
          loading: false,
          options: {
            ...options,
            yaxis: {
              title: { text: "Odometer" },
            },
            xaxis: {
              type: "Month",
              categories: [],
              title: { text: "Month" },
            },
          },
          series: [
            {
              name: "Odometer",
              data: [],
            },
          ],
        });
      }

      data.odometerData.sort(function (odoData1, odoData2) {
        let res = 0;
        if (odoData1.yyyy === odoData2.yyyy) {
          if (odoData1.month < odoData2.month) {
            return -1;
          } else {
            return 1;
          }
        } else {
          if (odoData1.yyyy < odoData2.yyyy) {
            return -1;
          } else {
            return 1;
          }
        }
      });
      data.odometerData.forEach((dataPoint) => {
        xaxisData.push(`${monthNames[dataPoint.month - 1]} ${dataPoint.yyyy}`);
        yaxisData.push(parseInt(dataPoint.max));
      });
      setState({
        ...state,
        loading: false,
        options: {
          ...options,
          yaxis: {
            title: { text: "Odometer" },
          },
          xaxis: {
            type: "Month",
            categories: xaxisData,
            title: { text: "Month" },
          },
        },
        series: [
          {
            name: "Odometer",
            data: yaxisData,
          },
        ],
      });
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return (
      <div class="card">
        <div class="card-body">
          <h3>Odometer History</h3>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card" style={{ height: "100%" }}>
      <div class="card-body">
        <h3>Odometer History</h3>
        <Chart options={options} series={series} type="line" />
        <br />
      </div>
    </div>
  );
};

export default OdometerHistoryChart;
