import React, { useEffect } from "react";
import { exchangeAbrpAuthCode } from "../../../services/abrp-service";

const AbrpOauth = () => {
  useEffect(() => {
    const exchangeOauthToken = async () => {
      try {
        const query = new URLSearchParams(window.location.search);
        const abrpAuthorizationCode = query.get("code");
        const abrpState = query.get("state");
        const data = await exchangeAbrpAuthCode(abrpAuthorizationCode);

        console.log(data);
      } catch (err) {
        console.log(err);
      }
    };

    exchangeOauthToken();
  }, []);

  return (
    <div className="text-dark">
      <h3>Communicating With Abrp Servers...</h3>
    </div>
  );
};

export default AbrpOauth;
