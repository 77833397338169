import React, { useState } from "react";
import FormGenerator from "../../common/FormGenerator";
import SkodaHelper from "../../../services/connected-cars/skoda-service";
import { Dialog } from "@material-ui/core";


const Onboard = () => {
  const [state, setState] = useState({
    _username: null,
    _password: null,
    _vin: null,
    _timezone: null,
    _useRemoteControl: true,
    _error: null,
    _loading: false,
    _success: false
  });

  const {
    _password,
    _timezone,
    _useRemoteControl,
    _username,
    _vin,
    _loading,
    _error,
    _success
  } = state;

  const _onSubmit = async (e) => {
    try {
      e.preventDefault();

      setState({ ...state, _loading: true, _error: null });

      const data = await SkodaHelper.loginWithSkoda(_username, _password, );

      if(data.result.error) {
        setState({
          ...state,
          _loading: false,
          _error: "The Username/Password were rejected by the Škoda Servers!",
        });
      } else if(data.result.access_token) {
      setState({ ...state, _loading: false, _error: null, _success: true });
    
      }
    } catch (err) {
      setState({
        ...state,
        _loading: false,
        _error: "Unable to process your request at this time!",
      });
    }
  };

  const _handleChange = (e) => {
    try {
      setState({ ...state, [e.target.name]: e.target.value });
    } catch (err) {}
  };

  return (
    <div className="p-3 card card-body text-dark">
      <h1>Connect Škoda Account</h1>

      <p>
        <strong>Note:</strong> Keemut is in beta for Škoda, we are still
        evaluating different features that we get from Škoda vehicles.
      </p>
      <p>
        We don't store your username or password. We obtain a Access Token from
        Škoda to poll data on the user's behalf.
      </p>
      <br />
     
          <Dialog open={_success}>
          <div class="modal-content" style={{ width: "500px" }}>
            <div class="modal-header modal-colored-header bg-primary">
              <h4 class="modal-title" id="primary-header-modalLabel">
                Connection Successfull
              </h4>
             
            </div>
            <div className="modal-body">
          <p>
            Your Škoda account is now connected with Keemut. Thank you for testing the Connection. This is still a work-in-progress, we hope to have your vehicles onboard in the next update!
          </p>
         
        </div>
            <div class="modal-footer">
            <a href="/dashboard/vehicles">
            <button className="btn btn-primary">Continue</button>
          </a>
            </div>
          </div>
        </Dialog>
      {!_success ? <FormGenerator
        onSubmit={_onSubmit}
        inputs={[
          {
            label: "Skoda Username",
            inputType: "text",
            id: "Skoda_onboard_username_textfield",
            required: true,
            placeholder: "Enter Skoda Username",
            name: "_username",
            value: _username,
            handleChange: _handleChange,
          },
          {
            label: "Skoda Password",
            inputType: "password",
            id: "Skoda_onboard_password_textfield",
            required: true,
            placeholder: "Enter Skoda Password",
            name: "_password",
            value: _password,
            handleChange: _handleChange,
          },
        ]}
        error={_error}
        loading={_loading}
      /> : null}

      
    </div>
  );
};

export default Onboard;
