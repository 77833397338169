import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../../config";

const InitialPrice = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: true,
    price: 0,
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["Percent"],
    },
    series: [100],
  });
  const { options, series, price, loading } = state;

  useEffect(() => {
    fetch(
      `${API}/vehicle-list/price?make=${vehicle.make}&model=${vehicle.model}&trim=${vehicle.trim}&country=${vehicle.country_code}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setState({ ...state, price: data.price, loading: false });
      })
      .catch((err) => console.log(err));
  }, [vehicle]);

  if (loading) {
    return (
      <div className="text-center ">
        <div class="spinner-border text-primary" role="status"></div>

        <h5>Intial Retail Price</h5>
      </div>
    );
  }

  return (
    <div className="text-center ">
      <Chart options={options} series={series} type="radialBar" />
      <h2 className="text-primary">
        USD {price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}
      </h2>
      <h5>Intial Retail Price</h5>
    </div>
  );
};

export default InitialPrice;
