const CompatibleVehicles = [
  { Make: "Audi", Model: "A5", minYear: "2018+", maxYear: "" },
  { Make: "Audi", Model: "A5", minYear: "2018+", maxYear: "" },
  { Make: "Audi", Model: "A6", minYear: "2019+", maxYear: "" },
  { Make: "Audi", Model: "A7", minYear: "2019+", maxYear: "" },
  { Make: "Audi", Model: "A8", minYear: "2019+", maxYear: "" },
  { Make: "Audi", Model: "A8 PHEV", minYear: "2020+", maxYear: "" },
  { Make: "Audi", Model: "Allroad", minYear: "2017+", maxYear: "" },
  { Make: "Audi", Model: "e-tron", minYear: "2019+", maxYear: "" },
  { Make: "Audi", Model: "Q5", minYear: "2018+", maxYear: "" },
  { Make: "Audi", Model: "Q5 PHEV", minYear: "2020+", maxYear: "" },
  { Make: "Audi", Model: "RS5", minYear: "2018+", maxYear: "" },
  { Make: "Audi", Model: "RS7", minYear: "2019+", maxYear: "" },
  { Make: "Audi", Model: "S4", minYear: "2017+", maxYear: "" },
  { Make: "Audi", Model: "S5", minYear: "2018+", maxYear: "" },
  { Make: "Audi", Model: "S6", minYear: "2019+", maxYear: "" },
  { Make: "Audi", Model: "S7", minYear: "2017+", maxYear: "" },
  { Make: "Audi", Model: "S8", minYear: "2019+", maxYear: "" },
  { Make: "Audi", Model: "SQ5", minYear: "2018+", maxYear: "" },
  { Make: "Audi", Model: "SQ7", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "320", minYear: "2017-2018", maxYear: "" },
  { Make: "BMW", Model: "330", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "340", minYear: "2017", maxYear: "2018" },
  { Make: "BMW", Model: "430", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "440", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "530", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "540", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "640", minYear: "2017", maxYear: "2018" },
  { Make: "BMW", Model: "650", minYear: "2017", maxYear: "2018" },
  { Make: "BMW", Model: "740", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "750", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "840", minYear: "2020+", maxYear: "" },
  { Make: "BMW", Model: "228 Gran Coupe", minYear: "2020+", maxYear: "" },
  { Make: "BMW", Model: "328d", minYear: "2017", maxYear: "2018" },
  {
    Make: "BMW",
    Model: "330 Gran Turismo",
    minYear: "2017",
    maxYear: "2019",
  },
  { Make: "BMW", Model: "330e", minYear: "2017+", maxYear: "" },
  {
    Make: "BMW",
    Model: "340 Gran Turismo",
    minYear: "2017",
    maxYear: "2019",
  },
  { Make: "BMW", Model: "430 Gran Coupe", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "440 Gran Coupe", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "530e", minYear: "2018+", maxYear: "" },
  {
    Make: "BMW",
    Model: "535 Gran Turismo",
    minYear: "2017",
    maxYear: "2017",
  },
  { Make: "BMW", Model: "540d", minYear: "2018", maxYear: "2019" },
  {
    Make: "BMW",
    Model: "550 Gran Turismo",
    minYear: "2017",
    maxYear: "2017",
  },
  {
    Make: "BMW",
    Model: "640 Gran Coupe",
    minYear: "2017",
    maxYear: "2019",
  },
  {
    Make: "BMW",
    Model: "640 Gran Turismo",
    minYear: "2018",
    maxYear: "2019",
  },
  {
    Make: "BMW",
    Model: "650 Gran Coupe",
    minYear: "2017",
    maxYear: "2019",
  },
  { Make: "BMW", Model: "7 Series", minYear: "2016+", maxYear: "" },
  { Make: "BMW", Model: "740e", minYear: "2017", maxYear: "2019" },
  { Make: "BMW", Model: "745e", minYear: "2020+", maxYear: "" },
  { Make: "BMW", Model: "840 Gran Coupe", minYear: "2020+", maxYear: "" },
  {
    Make: "BMW",
    Model: "Alpina B6 Gran Coupe",
    minYear: "2017",
    maxYear: "2019",
  },
  { Make: "BMW", Model: "Apina B7", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "i3", minYear: "2014+", maxYear: "" },
  { Make: "BMW", Model: "i8", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "M2", minYear: "2017+", maxYear: "" },
  {
    Make: "BMW",
    Model: "M235 Gran Coupe",
    minYear: "2020+",
    maxYear: "",
  },
  { Make: "BMW", Model: "M240", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "M3", minYear: "2017", maxYear: "2018" },
  { Make: "BMW", Model: "M340", minYear: "2020+", maxYear: "" },
  { Make: "BMW", Model: "M4", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "M5", minYear: "2018+", maxYear: "" },
  { Make: "BMW", Model: "M6", minYear: "2017", maxYear: "2018" },
  {
    Make: "BMW",
    Model: "M6 Gran Coupe",
    minYear: "2017",
    maxYear: "2019",
  },
  { Make: "BMW", Model: "M8 Gran Coupe", minYear: "2020+", maxYear: "" },
  { Make: "BMW", Model: "X1", minYear: "2018+", maxYear: "" },
  { Make: "BMW", Model: "X2", minYear: "2018+", maxYear: "" },
  { Make: "BMW", Model: "X3", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "X3 M", minYear: "2020+", maxYear: "" },
  { Make: "BMW", Model: "X3 PHEV", minYear: "2020+", maxYear: "" },
  { Make: "BMW", Model: "X4", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "X4 M", minYear: "2020+", maxYear: "" },
  { Make: "BMW", Model: "X5", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "X5 eDrive", minYear: "2017", maxYear: "2018" },
  { Make: "BMW", Model: "X5 M", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "X6", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "X6 M", minYear: "2017+", maxYear: "" },
  { Make: "BMW", Model: "X7", minYear: "2019+", maxYear: "" },
  { Make: "BMW", Model: "Z4", minYear: "2019+", maxYear: "" },
  { Make: "Buick", Model: "Cascada", minYear: "2011+", maxYear: "" },
  { Make: "Buick", Model: "Enclave", minYear: "2011+", maxYear: "" },
  { Make: "Buick", Model: "Encore", minYear: "2013+", maxYear: "" },
  { Make: "Buick", Model: "Encore GX", minYear: "2020+", maxYear: "" },
  { Make: "Buick", Model: "Envision", minYear: "2016+", maxYear: "" },
  { Make: "Buick", Model: "LaCrosse", minYear: "2011", maxYear: "2019" },
  { Make: "Buick", Model: "Lucerne", minYear: "2011", maxYear: "2011" },
  { Make: "Buick", Model: "Regal", minYear: "2011", maxYear: "2017" },
  {
    Make: "Buick",
    Model: "Regal Sportback",
    minYear: "2018+",
    maxYear: "",
  },
  { Make: "Buick", Model: "Regal TourX", minYear: "2018+", maxYear: "" },
  { Make: "Buick", Model: "Verano", minYear: "2013", maxYear: "2017" },
  { Make: "Cadillac", Model: "ATS", minYear: "2016", maxYear: "2019" },
  { Make: "Cadillac", Model: "CT4", minYear: "2020+", maxYear: "" },
  { Make: "Cadillac", Model: "CT5", minYear: "2020+", maxYear: "" },
  { Make: "Cadillac", Model: "CT6", minYear: "2019+", maxYear: "" },
  {
    Make: "Cadillac",
    Model: "CT6 Plug-in",
    minYear: "2017",
    maxYear: "2018",
  },
  { Make: "Cadillac", Model: "CTS", minYear: "2011", maxYear: "2019" },
  { Make: "Cadillac", Model: "DTS", minYear: "2011", maxYear: "2011" },
  { Make: "Cadillac", Model: "ELR", minYear: "2014", maxYear: "2016" },
  { Make: "Cadillac", Model: "Escalade", minYear: "2011+", maxYear: "" },
  { Make: "Cadillac", Model: "SRX", minYear: "2011", maxYear: "2016" },
  { Make: "Cadillac", Model: "STS", minYear: "2011", maxYear: "2011" },
  { Make: "Cadillac", Model: "XT4", minYear: "2019+", maxYear: "" },
  { Make: "Cadillac", Model: "XT5", minYear: "2017+", maxYear: "" },
  { Make: "Cadillac", Model: "XT6", minYear: "2020+", maxYear: "" },
  { Make: "Chevrolet", Model: "Avalance", minYear: "2011", maxYear: "" },
  { Make: "Chevrolet", Model: "Aveo", minYear: "2011", maxYear: "" },
  { Make: "Chevrolet", Model: "Blazer", minYear: "2019+", maxYear: "" },
  { Make: "Chevrolet", Model: "Bolt EV", minYear: "2017+", maxYear: "" },
  { Make: "Chevrolet", Model: "Camaro", minYear: "2011+", maxYear: "" },
  { Make: "Chevrolet", Model: "Colorado", minYear: "2011+", maxYear: "" },
  { Make: "Chevrolet", Model: "Corvette", minYear: "2011+", maxYear: "" },
  { Make: "Chevrolet", Model: "Cruze", minYear: "2011", maxYear: "2019" },
  {
    Make: "Chevrolet",
    Model: "Cruze Limited",
    minYear: "2016",
    maxYear: "2016",
  },
  { Make: "Chevrolet", Model: "Equinox", minYear: "2011+", maxYear: "" },
  { Make: "Chevrolet", Model: "Express", minYear: "2011+", maxYear: "" },
  { Make: "Chevrolet", Model: "Impala", minYear: "2011+", maxYear: "" },
  { Make: "Chevrolet", Model: "Mailbu", minYear: "2011+", maxYear: "" },
  {
    Make: "Chevrolet",
    Model: "Mailbu Hybrid",
    minYear: "2016+",
    maxYear: "",
  },
  {
    Make: "Chevrolet",
    Model: "Silverado",
    minYear: "2011+",
    maxYear: "",
  },
  {
    Make: "Chevrolet",
    Model: "Silverado Heavy Duty",
    minYear: "2011+",
    maxYear: "",
  },
  {
    Make: "Chevrolet",
    Model: "Silverado Hybrid",
    minYear: "2011+",
    maxYear: "",
  },
  { Make: "Chevrolet", Model: "Sonic", minYear: "2012+", maxYear: "" },
  { Make: "Chevrolet", Model: "Spark", minYear: "2013+", maxYear: "" },
  {
    Make: "Chevrolet",
    Model: "Spark EV",
    minYear: "2014",
    maxYear: "2016",
  },
  { Make: "Chevrolet", Model: "Suburban", minYear: "2016+", maxYear: "" },
  { Make: "Chevrolet", Model: "Tahoe", minYear: "2011+", maxYear: "" },
  {
    Make: "Chevrolet",
    Model: "Tahoe Hybrid",
    minYear: "2011+",
    maxYear: "",
  },
  { Make: "Chevrolet", Model: "Traverse", minYear: "2011+", maxYear: "" },
  { Make: "Chevrolet", Model: "Trax", minYear: "2015+", maxYear: "" },
  { Make: "Chevrolet", Model: "Volt", minYear: "2011", maxYear: "2019" },
  { Make: "Chrysler", Model: "200", minYear: "2015", maxYear: "2017" },
  { Make: "Chrysler", Model: "300", minYear: "2015+", maxYear: "" },
  { Make: "Chrysler", Model: "Pacifica", minYear: "2017+", maxYear: "" },
  {
    Make: "Chrysler",
    Model: "Pacifica Hybrid",
    minYear: "2017+",
    maxYear: "",
  },
  {
    Make: "Chrysler",
    Model: "Town & Country",
    minYear: "2017",
    maxYear: "2017",
  },
  { Make: "Dodge", Model: "Challenger", minYear: "2015+", maxYear: "" },
  { Make: "Dodge", Model: "Charger", minYear: "2015+", maxYear: "" },
  { Make: "Dodge", Model: "Durango", minYear: "2014+", maxYear: "" },
  { Make: "Dodge", Model: "Viper SRT", minYear: "2013", maxYear: "2016" },
  { Make: "Ford", Model: "Ecosport", minYear: "2018+", maxYear: "" },
  { Make: "Ford", Model: "Edge", minYear: "2019+", maxYear: "" },
  { Make: "Ford", Model: "Escape", minYear: "2019+", maxYear: "" },
  { Make: "Ford", Model: "Expedition", minYear: "2018+", maxYear: "" },
  { Make: "Ford", Model: "Explorer", minYear: "2018+", maxYear: "" },
  { Make: "Ford", Model: "F-150", minYear: "2017+", maxYear: "" },
  { Make: "Ford", Model: "Fusion", minYear: "2017+", maxYear: "" },
  { Make: "Ford", Model: "Fusion Hybrid", minYear: "2017+", maxYear: "" },
  { Make: "Ford", Model: "Mustang", minYear: "2018+", maxYear: "" },
  { Make: "Ford", Model: "Ranger", minYear: "2019+", maxYear: "" },
  { Make: "Ford", Model: "Super Duty", minYear: "2019+", maxYear: "" },
  {
    Make: "Ford",
    Model: "Transit Connect",
    minYear: "2019+",
    maxYear: "",
  },
  { Make: "GMC", Model: "Acadia", minYear: "2011+", maxYear: "" },
  { Make: "GMC", Model: "Canyon", minYear: "2011+", maxYear: "" },
  { Make: "GMC", Model: "Savana", minYear: "2011+", maxYear: "" },
  { Make: "GMC", Model: "Sierra", minYear: "2011+", maxYear: "" },
  {
    Make: "GMC",
    Model: "Sierra Heavy Duty",
    minYear: "2011+",
    maxYear: "",
  },
  { Make: "GMC", Model: "Terrain", minYear: "2011+", maxYear: "" },
  { Make: "GMC", Model: "Yukon", minYear: "2011+", maxYear: "" },
  { Make: "GMC", Model: "Yuxon XL", minYear: "2016+", maxYear: "" },
  { Make: "Hyundai", Model: "Azera", minYear: "2012+", maxYear: "" },
  { Make: "Hyundai", Model: "Elantra", minYear: "2014+", maxYear: "" },
  {
    Make: "Hyundai",
    Model: "Elantra Coupe",
    minYear: "2014+",
    maxYear: "",
  },
  { Make: "Hyundai", Model: "Elantra GT", minYear: "2013+", maxYear: "" },
  { Make: "Hyundai", Model: "Equus", minYear: "2014+", maxYear: "" },
  { Make: "Hyundai", Model: "Genesis", minYear: "2013+", maxYear: "" },
  {
    Make: "Hyundai",
    Model: "Genesis Coupe",
    minYear: "2013+",
    maxYear: "",
  },
  {
    Make: "Hyundai",
    Model: "IONIQ Electric",
    minYear: "2017+",
    maxYear: "",
  },
  {
    Make: "Hyundai",
    Model: "IONIQ Hybrid",
    minYear: "2017+",
    maxYear: "",
  },
  {
    Make: "Hyundai",
    Model: "IONIQ Plug-in Hybrid",
    minYear: "2018+",
    maxYear: "",
  },
  { Make: "Hyundai", Model: "Kona", minYear: "2018+", maxYear: "" },
  {
    Make: "Hyundai",
    Model: "Kona Electric",
    minYear: "2019+",
    maxYear: "",
  },
  { Make: "Hyundai", Model: "NEXO", minYear: "2019+", maxYear: "" },
  { Make: "Hyundai", Model: "Palisade", minYear: "2020+", maxYear: "" },
  { Make: "Hyundai", Model: "Santa Fe", minYear: "2013+", maxYear: "" },
  {
    Make: "Hyundai",
    Model: "Santa Fe Sport",
    minYear: "2017+",
    maxYear: "",
  },
  {
    Make: "Hyundai",
    Model: "Santa Fe XL",
    minYear: "2019+",
    maxYear: "",
  },
  { Make: "Hyundai", Model: "Sonata", minYear: "2012+", maxYear: "" },
  {
    Make: "Hyundai",
    Model: "Sonata Hybrid",
    minYear: "2012+",
    maxYear: "",
  },
  {
    Make: "Hyundai",
    Model: "Sonata Plug",
    minYear: "2017+",
    maxYear: "",
  },
  { Make: "Hyundai", Model: "Tucson", minYear: "2014+", maxYear: "" },
  { Make: "Hyundai", Model: "Veloster", minYear: "2012+", maxYear: "" },
  { Make: "Hyundai", Model: "Venue", minYear: "2020+", maxYear: "" },
  { Make: "Jaguar", Model: "E-Pace", minYear: "2018+", maxYear: "" },
  { Make: "Jaguar", Model: "F-Pace", minYear: "2017+", maxYear: "" },
  { Make: "Jaguar", Model: "F-Type", minYear: "2016+", maxYear: "" },
  { Make: "Jaguar", Model: "I-Pace", minYear: "2019+", maxYear: "" },
  { Make: "Jaguar", Model: "XE", minYear: "2017+", maxYear: "" },
  { Make: "Jaguar", Model: "XF", minYear: "2016+", maxYear: "" },
  { Make: "Jaguar", Model: "XJ", minYear: "2016", maxYear: "2019" },
  { Make: "Jeep", Model: "Cherokee", minYear: "2014", maxYear: "2018" },
  { Make: "Jeep", Model: "Compass", minYear: "2017+", maxYear: "" },
  {
    Make: "Jeep",
    Model: "Grand Cherokee",
    minYear: "2014+",
    maxYear: "",
  },
  { Make: "Jeep", Model: "Renegade", minYear: "2018+", maxYear: "" },
  { Make: "Jeep", Model: "Wrangler JL", minYear: "2018+", maxYear: "" },
  {
    Make: "Land Rover",
    Model: "Defender",
    minYear: "2020+",
    maxYear: "",
  },
  {
    Make: "Land Rover",
    Model: "Discovery",
    minYear: "2017+",
    maxYear: "",
  },
  {
    Make: "Land Rover",
    Model: "Discovery Sport",
    minYear: "2016+",
    maxYear: "",
  },
  { Make: "Land Rover", Model: "LR4", minYear: "2016+", maxYear: "" },
  {
    Make: "Land Rover",
    Model: "Range Rover",
    minYear: "2016+",
    maxYear: "",
  },
  {
    Make: "Land Rover",
    Model: "Range Rover (PHEV) P400e",
    minYear: "2020+",
    maxYear: "",
  },
  {
    Make: "Land Rover",
    Model: "Range Rover Evoque",
    minYear: "2016+",
    maxYear: "",
  },
  {
    Make: "Land Rover",
    Model: "Range Rover Sport",
    minYear: "2016+",
    maxYear: "",
  },
  {
    Make: "Land Rover",
    Model: "Range Rover Sport (PHEV) P400e",
    minYear: "2020+",
    maxYear: "",
  },
  {
    Make: "Land Rover",
    Model: "Range Rover Velar",
    minYear: "2018+",
    maxYear: "",
  },
  { Make: "Lexus", Model: "CT200h", minYear: "2016+", maxYear: "" },
  { Make: "Lexus", Model: "ES", minYear: "2015+", maxYear: "" },
  { Make: "Lexus", Model: "GS", minYear: "2015+", maxYear: "" },
  { Make: "Lexus", Model: "GX", minYear: "2016+", maxYear: "" },
  { Make: "Lexus", Model: "IS", minYear: "2015+", maxYear: "" },
  { Make: "Lexus", Model: "LC", minYear: "2018+", maxYear: "" },
  { Make: "Lexus", Model: "LS", minYear: "2015+", maxYear: "" },
  { Make: "Lexus", Model: "LX", minYear: "2016+", maxYear: "" },
  { Make: "Lexus", Model: "NX", minYear: "2016+", maxYear: "" },
  { Make: "Lexus", Model: "RC", minYear: "2015+", maxYear: "" },
  { Make: "Lexus", Model: "RX", minYear: "2016+", maxYear: "" },
  { Make: "Lexus", Model: "UX", minYear: "2019+", maxYear: "" },
  { Make: "Lincoln", Model: "Aviator", minYear: "2020+", maxYear: "" },
  {
    Make: "Lincoln",
    Model: "Continental",
    minYear: "2017+",
    maxYear: "",
  },
  { Make: "Lincoln", Model: "MKC", minYear: "2015", maxYear: "2019" },
  { Make: "Lincoln", Model: "MKT", minYear: "2018", maxYear: "2019" },
  { Make: "Lincoln", Model: "MKX", minYear: "2015", maxYear: "2018" },
  { Make: "Lincoln", Model: "MKZ", minYear: "2015+", maxYear: "" },
  { Make: "Lincoln", Model: "MKZ Hybrid", minYear: "2018+", maxYear: "" },
  { Make: "Lincoln", Model: "Nautilus", minYear: "2019+", maxYear: "" },
  { Make: "Lincoln", Model: "Navigator", minYear: "2018+", maxYear: "" },
  {
    Make: "Lincoln",
    Model: "Navigator L",
    minYear: "2019+",
    maxYear: "",
  },
  { Make: "Nissan", Model: "Leaf", minYear: "2011+", maxYear: "" },
  { Make: "Ram", Model: "1500", minYear: "2013+", maxYear: "" },
  { Make: "Ram", Model: "2500", minYear: "2013+", maxYear: "" },
  { Make: "Ram", Model: "3500", minYear: "2013+", maxYear: "" },
  { Make: "Ram", Model: "Chassis Cab", minYear: "2013+", maxYear: "" },
  { Make: "Tesla", Model: "S", minYear: "2012+", maxYear: "" },
  { Make: "Tesla", Model: "X", minYear: "2016+", maxYear: "" },
  { Make: "Tesla", Model: "3", minYear: "2017+", maxYear: "" },
  { Make: "Tesla", Model: "Y", minYear: "2020+", maxYear: "" },
  {
    Make: "Tesla",
    Model: "Cybertruck",
    minYear: "coming soon",
    maxYear: "",
  },
  { Make: "Toyota", Model: "4Runner", minYear: "2020+", maxYear: "" },
  { Make: "Toyota", Model: "Avalon", minYear: "2019+", maxYear: "" },
  { Make: "Toyota", Model: "Camry", minYear: "2018+", maxYear: "" },
  {
    Make: "Toyota",
    Model: "Corolla Hatchback",
    minYear: "2019+",
    maxYear: "",
  },
  {
    Make: "Toyota",
    Model: "Corolla Sedan",
    minYear: "2020+",
    maxYear: "",
  },
  { Make: "Toyota", Model: "Highlander", minYear: "2020+", maxYear: "" },
  { Make: "Toyota", Model: "Prius Prime", minYear: "2021+", maxYear: "" },
  { Make: "Toyota", Model: "RAV4", minYear: "2019+", maxYear: "" },
  { Make: "Toyota", Model: "Sequoia", minYear: "2020+", maxYear: "" },
  { Make: "Toyota", Model: "Sienna", minYear: "2021+", maxYear: "" },
  { Make: "Toyota", Model: "Tacoma", minYear: "2020+", maxYear: "" },
  { Make: "Toyota", Model: "Tundra", minYear: "2020+", maxYear: "" },
  { Make: "Toyota", Model: "Venza", minYear: "2020+", maxYear: "" },
  { Make: "Volkswagen", Model: "Arteon", minYear: "2018+", maxYear: "" },
  { Make: "Volkswagen", Model: "Atlas", minYear: "2018+", maxYear: "" },
  { Make: "Volkswagen", Model: "Beetle", minYear: "2014+", maxYear: "" },
  {
    Make: "Volkswagen",
    Model: "Beetle Convertible",
    minYear: "2018+",
    maxYear: "",
  },
  { Make: "Volkswagen", Model: "CC", minYear: "2014", maxYear: "2017" },
  { Make: "Volkswagen", Model: "e Golf", minYear: "2015+", maxYear: "" },
  { Make: "Volkswagen", Model: "Eos", minYear: "2014", maxYear: "2016" },
  { Make: "Volkswagen", Model: "Golf", minYear: "2015+", maxYear: "" },
  {
    Make: "Volkswagen",
    Model: "Golf Alltrack",
    minYear: "2017+",
    maxYear: "",
  },
  {
    Make: "Volkswagen",
    Model: "Golf GTI",
    minYear: "2017+",
    maxYear: "",
  },
  { Make: "Volkswagen", Model: "Golf R", minYear: "2016+", maxYear: "" },
  {
    Make: "Volkswagen",
    Model: "Golf SportWagen",
    minYear: "2015+",
    maxYear: "",
  },
  { Make: "Volkswagen", Model: "Jetta", minYear: "2014+", maxYear: "" },
  {
    Make: "Volkswagen",
    Model: "Jetta GLI",
    minYear: "2018+",
    maxYear: "",
  },
  {
    Make: "Volkswagen",
    Model: "Jetta SportWagen",
    minYear: "2014",
    maxYear: "2014",
  },
  { Make: "Volkswagen", Model: "Passat", minYear: "2014+", maxYear: "" },
  { Make: "Volkswagen", Model: "Tiguan", minYear: "2014+", maxYear: "" },
];

export default CompatibleVehicles;
