import React, { useState, useEffect } from "react";
import ChangePassword from "./ChangePassword";

const AccountSettings = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 container p-3 ">
          <ChangePassword />
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
