import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import moment from "moment";
import resolveCurrency from "../../../helpers/Helper";
import Dialog from "@material-ui/core/Dialog";
import { Dropdown } from "semantic-ui-react";
import SubscriptionDialog from "../../common/SubscriptionDialog";
import {
  convertTimeToTimezone,
  convertEpochToUtcDate,
} from "../../../helpers/date-time-helper";

const ChargingHistory = ({ vehicle, units }) => {
  const [state, setState] = useState({
    charges: [],

    showUpdate: false,
    charge: null,
    loading: false,
    pricePerUnit: null,
    cost: null,
    vendor: null,
    chargers: [],
    tableLoading: true,
    paginations: 0,
    currentPage: 1,
    timeRange: "7days",
    showSubscriptionMessage: false,
    isSubscribed: false,
  });

  const {
    charges,
    tableLoading,
    loading,
    charge,
    showUpdate,
    pricePerUnit,
    cost,
    vendor,
    chargers,
    currentPage,
    paginations,
    timeRange,
    isSubscribed,
    showSubscriptionMessage,
  } = state;

  useEffect(() => {
    let mounted = true;

    const getSubscriptionStatus = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));

        if (!user.subscription_id) {
          setState({ ...state, isSubscribed: false });
        } else {
          const subRes = await fetch(
            `${API}/subscription/${user.subscription_id}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          const subData = await subRes.json();
          state.isSubscribed = subData.isSubscribed;
        }
      } catch (err) {}
    };

    if (mounted) {
      getSubscriptionStatus();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getChargingInfo();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [timeRange]);

  const getChargingInfo = async () => {
    try {
      setState({
        ...state,
        tableLoading: true,
      });
      const res = await fetch(
        `${API}/tesla/charging-history/${vehicle.vin}?timeRange=${timeRange}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await res.json();

      const eres = await fetch(`${API}/units/electricity-price`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ country: units.country, state: units.state }),
      });

      const edata = await eres.json();
      setState({
        ...state,
        charges: data.charges,
        pricePerUnit: edata.price,
        showUpdate: false,
        paginations: Math.ceil(data.charges.length / 10),
        tableLoading: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateCharge = async (e) => {
    try {
      setState({ ...state, loading: true });
      const res = await fetch(`${API}/tesla/charging-history`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          id: charge.id,
          vendor,
          pricePerUnit,
          cost,
        }),
      });

      setState({ ...state, loading: false, showUpdate: false });

      getChargingInfo();
    } catch (err) {}
  };

  const renderPagination = () => {
    let content = [];
    for (let i = 1; i <= paginations; i++) {
      content.push(
        <a
          onClick={() => setState({ ...state, currentPage: i })}
          class="page-link"
          style={
            currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
      );
    }
    return <> {content} </>;
  };

  // const getOcmLocation = async (lat, lng) => {
  //   try {
  //     let chargers = [];
  //     console.log("HERFE");
  //     const res = await fetch(
  //       `https://api.openchargemap.io/v3/poi/key=1397042b-3f69-4cbc-8acc-c64390cb1313/?latitude=${lat}&longitude=${lng}&distance=1`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "User-Agent": "keemut",
  //         },
  //       }
  //     );
  //     const data = await res.json();
  //     console.log(data);
  //     data.forEach((e) =>
  //       chargers.push({
  //         key: e.OperatorInfo ? e.OperatorInfo.Title : "",
  //         text: e.OperatorInfo ? e.OperatorInfo.Title : "",
  //         value: e.OperatorInfo ? e.OperatorInfo.Title : "",
  //       })
  //     );

  //     setState({ ...state, chargers  });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <div className="card card-body">
      <h4>Charging History</h4>

      <div className="form-group">
        <label className="mr-3">Show Charges From</label>
        <Dropdown
          search
          selection
          value={timeRange}
          onChange={(e, { value }) => {
            if (value != "7days") {
              if (!isSubscribed) {
                return setState({
                  ...state,
                  showSubscriptionMessage: true,
                });
              }
            }

            setState({ ...state, timeRange: value });
          }}
          options={[
            { key: "7days", value: "7days", text: "Last 7 days" },

            { key: "all", value: "all", text: "All" },
          ]}
        />
        <p className="my-2 text-muted">
          All the times are displayed in <strong>{units.timezone_name}</strong>{" "}
          Timezone. Your can update it in your{" "}
          <a href="/dashboard/settings" style={{ color: "blue" }}>
            Settings
          </a>
        </p>
      </div>
      {tableLoading ? (
        <div className="spinner-border spinner-center" role="status">
          {" "}
        </div>
      ) : (
        <table className="table-striped">
          <thead>
            <tr>
              <th>Start Time </th>
              <th>End Time </th>

              {/* <th>Vendor </th> */}
              <th>Cost </th>
              <th>
                {units.distanceunit == "mi" ? "Miles" : "Kilometers"} Added{" "}
              </th>
              <th>Energy Added (kWh)</th>
              <th>
                Charge Rate ({units.distanceunit == "mi" ? "mph" : "kph"})
              </th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {charges.map((e, i) =>
              i < currentPage * 10 && i >= (currentPage - 1) * 10 ? (
                <tr key={e.id}>
                  <td>
                    {moment(
                      convertTimeToTimezone(
                        convertEpochToUtcDate(e.start_time),
                        units.timezone_name
                      )
                    ).format("DD-MMM-YY hh:mm a")}
                  </td>
                  <td>
                    {moment(
                      convertTimeToTimezone(
                        convertEpochToUtcDate(e.end_time),
                        units.timezone_name
                      )
                    ).format("DD-MMM-YY hh:mm a")}
                  </td>
                  {/* <td>{e.vendor == null ? "N/A" : e.vendor}</td> */}
                  <td>
                    {e.cost == null
                      ? "N/A"
                      : resolveCurrency(units.currencyunit) +
                        " " +
                        (e.cost && parseFloat(e.cost).toFixed(2))}
                  </td>
                  <td>
                    {units.distanceunit == "mi"
                      ? e.miles_added
                      : e.miles_added * 1.6}
                  </td>
                  <td>{e.energy_added}</td>
                  <td>
                    {e.charge_rate}{" "}
                    {e.charge_rate >= 100 && (
                      <i
                        title="Supercharging"
                        className="mdi mdi-battery-charging-high text-danger"
                      ></i>
                    )}
                  </td>
                  <td>
                    <button
                      className="btn btn-outline-primary "
                      onClick={() => {
                        setState({
                          ...state,
                          showUpdate: true,
                          charge: e,
                          cost:
                            pricePerUnit *
                            e.energy_added *
                            units.currencyRateWrtUsd,
                          vendor: e.vendor,
                        });

                        // getOcmLocation(e.lat, e.long);
                      }}
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      )}
      <br />
      <div style={{ width: "100%" }}>
        <nav aria-label="Page navigation example" style={{ float: "right" }}>
          <ul class="pagination pagination">{renderPagination()}</ul>
        </nav>
      </div>

      <Dialog open={showUpdate}>
        <div class="modal-content" style={{ width: "400px" }}>
          <div class="modal-header modal-colored-header bg-primary">
            <h4 class="modal-title" id="primary-header-modalLabel">
              Update Charge
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => setState({ ...state, showUpdate: false })}
            >
              ×
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label className="overview-dialog-label">Date-Time</label>

              <input
                class="form-control"
                value={
                  charge
                    ? moment
                        .unix(charge.start_time)
                        .utc()
                        .format("DD-MMM-YY HH:MM A")
                    : null
                }
                disabled
                aria-describedby="basic-addon1"
              />
            </div>

            <div class="form-group">
              <label className="overview-dialog-label">Energy Added</label>

              <input
                class="form-control"
                value={charge ? charge.energy_added : null}
                disabled
                aria-describedby="basic-addon1"
              />
            </div>

            <div class="form-group">
              <label className="overview-dialog-label">Cost Per kWh</label>

              <input
                class="form-control"
                value={pricePerUnit}
                onChange={(e) => {
                  setState({
                    ...state,
                    pricePerUnit: e.target.value,
                    cost: charge.energy_added * e.target.value,
                  });
                }}
                aria-describedby="basic-addon1"
              />
            </div>

            <div class="form-group">
              <label className="overview-dialog-label">Total Cost</label>

              <input
                class="form-control"
                value={cost}
                onChange={(e) => {
                  setState({
                    ...state,
                    cost: e.target.value,
                  });
                }}
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="form-group">
              <label>Select Vendor</label>
              <input
                class="form-control"
                value={vendor}
                onChange={(e) => {
                  setState({
                    ...state,
                    vendor: e.target.value,
                  });
                }}
                aria-describedby="basic-addon1"
              />
              {/* <Dropdown
                search
                selection
                fluid
                options={chargers}
                onChange={(e, val) => {
                  setState({ ...state, vendor: val });
                }}
              /> */}
            </div>
            <button
              className="btn btn-block btn-outline-primary"
              onClick={updateCharge}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </Dialog>

      <SubscriptionDialog
        onClose={() => setState({ ...state, showSubscriptionMessage: false })}
        showSubscriptionMessage={showSubscriptionMessage}
      />
    </div>
  );
};

export default ChargingHistory;
