import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { API } from "../../../config";
import Dialog from "@material-ui/core/Dialog";
import resolveCurrency from "../../../helpers/Helper";
import RecentOdometer from "../common/RecentOdometer";
import { Helmet } from "react-helmet";
import { createExpenseFormService } from "../../../services/forms/expense-form-service";
import moment from "moment";
import NumberFormat from "../../../helpers/number-format-helper";

const ExpenseForm = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: true,
    vehicles: [],
    units: {},
    success: false,
    date: null,
    odometer: null,
    type: null,
    formData: new FormData(),
    cost: null,
    error: null,
    submitting: false,
    showOdoDialog: false,
  });

  const {
    loading,
    vehicles,
    units,
    success,
    date,
    odometer,
    formData,
    type,
    error,
    cost,
    submitting,
    showOdoDialog,
  } = state;

  useEffect(() => {
    intialRequest();
  }, []);

  const intialRequest = () => {
    const vehicles = JSON.parse(localStorage.getItem("vehicles"));
    const units = JSON.parse(localStorage.getItem("units"));
    setState({
      ...state,
      vehicles,
      units,
      loading: false,
    });
  };

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const isFormValid = (e) => {
    if (!date || !odometer || !cost || !type) {
      setState({ ...state, error: "Please Fillout All The Fields" });
      return false;
    } else {
      return true;
    }
  };

  const addExpense = async (e) => {
    try {
      e.preventDefault();
      if (isFormValid()) {
        setState({ ...state, submitting: true });

        formData.set("date", moment(date).format());
        formData.set("odometer", odometer.replaceAll(",", ""));
        formData.set("cost", cost);
        formData.set("type", type);

        const data = await createExpenseFormService(formData, vehicle.id);

        localStorage.setItem("odoChange", true);
        setState({
          ...state,
          submitting: false,
          loading: false,
          success: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return <div class="spinner-border text-primary" role="status"></div>;
  }

  if (success) {
    return <Redirect to="/dashboard/overview" />;
  }

  return (
    <div className="text-dark">
      <h3>Add Expense</h3>
      <Helmet>
        <title>Keemut | Expense</title>
      </Helmet>
      <div style={{ backgroundColor: "#ffffff" }} className="mx-2 my-3">
        <div className=" container p-3  card-body">
          <div className="row">
            <div className="col-lg-12">
              <div class="form-group">
                <label>Enter date</label>
                <input
                  type="date"
                  class="form-control date"
                  id="birthdatepicker"
                  data-toggle="date-picker"
                  data-single-date-picker="true"
                  name="date"
                  value={date}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <label>Enter Expense</label>

                <br />
                <div className="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter expense type"
                    name="type"
                    value={type}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>
                  Enter Odometer{" "}
                  <i
                    className={
                      date
                        ? "mdi mdi-information-outline text-danger"
                        : "mdi mdi-information-outline"
                    }
                    title="Click to see, last odometer value"
                    onClick={
                      date
                        ? () => setState({ ...state, showOdoDialog: true })
                        : () => {}
                    }
                  ></i>
                </label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter how much you paid"
                    name="odometer"
                    value={NumberFormat(
                      (odometer || "").toString().replaceAll(",", ""),
                      vehicle.country_code
                    )}
                    onChange={handleChange}
                  />
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">
                      {units.distanceunit}
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Amount Paid</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">
                      {resolveCurrency(units.currencyunit.toUpperCase())}
                    </label>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter how much you paid"
                    name="cost"
                    value={NumberFormat(
                      (cost || "").toString().replaceAll(",", ""),
                      vehicle.country_code
                    )}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="form-group">
                <label>Expense Receipt</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="inputGroupFile04"
                      name="attachment"
                      onChange={(e) => {
                        document.getElementById("file-name").innerHTML =
                          e.target.files[0].name;
                        formData.set("attachment", e.target.files[0]);
                      }}
                    />
                    <label
                      class="custom-file-label"
                      id="file-name"
                      for="inputGroupFile04"
                    >
                      Optional
                    </label>
                  </div>
                </div>
              </div>
              <br />
              <button
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={addExpense}
              >
                {submitting ? "Saving..." : "Submit"}
              </button>
            </div>

            <RecentOdometer
              vehicle={vehicle}
              date={date}
              showOdoDialog={showOdoDialog}
              closeOdoDialog={() =>
                setState({ ...state, showOdoDialog: false })
              }
            />

            <Dialog
              open={error}
              onClose={() => setState({ ...state, error: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div class="modal-content">
                <div class="modal-header modal-colored-header bg-warning">
                  <h4 class="modal-title" id="primary-header-modalLabel">
                    Please Fillout All the Fields
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={() => setState({ ...state, error: null })}
                  >
                    ×
                  </button>
                </div>
                <div class="modal-body">
                  It seems like you missed a few fields while filling up the
                  form.
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-light"
                    data-dismiss="modal"
                    onClick={() => setState({ ...state, error: null })}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseForm;
