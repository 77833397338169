import simpleFetch from "./fetch-wrapper";

const TataHelper = {
  /**
   *
   * @param {String} username
   * @param {String} password
   * @param {String} phoneNo
   * @returns
   */
  loginWithTata: async (username, password, phoneNo, isChild) => {
    try {
      return await simpleFetch(
        "/tata/auth",
        "POST",
        JSON.stringify({
          username,
          password,
          phoneNo,
          isChild,
        })
      );
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export default TataHelper;
