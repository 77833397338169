const unitsHelper = {
  /**
   * @description Converts miles to kilometers if required else returns the same value back
   * @param {Number} value
   * @returns {Number}
   */
  convertMiToKm: (value) => {
    try {
      const units = localStorage.getItem("units");

      // if distance unit is km then convert to mile else return as is
      if (units && units.distanceunit == "km") {
        return value * 1.60934;
      } else {
        return value;
      }
    } catch (err) {
      return value;
    }
  },
};

export default unitsHelper;
