import React, { useState } from "react";
import FormGenerator from "../../common/FormGenerator";
import HondaHelper from "../../../services/connected-cars/honda-service";
import { Dialog } from "@material-ui/core";
const Onboard = () => {
  const [state, setState] = useState({
    _username: null,
    _password: null,
    _vin: null,
    _timezone: null,
    _useRemoteControl: true,
    _error: null,
    _loading: false,
    _success: false,
  });

  const {
    _password,
    _timezone,
    _useRemoteControl,
    _username,
    _vin,
    _loading,
    _error,
    _success
  } = state;

  const _onSubmit = async (e) => {
    try {
      e.preventDefault();

      setState({ ...state, _loading: true, _error: null });

      const data = await HondaHelper.loginWithHonda(_username, _password);

      if(data.done)
        setState({ ...state, _loading: false, _error: null , _success: true});
      else 
      setState({
        ...state,
        _loading: false,
        _error: "Invalid Username or Password.",
      });


    } catch (err) {
      setState({
        ...state,
        _loading: false,
        _error: "Unable to process your request at this time!",
      });
    }
  };

  const _handleChange = (e) => {
    try {
      setState({ ...state, [e.target.name]: e.target.value });
    } catch (err) {}
  };

  return (
    <div className="p-3 card card-body text-dark">
      <h1>Connect Honda Account  <sup className="text-danger" style={{ fontSize: "18px" }}>
          BETA
        </sup>{" "}</h1>

      <p>
        <strong>Note:</strong> Keemut is in beta for Honda, we are still
        evaluating different features that we get from Honda vehicles.
      </p>
      <p>
        We don't store your username or password. We obtain a Access Token from
        Honda to poll data on the user's behalf.
      </p>
      <br />
      <FormGenerator
        onSubmit={_onSubmit}
        inputs={[
          {
            label: "Honda Username",
            inputType: "text",
            id: "Honda_onboard_username_textfield",
            required: true,
            placeholder: "Enter Honda Username",
            name: "_username",
            value: _username,
            handleChange: _handleChange,
          },
          {
            label: "Honda Password",
            inputType: "password",
            id: "Honda_onboard_password_textfield",
            required: true,
            placeholder: "Enter Honda Password",
            name: "_password",
            value: _password,
            handleChange: _handleChange,
          },

        ]}
        error={_error}
        loading={_loading}
      />

<Dialog
          open={_success}
          onClose={() => setState({ ...state, _success: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div class="modal-content modal-filled bg-success">
            <div class="modal-body p-4">
              <div class="text-center">
                <i class="dripicons-checkmark h1"></i>
                <h4 class="mt-2">Connection Success!</h4>
                <p class="mt-3">
                 Thank you for testing out the Honda Connection. We are still
        evaluating different features that we get from Honda vehicles. We will be adding more features in the future updates.
                </p>
               <a href="/dashboard/overview">
               <button
                  type="button"
                  class="btn btn-light my-2"
                  data-dismiss="modal"
                >
                 Continue
                </button>
               </a>
              </div>
            </div>
          </div>
        </Dialog>
      <div className="mt-3"></div>
    </div>
  );
};

export default Onboard;
