import React, { useEffect, useState } from "react";
import Slider from "@material-ui/core/Slider";
import { API } from "../../../config";
import moment from "moment";
import Chart from "react-apexcharts";
import { Dropdown } from "semantic-ui-react";
import NumberFormat from "../../../helpers/number-format-helper";
const BreakEven = ({ vehicle }) => {
  const [state, setState] = useState({
    arpm: null,
    aepm: null,
    residualPercent: null,
    loading: false,
    loanAmount: vehicle.monthly_payment * vehicle.duration,
    duration: vehicle.duration,
    monthlyPayment: vehicle.monthly_payment,
    year: vehicle.year.toString(),
    result: [],
    options: {
      chart: {
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: false,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 5,
          color: "#00274c",
          opacity: 0.45,
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        candlestick: {
          colors: {
            upward: "#6c757d",
            downward: "#6c757d",
          },
        },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#00274c"],

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2.75,
        dashArray: 0,
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],

    options1: {
      colors: ["#00274c"],
      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -10,
              show: false,
              color: "#000",
              fontSize: "0px",
            },

            value: {
              fontSize: "32px",
              formatter: function (val) {
                return val;
              },
            },
          },
        },
      },
      labels: [""],
    },
    series1: [100],

    options2: {
      colors: ["#00274c"],
      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -10,
              show: false,
              color: "#000",
              fontSize: "0px",
            },

            value: {
              fontSize: "32px",
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        },
      },
      labels: ["Revenue Per Unit"],
    },
    series2: [100],

    trimOptions: [{ value: vehicle.trim, text: vehicle.trim, key: "1" }],
    makeOptions: [{ value: vehicle.make, text: vehicle.make, key: "1" }],
    modelOptions: [{ value: vehicle.model, text: vehicle.model, key: "1" }],
    depreciation: -1,
    make: null,
    model: null,
    trim: null,
    downPayment: vehicle.downpayment || 0,
    startDate: moment(vehicle.created_at).format("YYYY-MM-DD"),
    purchaseOption: "Lease",
    success: false,
    bepData: [],
    domainY: [],
    years: [],
    useKeemutDep: true,
  });
  const {
    arpm,
    downPayment,
    aepm,
    residualPercent,
    loading,
    series,
    year,
    options,
    loanAmount,
    duration,
    monthlyPayment,
    result,
    trim,
    make,
    model,
    modelOptions,
    makeOptions,
    trimOptions,
    startDate,
    purchaseOption,
    options1,
    options2,
    series1,
    series2,
    success,
    bepData,
    domainY,
    depreciation,
    years,
    useKeemutDep,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const getMakeList = async () => {
        try {
          const makeRes = await fetch(`${API}/vehicle-list/make`, {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          });
          const makeData = await makeRes.json();

          const arpmRes = await fetch(`${API}/fleet/aepm-arpm/${vehicle.id}`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          const arpmData = await arpmRes.json();
          let makeOptions = [];
          makeData.makeList.forEach((m) => {
            if (m.make !== "All")
              makeOptions.push({ key: m.make, value: m.make, text: m.make });
          });
          setState({
            ...state,
            loading: false,
            makeOptions,
            make: vehicle.make,
            model: vehicle.model,
            trim: vehicle.trim,
            arpm: (arpmData.arpm && arpmData.arpm.toFixed(2)) || 0,
            aepm: (arpmData.aepm && arpmData.aepm.toFixed(2)) || 0,
          });
        } catch (err) {}
      };

      getMakeList();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    const getDepreciation = async () => {
      try {
        const res = await fetch(`${API}/depreciation?make=${make}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        });

        const data = await res.json();
        console.log(data);
        setState({
          ...state,
          depreciation: (
            (parseFloat(data.yearOverYearDepreciation[0].avg1) +
              parseFloat(data.yearOverYearDepreciation[0].avg2) +
              parseFloat(data.yearOverYearDepreciation[0].avg3) +
              parseFloat(data.yearOverYearDepreciation[0].avg4) +
              parseFloat(data.yearOverYearDepreciation[0].avg5)) /
            60
          ).toFixed(2),
        });
      } catch (err) {
        console.log(err);
      }
    };

    if (mounted) {
      getDepreciation();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [make]);

  useEffect(() => {
    let mounted = true;

    const getResidualValue = async () => {
      try {
        const res = await fetch(
          `${API}/fleet/residual-value?make=${make}&model=${model}&trim=${trim}&year=${year}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          }
        );

        const data = await res.json();

        setState({
          ...state,
          loanAmount: data.residualValue && data.residualValue.toFixed(2),
        });
      } catch (err) {
        console.log(err);
      }
    };

    if (mounted) {
      getResidualValue();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [make, model, trim, year]);

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const onSubmit = async () => {
    try {
      setState({ ...state, loading: true });
      const res = await fetch(`${API}/fleet/bep/${vehicle.id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          arpm,
          aepm,

          monthlyPayment,
          duration,
          loanAmount,
          make,
          model,
          trim,
          startDate,
          downPayment,
          depreciation: -1 * depreciation,
          year,
          purchaseOption,
        }),
      });
      const data = await res.json();

      let xaxis = [];
      let yaxis = [];

      let bepData = [];
      let years = [];
      let min = parseInt(data.result[0].bep);
      let max = parseInt(data.result[0].bep);

      data.result.forEach((row, i) => {
        xaxis.push(moment(row.date).format("MMM-YY"));
        yaxis.push(parseInt(row.bep));

        bepData.push({
          x: moment(row.date).format("MMM-YY"),
          y: [
            parseInt(row.bep) + parseInt(row.bep) * 0.025,
            parseInt(row.bep) + parseInt(row.bep) * 0.1,
            parseInt(row.bep) - parseInt(row.bep) * 0.1,
            parseInt(row.bep) - parseInt(row.bep) * 0.025,
          ],
        });
        years.push(moment(row.date).year());

        if (parseInt(row.bep) < min) {
          min = parseInt(row.bep);
        }

        if (parseInt(row.bep) > max) {
          max = parseInt(row.bep);
        }
      });

      setState({
        ...state,
        loading: false,
        result: data.result,
        options: {
          ...options,
          yaxis: {
            title: { text: "Amount" },
          },
          xaxis: {
            type: "Month",
            categories: xaxis,
            title: { text: "Month" },
          },
        },
        series: [{ name: "series", data: bepData }],

        series1: [data.monthsTillBreakeven],
        series2: [(data.roi * 100).toFixed(2)],
        success: true,
        bepData,
        domainY: [min, max],

        years,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getModelList = (make) => {
    fetch(`${API}/vehicle-list/model?make=${make}&year=${year}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let modelOptions = [];
        data.modelList.forEach((m) => {
          modelOptions.push({ key: m.model, value: m.model, text: m.model });
        });
        setState({
          ...state,
          loading: false,
          make,
          modelOptions,
        });
      })
      .catch((err) => console.log(err));
  };

  const getTrimList = (model) => {
    fetch(`${API}/vehicle-list/trim?model=${model}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        let trimOptions = [];
        data.trimList.forEach((m) => {
          trimOptions.push({ key: m.trim, value: m.trim, text: m.trim });
        });
        setState({
          ...state,
          loading: false,
          trimOptions,
          model,
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="card card-body">
        <h4>Vehicle Information</h4>

        <div className="row mt-3">
          <div className="col-lg-6">
            <div class="form-group">
              <label className="overview-dialog-label">Make</label>

              <Dropdown
                fluid
                search
                selection
                placeholder="Select Make"
                value={make}
                options={makeOptions}
                onChange={(e, { value }) => {
                  getModelList(value);
                }}
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group input-group">
              <label>Enter Model Year</label>

              <br />
              <Dropdown
                search
                placeholder="Select Model Year"
                fluid
                value={year}
                selection
                onChange={(e, { value }) => {
                  setState({ ...state, year: value });
                }}
                options={[
                  { key: "2015", text: "2015", value: "2015" },
                  { key: "2016", text: "2016", value: "2016" },
                  { key: "2017", text: "2017", value: "2017" },
                  { key: "2018", text: "2018", value: "2018" },
                  { key: "2019", text: "2019", value: "2019" },
                  { key: "2020", text: "2020", value: "2020" },
                ]}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div class="form-group">
              <label className="overview-dialog-label">Model</label>

              <div className="input-group">
                <Dropdown
                  fluid
                  placeholder="Select Model"
                  search
                  selection
                  value={model}
                  options={modelOptions}
                  onChange={(e, { value }) => {
                    getTrimList(value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div class="form-group">
              <label className="overview-dialog-label">Trim</label>

              <div className="input-group">
                <Dropdown
                  fluid
                  search
                  selection
                  placeholder="Select Trim"
                  options={trimOptions}
                  value={trim}
                  onChange={(e, { value }) => {
                    setState({
                      ...state,
                      trim: value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card card-body">
        <h4>Financial Information</h4>

        <div className="row mt-3">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Select Purchase Option</label>

              <Dropdown
                search
                selection
                fluid
                value={purchaseOption}
                options={[
                  { value: "Lease", text: "Lease / Finance", key: "2" },

                  { value: "Purchase", text: "Purchase", key: "1" },
                ]}
                onChange={(e, { value }) => {
                  if (value == "Purchase") {
                    setState({
                      ...state,
                      purchaseOption: value,
                      downPayment: 0,
                      monthlyPayment: 0,
                      duration: 0,
                    });
                  } else {
                    setState({ ...state, purchaseOption: value });
                  }
                }}
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group ">
              <label className="overview-dialog-label">Start Date</label>
              <input
                type="date"
                class="form-control date"
                data-toggle="input-mask"
                placeholder="09/09/2020"
                min="2000-01-01"
                name="startDate"
                value={startDate}
                onChange={handleChange}
                data-mask-format="00/00/0000"
                data-single-date-picker="true"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div class="form-group ">
              <label className="overview-dialog-label">{`${
                purchaseOption == "Purchase" ? "Purchase Price" : "Loan Amount"
              }`}</label>

              <div className="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    $
                  </span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  name="loanAmount"
                  value={loanAmount}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                  placeholder="How much is your loan ?"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="form-group ">
              <label className="overview-dialog-label">Monthly Payment</label>

              <div className="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    $
                  </span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  name="monthlyPayment"
                  value={monthlyPayment}
                  onChange={(e) => {
                    let value = e.target.value;

                    setState({
                      ...state,
                      monthlyPayment: value,
                    });
                  }}
                  aria-describedby="basic-addon1"
                  placeholder="How much is your monthly loan payment ?"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div class="form-group ">
              <label className="overview-dialog-label">Duration (months)</label>

              <div className="input-group">
                <input
                  type="number"
                  class="form-control"
                  name="duration"
                  value={duration}
                  onChange={(e) => {
                    let value = e.target.value;

                    setState({
                      ...state,
                      duration: value,
                    });
                  }}
                  aria-describedby="basic-addon1"
                  placeholder="How many months is your loan ?"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="form-group ">
              <label className="overview-dialog-label">
                Downpayment If Any
              </label>

              <div className="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    $
                  </span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  name="downPayment"
                  value={downPayment}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                  placeholder="How much is your downpayment ?"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <p>
              We have analyzed used car data for over 500k cars and we are
              continuously adjust our depreciation model. You can use that as
              default or select the monthly deprecation by adjusting the slider
              on the right
            </p>
          </div>
          <div className="col-lg-2">
            <div className="form-group">
              <label className="overview-dialog-label">
                Use Keemut Depreciation ?
              </label>
              <br />
              <input
                type="checkbox"
                id="switch0"
                data-switch="none"
                checked={useKeemutDep}
                onChange={() => {
                  setState({ ...state, useKeemutDep: !useKeemutDep });
                }}
              />
              <label for="switch0" data-on-label="" data-off-label=""></label>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Monthly Depreciation (%)</label>
              <Slider
                aria-labelledby="discrete-slider-always"
                step={0.25}
                min={-3}
                max={0}
                value={depreciation}
                valueLabelDisplay="on"
                disabled={useKeemutDep}
                onChange={(e, val) => {
                  setState({ ...state, depreciation: val });
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card card-body">
        <h4>Revenue Details</h4>
        <small>
          If you have added any revenue reports, they will be auto filled for
          you
        </small>

        <div className="row mt-3">
          <div className="col-lg-6">
            <div className="form-group">
              <label className="overview-dialog-label">
                Average Revenue Per Month
              </label>

              <div className="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    $
                  </span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  name="arpm"
                  value={arpm}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                  placeholder="How much is your monthly revenue ?"
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="form-group ">
              <label className="overview-dialog-label">
                Average Expense Per Month (Excluding Lease / Loan Payment)
              </label>

              <div className="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    $
                  </span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  name="aepm"
                  value={aepm}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                  placeholder="How much is your monthly expense ?"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        className="btn btn-primary my-3"
        onClick={onSubmit}
        style={{ width: "100%" }}
      >
        {loading ? "Calculating..." : "Calculate"}
      </button>

      {success && (
        <>
          <div className="row my-3">
            <div className="col-lg-9">
              <div className="card card-body">
                <Chart options={options} series={series} type="candlestick" />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card card-body">
                <h4>Months Till Breakeven</h4>
                <Chart options={options1} series={series1} type="radialBar" />
              </div>
              <div className="card card-body">
                <h4>Return On Investment</h4>
                <Chart options={options2} series={series2} type="radialBar" />
              </div>
            </div>
          </div>

          <div className="row px-4 my-4">
            <table className="table-striped">
              <thead>
                <th>Date</th>
                <th>revenue</th>
                <th>expense</th>
                <th>Profit / Loss</th>
                <th>Cumulative P/L</th>
                <th>Residual Value</th>
                <th>Loan Amount</th>
                <th>Break Even Point</th>
              </thead>

              <tbody>
                {result.map((row) => (
                  <tr>
                    <td>{moment(row.date).format("MMM-YY")}</td>
                    <td>
                      $
                      {row.cumulativeRevenue &&
                        NumberFormat(row.cumulativeRevenue.toFixed(2))}
                    </td>
                    <td>
                      $
                      {row.cumulativeExpense &&
                        NumberFormat(row.cumulativeExpense.toFixed(2))}
                    </td>
                    <td>
                      ${row.margin && NumberFormat(row.margin.toFixed(2))}
                    </td>
                    <td>
                      $
                      {row.cumulativeMargin &&
                        NumberFormat(row.cumulativeMargin.toFixed(2))}
                    </td>
                    <td>
                      $
                      {row.residualValue &&
                        NumberFormat(row.residualValue.toFixed(2))}
                    </td>
                    <td>
                      $
                      {row.loanAmount &&
                        NumberFormat(row.loanAmount.toFixed(2))}
                    </td>
                    <td
                      className={row.bep < 0 ? "text-danger" : "text-success"}
                    >
                      ${row.bep && NumberFormat(row.bep.toFixed(2))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default BreakEven;
