import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Dropdown, Icon } from "semantic-ui-react";
import { nextSite } from "../../config";
const Topbar = (props) => {
  // 2e0e5b
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="py-lg-3"
      style={{
        backgroundImage: "linear-gradient(to bottom, #5e41ac, #00274c )",
      }}
    >
      <div className="container">
        <Navbar.Brand href={nextSite}>
          {" "}
          <img
            src={require("../../assets/keemut-2.png")}
            alt=""
            class="logo-dark"
            height="40"
          />
          {/* <h1 style={{ color: "#fff" }}>Keemut</h1> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Nav.Link href="/how-it-works" className="text-light mx-4">
              How It Works
            </Nav.Link>
          </Nav>

          <Nav>
            <Nav.Link
              href="https://auto.keemut.com"
              className="text-light mx-4"
            >
              Research
            </Nav.Link>
          </Nav>

          <span className="mx-4">
            {/* <span className="text-light">Valuation</span> */}
            <Dropdown item className="white-text" text="Tools">
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Nav.Link href="/vin-decoder">
                    <span className="mr-5">Vin Decoder</span>
                  </Nav.Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>

          <Nav>
            <Nav.Link
              href={`${nextSite}/valuation`}
              className="text-light mx-4"
            >
              Valuation
            </Nav.Link>
          </Nav>
          <Nav className="mr-auto">
            <Nav.Link href="/sample-report" className="text-light mx-4">
              Sample Keemut Report™
            </Nav.Link>
          </Nav>

          <Nav>
            <Nav.Link
              href="/login"
              className="text-light"
              style={{ fontSize: "16px" }}
            >
              <strong>Login</strong>
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              href="/register"
              className="text-light"
              style={{ fontSize: "16px" }}
            >
              <strong>Register</strong>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Topbar;
