import React, { useState, useEffect } from "react";
import { API } from "../../../../config";
import moment from "moment";
import resolveCurrency from "../../../../helpers/Helper";
import SubscriptionDialog from "../../../common/SubscriptionDialog";
import {
  convertTimeToTimezone,
  convertEpochToUtcDate,
} from "../../../../helpers/date-time-helper";

const RecentCharges = ({ vehicle, units }) => {
  const [state, setState] = useState({
    charges: [],

    showUpdate: false,
    charge: null,
    loading: false,
    pricePerUnit: null,
    cost: null,
    vendor: null,
    chargers: [],
    tableLoading: true,
    paginations: 0,
    currentPage: 1,
    timeRange: "7days",
    showSubscriptionMessage: false,
    isSubscribed: false,
  });

  const {
    charges,
    tableLoading,
    loading,
    charge,
    showUpdate,
    pricePerUnit,
    cost,
    vendor,
    chargers,
    currentPage,
    paginations,
    timeRange,
    isSubscribed,
    showSubscriptionMessage,
  } = state;

  useEffect(() => {
    let mounted = true;

    const getSubscriptionStatus = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));

        if (!user.subscription_id) {
          setState({ ...state, isSubscribed: false });
        } else {
          const subRes = await fetch(
            `${API}/subscription/${user.subscription_id}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          const subData = await subRes.json();
          state.isSubscribed = subData.isSubscribed;
        }
      } catch (err) {}
    };

    if (mounted) {
      getSubscriptionStatus();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getChargingInfo();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [timeRange]);

  const getChargingInfo = async () => {
    try {
      setState({
        ...state,
        tableLoading: true,
      });
      const res = await fetch(
        `${API}/tesla/charging-history/${vehicle.vin}?timeRange=${timeRange}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const data = await res.json();

      const eres = await fetch(`${API}/units/electricity-price`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ country: units.country, state: units.state }),
      });

      const edata = await eres.json();

      setState({
        ...state,
        charges: data.charges,
        pricePerUnit: edata.price,
        showUpdate: false,
        paginations: Math.ceil(data.charges.length / 10),
        tableLoading: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateCharge = async (e) => {
    try {
      setState({ ...state, loading: true });
      const res = await fetch(`${API}/tesla/charging-history`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          id: charge.id,
          vendor,
          pricePerUnit,
          cost,
        }),
      });

      setState({ ...state, loading: false, showUpdate: false });

      getChargingInfo();
    } catch (err) {}
  };

  const renderPagination = () => {
    let content = [];
    for (let i = 1; i <= paginations; i++) {
      content.push(
        <a
          onClick={() => setState({ ...state, currentPage: i })}
          class="page-link"
          style={
            currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
      );
    }
    return <> {content} </>;
  };

  // const getOcmLocation = async (lat, lng) => {
  //   try {
  //     let chargers = [];
  //     console.log("HERFE");
  //     const res = await fetch(
  //       `https://api.openchargemap.io/v3/poi/key=1397042b-3f69-4cbc-8acc-c64390cb1313/?latitude=${lat}&longitude=${lng}&distance=1`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "User-Agent": "keemut",
  //         },
  //       }
  //     );
  //     const data = await res.json();
  //     console.log(data);
  //     data.forEach((e) =>
  //       chargers.push({
  //         key: e.OperatorInfo ? e.OperatorInfo.Title : "",
  //         text: e.OperatorInfo ? e.OperatorInfo.Title : "",
  //         value: e.OperatorInfo ? e.OperatorInfo.Title : "",
  //       })
  //     );

  //     setState({ ...state, chargers  });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <div className="card card-body" style={{ height: "100%" }}>
      <div className="row mb-4">
        <div className="col-lg-6">
          <h3>Recent Charging History</h3>
        </div>
        <div className="col-lg-6" style={{ textAlign: "end" }}>
          <a href="/dashboard/logbook/?defaultTab=1">
            <button className="btn btn-primary" style={{ width: "100px" }}>
              Show more
            </button>
          </a>
        </div>
      </div>

      {tableLoading ? (
        <div className="spinner-border spinner-center" role="status">
          {" "}
        </div>
      ) : (
        <table className="table-striped">
          <thead>
            <tr>
              <th>Start Time </th>

              {/* <th>Vendor </th> */}
              <th>Cost </th>
              <th>
                {units.distanceunit == "mi" ? "Miles" : "Kilometers"} Added{" "}
              </th>

              <th>
                Charge Rate ({units.distanceunit == "mi" ? "mph" : "kph"})
              </th>
            </tr>
          </thead>

          <tbody>
            {charges.map((e, i) =>
              i < currentPage * 4 && i >= (currentPage - 1) * 4 ? (
                <tr key={e.id}>
                  <td>
                    {moment(
                      convertTimeToTimezone(
                        convertEpochToUtcDate(e.start_time),
                        units.timezone_name
                      )
                    ).format("DD-MMM-YY hh:mm a")}
                  </td>

                  {/* <td>{e.vendor == null ? "N/A" : e.vendor}</td> */}
                  <td>
                    {e.cost == null
                      ? "N/A"
                      : resolveCurrency(units.currencyunit) +
                        " " +
                        (e.cost && parseFloat(e.cost).toFixed(2))}
                  </td>
                  <td>
                    {units.distanceunit == "mi"
                      ? e.miles_added
                      : e.miles_added * 1.6}
                  </td>
                  <td>{e.energy_added}</td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      )}
      <br />
      <SubscriptionDialog
        onClose={() => setState({ ...state, showSubscriptionMessage: false })}
        showSubscriptionMessage={showSubscriptionMessage}
      />
    </div>
  );
};

export default RecentCharges;
