import React, { useState, useEffect } from "react";
import { API } from "../../config";

const VehicleInfo = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: false,
    exteriorColor: null,
  });
  const { loading, exteriorColor } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (vehicle.make == "Tesla") getConfigTesla();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getConfigTesla = async () => {
    try {
      const res = await fetch(`${API}/tesla/config/${vehicle.vin}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      const data = await res.json();
      if (data.done) {
        setState({ ...state, exteriorColor: data.teslaConfig.exterior_color });
      } else {
        setState({ ...state, exteriorColor: "Black" });
      }
    } catch (err) {}
  };

  return (
    <div id="keemut-report_vehicle_info_card" class="card widget-flat">
      <div class="card-body">
        <div class="float-right">
          <i class="  mdi mdi-robot-mower widget-icon bg-success-lighten text-success"></i>
        </div>
        <h5
          class="text-dark font-weight-normal mt-0"
          title="Distance Driven till date"
        >
          {vehicle.vin}
        </h5>
        <h3 class="mt-3 mb-3">
          {vehicle.make} {vehicle.model}
        </h3>
        <p>Year : {vehicle.year}</p>
        <p>Drivetrain: {vehicle.drivetrain || "N/A"}</p>
        <p>Exterior Color: {exteriorColor || "N/A"}</p>
      </div>
    </div>
  );
};

export default VehicleInfo;
