import React, { useState, useEffect } from "react";
import FuelHistoryTable from "./FuelHistoryTable";
import FuelChart from "./FuelChart";
import FuelMpgChart from "./FuelMpgChart";
import FuelUpCountCard from "./FuelUpCountCard";
import ExpectedFuel from "./ExpectedFuel";
import AverageFuelEconomy from "./AverageFuelEconomy";
import { Redirect } from "react-router-dom";
import { API } from "../../../config";
import CityHighwayMpg from "./CityHighwayMpg";
const FuelHistory = ({ vehicle }) => {
  const [state, setState] = useState({
    redirect: false,
    units: {},
    loading: true,
  });
  const { redirect, units, loading } = state;

  useEffect(() => {
    if (vehicle.make === "Tesla") {
      return setState({ ...state, redirect: true, loading: false });
    }
    const token = localStorage.getItem("token");
    const unitsJson = localStorage.getItem("units");
    const user = JSON.parse(localStorage.getItem("user"));
    if (unitsJson) {
      return setState({
        ...state,
        units: JSON.parse(unitsJson),
        loading: false,
      });
    }

    fetch(`${API}/units/${user.uid}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((jsonData) => {
        localStorage.setItem("units", JSON.stringify(jsonData.units));

        setState({
          ...state,

          units: jsonData.units,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          units: { currenyunit: "usd", distanceunit: "mi", fuelunit: "gal" },
          loading: false,
        });
      });
  }, [vehicle]);

  if (loading) {
    return (
      <>
        <div class="spinner-border text-primary" role="status"></div>
      </>
    );
  }

  if (redirect) {
    return <Redirect to="/dashboard/energy-history" />;
  }
  return (
    <div className="text-dark">
      <a href="/dashboard/fuel">
        <div
          class="btn btn-primary text-center "
          role="alert"
          style={{ width: "100%" }}
        >
          Add Fuel
        </div>
      </a>
      <div className="mx-2 my-3">
        <div className="row">
          <div className="col-lg-4">
            <FuelUpCountCard vehicle={vehicle} units={units} />
          </div>
          <div className="col-lg-4">
            <ExpectedFuel vehicle={vehicle} units={units} />
          </div>
          <div className="col-lg-4">
            <AverageFuelEconomy vehicle={vehicle} units={units} />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <FuelHistoryTable vehicle={vehicle} units={units} />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <FuelChart vehicle={vehicle} units={units} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FuelMpgChart vehicle={vehicle} units={units} />
        </div>

        <div className="col-lg-6">
          <CityHighwayMpg vehicle={vehicle} units={units} />
        </div>
      </div>
    </div>
  );
};

export default FuelHistory;
