import React from "react";
import { nextSite } from "../../config";

const VerifyEmail = () => {
  return (
    <div class="authentication-bg" style={{ height: "100vh" }}>
      <div class="account-pages mb-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="card mt-5">
                <div class="card-header pt-4 pb-4 text-center bg-primary">
                  <a href={`${nextSite}/`}>
                    <span>
                      <img
                        src={require("../../assets/keemut-2.png")}
                        alt=""
                        width="100"
                        height="30"
                      />
                    </span>
                  </a>
                </div>

                <div class="card-body p-4">
                  <div class="text-center m-auto">
                    <img
                      src={require("../../assets/mail_sent.svg")}
                      alt="mail sent image"
                      height="64"
                    />
                    <h4 class="text-dark-50 text-center mt-4 font-weight-bold">
                      Please check your email
                    </h4>
                    <p class="text-muted mb-4">
                      A email has been send to your registered email. Please
                      check for an email from company and click on the included
                      link to reset your password.
                    </p>
                  </div>

                  <form>
                    <div class="form-group mb-0 text-center">
                      <button class="btn btn-primary">
                        <a href="/login" style={{ color: "#ffffff" }}>
                          <i class="mdi mdi-home mr-1"></i> Back to Login
                        </a>
                      </button>
                      {/* <button class="btn btn-primary ml-2">
                        <a href="/login" style={{ color: "#ffffff" }}>
                          <i class=" mdi mdi-email-send-outline mr-1"></i>{" "}
                          Resend Email
                        </a>
                      </button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="footer footer-alt">2020 © Keemut</footer>
    </div>
  );
};

export default VerifyEmail;
