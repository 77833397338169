import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../../config";

const FuelMpgChart = ({ vehicle, units }) => {
  const [state, setState] = useState({
    options: {
      chart: {
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 5,
          color: "#00274c",
          opacity: 0.45,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
      },
      yaxis: { title: { text: "Count" } },
      xaxis: {
        categories: [],
      },
      colors: ["#00274c"],
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
    loading: true,
    noData: false,
  });

  const { options, series, loading, noData } = state;

  useEffect(() => {
    getFuelMpgData();
  }, [vehicle]);

  const getFuelMpgData = () => {
    setState({ ...state, loading: true });
    const fuelMpgJson = localStorage.getItem("fuelMpg");

    if (fuelMpgJson) {
      const { x0, x1, x2, x3, x4, x5, x6, x7, x8, make, model } = JSON.parse(
        fuelMpgJson
      );

      if (vehicle.make === make && vehicle.model === model) {
        return setState({
          ...state,
          loading: false,
          options: {
            ...options,
            xaxis: {
              type: "Fuel Economy",
              categories: [x0, x1, x2, x3, x4, x5, x6, x7, x8],
              title: {
                text: `Miles Per ${
                  units.fuelunit == "gal" ? "Gallon" : "Litre"
                }`,
              },
            },
          },
          series: [
            {
              name: "Count",
              data: [
                Math.floor(1 + Math.random() * (50 + 1 - 1)),
                Math.floor(52 + Math.random() * (100 + 1 - 52)),
                Math.floor(105 + Math.random() * (180 + 1 - 105)),
                Math.floor(200 + Math.random() * (300 + 1 - 200)),
                320,
                Math.floor(300 + Math.random() * (100 + 1 - 200)),
                Math.floor(105 + Math.random() * (180 + 1 - 105)),
                Math.floor(52 + Math.random() * (100 + 1 - 52)),
                Math.floor(1 + Math.random() * (50 + 1 - 1)),
              ],
            },
          ],
        });
      }
    }
    const token = localStorage.getItem("token");
    fetch(
      `${API}/vehicle-list/mpg/?trim=${vehicle.trim}&make=${vehicle.make}&model=${vehicle.model}&country=${vehicle.country_code}&crowdsource=${vehicle.is_crowdsourced}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data.done) {
          setState({ ...state, loading: false, noData: true });
        } else {
          console.log(data);
          let { fuelMpg, std } = data;

          if (units.distanceunit == "km" && units.fuelunit == "ltr") {
            fuelMpg *= 0.43;
          }

          const x4 = parseFloat(fuelMpg).toFixed(3);

          const x3 = parseFloat(fuelMpg - std / 2).toFixed(1);
          const x2 = parseFloat(x3 - std / 2).toFixed(1);
          const x1 = parseFloat(x2 - std / 2).toFixed(1);
          const x0 = parseFloat(x1 - std / 2).toFixed(1);
          const x5 = parseFloat(x0 + std / 3).toFixed(1);
          const x6 = parseFloat(x5 + std / 3).toFixed(1);
          const x7 = parseFloat(x6 + std / 3).toFixed(1);
          const x8 = parseFloat(x7 + std / 3).toFixed(1);
          console.log(x5, x6, x7, x8);
          localStorage.setItem(
            "fuelMpg",
            JSON.stringify({
              x0,
              x1,
              x2,
              x3,
              x4,
              x5,
              x6,
              x7,
              x8,
              make: vehicle.make,
              model: vehicle.model,
            })
          );

          setState({
            ...state,
            loading: false,
            options: {
              ...options,
              xaxis: {
                type: "Fuel Economy",
                categories: [x0, x1, x2, x3, x4, x5, x6, x7, x8],
                title: { text: "Miles Per Gallon" },
              },
            },
            series: [
              {
                name: "Count",
                data: [
                  Math.floor(1 + Math.random() * (50 + 1 - 1)),
                  Math.floor(52 + Math.random() * (100 + 1 - 52)),
                  Math.floor(105 + Math.random() * (180 + 1 - 105)),
                  Math.floor(200 + Math.random() * (300 + 1 - 200)),
                  320,
                  Math.floor(300 + Math.random() * (100 + 1 - 200)),
                  Math.floor(105 + Math.random() * (180 + 1 - 105)),
                  Math.floor(52 + Math.random() * (100 + 1 - 52)),
                  Math.floor(1 + Math.random() * (50 + 1 - 1)),
                ],
              },
            ],
          });
        }
      });
  };

  if (loading) {
    return (
      <div class="card">
        <div class="card-body">
          <h4> Community Fuel Economy Data</h4>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  if (noData) {
    return (
      <div class="card">
        <div class="card-body">
          <h4> Community Fuel Economy Data</h4>
          <br /> <br />
          Oops, It seems like we dont have any data for this car, Please Check
          back later for more information.
        </div>
      </div>
    );
  }

  return (
    <div class="card" style={{ height: "100%" }}>
      <div class="card-body">
        <h4> Community Fuel Data</h4>
        <Chart options={options} series={series} type="bar" />
      </div>
    </div>
  );
};

export default FuelMpgChart;
