import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Popup } from "semantic-ui-react";
import { API } from "../../../config";

const AverageBatteryLife = ({ vehicle, units }) => {
  const [state, setState] = useState({
    idealRange: null,
    loading: true,
    averageBatteryLife: null,
    options: {
      colors: ["#00274c"],
      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -10,
              show: false,
              color: "#000",
              fontSize: "0px",
            },
            value: {
              color: "#000000",
              fontSize: "44px",
              show: true,
            },
          },
        },
      },
      labels: [" "],
    },
    series: [97.57],
  });
  const { idealRange, averageBatteryLife } = state;

  useEffect(() => getAverageBatteryLife(), [vehicle]);

  const getAverageBatteryLife = () => {
    const token = localStorage.getItem("token");
    setState({ ...state, loading: true });

    let url;

    if (vehicle.make == "Tesla")
      url = `/tesla/battery-life/${vehicle.vin}/?model=${vehicle.model}&trim=${vehicle.trim}`;
    else if (vehicle.is_smartcar) url = `/smartcar/battery-life/${vehicle.vin}`;

    fetch(`${API}${url}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setState({
          ...state,
          series: [parseFloat(data.averageBatteryLife).toFixed(2)],
          idealRange: data.idealRange,
          averageBatteryLife: parseFloat(data.averageBatteryLife).toFixed(2),
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, series: [NaN], loading: false });
      });
  };

  const { options, series, loading } = state;

  if (loading) {
    return (
      <div class="card" style={{ height: "100%" }}>
        <div class="card-body">
          <h4
            title={`This how your car's battery life has degraded over time.`}
          >
            {" "}
            Battery Health
          </h4>
          <p>This is the estimated Health of your car.</p>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card" style={{ height: "100%" }}>
      <div class="card-body">
        <h3>
          {" "}
          Battery Health For {vehicle.trim}{" "}
          <Popup
            className="ml-4"
            content={
              "This is the current battery health after taking into account the degradation"
            }
            trigger={
              <i className="mdi mdi-information-outline hover-pointer"></i>
            }
          />
        </h3>
        <p>
          Your Vehicle Full Capacity Range :{" "}
          {parseInt(idealRange * (averageBatteryLife / 100))}{" "}
          {units && units.distanceunit}{" "}
        </p>
        <p>
          Expected Full Capacity Range : {parseInt(idealRange)}{" "}
          {units && units.distanceunit}
        </p>
        <Chart options={options} series={series} type="radialBar" />
      </div>
    </div>
  );
};

export default AverageBatteryLife;
