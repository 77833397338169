import React from "react";

const NotFound = () => {
  return (
    <div
      className="account-pages authentication-bg "
      style={{ minHeight: "100vh" }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="card mt-5">
              <div className="card-header pt-4 pb-4 text-center bg-primary">
                <a href="/">
                  <span>
                    <img
                      src={require("../../assets/keemut-2.png")}
                      alt=""
                      width="100"
                      height="30"
                    />
                  </span>
                </a>
              </div>

              <div className="card-body p-4">
                <div className="text-center">
                  <h1 className="text-error">
                    4<i className="mdi mdi-emoticon-sad"></i>4
                  </h1>
                  <h4 className="text-uppercase text-danger mt-3">
                    Page Not Found
                  </h4>
                  <p className="text-muted mt-3">
                    It's looking like you may have taken a wrong turn. Don't
                    worry... it happens to the best of us. Here's a little tip
                    that might help you get back on track.
                  </p>

                  <a className="btn btn-primary mt-3" href="/">
                    <i className="mdi mdi-reply"></i> Return Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
