import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import resolveCurrency from "../../../helpers/Helper";
import moment from "moment";
import { API } from "../../../config";
import {
  editFuelReportService,
  getFuelReportByIdService,
} from "../../../services/forms/fuel-form-service";
const EditFuelDialog = ({
  fuelReportId,
  units,
  vehicle,
  showEditDialog,
  closeEditDialog,
}) => {
  const [state, setState] = useState({
    date: null,
    odometer: null,
    refuelDistance: null,
    amount: null,
    cost: null,
    workRelated: false,
    prevDate: null,
    prevOdometer: null,
    formData: new FormData(),
    attachment: null,
    saving: false,
  });
  const {
    date,
    odometer,
    refuelDistance,
    amount,

    cost,
    workRelated,
    prevDate,
    prevOdometer,
    attachment,
    formData,
    saving,
  } = state;

  useEffect(() => {
    const _getFuelReportById = async () => {
      try {
        const data = await getFuelReportByIdService(fuelReportId);

        if (data.done && data.fuelReport) {
          setState({
            ...state,
            odometer: data.fuelReport.odometer,
            date: moment(data.fuelReport.date).format("YYYY-MM-DD"),
            prevOdometer: data.fuelReport.odometer,
            prevDate: moment(data.fuelReport.date).format("YYYY-MM-DD"),
            refuelDistance: data.fuelReport.refueldistance,
            workRelated: data.fuelReport.workrelated,
            amount: data.fuelReport.amount,
            cost: data.fuelReport.cost,
          });
        }
      } catch (err) {
        console.log(err);
        setState({
          ...state,
          error: "Unable to fetch the fuel report, Please try later",
        });
      }
    };

    let mounted = true;

    if (mounted) {
      _getFuelReportById();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [fuelReportId]);

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const updateFuelReport = async (e) => {
    try {
      e.preventDefault();
      setState({ ...state, saving: true });
      formData.set("odometer", parseInt(odometer));
      formData.set("prevOdometer", parseInt(prevOdometer));
      formData.set("date", moment(date).format("YYYY-MM-DD"));
      formData.set("prevDate", moment(prevDate).format("YYYY-MM-DD"));
      formData.set("amount", parseFloat(amount));
      formData.set("pricePerUnit", parseFloat(cost) / parseFloat(amount));
      formData.set("workRelated", workRelated);
      formData.set("refuelDistance", parseFloat(refuelDistance));
      formData.set("cost", cost);
      formData.set("attachment", attachment);
      const token = localStorage.getItem("token");
      const data = await editFuelReportService(
        vehicle.id,
        fuelReportId,
        formData
      );
      closeEditDialog();
    } catch (err) {
      closeEditDialog();
    }
  };

  return (
    <Dialog
      open={showEditDialog}
      onClose={closeEditDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div class="modal-content dialog">
        <div class="modal-header modal-colored-header bg-info">
          <h4
            class="modal-title"
            id="primary-header-modalLabel"
            style={{ color: "#ffffff", fontFamily: "Roboto" }}
          >
            Edit Fuel Report
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={closeEditDialog}
          >
            ×
          </button>
        </div>
        <div class="modal-body dialog">
          <div className="p-3">
            <div class="form-group">
              <label className="overview-dialog-label">Enter date</label>

              <input
                type="date"
                class="form-control date"
                id="birthdatepicker"
                data-toggle="date-picker"
                data-single-date-picker="true"
                name="date"
                value={date}
                onChange={handleChange}
              />
            </div>
            <div className="form-group ">
              <label className="overview-dialog-label">Enter Odometer</label>
              <div className="input-group">
                {" "}
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter odometer"
                  name="odometer"
                  value={odometer}
                  onChange={handleChange}
                />
                <div class="input-group-append">
                  <span class="input-group-text">{units.distanceunit}</span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="overview-dialog-label">Amount Paid</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="inputGroupSelect01">
                    {resolveCurrency(units.currencyunit.toUpperCase())}
                  </label>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter how much you paid"
                  name="cost"
                  value={cost}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="overview-dialog-label">Enter Amount</label>
              <div className="input-group">
                <input
                  type="number"
                  class="form-control"
                  name="amount"
                  onChange={handleChange}
                  value={amount}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                  placeholder={`How many ${
                    units.fuelunit == "gal" ? "gallons" : "litres"
                  }?`}
                />
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    {units.fuelunit}
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label className="overview-dialog-label">Refuel Distance</label>

              <div className="input-group">
                <input
                  type="number"
                  class="form-control"
                  name="refuelDistance"
                  value={refuelDistance}
                  onChange={handleChange}
                  placeholder="0.000"
                  aria-describedby="basic-addon1"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    {units.distanceunit}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="overview-dialog-label">
                Was this Work Related ?
              </label>
              <br />
              <input
                type="checkbox"
                id="switch0"
                data-switch="none"
                name="workRelated"
                checked={workRelated}
                onChange={() => {
                  setState({ ...state, workRelated: !workRelated });
                }}
              />
              <label for="switch0" data-on-label="" data-off-label=""></label>
            </div>
            <div class="form-group">
              <label style={{ color: "#6c757d" }}>Upload Fuel Attachment</label>
              <div class="input-group">
                <div class="custom-file ">
                  <input
                    type="file"
                    class="custom-file-input "
                    id="inputGroupFile04"
                    name="attachment"
                    onChange={(e) => {
                      document.getElementById("file-name").innerHTML =
                        e.target.files[0].name;
                      formData.set("attachment", e.target.files[0]);
                    }}
                  />
                  <label
                    class="custom-file-label"
                    id="file-name"
                    for="inputGroupFile04"
                  >
                    Optional
                  </label>
                </div>
              </div>
            </div>
            <button
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={updateFuelReport}
            >
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditFuelDialog;
