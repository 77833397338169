import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import resolveCurrency from "../../../helpers/Helper";
import CurrencyConversion from "../../../helpers/CurrencyConversion";
import NumberFormat from "../../../helpers/number-format-helper";
import ConnectionModeHelper from "../../../helpers/connection-mode-helper";
import moment from "moment";

const ValueCard = ({ vehicle, units }) => {
  const [state, setState] = useState({
    loading: true,
    depreciation: null,
    lowValue: null,
    highValue: null,
    baseValue: null,
    newCarPrice: null,
    showStarman: false,
    decreaseInPrice: null,
    notFound: false,
  });
  const {
    depreciation,
    loading,
    lowValue,
    highValue,
    baseValue,
    newCarPrice,
    decreaseInPrice,
    showStarman,
    notFound,
  } = state;
  useEffect(() => {
    const getValuation = async () => {
      try {
        if (vehicle.is_crowdsourced) {
          throw "err";
        }

        const token = localStorage.getItem("token");

        if (ConnectionModeHelper.isTataZConnect(vehicle)) {
          return setState({ ...state, loading: false, notFound: true });
        }

        if (vehicle.name === "Starman" && vehicle.id === "1iwzoinke0fgs9u") {
          return setState({ ...state, showStarman: true, loading: false });
        }

        // use polynomials only if vehicle is not a tesla (We have ml models for Tesla)
        if (vehicle.make != "Tesla" && vehicle.make != "Porsche") {
          const res = await fetch(
            `${API}/weekly-depreciation/?make=${vehicle.make}&model=${vehicle.model}&purchaseYear=${vehicle.year}&trim=${vehicle.trim}`,
            {
              method: "GET",
              headers: {
                Accept: `application/json`,
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await res.json();

          if (data.weeklyDepreciation) {
            let price = data.price ? data.price : vehicle.purchaseprice;
            let totalDepreciation =
              data.netDepreciation > 90 ? 90 : data.netDepreciation;
            let weeklyDepreciation =
              data.weeklyDepreciation > 25 ? 3.5 : data.weeklyDepreciation;
            const newValue = parseInt(price) * (1 - totalDepreciation / 100);

            const lowValue = newValue - newValue * 0.03;
            const highValue = newValue + newValue * 0.03;
            setState({
              ...state,
              loading: false,
              highValue: highValue.toFixed(0),
              lowValue: lowValue.toFixed(0),
              baseValue: newValue,
              newCarPrice: parseInt(price),
              depreciation: weeklyDepreciation.toFixed(2),
            });
          }
        } else {
          let fetchNewData = true;
          const storedDataJson = localStorage.getItem("valuation");
          let data;

          // if localstorage data is present
          if (storedDataJson) {
            data = JSON.parse(storedDataJson);

            let thisMonthYear = moment().format("MMM-YYYY");
            // if data is from this month then don't fetch the valaution again and just use the storedData
            if (thisMonthYear == data.monthYear) {
              fetchNewData = false;
            } else {
              fetchNewData = true;
            }
          }

          // if new data has to fetched fetch it
          if (true) {
            const res = await fetch(
              `${API}/keemut-report/valuation/${vehicle.id}?vin=${vehicle.vin}`,
              {
                method: "GET",
                headers: {
                  Accept: `application/json`,
                  "Content-Type": "application/json",
                },
              }
            );

            const msrpRes = await fetch(
              `${API}/vehicle-list/price?make=${vehicle.make}&model=${vehicle.model}&year=${vehicle.year}&trim=${vehicle.trim}&country=${vehicle.country_code}`,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                },
              }
            );
            const msrpData = await msrpRes.json();

            data = await res.json();

            data.msrp = msrpData.price;
            const mmmYYYY = moment().format("MMM-YYYY");

            // save the response locally, and set a field monthYear to signify the valuation month year
            if (data.done) {
              localStorage.setItem(
                "valuation",
                JSON.stringify({
                  ...data,
                  monthYear: mmmYYYY,
                  msrp: msrpData.price,
                })
              );
            }
          }

          if (data.done) {
            if (data.result.currency_code != "USD") {
              units.currencyRateWrtUsd = 1;
            }
            let currPrice =
              data.result.current_price * units.currencyRateWrtUsd;
            let lowPrice =
              (data.result.current_price + data.result.std_deviation.lower) *
              units.currencyRateWrtUsd;
            let highPrice =
              (data.result.current_price + data.result.std_deviation.upper) *
              units.currencyRateWrtUsd;
            let decreaseInPrice =
              data.msrp * units.currencyRateWrtUsd - currPrice;

            setState({
              ...state,
              lowValue: lowPrice,
              highValue: highPrice,
              depreciation: 0.3,
              decreaseInPrice: decreaseInPrice,
              loading: false,
            });
          }
        }
      } catch (err) {
        console.log(err);
        setState({ ...state, loading: false, notFound: true });
      }
    };

    let mounted = true;

    if (mounted) {
      getValuation();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [vehicle]);

  if (loading) {
    return (
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-cash-multiple widget-icon bg-success-lighten text-success "></i>
          </div>
          <h3>Value </h3>
          <div class="spinner-border text-primary" role="status"></div>
          <br />
          <br />
        </div>
      </div>
    );
  }

  if (showStarman) {
    return (
      <div class="card  widget-flat text-dark">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-cash-multiple widget-icon bg-success-lighten text-success  "></i>
          </div>
          <span>
            <strong>Value</strong>{" "}
          </span>
          <h3 class="mt-3 mb-3">
            {resolveCurrency(units.currencyunit.toUpperCase())} ~
            {(365541500).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h3>
          <label class="mb-0 text-muted">
            <span class="text-danger mr-2">
              <i class="mdi mdi-arrow-down-bold"></i>{" "}
              {resolveCurrency(units.currencyunit.toUpperCase())}{" "}
              {(250000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
            <span class="text-nowrap">Since Purchase</span>
          </label>
          <label class="mb-0 text-muted">
            <span class="text-danger mr-2">
              <i class="mdi mdi-thumb-down-outline"></i>
            </span>
            <span class="text-nowrap">
              Depreciated <strong>2%</strong> Since Last Week
            </span>
          </label>
        </div>
      </div>
    );
  }

  if (notFound) {
    return (
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-cash-multiple widget-icon bg-success-lighten text-success  "></i>
          </div>
          <h3>Valuation</h3>
          <h3 class="mt-3 mb-3">No Valuation Data</h3>
          <label class="mb-0 text-muted"></label>
          <label class="mb-0 text-muted">
            We currently donot support valuation for your vehicle.
          </label>

          <br />
        </div>
      </div>
    );
  }

  return (
    <div class="card  widget-flat text-dark">
      <div class="card-body">
        <div class="float-right">
          <i class="mdi mdi-cash-multiple widget-icon bg-success-lighten text-success  "></i>
        </div>
        <h3>
          <strong>Valuation</strong>{" "}
        </h3>
        <h3 class="mt-3 mb-3">
          ~ {resolveCurrency(units.currencyunit.toUpperCase())}{" "}
          {NumberFormat(parseInt(lowValue), units.country_code)} -{" "}
          {resolveCurrency(units.currencyunit.toUpperCase())}{" "}
          {NumberFormat(parseInt(highValue))}
        </h3>
        <p class="mb-0  text-muted">
          <span class="text-danger mr-2">
            <i class="mdi mdi-arrow-down-bold"></i>{" "}
            <strong>
              {resolveCurrency(units.currencyunit.toUpperCase())}{" "}
              {NumberFormat(
                parseInt(
                  CurrencyConversion(
                    units.currencyunit,
                    Math.abs(decreaseInPrice)
                  )
                ),
                units.country_code
              )}
            </strong>
          </span>
          <span class="text-nowrap">Since Purchase</span>
        </p>
        <p class="mb-0 text-muted">
          <span class="text-danger mr-2">
            <i class="mdi mdi-thumb-down-outline"></i>
          </span>
          <span class="text-nowrap">
            Depreciated{" "}
            <strong className="text-danger">
              {depreciation ? Math.abs(depreciation) : 0}%
            </strong>{" "}
            Since Last Week
          </span>
        </p>
      </div>
    </div>
  );
};

export default ValueCard;
