import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../config";

const DrivingBreakdown = ({ vehicle, isFreeReport }) => {
  const [state, setState] = useState({
    loading: true,
    price: 0,
    options1: {
      colors: ["#7523E8"],
      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 0,
              show: false,
              color: "#000",
              fontSize: "0px",
            },
            value: {
              color: "#000000",
              fontSize: "28px",
              show: true,
            },
          },
        },
      },
      labels: ["High Way"],
    },
    series1: ["N/A"],
    options2: {
      colors: ["#7523E8"],

      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 0,
              show: false,
              color: "#000",
              fontSize: "0px",
            },
            value: {
              color: "#000000",
              fontSize: "28px",
              show: true,
            },
          },
        },
      },
      labels: ["Congestion"],
    },
    series2: ["N/A"],

    options3: {
      colors: ["#7523E8"],
      fill: {
        type: "gradient",
      },
      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 0,
              show: false,
              color: "#000",
              fontSize: "0px",
            },
            value: {
              color: "#000000",
              fontSize: "28px",
              show: true,
            },
          },
        },
      },
      labels: ["City Driving"],
    },
    series3: ["N/A"],
    options4: {
      colors: ["#7523E8"],

      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 0,
              show: false,
              color: "#000",
              fontSize: "0px",
            },
            value: {
              color: "#000000",
              fontSize: "28px",
              show: true,
            },
          },
        },
      },
      labels: ["Extreme Highway"],
    },
    series4: ["N/A"],
  });
  const {
    options1,
    options2,
    series1,
    series2,
    price,
    loading,
    options3,
    options4,
    series3,
    series4,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted && !isFreeReport) {
      getDrivingBreakdown();
    } else {
      setState({ ...state, loading: false });
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getDrivingBreakdown = async () => {
    try {
      const token = localStorage.getItem("token");

      const res = await fetch(
        `${API}/keemut-report/driving-breakdown/${vehicle.vin}`,
        {
          method: "GET",
          headers: {
            Accept: `application/json`,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await res.json();
      setState({
        ...state,
        series1: [
          vehicle.vin == "1HM888055H7DDA033"
            ? 23
            : vehicle.vin == "1HM888055H7DDA034"
            ? 75
            : data.percentHighway.toFixed(0),
        ],
        series2: [
          vehicle.vin == "1HM888055H7DDA033"
            ? 41
            : vehicle.vin == "1HM888055H7DDA034"
            ? 5
            : data.percentCongestion.toFixed(0),
        ],
        series3: [
          vehicle.vin == "1HM888055H7DDA033"
            ? 13
            : vehicle.vin == "1HM888055H7DDA034"
            ? 5
            : data.percentCity.toFixed(0),
        ],
        series4: [
          vehicle.vin == "1HM888055H7DDA033"
            ? 21
            : vehicle.vin == "1HM888055H7DDA034"
            ? 15
            : data.percentExtreme.toFixed(0),
        ],
        loading: false,
      });
    } catch (err) {
      console.log(err);
      setState({ ...state, loading: false });
    }
  };

  if (loading) {
    return <div className="spinner-border text-primary" role="status" />;
  }

  return (
    <div id="keemut-report_driving_breakdown">
      <div>
        <div className="row">
          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class=" mdi mdi-road-variant widget-icon bg-primary-lighten text-primary"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">
                  Highway Driving
                </h5>
                <Chart options={options1} series={series1} type="radialBar" />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class=" mdi mdi-highway  widget-icon bg-danger-lighten text-danger"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">Congestion</h5>
                <Chart options={options2} series={series2} type="radialBar" />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class=" mdi mdi-road widget-icon bg-success-lighten text-success"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">City Driving</h5>
                <Chart options={options3} series={series3} type="radialBar" />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class="mdi mdi-truck-fast-outline widget-icon bg-warning-lighten text-warning"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">
                  Extreme Highway Driving
                </h5>
                <Chart options={options4} series={series4} type="radialBar" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrivingBreakdown;
