import React, { useState, useEffect } from "react";
import { API } from "../../config";
import NumberFormat from "../../helpers/number-format-helper";
import resolveCurrency from "../../helpers/Helper";

const TcoCard = ({ vehicle, units, isFreeReport }) => {
  const [state, setState] = useState({
    tco: "N/A",
    loading: true,
  });

  const { tco, loading } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (!isFreeReport) getOdo();
      else setState({ ...state, loading: false });
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getOdo = async () => {
    try {
      const res = await fetch(`${API}/keemut-report/tco/${vehicle.id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await res.json();

      if (data.done) {
        setState({ ...state, loading: false, tco: data.costPerMile });
      } else {
        setState({ ...state, loading: false });
      }
    } catch (err) {
      setState({ ...state, loading: false });
    }
  };

  if (loading) {
    return (
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class=" mdi mdi-gauge widget-icon bg-success-lighten text-success"></i>
          </div>
          <h5
            class="text-dark font-weight-normal mt-0"
            title="Distance Driven till date"
          >
            Cost Per Mile
          </h5>
          <div className="spinner-border text-primary" role="status" />
        </div>
      </div>
    );
  }

  return (
    <div class="card widget-flat" id="keemut-report_tco_card">
      <div class="card-body">
        <div class="float-right">
          <i class="mdi mdi-coin-outline widget-icon bg-warning-lighten text-warning"></i>
        </div>
        <h5
          class="text-dark font-weight-normal mt-0"
          title="Distance Driven till date"
        >
          Cost Per Mile
        </h5>
        <h3 class="mt-3 mb-3">
          {" "}
          {resolveCurrency(units.currencyunit)} {NumberFormat(tco)}{" "}
        </h3>
        <p>Based on the data, This is the cost incurred per mile.</p>
      </div>
    </div>
  );
};

export default TcoCard;
