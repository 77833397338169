import { nextSite } from "./config";

const WebRoutes = {
  LOGIN_ROUTE: "/login",
  REGISTER_ROUTE: "/register",
  FORGOT_PASSWORD: "/forgot-password",

  // Keemut.com routes
  KEEMUT_LANDING: `${nextSite}/`,

  // dealer.keemut.com routes
  DEALER_LOGIN_ROUTE: "https://dealer.keemut.com/login",
};

export default WebRoutes;
