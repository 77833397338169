import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../../config";

const CommunityBatteryLife = ({ vehicle, units }) => {
  const [state, setState] = useState({
    options: {
      chart: {
        type: "scatter",
        zoom: {
          enabled: true,
          type: "xy",
        },
      },
      xaxis: {
        title: { text: "Odometer (miles)" },
        tickAmount: 10,
        labels: {
          formatter: function (value) {
            return Math.round(value / 1000) * 1000;
          },
        },
      },
      yaxis: {
        title: { text: "Battery Life (%)" },
        tickAmount: 7,
        min: 50,
        labels: {
          formatter: function (value) {
            return parseInt(value) + "%";
          },
        },
      },
      colors: ["#0acf977c", "#fa5c7c9c"],
    },

    series: [],
    loading: true,
  });
  const { options, series } = state;

  // useEffect(() => {
  //   const token = localStorage.getItem("token");

  //   fetch(`${API}/tesla/community/distancewise-battery-life`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       // console.log(data);
  //       let seriesA = [];

  //       let seriesB = [];

  //       let seriesC = [];
  //       data.distancewiseBatteryLifeData.forEach((d, i) => {
  //         if (units.distanceunit == "mi") {
  //           if (d.distanceunit == "km") d.odometer *= 0.621371;
  //         } else {
  //           if (d.distanceunit == "mi") d.odometer *= 1.6;
  //         }

  //         if (vehicle.vin != d.vin) {
  //           if (d.average_battery_life < 73) {
  //             seriesC.push([
  //               d.odometer && parseInt(d.odometer),
  //               d.average_battery_life &&
  //                 parseFloat(d.average_battery_life).toFixed(2),
  //             ]);
  //           } else {
  //             seriesA.push([
  //               d.odometer && parseInt(d.odometer),
  //               d.average_battery_life &&
  //                 parseFloat(d.average_battery_life).toFixed(2),
  //             ]);
  //           }
  //         } else {
  //           seriesB.push([
  //             d.odometer && parseInt(d.odometer),
  //             d.average_battery_life &&
  //               parseFloat(d.average_battery_life).toFixed(2),
  //           ]);
  //           // console.log(d)
  //         }
  //       });

  //       setState({
  //         ...state,
  //         series: [
  //           { name: "Your Vehicle", data: seriesB },

  //           { name: "Community Data (Good SOC)", data: seriesA },
  //           { name: "Community Data (Bad SOC)", data: seriesC },
  //         ],
  //       });
  //     })
  //     .catch((err) => {
  //       setState({ ...state, loading: false });
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch(`${API}/tesla/community/battery-life-trend`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let seriesC = [];
        data.result.forEach((d, i) => {
          seriesC.push([
            d.min_odometer && parseInt(d.min_odometer),
            d.min_odometer == 0
              ? 100
              : d.average_battery_life &&
                parseFloat(d.average_battery_life).toFixed(2),
          ]);
        });

        setState({
          ...state,
          series: [
            { name: "Your Vehicle", data: [[30000, 93]] },

            { name: "Community Trend", data: seriesC },
          ],
        });
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        console.log(err);
      });
  }, []);

  return (
    <div class="card" style={{ height: "100%" }}>
      <div class="card-body">
        <h4 title={`This how your car's battery range has changed over time.`}>
          {" "}
          Community Battery Life
        </h4>
        <p>This is how the car's battery life compares to other cars</p>
        <Chart options={options} series={series} type="scatter" />
      </div>
    </div>
  );
};

export default CommunityBatteryLife;
