import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../../config";
import resolveCurrency from "../../../helpers/Helper";
import NumberFormat from "../../../helpers/number-format-helper";

const FleetRevenuePerUnit = ({ units }) => {
  const [state, setState] = useState({
    options: {
      colors: ["#00274c"],
      chart: {
        height: 0,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -10,
              show: false,
              color: "#000",
              fontSize: "0px",
            },
            value: {
              color: "#000000",
              fontSize: "32px",
              show: true,
              formatter: function (val) {
                return "$45";
              },
            },
          },
        },
      },
      labels: ["Revenue Per Unit"],
    },
    series: [100],
    error: null,
    loading: true,
  });
  const { options, series, loading, error } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getRevenuePerUnit();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getRevenuePerUnit = async () => {
    try {
      const token = localStorage.getItem("token");
      setState({ ...state, loading: true, error: null });
      const res = await fetch(`${API}/fleet/revenue-per-unit`, {
        method: "GET",
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await res.json();
      let currencyUnit;
      if (units) {
        currencyUnit = resolveCurrency(units.currencyunit);
      } else {
        currencyUnit = "$";
      }

      if (data.done) {
        setState({
          ...state,
          loading: false,
          options: {
            ...options,
            plotOptions: {
              ...options.plotOptions,
              radialBar: {
                ...options.plotOptions.radialBar,
                dataLabels: {
                  ...options.plotOptions.radialBar.dataLabels,
                  value: {
                    ...options.plotOptions.radialBar.dataLabels.value,
                    formatter: function (val) {
                      return (
                        currencyUnit +
                        "" +
                        (data.revenuePerUnit
                          ? NumberFormat(data.revenuePerUnit.toFixed(2))
                          : 0)
                      );
                    },
                  },
                },
              },
            },
          },
        });
      } else {
        setState({
          ...state,
          loading: false,
          error: "Unable to process your revenue per unit.",
        });
      }
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        loading: false,
        error: "Unable to process your revenue per unit.",
      });
    }
  };

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h4>Revenue Per Unit</h4>

          <div className="spinner-border text-primary" role="status" />
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4>Revenue Per Unit</h4>

        {error ? (
          <>
            {" "}
            <p className="text-danger">{error}</p>{" "}
          </>
        ) : (
          <Chart options={options} series={series} type="radialBar" />
        )}
      </div>
    </div>
  );
};

export default FleetRevenuePerUnit;
