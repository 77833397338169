import React, { useState, useEffect } from "react";
import { API } from "../../../../config";
import resolveCurrency from "../../../../helpers/Helper";
import CurrencyConversion from "../../../../helpers/CurrencyConversion";
import NumberFormat from "../../../../helpers/number-format-helper";
import { Popup } from "semantic-ui-react";

const PhantomDrainCard = ({ vehicle, units }) => {
  const [state, setState] = useState({
    loading: true,
    phantomDrain: null,
    phantomDrainCost: null,
    rangeLoss: null,
  });
  const { loading, phantomDrain, phantomDrainCost, rangeLoss } = state;

  useEffect(() => {
    const getPhantomDrain = async () => {
      try {
        const token = localStorage.getItem("token");
        const res = await fetch(`${API}/tesla/phantom-drain/${vehicle.vin}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        setState({
          ...state,
          phantomDrain: parseFloat(data.phantomDrainInfo[0].phantom_drain),
          phantomDrainCost:
            parseFloat(data.phantomDrainInfo[0].phantom_drain) *
            units.energy_rate,
          rangeLoss: parseFloat(data.drainedRange),

          loading: false,
        });
      } catch (err) {
        console.log(err);
      }
    };

    let mounted = true;

    if (mounted) {
      getPhantomDrain();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [vehicle]);

  if (loading) {
    return (
      <div class="card widget-flat">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-cash-multiple widget-icon bg-success-lighten text-success "></i>
          </div>
          <h3>Phantom Drain </h3>
          <div class="spinner-border text-primary" role="status"></div>
          <br />
          <br />
        </div>
      </div>
    );
  }

  return (
    <div class="card  widget-flat text-dark">
      <div class="card-body">
        <div class="float-right">
          <i class="mdi mdi-cash-multiple widget-icon bg-success-lighten text-success  "></i>
        </div>
        <h3>Phantom Drain</h3>
        <p class="mt-3 mb-3 ">
          <span className="h3">{phantomDrain && phantomDrain.toFixed(2)}</span>{" "}
          <span>kWh per week</span>
        </p>
        <p class="mb-0 text-muted">
          <span class="text-danger mr-2">
            <i class="mdi mdi-arrow-down-bold"></i>{" "}
            <strong>
              {rangeLoss && rangeLoss.toFixed(2)} {units.distanceunit}
            </strong>{" "}
          </span>
          <span class="text-nowrap">Range loss</span>
        </p>
        <p class="mb-0 text-muted">
          <span class="text-danger mr-2">
            <i class="mdi mdi-thumb-down-outline"></i>
          </span>
          <span class="text-nowrap">
            <strong className="text-danger">
              {resolveCurrency(units.currencyunit)}
              {phantomDrainCost && phantomDrainCost.toFixed(2)}
            </strong>{" "}
            loss per week
          </span>
        </p>
      </div>
    </div>
  );
};

export default PhantomDrainCard;
