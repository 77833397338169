import React, { useState, useEffect } from "react";
import { API } from "../../config";
import Chart from "react-apexcharts";
const CurrentOdometerRadial = () => {
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   console.log(vehicle);
  //   fetch(`${API}/odometer-data/${vehicle.id}`, {
  //     method: "GET",
  //     headers: {
  //       Accept: `application/json`,
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //       setState({
  //         ...state,
  //         odometer: data.totalOdo,
  //       });
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  return (
    <div class="card widget-flat">
      <div class="card-body">
        <div class="float-right">
          <i class=" mdi mdi-gauge widget-icon bg-success-lighten text-success"></i>
        </div>
        <h5
          class="text-dark font-weight-normal mt-0"
          title="Distance Driven till date"
        >
          Distance Driven
        </h5>
        <h3 class="mt-3 mb-3"> 100,213 mi </h3>
        <p>
          Based on The data, the vehicle was driven regularly over its lifespan.
        </p>
      </div>
    </div>
  );
};

export default CurrentOdometerRadial;
