import simpleFetch from "../../helpers/fetch-wrapper";
import moment from "moment";

export async function createMaintenanceFormService(formData, vehicleId) {
  try {
    return await simpleFetch(
      `/maintenance-reports-v2/${vehicleId}`,
      "POST",
      formData,
      {}
    );
  } catch (err) {
    console.log(err);
  }
}

export async function editMaintenanceFormService(formData, vehicleId) {
  try {
    return await simpleFetch(
      `/maintenance-reports-v2/${vehicleId}`,
      "PUT",
      formData,
      {}
    );
  } catch (err) {
    console.log(err);
  }
}

export async function getMaintenanceAnalyticsService(vehicleid) {
  try {
    return await simpleFetch(`/analytics-v2/${vehicleid}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

export async function getAllMaintenanceFormService(vehicleid) {
  try {
    return await simpleFetch(`/maintenance-v2/all/${vehicleid}`, "GET");
  } catch (err) {
    console.log(err);
  }
}
export async function getMonthlyMaintenanceCostService(vehicleid) {
  try {
    return await simpleFetch(`monthly-maintenance-cost/${vehicleid}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

export async function deleteMaintenanceFormService(
  maintenanceReportId,
  vehicleId
) {
  try {
    return await simpleFetch(
      `/maintenance-v2/${vehicleId}/${maintenanceReportId}`,
      "DELETE"
    );
  } catch (err) {
    console.log(err);
  }
}
