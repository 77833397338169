import React, { useState, useEffect } from "react";
import { API, site } from "../../../config";
import resolveCurrency from "../../../helpers/Helper";
import { Redirect } from "react-router-dom";
import FloatingButton from "../common/FloatingButton";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import RecentOdometer from "../common/RecentOdometer";
import { createInsuranceFormService } from "../../../services/forms/insurance-form-service";
import { Helmet } from "react-helmet";
const mixpanel = require("mixpanel-browser");

const InsuranceForm = ({ vehicle }) => {
  const [state, setState] = useState({
    success: false,
    loading: true,
    vehicles: [],
    units: {},
    odometer: null,
    date: null,
    renewalDate: null,
    expenseType: "insurance",
    vendor: null,
    cost: null,
    billNumber: null,
    error: null,
    formData: new FormData(),
    futureError: false,
    submitting: false,
    showOdoDialog: false,
  });
  const {
    loading,
    vehicles,
    units,
    success,
    date,
    odometer,
    futureError,
    renewalDate,
    expenseType,
    vendor,
    cost,
    billNumber,
    formData,
    error,
    showOdoDialog,
    submitting,
  } = state;

  useEffect(() => {
    mixpanel.track("insurance_form", {
      vehicleId: vehicle.id,
      uid: vehicle.fkusersuid,
    });
    initialRequest();
  }, []);

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const initialRequest = () => {
    if (localStorage.getItem("units") && localStorage.getItem("vehicles")) {
      const vehicles = JSON.parse(localStorage.getItem("vehicles"));
      const units = JSON.parse(localStorage.getItem("units"));
      setState({
        ...state,
        vehicles,
        units,
        loading: false,
      });
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");
      setState({ ...state, loading: true });
      fetch(`${API}/vehicles/all/${user.uid}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          fetch(`${API}/units/${user.uid}`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => response.json())
            .then((jsonData) => {
              localStorage.setItem("units", JSON.stringify(jsonData.units));
              localStorage.setItem("vehicles", JSON.stringify(data.vehicles));
              setState({
                ...state,
                units: jsonData.units,
                loading: false,
                vehicles: data.vehicles,
              });
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
  };

  const isFormValid = (e) => {
    if (!date || !renewalDate || !cost || !expenseType) {
      setState({ ...state, error: "Please fillout all the fields" });
      return false;
    } else if (moment().isBefore(date)) {
      setState({ ...state, futureError: true });
      return false;
    } else if (cost < 0) {
      setState({ ...state, error: "Cost Cannot be negative" });
      return false;
    } else {
      return true;
    }
  };

  const addInsurance = async (e) => {
    try {
      e.preventDefault();

      const token = localStorage.getItem("token");

      if (isFormValid()) {
        setState({ ...state, submitting: true });
        formData.set(
          "date",
          date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2")
        );
        formData.set(
          "renewalDate",
          renewalDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2")
        );
        formData.set("cost", cost);
        formData.set("expenseType", expenseType);
        formData.set("vendor", vendor);

        formData.set("billNumber", billNumber);

        const data = await createInsuranceFormService(formData, vehicle.id);
        if (data.done) {
          localStorage.setItem("odoChange", true);
          setState({ ...state, submitting: false, success: true });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return <div class="spinner-border text-primary" role="status"></div>;
  }

  if (success) {
    return <Redirect to="/dashboard/vehicles" />;
  }

  return (
    <div className="fuel-main text-dark" style={{ maxWidth: "100%" }}>
      <Helmet>
        <title>Keemut | Insurance</title>
      </Helmet>
      <h3 className="fuel-header-heading">
        Add Insurance / Regulation Expenses
      </h3>
      <FloatingButton />
      <div style={{ backgroundColor: "#ffffff" }} className="mx-2 my-3">
        <div className=" container p-3  card-body">
          <div className="row">
            <div className="col-lg-12">
              <div class="form-group">
                <label className="overview-dialog-label">
                  When did you pay ?
                </label>

                <input
                  type="date"
                  class="form-control date"
                  id="birthdatepicker"
                  data-toggle="date-picker"
                  data-single-date-picker="true"
                  name="date"
                  value={date}
                  onChange={handleChange}
                  aria-describedby="basic-addon1"
                />
              </div>

              <div className="form-group">
                <label>Enter Vendor</label>

                <br />
                <div className="input-group">
                  <input
                    name="vendor"
                    value={vendor}
                    onChange={handleChange}
                    type="text"
                    class="form-control"
                    placeholder="optional"
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Expense type</label>

                <div className="input-group">
                  <select
                    class="form-control select2"
                    data-toggle="select2"
                    name="expenseType"
                    value={expenseType}
                    onChange={handleChange}
                  >
                    <option value="insurance">Insurance</option>
                    <option value="regulation">Government Regulations</option>
                    <option value="other">Other regulatory expenses </option>
                  </select>
                </div>
              </div>

              <br />
              <div className="form-group">
                <label>Enter Bill Number</label>
                <div class="input-group">
                  <br />
                  <input
                    type="text"
                    class="form-control"
                    placeholder="(Optional)"
                    name="billNumber"
                    value={billNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <br />

              <div className="form-group">
                <label>Amount Paid</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01">
                      {resolveCurrency(units.currencyunit.toUpperCase())}
                    </label>
                  </div>
                  <input
                    name="cost"
                    value={cost}
                    onChange={handleChange}
                    type="text"
                    class="form-control"
                    placeholder="Enter how much you paid"
                  />
                </div>
              </div>

              <div class="form-group">
                <label className="overview-dialog-label">When to renew ?</label>

                <div className="input-group">
                  <input
                    type="date"
                    class="form-control"
                    name="renewalDate"
                    value={renewalDate}
                    onChange={handleChange}
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>

              <div class="form-group">
                <label>Upload Insurance Receipt</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="inputGroupFile04"
                      name="attachment"
                      onChange={(e) => {
                        document.getElementById("file-name").innerHTML =
                          e.target.files[0].name;
                        formData.set("attachment", e.target.files[0]);
                      }}
                    />
                    <label class="custom-file-label" id="file-name">
                      Optional
                    </label>
                  </div>
                </div>
              </div>
              <br />
              <button
                onClick={addInsurance}
                class="btn btn-primary"
                style={{ width: "100%" }}
              >
                {submitting ? "Saving..." : "Submit"}
              </button>
            </div>

            <RecentOdometer
              vehicle={vehicle}
              date={date}
              showOdoDialog={showOdoDialog}
              closeOdoDialog={() =>
                setState({ ...state, showOdoDialog: false })
              }
            />

            <Dialog
              open={error}
              onClose={() => setState({ ...state, error: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div class="modal-content">
                <div class="modal-header modal-colored-header bg-warning">
                  <h4 class="modal-title" id="primary-header-modalLabel">
                    Please Fillout All the Fields
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={() => setState({ ...state, error: null })}
                  >
                    ×
                  </button>
                </div>
                <div class="modal-body">
                  It seems like you missed a few fields while filling up the
                  form.
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-light"
                    data-dismiss="modal"
                    onClick={() => setState({ ...state, error: null })}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Dialog>

            <Dialog
              open={futureError}
              onClose={() => setState({ ...state, futureError: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div class="modal-content">
                <div class="modal-header modal-colored-header bg-warning">
                  <h4 class="modal-title" id="primary-header-modalLabel">
                    Seems like You are trying to enter a Future event
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={() => setState({ ...state, futureError: null })}
                  >
                    ×
                  </button>
                </div>
                <div class="modal-body">
                  The date field must be today or a past date, To Add a
                  reminder, head over to reminders.
                </div>
                <div class="modal-footer">
                  <a href={`${site}/dashboard/tco`}>
                    <button
                      type="button"
                      class="btn btn-warning"
                      data-dismiss="modal"
                      onClick={() => setState({ ...state, futureError: null })}
                    >
                      Go To Reminders
                    </button>
                  </a>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceForm;
