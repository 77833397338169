import React, { useEffect, useState } from "react";
import TeslaHelper from "../../../helpers/tesla-helper";
import AreaChart from "../../custom-charts/AreaChart";
import LineChart from "../../custom-charts/LineChart";
const WhMiVsOdometerChart = ({ vehicle, units }) => {
  const [state, setState] = useState({
    _xaxis: [],
    _yaxis: [],
    _loading: true,
  });

  const { _loading, _xaxis, _yaxis } = state;

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    try {
      const data = await TeslaHelper.getWhMiVsOdometer(vehicle.vin);

      let xaxis = [],
        yaxis = [];

      data.result.forEach((item, i) => {
        if (item.distanceunit == "km") item.watt_per_mile /= 1.607;
        if (item.watt_per_mile != 0 && item.watt_per_mile <= 400 && i % 5 == 0) {
          xaxis.push(parseInt(item.odometer));
          yaxis.push(parseInt(item.watt_per_mile));
        }
      });

      setState({ ...state, _xaxis: xaxis, _yaxis: yaxis, _loading: false });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="text-dark card card-body" style={{ height: "100%" }}>
      <h3>
        Watt Per {units.distanceunit == "km" ? "Kilometer" : "Mile"} vs Odometer{" "}
      </h3>

      {_loading ? (
        <div className="spinner spinner-border center-spinner"> </div>
      ) : (
        <AreaChart
          yAxisTitle={`Watt Per ${
            units.distanceunit == "km" ? "Kilometer" : "Mile"
          } (Wh/${units.distanceunit})`}
          xAxisTitle={`Odometer (${units.distanceunit})`}
          xaxis={_xaxis}
          yaxis={_yaxis}
        />
      )}
    </div>
  );
};
export default WhMiVsOdometerChart;
