import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import resolveCurrency from "../../../helpers/Helper";
import { getFuelReportCountService } from "../../../services/forms/fuel-form-service";

const FuelUpCountCard = ({ vehicle, units }) => {
  const [state, setState] = useState({
    loading: false,
    count: null,
    cost: null,
  });
  const { loading, count, cost } = state;

  useEffect(() => {
    const _getFuelReportCount = async () => {
      try {
        setState({ ...state, loading: true });
        const data = await getFuelReportCountService(vehicle.id);

        if (data.done)
          setState({
            ...state,
            loading: false,
            count: data.fillUpCount,
            cost: data.cost,
          });
      } catch (err) {
        setState({ ...state, loading: false, count: 0, cost: 0 });
        console.log(err);
      }
    };

    _getFuelReportCount();
  }, [vehicle]);

  if (loading) {
    return (
      <div class="card  widget-flat ">
        <div class="card-body">
          <div class="float-right">
            <i class="mdi mdi-cash-multiple widget-icon bg-success-lighten text-success"></i>
          </div>
          <span>
            <strong>Reported Fillups</strong>{" "}
            {`| ${vehicle.make} ${vehicle.model}`}{" "}
          </span>
          <br />
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card  widget-flat ">
      <div class="card-body">
        <div class="float-right">
          <i class="mdi mdi-cash-multiple widget-icon bg-success-lighten text-success"></i>
        </div>
        <span>
          <strong>Reported Fillups</strong>{" "}
          {`| ${vehicle.make} ${vehicle.model}`}{" "}
        </span>
        <h3 class="mt-3 mb-3">{count} Fillups</h3>
        <label class="mb-0 text-muted">
          <span class="text-danger mr-2">
            <i class="mdi mdi-arrow-down-bold"></i>{" "}
            {resolveCurrency(units.currencyunit)} {cost}
          </span>
        </label>
        <p>This is based on the data provided by you.</p>
      </div>
    </div>
  );
};

export default FuelUpCountCard;
