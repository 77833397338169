import React from "react";
import { nextSite } from "../../config";

const Footer = () => {
  return (
    <footer class="bg-primary py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-2">
            <img src="/keemut-2.png" alt="" class="logo-dark" height="40" />

            <ul class="social-list list-inline mt-3">
              <li class="list-inline-item text-center">
                <a
                  target="_blank"
                  href="https://www.instagram.com/keemut/"
                  class="social-list-item border-warning text-warning"
                >
                  <i class="mdi mdi-instagram"></i>
                </a>
              </li>

              <li class="list-inline-item text-center">
                <a
                  target="_blank"
                  href="https://twitter.com/getkeemut"
                  class="social-list-item border-info text-info"
                  target="_blank"
                >
                  <i class="mdi mdi-twitter"></i>
                </a>
              </li>
            </ul>
            <a
              title="Realtime application protection"
              href="https://www.sqreen.com/?utm_source=badge"
              target="_blank"
            >
              <img
                style={{ height: "36px", width: "109px" }}
                src="https://s3-eu-west-1.amazonaws.com/sqreen-assets/badges/20171107/sqreen-light-badge.svg"
                alt="Sqreen | Runtime Application Protection"
              />
            </a>

            {/* <div>
              <h3 className="text-white">Our Security Partner</h3>
              <img
                src={require("../../assets/sqreen-2.jpg")}
                height="100"
                width="100"
              ></img>
            </div> */}
          </div>
          <div className="col-lg-2" />
          <div class="col-lg-2 mt-3 mt-lg-0">
            <h5 class="text-white">About</h5>

            <ul class="list-unstyled pl-0 mb-0 mt-3">
              <li class="mt-2">
                <a href={`${nextSite}/about`} class="text-white-50">
                  Company
                </a>
              </li>

              <li class="mt-2">
                <a href={`${nextSite}/contact`} class="text-white-50">
                  Contact
                </a>
              </li>
              <li class="mt-2">
                <a href={`${nextSite}/about/security`} class="text-white-50">
                  Security
                </a>
              </li>
              <li class="mt-2">
                <a href={`${nextSite}/privacy-policy`} class="text-white-50">
                  Terms
                </a>
              </li>
            </ul>
          </div>

          <div class="col-lg-2 mt-3 mt-lg-0">
            <h5 class="text-white">Product</h5>

            <ul class="list-unstyled pl-0 mb-0 mt-3">
              <li class="mt-2">
                <a href={`${nextSite}/valuation/tesla`} class="text-white-50">
                  Keemut Valuation
                </a>
              </li>
              <li class="mt-2">
                <a href={`${nextSite}/how-it-works`} class="text-white-50">
                  How It Works
                </a>
              </li>

              <li class="mt-2">
                <a href={`/sample-report`} class="text-white-50">
                  Sample Report
                </a>
              </li>
              <li class="mt-2">
                <a href={`/pricing`} class="text-white-50">
                  Pricing
                </a>
              </li>
              <li class="mt-2">
                <a href={`${nextSite}/vehicles`} class="text-white-50">
                  Compatible Vehicles
                </a>
              </li>
              {/* <li class="mt-2">
                <a href="/valuation/toyota" class="text-white-50">
                  Toyota
                </a>
              </li> */}
            </ul>
          </div>

          <div class="col-lg-2 mt-3 mt-lg-0">
            <h5 class="text-white">Segments</h5>

            <ul class="list-unstyled pl-0 mb-0 mt-3">
              <li class="mt-2">
                <a href={`${nextSite}`} class="text-white-50">
                  Personal
                </a>
              </li>
              <li class="mt-2">
                <a href={`${nextSite}/fleet`} class="text-white-50">
                  Fleet
                </a>
              </li>

              <li class="mt-2">
                <a href={`${nextSite}/dealership`} class="text-white-50">
                  Dealerships
                </a>
              </li>
              <li class="mt-2">
                <a href={`${nextSite}/connected-reports`} class="text-white-50">
                  Valuation Reports
                </a>
              </li>
            </ul>
          </div>

          <div class="col-lg-2 mt-3 mt-lg-0">
            <h5 class="text-white">Support </h5>

            <ul class="list-unstyled pl-0 mb-0 mt-3">
              <li class="mt-2">
                <a href="https://auto.keemut.com" class="text-white-50">
                  Research
                </a>
              </li>
              <li class="mt-2">
                <a href="https://auto.keemut.com/faqs/" class="text-white-50">
                  FAQ
                </a>
              </li>

              <li class="mt-2">
                <a href={`${nextSite}/privacy-policy`} class="text-white-50">
                  Privacy
                </a>
              </li>
              {/* <li class="mt-2">
                <a href="/valuation/toyota" class="text-white-50">
                  Toyota
                </a>
              </li> */}
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="mt-5">
              <p
                class="text-white-20 mt-4 text-center mb-0"
                style={{ color: "#fff" }}
              >
                © {new Date().getFullYear()} Keemut, Inc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
