import React, { useEffect, useState, useRef } from "react";
import { API } from "../../../config";
import { Dropdown } from "semantic-ui-react";
import moment from "moment";
import mapboxgl from "mapbox-gl";
import { convertTimeToTimezone } from "../../../helpers/date-time-helper";
import FloatingButton from "../common/FloatingButton";
import { Helmet } from "react-helmet";
import NumberFormat from "../../../helpers/number-format-helper";
import SubscribeBanner from "../../common/SubscribeBanner";
import { Dialog } from "@material-ui/core";
import SubscriptionDialog from "../../common/SubscriptionDialog";
import TemperatureConversion from "../../../helpers/TemperatureConversion";
import resolveCurrency from "../../../helpers/Helper";
import { CSVLink, CSVDownload } from "react-csv";
import unitsHelper from "../../../helpers/units-helper";
import simpleFetch from "../../../helpers/fetch-wrapper";
const mixpanel = require("mixpanel-browser");

mapboxgl.accessToken =
  "pk.eyJ1Ijoia2VlbXV0IiwiYSI6ImNqb29kMjRhMjAwZm0zcG10d2Jmb2x2bTUifQ.YUXQ359H96QrmRteACT0ZA";

const styles = {
  width: "100%",
  height: "500px",
};

const Trips = ({ vehicle }) => {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);
  const [state, setState] = useState({
    loading: true,
    tripOptions: [],
    currentTrip: null,
    tripCoordinates: [],
    center: [0, 0],
    startLocation: null,
    endLocation: null,
    duration: null,
    batteryUsed: null,
    distanceDriven: null,
    efficiency: null,
    noTrips: null,
    batterySize: null,
    batteryLife: null,
    paginations: 0,
    currentPage: 1,
    units: null,
    workRelated: null,
    speedLayer: true,
    startOdometer: null,
    endOdometer: null,
    showSubscriptionMessage: false,
    timeRange: "7days",
    isSubscribed: true,
    trips: [],
    tripCsv: [
      [
        "id",
        "count",
        "vin",
        "Start Time",
        "End Time",
        "Duration (min)",
        "Start Odometer",
        "End Odometer",
        "Distance",
        "Start Battery Level",
        "End Battery Level",
        "Avearge Speed",
        "Average Internal Temperature",
        "Average External Temperature",
        "Tax Savings (if any)",
      ],
    ],
  });
  const {
    loading,
    batteryLife,
    showSubscriptionMessage,
    batterySize,
    tripOptions,
    currentTrip,
    mapboxToken,
    tripCoordinates,
    center,
    startLocation,
    endLocation,
    duration,
    batteryUsed,
    distanceDriven,
    efficiency,
    noTrips,
    paginations,
    currentPage,
    units,
    speedLayer,
    workRelated,
    startOdometer,
    endOdometer,
    timeRange,
    isSubscribed,
    trips,
    tripCsv,
  } = state;

  useEffect(() => {
    let mounted = true;

    const getTrips = async () => {
      try {
        const token = localStorage.getItem("token");
        setState({ ...state, loading: true, currentTrip: null });
        let isSubscribed = false;
        const user = JSON.parse(localStorage.getItem("user"));

        if (!user.subscription_id && user.subscription_mode == "stripe") {
          setState({ ...state, isSubscribed: false });
        } else {
          const subRes = await fetch(
            `${API}/subscription/${user.subscription_id}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          const subData = await subRes.json();
          isSubscribed = subData.isSubscribed;
        }

        const res = await fetch(
          `${API}/tesla/trips/${vehicle.vin}?timeRange=${timeRange}`,
          {
            method: "GET",
            headers: {
              Accept: `application/json`,
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await res.json();

        const bres = await fetch(
          `${API}/tesla/battery-life/${vehicle.vin}/?model=${vehicle.model}&trim=${vehicle.trim}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const bdata = await bres.json();
        let units = JSON.parse(localStorage.getItem("units"));

        if (data.trips.length == 0 || !data.done) {
          return setState({ ...state, loading: false, noTrips: true, units });
        }
        let tripOptions = [];
        let trips = [];
        data.trips.forEach((trip, i) => {
          trip.avgSpeed = unitsHelper.convertMiToKm(trip.avgSpeed);
          tripCsv.push([
            trip._id,
            data.trips.length - i,
            trip.vin,
            moment(
              convertTimeToTimezone(
                new Date(trip.start_time).toISOString(),
                units.timezone_name
              )
            ).format("DD-MMM-YY hh:mm A"),
            moment(
              convertTimeToTimezone(
                new Date(trip.end_time).toISOString(),
                units.timezone_name
              )
            ).format("DD-MMM-YY hh:mm A"),
            moment(trip.end_time).diff(moment(trip.start_time), "minutes"),
            trip.startOdometer,
            trip.endOdometer,
            (trip.endOdometer - trip.startOdometer).toFixed(2),
            trip.start_blevel,
            trip.end_blevel,
            trip.avgSpeed,
            trip.avgIntTemp,
            trip.avgExtTemp,
            (trip.tax_savings && trip.tax_savings.toFixed(2)) || "N/A",
          ]);

          trips.push(trip);

          tripOptions.push({
            text: `(${data.trips.length - i}) ${moment(
              convertTimeToTimezone(
                new Date(trip.start_time).toISOString(),
                units.timezone_name
              )
            ).format("DD-MMM-YY hh:mm A")} - ${moment(
              convertTimeToTimezone(
                new Date(trip.end_time).toISOString(),
                units.timezone_name
              )
            ).format("DD-MMM-YY hh:mm A")}`,
            value: `${JSON.stringify(trip)}`,
            key: `${moment.utc(trip.start_time).format("DD-MMM-YY hh:mm A")}`,
          });
        });

        const trip = JSON.parse(tripOptions[0].value);
        setState({
          ...state,
          tripOptions,
          trips,
          currentTrip: tripOptions[0].value,
          workRelated: trip.work_related ? true : false,
          batterySize: bdata.batterySize,
          batteryLife: bdata.averageBatteryLife,
          paginations: Math.ceil(tripOptions.length / 10),
          loading: false,
          units,
          isSubscribed: isSubscribed,
          noTrips: false,
        });
        // getSubscriptionStatus();
      } catch (err) {
        console.log(err);
      }
    };

    if (mounted) {
      getTrips();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [vehicle, timeRange]);

  useEffect(() => {
    let mounted = true;
    const onTripUpdate = async () => {
      try {
        const trip = JSON.parse(currentTrip);
        let coordinates = trip.latLon;
        let geoJson = {
          type: "FeatureCollection",
          features: [],
        };

        let lineGeoJson = {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: [],
          },
        };

        coordinates.forEach((coord, i) => {
          geoJson.features.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: coord,
            },
            properties: {
              speed: trip.speed[i] || 0,
              battery: trip.battery_level[i],
              description: `<p>  </p>`,
            },
          });

          lineGeoJson.geometry.coordinates.push(coord);
        });

        if (!trip.startLocation) {
          const startRes = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${coordinates[0][1]}&lon=${coordinates[0][0]}`,
            {
              method: "GET",
            }
          );

          const startData = await startRes.json();

          trip.startLocation = startData.display_name;
        }

        if (!trip.endLocation) {
          const endRes = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${coordinates[coordinates.length - 1][1]
            }&lon=${coordinates[coordinates.length - 1][0]}`,
            {
              method: "GET",
            }
          );
          const endData = await endRes.json();
          trip.endLocation = endData.display_name;
        }

        let efficiency = (
          ((trip.endOdometer - trip.startOdometer) /
            (trip.start_brange - trip.end_brange)) *
          100
        ).toFixed(2);
        efficiency *= efficiency < 0 ? -1 : 1;
        setState({
          ...state,
          distanceDriven: (trip.endOdometer - trip.startOdometer).toFixed(2),
          startOdometer: trip.startOdometer,
          endOdometer: trip.endOdometer,
          duration: moment(trip.end_time).diff(
            moment(trip.start_time),
            "minutes"
          ),
          efficiency,
          batteryUsed: (
            ((trip.start_blevel - trip.end_blevel) / 100) *
            batterySize *
            (efficiency / 100) *
            (batteryLife / 100)
          ).toFixed(2),
          startLocation: trip.startLocation,
          endLocation: trip.endLocation,
          workRelated: trip.workRelated,
        });
        displayMap(geoJson, speedLayer ? "speed" : "battery", lineGeoJson);
      } catch (err) {
        console.log(err);
      }
    };

    const displayMap = async (geoJson, layer, lineGeoJson) => {
      try {
        mapboxgl.accessToken =
          "pk.eyJ1Ijoia2VlbXV0IiwiYSI6ImNqb29kMjRhMjAwZm0zcG10d2Jmb2x2bTUifQ.YUXQ359H96QrmRteACT0ZA";

        const initializeMap = ({ setMap, mapContainer }) => {
          // create a map
          const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/light-v9", // stylesheet location
            center: geoJson.features[0].geometry.coordinates,
            zoom: 10,
          });

          // on load add the sources and layers
          map.on("load", function () {
            map.addSource("trees", {
              type: "geojson",
              data: geoJson,
            });

            map.addSource("route", {
              type: "geojson",
              data: lineGeoJson,
              lineMetrics: true,
            });

            map.addLayer({
              id: "route",
              type: "line",
              source: "route",
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": "#888",
                "line-width": 5,
                "line-gradient": [
                  "interpolate",
                  ["linear"],
                  ["line-progress"],
                  0,
                  "#00D493",

                  0.5,
                  "#2829CB",

                  1,
                  "#FF4B7A",
                ],
              },
            });

            // depending on layer change the color gradient
            if (layer == "speed") {
              const myLayer = map.addLayer(
                {
                  id: "trees-point",
                  type: "circle",
                  source: "trees",
                  minzoom: 0,
                  paint: {
                    // increase the radius of the circle as the zoom level and dbh value increases
                    "circle-radius": {
                      property: `speed`,
                      type: "exponential",
                      stops: [
                        [{ zoom: 15, value: 100 }, 5],
                        [{ zoom: 15, value: 100 }, 10],
                        [{ zoom: 22, value: 100 }, 20],
                        [{ zoom: 22, value: 100 }, 50],
                      ],
                    },
                    "circle-color": {
                      property: `speed`,
                      type: "exponential",
                      stops: [
                        [0, "#cac0b7"],
                        [20, "#ffbc00"],
                        [40, "#e98f40"],
                        [50, "#ff679b"],
                        [60, "#db3a5a"],
                      ],
                    },
                    "circle-stroke-color": "white",
                    "circle-stroke-width": 1,
                  },
                },
                "waterway-label"
              );
            } else {
              map.addLayer(
                {
                  id: "trees-point",
                  type: "circle",
                  source: "trees",
                  minzoom: 0,
                  paint: {
                    // increase the radius of the circle as the zoom level and dbh value increases
                    "circle-radius": {
                      property: "battery",
                      type: "exponential",
                      stops: [
                        [{ zoom: 15, value: 100 }, 5],
                        [{ zoom: 15, value: 100 }, 10],
                        [{ zoom: 22, value: 100 }, 20],
                        [{ zoom: 22, value: 100 }, 60],
                      ],
                    },
                    "circle-color": {
                      property: "battery",
                      type: "exponential",
                      stops: [
                        [20, "#7c1539"],
                        [30, "#fa5c7c"],
                        [40, "#fd7e14"],
                        [50, "#ffbc00"],
                        [65, "#02a8b5"],
                        [75, "#0acf97"],
                        [80, "#0acf97"],
                        [90, "#107457"],
                        [100, "#107457"],
                      ],
                    },
                    "circle-stroke-color": "white",
                    "circle-stroke-width": 1,
                  },
                },
                "waterway-label"
              );
            }

            // move route layer above trees-point
            map.moveLayer("route", "trees-point");

            let popup = new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
            });

            // on mouse enter show a tooltip
            map.on("mouseenter", "trees-point", (e) => {
              map.getCanvas().style.cursor = "pointer";
              popup
                .setLngLat(map.unproject(e.point))
                .setHTML(
                  "<p>" +
                  "Speed : <b>" +
                  e.features[0].properties.speed +
                  ` ${units.distanceunit == "mi" ? "m" : "k"}ph` +
                  "</b></p>" +
                  `<p> Battery :  <b>${e.features[0].properties.battery} % </b> </p>`
                )
                .addTo(map);
            });

            // remove tooltip on mouse leave
            map.on("mouseleave", "trees-point", () => {
              map.getCanvas().style.cursor = "";
              popup.remove();
            });

            // start marker
            var startMarker = new mapboxgl.Marker({ color: "#0acf97" })
              .setLngLat(geoJson.features[0].geometry.coordinates)
              .addTo(map);

            // end marker
            var endMarker = new mapboxgl.Marker({ color: "#fa5c7c" })
              .setLngLat(
                geoJson.features[geoJson.features.length - 1].geometry
                  .coordinates
              )
              .addTo(map);

            setMap(map);
            map.resize();
          });
        };

        initializeMap({ setMap, mapContainer });
      } catch (err) {
        console.log(err);
      }
    };

    if (mounted) {
      onTripUpdate();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [currentTrip, speedLayer]);

  const updateWorkRelated = async (trip) => {
    try {
      await simpleFetch(
        `/tesla/trips/${trip.vin}`,
        "PUT",
        JSON.stringify({
          _id: trip._id,
          workRelated: trip.tax_savings != null,
          taxDeductionRate: trip.tax_rate,
          distance: trip.endOdometer - trip.startOdometer,
        })
      );
    } catch (err) { }
  };

  const renderTripTable = () => {
    let content = [];

    trips.forEach((trip, i) => {
      return i < currentPage * 10 && i >= (currentPage - 1) * 10
        ? content.push(
          <tr>
            <td> {tripOptions.length - i} </td>
            <td style={{ fontSize: "13px" }}>
              {moment(
                convertTimeToTimezone(
                  new Date(trip.start_time).toISOString(),
                  units.timezone_name
                )
              ).format("DD-MMM-YY hh:mm A")}
            </td>
            <td style={{ fontSize: "13px" }}>
              {moment(
                convertTimeToTimezone(
                  new Date(trip.end_time).toISOString(),
                  units.timezone_name
                )
              ).format("DD-MMM-YY hh:mm A")}
            </td>
            <td>
              {moment(trip.end_time).diff(moment(trip.start_time), "minutes")}{" "}
            </td>
            <td>{(trip.endOdometer - trip.startOdometer).toFixed(2)}</td>
            <td>
              {TemperatureConversion(
                trip.avgIntTemp,
                units.temperatureunit
              ).toFixed(2)}{" "}
            </td>
            <td>
              {TemperatureConversion(
                trip.avgExtTemp,
                units.temperatureunit
              ).toFixed(2)}
            </td>
            <td>
              {resolveCurrency(units.currencyunit)}{" "}
              {(trip.tax_savings && trip.tax_savings.toFixed(2)) || "N/A"}
            </td>
            <td>
              <input
                type="checkbox"
                checked={trip.tax_savings != null}
                onChange={(e) => {
                  if (trip.tax_savings != null) {
                    trips[i].tax_savings = null;
                    updateWorkRelated(trips[i]);
                    setState({ ...state, trips: trips });
                  } else {
                    trips[i].tax_savings =
                      (trip.endOdometer - trip.startOdometer) * trip.tax_rate;
                    updateWorkRelated(trips[i]);
                    setState({ ...state, trips: trips });
                  }
                }}
              />
            </td>
          </tr>
        )
        : null;
    });

    return <> {content} </>;
  };

  const renderPagination = () => {
    let content = [];
    for (let i = 1; i <= paginations; i++) {
      content.push(
        <a
          onClick={() => setState({ ...state, currentPage: i })}
          class="page-link"
          style={
            currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
      );
    }
    return <> {content} </>;
  };

  if (loading) {
    return (
      <>
        <div className="card card-body text-dark">
          <h2>Trips</h2>
          <div
            className="spinner-border spinner-center text-primary"
            role="status"
          ></div>
        </div>
      </>
    );
  }



  return (
    <>
      <div className="text-dark">
        <Helmet>
          <title>Keemut | Trips</title>
        </Helmet>

        <FloatingButton />
        <SubscribeBanner show7dayMessage={true} />

        
        <div className="card card-body">
          <h2>{vehicle.name}'s Trips</h2>
          <p className="text-muted">
            All the times are displayed in{" "}
            <strong>{units.timezone_name}</strong> Timezone. Your can update it
            in your{" "}
            <a href="/dashboard/settings" style={{ color: "blue" }}>
              Settings
            </a>
          </p>
          <div className="row mt-3">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group my-2">
                    <input
                      type="checkbox"
                      id="switch0"
                      data-switch="none"
                      checked={speedLayer}
                      onChange={(e) => {
                        setState({ ...state, speedLayer: !speedLayer });
                      }}
                    />
                    <label
                      for="switch0"
                      data-on-label=""
                      data-off-label=""
                    ></label>
                    <br />
                    <label className="overview-dialog-label">Speed</label>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group my-2">
                    <input
                      type="checkbox"
                      id="switch1"
                      data-switch="none"
                      name="workRelated"
                      checked={!speedLayer}
                      onChange={(e) => {
                        setState({ ...state, speedLayer: !speedLayer });
                      }}
                    />
                    <label
                      for="switch1"
                      data-on-label=""
                      data-off-label=""
                    ></label>
                    <br />
                    <label className="overview-dialog-label">Battery</label>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Show Trips From</label>
                    <Dropdown
                      search
                      selectOnNavigation={false}
                      selection
                      value={timeRange}
                      onChange={(e, { value }) => {
                        if (value != "7days") {
                          console.log(isSubscribed)
                          if (!isSubscribed) {
                            return setState({
                              ...state,
                              showSubscriptionMessage: true,
                            });
                          }
                        }

                        setState({ ...state, timeRange: value });
                      }}
                      options={[
                        { key: "7days", value: "7days", text: "Last 7 days" },
                        {
                          key: "1month",
                          value: "1month",
                          text: "Last 1 month",
                        },

                        {
                          key: "3month",
                          value: "3month",
                          text: "Last 3 months",
                        },
                        { key: "all", value: "all", text: "All" },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <label>Select Trip</label>
              <Dropdown
                selectOnNavigation={false}
                search
                selection
                fluid
                options={tripOptions}
                value={currentTrip}
                onChange={(e, { value }) => {
                  setState({ ...state, currentTrip: value });
                }}
              />
            </div>
          </div>
          {noTrips && <>
            <div
              class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
              role="alert"
            >
              <strong>Error - </strong> No trips found, Please select a time period.
            </div>
        </>}
        </div>

        {!noTrips && <>
          <div className="row">
            <div className="col-lg-8 card card-body">
              <div ref={(el) => (mapContainer.current = el)} style={styles} />
            </div>

            <div className="col-lg-4 card card-body text-center">
              <div className="row">
                <div className="col-lg-6">
                  <i className=" mdi mdi-crosshairs-gps h1 text-primary"></i>
                  <h4>Start Location</h4>
                  <p>{startLocation}</p>
                </div>
                <div className="col-lg-6">
                  <i className=" mdi mdi-crosshairs h1 text-primary"></i>
                  <h4>End Location</h4>
                  <p>{endLocation}</p>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6">
                  <i className=" mdi mdi-clock h1 text-primary"></i>
                  <h4>Duration</h4>
                  <p>{duration} minutes</p>
                </div>

                <div className="col-lg-6">
                  <i className=" mdi mdi-battery-charging h1 text-primary"></i>
                  <h4>Battery Used</h4>
                  <p>{batteryUsed} kWh</p>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6">
                  <i className=" mdi mdi-speedometer h1 text-primary"></i>
                  <h4>Distance Driven</h4>
                  <p>
                    {distanceDriven} {units.distanceunit}
                  </p>
                  <p>
                    {startOdometer &&
                      NumberFormat(parseInt(startOdometer), units.country_code)}
                    {units.distanceunit} -{" "}
                    {endOdometer &&
                      NumberFormat(parseInt(endOdometer), units.country_code)}
                    {units.distanceunit}
                  </p>
                </div>

                <div className="col-lg-6">
                  <i className=" mdi mdi-coin-outline h1 text-primary"></i>
                  <h4>Efficiency</h4>
                  <p>{efficiency}%</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card card-body">
                <h2>Trip History</h2>

                <CSVLink
                  data={tripCsv}
                  className="btn btn-primary my-2"
                  style={{ width: "15%" }}
                  filename={`trips_${vehicle.vin}.csv`}
                >
                  Download Trip Data
                </CSVLink>

                <table className="table-striped">
                  <thead>
                    <th>Count</th>
                    <th>Start Time </th>
                    <th>End Time</th>
                    <th>Duration (mins)</th>
                    <th>Distance ({units.distanceunit})</th>

                    <th>
                      Inside Temperature (
                      {"°" + units.temperatureunit.toUpperCase()}){" "}
                    </th>
                    <th>
                      Outside Temperature (
                      {"°" + units.temperatureunit.toUpperCase()})
                    </th>
                    <th>Potential Tax Savings</th>
                    <th>Work Related</th>
                  </thead>

                  <tbody>{renderTripTable()}</tbody>
                </table>
                <div className="float-right">
                  <nav
                    aria-label="Page navigation example"
                    style={{ float: "right" }}
                    className="my-2"
                  >
                    <ul class="pagination pagination">{renderPagination()}</ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </>}

        <SubscriptionDialog
          onClose={() => setState({ ...state, showSubscriptionMessage: false })}
          showSubscriptionMessage={showSubscriptionMessage}
        />
      </div>
    </>
  );
};

export default Trips;
