import simpleFetch from "../helpers/fetch-wrapper";

const ServerConfigService = {
  getServerConfigurationValue: async (configName) => {
    try {
      return await simpleFetch(`/configuration/${configName}`, "GET");

      //  {done:true,value:"true"}
    } catch (err) {
      console.log(err);
    }
  },
};

export default ServerConfigService;
