import React from "react";

const KeemutReportBanner = () => {
  return (
    <div className="card">
      <div className="card-body">
        <span>
          <div className="text-center">
            <span>
              <img
                src={require("../../assets/keemut-dark.png")}
                width="200"
              ></img>
              <span className="float-right p-3 certificate-img">
                <img
                  src={require("../../assets/keemut_cert.svg")}
                  width="150px"
                />
              </span>
            </span>
          </div>
          <div className="text-primary p-2 text-center">
            <span style={{ fontSize: "3rem" }}>
              <strong>Connected Car Vehicle History Report </strong>
            </span>
          </div>
        </span>
      </div>
    </div>
  );
};

export default KeemutReportBanner;
