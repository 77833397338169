import simpleFetch from "../helpers/fetch-wrapper";
import moment from "moment";

/**
 * @author Hemang Bhogayata
 * @description Get the recent odo of vehicle
 * @param {String} vehicleId
 * @param {Date} date
 */
export async function getRecentOdometerService(vehicleId, date) {
  try {
    return await simpleFetch(
      `/recent-odometer/${vehicleId}?date=${moment(date).format("YYYY-MM-DD")}`
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 * @author Hemang Bhogayata
 * @description Gets the current odometer value
 * @param {String} vehicleId
 * @returns
 */
export async function getCurrentOdometer(vehicleId) {
  try {
    return await simpleFetch(`/odometer/${vehicleId}`);
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Get the cost data
 * @param {String} vehicleId
 * @returns
 */
export async function getCostDataService(vehicleId) {
  try {
    return await simpleFetch(`/cost-data/${vehicleId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Get the cost per mile information
 * @param {String} vehicleId
 * @returns
 */
export async function getCostPerMileService(vehicleId) {
  try {
    return await simpleFetch(`/cost-per-mile/${vehicleId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

/**
 * @description Get cost per month
 * @param {String} vehicleId
 * @returns
 */
export async function getCostPerMonthService(vehicleId) {
  try {
    return await simpleFetch(`/cost-per-month/${vehicleId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

export async function getCostPerMileDataService(vehicleId) {
  try {
    return await simpleFetch(`/cost-per-mile-data/${vehicleId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

export async function getOdometerDrivingDataService(vehicleId) {
  try {
    return await simpleFetch(`/odometer-data/${vehicleId}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

export async function getMonthlyOdometerDataService(vehicleId) {
  try {
    return await simpleFetch(`/odometer-monthly/all/${vehicleId}`, "GET");
  } catch (err) {}
}
