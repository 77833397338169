import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { API, site } from "../../../../config";
import Dialog from "@material-ui/core/Dialog";
import resolveCurrency from "../../../../helpers/Helper";
import RecentOdometer from "../../common/RecentOdometer";
import RollbackDialog from "../../common/RollbackDialog";
import { DateInput } from "semantic-ui-calendar-react";
import { Helmet } from "react-helmet";
import FloatingButton from "../../common/FloatingButton";
import { getRecentOdometerService } from "../../../../services/odometer-history-service";
import { createFuelFormService } from "../../../../services/forms/fuel-form-service";
import NumberFormat from "../../../../helpers/number-format-helper";
const mixpanel = require("mixpanel-browser");

const AddFuel = ({ vehicle, updateOdometer }) => {
  const [state, setState] = useState({
    loading: true,
    success: false,
    date: null,
    odometer: null,
    amount: null,
    cost: null,
    refuelDistance: null,
    workRelated: "false",
    formData: new FormData(),
    units: {},
    error: null,
    submitting: false,
    futureError: false,
    showOdoDialog: false,
    showRollbackDialog: false,
    lastOdo: 0,
    ignoreRollback: false,
    pricePerUnit: 0,
    idealMpg: 0,
    showMpgDialog: false,
    ignoreMpg: false,
  });
  const {
    showMpgDialog,
    showRollbackDialog,
    loading,
    success,
    date,
    odometer,
    amount,
    cost,
    refuelDistance,
    workRelated,
    formData,
    units,
    error,
    submitting,
    futureError,
    showOdoDialog,
    lastOdo,
    ignoreRollback,
    pricePerUnit,
    idealMpg,
    ignoreMpg,
  } = state;

  useEffect(() => {
    mixpanel.track("add_fuel", {
      vehicleId: vehicle.id,
      uid: vehicle.fkusersuid,
    });
    intialRequest();
  }, []);

  const intialRequest = () => {
    setState({ ...state, loading: true });
    if (localStorage.getItem("units")) {
      const units = JSON.parse(localStorage.getItem("units"));
      console.log(units);
      setState({
        ...state,

        units,
        loading: false,
      });
      console.log("Initial Done");
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");
      fetch(`${API}/units/${user.uid}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((jsonData) => {
          localStorage.setItem("units", JSON.stringify(jsonData.units));
          setState({
            ...state,
            units: jsonData.units,
            loading: false,
          });
        })
        .catch((err) => console.log(err));
    }
  };

  const getOdo = async (date) => {
    try {
      const token = localStorage.getItem("token");

      const data = await getRecentOdometerService(vehicle.id, date);

      const mgpRes = await fetch(
        `${API}/vehicle-list/mpg?make=${vehicle.make}&model=${vehicle.model}&country=${vehicle.country_code}&crowdsource=${vehicle.is_crowdsourced}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const mpgData = await mgpRes.json();

      let mpg = mpgData.fuelMpg || 25;

      if (units.distanceunit == "km" && units.fuelunit == "ltr") {
        mpg = 0.43 * mpg;
      } else if (units.distanceunit == "mi" && units.fuelunit == "ltr") {
        mpg = 0.23 * mpg;
      }

      console.log(data);

      setState({
        ...state,
        lastOdo: data.previousRecord ? data.previousRecord.odometer : 0,
        idealMpg: mpg,
        date,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const isFormValid = () => {
    const mpg =
      (odometer.replaceAll(",", "") - lastOdo) / (cost / pricePerUnit);
    const change = (mpg - idealMpg) / idealMpg;
    if (!cost || !odometer || !date || !pricePerUnit) {
      setState({ ...state, error: "Please Fillout all the fields" });
      return false;
    } else if (odometer.replaceAll(",", "") < lastOdo && !ignoreRollback) {
      setState({ ...state, showRollbackDialog: true });
      return false;
    } else if (
      (change >= 0.25 || change < -0.25) &&
      !ignoreMpg &&
      lastOdo != 0
    ) {
      setState({ ...state, showMpgDialog: true });
    } else if (cost < 0) {
      setState({ ...state, error: "Cost Cannot be Negative" });
      return false;
    } else {
      return true;
    }
  };

  const addFuel = async (e) => {
    try {
      e.preventDefault();
      setState({
        ...state,
        submitting: true,
        error: null,
        showMpgDialog: false,
      });
      const token = localStorage.getItem("token");
      if (isFormValid()) {
        formData.set("cost", cost);
        formData.set("odometer", odometer.replaceAll(",", ""));
        formData.set("amount", cost / pricePerUnit);
        formData.set("pricePerUnit", pricePerUnit);
        formData.set(
          "date",
          date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2")
        );
        formData.set("workRelated", workRelated);
        formData.set("refuelDistance", odometer.replaceAll(",", "") - lastOdo);
        formData.set("ignore", ignoreMpg);

        const data = await createFuelFormService(formData, vehicle.id);

        localStorage.setItem("odoChange", true);
        setState({ ...state, submitting: false, success: true });
        updateOdometer(parseInt(odometer.replaceAll(",", "")));
      }
    } catch (err) {}
  };

  if (loading) {
    return <div class="spinner-border text-primary" role="status"></div>;
  }

  if (success) {
    return <Redirect to="/dashboard/overview" />;
  }

  return (
    <div className="fuel-main text-dark" style={{ maxWidth: "100%" }}>
      <Helmet>
        <title>Keemut | Fuel</title>
      </Helmet>
      <h3 className="fuel-header-heading">Add Fuel Details</h3>
      <FloatingButton />

      <div style={{ backgroundColor: "#ffffff" }} className="mx-2 my-3">
        <div className=" container p-3  card-body">
          <div className="row">
            <div className="col-lg-12">
              <div class="form-group">
                <label style={{ fontWeight: "700" }}>
                  Refuel / Recharge Event
                </label>
                <br /> <br />
                <label className="overview-dialog-label">Event Date</label>
                <input
                  type="date"
                  class="form-control date"
                  data-toggle="input-mask"
                  placeholder="09/09/2020"
                  min="2000-01-01"
                  name="date"
                  value={date}
                  onChange={(e) => {
                    getOdo(e.target.value);
                  }}
                  data-mask-format="00/00/0000"
                  data-single-date-picker="true"
                />
              </div>

              <div class="form-group">
                <label className="overview-dialog-label">
                  Enter Cost Per {units.fuelunit == "gal" ? "Gallon" : "Litre"}
                </label>

                <div className="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      {resolveCurrency(units.currencyunit.toUpperCase())}
                    </span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    name="pricePerUnit"
                    value={pricePerUnit}
                    onChange={handleChange}
                    aria-describedby="basic-addon1"
                    placeholder={`How many ${
                      units.fuelunit == "gal" ? "gallons" : "litres"
                    }?`}
                  />
                </div>
              </div>
              <div class="form-group">
                <label className="overview-dialog-label">Amount Paid</label>

                <div className="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      {resolveCurrency(units.currencyunit.toUpperCase())}
                    </span>
                  </div>
                  <input
                    class="form-control"
                    name="cost"
                    value={NumberFormat(
                      (cost || "").toString().replaceAll(",", "")
                    )}
                    onChange={handleChange}
                    aria-describedby="basic-addon1"
                    placeholder="How much did you pay ?"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <div class="form-group">
                    <label className="overview-dialog-label">
                      Enter Odometer{" "}
                    </label>

                    <div className="input-group">
                      <input
                        id="odoInput"
                        class="form-control"
                        name="odometer"
                        value={NumberFormat(
                          (odometer || "").toString().replaceAll(",", "")
                        )}
                        onChange={handleChange}
                        aria-describedby="basic-addon1"
                        placeholder="What was the odometer reading when refueling ?"
                      />
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          {units.distanceunit}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div class="form-group">
                    <label className="overview-dialog-label">
                      Last Odometer{" "}
                    </label>

                    <div className="input-group">
                      <input
                        id="odoInput"
                        type="number"
                        class="form-control"
                        disabled={true}
                        value={lastOdo}
                        aria-describedby="basic-addon1"
                        placeholder="What was the odometer reading when refueling ?"
                      />
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          {units.distanceunit}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div class="form-group">
                    <label className="overview-dialog-label">
                      Distance Between Fillups{" "}
                    </label>

                    <div className="input-group">
                      <input
                        id="odoInput"
                        type="number"
                        class="form-control"
                        value={odometer - lastOdo}
                        disabled={true}
                        aria-describedby="basic-addon1"
                        placeholder="What was the odometer reading when refueling ?"
                      />
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          {units.distanceunit}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div class="form-group">
                    <label className="overview-dialog-label">Fuel Volume</label>

                    <div className="input-group">
                      <input
                        id="odoInput"
                        type="number"
                        class="form-control"
                        disabled={true}
                        value={
                          (pricePerUnit && (cost / pricePerUnit).toFixed(2)) ||
                          0
                        }
                        onChange={handleChange}
                        aria-describedby="basic-addon1"
                      />
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                          {units.fuelunit}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="overview-dialog-label">
                  Was this Work Related ?
                </label>
                <br />
                <input
                  type="checkbox"
                  id="switch0"
                  data-switch="none"
                  name="workRelated"
                  value={workRelated}
                  onChange={handleChange}
                />
                <label for="switch0" data-on-label="" data-off-label=""></label>
              </div>

              <div class="form-group">
                <label>Upload Fuel Receipt</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="inputGroupFile04"
                      name="attachment"
                      onChange={(e) => {
                        document.getElementById("file-name").innerHTML =
                          e.target.files[0].name;
                        formData.set("attachment", e.target.files[0]);
                      }}
                    />
                    <label
                      class="custom-file-label"
                      id="file-name"
                      for="inputGroupFile04"
                    >
                      Optional
                    </label>
                  </div>
                </div>
              </div>
              <br />
              <button
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={addFuel}
              >
                {submitting ? (
                  <>
                    {" "}
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Saving...{" "}
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>

            <Dialog
              open={error}
              onClose={() => setState({ ...state, error: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div class="modal-content">
                <div class="modal-header modal-colored-header bg-warning">
                  <h4 class="modal-title" id="primary-header-modalLabel">
                    Please Fillout All the Fields
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={() => setState({ ...state, error: null })}
                  >
                    ×
                  </button>
                </div>
                <div class="modal-body">
                  It seems like you missed a few fields while filling up the
                  form.
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-light"
                    data-dismiss="modal"
                    onClick={() => setState({ ...state, error: null })}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Dialog>

            <Dialog
              open={showMpgDialog}
              onClose={() => setState({ ...state, showMpgDialog: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div class="modal-content">
                <div class="modal-header modal-colored-header bg-primary">
                  <h4 class="modal-title" id="primary-header-modalLabel">
                    Are You Sure?
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={() => setState({ ...state, showMpgDialog: null })}
                  >
                    ×
                  </button>
                </div>
                <div class="modal-body">
                  It seems like you missed a previous fuel entry, Are you sure
                  you want to proceed ?
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-light"
                    data-dismiss="modal"
                    onClick={() => setState({ ...state, showMpgDialog: false })}
                  >
                    Close
                  </button>

                  <button
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() =>
                      setState({
                        ...state,
                        showMpgDialog: false,
                        ignoreMpg: true,
                      })
                    }
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </Dialog>

            <Dialog
              open={futureError}
              onClose={() => setState({ ...state, futureError: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div class="modal-content">
                <div class="modal-header modal-colored-header bg-warning">
                  <h4 class="modal-title" id="primary-header-modalLabel">
                    Seems like You are trying to enter a Future event
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={() => setState({ ...state, futureError: null })}
                  >
                    ×
                  </button>
                </div>
                <div class="modal-body">
                  The date field must be today or a past date, To Add a
                  reminder, head over to reminders.
                </div>
                <div class="modal-footer">
                  <a href={`${site}/dashboard/tco`}>
                    <button
                      type="button"
                      class="btn btn-warning"
                      data-dismiss="modal"
                      onClick={() => setState({ ...state, futureError: null })}
                    >
                      Go To Reminders
                    </button>
                  </a>
                </div>
              </div>
            </Dialog>

            <RollbackDialog
              units={units}
              showRollbackDialog={showRollbackDialog}
              cancelRollback={() =>
                setState({
                  ...state,
                  showRollbackDialog: false,
                  ignoreRollback: false,
                })
              }
              proceedRollback={() =>
                setState({
                  ...state,
                  showRollbackDialog: false,
                  ignoreRollback: true,
                })
              }
              lastOdo={lastOdo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFuel;
