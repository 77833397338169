import React, { useEffect, useState } from "react";
import * as firebase from "firebase";
import { set } from "d3v4";
import { nextSite } from "../../config";

const AuthAction = (props) => {
  const [state, setState] = useState({
    emailVerified: false,
    showNewPasswordForm: false,
    loading: false,

    oob: null,
    auth: null,
    newPassword: "",
    error: false,
    success: false,
  });

  const {
    emailVerified,
    showNewPasswordForm,
    oob,
    auth,
    loading,
    error,
    success,
    newPassword,
  } = state;

  const handleVerifyEmail = (auth, oobCode) => {
    auth.applyActionCode(oobCode).then((res) => {
      setState({ ...state, emailVerified: true });
    });
  };

  const handlePasswordReset = (auth, oboCode) => {
    auth
      .verifyPasswordResetCode(oboCode)
      .then((res) => {
        setState({
          ...state,
          showNewPasswordForm: true,
          auth: auth,
          oob: oboCode,
        });
      })
      .catch((err) => {});
  };

  const setNewPassword = () => {
    auth
      .confirmPasswordReset(oob, newPassword)
      .then(function (resp) {
        setState({ ...state, success: true, error: false, loading: false });
      })
      .catch(function (error) {
        setState({ ...state, error: true, success: true, loading: false });
      });
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const mode = params.get("mode");
    const oobCode = params.get("oobCode");
    const apiKey = params.get("apiKey");
    // console.log(oobCode, apiKey);
    const config = {
      apiKey: apiKey,
    };

    if (!firebase.apps.length) {
      var app = firebase.initializeApp(config);
    }
    const auth = app.auth();
    switch (mode) {
      case "verifyEmail":
        handleVerifyEmail(auth, oobCode);
        break;
      case "resetPassword":
        handlePasswordReset(auth, oobCode);
        break;
      default:
        break;
    }
  }, []);

  if (emailVerified) {
    return (
      <div class="authentication-bg" style={{ height: "100vh" }}>
        <div class="account-pages mb-5">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-5">
                <div class="card mt-5">
                  <div class="card-header pt-4 pb-4 text-center bg-primary">
                    <a href="/">
                      <span>
                        <img
                          src={require("../../assets/keemut-2.png")}
                          alt=""
                          width="100"
                          height="30"
                        />
                      </span>
                    </a>
                  </div>

                  <div class="card-body p-4">
                    <div class="text-center m-auto">
                      <img
                        src={require("../../assets/mail_sent.svg")}
                        alt="mail sent image"
                        height="64"
                      />
                      <h4 class="text-dark-50 text-center mt-4 font-weight-bold">
                        Email Verified
                      </h4>
                      <p class="text-muted mb-4">
                        Your Email has been verified, You can now proceed to
                        login.
                      </p>
                    </div>

                    <div class="form-group mb-0 text-center">
                      <a href="/login" style={{ color: "#ffffff" }}>
                        <button class="btn btn-primary">
                          <i class="mdi mdi-home mr-1"></i> Login
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer footer-alt">2020 © Keemut</footer>
      </div>
    );
  }

  if (showNewPasswordForm) {
    return (
      <div class="authentication-bg" style={{ height: "100vh" }}>
        <div class="account-pages mb-5">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-5">
                <div class="card mt-5">
                  <div class="card-header pt-4 pb-4 text-center bg-primary">
                    <a href={`${nextSite}/`}>
                      <span>
                        <img
                          src={require("../../assets/keemut-2.png")}
                          alt=""
                          width="100"
                          height="30"
                        />
                      </span>
                    </a>
                  </div>

                  <div class="card-body p-4">
                    <div class="text-center m-auto">
                      <h4 class="text-dark-50 text-center mt-4 font-weight-bold">
                        Reset Password
                      </h4>
                      <p class="text-muted mb-4">
                        An Email will be sent to your email with the
                        instructions to reset your password.
                      </p>
                    </div>
                    {error && (
                      <div
                        class="my-2 alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
                        role="alert"
                      >
                        Unable to change your password at this time.
                      </div>
                    )}

                    {success && (
                      <div
                        class="my-2 alert alert-success alert-dismissible bg-success text-white border-0 fade show"
                        role="alert"
                      >
                        Your password has been changed, You can close this page
                        now.
                      </div>
                    )}
                    <div className="form-group">
                      <label for="emailaddress">New Password</label>
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="password"
                          id="password"
                          required="true"
                          placeholder="Enter your new password"
                          name="newPassword"
                          value={newPassword}
                          onChange={(e) =>
                            setState({ ...state, newPassword: e.target.value })
                          }
                        />
                        <div
                          className="input-group-append"
                          data-password="false"
                        >
                          <div
                            className="input-group-text"
                            onClick={() => {
                              const passwordInp = document.getElementById(
                                "password"
                              );
                              if (passwordInp.type === "text") {
                                passwordInp.type = "password";
                              } else {
                                passwordInp.type = "text";
                              }
                            }}
                          >
                            <span className="password-eye"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group mb-0 text-center">
                      <button onClick={setNewPassword} class="btn btn-primary">
                        {loading ? (
                          <>
                            {" "}
                            <span
                              class="spinner-grow spinner-grow-sm mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...{" "}
                          </>
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer footer-alt">2020 © Keemut</footer>
      </div>
    );
  }

  return <div>...</div>;
};

export default AuthAction;
