import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../../config";
import resolveCurrency from "../../../helpers/Helper";
import { getCostDataService } from "../../../services/odometer-history-service";

const CostDataChart = ({ vehicle, units }) => {
  const [state, setState] = useState({
    options: {
      chart: {
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
        id: "basic-line",
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 5,
          color: "#00274c",
          opacity: 0.45,
        },
      },
      xaxis: {
        categories: [],
      },
      colors: ["#00274c"],

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2.75,
        dashArray: 0,
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
    loading: true,
  });

  const { options, series, loading } = state;

  useEffect(() => {
    getFuelMpgData();
  }, [vehicle]);

  const getFuelMpgData = async () => {
    try {
      setState({ ...state, loading: true });
      const costDataJson = localStorage.getItem("costData");
      const odoChange = localStorage.getItem("odoChange");

      if (odoChange === "false" && costDataJson) {
        const data = JSON.parse(costDataJson);

        if (vehicle.id === data.vehicleId) {
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];

          const xaxisData = [];
          const yaxisData = [];
          data.costData.sort(function (costData1, costData2) {
            let res = 0;
            if (costData1.yyyy === costData2.yyyy) {
              if (costData1.month < costData2.month) {
                return -1;
              } else {
                return 1;
              }
            } else {
              if (costData1.yyyy < costData2.yyyy) {
                return -1;
              } else {
                return 1;
              }
            }
          });
          // console.log(data.costData);
          // data.costData.reverse();
          // console.log("Reverse : " + data.costData);

          data.costData.forEach((dataPoint) => {
            xaxisData.push(
              `${monthNames[dataPoint.month - 1]} ${dataPoint.yyyy}`
            );
            yaxisData.push(dataPoint.sum);
          });
          return setState({
            ...state,
            loading: false,
            options: {
              ...options,

              yaxis: {
                title: {
                  text: `Cost (${resolveCurrency(
                    units.currencyunit.toUpperCase()
                  )})`,
                },
              },
              xaxis: {
                type: "Month",
                categories: xaxisData,
                title: { text: "Month" },
              },
            },
            series: [
              {
                data: yaxisData,
              },
            ],
          });
        }
      }

      const data = await getCostDataService(vehicle.id);

      localStorage.setItem(
        "costData",
        JSON.stringify({
          vehicleId: vehicle.id,
          costData: data.costData,
        })
      );
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const xaxisData = [];
      const yaxisData = [];

      data.costData.sort(function (costData1, costData2) {
        let res = 0;
        if (costData1.yyyy === costData2.yyyy) {
          if (costData1.month < costData2.month) {
            return -1;
          } else {
            return 1;
          }
        } else {
          if (costData1.yyyy < costData2.yyyy) {
            return -1;
          } else {
            return 1;
          }
        }
      });
      data.costData.forEach((dataPoint) => {
        xaxisData.push(`${monthNames[dataPoint.month - 1]} ${dataPoint.yyyy}`);
        yaxisData.push(dataPoint.sum);
      });
      setState({
        ...state,
        loading: false,
        options: {
          ...options,
          yaxis: {
            title: {
              text: `Cost (${resolveCurrency(
                units.currencyunit.toUpperCase()
              )})`,
            },
          },
          xaxis: {
            type: "Month",
            categories: xaxisData,
            title: { text: "Month" },
          },
        },
        series: [
          {
            name: "Cost",
            data: yaxisData,
          },
        ],
      });
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return (
      <div class="card">
        <div class="card-body">
          <h3>Cost Chart</h3>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  return (
    <div class="card" style={{ height: "100%" }}>
      <div class="card-body">
        <h3 title="This is a summary of all the cost incurred on your car during its lifespan.">
          {" "}
          Cost Chart
        </h3>
        <Chart options={options} series={series} type="line" />
      </div>
    </div>
  );
};

export default CostDataChart;
