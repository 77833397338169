import React, { useEffect, useState } from "react";
import TeslaService from "../../../../helpers/tesla-helper";
import LineChart from "../../../custom-charts/LineChart";
import TemperatureConversion from "../../../../helpers/TemperatureConversion";

const TemperatureVsEfficiencyChart = ({ vehicle, units }) => {
  const [state, setState] = useState({
    _loading: false,
    _error: null,
    _yaxis: [],
    _xaxis: [],
  });
  const { _error, _loading, _xaxis, _yaxis } = state;

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    try {
      setState({ ...state, _loading: true });
      const data = await TeslaService.getTemperatureVsEfficiency(vehicle.vin);

      let yaxis = [];
      let xaxis = [];
      data.result.forEach((item) => {
        let t1 = item.temperature_bin.split(",")[0];
        let t2 = item.temperature_bin.split(",")[1];

        if (
          units &&
          units.temperatureunit &&
          units.temperatureunit.toUpperCase() == "F"
        ) {
          t1 = (t1 * 9) / 5 + 32;
          t2 = (t2 * 9) / 5 + 32;
        }

        item.temperature_bin = `${parseInt(t1)},${parseInt(t2)}`;

        yaxis.push(parseInt(item.efficiency));

        xaxis.push(item.temperature_bin.replace(",", " to "));
      });
      setState({ ...state, _yaxis: yaxis, _xaxis: xaxis, _loading: false });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="card card-body">
      <h3>Temperature vs Efficiency</h3>
      {_loading && (
        <div className="spinner spinner-border center-spinner"> </div>
      )}
      {!_loading && (
        <LineChart
          xaxis={_xaxis}
          yaxis={_yaxis}
          xAxisTitle={`Temperature Range (°${units.temperatureunit})`}
          yAxisTitle="Efficiency (%)"
        />
      )}
    </div>
  );
};

export default TemperatureVsEfficiencyChart;
