/**
 * @author Hemang Bhogayata [hemang@dashroad.com]
 * @copyright Keemut LLC, See License.txt for more info
 * @created 25 Feb, 2021
 */

import React, { useState, useEffect } from "react";
import { API, nextSite, site } from "../../../config";
import Dialog from "@material-ui/core/Dialog";
import Stepper from "react-stepper-horizontal";
import { Dropdown, Popup } from "semantic-ui-react";
import simpleFetch from "../../../helpers/fetch-wrapper";
import getReferrer from "../../../helpers/Referrer";
import getUser from "../../../helpers/user";
import TeslaHelper from "../../../helpers/tesla-helper";
import ServerConfigService from "../../../services/server-config-service";
import ServerConfigs from "../../../server-config";

const mixpanel = require("mixpanel-browser");

const AddConnectedTesla = ({
  showTeslaDialog,
  closeTeslaDialog,
  getAllVehicles,
}) => {
  const [state, setState] = useState({
    isWorking: true,
    sessionId: null,
    mfaCode: "",
    failure: false,
    email: null,
    isMfa: true,
    password: null,
    vehicles: [],
    loading: false,
    error: null,
    sessionCookie: null,
    showMfaStep: false,
    currentStep: 0,
    showConfigureStep: false,
    success: false,
    showTeslaLogin: true,
    showConnectWithKeemut: false,
    mfaDeviceList: [],
    selectedMfaDevice: null,
    timezone: null,
    steps: [
      {
        title: "Login With Tesla",
      },
      { title: "MFA Verification" },
      { title: "Connect With Keemut" },
      { title: "Configure Your Vehicles" },
      { title: "Done" },
    ],
    redirect: false,
    accessToken: null,
    refreshToken: null,
    meta: {
      total: 0,
      failed: 0,
    },
    trimOptions: [],
    trim: null,
    trimLoading: false,
    vehicle: null,
    totalVehicles: null,
  });
  const {
    meta,
    email,
    password,
    loading,
    error,
    sessionId,
    steps,
    currentStep,
    success,
    redirect,
    showConnectWithKeemut,
    trimLoading,
    showTeslaLogin,
    failure,
    timezone,
    accessToken,
    mfaCode,
    sessionCookie,
    showMfaStep,
    showConfigureStep,
    vehicles,
    isMfa,
    refreshToken,
    trim,
    trimOptions,
    selectedMfaDevice,
    mfaDeviceList,
    vehicle,
  } = state;

  useEffect(() => {
    _doesTeslaExist();
  }, []);

  const _doesTeslaExist = async () => {
    try {
      const isWorkingData =
        await ServerConfigService.getServerConfigurationValue(
          ServerConfigs.WEB_TESLA_LOGIN_ALLOWED
        );

      let isWorking = isWorkingData == "true" ? true : false;

      const data = await TeslaHelper.doesExist();

      if (data.exists) {
        setState({
          ...state,
          currentStep: 2,
          isWorking,
          showTeslaLogin: false,
          showConnectWithKeemut: true,
        });
      } else {
        setState({ ...state, isWorking });
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * @author Hemang Bhogayata
   * @description Uses Tesla credentials supplied by user to initiate the mfa process, receives a session cookie in return which is used in teslaLoginStep2
   * @see Tesla MFA Docs to understand more about how the MFA process works
   */
  const teslaLoginStep1 = async () => {
    try {
      setState({ ...state, loading: true, error: null });
      const data = await simpleFetch(
        "/tesla/login-step-1",
        "POST",
        JSON.stringify({
          email,
          password,
        })
      );

      if (data.authResponse) {
        if (data.authResponse.error) {
          let e = data.authResponse.error;
          let errMsg = "";
          if (e == "Auth Form Timeout" || e == "Post Auth Timeout") {
            errMsg = "Your request timed out. Please try again.";
          } else if (e == "Account Locked") {
            errMsg =
              "Your Tesla account seems to have been locked. Please unlock it on Tesla.com";
          } else if (e == "Check Email & Password") {
            errMsg = "Invalid Email or password";
          }

          setState({
            ...state,
            error: errMsg,
            loading: false,
          });
        } else {
          let deviceOptions = [];
          if (data.authResponse.device_list) {
            data.authResponse.device_list.forEach((device) =>
              deviceOptions.push({
                key: device,
                text: device,
                value: device,
              })
            );
          }

          // pre-select first device by default
          setState({
            ...state,
            error: null,
            mfaDeviceList: deviceOptions,
            selectedMfaDevice:
              deviceOptions.length > 0 && deviceOptions[0].value,
            sessionId: data.authResponse.id,
            sessionCookie: data.sessionCookie,
            isMfa: data.authResponse.is_mfa,
            showTeslaLogin: false,
            currentStep: 1,
            loading: false,
            showMfaStep: true,
          });
        }
      }
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        error: "An error occured, please try again later",
        loading: false,
      });
    }
  };

  /**
   * @author Hemang Bhogayata
   * @description This step uses the sessionCookie and mfa code to complete the auth process
   */
  const teslaLoginStep2 = async () => {
    try {
      let user = getUser();
      mixpanel.track("tesla_login", {
        uid: user && user.uid,
        referrer: getReferrer(),
      });
      setState({ ...state, loading: true, error: null });

      const data = await simpleFetch(
        "/tesla/login-step-2",
        "POST",
        JSON.stringify({
          sessionId,
          passcode: mfaCode,
          sessionCookie,
          device: selectedMfaDevice,
        })
      );

      if (data.authResponse) {
        if (data.authResponse.error) {
          let e = data.authResponse.error;
          let errMsg = "";
          if (e == "Login First! user not found") {
            errMsg = "Your request timedout, please try again";
          } else if (e == "Invalid passcode") {
            errMsg =
              "The provided Multi Factor Authentication code is valid for less than 30 seconds and has expired. Please try again";
          } else if (
            e == "Location not found(MFA)" ||
            e == "Location Not found(non-mfa)" ||
            e == "Token Empty error"
          ) {
            errMsg = "Your request timedout, please try again";
          }

          setState({
            ...state,
            error: errMsg,
            loading: false,
          });
        } else {
          setState({
            ...state,
            error: null,
            currentStep: 2,
            showMfaStep: false,
            showConnectWithKeemut: true,
          });
        }
      }
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        error: "An error occured, please try again.",
        loading: false,
      });
    }
  };

  /**
   * @author Hemang Bhogayata
   * @description Onboard user's telsa vehicles
   */
  const getTeslaVehicles = () => {
    const token = localStorage.getItem("token");
    setState({ ...state, loading: true });
    fetch(`${API}/tesla/seed-vehicles`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((vdata) => {
        if (!vdata.done && vdata.error) {
          return setState({
            ...state,
            failure: true,
            success: false,
            loading: false,
          });
        }

        setState({
          ...state,
          currentStep: 3,
          failure: false,
          showConnectWithKeemut: false,
          showConfigureStep: true,
          loading: false,
          vehicles: vdata.vehicles,
          vehicle: vdata.vehicles.length > 0 ? vdata.vehicles[0] : null,
          meta: vdata.meta,
        });
      })
      .catch((err) => {
        setState({
          ...state,
          error: "Unable to fetch your Tesla Vehicles, Please Try again later.",
          loading: false,
        });
        console.log(err);
      });
  };

  useEffect(() => {
    /**
     * @author Hemang Bhogayata
     * @description Get Trims based on MMY
     */
    const getTrim = async () => {
      try {
        const data = await simpleFetch(
          `/vehicle-list/trim?make=${vehicle.make}&model=${vehicle.model}&year=${vehicle.year}`,
          "GET"
        );

        let trimOps = [];

        data.trimList.forEach((t) =>
          trimOps.push({ key: t.trim, value: t.trim, text: t.trim })
        );

        setState({ ...state, trimOptions: trimOps, trim: vehicle.trim });
      } catch (err) {}
    };

    let mounted = true;

    if (mounted && vehicle) {
      getTrim();
    }

    return function cleanup() {
      mounted = false;
    };
  }, [vehicle]);

  /**
   * @author Hemang Bhogayata
   * @description Update trim of the vehicle
   */
  const updateTrim = async () => {
    try {
      setState({ ...state, trimLoading: true });

      const data = await simpleFetch(
        `/vehicles/${vehicle.id}`,
        "PUT",
        JSON.stringify({
          nickname: vehicle.name,
          purchasePrice: vehicle.purchaseprice,
          odometer: vehicle.odometer,
          year: vehicle.year,
          vin: vehicle.vin,
          plateNo: vehicle.plate_no,
          trim: trim,
          workRelated: vehicle.work_related,
        })
      );

      let myV = vehicles;
      myV.shift();

      setState({
        ...state,
        vehicles: myV,
        trimLoading: false,
        vehicle: myV.length > 0 ? myV[0] : null,
      });
    } catch (err) {
      console.log(err);
      setState({ ...state, trimLoading: false });
    }
  };

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  if (redirect) {
    window.location = `${site}/dashboard/vehicles`;
  }

  if (!state.isWorking) {
    return (
      <div className="p-3 text-dark">
        <div className="card card-body text-dark text-center">
          <p>
            Connecting your Tesla from the website is not currently possible.
            You can connect via the{" "}
            <a
              href="https://apps.apple.com/us/app/keemut-connected-car-for-tesla/id1549242352"
              target="_blank"
              style={{ color: "blue" }}
            >
              Keemut app
            </a>{" "}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-3 text-dark">
      <div className="card card-body text-dark">
        <Stepper
          steps={steps}
          activeStep={currentStep}
          activeColor="#02d9e1"
          completeColor="#313a46"
        />
        <br /> <br />
        {error && (
          <div class="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        {showTeslaLogin && (
          <>
            {" "}
            <div class="form-group">
              <label>Enter Tesla Email</label>

              <input
                type="text"
                class="form-control "
                name="email"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div class="form-group">
              <label>Enter Tesla Password</label>

              <input
                type="password"
                class="form-control "
                name="password"
                value={password}
                onChange={handleChange}
              />
            </div>
            <small className="mt-3">
              {" "}
              We Don't Store your Tesla Email or Password. You can{" "}
              <a
                target="_blank"
                href={`${nextSite}/about/security`}
                className="text-primary"
              >
                <strong>read more here</strong>
              </a>
            </small>{" "}
            <br /> <br />
            <button
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={teslaLoginStep1}
            >
              {loading ? "Loading..." : "Login"}
            </button>
          </>
        )}
        {showMfaStep && (
          <>
            {isMfa && (
              <>
                <div className="form-group">
                  <label>
                    Select Authenticator App{" "}
                    <Popup
                      className="ml-4"
                      content={
                        "The passcode generated on the Authenticator app from this Device will be used for MFA Verification. If you have multiple devices please ensure you select any one."
                      }
                      trigger={
                        <i className="mdi mdi-information-outline hover-pointer"></i>
                      }
                    />
                  </label>
                  <Dropdown
                    search
                    fluid
                    selection
                    options={mfaDeviceList}
                    value={selectedMfaDevice}
                    onChange={(e, { value }) =>
                      setState({ ...state, selectedMfaDevice: value })
                    }
                  />
                </div>

                <div class="form-group">
                  <label>
                    Enter Multi-Factor Authentication Passcode from your
                    Authenticator App.
                  </label>

                  <input
                    class="form-control "
                    name="mfaCode"
                    value={mfaCode}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}

            {!isMfa && (
              <p>
                You have not enabled Multi-Factor Authentication (MFA) with
                Tesla. Please press Proceed to continue.
              </p>
            )}

            <button
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={teslaLoginStep2}
            >
              {loading ? "Loading..." : "Proceed"}
            </button>
          </>
        )}
        {showConfigureStep && (
          <>
            {vehicles.length == 0 && (
              <>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setState({ ...state, success: true });
                  }}
                >
                  Continue
                </button>
              </>
            )}

            {vehicles.length > 0 && (
              <>
                <div className="form-group mt-3">
                  <label>Select Trim For {vehicles[0].name}</label>
                  <br />

                  <Dropdown
                    fluid
                    search
                    selection
                    placeholder="Select Trim"
                    options={trimOptions}
                    value={trim}
                    onChange={(e, { value }) =>
                      setState({ ...state, trim: value })
                    }
                  />
                </div>

                <button className="btn btn-primary" onClick={updateTrim}>
                  {trimLoading ? "Loading..." : "Next"}
                </button>
              </>
            )}
          </>
        )}
        {showConnectWithKeemut && (
          <button
            className="btn btn-primary"
            disabled={loading}
            onClick={getTeslaVehicles}
          >
            {loading
              ? "Connecting Your Vehicles With Keemut..."
              : " Connect My Vehicles with Keemut"}
          </button>
        )}
        <br />
      </div>
      <Dialog
        open={success}
        onClose={() => setState({ ...state, success: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div class="modal-content modal-filled ">
          <div class="modal-body p-4">
            <div class="text-center text-dark">
              <i class=" mdi mdi-car-connected h1"></i>
              <h4 class="mt-2 h3 ">Keemut Connection Successful 🎊</h4>
              <p class="mt-3 " style={{ textAlign: "start" }}>
                <strong>Cars Connected:</strong>{" "}
                {meta && meta.total - meta.failed} out of {meta && meta.total}
              </p>

              <p class="mt-3" style={{ textAlign: "start" }}>
                Please proceed to select your subscription plan. You will have
                15 days free trial period for a Tesla connected car, in which
                you can cancel at anytime.
              </p>
              <a
                class="btn btn-outline-primary my-2"
                href={`${site}/dashboard/vehicles`}
              >
                Continue
              </a>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={failure}
        onClose={() => setState({ ...state, failure: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div class="modal-content modal-filled bg-dark">
          <div class="modal-body p-4">
            <div class="text-center">
              <i class=" mdi mdi-wifi-strength-2-alert h1"></i>
              <h4 class="mt-2">Keemut Connection Failed!</h4>
              <p class="mt-3">
                Your Tesla Vehicle Seems to be asleep, Please login once into
                the tesla app and then try again.
              </p>
              <button
                type="button"
                class="btn btn-light my-2"
                data-dismiss="modal"
                onClick={() => {
                  setState({ ...state, failure: false });
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddConnectedTesla;
