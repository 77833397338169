import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import resolveCurrency from "../../../helpers/Helper";
import NumberFormat from "../../../helpers/number-format-helper";
import { getMaintenanceAnalyticsService } from "../../../services/forms/maintenance-form-service";

const AnalyticsTable = ({ vehicle, units }) => {
  const [state, setState] = useState({
    loading: true,
    analytics: {},
    count: {},
    error: null,
    odometer: 0,
  });
  const { count, loading, analytics, error, odometer } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const token = localStorage.getItem("token");
      setState({ ...state, loading: true, error: null });

      getMaintenanceAnalyticsService(vehicle.id)
        .then((data) => {
          fetch(
            `${API}/maintenance-schedule-count/${vehicle.id}?make=${vehicle.make}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((countData) => {
              // console.log(JSON.stringify(data.analytics));
              setState({
                ...state,
                loading: false,
                error: null,
                analytics: data.analytics,
                count: countData.maintenanceCount,
                odometer: countData.odometer,
              });
            })
            .catch((err) => {
              console.log(err);
              setState({
                ...state,
                loading: false,
                error:
                  "Unable to fetch your Maintenance Analytics at this time.",
              });
            });
        })
        .catch((err) => {
          console.log(err);
          setState({
            ...state,
            loading: false,
            error: "Unable to fetch your Maintenance Analytics at this time.",
          });
        });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [vehicle]);

  const computeCondition = (property) => {
    let actualCount = analytics[`${property}_sum`];
    let recommendedCount = 0 * 8 + count[`${property}Count`] || 1;
    const change = ((actualCount - recommendedCount) / recommendedCount) * 100;
    if (analytics[`${property}_sum`] == 0 && count[`${property}Count`] == 0) {
      return <span class="badge badge-success">OK</span>;
    }

    if (change <= 5 && change >= -5) {
      return <span class="badge badge-success">OK</span>;
    } else {
      return <span class="badge badge-warning">ASK</span>;
    }
  };

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h2>Maintenance Totals</h2>

          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card">
        <div className="card-body">
          <h2>Maintenance Totals</h2>
          <div
            class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
            role="alert"
          >
            <strong>Error - </strong> {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h2>Maintenance Totals</h2>
        <p>
          A comparison between your vehicle and the manufacturer recommended
          maintenance count.
        </p>
        <table className="table-striped">
          <thead>
            <tr>
              <th>Category</th>
              <th>Amount</th>
              <th>Count</th>
              <th>Recommended Count Since Purchase</th>

              <th>Recommended Count Since Manufacture</th>
              <th>Condition</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td data-label="Category">AC Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(analytics.ac_cost.toFixed(0), units.country_code)}
              </td>

              <td data-label="Count">{analytics.ac_sum}</td>
              <td data-label="Recommended Count Since Purchase">
                {count.acCountPostKeemut}
              </td>

              <td data-label="Recommended Count">
                {count.acCount ? 0 + count.acCount : 0}
              </td>
              <td data-label="Condition">{computeCondition("ac")}</td>
            </tr>

            <tr>
              <td data-label="Category">Body Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(
                  analytics.body_cost.toFixed(0),
                  units.country_code
                )}
              </td>

              <td data-label="Count">{analytics.body_sum}</td>
              <td data-label="Recommended Count Since Purchase">
                {count.bodyCountPostKeemut}
              </td>
              <td data-label="Recommended Count">
                {count.bodyCount ? 0 + count.bodyCount : 0}
              </td>
              <td data-label="Condition">{computeCondition("body")}</td>
            </tr>
            <tr>
              <td data-label="Category">Engine Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(
                  analytics.engine_cost.toFixed(0),
                  units.country_code
                )}
              </td>
              <td data-label="Count">{analytics.engine_sum}</td>
              <td data-label="Recommended Count Since Purchase">
                {count.engineCountPostKeemut}
              </td>
              <td data-label="Recommended Count">
                {count.engineCount ? 0 + count.engineCount : 0}
              </td>
              <td data-label="Condition">{computeCondition("engine")}</td>
            </tr>
            <tr>
              <td data-label="Category">Electrical Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(
                  analytics.electrical_cost.toFixed(0),
                  units.country_code
                )}
              </td>

              <td data-label="Count">{analytics.electrical_sum}</td>
              <td data-label="Recommended Count Since Purchase">
                {count.electricalCountPostKeemut}
              </td>
              <td data-label="Recommended Count">
                {count.electricalCount ? 0 + count.electricalCount : 0}
              </td>
              <td data-label="Condition">{computeCondition("electrical")}</td>
            </tr>

            <tr>
              <td data-label="Category">Interior Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(
                  analytics.interior_cost.toFixed(0),
                  units.country_code
                )}
              </td>

              <td data-label="Count">{analytics.interior_sum}</td>
              <td data-label="Recommended Count Since Purchase">
                {count.interiorCountPostKeemut}
              </td>
              <td data-label="Recommended Count">
                {count.interiorCount ? 0 + count.interiorCount : 0}
              </td>
              <td data-label="Condition">{computeCondition("interior")}</td>
            </tr>

            <tr>
              <td data-label="Category">Other Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(
                  analytics.other_cost.toFixed(0),
                  units.country_code
                )}
              </td>

              <td data-label="Count">{analytics.other_sum}</td>
              <td data-label="Recommended Count Since Purchase">
                {count.otherCountPostKeemut || 0}
              </td>
              <td data-label="Recommended Count">
                {count.otherCount ? count.otherCount : 0}
              </td>
              <td data-label="Condition">
                <span class="badge badge-success">OK</span>
              </td>
            </tr>

            <tr>
              <td data-label="Category">Steering Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(
                  analytics.steering_cost.toFixed(0),
                  units.country_code
                )}
              </td>

              <td data-label="Count">{analytics.steering_sum}</td>
              <td data-label="Recommended Count Since Purchase">
                {count.steeringCountPostKeemut}
              </td>
              <td data-label="Recommended Count">
                {count.steeringCount ? 0 + count.steeringCount : 0}
              </td>
              <td data-label="Condition">{computeCondition("steering")}</td>
            </tr>
            <tr>
              <td data-label="Category">Suspension Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(
                  analytics.suspension_cost.toFixed(0),
                  units.country_code
                )}
              </td>

              <td data-label="Count">{analytics.suspension_sum}</td>
              <td data-label="Recommended Count Since Purchase">
                {count.suspensionCountPostKeemut}
              </td>
              <td data-label="Recommended Count">
                {count.suspensionCount ? 0 + count.suspensionCount : 0}
              </td>
              <td data-label="Condition">{computeCondition("suspension")}</td>
            </tr>

            <tr>
              <td data-label="Category">Tire / Brake Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(
                  analytics.tyre_brake_cost.toFixed(0),
                  units.country_code
                )}
              </td>

              <td data-label="Count">{analytics.tyre_brake_sum}</td>
              <td data-label="Recommended Count Since Purchase">
                {count.tyreBrakeCountPostKeemut}
              </td>
              <td data-label="Recommended Count">
                {count.tyreBrakeCount ? 0 + count.tyreBrakeCount : 0}
              </td>
              <td data-label="Condition">{computeCondition("tyre_brake")}</td>
            </tr>
            <tr>
              <td data-label="Category">Transmission Related</td>
              <td data-label="Amount">
                {resolveCurrency(units.currencyunit)}{" "}
                {NumberFormat(
                  analytics.transmission_cost.toFixed(0),
                  units.country_code
                )}
              </td>

              <td data-label="Count">{analytics.transmission_sum}</td>
              <td data-label="Recommended Count Since Purchase">
                {count.transmissionCountPostKeemut}
              </td>
              <td data-label="Recommended Count">
                {count.transmissionCount ? 0 + count.transmissionCount : 0}
              </td>
              <td data-label="Condition">{computeCondition("transmission")}</td>
            </tr>

            <tr style={{ borderTop: "2px solid #000" }}>
              <td data-label="Category">
                <strong>Total</strong>
              </td>
              <td data-label="Amount">
                <strong>
                  {resolveCurrency(units.currencyunit)}{" "}
                  {NumberFormat(
                    parseInt(analytics.other_cost) +
                      parseInt(analytics.engine_cost) +
                      parseInt(analytics.electrical_cost) +
                      parseInt(analytics.ac_cost) +
                      parseInt(analytics.tyre_brake_cost) +
                      parseInt(analytics.transmission_cost) +
                      parseInt(analytics.interior_cost) +
                      parseInt(analytics.steering_cost) +
                      parseInt(analytics.suspension_cost),
                    units.country_code
                  )}
                </strong>
              </td>

              <td data-label="Count">
                {analytics.engine_sum +
                  analytics.ac_sum +
                  analytics.electrical_sum +
                  analytics.tyre_brake_sum +
                  analytics.transmission_sum +
                  analytics.interior_sum +
                  analytics.steering_sum +
                  analytics.suspension_sum +
                  analytics.other_sum}
              </td>
              <td>
                {count.engineCountPostKeemut +
                  count.acCountPostKeemut +
                  count.electricalCountPostKeemut +
                  count.tyreBrakeCountPostKeemut +
                  count.transmissionCountPostKeemut +
                  count.interiorCountPostKeemut +
                  count.steeringCountPostKeemut +
                  count.suspensionCountPostKeemut +
                  count.otherCountPostKeemut}
              </td>
              <td data-label="Recommended Count">
                {parseInt(odometer / 50000) * 8 +
                  count.engineCount +
                  count.acCount +
                  count.bodyCount +
                  count.electricalCount +
                  count.tyreBrakeCount +
                  count.transmissionCount +
                  count.interiorCount +
                  count.steeringCount +
                  count.suspensionCount +
                  count.otherCount}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <br />
      </div>
    </div>
  );
};

export default AnalyticsTable;
