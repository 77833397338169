import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import simpleFetch from "../../../helpers/fetch-wrapper";
import LineChart from "../../custom-charts/LineChart";

const ModelwiseChargeCurve = ({ vehicle }) => {
  const [state, setState] = useState({
    _loading: true,
    _make: "Tesla",
    _model: "Model S",
    _xaxis: [],
    _yaxes: [],
    _level: "L1",
  });

  const { _level, _loading, _xaxis, _yaxes, _make, _model } = state;

  useEffect(() => {
    _getData(vehicle.model, "L1");
  }, []);

  const _getData = async (model, level) => {
    try {
      setState({ ...state, _loading: true });
      let x = [],
        y = [],
        missingY = [];

      const data = await simpleFetch(
        `/charge-curve/model`,
        "POST",
        JSON.stringify({
          make: _make,
          model: model,
          chargerLevel: level,
        })
      );

      data.result.forEach((item) => {
        x.push(item.battery_level);
        if (true) {
          if (item.charger_power > 250) item.charger_power = 250;

          y.push(item.charger_power);
        } else {
          missingY.push(item.charger_power);
        }
      });

      setState({
        ...state,
        _xaxis: x,
        _yaxes: [
          { data: y, type: "line", name: `${level} Charge Curve` },
          { data: missingY, type: "line", name: `` },
        ],
        _loading: false,
        _model: model,
        _level: level,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="text-dark card card-body" style={{ height: "100%" }}>
      <h3>Community Charge Curve</h3>

      <div className="row my-2">
        <div className="col-lg-6">
          <Dropdown
            placeholder="Charger Level"
            value={_level}
            onChange={(e, { value }) => _getData(_model, value)}
            options={[
              { key: 1, text: "L1", value: "L1" },
              { key: 2, text: "L2", value: "L2" },
              { key: 3, text: "L3V1", value: "L3V1" },
              { key: 4, text: "L3V2", value: "L3V2" },
              { key: 5, text: "L3V3", value: "L3V3" },
            ]}
            search
            selection
          />
        </div>

        <div className="col-lg-6">
          <Dropdown
            placeholder="Model"
            value={_model}
            onChange={(e, { value }) => _getData(value, _level)}
            options={[
              { key: 1, text: "Model 3", value: "Model 3" },
              { key: 2, text: "Model S", value: "Model S" },
              { key: 3, text: "Model X", value: "Model X" },
              { key: 4, text: "Model Y", value: "Model Y" },
            ]}
            search
            selection
          />
        </div>
      </div>
      {_loading ? (
        <div className="spinner spinner-border center-spinner"> </div>
      ) : (
        <LineChart
          xAxisTitle="Battery Level (%)"
          yAxisTitle="Charge Power (kW)"
          xaxis={_xaxis}
          yaxes={_yaxes}
        />
      )}
    </div>
  );
};

export default ModelwiseChargeCurve;
