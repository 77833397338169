import React, { useState, useEffect } from "react";
import NumberFormat from "../../helpers/number-format-helper";
import { API } from "../../config";
import ConfidenceChart from "../custom-charts/ConfidenceChart";
import moment from "moment";
const ValuationChart = ({ vehicle, units, isFreeReport }) => {
  const [state, setState] = useState({
    loading: true,
    predictionData: 84000,
    dashedPredictionData: [],
    dashedMl2Data: [],
    domainY: [],
    dataMl2: [],
    domainX: [],
    years: [],
    ml2Data: [],
    areaData: [],
    dates: []
  });
  const {
    loading,
    predictionData,
    domainY,
    years,
    dates,
    domainX,
    areaData,
    ml2Data,
    dashedPredictionData,
    dashedMl2Data,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getKeemutValuation();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getKeemutValuation = async () => {
    try {
      let res;
      if (isFreeReport) {
        const pres = await fetch(
          `${API}/vehicle-list/price?make=${vehicle.make}&model=${vehicle.model}&year=${vehicle.year}&trim=${vehicle.trim}&country=${vehicle.country_code}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          }
        );
        const pdata = await pres.json();

        res = await fetch(`${API}/ml-depreciation`, {
          method: "POST",
          headers: {
            Accept: `application/json`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            make: vehicle.make,
            model: vehicle.model,
            year: vehicle.year,
            powertrain: vehicle.trim,
            odometer: 15000 * (moment().format("YYYY") - vehicle.year + 1),
            msrp: pdata.price,
            optionPrice: pdata.price,
            drivetrain: vehicle.drivetrain,
            transmission: "Automatic",
            interiorColor: "White",
            exteriorColor: "White",
          }),
        });
      } else {
        res = await fetch(
          `${API}/keemut-report/valuation/${vehicle.id}?vin=${vehicle.vin}&currencyCode=${units.currencyunit}`,
          {
            method: "GET",
            headers: {
              Accept: `application/json`,
              "Content-Type": "application/json",
            },
          }
        );
      }
      const data = await res.json();

      let ml2Data = [];
      let dashedPredictionData = [];
      let areaData = [];
      let dashedMl2Data = [];

      if (data.done) {
       
        // copy by value, by default js does it by reference which will screw things up as it is a prop
        let myUnits = JSON.parse(JSON.stringify(units));

        if (data.result.currency_code != "USD") myUnits.currencyRateWrtUsd = 1;

        let predictionData = [];
        let years = [];
        let min = data.result.price_data[0].price;
        let max = data.result.price_data[0].price;

        if (vehicle.vin == "1HM888055H7DDA033") {
          data.ml2Data.value = 5500;
        } else if (vehicle.vin == "1HM888055H7DDA034") {
          data.ml2Data.value = -5500;
        }

        data.result.price_data.forEach((p, i) => {
          let m =
            p.price * myUnits.currencyRateWrtUsd +
            p.std_dev_lower * myUnits.currencyRateWrtUsd;

          let n =
            p.price * myUnits.currencyRateWrtUsd +
            p.std_dev_upper * myUnits.currencyRateWrtUsd;

          if (min > m) {
            min = m;
          }

          if (max < n) {
            max = n;
          }

          if(!p.is_predicted)
          ml2Data.push({
            x: parseInt(p.month),
            y: parseInt(
              (p.price + p.ml2value) *
                myUnits.currencyRateWrtUsd
            ),
            lower: parseInt(
              (p.price +
                p.std_dev_lower *
                  myUnits.currencyRateWrtUsd +
                p.ml2value) *
                myUnits.currencyRateWrtUsd
            ),
            upper: parseInt(
              (p.price +
                p.std_dev_upper *
                  myUnits.currencyRateWrtUsd +
                p.ml2value) *
                myUnits.currencyRateWrtUsd
            ),
          });

          areaData.push({
            x: parseInt(p.month),
            y: parseInt(p.price * myUnits.currencyRateWrtUsd),
            lower: parseInt(
              (p.price + parseInt(p.std_dev_lower)) * myUnits.currencyRateWrtUsd
            ),
            upper: parseInt(
              (p.price + parseInt(p.std_dev_upper)) * myUnits.currencyRateWrtUsd
            ),

            ml2:
              parseInt(
                (p.price + p.ml2value) *
                  myUnits.currencyRateWrtUsd
              ),
          });

          if (
            !p.is_predicted ||
            (data.result.price_data[i + 1] &&
              !data.result.price_data[i + 1].is_predicted)
          ) {
            predictionData.push({
              x: parseInt(p.month),
              y: parseInt(p.price * myUnits.currencyRateWrtUsd),
              lower: parseInt(
                (p.price + parseInt(p.std_dev_lower)) *
                  myUnits.currencyRateWrtUsd
              ),
              upper: parseInt(
                (p.price + parseInt(p.std_dev_upper)) *
                  myUnits.currencyRateWrtUsd
              ),

              ml2:
                parseInt(
                  (p.price + p.ml2value) *
                    myUnits.currencyRateWrtUsd
                ),
            });
          }

          if (
            p.is_predicted ||
            (data.result.price_data[i + 1] &&
              data.result.price_data[i + 1].is_predicted)
          ) {
            dashedPredictionData.push({
              x: parseInt(p.month),
              y: parseInt(p.price * myUnits.currencyRateWrtUsd),
              lower: parseInt(
                (p.price + parseInt(p.std_dev_lower)) *
                  myUnits.currencyRateWrtUsd
              ),
              upper: parseInt(
                (p.price + parseInt(p.std_dev_upper)) *
                  myUnits.currencyRateWrtUsd
              ),

              ml2:
              parseInt(
                (p.price + p.ml2value) *
                  myUnits.currencyRateWrtUsd
              ),
            });
          }

          

          years.push(p.year);
          dates.push(p.date)
        });

        // console.log(predictionData, areaData, dashedMl2Data, dashedPredictionData, ml2Data)

        // dashedData={dashedPredictionData}
        // data={predictionData}
        // domainX={domainX}
        // domainY={domainY}
        // years={years}
        // ml2Data={ml2Data}
        // vin={vehicle.vin}
        // currencyUnit={units.currencyunit}
        // areaData={areaData}
        // dashedMl2Data={dashedMl2Data}
        // dates={dates}



        



        setState({
          ...state,
          loading: false,
          dates,
          predictionData: predictionData,
          domainY: predictionData[0] && [
            parseInt(min - min * 0.05),
            parseInt(max + max * 0.05),
          ],
          ml2Data,
          years,
          areaData,
          dashedPredictionData,
          dashedMl2Data,
          domainX: [
            parseInt(data.result.price_data[0].month),
            parseInt(
              data.result.price_data[data.result.price_data.length - 1].month
            ),
          ],
        });
      } else {
        setState({ ...state, loading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h3>Valuation</h3>
          <div className="row">
            <div className="col-lg-12  text-center">
              <div className="spinner-border text-primary" role="status" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card" id="keemut-report_valuation_chart">
      <div className="card-body">
        <h3>Valuation</h3>
        <div className="row">
          <div className="col-lg-12 mt-5 p-0  text-center">
            <ConfidenceChart
              id="tesla-xyz"
              dashedData={dashedPredictionData}
              data={predictionData}
              domainX={domainX}
              domainY={domainY}
              years={years}
              ml2Data={ml2Data}
              vin={vehicle.vin}
              currencyUnit={units.currencyunit}
              areaData={areaData}
              dashedMl2Data={dashedMl2Data}
              dates={dates}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuationChart;
