import React, { useEffect, useState } from "react";
import LogoResolver from "../../../helpers/LogoResolver";
import BatteryInfo from "../BatteryInfo";
import KeemutReportBanner from "../KeemutReportBanner";
import VehicleInfo from "../VehicleInfo";
import { useParams } from "react-router";
import TrackingHistory from "../TrackingHistory";
import ValuationChart from "../ValuationChart";
import DummyRadials from "../DummyRadials";
import ValuationBanner from "../ValuationBanner";
import OdometerCard from "../OdometerCard";
import TcoCard from "../TcoCard";
import DistancePerMonth from "../DistancePerMonth";
import OdometerHistory from "../OdometerHistory";
import DrivingBreakdown from "../DrivingBreakdown";
import MaintenanceHistory from "../MaintenanceHistory";
import { API } from "../../../config";

const FreeReport = (props) => {
  const [state, setState] = useState({
    vehicle: { vin: useParams().vin },
    loading: true,
    units: {
      distanceunit: "mi",
      fuelunit: "gal",
      currencyunit: "usd",
    },
  });

  const { vehicle, loading, units } = state;

  useEffect(() => {
    fetch(
      `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vehicle.vin}?format=json`,
      { method: "GET" }
    )
      .then((res) => res.json())
      .then(async (data) => {
        let vehicleData = data.Results[0];

        let dtrain = vehicleData.DriveType;
        vehicleData.Make =
          vehicleData.Make.charAt(0).toUpperCase() +
          vehicleData.Make.slice(1).toLowerCase();

        const freeReportTrim = new URLSearchParams(props.location.search).get(
          "trim"
        );
        console.log(dtrain);
        if (dtrain.includes("FWD")) {
          dtrain = "FWD";
        } else if (dtrain.includes("RWD")) {
          dtrain = "RWD";
        } else if (dtrain.includes("AWD")) {
          dtrain = "AWD";
        } else if (dtrain.includes("4x4")) {
          dtrain = "4x4";
        } else if (dtrain.includes("4x2")) {
          dtrain = "4x2";
        } else {
          dtrain = "AWD";
        }

        // Fetch the drivetrain options from master list, if no match for the drivetrain reported by vin api
        // then use the master list options

        const drivetrainRes = await fetch(`${API}/vehicle-list/drivetrain/`, {
          method: "POST",
          headers: {
            Accpet: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            make: vehicleData.Make,
            model: vehicleData.Model,
            trim: freeReportTrim,
            year: vehicleData.ModelYear,
          }),
        });
        const drivetrainData = await drivetrainRes.json();
        let found = false;
        drivetrainData.drivetrains.forEach((d) => {
          if (d.drivetrain == dtrain) {
            found = true;
          }
        });

        if (!found) {
          dtrain = drivetrainData.drivetrains[0];
        }

        setState({
          ...state,
          vehicle: {
            ...vehicle,
            make: vehicleData.Make,
            model: vehicleData.Model,
            trim: freeReportTrim,
            year: vehicleData.ModelYear,
            drivetrain: dtrain,

            id: 123232,
          },
          loading: false,
        });
      });
  }, []);

  if (loading) {
    return <p>Loading....</p>;
  }

  return (
    <>
      <div
        id="keemut-report"
        className="keemut-report-padding"
        style={{ backgroundColor: "#ffffff" }}
      >
        <KeemutReportBanner />

        <div className="card">
          <div className="card-body">
            <h3>Vehicle Information</h3>
          </div>
        </div>

        <div
          class="alert alert-primary text-center"
          role="alert"
          style={{ width: "100%" }}
        >
          The vehicle is not connected to Keemut, By connecting to Keemut a more
          comprehensive report can be produced.
          <a href="/register" className="text-danger">
            {" "}
            Click Here To Connect
          </a>
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setState({ ...state, show: false })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="row my-4">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3 my-5 text-center">
                    <img
                      src={LogoResolver(vehicle.make)}
                      width="100"
                      height="75"
                    ></img>
                  </div>

                  <div className="col-lg-3">
                    <VehicleInfo vehicle={vehicle} />
                  </div>

                  <div className="col-lg-3">
                    <BatteryInfo
                      vehicle={vehicle}
                      units={units}
                      isFreeReport={true}
                    />
                  </div>
                  <div className="col-lg-3">
                    <TrackingHistory vehicle={vehicle} units={units} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <ValuationBanner
              isFreeReport={true}
              vehicle={vehicle}
              units={units}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-7">
            <ValuationChart
              units={units}
              vehicle={vehicle}
              isFreeReport={true}
            />
          </div>
          <div className="col-lg-5">
            <DummyRadials vehicle={vehicle} isFreeReport={true} />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <OdometerCard isFreeReport={true} vehicle={vehicle} units={units} />
          </div>

          <div className="col-lg-4">
            <TcoCard vehicle={vehicle} units={units} isFreeReport={true} />
          </div>

          <div className="col-lg-4">
            <DistancePerMonth
              vehicle={vehicle}
              units={units}
              isFreeReport={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-7">
            <OdometerHistory
              isFreeReport={true}
              vehicle={vehicle}
              units={units}
            />
          </div>

          <div className="col-lg-5">
            <DrivingBreakdown vehicle={vehicle} isFreeReport={true} />
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h3>Maintenance Information</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <MaintenanceHistory
              isFreeReport={true}
              vehicle={vehicle}
              units={units}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeReport;
