import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
import * as Sentry from "@sentry/react";

import "./vendor/dist/saas/assets/css/vendor/jquery-jvectormap-1.2.2.css";
import "./vendor/dist/saas/assets/css/icons.min.css";
import "./vendor/dist/saas/assets/css/app.min.css";
import "./index.css";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

var mixpanel = require("mixpanel-browser");

mixpanel.init("3829aedd944b75a4e256a7ee7263cbbc");

// Sentry.init({
//   dsn: "https://b3fafba8f3c442458ddb5565f644b56e@o232828.ingest.sentry.io/5337911",
// });

mixpanel.track("website_visit");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1c45ef",
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Routes />
  </ThemeProvider>,
  document.getElementById("root")
);
