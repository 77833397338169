import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "semantic-ui-react";
import mapboxgl from "mapbox-gl";
import TataService from "../../../services/connected-cars/tata-service";
import moment from "moment";
import DonutChart from "../../custom-charts/DonutChart";
import { filterOptions } from "../../../helpers/filter-helper";
mapboxgl.accessToken =
  "pk.eyJ1Ijoia2VlbXV0IiwiYSI6ImNqb29kMjRhMjAwZm0zcG10d2Jmb2x2bTUifQ.YUXQ359H96QrmRteACT0ZA";
// import {
//   MapContainer,
//   TileLayer,
//   Marker,
//   Circle,
//   useMapEvents,
//   Polyline,
//   CircleMarker,
// } from "react-leaflet";

export default function TataTrips({ vehicle }) {
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);
  const [_trip, setTrip] = useState(null);
  const [_loading, setLoading] = useState(true);
  const [_tripOptions, setTripOptions] = useState([]);
  const [_filter, setFilter] = useState(filterOptions.last7DaysFilter);

  useEffect(() => {
    _getTrips(filterOptions.last7DaysFilter);

    // displayMap(geojson, "battery", linegeojson);
  }, []);

  const displayMap = async (trip) => {
    try {
      setTrip(trip);

      mapboxgl.accessToken =
        "pk.eyJ1Ijoia2VlbXV0IiwiYSI6ImNqb29kMjRhMjAwZm0zcG10d2Jmb2x2bTUifQ.YUXQ359H96QrmRteACT0ZA";

      let geojson = {
          type: "FeatureCollection",
          features: [],
        },
        linegeojson = {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: [],
          },
        };

      trip.latLon.forEach((coord, idx) => {
        geojson.features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [coord.gpsLongitude, coord.gpsLatitude],
          },
          properties: {
            speed: trip.speed[idx],
            battery: trip.batteryLevel[idx],
            description: `<p>  </p>`,
          },
        });

        linegeojson.geometry.coordinates.push([
          coord.gpsLongitude,
          coord.gpsLatitude,
        ]);
      });

      initializeMap({
        setMap,
        mapContainer,
        geoJson: geojson,
        lineGeoJson: linegeojson,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const initializeMap = ({ setMap, mapContainer, geoJson, lineGeoJson }) => {
    // create a map
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v9", // stylesheet location
      center: geoJson.features[0].geometry.coordinates,
      zoom: 13,
    });

    // on load add the sources and layers
    map.on("load", function () {
      map.addSource("trees", {
        type: "geojson",
        data: geoJson,
      });

      map.addSource("route", {
        type: "geojson",
        data: lineGeoJson,
        lineMetrics: true,
      });

      map.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#888",
          "line-width": 5,
          "line-gradient": [
            "interpolate",
            ["linear"],
            ["line-progress"],
            0,
            "#00D493",

            0.5,
            "#2829CB",

            1,
            "#FF4B7A",
          ],
        },
      });

      map.addLayer(
        {
          id: "trees-point",
          type: "circle",
          source: "trees",
          minzoom: 0,
          paint: {
            // increase the radius of the circle as the zoom level and dbh value increases
            "circle-radius": {
              property: "battery",
              type: "exponential",
              stops: [
                [{ zoom: 15, value: 100 }, 5],
                [{ zoom: 15, value: 100 }, 10],
                [{ zoom: 22, value: 100 }, 20],
                [{ zoom: 22, value: 100 }, 60],
              ],
            },
            "circle-color": {
              property: "battery",
              type: "exponential",
              stops: [
                [20, "#cc9600"],
                [30, "#cc9600"],
                [40, "#cc9600"],
                [50, "#cc9600"],
                [65, "#cc9600"],
                [75, "#cc9600"],
                [80, "#cc9600"],
                [90, "#cc9600"],
                [100, "#cc9600"],
              ],
            },
            "circle-stroke-color": "white",
            "circle-stroke-width": 1,
          },
        },
        "waterway-label"
      );

      // move route layer above trees-point
      map.moveLayer("route", "trees-point");

      let popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      // on mouse enter show a tooltip
      map.on("mouseenter", "trees-point", (e) => {
        map.getCanvas().style.cursor = "pointer";
        popup
          .setLngLat(map.unproject(e.point))
          .setHTML(
            "<p>" +
              "Speed : <b>" +
              `${parseInt(e.features[0].properties.speed)} kmph ` +
              "</b></p>" +
              `<p> Battery :  <b>${e.features[0].properties.battery}% </b> </p>`
          )
          .addTo(map);
      });

      // remove tooltip on mouse leave
      map.on("mouseleave", "trees-point", () => {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });

      // start marker
      var startMarker = new mapboxgl.Marker({ color: "#0acf97" })
        .setLngLat(geoJson.features[0].geometry.coordinates)
        .addTo(map);

      // end marker
      var endMarker = new mapboxgl.Marker({ color: "#fa5c7c" })
        .setLngLat(
          geoJson.features[geoJson.features.length - 1].geometry.coordinates
        )
        .addTo(map);

      setMap(map);
      map.resize();
    });
  };

  const _getTrips = async (filter) => {
    try {
      setFilter(filter);
      setLoading(true);
      const data = await TataService.getTrips(vehicle.vin, "1month");
      console.clear();
      console.log(data);
      let ops = [];
      let ts = [];
      data.result.forEach((item, idx) => {
        let text = `${moment(item.startTimestamp)
          .add(5.5, "hours")
          .format("DD-MMM-YY hh:mm A")} - ${moment(item.endTimestamp)
          .add(5.5, "hours")
          .format("DD-MMM-YY hh:mm A")}`;

        let duration = moment(item.endTimestamp).diff(
          moment(item.startTimestamp),
          "minutes"
        );
        item.duration = duration;
        if (!ts.includes(text)) {
          ops.push({
            key: idx,
            value: item,
            text: text,
          });
          ts.push(text);
        }
      });

      setTripOptions(ops);
      setLoading(false);

      if (ops.length > 0) displayMap(ops[0].value);
    } catch (error) {
      console.log(error);
    }
  };

  if (vehicle.make != "Tata") {
    return (
      <p>
        Trips is only supported for Tata Vehicles. Please use the dropdown at
        the top to switch to another vehicle.
      </p>
    );
  }

  return (
    <div className="text-dark">
      <div className="card card-body">
        <h2>{vehicle.name}'s Trips</h2>
        <p className="text-muted">
          All the times are displayed in <strong>Asia/Kolkata</strong> Timezone.
        </p>

        <div className="form-group">
          <label>Show Trips From</label>
          <Dropdown
            onSearchChange={false}
            className="ml-2"
            search
            selection
            value={"7days"}
            onChange={(e, { value }) => {
              _getTrips(value);
            }}
            options={[
              {
                key: "7days",
                value: filterOptions.last7DaysFilter,
                text: "Last 7 days",
              },
              {
                key: "1month",
                value: filterOptions.last1MonthFilter,
                text: "Last 1 month",
              },
              { key: "all", value: filterOptions.allTimeFilter, text: "All" },
            ]}
          />
        </div>
        {_loading && <div className="spinner spinner-border"> </div>}

        {!_loading && (
          <>
            <div className="form-group">
              <label>Select Trip</label>
              <Dropdown
                className="ml-2"
                search
                onSearchChange={false}
                selection
                value={_trip}
                onChange={(e, { value }) => {
                  displayMap(value);
                }}
                options={_tripOptions}
              />
            </div>
          </>
        )}
      </div>

      <div className="card card-body">
        <div className="row">
          <div className="col-lg-8">
            <div
              ref={(el) => (mapContainer.current = el)}
              style={{
                width: "100%",
                height: "500px",
              }}
            />
          </div>

          {_trip && (
            <div className="col-lg-4  text-center">
              <div className="row">
                <div className="col-lg-6">
                  <i className=" mdi mdi-crosshairs-gps h1 text-primary"></i>
                  <h4>Start Location</h4>
                  <p>{_trip && _trip.startLocation}</p>
                </div>
                <div className="col-lg-6">
                  <i className=" mdi mdi-crosshairs h1 text-primary"></i>
                  <h4>End Location</h4>
                  <p>{_trip && _trip.endLocation}</p>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6">
                  <i className=" mdi mdi-clock-outline h1 text-primary"></i>
                  <h4>Duration</h4>
                  <p>{_trip && parseInt(_trip.duration)} minutes</p>
                </div>

                <div className="col-lg-6">
                  <i className="  mdi mdi-battery-charging-50 h1 text-primary"></i>
                  <h4>Battery Used</h4>
                  <p>
                    {_trip &&
                      (
                        ((_trip.startBatteryLevel - _trip.endBatteryLevel) /
                          100) *
                        30.2
                      ).toFixed(2)}{" "}
                    kWh
                  </p>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6">
                  <i className="  mdi mdi-road-variant h1 text-primary"></i>
                  <h4>Distance Driven</h4>
                  <p>{_trip && _trip.endOdometer - _trip.startOdometer} km</p>
                </div>

                <div className="col-lg-6">
                  <i className=" mdi mdi-speedometer h1 text-primary"></i>
                  <h4>Max. Speed</h4>
                  <p>{_trip && _trip.maxSpeed.toFixed(0)} km/h</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 text-center">
          <div className="card card-body">
            <DonutChart
              formatter={(val) => `${val}%`}
              label="Acceleration Score"
              percentValue={
                _trip ? (_trip.behaviour.accelerationRating * 10).toFixed(1) : 0
              }
            />
            <h3>Acceleration Score</h3>
          </div>
        </div>

        <div className="col-lg-4 text-center">
          <div className="card card-body">
            <DonutChart
              formatter={(val) => `${val}%`}
              label="Braking Score"
              percentValue={
                _trip ? (_trip.behaviour.brakingRating * 10).toFixed(1) : 0
              }
            />
            <h3>Braking Score</h3>
          </div>
        </div>

        <div className="col-lg-4 text-center">
          <div className="card card-body">
            <DonutChart
              formatter={(val) => `${val}%`}
              label="Overall Score"
              percentValue={
                _trip ? (_trip.behaviour.overallRating * 10).toFixed(1) : 0
              }
            />
            <h3>Overall Score</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
