import React, { useEffect, useState } from "react";
import TeslaHelper from "../../../../helpers/tesla-helper";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";
import { Popup } from "mapbox-gl";
import simpleFetch from "../../../../helpers/fetch-wrapper";

const TeslaLiveData = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: true,
    vehicleState: "",
    batteryLevel: null,
    batteryRange: null,
    doors: "Unlocked",
    sentryMode: "Off",
    timestamp: "",
    address: "",
    loadingStatus: null,
    commandLoading: false,
  });

  const {
    address,
    timestamp,
    batteryLevel,
    batteryRange,
    doors,
    loading,
    sentryMode,
    vehicleState,
    loadingStatus,
    commandLoading,
  } = state;

  useEffect(() => {
    async function getTeslaVehicleStatus() {
      try {
        setState({
          ...state,
          loading: true,
          loadingStatus: "Communicating With Tesla Servers...",
        });

        const data = await TeslaHelper.getTeslaStatus(vehicle.vin);

        setState({ ...state, loadingStatus: "Doing some calculations..." });

        const startRes = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${data.teslaStatus.lat}&lon=${data.teslaStatus.long}`,
          {
            method: "GET",
          }
        );

        const i = await startRes.json();
        setState({
          ...state,
          vehicleState: data.teslaStatus.state,
          batteryLevel: data.teslaStatus.batteryLevel,
          batteryRange: data.teslaStatus.batteryRange,
          doors: data.teslaStatus.doors ? "Unlocked" : "Locked",
          sentryMode: data.teslaStatus.sentryMode ? "On" : "Off",
          timestamp: moment(data.teslaStatus.timestamp).format(
            "DD-MMM-YYYY hh:mm a "
          ),
          address: i.display_name,
          loading: false,
          loadingStatus: null,
        });

        // console.log();
      } catch (err) {
        console.log(err);
        setState({ ...state, loading: true });
        alert("We were unable to get the live stats for your Tesla");
      }
    }

    getTeslaVehicleStatus();
  }, []);

  const wakeUp = async () => {
    try {
      setState({ ...state, commandLoading: true });
      await simpleFetch(`/tesla/wake-up/${vehicle.vin}`, "GET");
      setState({ ...state, commandLoading: false });

      alert("Wake up command was sent.");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="card card-body" style={{ height: "100%" }}>
      <div>
        <div style={{ float: "left" }}>
          <h3 style={{ display: "inline-block" }}>
            Live Stats | As of {timestamp}
          </h3>
        </div>
        <div style={{ float: "right" }}>
          {vehicleState == "Sleeping" && (
            <button onClick={wakeUp} className="btn btn-sm btn-primary">
              {commandLoading ? "Loading..." : "Wake Up"}
            </button>
          )}
        </div>
      </div>

      {loading && (
        <div>
          <span>{loadingStatus}</span>
          <span className="ml-3 spinner spinner-border center-spinner"></span>
        </div>
      )}

      {!loading && (
        <table className="mt-3 table-striped">
          <tbody>
            <tr>
              <td>
                <i className=" mdi mdi-car-info text-warning mr-1"></i>
                <strong>Vehicle State: </strong>
              </td>
              <td>
                {loading ? (
                  <div className="spinner spinner-border center-spinner"></div>
                ) : (
                  <span
                    className={
                      vehicleState == "Sleeping"
                        ? "text-danger"
                        : "text-success"
                    }
                  >
                    {" "}
                    {vehicleState}{" "}
                  </span>
                )}
              </td>
            </tr>

            <tr>
              <td>
                <i className=" mdi mdi-battery-medium text-primary mr-1"></i>{" "}
                <strong>Battery Level:</strong>
              </td>
              <td>
                <span>
                  {" "}
                  {batteryLevel && parseInt(batteryLevel)}% (
                  {batteryRange && parseInt(batteryRange)} mi){" "}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <i className=" mdi mdi-shield-lock-outline text-secondary mr-1"></i>
                <strong>Doors:</strong>{" "}
              </td>
              <td>
                <span
                  className={
                    doors == "Unlocked" ? "text-danger" : "text-success"
                  }
                >
                  {doors}
                </span>{" "}
              </td>
            </tr>

            <tr>
              <td>
                <i className=" mdi mdi-shield-lock-outline text-secondary mr-1"></i>
                <strong>Sentry Mode:</strong>{" "}
              </td>
              <td>
                <span className="text-danger">{sentryMode}</span>{" "}
              </td>
            </tr>

            <tr>
              <td>
                <i className=" mdi mdi-crosshairs-gps text-success mr-1"></i>{" "}
                <strong>Current Location:</strong>
              </td>
              <td style={{ textOverflow: "ellipsis" }}>
                <a
                  target="_blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${address}`}
                  style={{ color: "blue" }}
                  title={address}
                >
                  {" "}
                  <LinesEllipsis
                    text={address}
                    maxLine="1"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TeslaLiveData;
