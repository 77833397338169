import simpleFetch from "../helpers/fetch-wrapper";

const GeofenceService = {
  /**
   * @description Makes api call to create a geofence
   * @param {String} vin
   * @param {String} vehicleId
   * @param {String} polygonCoords
   * @param {String} geofenceType
   * @param {Number} geofenceRadius
   * @param {String} name
   * @param {String} geofenceRadiusCenter
   * @param {String} triggerOn
   * @param {String} alertMode
   */
  createGeofence: async (
    vin,
    vehicleId,
    polygonCoords,
    geofenceType,
    geofenceRadius,
    name,
    geofenceRadiusCenter,
    triggerOn,
    alertMode
  ) => {
    try {
      return await simpleFetch(
        "/geofence",
        "POST",
        JSON.stringify({
          vin,
          vehicleId,
          polygonCoords,
          geofenceType,
          geofenceRadius,
          name,
          geofenceRadiusCenter,
          triggerOn,
          alertMode,
        })
      );
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * @description Get geofences by vin
   * @param {String} vin
   * @returns
   */
  getGeofencesByVin: async (vin) => {
    try {
      return await simpleFetch(`/geofence/${vin}`, "GET");
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * @description Makes api call to delete geofence by ID
   * @param {String} id
   * @returns
   */
  deleteGeofenceById: async (id) => {
    try {
      return await simpleFetch(`/geofence/${id}`, "DELETE");
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * @description Get geofence from api by id
   * @param {String} id
   * @returns
   */
  getGeofenceById: async (id) => {
    try {
      return await simpleFetch(`/geofence-by-id/${id}`, "GET");
    } catch (err) {
      console.log(err);
    }
  },

  /**
   *
   * @param {String} id
   * @param {String} polygonCoords
   * @param {String} geofenceType
   * @param {Number} geofenceRadius
   * @param {String} name
   * @param {String} geofenceRadiusCenter
   * @param {String} triggerOn
   * @param {String} alertMode
   * @returns
   */
  updateGeofenceById: async (
    id,
    polygonCoords,
    geofenceType,
    geofenceRadius,
    name,
    geofenceRadiusCenter,
    triggerOn,
    alertMode
  ) => {
    try {
      return await simpleFetch(
        `/geofence`,
        "PUT",
        JSON.stringify({
          id,
          polygonCoords,
          geofenceType,
          geofenceRadius,
          name,
          geofenceRadiusCenter,
          triggerOn,
          alertMode,
        })
      );
    } catch (err) {
      console.log(err);
    }
  },
};

export default GeofenceService;
