import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
const VinDialog = ({ showVinDialog, closeVinDialog }) => {
  return (
    <Dialog
      open={showVinDialog}
      onClose={closeVinDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div class="modal-content">
        <div class="modal-header modal-colored-header bg-primary">
          <h4 class="modal-title" id="primary-header-modalLabel">
            Please Add Your VIN
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            ×
          </button>
        </div>
        <div class="modal-body">
          To prevent fraudulent Reports, we require the user's to provide us the
          Vehicle's VIN, Please follow the link below to fill out the vin.
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-dismiss="modal"
            onClick={closeVinDialog}
          >
            Close
          </button>
          <a href="/dashboard/vehicles">
            <button type="button" class="btn btn-primary">
              Go
            </button>
          </a>
        </div>
      </div>
    </Dialog>
  );
};

export default VinDialog;
