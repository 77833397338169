import React, { useEffect, useState } from "react";
import { Switch, Route, Link } from "react-router-dom";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Vehicles from "./vehicles/Vehicles";
import { Dropdown } from "semantic-ui-react";
import AddVehicle from "./vehicles/Add";
import Overview from "./overview/Overview";
import Settings from "./settings/Settings";
import AddFuel from "./overview/Fuel/AddFuel";
import InsuranceForm from "./insurance/InsuranceForm";
import Hidden from "@material-ui/core/Hidden";
import { site, API, nextSite } from "../../config";
import MaintenanceForm from "./maintenance/MaintenanceForm";
import ExpenseForm from "./expense/ExpenseForm";
import TCO from "./tco/TCO";
import Valuation from "./valuation/Valuation";
import BugReport from "../common/BugReport";
import KeemutValuation from "./keemut-ai/KeemutValuation";
import FuelHistory from "./fuel-history/FuelHistory";
import EnergyHistory from "./energy-history/EnergyHistory";
import AddConnectedTesla from "./vehicles/AddConnectedTesla";
import LogoResolver from "../../helpers/LogoResolver";
import RevenueForm from "./revenue-reports/RevenueForm";
import Trips from "./trip/Trips";
import FleetAnalytics from "./fleet/FleetAnalytics";
import Logbook from "./logbook/Logbook";
import FleetPage from "./fleet/FleetPage";
import MaintenanceFormV2 from "./maintenance/MaintenanceFormV2";
import BatteryPredictor from "./battery-predictor/BatteryPredictor";
import SmartcarOauth from "./smart-car/SmartcarOauth";
import KeemutReportSettings from "./settings/keemut-report-settings/KeemutReportSettings";
import { useRef } from "react";
import HyundaiConnection from "./hyundai/HyundaiConnection";
import Rta from "./rta-rms/Rta";
import ToyotaOnboard from "./toyota/Onboard";
import RivianOauth from "./rivian/oauth";

import HondaOnboard from "./honda/onboard";

import TataOnboard from "./tata/onboard";
import SkodaOnboard from "./skoda/onboard";


import DrivingStats from "./driving-stats/DrivingStats";
import getReferrer from "../../helpers/Referrer";

import getUser from "../../helpers/user";
import { authorizeAccessToVehicleService } from "../../services/vehicles-service";
import {
  getCurrentOdometer,
  getRecentOdometerService,
} from "../../services/odometer-history-service";
import Abrp from "./abrp/Abrp";
import AbrpOauth from "./abrp/AbrpOauth";
import AddGeofence from "./geofence/AddGeofence";
import Geofence from "./geofence/Geofence";
import TripsV2 from "./trip/TripsV2";
import BmwPage from "../../pages/dashboard/bmw-page";
import TataTrips from "./trip/TataTrips";
import ConnectYourCar from "./connect-car/ConnectYourCar";
import TeslaFleet from "./tesla-fleet/tesla-fleet";
const mixpanel = require("mixpanel-browser");

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#ffffff",
    color: "#000000",
    padding: "0",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarIcon: {
    marginRight: "20px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    color: "#ffffff",
    backgroundImage: "linear-gradient(to bottom, #0268c7, #00274c )",
    // backgroundColor: "#313a46",
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerLink: {
    color: "#eef2f7",
    textDecoration: "none",
  },
  title: {
    flexGrow: 1,
    fontWeight: "600",
    color: "#6c757d",

    marginLeft: "auto",
  },
  vehiclePicker: {
    flexGrow: 1,
    fontWeight: "600",
    color: "#6c757d",
    marginLeft: "200px",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerIcon: {
    color: "#808da0",
  },
  drawerItem: {
    fontSize: "8px",
    padding: "7px 25px",
    fontSize: "28px",
    fontWeight: "bold",

    "&:hover": {
      color: "#ffffff",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#EAF0F1",
    marginLeft: 0,
    marginRight: "20px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },

  listItem: {
    marginLeft: "20px",
  },

  active: {
    color: "white",
  },
}));

const Dashboard = ({ user, token }) => {
  const classes = useStyles();
  const theme = useTheme();
  const vehiclePicker = useRef(null);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [state, setState] = useState({
    vehicles: [],
    loading: true,
    odoLoading: true,
    vehicle: "",
    vehicleOptions: [],
    brandLogoImg: require("../../assets/logos/default.svg"),
    odometer: null,
    isSubscribed: false,
  });
  const {
    vehicles,
    loading,
    vehicle,
    vehicleOptions,
    brandLogoImg,
    odometer,
    odoLoading,
    isSubscribed,
  } = state;

  const getDropdownOptions = (vehicles) => {
    const options = [];
    vehicles.map((vehicle) => {
      let img;
      img = LogoResolver(vehicle.make);
      options.push({
        key: `${vehicle.id}`,
        text: `${vehicle.name} (${vehicle.make} ${vehicle.model}) `,
        value: JSON.stringify(vehicle),
        image: {
          avatar: true,
          src: img,
        },
      });
    });
    return { options };
  };

  const updateVehicleDropdownValues = (vehicleOptions, vehicles) => {
    if (vehicles.length === 1) {
      localStorage.setItem("vehicle", JSON.stringify(vehicles[0]));
      setState({ ...state, vehicleOptions, vehicles, vehicle: vehicles[0] });
    } else {
      setState({ ...state, vehicleOptions, vehicles });
    }
  };

  useEffect(() => {
    const initialRequests = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = localStorage.getItem("token");
        const vehiclesJson = localStorage.getItem("vehicles");
        const vehicleJson = localStorage.getItem("vehicle");
        let vehicles = [];
        let vehicle, isSubscribed;
        if (!user || !token) {
          window.location = `${site}/login`;
        }

        if(vehiclesJson) {
           vehicles = JSON.parse(vehiclesJson);

           vehicle =
            vehicleJson !== "undefined" ? JSON.parse(vehicleJson) : vehicles[0];
        } else { 
          const res = await fetch(`${API}/vehicles/all/${user.uid}`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await res.json();

          data.vehicles.length > 0 &&
          localStorage.setItem("vehicle", JSON.stringify(data.vehicles[0]));
          vehicles = data.vehicles;
          vehicle = data.vehicles[0];
        }

        const authorizationData = await authorizeAccessToVehicleService(
          vehicle.id
        );
        const vehicleAuth = authorizationData.vehicleAccessToken;
        if (vehicleAuth) {
          localStorage.setItem("vehicleAuthToken", vehicleAuth.token);
          localStorage.setItem("vehicleAuthTokenId", vehicleAuth.id);
        }
        const { options } = getDropdownOptions(vehicles);

        const subRes = await fetch(
          `${API}/subscription/${user.subscription_id || user.uid}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const subData = await subRes.json();
        state.isSubscribed = subData.isSubscribed;

        setState({
          ...state,
          loading: false,
          vehicleOptions: options,
          vehicles: vehicles,
          vehicle: vehicle,
          brandLogoImg: vehicle
            ? LogoResolver(vehicle.make)
            : brandLogoImg,
        });

        
         
        
      } catch (err) {
        console.log(err);
      }
    };

    let mounted = true;

    let user = getUser();
    if (mounted) {
      mixpanel.track("dashboard_open", {
        uid: user && user.uid,
        referrer: getReferrer(),
      });
      initialRequests();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    const _getOdometer = async () => {
      try {
        const data = await getCurrentOdometer(vehicle.id);

        setState({
          ...state,
          odometer: data.odometer || 0,
          loading: false,
        });
      } catch (err) {
        console.log(err);
      }
    };

    if (mounted) {
      if (vehicle) {
        _getOdometer();
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [vehicle]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isActiveLink = (value) =>
    window.location.pathname === value ? true : false;

  const updateOdometer = (value) => {
    if (value > odometer) setState({ ...state, odometer: value });
  };

  /** MARKUP BELOW */

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <div className={classes.drawerHeader}>
        <div style={{ padding: "20px 40px" }}>
          <img
            src={require("../../assets/keemut-2.png")}
            style={{ width: "155px", height: "45px" }}
          />
          <h5 style={{ fontWeight: "normal" }}>
            {isSubscribed ? "Premium Plan" : "Free Plan"}
          </h5>
          {/* <h1 style={{ color: "#fff" }}>Keemut</h1> */}
        </div>
      </div>

      <List>
        <br />
        <br />
        <Link
          to="/dashboard/overview"
          className={`${classes.drawerLink} `}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem className={classes.drawerItem} button key="Overview">
            <i
              className="mdi mdi-desktop-mac-dashboard"
              style={{
                fontSize: "20px",
                color: `${
                  isActiveLink("/dashboard/overview") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            ></i>
            <ListItemText
              primary="Overview"
              className={`${classes.listItem}`}
              style={{
                color: `${
                  isActiveLink("/dashboard/overview") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            />
          </ListItem>
        </Link>

        <Link
          to="/dashboard/vehicles"
          className={classes.drawerLink}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem className={classes.drawerItem} button key="Vehicles">
            <i
              className="mdi mdi-car-back"
              style={{
                fontSize: "20px",
                color: `${
                  isActiveLink("/dashboard/vehicles") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            ></i>
            <ListItemText
              primary="Vehicles"
              className={classes.listItem}
              style={{
                color: `${
                  isActiveLink("/dashboard/vehicles") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            />
          </ListItem>
        </Link>
        {/*  */}

        <Link
          to="/dashboard/logbook"
          className={classes.drawerLink}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem className={classes.drawerItem} button key="logbook">
            <i
              className="  mdi mdi-notebook-outline"
              style={{
                fontSize: "20px",
                color: `${isActiveLink("/dashboard/logbook") ? "rgba(68, 215, 182, 1)" : ""}`,
              }}
            ></i>
            <ListItemText
              primary="Logbook"
              className={classes.listItem}
              style={{
                color: `${isActiveLink("/dashboard/logbook") ? "rgba(68, 215, 182, 1)" : ""}`,
              }}
            />
          </ListItem>
        </Link>
        <Link
          to="/dashboard/trips"
          className={classes.drawerLink}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem className={classes.drawerItem} button key="Trips">
            <i
              className=" mdi mdi-road-variant"
              style={{
                fontSize: "20px",
                color: `${isActiveLink("/dashboard/trips") ? "rgba(68, 215, 182, 1)" : ""}`,
              }}
            ></i>
            <ListItemText
              primary="Trips"
              className={classes.listItem}
              style={{
                color: `${isActiveLink("/dashboard/trips") ? "rgba(68, 215, 182, 1)" : ""}`,
              }}
            />
          </ListItem>
        </Link>

        <Link
          to="/dashboard/keemut-report"
          className={classes.drawerLink}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem className={classes.drawerItem} button key="Keemut Report">
            <i
              className="mdi mdi-receipt"
              style={{
                fontSize: "20px",
                color: `${
                  isActiveLink("/dashboard/keemut-report") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            ></i>
            <ListItemText
              primary="Keemut Report"
              className={classes.listItem}
              style={{
                color: `${
                  isActiveLink("/dashboard/keemut-report") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            />
          </ListItem>
        </Link>
        <Link
          to="/dashboard/geofence"
          className={classes.drawerLink}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem className={classes.drawerItem} button key="Geofence">
            <i
              className="  mdi mdi-radar              "
              style={{
                fontSize: "20px",
                color: `${
                  isActiveLink("/dashboard/geofence") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            ></i>
            <ListItemText
              primary="Geofence"
              className={classes.listItem}
              style={{
                color: `${
                  isActiveLink("/dashboard/geofence") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            />
          </ListItem>
        </Link>

        
        {/* <Link
          to="/dashboard/fleet-analytics"
          className={classes.drawerLink}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem className={classes.drawerItem} button key="FleetAnalytics">
            <i
              className=" mdi mdi-taxi"
              style={{
                fontSize: "20px",
                color: `${
                  isActiveLink("/dashboard/fleet-analytics") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            ></i>
            <ListItemText
              primary="Turo Analytics"
              className={classes.listItem}
              style={{
                color: `${
                  isActiveLink("/dashboard/fleet-analytics") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            />
          </ListItem>
        </Link> */}

        
        <Link
          to="/dashboard/connect-car"
          className={classes.drawerLink}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem className={classes.drawerItem} button key="Connect Car">
            <i
              className="  mdi mdi-wifi     "
              style={{
                fontSize: "20px",
                color: `${
                  isActiveLink("/dashboard/connect-car") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            ></i>
            <ListItemText
              primary="Connect Your Car"
              className={classes.listItem}
              style={{
                color: `${
                  isActiveLink("/dashboard/connect-car") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            />
          </ListItem>
        </Link>

        <Link
          to="/dashboard/settings"
          className={classes.drawerLink}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem className={classes.drawerItem} button key="Settings">
            <i
              className="mdi mdi-settings"
              style={{
                fontSize: "20px",
                color: `${
                  isActiveLink("/dashboard/settings") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            ></i>
            <ListItemText
              primary="Settings"
              className={classes.listItem}
              style={{
                color: `${
                  isActiveLink("/dashboard/settings") ? "rgba(68, 215, 182, 1)" : ""
                }`,
              }}
            />
          </ListItem>
        </Link>

        {/* <Link
          to="/dashboard/bmw"
          className={classes.drawerLink}
          onClick={() => setMobileOpen(false)}
        >
          <ListItem className={classes.drawerItem} button key="BMW">
            <i
              className="mdi mdi-settings"
              style={{
                fontSize: "20px",
                color: `${isActiveLink("/dashboard/bmw") ? "rgba(68, 215, 182, 1)" : ""}`,
              }}
            ></i>
            <ListItemText
              primary="BMW"
              className={classes.listItem}
              style={{
                color: `${isActiveLink("/dashboard/bmw") ? "rgba(68, 215, 182, 1)" : ""}`,
              }}
            />
          </ListItem>
        </Link> */}
        <Link
          className={classes.drawerLink}
          onClick={() => {
            localStorage.clear();

            window.location = `${site}/login`;
          }}
        >
          <ListItem className={classes.drawerItem} button key="Logout">
            <i className="mdi mdi-logout" style={{ fontSize: "20px" }}></i>
            <ListItemText primary="Logout" className={classes.listItem} />
          </ListItem>
        </Link>
      </List>
    </div>
  );

  if (loading) {
    return (
      <div
        className="spinner-border spinner-center text-primary"
        role="status"
      ></div>
    );
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <meta
          name="description"
          content="Keemut Dashboard, Get Detailed Analytics for your car and key insights that help you increase the value of your car."
        />
        <title>Keemut | Dashboard</title>
      </Helmet>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: mobileOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <MenuIcon />
          </IconButton>
          {vehicles.length > 0 && (
            <span className={classes.vehiclePicker}>
              {" "}
              <img
                src={brandLogoImg}
                style={{ width: "30px", height: "30px" }}
              />
              <Dropdown
                search
                ref={vehiclePicker}
                placeholder="Select Your Car"
                selection
                options={vehicleOptions}
                className="picker vehicle-picker-step "
                defaultValue={JSON.stringify(vehicle)}
                value={JSON.stringify(vehicle)}
                onChange={async (e, { value }) => {
                  try {
                    const vehicle = JSON.parse(value);

                    try {
                      const authorizationData =
                        await authorizeAccessToVehicleService(vehicle.id);
                      const vehicleAuth = authorizationData.vehicleAccessToken;
                      if (vehicleAuth) {
                        localStorage.setItem(
                          "vehicleAuthToken",
                          vehicleAuth.token
                        );
                        localStorage.setItem(
                          "vehicleAuthTokenId",
                          vehicleAuth.id
                        );
                        localStorage.setItem(
                          "vehicle",
                          JSON.stringify(vehicle)
                        );
                      }
                    } catch (err) {}
                    let img;
                    img = LogoResolver(vehicle.make);
                    setState({
                      ...state,
                      brandLogoImg: img,
                      vehicle: vehicle,
                    });
                  } catch (err) {}
                }}
              />{" "}
            </span>
          )}
          <Typography
            variant="h7"
            noWrap
            className={classes.title}
            style={{
              fontFamily: "Roboto",
              fontSize: "1.05rem",
              fontWeight: "600",
              textAlign: "end",
            }}
          >
            {` Odometer : ${
              odometer
                ? odometer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
            }`}
          </Typography>{" "}
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.drawerHeader} />
        <div
          className="flex-wrapper"
          style={{
            fontFamily: "Roboto",
            fontSize: "1.05rem",
            fontWeight: "400",
            color: "#6c757d",
            backgroundColor: "#f9fafd",
          }}
        >
          <Switch>
            <Route
              exact
              path="/dashboard/overview"
              component={(props) => (
                <Overview
                  vehicle={vehicle}
                  updateDropdownOptions={getDropdownOptions}
                  updateVehicleDropdownValues={updateVehicleDropdownValues}
                  vehiclePickerRef={vehiclePicker}
                  updateOdometer={updateOdometer}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path="/dashboard/geofence/add"
              component={(props) => <AddGeofence vehicle={vehicle} />}
            />

            <Route
              exact
              path="/dashboard/geofence"
              component={(props) => <Geofence vehicle={vehicle} />}
            />
            <Route
              exact
              path="/dashboard/toyota/onboard"
              component={(props) => <ToyotaOnboard />}
            />
            <Route
              exact
              path="/dashboard/rivian/onboard"
              component={(props) => <RivianOauth />}
            />
             <Route
              exact
              path="/dashboard/honda/onboard"
              component={(props) => <HondaOnboard />}
            />

            <Route
              exact
              path="/dashboard/tata/onboard"
              component={(props) => <TataOnboard />}
            />

<Route
              exact
              path="/dashboard/skoda/onboard"
              component={(props) => <SkodaOnboard />}
            />

<Route
              exact
              path="/dashboard/connect-car"
              component={(props) => <ConnectYourCar />}
            />

            <Route
              exact
              path="/dashboard/bmw"
              component={(props) => <BmwPage />}
            />

            <Route
              exact
              path="/dashboard/abrp"
              component={(props) => <Abrp />}
            />

            <Route
              exact
              path="/dashboard/tesla-fleet"
              component={(props) => <TeslaFleet />}
            />

            <Route
              exact
              path="/dashboard/abrp/oauth"
              component={(props) => <AbrpOauth />}
            />
            <Route
              exact
              path="/dashboard/rta-rms"
              component={(props) => <Rta />}
            />

            <Route
              exact
              path="/dashboard/keemut-valuation"
              component={(props) => (
                <KeemutValuation vehicle={vehicle} {...props} />
              )}
            />

            <Route
              exact
              path="/dashboard/hyundai"
              component={(props) => <HyundaiConnection />}
            />

            <Route
              exact
              path="/dashboard/energy-history"
              component={(props) => (
                <EnergyHistory vehicle={vehicle} {...props} />
              )}
            />
            <Route
              exact
              path="/dashboard/vehicles"
              component={Vehicles}
              updateDropdownOptions={getDropdownOptions}
              updateVehicleDropdownValues={updateVehicleDropdownValues}
            />
            <Route
              exact
              path="/dashboard/battery-predictor"
              component={BatteryPredictor}
            />

            <Route
              exact
              path="/dashboard/trips"
              component={(props) =>
                vehicle && vehicle.make == "Tata" ? (
                  <TataTrips vehicle={vehicle} {...props} />
                ) : (
                  <Trips vehicle={vehicle} {...props} />
                )
              }
            />

            <Route
              exact
              path="/dashboard/tesla/onboard"
              component={AddConnectedTesla}
            />
            <Route
              exact
              path="/dashboard/vehicles/add"
              component={(props) => (
                <AddVehicle
                  updateDropdownOptions={getDropdownOptions}
                  updateVehicleDropdownValues={updateVehicleDropdownValues}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/settings"
              component={(props) => <Settings vehicle={vehicle} {...props} />}
            />
            <Route
              exact
              path="/dashboard/fuel"
              component={(props) => (
                <AddFuel
                  vehicle={vehicle}
                  updateOdometer={updateOdometer}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/insurance"
              component={(props) => (
                <InsuranceForm vehicle={vehicle} {...props} />
              )}
            />

            <Route
              exact
              path="/dashboard/revenue"
              component={(props) => <RevenueForm vehicle={vehicle} />}
            />
            <Route
              exact
              path="/dashboard/maintenance"
              component={(props) => (
                <MaintenanceForm
                  vehicle={vehicle}
                  updateOdometer={updateOdometer}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/expense"
              component={(props) => (
                <ExpenseForm vehicle={vehicle} {...props} />
              )}
            />
            <Route
              exact
              path="/dashboard/maintenance-history"
              component={(props) => <TCO vehicle={vehicle} {...props} />}
            />
            <Route
              exact
              path="/dashboard/logbook"
              component={(props) => <Logbook vehicle={vehicle} {...props} />}
            />
            <Route
              exact
              path="/dashboard/valuation"
              component={(props) => <Valuation {...props} />}
            />
            <Route
              exact
              path="/dashboard/bug-report"
              component={(props) => <BugReport {...props} />}
            />
            <Route
              exact
              path="/dashboard/fuel-history"
              component={(props) => (
                <FuelHistory vehicle={vehicle} {...props} />
              )}
            />

            <Route
              exact
              path="/dashboard/fleet-analytics"
              component={(props) => <FleetPage vehicle={vehicle} {...props} />}
            />

            <Route
              exact
              path="/dashboard/maintenance-v2"
              component={(props) => (
                <MaintenanceFormV2 vehicle={vehicle} {...props} />
              )}
            />

            <Route
              path="/dashboard/smartcar/oauth"
              component={(props) => <SmartcarOauth />}
            />

            <Route
              path="/dashboard/keemut-report"
              component={(props) => (
                <KeemutReportSettings vehicle={vehicle} {...props} />
              )}
            />
            <Route
              path="/dashboard/driving-stats"
              component={(props) => (
                <DrivingStats vehicle={vehicle} {...props} />
              )}
            />
          </Switch>
          {/* <Footer /> */}
          <br />
          <footer
            style={{
              borderTop: "1px solid rgba(152, 166, 173, 0.2)",
              padding: "19px 24px 20px",
              color: "#98a6ad",
            }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  {moment().format("YYYY")} © Keemut{" "}
                </div>
                <div className="col-md-6">
                  <div className="text-md-right footer-links d-none d-md-block">
                    {/* <a href="/dashboard/bug-report" className="mx-2">
                      <span style={{ fontSize: "25px", marginRight: "20px" }}>
                        <i className="uil-bug" style={{ color: "#6c757d" }}></i>
                      </span>
                    </a> */}
                    <a target="_blank" href={`${nextSite}/contact`}>
                      <span style={{ fontSize: "25px", marginRight: "20px" }}>
                        <i
                          className="uil-smile"
                          style={{ color: "#6c757d" }}
                        ></i>
                      </span>
                    </a>
                    <a target="_blank" href={`${nextSite}/terms`}>
                      {" "}
                      <span style={{ fontSize: "25px", marginRight: "20px" }}>
                        <i
                          className=" uil-clipboard-alt"
                          style={{ color: "#6c757d" }}
                        ></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
