import React, { useState, useEffect } from "react";
import { API } from "../../config";
import resolveCurrency from "../../helpers/Helper";
import moment from "moment";

const MaintenanceHistory = ({ vehicle, units, isFreeReport }) => {
  const [state, setState] = useState({
    loading: true,
    maintRecords: [],
    paginations: 0,
    currentPage: 1,
    showEditDialog: false,
    showViewDialog: false,
    maintenanceReportId: null,
    showDeleteDialog: false,
    error: null,
  });
  const {
    loading,
    maintRecords,
    paginations,
    currentPage,
    maintenanceReportId,
    showViewDialog,
    showEditDialog,
    showDeleteDialog,
    error,
  } = state;

  useEffect(() => {
    if (!isFreeReport) getAllMaintenanceHistory();
    else setState({ ...state, loading: false });
  }, [vehicle]);
  const getAllMaintenanceHistory = () => {
    const token = localStorage.getItem("token");
    setState({
      ...state,
      loading: true,
      showEditDialog: false,
      showDeleteDialog: false,
      error: null,
    });
    fetch(`${API}/keemut-report/maintenance-history/${vehicle.id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setState({
          ...state,
          loading: false,
          maintRecords: data.maintRecords,
          paginations: Math.abs(data.maintRecords.length / 10) + 1,
          showEditDialog: false,
          showDeleteDialog: false,
          error: null,
        });
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          loading: false,
          error: "Unable to fetch your Maintenance History at this time.",
        });
      });
  };
  const renderPagination = () => {
    let content = [];
    for (let i = 1; i <= paginations; i++) {
      content.push(
        <a
          onClick={() => setState({ ...state, currentPage: i })}
          class="page-link"
          style={
            currentPage == i
              ? { backgroundColor: "#00274c", color: "#ffffff" }
              : {}
          }
        >
          {i}
        </a>
      );
    }
    return <> {content} </>;
  };

  const getMaintenanceType = (typeObj) => {
    if (typeObj.enginerelated) {
      return "Engine Related";
    } else if (typeObj.electricalrelated) {
      return "Electrical Related";
    } else if (typeObj.acrelated) {
      return "AC Related";
    } else if (typeObj.tyrebrakerelated) {
      return "Tyre / Brake Related";
    } else if (typeObj.transmissionrelated) {
      return "Transmission Related";
    } else if (typeObj.bodyrelated) {
      return "Body Related";
    } else if (typeObj.interiorrelated) {
      return "Interior Related";
    } else if (typeObj.suspensionrelated) {
      return "Suspension Related";
    } else {
      return "Other";
    }
  };

  const sortByPropertyAsc = (propertyName) => {
    function compare(v1, v2) {
      if (propertyName === "maintCost") {
        const valueA = parseFloat(v1["maintCost"]);
        const valueB = parseFloat(v2["maintCost"]);
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      }

      const valueA =
        typeof v1[propertyName] === "string"
          ? v1[propertyName].toUpperCase()
          : v1[propertyName];
      const valueB =
        typeof v2[propertyName] === "string"
          ? v2[propertyName].toUpperCase()
          : v2[propertyName];

      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      return comparison;
    }
    const values = maintRecords;
    values.sort(compare);
    setState({ ...state, maintRecords: values });
  };

  const sortByPropertyDesc = (propertyName) => {
    function compare(v1, v2) {
      if (propertyName === "maintcost") {
        const valueA = parseFloat(v1["maintcost"]);
        const valueB = parseFloat(v2["maintcost"]);
        console.log(v1);
        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      } else {
        const valueA =
          typeof v1[propertyName] === "string"
            ? v1[propertyName].toUpperCase()
            : v1[propertyName];
        const valueB =
          typeof v2[propertyName] === "string"
            ? v2[propertyName].toUpperCase()
            : v2[propertyName];

        let comparison = 0;
        if (valueA > valueB) {
          comparison = 1;
        } else if (valueA < valueB) {
          comparison = -1;
        }
        return comparison;
      }
    }
    const values = maintRecords;
    values.sort(compare);
    setState({ ...state, maintRecords: values.reverse() });
  };

  if (loading) {
    return (
      <div className="card">
        <div className="card-body">
          <h4 class="header-title">Maintenance History</h4>
          <p>Breakdown of Total Cost of Ownership</p>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card">
        <div className="card-body">
          <h4 class="header-title">Maintenance History</h4>
          <p>Breakdown of Total Cost of Ownership</p>
          <div
            class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show"
            role="alert"
          >
            <strong>Error - </strong> {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card" id="keemut-report_maintenance_history">
      <div className="card-body">
        <h4>Maintenance History</h4>
        <p>Breakdown of Total Cost of Ownership</p>
        <table className="table-striped">
          <thead>
            <tr>
              <th>
                Date{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("date")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("date")}
                ></i>
              </th>
              <th>Service </th>
              <th>
                Cost{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("maintcost")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("maintcost")}
                ></i>
              </th>
              <th>
                Odometer{" "}
                <i
                  className=" caret up icon ml-3"
                  onClick={() => sortByPropertyAsc("odometer")}
                ></i>
                <i
                  className="caret down icon"
                  onClick={() => sortByPropertyDesc("odometer")}
                ></i>
              </th>
              <th>Attachments</th>
            </tr>
          </thead>

          <tbody>
            {maintRecords.map((record, i) => {
              return i < currentPage * 10 && i >= (currentPage - 1) * 10 ? (
                <tr key={record.id}>
                  <td data-label="Date">
                    {moment(record.date.split("T")[0]).format("DD-MMM-YYYY")}
                  </td>
                  <td data-label="Service">
                    {getMaintenanceType({
                      enginerelated: record.enginerelated,
                      electricalrelated: record.electricalrelated,
                      acrelated: record.acrelated,
                      tyrebrakerelated: record.tyrebrakerelated,
                      transmissionrelated: record.transmissionrelated,
                      interiorrelated: record.interiorrelated,
                      bodyrelated: record.bodyrelated,
                      steeringrelated: record.steeringrelated,
                      suspensionrelated: record.suspensionrelated,
                    })}
                  </td>
                  <td data-label="Cost">
                    {resolveCurrency(units.currencyunit)}{" "}
                    {record.maintcost &&
                      parseInt(record.maintcost)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td data-label="Odometer">
                    {record.odometer &&
                      record.odometer
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td data-label="Attachments">
                    {record.attachment && (
                      <a href={record.attachment} target="_blank">
                        <i
                          title="View / Download Attachment"
                          className=" mdi mdi-file-multiple mx-3"
                        ></i>
                      </a>
                    )}
                  </td>
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
        <br />
        <nav aria-label="Page navigation example" style={{ float: "right" }}>
          <ul class="pagination">{renderPagination()}</ul>
        </nav>
      </div>
    </div>
  );
};

export default MaintenanceHistory;
