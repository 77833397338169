import React, { useEffect, useState } from "react";
import { Popup } from "semantic-ui-react";
import TeslaService from "../../../../services/connected-cars/tata-service";
import AreaChart from "../../../custom-charts/AreaChart";
import moment from "moment";

export default function TataBatteryLifeChart({ vehicle, units }) {
  const [state, setState] = useState({
    _loading: true,
    _xaxis: [],
    _yaxis: [],
  });
  const { _loading, _xaxis, _yaxis } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) _getData();

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const _getData = async () => {
    try {
      const data = await TeslaService.getDailyAvgBatteryLife(vehicle.vin);
      console.log(data);
      let xaxis = [],
        yaxis = [];

      data.result.forEach((item) => {
        xaxis.push(moment(item.date).format("DD-MMM-YY"));
        yaxis.push(
          parseFloat((item.average_battery_life / 100) * 312).toFixed(2)
        );
      });

      setState({ ...state, _loading: false, _xaxis: xaxis, _yaxis: yaxis });
    } catch (err) {}
  };

  return (
    <div class="card" style={{ height: "100%" }}>
      <div class="card-body">
        <h3>
          Battery Degradation
          <Popup
            className="ml-4"
            content={
              "This chart show the degradation of your battery. Maintaining a SoC below 90 is advised for increasing the life span of your vehicle"
            }
            trigger={
              <i className="mdi mdi-information-outline hover-pointer"></i>
            }
          />
        </h3>

        {!_loading && (
          <AreaChart
            xAxisTitle="Date"
            yAxisTitle="Battery Range At 100%"
            xaxis={_xaxis}
            yaxis={_yaxis}
          />
        )}
      </div>
    </div>
  );
}
