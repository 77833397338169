export default function CurrencyConversion(currencyUnit, amount) {
  switch (currencyUnit.toLowerCase()) {
    case "aed":
      return amount * 3.67;
    case "inr":
      return amount * 73.22;
    default:
      return amount;
  }
}
