import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../config";
const DummyRadials = ({ vehicle, isFreeReport }) => {
  const [state, setState] = useState({
    loading: false,
    price: 0,
    options1: {
      colors: ["#7523E8"],
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 0,
              show: false,
              color: "#000",
            },
            value: {
              color: "#000000",
              fontSize: "28px",
              show: true,
            },
          },
        },
      },
      labels: ["Driving Score"],
    },
    series1: ["N/A"],
    options2: {
      colors: ["#7523E8"],

      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 0,
              show: false,
              color: "#000",
              fontSize: "0px",
            },
            value: {
              color: "#000000",
              fontSize: "28px",
              show: true,
            },
          },
        },
      },
      labels: ["Battery Score"],
    },
    series2: ["N/A"],
    options3: {
      colors: ["#7523E8"],

      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 0,
              show: false,
              color: "#000",
              fontSize: "0px",
            },
            value: {
              color: "#000000",
              fontSize: "28px",
              show: true,
            },
          },
        },
      },
      labels: ["Demand Score"],
    },
    series3: [90],
    options4: {
      colors: ["#7523E8"],

      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 0,
              show: false,
              color: "#000",
              fontSize: "0px",
            },
            value: {
              color: "#000000",
              fontSize: "28px",
              show: true,
            },
          },
        },
      },
      labels: ["Maintenance Score"],
    },
    series4: ["N/A"],
    loading: false,
  });
  const {
    options1,
    options2,
    options3,
    options4,
    series1,
    series2,
    series3,
    series4,
    loading,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (!isFreeReport) getData();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const getData = async () => {
    try {
      const token = localStorage.getItem("token");
      const dres = await fetch(
        `${API}/keemut-report/driving-breakdown/${vehicle.vin}`,
        {
          method: "GET",
          headers: {
            Accept: `application/json`,
          },
        }
      );
      const driveData = await dres.json();

      const bres = await fetch(
        `${API}/keemut-report/battery-info/${vehicle.vin}?is_smartcar=${vehicle.is_smartcar}`,
        {
          method: "GET",
          headers: {
            Accept: `application/json`,
          },
        }
      );

      const batteryData = await bres.json();
      setState({
        ...state,

        series1: [
          vehicle.vin == "1HM888055H7DDA033"
            ? 85
            : vehicle.vin == "1HM888055H7DDA034"
            ? 65
            : driveData.drivingScore
            ? driveData.drivingScore.toFixed(0)
            : "N/A",
        ],
        series2: [
          vehicle.vin == "1HM888055H7DDA033"
            ? 96
            : vehicle.vin == "1HM888055H7DDA034"
            ? 75
            : batteryData.batteryLife || 90,
        ],
        loading: false,
      });
    } catch (err) {
      console.log(err);
      setState({ ...state, loading: false });
    }
  };

  if (loading) {
    return <div className="spinner-body text-primary" role="status" />;
  }

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class="  mdi mdi-bus-multiple widget-icon bg-primary-lighten text-primary"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">Driving Score</h5>
                <Chart options={options1} series={series1} type="radialBar" />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class=" mdi mdi-battery-charging-50  widget-icon bg-danger-lighten text-danger"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">Battery Score</h5>
                <Chart options={options2} series={series2} type="radialBar" />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class="  mdi mdi-google-analytics widget-icon bg-success-lighten text-success"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">Demand Score</h5>
                <Chart options={options3} series={series3} type="radialBar" />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div class="card widget-flat">
              <div class="card-body">
                <div class="float-right">
                  <i class=" mdi mdi-progress-wrench widget-icon bg-warning-lighten text-warning"></i>
                </div>
                <h5 class="text-dark font-weight-normal mt-0">
                  Maintenance Score
                </h5>
                <Chart
                  options={options4}
                  series={
                    vehicle.vin == "1HM888055H7DDA033"
                      ? [90]
                      : vehicle.vin == "1HM888055H7DDA034"
                      ? [85]
                      : series4
                  }
                  type="radialBar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DummyRadials;
