import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { API } from "../../../config";
import moment from "moment";
import { getRecentOdometerService } from "../../../services/odometer-history-service";

const RecentOdometer = ({ showOdoDialog, closeOdoDialog, vehicle, date }) => {
  const [state, setState] = useState({
    nextRecord: {},
    previousRecord: {},
  });
  const { nextRecord, previousRecord } = state;

  useEffect(() => {
    const _getRecentOdometer = async () => {
      try {
        setState({ ...state, showOdoDialog: true });

        const data = await getRecentOdometerService(vehicle.id, date);

        setState({
          ...state,
          previousRecord: data.previousRecord,
          nextRecord: data.nextRecord,
        });
      } catch (err) {
        console.log(err);
      }
    };
    if (date) {
      _getRecentOdometer();
    }
  }, [date, vehicle]);

  return (
    <Dialog
      open={showOdoDialog}
      onClose={closeOdoDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div class="modal-content">
        <div class="modal-header modal-colored-header bg-primary">
          <h4
            class="modal-title"
            id="primary-header-modalLabel"
            style={{ fontFamily: "Roboto" }}
          >
            Recent Odometer
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={closeOdoDialog}
          >
            ×
          </button>
        </div>
        <div class="modal-body">
          <div data-simplebar>
            <div class="timeline-alt pb-0">
              <div class="timeline-item dialog">
                <i class="mdi mdi-upload bg-info-lighten text-info timeline-icon"></i>
                <div class="timeline-item-info">
                  <a
                    href="#"
                    class="text-info font-weight-bold mb-1 d-block"
                    style={{ fontFamily: "Roboto" }}
                  >
                    {!previousRecord
                      ? "No previous Odometer reading found"
                      : `Odometer: ${
                          previousRecord && previousRecord.odometer
                        }`}
                  </a>

                  <p class="mb-0 pb-2">
                    <small class="text-muted" style={{ fontFamily: "Roboto" }}>
                      {previousRecord &&
                        moment(previousRecord.date).format("DD-MMM-YYYY")}
                    </small>
                  </p>
                </div>
              </div>

              <div class="timeline-item">
                <i class="mdi mdi-airplane bg-primary-lighten text-primary timeline-icon"></i>
                <div
                  class="timeline-item-info"
                  style={{ fontFamily: "Roboto" }}
                >
                  <a
                    href="#"
                    class="text-primary font-weight-bold mb-1 d-block"
                  >
                    {!nextRecord
                      ? "No next Odometer reading found"
                      : `Odometer: ${nextRecord && nextRecord.odometer}`}
                  </a>

                  <p class="mb-0 pb-2">
                    <small class="text-muted" style={{ fontFamily: "Roboto" }}>
                      {nextRecord &&
                        moment(nextRecord.date).format("DD-MMM-YYYY")}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-dismiss="modal"
            onClick={closeOdoDialog}
          >
            Close
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default RecentOdometer;
