import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { API } from "../../../config";

const KeemutValuationChart = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: false,
    series: [],
    options: {
      chart: {
        type: "line",

        id: "basic-line",
      },

      xaxis: {
        categories: [],
      },

      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
  });
  const { loading, series, options } = state;

  useEffect(() => {
    setState({ ...state, loading: true });
    const token = localStorage.getItem("token");
    fetch(`${API}/weekly-depreciation?make=Audi&model=A6&purchaseYear=2019`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let cumulativeData = [];
        let cumulativeHigh = [];
        let cumulativeLow = [];
        let deviation = 0;
        data.cumulativeWeekDepreciation.forEach((cd, i) => {
          if (i < data.cumulativeWeekDepreciation.length / 2) {
            deviation = 2;
          } else if (deviation < data.cumulativeWeekDepreciation.length - 2) {
            deviation = 4;
          } else {
            deviation = 5;
          }

          cumulativeHigh.push({
            x: i + 1,
            y:
              100000 -
              100000 * parseFloat((-1 * cd) / 100) +
              100000 * (deviation / 100),
          });
          cumulativeData.push({
            x: i + 1,
            y: 100000 - 100000 * parseFloat((-1 * cd) / 100),
          });
          cumulativeLow.push({
            x: i + 1,
            y:
              100000 -
              100000 * parseFloat((-1 * cd) / 100) -
              100000 * (deviation / 100),
          });
        });

        setState({
          ...state,
          options: {
            ...options,
            colors: ["#343a40", "#00274c", "#343a40"],
            yaxis: { title: { text: "Value" } },
            xaxis: {
              type: "Age, Years",
              categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
              labels: {
                formatter: function (value) {
                  return parseInt(value);
                },
              },
              title: { text: "Car Age ( Week )" },
            },
          },
          series: [
            {
              name: "Good Condition",
              data: cumulativeHigh,
            },
            {
              name: "Average Condition",
              data: cumulativeData,
            },
            {
              name: "Poor Condition",
              data: cumulativeLow,
            },
          ],
        });
      })
      .catch((err) => console.log(err));
  }, [vehicle]);

  return (
    <div>
      <h4>Estimated Value of Your Car</h4>
      <Chart options={options} series={series} type="line" />
    </div>
  );
};

export default KeemutValuationChart;
