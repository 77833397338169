export default function resolveCurrency(value) {
  if (value == null) {
    return "$";
  }

  let v = value.toLowerCase();
  let resolvedValue;
  switch (v) {
    case "usd":
      resolvedValue = "$";
      break;
    case "eur":
      resolvedValue = "€";
      break;
    case "cad":
      resolvedValue = "C$";
      break;
    case "gbp":
      resolvedValue = "£";
      break;
    case "inr":
      resolvedValue = "₹";
      break;
    default:
      resolvedValue = v;
  }

  return resolvedValue.toUpperCase();
}
