import React, { useState, useEffect } from "react";
import { Dropdown, Popup } from "semantic-ui-react";
import { API } from "../../../config";
import SnackbarMessage from "../common/SnackbarMessage";

const NotificationSettings = ({ vehicle }) => {
  const [state, setState] = useState({
    loading: false,
    submitting: false,
    showSnackbar: false,
    snackbarMessage: null,
    enableBatteryNotification: false,
    lowBatteryThreshold: null,
    lowBatteryFrequency: null,
    lowBatteryEmail: null,

    highSocEnabled: null,
    highSocThreshold: null,
    highSocFrequency: null,
    highSocEmail: null,

    reminderNotificationEnabled: null,
    reminderNotificationThershold: null,
    reminderNotificationEmail: null,

    androidEnabled: false,
    emailEnabled: false,
  });

  const {
    enableBatteryNotification,
    lowBatteryThreshold,
    lowBatteryFrequency,
    lowBatteryEmail,
    loading,
    submitting,
    showSnackbar,
    snackbarMessage,
    highSocEnabled,
    highSocFrequency,
    highSocThreshold,
    highSocEmail,
    androidEnabled,
    emailEnabled,
    reminderNotificationEnabled,
    reminderNotificationThershold,
    reminderNotificationEmail,
  } = state;

  useEffect(() => {
    const getNotificationPreference = async () => {
      try {
        setState({ ...state, loading: true });
        const user = JSON.parse(localStorage.getItem("user"));

        const res = await fetch(
          `${API}/notification-preferences/${vehicle.id}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const data = await res.json();

        const pref =
          data.notificationPreferences.length > 0
            ? data.notificationPreferences[0]
            : {};

        console.log(pref);

        setState({
          ...state,
          loading: false,
          lowBatteryEmail: pref.low_battery_email || user.email,

          lowBatteryFrequency: pref.low_battery_frequency,
          lowBatteryThreshold: pref.low_battery_threshold,
          enableBatteryNotification: pref.low_battery_enabled,

          highSocEmail: pref.high_soc_email || user.email,

          highSocFrequency: pref.high_soc_frequency,
          highSocThreshold: pref.high_soc_threshold,
          highSocEnabled: pref.high_soc_enabled,
          androidEnabled: pref.android_enabled,
          emailEnabled: pref.email_enabled,
          reminderNotificationEnabled: pref.reminder_notification_enabled,
          reminderNotificationThershold: pref.reminder_notification_threshold,
          reminderNotificationEmail:
            pref.reminder_notification_email || user.email,
        });
      } catch (err) {
        console.log(err);
        setState({ ...state, loading: false });
      }
    };

    let mounted = true;

    if (mounted) {
      getNotificationPreference();
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const onSubmit = async (e) => {
    try {
      setState({ ...state, submitting: true });
      const token = localStorage.getItem("token");

      const res = await fetch(`${API}/notification-preferences/${vehicle.id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          enableBatteryNotification,
          lowBatteryFrequency,
          lowBatteryThreshold,
          lowBatteryEmail,
          highSocEnabled,
          highSocFrequency,
          highSocThreshold,
          highSocEmail,
          androidEnabled,
          emailEnabled,
          reminderNotificationEnabled,
          reminderNotificationThreshold: reminderNotificationThershold,
          reminderNotificationEmail,
        }),
      });
      const data = await res.json();

      setState({
        ...state,
        showSnackbar: true,
        submitting: false,
        snackbarMessage: "Your notification preferences have been updated!",
      });
    } catch (err) {
      setState({
        ...state,
        showSnackbar: true,
        submitting: false,
        snackbarMessage:
          "Unable to update your notification preferences at this time!",
      });
    }
  };

  if (loading)
    return <div className="spinner-border center-spinner" role="status"></div>;

  return (
    <div className="container p-3">
      <h4>
        <strong> Personalize Notifications for</strong> :{" "}
        <span style={{ fontWeight: "normal" }}>{vehicle.name}</span>
      </h4>

      <div className="mt-4">
        <span>
          <input
            type="checkbox"
            checked={emailEnabled}
            onChange={(e) =>
              setState({
                ...state,
                emailEnabled: !emailEnabled,
              })
            }
          />
        </span>
        <span className="ml-2">
          {" "}
          <label>Email Notification </label>
        </span>

        <span className="ml-4">
          <input
            type="checkbox"
            checked={androidEnabled}
            onChange={(e) =>
              setState({
                ...state,
                androidEnabled: !androidEnabled,
              })
            }
          />
        </span>
        <span className="ml-2">
          {" "}
          <label>
            <a
              href="https://apps.apple.com/us/app/keemut-connected-car-for-tesla/id1549242352#?platform=iphone"
              target="_blank"
              style={{ color: "blue" }}
            >
              Phone Notification
            </a>{" "}
            <Popup
              content={
                "Send notification to your Android / iOS phone which has the Keemut App installed."
              }
              trigger={
                <i className="mdi mdi-information-outline hover-pointer"></i>
              }
            />
          </label>
        </span>

        {/* <span className="ml-4">
          <input
            type="checkbox"
            disabled
            checked={enableBatteryNotification}
            onChange={(e) =>
              setState({
                ...state,
                enableBatteryNotification: !enableBatteryNotification,
              })
            }
          />
        </span>
        <span className="ml-2 text-muted">
          {" "}
          <label>iOS Notification [Coming Soon] </label>
        </span> */}
      </div>

      <hr style={{ backgroundColor: "#000" }} />

      <div className="mt-4">
        <span>
          <input
            type="checkbox"
            checked={enableBatteryNotification}
            onChange={(e) =>
              setState({
                ...state,
                enableBatteryNotification: !enableBatteryNotification,
              })
            }
          />
        </span>
        <span className="ml-2">
          {" "}
          <label>
            Low Battery Notification{" "}
            <Popup
              content={
                "A notification will be sent to you whenever your battery is running low"
              }
              trigger={
                <i className="mdi mdi-information-outline hover-pointer"></i>
              }
            />
          </label>
        </span>
        <br />
        {enableBatteryNotification && (
          <>
            {" "}
            <span className="ml-2">
              {" "}
              <span>When Battery is below </span>
            </span>
            <span className="ml-2">
              <Dropdown
                placeholder="Battery Threshold %"
                value={lowBatteryThreshold}
                onChange={(e, { value }) => {
                  setState({ ...state, lowBatteryThreshold: value });
                }}
                options={[
                  {
                    key: "80",
                    value: "80",
                    text: "80%",
                  },
                  {
                    key: "70",
                    value: "70",
                    text: "70%",
                  },
                  {
                    key: "60",
                    value: "60",
                    text: "60%",
                  },
                  {
                    key: "50",
                    value: "50",
                    text: "50%",
                  },
                  {
                    key: "40",
                    value: "40",
                    text: "40%",
                  },
                  {
                    key: "30",
                    value: "30",
                    text: "30%",
                  },
                  {
                    key: "20",
                    value: "20",
                    text: "20%",
                  },
                  {
                    key: "10",
                    value: "10",
                    text: "10%",
                  },
                ]}
                className="p-1"
                style={{ border: "1px solid #BFBFBF", borderRadius: "3px" }}
              />
            </span>
            <span className="ml-2">send email to</span>
            <input
              type="email"
              id="emailaddress"
              required="true"
              className="ml-2 p-1"
              name="email"
              value={lowBatteryEmail}
              onChange={(e) =>
                setState({ ...state, lowBatteryEmail: e.target.value })
              }
              style={{
                height: "calc(2.25rem + 2px)",

                fontSize: "0.9rem",
                fontWeight: "400",
                lineHeight: "1.5",
                color: "#6c757d",
                backgroundColor: "#fff",
                backgroundClip: "padding-box",
                border: "1px solid #BFBFBF",
                borderRadius: "0.25rem",
              }}
            />
            <span className="ml-2">
              If the issue persists, remind again in{" "}
            </span>
            <span className="ml-2">
              <Dropdown
                placeholder="Frequency of reminder"
                className="p-1"
                value={lowBatteryFrequency}
                style={{ border: "1px solid #BFBFBF", borderRadius: "3px" }}
                onChange={(e, { value }) => {
                  setState({ ...state, lowBatteryFrequency: value });
                }}
                options={[
                  {
                    key: "4",
                    value: "never",
                    text: "Remind only once",
                  },
                  {
                    key: "1",
                    value: "1hr",
                    text: "1 hour",
                  },
                  {
                    key: "2",
                    value: "3hr",
                    text: "3 hours",
                  },
                  {
                    key: "3",
                    value: "6hr",
                    text: "6 hours",
                  },
                ]}
              />
            </span>
          </>
        )}
      </div>

      <div className="mt-4">
        <span>
          <input
            type="checkbox"
            checked={highSocEnabled}
            onChange={(e) =>
              setState({
                ...state,
                highSocEnabled: !highSocEnabled,
              })
            }
          />
        </span>
        <span className="ml-2">
          {" "}
          <label>
            High SoC Notification{" "}
            <Popup
              content={
                "The State of Charge (SoC) is the percent limit you charge your battery to. Charging above 94% has shown quicker battery degradation in Teslas and reduces driving range over time."
              }
              trigger={
                <i className="mdi mdi-information-outline hover-pointer"></i>
              }
            />
          </label>
        </span>
        <br />
        {highSocEnabled && (
          <>
            {" "}
            <span className="ml-2">
              {" "}
              <span>When SoC is above</span>
            </span>
            <span className="ml-2">
              <Dropdown
                placeholder="SoC %"
                value={highSocThreshold}
                onChange={(e, { value }) => {
                  setState({ ...state, highSocThreshold: value });
                }}
                options={[
                  {
                    key: "90",
                    value: "90",
                    text: "90%",
                  },
                  {
                    key: "95",
                    value: "95",
                    text: "95%",
                  },
                ]}
                className="p-1"
                style={{ border: "1px solid #BFBFBF", borderRadius: "3px" }}
              />
            </span>
            <span className="ml-2">send email to</span>
            <input
              type="email"
              id="emailaddress"
              required="true"
              className="ml-2 p-1"
              name="email"
              value={highSocEmail}
              onChange={(e) =>
                setState({ ...state, highSocEmail: e.target.value })
              }
              style={{
                height: "calc(2.25rem + 2px)",

                fontSize: "0.9rem",
                fontWeight: "400",
                lineHeight: "1.5",
                color: "#6c757d",
                backgroundColor: "#fff",
                backgroundClip: "padding-box",
                border: "1px solid #BFBFBF",
                borderRadius: "0.25rem",
              }}
            />
            <span className="ml-2">
              If the issue persists, remind again in{" "}
            </span>
            <span className="ml-2">
              <Dropdown
                placeholder="Frequency of reminder"
                className="p-1"
                value={lowBatteryFrequency}
                style={{ border: "1px solid #BFBFBF", borderRadius: "3px" }}
                onChange={(e, { value }) => {
                  setState({ ...state, lowBatteryFrequency: value });
                }}
                options={[
                  {
                    key: "4",
                    value: "never",
                    text: "Remind only once",
                  },
                  {
                    key: "1",
                    value: "1hr",
                    text: "1 hour",
                  },
                  {
                    key: "2",
                    value: "3hr",
                    text: "3 hours",
                  },
                  {
                    key: "3",
                    value: "6hr",
                    text: "6 hours",
                  },
                ]}
              />
            </span>
            {/* <span className="ml-2">send email to</span>
            <span className="ml-2">
              <input
                className="form-control "
                type="email"
                id="emailaddress"
                required="true"
                placeholder="Enter your email"
                value={lowBatteryEmail}
                onChange={(e) =>
                  setState({ ...state, lowBatteryEmail: e.target.value })
                }
                style={{ display: "inline" }}
              />
            </span>{" "} */}
          </>
        )}
      </div>

      <div className="mt-4">
        <span>
          <input
            type="checkbox"
            checked={reminderNotificationEnabled}
            onChange={(e) =>
              setState({
                ...state,
                reminderNotificationEnabled: !reminderNotificationEnabled,
              })
            }
          />
        </span>
        <span className="ml-2">
          {" "}
          <label>
            Maintenance Reminder Notification{" "}
            <Popup
              content={
                "The State of Charge (SoC) is the percent limit you charge your battery to. Charging above 94% has shown quicker battery degradation in Teslas and reduces driving range over time."
              }
              trigger={
                <i className="mdi mdi-information-outline hover-pointer"></i>
              }
            />
          </label>
        </span>
        <br />
        {reminderNotificationEnabled && (
          <>
            {" "}
            <span className="ml-2">
              {" "}
              <span>Send Maintenance Reminders for tasks due in next</span>
            </span>
            <span className="ml-2">
              <Dropdown
                className="p-1"
                value={reminderNotificationThershold}
                style={{ border: "1px solid #BFBFBF", borderRadius: "3px" }}
                onChange={(e, { value }) => {
                  setState({ ...state, reminderNotificationThershold: value });
                }}
                options={[
                  {
                    key: "0",
                    value: "500",
                    text: "500 mi",
                  },
                  {
                    key: "4",
                    value: "1000",
                    text: "1000 mi",
                  },
                  {
                    key: "1",
                    value: "2500",
                    text: "2500 mi",
                  },
                  {
                    key: "2",
                    value: "5000",
                    text: "5000 mi",
                  },
                ]}
              />
            </span>
            <span className="ml-2">send email to</span>
            <input
              type="email"
              id="emailaddress"
              required="true"
              className="ml-2 p-1"
              name="email"
              value={reminderNotificationEmail}
              onChange={(e) =>
                setState({
                  ...state,
                  reminderNotificationEmail: e.target.value,
                })
              }
              style={{
                height: "calc(2.25rem + 2px)",

                fontSize: "0.9rem",
                fontWeight: "400",
                lineHeight: "1.5",
                color: "#6c757d",
                backgroundColor: "#fff",
                backgroundClip: "padding-box",
                border: "1px solid #BFBFBF",
                borderRadius: "0.25rem",
              }}
            />
          </>
        )}
      </div>

      <button className="btn btn-block btn-primary mt-3" onClick={onSubmit}>
        {submitting ? "Saving..." : "Save"}
      </button>

      <SnackbarMessage
        message={snackbarMessage}
        open={showSnackbar}
        onClose={() => setState({ ...state, showSnackbar: false })}
      />
    </div>
  );
};

export default NotificationSettings;
