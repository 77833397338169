import React, { useState, useEffect } from "react";
import { API } from "../../config";
import Chart from "react-apexcharts";
const CurrentCostPerMile = () => {
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   console.log(vehicle);
  //   fetch(`${API}/cost-per-mile/${vehicle.id}`, {
  //     method: "GET",
  //     headers: {
  //       Accept: `application/json`,
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //       setState({
  //         ...state,
  //         costPerMile: data.costPerMile,
  //       });
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  return (
    <div class="card widget-flat">
      <div class="card-body">
        <div class="float-right">
          <i class=" mdi mdi-coin-outline widget-icon bg-warning-lighten text-warning"></i>
        </div>
        <h5
          class="text-dark font-weight-normal mt-0"
          title="Cost incurred per mile"
        >
          Cost Per Mile
        </h5>
        <h3 class="mt-3 mb-3">$0.2 per mi</h3>
        <p>
          Based on The data, owning this vehicle incurs this much cost per mi.{" "}
        </p>
      </div>
    </div>
  );
};

export default CurrentCostPerMile;
