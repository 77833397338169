import simpleFetch from "../helpers/fetch-wrapper";

/**
 * @author Hemang Bhogayata
 * @description Get the list of countries
 */
export async function getCountriesService() {
  try {
    return await simpleFetch("/units/countries/all", "GET");
  } catch (err) {
    console.log(err);
  }
}

/**
 * @author Hemang Bhogayata
 * @description Get list of all states for usa
 */
export async function getStatesService() {
  try {
    return await simpleFetch("/units/states/all", "GET");
  } catch (err) {
    console.log(err);
  }
}
