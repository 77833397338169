import React, { useState, useEffect } from "react";
import { API } from "../../config";
import Chart from "react-apexcharts";
const DistanceDrivenPerMonth = ({ vehicle }) => {
  return (
    <div class="card widget-flat">
      <div class="card-body">
        <div class="float-right">
          <i class=" mdi mdi-steering widget-icon bg-primary-lighten text-primary"></i>
        </div>
        <h5
          class="text-dark font-weight-normal mt-0"
          title="Distance Driven Per Month"
        >
          Average Distance Driven Per Month
        </h5>
        <h3 class="mt-3 mb-3">615 mi per month</h3>
        <p>
          Based on The data, the vehicle was driven regularly over its lifespan.{" "}
        </p>
      </div>
    </div>
  );
};

export default DistanceDrivenPerMonth;
