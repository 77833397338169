import { Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GeofenceService from "../../../services/geofence-service";

const Geofence = ({ vehicle }) => {
  const [state, setState] = useState({
    _loading: false,
    _geofences: [],
    _error: null,

    _showDelete: false,
    _deleteGeofenceId: null,
    _deleteLoading: false,
  });
  const {
    _loading,
    _geofences,
    _error,
    _deleteGeofenceId,
    _showDelete,
    _deleteLoading,
  } = state;

  useEffect(() => {
    let mounted = true;

    if (mounted) _getGeofence();

    return function cleanup() {
      mounted = false;
    };
  }, []);

  const _getGeofence = async () => {
    try {
      setState({
        ...state,
        _loading: true,
        _error: null,
        _deleteGeofenceId: null,
        _deleteLoading: false,
        _showDelete: false,
      });
      const data = await GeofenceService.getGeofencesByVin(vehicle.vin);

      if (data.done)
        setState({
          ...state,
          _loading: false,
          _geofences: data.geofences,
          _error: null,
          _deleteGeofenceId: null,
          _deleteLoading: false,
          _showDelete: false,
        });
      else
        setState({
          ...state,
          _loading: false,
          _geofences: [],
          _error: "Unable to fetch geofence data at the moment.",
          _deleteGeofenceId: null,
          _deleteLoading: false,
          _showDelete: false,
        });
    } catch (err) {
      console.log(err);
      setState({
        ...state,
        _loading: false,
        _geofences: [],
        _error: "Unable to fetch geofence data at the moment.",
        _deleteGeofenceId: null,
        _deleteLoading: false,
        _showDelete: false,
      });
    }
  };

  const _deleteGeofence = async (e) => {
    try {
      setState({ ...state, _deleteLoading: true });
      const data = await GeofenceService.deleteGeofenceById(_deleteGeofenceId);
    } catch (err) {
      console.log(err);
    } finally {
      _getGeofence();
    }
  };

  return (
    <div className="text-dark">
      <h1>Geofence</h1>
      {_loading && (
        <div className="spinner spinner-border center-spinner"> </div>
      )}

      {!_loading && (
        <div className="card card-body">
          <Link to="/dashboard/geofence/add">
            <button className="my-2 btn btn-primary" style={{ width: "100%" }}>
              Add Geofence
            </button>
          </Link>

          <table className="table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Trigger On</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {_geofences.map((g) => (
                <tr>
                  <td>{g.name}</td>
                  <td>{g.geofence_type}</td>
                  <td>{g.trigger_on}</td>
                  <td>
                    {" "}
                    <a
                      href={`/dashboard/geofence/add/?id=${g.id}`}
                      class="action-icon"
                    >
                      {" "}
                      <i class="mdi mdi-pencil"></i>
                    </a>
                    <a class="action-icon mx-4">
                      {" "}
                      <i
                        onClick={() =>
                          setState({
                            ...state,
                            _showDelete: true,
                            _deleteGeofenceId: g.id,
                          })
                        }
                        class="mdi mdi-delete"
                      ></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <DeleteGeofenceDialog
        loading={_deleteLoading}
        onClose={() => {
          setState({ ...state, _showDelete: false });
        }}
        onConfirm={_deleteGeofence}
        showDelete={_showDelete}
      />
    </div>
  );
};

const DeleteGeofenceDialog = ({ showDelete, onClose, loading, onConfirm }) => {
  return (
    <Dialog
      open={showDelete}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div class="modal-content">
        <div class="modal-header modal-colored-header bg-primary">
          <h4 class="modal-title" id="primary-header-modalLabel">
            Are You Sure
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
            onClick={onClose}
          >
            ×
          </button>
        </div>
        <div class="modal-body">
          Once deleted, You will not be able to recover this geofence.
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-dismiss="modal"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            onClick={onConfirm}
          >
            {loading ? "Loading..." : "Confirm"}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default Geofence;
