import simpleFetch from "../helpers/fetch-wrapper";
import getUser from "../helpers/user";

/**
 * @author Hemang Bhogayata
 * @description Get the vehicles of user
 */
export async function getVehiclesService() {
  try {
    const user = getUser();
    return await simpleFetch(`/vehicles/all/${user.uid}`, "GET");
  } catch (err) {
    console.log(err);
  }
}

export async function createVehicleService(
  name,
  model,
  make,
  trim,
  drivetrain,
  odometer,
  category,
  type,
  purchasePrice,
  year,
  vin,
  plateNo,
  duration,
  monthlyPayment,
  downpayment,
  purchaseOption,
  drivingLimit,
  isCrowdsourced
) {
  try {
    return await simpleFetch(
      "/vehicles",
      "POST",
      JSON.stringify({
        name,
        model,
        make,
        trim,
        drivetrain,
        odometer,
        category,
        type,
        purchasePrice,
        year,
        vin,
        plateNo,
        duration,
        monthlyPayment,
        downpayment,
        purchaseOption,
        drivingLimit,
        isCrowdsourced,
      })
    );
  } catch (err) {
    console.log(err);
  }
}

/**
 *
 * @description Get the access token for vehicle authorization
 * @param {String} vehicleId
 * @returns
 */
export async function authorizeAccessToVehicleService(vehicleId) {
  try {
    return await simpleFetch(
      "/vehicles/auth",
      "POST",
      JSON.stringify({ vehicleId })
    );
  } catch (err) {
    console.log(err);
  }
}
