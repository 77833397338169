export default function LogoResolver(make) {
  let img;

  switch (make.toLowerCase()) {
    case "Honda".toLowerCase():
      img = require("../assets/logos/honda.svg");
      break;
    case "Toyota".toLowerCase():
      img = require("../assets/logos/toyota.svg");
      break;
    case "Tata".toLowerCase():
      img = require("../assets/logos/tata.svg");
      break;
    case "BMW".toLowerCase():
      img = require("../assets/logos/bmw.svg");
      break;

    case "Audi".toLowerCase():
      img = require("../assets/logos/audi.svg");
      break;

    case "Ford".toLowerCase():
      img = require("../assets/logos/ford.svg");
      break;

    case "Volkswagen".toLowerCase():
      img = require("../assets/logos/wv.svg");
      break;

    case "Acura".toLowerCase():
      img = require("../assets/logos/acura.svg");
      break;

    case "Buick".toLowerCase():
      img = require("../assets/logos/buick.svg");
      break;
    case "Cadillac".toLowerCase():
      img = require("../assets/logos/cadillac.svg");
      break;
    case "Chevy".toLowerCase():
      img = require("../assets/logos/chevy.svg");
      break;
    case "Chevrolet".toLowerCase():
      img = require("../assets/logos/chevrolet.svg");
      break;
    case "Citroen".toLowerCase():
      img = require("../assets/logos/citroen.svg");
      break;
    case "Ferrari".toLowerCase():
      img = require("../assets/logos/ferrari.svg");
      break;

    case "FIAT".toLowerCase():
      img = require("../assets/logos/fiat.svg");
      break;

    case "Hyundai".toLowerCase():
      img = require("../assets/logos/hyundai.svg");
      break;
    case "Infiniti".toLowerCase():
      img = require("../assets/logos/infiniti.svg");
      break;
    case "Jeep".toLowerCase():
      img = require("../assets/logos/jeep.svg");
      break;
    case "Kia".toLowerCase():
      img = require("../assets/logos/kia.svg");
      break;
    case "Lamborghini".toLowerCase():
      img = require("../assets/logos/lambo.svg");
      break;
    case "Lexus".toLowerCase():
      img = require("../assets/logos/lexus.svg");
      break;
    case "Mazda".toLowerCase():
      img = require("../assets/logos/mazda.svg");
      break;
    case "McLaren".toLowerCase():
      img = require("../assets/logos/mclaren.svg");
      break;
    case "Mercedes-Benz".toLowerCase():
      img = require("../assets/logos/mbenz.svg");
      break;
    case "Mercury".toLowerCase():
      img = require("../assets/logos/mercury.svg");
      break;
    case "Mini".toLowerCase():
      img = require("../assets/logos/mini.svg");
      break;
    case "Mitsubishi".toLowerCase():
      img = require("../assets/logos/mitsubishi.svg");
      break;
    case "Nissan".toLowerCase():
      img = require("../assets/logos/nissan.svg");
      break;
    case "Opel".toLowerCase():
      img = require("../assets/logos/opel.svg");
      break;
    case "Peugeot".toLowerCase():
      img = require("../assets/logos/peugeot.svg");
      break;
    case "Pontiac".toLowerCase():
      img = require("../assets/logos/pontiac.svg");
      break;
    case "Porsche".toLowerCase():
      img = require("../assets/logos/porsche.svg");
      break;
    case "Renault".toLowerCase():
      img = require("../assets/logos/renault.svg");
      break;

    case "Smart".toLowerCase():
      img = require("../assets/logos/smart.svg");
      break;
    case "Suzuki".toLowerCase():
      img = require("../assets/logos/suzuki.svg");
      break;

    case "Tesla".toLowerCase():
      img = require("../assets/logos/tesla.svg");
      break;
    case "TVS".toLowerCase():
      img = require("../assets/logos/tvs.svg");
      break;
    case "Volvo".toLowerCase():
      img = require("../assets/logos/volvo.svg");
      break;
    default:
      img = require("../assets/logos/default.svg");
      break;
  }

  return img;
}
