import React, { useState, useEffect } from "react";
import { API } from "../../../config";
import MaintenanceHistoryTable from "./MaintenanceHistoryTable";
import AnalyticsTable from "./AnalyticsTable";

const TCO = ({ vehicle }) => {
  const [state, setState] = useState({
    units: {},
  });
  const { units } = state;

  useEffect(() => {
    const lsJSON = localStorage.getItem("units");

    if (lsJSON) {
      setState({ ...state, units: JSON.parse(lsJSON) });
    } else {
      const token = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("user"));
      fetch(`${API}/units/${user.uid}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          localStorage.setItem("units", JSON.stringify(data.units));
          setState({ ...state, units: data.units });
        });
    }
  }, []);

  return (
    <div className="text-dark">
      <a href="/dashboard/maintenance-v2">
        <div
          class="btn btn-primary text-center "
          role="alert"
          style={{ width: "100%" }}
        >
          Add Maintenance
        </div>
      </a>
      <div className="mx-2 my-3">
        <div className="row ">
          <div className="col-lg-12">
            <AnalyticsTable vehicle={vehicle} units={units} />
          </div>{" "}
        </div>
        <div className="row">
          <div className="col-lg-12">
            <MaintenanceHistoryTable vehicle={vehicle} units={units} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {/* <MaintenanceCostChart vehicle={vehicle} units={units} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TCO;
